import React, { useState, useEffect } from "react";
import "./DisplayAllOrders.css";
import { GetGestionMatiereOrderData } from "../../../../../../../Data/firebaseInteractionOwnerV2";
import SelectFournisseur from "./SelectFournisseur/SelectFournisseur";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../../../firebaseConfig";

import { CircularProgress } from "@mui/material";

const DisplayAllOrders = ({
  RestaurantName,
  SelectedMatiereOrder,
  setSelectedMatiereOrder,
}) => {
  const [MatiereOrder, setMatiereOrder] = useState(null);
  const [SelectedFournisseur, setSelectedFournisseur] = useState(null);

  const [CommandeMatiere] = useCollection(
    collection(getFirestore(app), `${RestaurantName}CommandeMatiere`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchData() {
      const commandes = await GetGestionMatiereOrderData(RestaurantName);
      setMatiereOrder(commandes);
    }

    fetchData();
  }, [RestaurantName, CommandeMatiere]);
  return (
    <div className="contenerDisplayAllOrders">
      <SelectFournisseur
        RestaurantName={RestaurantName}
        SelectedFournisseur={SelectedFournisseur}
        setSelectedFournisseur={setSelectedFournisseur}
      />
      <div>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Commande du :</TableCell>
                <TableCell align="left">Etat de livraison</TableCell>
                <TableCell align="left">Date Recu</TableCell>
                <TableCell align="left">Prix de la commande</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {MatiereOrder ? (
                MatiereOrder.map((order) => {
                  const OrderDate = new Date(order.date.seconds * 1000);
                  const year = OrderDate.getFullYear();
                  const month = String(OrderDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const day = String(OrderDate.getDate()).padStart(2, "0");
                  const FormatedDate = `${day}/${month}/${year} - ${OrderDate.getHours()} : ${OrderDate.getMinutes()}`;

                  return (
                    SelectedFournisseur &&
                    order.FournisseurID === SelectedFournisseur.IDPepers && (
                      <TableRow
                        key={order.IDPepers}
                        sx={
                          SelectedMatiereOrder &&
                          SelectedMatiereOrder.IDPepers === order.IDPepers
                            ? {
                                backgroundColor: "#3f51b5",
                                color: "white",
                              }
                            : { border: 0 }
                        }
                        onClick={() => setSelectedMatiereOrder(order)}
                      >
                        <TableCell component="th" scope="row">
                          {FormatedDate}
                        </TableCell>
                        <TableCell align="left">
                          {order.DeliveryState}
                        </TableCell>
                        <TableCell align="left">
                          {order.hasOwnProperty("MisEnStockLe") &&
                          order.MisEnStockLe !== null
                            ? order.MisEnStockLe.toDate().toLocaleDateString(
                                "fr-FR"
                              )
                            : "Non Recu"}
                        </TableCell>
                        <TableCell align="left">
                          {order.hasOwnProperty("PrixDeLaCommande") &&
                            order.PrixDeLaCommande + " €"}{" "}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })
              ) : (
                <TableRow
                  key={1}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <CircularProgress size={24} color="secondary" />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DisplayAllOrders;
