import React, { useEffect, useState } from "react";
import "./RefairePrep.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import ModalAvecDeuxFunction from "../../../VisiteurComponents/Modal/ModalAvecDeuxFunction/ModalAvecDeuxFunction";
import {
  HandleRefairePrep,
  HandleSavePrep,
  getIngredientObjectUsedInPrep,
  getPrepObjectWithIDPrep,
} from "../../../../Data/firebaseInteractionOwnerV2";
import Avatar from "@mui/material/Avatar";
import { TextField, Button } from "@material-ui/core";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

const RefairePrep = ({ IDPrep, RestaurantName }) => {
  const [OpenRefairePrepModal, setOpenRefairePrepModal] = useState(false);
  const [DataForm, setDataForm] = useState({ RestaurantName, IDPrep });
  const [IngredientUsed, setIngredientUsed] = useState(null);
  const [PrepObject, setPrepObject] = useState(null);
  const [CoefMultiplicateur, setCoefMultiplicateur] = useState([
    1, 2, 4, 8, 16,
  ]);
  const [SelectedCoef, setSelectedCoef] = useState(CoefMultiplicateur[0]);

  const handleOpenRefairePrepModal = () => {
    console.log(DataForm);
    setDataForm((prev) => ({ ...prev, RestaurantName, IDPrep }));
    setOpenRefairePrepModal(true);
  };

  const handleCloseRefairePrepModal = () => {
    setOpenRefairePrepModal(false);
  };

  const [RestaurantSainteFoyRefairePrep] = useCollection(
    collection(getFirestore(app), "RestaurantSainteFoyRefairePrep"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const Data = await getIngredientObjectUsedInPrep(IDPrep);
      setIngredientUsed(Data);
      setDataForm((prevdata) => {
        let qteIngredient = {};
        Data.forEach((ingredient) => {
          qteIngredient[ingredient.IDPepers] = ingredient.QteUsedInPrep;
        });

        return {
          ...prevdata,
          QteIngredientUsedForRefaire: { ...qteIngredient },
        };
      });
      const PrepData = await getPrepObjectWithIDPrep(IDPrep);
      setPrepObject(PrepData);
    }
    fetchOrders();
  }, [RestaurantSainteFoyRefairePrep]);

  return (
    <div>
      <div
        className="ButtonAddMatiere"
        onClick={() => handleOpenRefairePrepModal()}
      >
        Refaire Prep
      </div>
      {OpenRefairePrepModal && (
        <ModalAvecDeuxFunction
          open={OpenRefairePrepModal}
          handleClose={handleCloseRefairePrepModal}
          Form={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "500px",
              }}
            >
              {IngredientUsed &&
                IngredientUsed.map((Ingredient) => (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    {Ingredient.name}
                    <Avatar
                      style={{ marginLeft: "15px", marginRight: "15px" }}
                      alt={Ingredient.name}
                      src={Ingredient.Img}
                    />
                    <TextField
                      onChange={(event) =>
                        setDataForm((prevData) => ({
                          ...prevData,
                          QteIngredientUsedForRefaire: {
                            ...prevData.QteIngredientUsedForRefaire,
                            [Ingredient.IDPepers]: event.target.value,
                          },
                        }))
                      }
                      style={{ maxWidth: "50px" }}
                      label="Qte utilisee"
                      value={
                        DataForm.hasOwnProperty(
                          "QteIngredientUsedForRefaire"
                        ) &&
                        DataForm.QteIngredientUsedForRefaire[
                          Ingredient.IDPepers
                        ]
                      }
                    />
                    <div>
                      INDIC:
                      {PrepObject &&
                        PrepObject.MatierePremiereUtilisees.filter(
                          (prepUsed) =>
                            prepUsed.IDIngredient === Ingredient.IDPepers
                        )[0].Qte * SelectedCoef}
                    </div>
                    <div>{Ingredient.UniteDeRecette}</div>
                  </div>
                ))}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  onChange={(event) =>
                    setDataForm((prevData) => ({
                      ...prevData,
                      QteRefait: event.target.value,
                    }))
                  }
                  style={{ maxWidth: "120px" }}
                  label="Qte faite"
                  value={DataForm.QteRefait}
                />
                {PrepObject && PrepObject.UniteDeMesure}
              </div>
            </div>
          }
          TextActionButton={"Refaire"}
          TextActionButton2={"sauvegarder"}
          SousParagraphe={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                Combien voulez vous faire de preparation :{" "}
                {CoefMultiplicateur.map((coef) => (
                  <div
                    style={
                      SelectedCoef && SelectedCoef === coef
                        ? {
                            padding: "5px",
                            borderRadius: "5px",
                            border: "solid 1px red",
                            marginLeft: "3px",
                            marginRight: "3px",
                          }
                        : {
                            padding: "5px",
                            borderRadius: "5px",
                            border: "solid 1px",
                            marginLeft: "3px",
                            marginRight: "3px",
                            cursor: "pointer",
                          }
                    }
                    onClick={() => setSelectedCoef(coef)}
                  >
                    {PrepObject && coef * PrepObject.QteFinalePrep}
                    {PrepObject && PrepObject.UniteDeMesure}$
                  </div>
                ))}
              </div>
            </div>
          }
          TitleModal={`Refaire prep`}
          DataForm={{ ...DataForm }}
          Function={HandleRefairePrep}
          Function2={HandleSavePrep}
          setDataForm={setDataForm}
        />
      )}
    </div>
  );
};

export default RefairePrep;
