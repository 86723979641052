import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

const InfoOption = ({ InfoOptionData, setInfoOptionData }) => {
  return (
    <>
      <div
        style={{
          background: "rgba(255, 255, 255)",
          borderRadius: "0.7rem",
          padding: "1rem",
          fontSize: "13px",
          overflowY: "auto",
        }}
      >
        <TextField
          label="Nom de l'option"
          variant="outlined"
          fullWidth
          value={
            InfoOptionData.hasOwnProperty("name") ? InfoOptionData.name : ""
          }
          onChange={(e) =>
            setInfoOptionData((prev) => ({ ...prev, name: e.target.value }))
          }
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          label="Nombre minimum de réponses possibles"
          variant="outlined"
          fullWidth
          value={
            InfoOptionData.hasOwnProperty("min_choices")
              ? InfoOptionData.min_choices
              : ""
          }
          onChange={(e) =>
            setInfoOptionData((prev) => ({
              ...prev,
              min_choices: e.target.value,
            }))
          }
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          label="Nombre maximum de réponses possibles"
          variant="outlined"
          fullWidth
          value={
            InfoOptionData.hasOwnProperty("max_choices")
              ? InfoOptionData.max_choices
              : ""
          }
          onChange={(e) =>
            setInfoOptionData((prev) => ({
              ...prev,
              max_choices: e.target.value,
            }))
          }
          style={{ marginBottom: "1rem" }}
        />
      </div>
    </>
  );
};

export default InfoOption;
