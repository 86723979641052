import React, {useState} from "react";
import { Button } from "@mui/material";
import ModalBankTransaction from '../../Modal/ModalBankTransaction/ModalBankTransaction'


export default function DepositBankTransfer() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (<>
        <Button variant="contained" color="primary" onClick={handleOpen}>
            Deposit
        </Button>
        <ModalBankTransaction
          open={open}
          handleClose={handleClose}
        />
      </>
    )
}