import React, { useState } from "react";
import "./GestionDishAndPrep.css";
import BigSelectorDishAndPrep from "../../OwnerV2Components/BigSelectorDishAndPrep/BigSelectorDishAndPrep";
import ModifictaionDishOrPrep from "../../OwnerV2Components/ModifictaionDishOrPrep/ModifictaionDishOrPrep";

const GestionDishAndPrep = () => {
  const [SelectedDish, SetSelectedDish] = useState(null);
  const [SelectedPrep, setSelectedPrep] = useState(null);

  return (
    <div className="ContenerGestionDishAndPrep">
      <ModifictaionDishOrPrep
        SelectedDish={SelectedDish}
        SelectedPrep={SelectedPrep}
      />
      <BigSelectorDishAndPrep
        SelectedDish={SelectedDish}
        SetSelectedDish={SetSelectedDish}
        SelectedPrep={SelectedPrep}
        setSelectedPrep={setSelectedPrep}
      />
    </div>
  );
};

export default GestionDishAndPrep;
