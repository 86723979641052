import React, { useEffect, useState } from "react";
import "./NavBarHorisontale.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const NavBarHorisontale = ({ Data, setNavRow, navRow }) => {
  return (
    <div className="navAnaylseCommande">
      {Data.map((row, index) => (
        <div
          className={navRow === index ? "navRowSelected" : "navRow"}
          onClick={() => setNavRow(index)}
        >
          {row}
        </div>
      ))}
    </div>
  );
};
export default NavBarHorisontale;
