import React, { useState, useEffect } from "react";
import "./UpdateIngredientData.css";
import ModalAvecFunction from "../../../../components/VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField, Button } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { UpdateIngredientInfo } from "../../../../Data/firebaseInteractionOwnerV2";
const UpdateIngredientData = ({ IDIngredient, DefaultData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    IDPepers: IDIngredient,
    name: DefaultData.name,
  });

  useEffect(() => {
    async function fetchOrders() {}
    fetchOrders();
  }, []);

  const handleOpenModal = () => {
    setDataForm((prevData) => ({ ...prevData, ...DefaultData }));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        Update ingredient data
      </div>
      <ModalAvecFunction
        open={openModal}
        handleClose={handleCloseModal}
        Form={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="Name"
              variant="outlined"
              value={DataForm.name}
              onChange={(e) =>
                setDataForm({ ...DataForm, name: e.target.value })
              }
            />
            <div className="SelectFournisseurContener">
              UNITE DE RECETTE :
              <div
                className={
                  DataForm.UniteDeRecette === "Unit"
                    ? "SelectedIngredient"
                    : "SelectIngredient"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    UniteDeRecette: "Unit",
                  }))
                }
              >
                Unit
              </div>
              <div
                className={
                  DataForm.UniteDeRecette === "KG"
                    ? "SelectedIngredient"
                    : "SelectIngredient"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    UniteDeRecette: "KG",
                  }))
                }
              >
                KG
              </div>
            </div>
          </div>
        }
        TextActionButton={"Update"}
        TitleModal={"Update ingredient data"}
        DataForm={{ ...DataForm }}
        setDataForm={setDataForm}
        Function={UpdateIngredientInfo}
      />
    </div>
  );
};

export default UpdateIngredientData;
