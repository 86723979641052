import React, { useState } from "react";

const DisplayCategoriesModuleCommande = () => {
  const [CategorieFood, setCategorieFood] = useState([
    "Entree",
    "Plat",
    "Dessert",
    "Boisson",
  ]);
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {CategorieFood.map((cat) => (
        <div
          style={{
            paddingRight: "30px",
            paddingLeft: "30px",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderRadius: "5px",
            backgroundColor: "purple",
            color: "white",
            margin: "5px",
          }}
        >
          {cat}
        </div>
      ))}
    </div>
  );
};

export default DisplayCategoriesModuleCommande;
