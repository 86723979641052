import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Avatar from "@mui/material/Avatar";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TablePreparations.css";

import { getOnlyPreparations } from "../../../Data/firebaseService";
import ModalModificationPrep from "../../../components/Modal/ModalModificationPrep/ModalModificationPrep";

import ButtonCalculateWallet from "../../Button/ButtonCalculateWallet/ButtonCalculateWallet";

export default function TablePreparations() {
  const [AllPrepData, setAllPrepData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPrep, setSelectedPrep] = useState(null);
  const [WalletData, setWalletData] = useState(null);

  console.log(WalletData);
  useEffect(() => {
    async function fetchOrders() {
      const AllPrep = await getOnlyPreparations();
      setAllPrepData(AllPrep);
      //CreateWalletDataEveryDay("wjLuT8pKUhMmEaqbJ9sCR5eVCAj1", 90, "2023/08/2");
      //SumtransactionWallet("2hX6jq4fcAVJzuJmEFIY3qMLssm1");
    }
    fetchOrders();
  }, []);

  const handleOpen = (Prep) => {
    setSelectedPrep(Prep);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPrep(null);
    setOpen(false);
  };

  return (
    <>
      <h3>Liste des Prep</h3>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Preparation</TableCell>
                <TableCell>img</TableCell>
                <TableCell>But</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {AllPrepData != null &&
                AllPrepData.map((Prep) => (
                  <TableRow
                    key={Prep}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {Prep.Nom}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Avatar alt={Prep.Nom} src={Prep.PhotoFirebase} />
                    </TableCell>
                    <TableCell
                      onClick={() => handleOpen(Prep)}
                      align="left"
                      className="Details"
                    >
                      Modifier
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedPrep && (
          <ModalModificationPrep
            open={open}
            handleClose={handleClose}
            prep={selectedPrep}
          />
        )}
      </div>
    </>
  );
}
