import React, { useState } from "react";
import { UpdateOrCreateNewOption } from "../../../../../Data/firebaseInteractionOwnerV2";

const SmallHeaderAddOption = ({
  OptionChoice,
  InfoOptionData,
  setInfoOptionData,
  setOptionChoice,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
          marginRight: "5px",
          backgroundColor: "#00A3FF",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          UpdateOrCreateNewOption(OptionChoice, InfoOptionData);
          setOptionChoice([]);
          setInfoOptionData({});
        }}
      >
        Save
      </div>
      <div style={{ flex: 1 }}>Retour</div>
    </div>
  );
};

export default SmallHeaderAddOption;
