import React, { useState } from "react";
import "./AnalyseProduitsVendus.css";
import TableAnalyseProduitVendu from "./TableAnalyseProduitVendu/TableAnalyseProduitVendu";

const AnalyseProduitsVendus = ({ dateStart, dateEnd }) => {
  const [navRow, setNavRow] = useState(0);
  return (
    <div className="ContenerAnalyseProduitsVendus">
      <TableAnalyseProduitVendu
        RestaurantName={"RestaurantSainteFoy"}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
      <div>MainData</div>
    </div>
  );
};

export default AnalyseProduitsVendus;
