import React, { useState, useEffect } from "react";
import MainDashDataKitchen from "../../components/MainDash/MainDashDataKitchen/MainDashDataKitchen";

const DataKitchen = () => {
  useEffect(() => {}, []);

  return (
    <>
      <MainDashDataKitchen />
      <div>Right side</div>
    </>
  );
};

export default DataKitchen;
