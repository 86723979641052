import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableDisplayFournisseur.css";

import { getAllFournisseurData } from "../../../Data/firebaseService";
import { SetIngredientPrixURecetteHT } from "../../../Functions/HandleClickFunctions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import ModalUpdateFournisseurData from "../../Modal/ModalUpdateFournisseurData/ModalUpdateFournisseurData";

import { useRecoilState } from "recoil";
import { CreationProductAtom } from "../../../atoms/CreationProductAtom";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Cancel") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function TableDisplayFournisseur() {
  const [user, loading, error] = useAuthState(auth);
  const [Allfournisseur, setAllfournisseur] = useState(null);
  const [selectedFournisseur, setFournisseur] = useState(null);
  const [open, setOpen] = useState(false);

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const Allfournisseur = await getAllFournisseurData();
      setAllfournisseur(Allfournisseur);
    }
    fetchOrders();
  }, [Production]);

  const handleOpen = (Fournisseur) => {
    setFournisseur(Fournisseur);
    setOpen(true);
  };

  const handleClose = () => {
    setFournisseur(null);
    setOpen(false);
  };

  return (
    <>
      <h3>Liste des Fournisseurs</h3>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Fournisseur</TableCell>
                <TableCell align="left">Commande Min</TableCell>
                <TableCell align="left">Frais de livraison</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {Allfournisseur != null &&
                Allfournisseur.map((Fournisseur) => (
                  <TableRow
                    key={Fournisseur.IDPErpers}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {Fournisseur.Nom}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Commande min
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Frais de livraison
                    </TableCell>

                    <TableCell
                      onClick={() => handleOpen(Fournisseur)}
                      align="left"
                      className="Details"
                    >
                      Modifier
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedFournisseur && (
          <ModalUpdateFournisseurData
            open={open}
            handleClose={handleClose}
            DataFournisseur={selectedFournisseur}
          />
        )}
      </div>
    </>
  );
}
