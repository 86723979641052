import React, { useState, useEffect } from "react";
import { getEtatDesStockPrepAuRestaurant } from "../../../Data/firebaseService";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ModalJetterPrep from "../../Modal/ModalJetterPrep/ModalJetterPrep";
import ModalRefairePrep from "../../Modal/ModalRefairePrep/ModalRefairePrep";
import "./VisibilityStockPrep.css";

const VisibilityStockPrep = () => {
  const [StockInRestauarant, setStockInRestauarant] = useState(null);
  const [SelectedCategorie, setSelectedCategorie] = useState("ALL");
  const [AllCategorie, setAllCategorie] = useState([
    "Mise a disposition",
    "Preparation",
    "Decoupe formatage",
  ]);
  const [selectedPrep, setSelectedPrep] = useState(null);
  const [open, setOpen] = useState(false);
  const [SelectedPrepForJetter, setSelectedPrepForJetter] = useState(null);
  const [openJetterPrep, setOpenJetterPrep] = useState(false);

  useEffect(() => {
    async function fetchUserBrand() {
      const Stock = await getEtatDesStockPrepAuRestaurant();
      setStockInRestauarant(Stock);
    }
    fetchUserBrand();
  }, []);

  const HandleOpen = (Prep) => {
    setSelectedPrep(Prep);
    setOpen(true);
  };
  const handleCloseJeterPrep = () => {
    setSelectedPrepForJetter(null);
    openJetterPrep(false);
  };
  const handleClose = () => {
    setSelectedPrep(null);
    setOpen(false);
  };

  const HandleJetterPrep = (Prep) => {
    setSelectedPrepForJetter(Prep);
    setOpenJetterPrep(true);
  };
  console.log(StockInRestauarant);

  return (
    <div>
      <div className="CreateInventaire">
        <div className="TrieMatiere">
          <div className="menuItem1">Recherche</div>
          <div className="menuItem1">
            <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
              <InputLabel id="select-unite-Preparation">
                Selection fournisseur
              </InputLabel>
              <Select
                labelId="select-Fournisseeur"
                id="select-Fournisseeur"
                value={SelectedCategorie}
                onChange={(e) => setSelectedCategorie(e.target.value)}
              >
                {AllCategorie != null &&
                  AllCategorie.map((Categorie) => (
                    <MenuItem key={Categorie} value={Categorie}>
                      {Categorie}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {StockInRestauarant &&
          StockInRestauarant.map((Prep, index) => {
            return SelectedCategorie === "ALL" ? (
              <div
                className={
                  Prep.DataPrep.Statut === "Inactif" ? "RowPrep" : "RowMatiere"
                }
              >
                <div>{Prep.DataPrep.Nom}</div>
                <div>
                  <Avatar
                    alt={Prep.DataPrep.Nom}
                    src={Prep.DataPrep.PhotoFirebase}
                  />
                </div>
                <div>{Prep.DataPrep.Categorie}</div>
                <div>
                  {Prep.QteEnStock} {Prep.DataPrep.Unite}
                </div>
                <div
                  className="link-refaire"
                  onClick={() => HandleOpen(Prep.DataPrep)}
                >
                  Refaire
                </div>
                <div
                  className="link-refaire"
                  onClick={() => HandleJetterPrep(Prep.DataPrep)}
                >
                  ---- Jetter
                </div>
              </div>
            ) : (
              Prep.DataPrep.Categorie === SelectedCategorie && (
                <div
                  className={
                    Prep.DataPrep.Statut === "Inactif"
                      ? "RowPrep"
                      : "RowMatiere"
                  }
                >
                  <div>{Prep.DataPrep.Nom}</div>
                  <div>
                    <Avatar
                      alt={Prep.DataPrep.Nom}
                      src={Prep.DataPrep.PhotoFirebase}
                    />
                  </div>
                  <div>{Prep.DataPrep.Categorie}</div>
                  <div>
                    {Prep.QteEnStock} {Prep.DataPrep.Unite}
                  </div>
                  <div
                    className="link-refaire"
                    onClick={() => HandleOpen(Prep)}
                  >
                    Refaire
                  </div>
                  <div
                    className="link-refaire"
                    onClick={() => HandleJetterPrep(Prep)}
                  >
                    ---- Jetter
                  </div>
                </div>
              )
            );
          })}
      </div>
      {selectedPrep && (
        <ModalRefairePrep
          open={open}
          handleClose={handleClose}
          Prep={selectedPrep}
        />
      )}
      {SelectedPrepForJetter && (
        <ModalJetterPrep
          open={openJetterPrep}
          handleClose={handleCloseJeterPrep}
          Prep={SelectedPrepForJetter}
        />
      )}
    </div>
  );
};

export default VisibilityStockPrep;
