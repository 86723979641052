import React, { useState, useEffect } from "react";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import TextField from "@material-ui/core/TextField";
import {
  HandleAddPrepToPlanDeTravail,
  getAllPrep,
} from "../../../../Data/firebaseInteractionOwnerV2";

const AddPrepToPlanDeTravail = ({ RestaurantName, PlanDeTravailID }) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    RestaurantName: RestaurantName,
    PlanDeTravailID: PlanDeTravailID,
  });
  const [AllPrep, setAllPrep] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const Data = await getAllPrep();
      setAllPrep(Data);
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        ADD PREP
      </div>
      <ModalAvecFunction
        open={OpenModal}
        handleClose={handleCloseModal}
        Form={
          <div>
            {AllPrep &&
              AllPrep.map((prep) => {
                return (
                  <div
                    style={
                      DataForm.hasOwnProperty("ListePrep") &&
                      DataForm.ListePrep.includes(prep.IDPepers)
                        ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            boxShadow: "0px 0px 10px 0px #c68787",
                          }
                        : {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            boxShadow: "0px 0px 10px 0px #000000",
                          }
                    }
                    onClick={() =>
                      setDataForm((prev) => {
                        let newListePrep = [];
                        if (prev.hasOwnProperty("ListePrep")) {
                          newListePrep = [...prev.ListePrep, prep.IDPepers];
                        } else {
                          newListePrep = [prep.IDPepers];
                        }
                        return { ...prev, ListePrep: newListePrep };
                      })
                    }
                  >
                    {prep.name}
                  </div>
                );
              })}
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter une preparation au plan de travail"}
        DataForm={{ ...DataForm }}
        Function={HandleAddPrepToPlanDeTravail}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default AddPrepToPlanDeTravail;
