import React, { useState } from "react";
import AddOption from "./AddOption/AddOption";
import NavBarHorisontale from "../../NavBar/NavBarHorisontale/NavBarHorisontale";
import DisplayOptionDetails from "./DisplayOptionDetails/DisplayOptionDetails";

const OptionManagement = () => {
  const [navRow, setNavRow] = useState(0);
  return (
    <div>
      <NavBarHorisontale
        Data={["AddOption", "Manage Option"]}
        setNavRow={setNavRow}
        navRow={navRow}
      />
      {navRow === 0 && <AddOption />}
      {navRow === 1 && <DisplayOptionDetails />}
    </div>
  );
};

export default OptionManagement;
