import React, { useEffect, useState } from "react";
import { GetAnalyseConssomationPrep } from "../../../../Data/firebaseService";
import ModaldisplayPrepRefait from "../../../Modal/ModaldisplayPrepRefait/ModaldisplayPrepRefait";

import "./GestionPrep.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const GestionPrep = ({ startDate, endDate }) => {
  const [Data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [SelectedPrep, setSelectedPrep] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let eDate = endDate !== "" ? new Date(endDate) : new Date();
      eDate.setHours(23);
      eDate.setMinutes(59);
      let sDate = new Date(startDate);
      sDate.setHours(0);
      sDate.setMinutes(1);
      const Data = await GetAnalyseConssomationPrep(sDate, eDate);

      setData(Data);
    };
    fetchData();
  }, [startDate, endDate]);

  const HandleOpen = (PrepName) => {
    const PrepRefait = Data.AllPrepRefaitEntre2Dates.filter(
      (item) => item.PrepData.Nom === PrepName
    );
    setOpen(true);
    setSelectedPrep(PrepRefait);
  };

  const HandleClose = () => {
    setOpen(false);
    setSelectedPrep(null);
  };

  return (
    <div className="contener">
      {/*Data &&
        Data.AllPrepRefaitEntre2Dates.map((item, index) => {
          return (
            <div className="Row">
              <div>{new Date(item.date.seconds * 1000).toDateString()}</div>
              <div>{item.PrepData.Nom}</div>
              <div>{item.QteRefait}</div>
              <div>{item.PrepData.Unite}</div>
            </div>
          );
        })*/}

      {Data &&
        Object.keys(Data.QtePrepRefaiteParPrep).map((item, index) => {
          return (
            <div className="Row" onClick={() => HandleOpen(item)}>
              <div>{item}</div>
              <div>{Data.QtePrepRefaiteParPrep[item]}</div>
            </div>
          );
        })}
      {SelectedPrep && (
        <ModaldisplayPrepRefait
          open={open}
          handleClose={HandleClose}
          DataPrepRefait={SelectedPrep}
        />
      )}
    </div>
  );
};
export default GestionPrep;
