import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../../../../firebaseConfig";

import { GetListProductCanBeMadeInThePosteDeTravail } from "../../../../../../../../Data/firebaseInteractionOwnerV2";
import { Button, CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";

const DisplayDishPossibleToDoInPlanDeTravail = ({
  PlanDeTravailID,
  RestaurantName,
}) => {
  const [DataProductCanBeMade, setDataProductCanBeMade] = useState(null);

  const [PlanDeTravail] = useCollection(
    collection(getFirestore(app), `${RestaurantName}PlanDeTravail`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      const Data = await GetListProductCanBeMadeInThePosteDeTravail(
        PlanDeTravailID,
        RestaurantName
      );
      setDataProductCanBeMade(Data);
    };
    fetchData();
  }, [PlanDeTravail, PlanDeTravailID]);

  useEffect(() => {
    setDataProductCanBeMade(null);
  }, [PlanDeTravailID]);

  return (
    <div style={{ height: "180px", overflowY: "auto" }}>
      {DataProductCanBeMade ? (
        DataProductCanBeMade.map((product) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                padding: "10px",
                borderRadius: "5px",
                backgroundColor: "yellow",
                margin: "5px",
              }}
            >
              <div>{product.Production.name}</div>
              <Avatar
                alt={product.Production.name}
                src={product.Production.img}
                sizes="small"
              />
            </div>
          );
        })
      ) : (
        <CircularProgress size={24} color="secondary" />
      )}
    </div>
  );
};

export default DisplayDishPossibleToDoInPlanDeTravail;
