import React from "react";
import MainDashOwner from "../../components/MainDash/MainDashOwner/MainDashOwner";
import RightSideCreationProduit from "../../components/RightSide/RightSideCreationProduit/RightSideCreationProduit";

const CreationProduct = () => {
  return (
    <>
      <MainDashOwner />
      <RightSideCreationProduit />
    </>
  );
};

export default CreationProduct;
