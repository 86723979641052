import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./UpdatesOwnerKitchen.css";

import { getWorkplanData } from "../../../Data/firebaseService";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import ButtonCreateWorkplan from "../../../components/Button/ButtonCreateWorkplan/ButtonCreateWorkplan";
import ButtonCreateWorkplanWithListOfProduct from "../../../components/Button/ButtonCreateWorkplanWithListOfProduct/ButtonCreateWorkplanWithListOfProduct";
import ButtonUpdateWorkplanWithListOfProduct from "../../../components/Button/ButtonUpdateWorkplanWithListOfProduct/ButtonUpdateWorkplanWithListOfProduct";

const UpdatesOwnerKitchen = () => {
  const [data, setData] = useState(["Loading"]);

  const [PosteDeTravail] = useCollection(
    collection(getFirestore(app), "PosteDeTravail"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchData() {
      const data = await getWorkplanData();
      setData(data);
    }
    fetchData();
  }, [PosteDeTravail]);

  return (
    <div className="Updates">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Poste de travail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonCreateWorkplan />
      <ButtonCreateWorkplanWithListOfProduct />
      <ButtonUpdateWorkplanWithListOfProduct />
    </div>
  );
};

export default UpdatesOwnerKitchen;
