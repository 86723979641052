import { UilEstate, UilPackage } from "@iconscout/react-unicons";

export const SidebarDataOwnerV2 = [
  {
    icon: UilEstate,
    heading: "Production",
  },
  {
    icon: UilEstate,
    heading: "Les restaurants",
  },
];
