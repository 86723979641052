import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { selectOrderMode } from "../../atoms/SelectOrderModeAtome";

const SelectionDate = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [DateRange, setDateRange] = useRecoilState(selectOrderMode);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    setDateRange({
      view: "Deliveroo",
      dateSatart: new Date(event.target.value),
      dateEnd: new Date(),
    });
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  return (
    <div>
      <Input
        placeholder="Start Date and Time"
        size="md"
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
      />
      {/*<Input
          placeholder="End Date and Time"
          size="md"
          type="date"
          value={endDate}
            onChange={handleEndDateChange}
    />*/}
    </div>
  );
};

export default SelectionDate;
