import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableMatierePremiere.css";

import {
  getAllDish,
  getListeMatireForPreparation,
  getMatierePremiere,
} from "../../../Data/firebaseService";
import { SetIngredientPrixURecetteHT } from "../../../Functions/HandleClickFunctions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import ModalModificationMatierePremiere from "../../../components/Modal/ModalModificationMatierePremiere/ModalModificationMatierePremiere";

import { useRecoilState } from "recoil";
import { CreationProductAtom } from "../../../atoms/CreationProductAtom";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Cancel") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function TableMatierePremiere() {
  const [user, loading, error] = useAuthState(auth);
  const [AllMatierePremiere, setAllMatierePremiere] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedMatiere, setSelectedMatiere] = useState(null);

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const AllMatierePremiere = await getMatierePremiere();
      setAllMatierePremiere(AllMatierePremiere);
    }
    fetchOrders();
  }, [Production]);

  const handleOpen = (Matiere) => {
    setSelectedMatiere(Matiere);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedMatiere(null);
    setOpen(false);
  };

  return (
    <>
      <h3>Liste des produits</h3>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Matiére</TableCell>
                <TableCell align="left">Fournisseur</TableCell>
                <TableCell align="left">Qte</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {AllMatierePremiere != null &&
                AllMatierePremiere.map((Matiere) => (
                  <TableRow
                    key={Matiere.Nom}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {Matiere.Nom}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {Matiere.Fournisseur}
                    </TableCell>

                    <TableCell
                      onClick={() => handleOpen(Matiere)}
                      align="left"
                      className="Details"
                    >
                      Modifier
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedMatiere && (
          <ModalModificationMatierePremiere
            open={open}
            handleClose={handleClose}
            Matiere={selectedMatiere}
          />
        )}
      </div>
    </>
  );
}
