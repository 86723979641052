import React, { useState, useEffect } from "react";
import "./DisplayFicheTechniqueVisitorV2.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import {
  getPrepDataForDishSelected,
  SupprimerPrepInProduct,
} from "../../../Data/firebaseInteractionVisitorsV2";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";
import { Modal } from "@mui/material";
import ModalAvecFunction from "../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { ModifyQtePrepInDish } from "../../../Data/firebaseInteractionVisitorsV2";

const DisplayFicheTechniqueVisitorV2 = ({ ProductSelected }) => {
  const [PrepInSelectedDish, setPrepInSelectedDish] = useState(null);
  const [PrepSelected, setPrepSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [DataForm, setDataForm] = useState({
    ProductSelected: ProductSelected,
    PrepSelected: PrepSelected,
  });

  const [dishes] = useCollection(collection(getFirestore(app), "dishes"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      let PrepInSelectedDish = null;
      if (ProductSelected) {
        PrepInSelectedDish = await getPrepDataForDishSelected(ProductSelected);
      }
      setPrepInSelectedDish(PrepInSelectedDish);
      setDataForm((prevData) => ({
        ...prevData,
        ProductSelected: ProductSelected,
      }));
    }
    fetchOrders();
  }, [ProductSelected, dishes]);

  const handleOpen = (prep) => {
    setPrepSelected(prep);
    setDataForm((prevData) => ({
      ...prevData,
      PrepSelected: prep,
      QtePrepDansProduit: prep.QteInTheProduct,
    }));
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setPrepSelected(null);
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const HandleSupprimerPrepInProduct = (Prep, ProductSelected) => {
    if (isProcessing) return;
    setIsProcessing(true);
    SupprimerPrepInProduct(Prep, ProductSelected);
    setIsProcessing(false);
  };

  return (
    <div className="ContenerDisplayFiche">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Preparation</TableCell>
              <TableCell align="left">Unité de mesure</TableCell>
              <TableCell align="left">Qte</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {PrepInSelectedDish &&
              PrepInSelectedDish.map((Prep) => (
                <TableRow
                  key={Prep.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {Prep.name}
                  </TableCell>
                  <TableCell align="left">{Prep.UniteDeMesure}</TableCell>
                  <TableCell align="left">
                    {Prep.QteInTheProduct}{" "}
                    <span className="Details" onClick={() => handleOpen(Prep)}>
                      Mofidier Qte
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Avatar alt={Prep.name} src={Prep.img} />
                  </TableCell>

                  <TableCell
                    align="left"
                    className="Details"
                    onClick={() =>
                      HandleSupprimerPrepInProduct(Prep, ProductSelected)
                    }
                  >
                    Supprimer
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {PrepSelected && (
        <ModalAvecFunction
          open={isOpen}
          handleClose={handleClose}
          Form={
            <div>
              <TextField
                id="outlined-basic"
                style={{ width: "50px" }}
                value={DataForm.QtePrepDansProduit}
                onChange={(event) =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    QtePrepDansProduit: event.target.value,
                  }))
                }
              />
              {PrepSelected.UniteDeMesure}
            </div>
          }
          TextActionButton={"Modifier la Qte"}
          TitleModal={`Modifier la qte de : ${PrepSelected.name}`}
          SousParagraphe={
            <div>
              <div></div>
              <div>
                <img style={{ maxWidth: "300px" }} src={PrepSelected.img}></img>
              </div>
            </div>
          }
          DataForm={DataForm}
          setDataForm={setDataForm}
          Function={ModifyQtePrepInDish}
        />
      )}
    </div>
  );
};

export default DisplayFicheTechniqueVisitorV2;
