import React, { useState } from "react";
import "./GestionMatieresAndIngredients.css";
import BigSelectorIngredient from "../BigSelectorIngredient/BigSelectorIngredient";
import ModifictaionIngredient from "../ModifictaionIngredient/ModifictaionIngredient";

const GestionMatieresAndIngredients = () => {
  const [Selected, SetSelected] = useState(null);
  return (
    <div className="ContenerGestionMatieresAndIngredients">
      <ModifictaionIngredient Selected={Selected} />
      <BigSelectorIngredient SetSelected={SetSelected} Selected={Selected} />
    </div>
  );
};

export default GestionMatieresAndIngredients;
