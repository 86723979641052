import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
  Textarea,
} from "@mui/material";
import "./ModaldisplayDetailMatiereOrder.css";

export default function ModaldisplayDetailMatiereOrder({
  open,
  handleClose,
  DataOrderMatiere,
}) {
  console.log(DataOrderMatiere);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ fontSize: "35px" }}>
          Cout total commande : {DataOrderMatiere.CoutTotal} €
        </div>
        {DataOrderMatiere.ListeFournisseurOrdered.map((Fournisseur) => {
          const ProductOrderedOfTheFournisseur =
            DataOrderMatiere.DataOrder.filter(
              (Product) =>
                Product.DataMatiere.Fournisseur === Fournisseur.fournisseurName
            );

          return (
            <div style={{ fontSize: "25px" }}>
              Fournisseur : {Fournisseur.fournisseurName}
              {ProductOrderedOfTheFournisseur.map((product) => {
                return (
                  <div
                    style={{
                      fontSize: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {" "}
                    <div>{product.DataMatiere.Nom}</div>
                    <div>-Quantité Commandé: {product.Qte}</div>
                    <div>-Quantité Recu: {product.QteRecived}</div>
                    <div>-{product.DataMatiere.Conditionnement}</div>
                    <div>
                      -Prix/ conditionnement:
                      {product.DataMatiere["Prix HT"]}
                    </div>
                    <div>
                      {" "}
                      - Prix TOTAL :{" "}
                      {product.Qte *
                        parseFloat(
                          product.DataMatiere["Prix HT"].replace(",", ".")
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Box>
    </Modal>
  );
}
