import React, { useState, useEffect } from "react";
import DisplayFicheTechinique from "../../components/CuisinierV2Components/DisplayFicheTechinique/DisplayFicheTechinique";
import { GetProductInTheWorkPlace } from "../../Data/firebaseInteractionCuisinierV2";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../firebaseConfig";

const FichetechniqueView = ({ WorkplaceID, RestaurantName }) => {
  const [ListeProducInTheWorkPlace, setListeProducInTheWorkPlace] =
    useState(null);

  const [RestoWorkplace] = useCollection(
    collection(getFirestore(app), `${RestaurantName}PlanDeTravail`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetProductInTheWorkPlace(WorkplaceID, RestaurantName);
      setListeProducInTheWorkPlace(data);
    };
    fetchData();
  }, [RestoWorkplace]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        overflow: "auto",
      }}
    >
      {ListeProducInTheWorkPlace &&
        ListeProducInTheWorkPlace.map((productToDoInTheWorkplace) => {
          return (
            <DisplayFicheTechinique
              ProductFormatedData={productToDoInTheWorkplace}
            />
          );
        })}
    </div>
  );
};

export default FichetechniqueView;
