import React from "react";
import "./RigthSideOwnerUserData.css";
import ListeUserBrand from "../../Updates/ListeUserBrand/ListeUserBrand";
import ButtonCalculateWallet from "../../Button/ButtonCalculateWallet/ButtonCalculateWallet";

const RigthSideOwnerUserData = ({
  SelectedBrand,
  setSelectedBrand,
  setWalletData,
}) => {
  return (
    <div className="RightSide">
      <div>
        <h3>Brands</h3>
        <ListeUserBrand
          SelectedBrand={SelectedBrand}
          setSelectedBrand={setSelectedBrand}
        />
      </div>
      <div>
        <ButtonCalculateWallet
          setWalletData={setWalletData}
          Brand={SelectedBrand}
        />
      </div>
    </div>
  );
};

export default RigthSideOwnerUserData;
