import React, { useState, useEffect } from "react";
import "./MainDashFichesTechinique.css";
import TableCreationProduct from "../../../components/Table/TableCreationProduct/TableCreationProduct";
import { auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserInfo } from "../../../Data/firebaseGetUserInfo";
import FichesTechnique from "../../../components/FichesTechnique/FichesTechnique";

import { useRecoilState } from "recoil";
import { CreationProductAtom } from "../../../atoms/CreationProductAtom";

const MainDashFichesTechinique = () => {
  const [user, loading, error] = useAuthState(auth);
  const [Brand, setBrand] = useState("Loading...");
  const [ProductAtom, setProductAtom] = useRecoilState(CreationProductAtom);

  useEffect(() => {
    async function fetchUserBrand() {
      const Userdata = await getUserInfo(user.uid);
      setBrand(Userdata.OwnerBrand);
    }
    fetchUserBrand();
  }, [user]);

  return (
    <div className="MainDash">
      <h1>Fiches Technique</h1>
      <FichesTechnique ProductName={ProductAtom.productSelected} />
      {/*<TableCreationProduct />*/}
    </div>
  );
};

export default MainDashFichesTechinique;
