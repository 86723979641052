import {
  getFirestore,
  doc,
  collection,
  getDocs,
  getDoc,
  deleteDoc,
  setDoc,
  addDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { app, firestore, storage, database } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { m } from "framer-motion";
import { el, fi } from "date-fns/locale";

export async function CalculPrixHTParUniteDeMesure(ListePrepObject) {
  try {
    let AllNewListePrepObject = [];

    for (const Prep of ListePrepObject) {
      let CoutHT = 0;

      // Créer un tableau de promesses pour chaque appel asynchrone
      const promises = Prep.MatierePremiereUtilisees.map(async (Ingredient) => {
        const IngredientDocRef = doc(
          firestore,
          "Ingredients",
          Ingredient.IDIngredient
        );
        const IngredientSnapshot = await getDoc(IngredientDocRef);

        if (IngredientSnapshot.exists()) {
          const IngredientData = IngredientSnapshot.data();
          const RefMatiereDocRef = doc(
            firestore,
            "ReferenceMatierePremiere",
            IngredientData.IdREFfournisseurParDefault
          );
          const RefMatiereSnapshot = await getDoc(RefMatiereDocRef);
          if (RefMatiereSnapshot.exists()) {
            const REFDataMatiere = RefMatiereSnapshot.data();
            CoutHT += REFDataMatiere.PrixURecetteHT * Ingredient.Qte;
          } else {
            console.log("RefMatiereSnapshot does not exist");
            throw new Error("RefMatiereSnapshot does not exist");
          }
        } else {
          console.log("IngredientSnapshot does not exist");
          throw new Error("IngredientSnapshot does not exist");
        }
      });

      // Attendre que toutes les promesses se terminent
      await Promise.all(promises);

      let PrixHTParUniteDeMesure = CoutHT / Prep.QteFinalePrep;

      AllNewListePrepObject.push({
        ...Prep,
        PrixHTParUniteDeMesure: parseFloat(PrixHTParUniteDeMesure.toFixed(6)),
      });
    }

    return AllNewListePrepObject;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}
export async function calculCoutProductionDish(ListeDishObject) {
  try {
    let AllNewListeDishObject = [];

    for (const Dish of ListeDishObject) {
      let CoutHT = 0;

      // Créer un tableau de promesses pour chaque appel asynchrone
      const promises = Dish.Production.PreparationIntermediaire.map(
        async (PrepUSed) => {
          const PrepDocRef = doc(firestore, "preparations", PrepUSed.IDPrep);
          const PrepSnapshot = await getDoc(PrepDocRef);
          if (PrepSnapshot.exists()) {
            const PrepData = PrepSnapshot.data();
            CoutHT += PrepData.PrixHTParUniteDeMesure * PrepUSed.Qte;
          } else {
            console.log("IngredientSnapshot does not exist");
            throw new Error("IngredientSnapshot does not exist");
          }
        }
      );

      // Attendre que toutes les promesses se terminent
      await Promise.all(promises);

      AllNewListeDishObject.push({
        ...Dish,
        CoutRevientHTVBB: parseFloat(CoutHT.toFixed(6)),
      });
    }

    return AllNewListeDishObject;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}

export function CalculCoutDeRevientDishPourClient(ListeOfProduct, AllPrepData) {
  let NewListeOfProductWithCoutRevientClient = [];
  ListeOfProduct.forEach((product) => {
    let CoutRevient = 0;
    product.Production.PreparationIntermediaire.forEach((prep) => {
      const prepData = AllPrepData.filter(
        (prepObject) => prepObject.IDPErpers === prep.IDPErpers
      );
      if (prepData.length > 0) {
        CoutRevient +=
          (parseFloat(prepData[0].CoutHT.replace(",", ".")) /
            parseFloat(prepData[0].Quantite.replace(",", "."))) *
          prep.Qte *
          2.5;
      }
    });

    NewListeOfProductWithCoutRevientClient.push({
      ...product,
      CoutRevientClient: CoutRevient.toFixed(2),
    });
  });
  return NewListeOfProductWithCoutRevientClient;
}

export async function getVisitorProduct(userID) {
  const UserDocRef = doc(firestore, "users", userID);
  const UserSnapshot = await getDoc(UserDocRef);
  const UserData = UserSnapshot.data();

  let listeProduitClient = [];
  if (UserData.hasOwnProperty("ListeOfProductOwner")) {
    listeProduitClient = UserData.ListeOfProductOwner;
  }

  try {
    const documents = [];
    for (const id of listeProduitClient) {
      const docRef = doc(firestore, "dishes", id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        // On regarde si le produit est en ligne (actif) dans un des restaurants
        const querySnapshot = await getDocs(
          collection(firestore, "AllRestaurant")
        );
        const AllRestaurant = [];
        querySnapshot.forEach((doc) => {
          AllRestaurant.push({ ...doc.data() });
        });
        for (const Restaurant of AllRestaurant) {
          const docRefActifProd = doc(
            firestore,
            Restaurant.name,
            "ActifProductInTheRestaurant"
          );
          const RestoSnapshoot = await getDoc(docRefActifProd);
          if (RestoSnapshoot.exists()) {
            // On calcule le cout de revient pour le client du produit en question
            let DatacalculCoutProductionDish = await calculCoutProductionDish([
              { ...docSnapshot.data() },
            ]);

            let CoutRevientClient = (
              DatacalculCoutProductionDish[0].CoutRevientHTVBB * 2.5
            ).toFixed(4);

            if (RestoSnapshoot.data().ActiveProduct.includes(id)) {
              documents.push({
                ...docSnapshot.data(),
                CoutRevientClient,
                Actif: true,
              });
            } else {
              documents.push({
                ...docSnapshot.data(),
                CoutRevientClient,
                Actif: false,
              });
            }
          }
        }
      } else {
        console.warn(`Document with ID ${id} does not exist.`);
      }
    }
    return documents;
  } catch (error) {
    console.error("Error fetching documents:", error);
    return [];
  }

  {
    /*const AllVisitorProductWithCoutDeRevient = CalculCoutDeRevientDishPourClient(
    AllVisitorProduct,
    AllPrep
  );*/
  }

  return null;
}

export async function SupprimerProduit(ProductSelected) {
  try {
    const querySnapshot = await getDocs(collection(firestore, "AllRestaurant"));
    const AllRestaurant = [];
    querySnapshot.forEach((doc) => {
      AllRestaurant.push({ id: doc.id, ...doc.data() });
    });
    let AllActiveProductInAllRestaurant = [];
    for (const Restaurant of AllRestaurant) {
      const docRefActifProd = doc(
        firestore,
        Restaurant.id,
        "ActifProductInTheRestaurant"
      );
      const RestoSnapshoot = await getDoc(docRefActifProd);
      if (RestoSnapshoot.exists()) {
        AllActiveProductInAllRestaurant = [
          ...AllActiveProductInAllRestaurant,
          ...RestoSnapshoot.data().ActiveProduct,
        ];
      }
    }

    if (
      AllActiveProductInAllRestaurant.includes(
        ProductSelected.Production.IDPepers
      )
    ) {
      alert("Le produit ne peux pas etre supprimer car il est en ligne");
      throw "Le produit ne peux pas etre supprimer car il est en ligne";
    }

    const docRef = doc(
      firestore,
      "dishes",
      ProductSelected.Production.IDPepers
    );

    const userDoc = doc(
      firestore,
      "users",
      ProductSelected.Production.UserIdOwner
    );
    const userDocSnapshot = await getDoc(userDoc);

    if (userDocSnapshot.data().hasOwnProperty("ListeOfProductOwner")) {
      let ListeOfProductOwner = userDocSnapshot
        .data()
        .ListeOfProductOwner.filter(
          (id) => id !== ProductSelected.Production.IDPepers
        );
      await updateDoc(userDoc, { ListeOfProductOwner });
    }
    // On regarde checune des preprations et on retire le produits dans la liste dish where the product is used de la prepration
    const dishSnapshoot = await getDoc(docRef);
    if (dishSnapshoot.exists()) {
      for (const PrepUsed of dishSnapshoot.data().Production
        .PreparationIntermediaire) {
        const PrepDocRef = doc(firestore, "preparations", PrepUsed.IDPrep);
        const prepSnapshoot = await getDoc(PrepDocRef);
        if (prepSnapshoot.exists()) {
          if (prepSnapshoot.data().hasOwnProperty("ListeDishWherePrepIsUsed")) {
            const ListeDishWherePrepIsUsed = prepSnapshoot
              .data()
              .ListeDishWherePrepIsUsed.filter(
                (dishID) => dishID !== dishSnapshoot.data().Production.IDPepers
              );
            await updateDoc(PrepDocRef, {
              ListeDishWherePrepIsUsed: ListeDishWherePrepIsUsed,
            });
          }
        }
      }
    }

    await deleteDoc(docRef);
    console.log("Document deleted successfully");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
}

export async function addNewProductVisitorV2(Data) {
  try {
    const IDPepers = uuidv4();
    const collectionRef = doc(firestore, "dishes", IDPepers);

    let sellingPrice = Data.SellingPrice.replace(",", ".");
    let FormatedSellingPrice = parseFloat(Data.SellingPrice.replace(",", "."));

    if (isNaN(FormatedSellingPrice) || !/^\d*\.?\d*$/.test(sellingPrice)) {
      alert("La conversion n'a pas réussi. Ce n'est pas un nombre valide.");
      throw new Error(
        "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
      );
    }

    const userDoc = doc(firestore, "users", Data.UserIdOwner);
    const userDocSnapshot = await getDoc(userDoc);
    let ListeOfProductOwner = [];
    if (userDocSnapshot.data().hasOwnProperty("ListeOfProductOwner")) {
      ListeOfProductOwner = [
        ...userDocSnapshot.data().ListeOfProductOwner,
        IDPepers,
      ];
    } else {
      ListeOfProductOwner = [IDPepers];
    }
    await updateDoc(userDoc, { ListeOfProductOwner });

    await setDoc(collectionRef, {
      Production: {
        ...Data,
        SellingPrice: FormatedSellingPrice,
        IDPepers,
        PreparationIntermediaire: [],
        ProductCategories: "Normal",
        img: "",
        dateCreation: new Date(),
      },
    });
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function getPrepDataForDishSelected(ProductSelected) {
  try {
    const docRef = doc(
      firestore,
      "dishes",
      ProductSelected.Production.IDPepers
    );
    const docSnapshot = await getDoc(docRef);
    let DishPreparationIntermediaire = [];
    if (docSnapshot.exists()) {
      DishPreparationIntermediaire =
        docSnapshot.data().Production.PreparationIntermediaire;
    } else {
      throw new Error(
        `Document with ID ${ProductSelected.Production.IDPepers} does not exist.`
      );
    }

    const documents = [];
    for (const id of DishPreparationIntermediaire) {
      const docRef = doc(firestore, "preparations", id.IDPrep);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        documents.push({
          ...docSnapshot.data(),
          QteInTheProduct: id.Qte,
        });
      } else {
        console.warn(`Document with ID ${id} does not exist.`);
      }
    }

    return documents;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function getMatiereDataForPreparationSelected(PrepSelected) {
  try {
    const docRef = doc(firestore, "preparations", PrepSelected.IDPepers);
    const docSnapshot = await getDoc(docRef);
    let MatierePremiereUtilisees = [];
    if (docSnapshot.exists()) {
      MatierePremiereUtilisees = docSnapshot.data().MatierePremiereUtilisees;
    } else {
      throw new Error(
        `Document with ID ${PrepSelected.IDPepers} does not exist.`
      );
    }

    const documents = [];
    for (const id of MatierePremiereUtilisees) {
      const docRef = doc(firestore, "Ingredients", id.IDIngredient);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        documents.push({
          ...docSnapshot.data(),
          QteInThePrep: id.Qte,
        });
      } else {
        console.warn(`Document with ID ${id} does not exist.`);
      }
    }

    return documents;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function GetAllPrepToUseInProduct() {
  const querySnapshot = await getDocs(collection(firestore, "preparations"));
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data() });
  });

  return documents;
}

export async function SupprimerPrepInProduct(Prep, ProductSelected) {
  try {
    const docRef = doc(
      firestore,
      "dishes",
      ProductSelected.Production.IDPepers
    );

    const DishSnapShot = await getDoc(docRef);
    const PreparationIntermediaire =
      DishSnapShot.data().Production.PreparationIntermediaire;

    let NewPreparationIntermediaire = PreparationIntermediaire.filter(
      (PrepIntermediaire) => PrepIntermediaire.IDPrep !== Prep.IDPepers
    );

    await updateDoc(docRef, {
      Production: {
        ...DishSnapShot.data().Production,
        PreparationIntermediaire: NewPreparationIntermediaire,
      },
    });

    const PrepRef = doc(firestore, "preparations", Prep.IDPepers);
    const PrepSnapShot = await getDoc(PrepRef);
    const newListeDishWherePrepIsUsed =
      PrepSnapShot.data().ListeDishWherePrepIsUsed.filter(
        (DishWherePrepIsUsed) =>
          DishWherePrepIsUsed !== ProductSelected.Production.IDPepers
      );

    await updateDoc(PrepRef, {
      ListeDishWherePrepIsUsed: newListeDishWherePrepIsUsed,
    });

    console.log("Document deleted successfully");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
}

export async function SupprimerPrep(Prep) {
  try {
    const querySnapshot = await getDocs(collection(firestore, "AllRestaurant"));
    const AllRestaurant = [];
    querySnapshot.forEach((doc) => {
      AllRestaurant.push({ id: doc.id, ...doc.data() });
    });
    let AllActivePrepInAllRestaurant = [];
    for (const Restaurant of AllRestaurant) {
      const docRefActifProd = doc(
        firestore,
        Restaurant.id,
        "ActifProductInTheRestaurant"
      );
      const RestoSnapshoot = await getDoc(docRefActifProd);
      if (RestoSnapshoot.exists()) {
        AllActivePrepInAllRestaurant = [
          ...AllActivePrepInAllRestaurant,
          ...RestoSnapshoot.data().ActivePrep,
        ];
      }
    }

    if (AllActivePrepInAllRestaurant.includes(Prep.IDPepers)) {
      alert(
        "La Preparation ne peux pas etre supprimer car elle est utilisee par un produit en ligne"
      );
      throw "La Preparation ne peux pas etre supprimer car elle est utilisee par un produit en ligne";
    }

    const docRef = doc(firestore, "preparations", Prep.IDPepers);

    const userDoc = doc(firestore, "users", Prep.UserIdOwner);
    const userDocSnapshot = await getDoc(userDoc);

    if (userDocSnapshot.data().hasOwnProperty("ListeOfPreparationOwner")) {
      let ListeOfPreparationOwner = userDocSnapshot
        .data()
        .ListeOfPreparationOwner.filter((id) => id !== Prep.IDPepers);
      await updateDoc(userDoc, { ListeOfPreparationOwner });
    }

    const docRefSnapshot = await getDoc(docRef);
    if (
      docRefSnapshot.exists() &&
      docRefSnapshot.data().hasOwnProperty("ListeDishWherePrepIsUsed")
    ) {
      for (const DishWherePrepIsUsed of docRefSnapshot.data()
        .ListeDishWherePrepIsUsed) {
        const DishdocRef = doc(firestore, "dishes", DishWherePrepIsUsed);
        const DishSnapshot = await getDoc(DishdocRef);
        const DishData = DishSnapshot.data().Production;

        let NewPreparationIntermediaire =
          DishData.PreparationIntermediaire.filter(
            (prep) => prep.IDPrep !== Prep.IDPepers
          );

        await updateDoc(DishdocRef, {
          Production: {
            ...DishData,
            PreparationIntermediaire: NewPreparationIntermediaire,
          },
        });
      }
    }

    await deleteDoc(docRef);
    console.log("Document deleted successfully");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
}

export async function SupprimerMatiereInPrep(Matiere, PrepSelected) {
  try {
    const docRef = doc(firestore, "preparations", PrepSelected.IDPepers);

    const PrepSnapShot = await getDoc(docRef);
    const MatierePremiereUtilisees =
      PrepSnapShot.data().MatierePremiereUtilisees;

    let NewMatierePremiereUtilisees = MatierePremiereUtilisees.filter(
      (MatierePremiere) => MatierePremiere.IDIngredient !== Matiere.IDPepers
    );

    await updateDoc(docRef, {
      MatierePremiereUtilisees: NewMatierePremiereUtilisees,
    });
    console.log("Document deleted successfully");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
}

export async function ModifyQtePrepInDish(Data) {
  try {
    let QtePrepDansProduit = Data.QtePrepDansProduit.replace(",", ".");
    let FormatedQtePrepDansProduit = parseFloat(
      Data.QtePrepDansProduit.replace(",", ".")
    );

    if (
      isNaN(FormatedQtePrepDansProduit) ||
      !/^\d*\.?\d*$/.test(QtePrepDansProduit)
    ) {
      alert("La conversion n'a pas réussi. Ce n'est pas un nombre valide.");
      throw new Error(
        "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
      );
    }

    const DishDocRef = doc(
      firestore,
      "dishes",
      Data.ProductSelected.Production.IDPepers
    );
    const DishSnapshot = await getDoc(DishDocRef);
    const PreparationIntermediaire =
      DishSnapshot.data().Production.PreparationIntermediaire;

    let NewPreparationIntermediaire = [];
    PreparationIntermediaire.forEach((PrepUsed) => {
      if (PrepUsed.IDPrep === Data.PrepSelected.IDPepers) {
        NewPreparationIntermediaire.push({
          ...PrepUsed,
          Qte: parseFloat(Data.QtePrepDansProduit.replace(",", ".")),
        });
      } else {
        NewPreparationIntermediaire.push({
          ...PrepUsed,
        });
      }
    });

    await updateDoc(DishDocRef, {
      Production: {
        ...DishSnapshot.data().Production,
        PreparationIntermediaire: NewPreparationIntermediaire,
      },
    });
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function ModifyQteFinalePrep(Data) {
  try {
    let QteFinalePrep = Data.QteFinalePrep.replace(",", ".");
    let FormatedQteFinalePrep = parseFloat(
      Data.QteFinalePrep.replace(",", ".")
    );

    if (isNaN(FormatedQteFinalePrep) || !/^\d*\.?\d*$/.test(QteFinalePrep)) {
      alert("La conversion n'a pas réussi. Ce n'est pas un nombre valide.");
      throw new Error(
        "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
      );
    }

    const PrepDocRef = doc(
      firestore,
      "preparations",
      Data.prepSelected.IDPepers
    );
    const PrepSnapshot = await getDoc(PrepDocRef);
    const PrepObject = PrepSnapshot.data();

    let calculPrixHTParUniteDeMesure = await CalculPrixHTParUniteDeMesure([
      { ...PrepObject, QteFinalePrep: FormatedQteFinalePrep },
    ]);

    await updateDoc(PrepDocRef, {
      QteFinalePrep: FormatedQteFinalePrep,
      PrixHTParUniteDeMesure:
        calculPrixHTParUniteDeMesure[0].PrixHTParUniteDeMesure,
    });
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function ModifyQteMatiereInPrep(Data) {
  try {
    let QteMatiereDansPrep = Data.QteMatiereDansPrep.replace(",", ".");
    let FormatedQteMatiereDansPrep = parseFloat(
      Data.QteMatiereDansPrep.replace(",", ".")
    );

    if (
      isNaN(FormatedQteMatiereDansPrep) ||
      !/^\d*\.?\d*$/.test(QteMatiereDansPrep)
    ) {
      alert("La conversion n'a pas réussi. Ce n'est pas un nombre valide.");
      throw new Error(
        "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
      );
    }

    const PrepDocRef = doc(
      firestore,
      "preparations",
      Data.PrepSelected.IDPepers
    );
    const PrepSnapshot = await getDoc(PrepDocRef);
    const MatierePremiereUtilisees =
      PrepSnapshot.data().MatierePremiereUtilisees;

    let NewMatierePremiereUtilisees = [];
    MatierePremiereUtilisees.forEach((Matiere) => {
      if (Matiere.IDIngredient === Data.MatiereSelected.IDPepers) {
        NewMatierePremiereUtilisees.push({
          ...Matiere,
          Qte: parseFloat(Data.QteMatiereDansPrep.replace(",", ".")),
        });
      } else {
        NewMatierePremiereUtilisees.push({
          ...Matiere,
        });
      }
    });
    let calculPrixHTParUniteDeMesure = await CalculPrixHTParUniteDeMesure([
      {
        ...PrepSnapshot.data(),
        MatierePremiereUtilisees: NewMatierePremiereUtilisees,
      },
    ]);

    await updateDoc(PrepDocRef, {
      MatierePremiereUtilisees: NewMatierePremiereUtilisees,
      PrixHTParUniteDeMesure:
        calculPrixHTParUniteDeMesure[0].PrixHTParUniteDeMesure,
    });
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function AddPrepToProduct(dish, Prep) {
  try {
    const querySnapshot = await getDocs(collection(firestore, "AllRestaurant"));
    const AllRestaurant = [];
    querySnapshot.forEach((doc) => {
      AllRestaurant.push({ id: doc.id, ...doc.data() });
    });
    let AllActiveProductInAllRestaurant = [];
    for (const Restaurant of AllRestaurant) {
      const docRefActifProd = doc(
        firestore,
        Restaurant.id,
        "ActifProductInTheRestaurant"
      );
      const RestoSnapshoot = await getDoc(docRefActifProd);
      if (RestoSnapshoot.exists()) {
        AllActiveProductInAllRestaurant = [
          ...AllActiveProductInAllRestaurant,
          ...RestoSnapshoot.data().ActiveProduct,
        ];
      }
    }

    if (AllActiveProductInAllRestaurant.includes(dish.Production.IDPepers)) {
      alert(
        "Vous ne pouvez pas ajouter de preparation au produit car il est deja en ligne"
      );
      throw "Vous ne pouvez pas ajouter de preparation au produit car il est deja en ligne";
    }

    const docRef = doc(firestore, "dishes", dish.Production.IDPepers);
    const docSnapshot = await getDoc(docRef);
    const docData = docSnapshot.data().Production;

    if (docSnapshot.exists()) {
      if (
        docData.PreparationIntermediaire.filter(
          (prepUsed) => prepUsed.IDPrep === Prep.IDPepers
        ).length === 0
      ) {
        const NewPreparationIntermediaire = [
          ...docData.PreparationIntermediaire,
          { IDPrep: Prep.IDPepers, Qte: 0 },
        ];
        await updateDoc(docRef, {
          Production: {
            ...docData,
            PreparationIntermediaire: NewPreparationIntermediaire,
          },
        });
      }
    }

    const docPrepRef = doc(firestore, "preparations", Prep.IDPepers);
    const docPrepSnapshot = await getDoc(docPrepRef);
    const PrepData = docPrepSnapshot.data();

    if (docPrepSnapshot.exists()) {
      let NewListeDishWherePrepIsUsed;
      if (PrepData.hasOwnProperty("ListeDishWherePrepIsUsed")) {
        NewListeDishWherePrepIsUsed = [
          ...PrepData.ListeDishWherePrepIsUsed,
          dish.Production.IDPepers,
        ];
      } else {
        NewListeDishWherePrepIsUsed = [dish.Production.IDPepers];
      }
      await updateDoc(docPrepRef, {
        ListeDishWherePrepIsUsed: NewListeDishWherePrepIsUsed,
      });
    }

    console.log("Document Mis a jour successfully");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
}

export async function GetAllIngredientVisitorMode() {
  const querySnapshot = await getDocs(collection(firestore, "Ingredients"));
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data() });
  });

  return documents;
}
export async function AddMatiereToPrep(Matiere, Prep) {
  try {
    const querySnapshot = await getDocs(collection(firestore, "AllRestaurant"));
    const AllRestaurant = [];
    querySnapshot.forEach((doc) => {
      AllRestaurant.push({ id: doc.id, ...doc.data() });
    });
    let AllActivePrepInAllRestaurant = [];
    for (const Restaurant of AllRestaurant) {
      const docRefActifProd = doc(
        firestore,
        Restaurant.id,
        "ActifProductInTheRestaurant"
      );
      const RestoSnapshoot = await getDoc(docRefActifProd);
      if (RestoSnapshoot.exists()) {
        AllActivePrepInAllRestaurant = [
          ...AllActivePrepInAllRestaurant,
          ...RestoSnapshoot.data().ActivePrep,
        ];
      }
    }

    if (AllActivePrepInAllRestaurant.includes(Prep.IDPepers)) {
      alert(
        "Vous ne pouvez pas ajouter de matiere a la prepration car elle est deja en ligne"
      );
      throw "Vous ne pouvez pas ajouter de matiere a la prepration car elle est deja en ligne";
    }

    const docRef = doc(firestore, "preparations", Prep.IDPepers);
    const docSnapshot = await getDoc(docRef);
    const MatierePremiereUtilisees =
      docSnapshot.data().MatierePremiereUtilisees;

    if (docSnapshot.exists()) {
      if (
        MatierePremiereUtilisees.filter(
          (MatiereUsed) => MatiereUsed.IDIngredient === Matiere.IDPepers
        ).length === 0
      ) {
        const NewMatierePremiereUtilisees = [
          ...MatierePremiereUtilisees,
          { IDIngredient: Matiere.IDPepers, Qte: 0 },
        ];
        await updateDoc(docRef, {
          MatierePremiereUtilisees: NewMatierePremiereUtilisees,
        });
      }
    }
    console.log("Document Mis a jour successfully");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
  return null;
}

export async function getVisitorPreparations(userID) {
  const UserDocRef = doc(firestore, "users", userID);
  const UserSnapshot = await getDoc(UserDocRef);
  const UserData = UserSnapshot.data();

  let ListeOfPreparationOwner = [];
  if (UserData.hasOwnProperty("ListeOfPreparationOwner")) {
    ListeOfPreparationOwner = UserData.ListeOfPreparationOwner;
  }

  try {
    const documents = [];
    for (const id of ListeOfPreparationOwner) {
      const docRef = doc(firestore, "preparations", id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        documents.push({
          ...docSnapshot.data(),
        });
      } else {
        console.warn(`Document with ID ${id} does not exist.`);
      }
    }

    return documents;
  } catch (error) {
    console.error("Error fetching documents:", error);
    return [];
  }
}

export async function AddNewUserPreparation(Data) {
  try {
    const IDPepers = uuidv4();
    const collectionRef = doc(firestore, "preparations", IDPepers);

    if (
      Data.hasOwnProperty("UniteDeMesure") &&
      Data.hasOwnProperty("name") &&
      Data.hasOwnProperty("Type")
    ) {
    } else {
      alert("Il manque une informations dans le formulaire");
      throw new Error("Il manque une informations dans le formulaire");
    }

    if (Data.img === null) {
      alert("Il manque une image");
      throw new Error("Il manque une image");
    }

    const imageRef = ref(storage, `Visitor/Preparation/${Data.name}`);
    await uploadString(imageRef, Data.img, "data_url");
    const downloadURL = await getDownloadURL(imageRef);

    const userDoc = doc(firestore, "users", Data.UserIdOwner);
    const userDocSnapshot = await getDoc(userDoc);
    let ListeOfPreparationOwner = [];
    if (userDocSnapshot.data().hasOwnProperty("ListeOfPreparationOwner")) {
      ListeOfPreparationOwner = [
        ...userDocSnapshot.data().ListeOfPreparationOwner,
        IDPepers,
      ];
    } else {
      ListeOfPreparationOwner = [IDPepers];
    }
    await updateDoc(userDoc, { ListeOfPreparationOwner });

    await setDoc(collectionRef, {
      ...Data,
      IDPepers,
      PrixHTParUniteDeMesure: 0,
      QteFinalePrep: 0,
      img: downloadURL,
      UniteDeMesure: Data.UniteDeMesure,
      MatierePremiereUtilisees: [],
    });
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function TranferMatiereData() {
  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  const MatiereVisitorDocRef = doc(
    firestore,
    "Production",
    "MatierePremiereVisitorMode"
  );
  const MatiereVisitorSnapshot = await getDoc(MatiereVisitorDocRef);
  const AllMatiereVisitor = MatiereVisitorSnapshot.data().MatierePremiere;

  const FournisseursDocRef = doc(firestore, "Production", "Fournisseurs");
  const FournisseursSnapshot = await getDoc(FournisseursDocRef);
  const AllFournisseurs = FournisseursSnapshot.data().Fournisseurs;

  let newAllMatiere = [];
  AllMatiere.forEach((matiere) => {
    const fournisseurID = AllFournisseurs.filter(
      (fournisseur) => fournisseur.Nom === matiere.Fournisseur
    )[0].IDPErpers;
    if (
      matiere.QteConditionnement === undefined ||
      matiere.PrixURecetteHT === undefined
    ) {
      console.log(matiere.Nom);
    } else {
      newAllMatiere.push({
        NomDeLaRef: matiere.Nom,
        Ingredient: matiere["Ingrédient"],
        fournisseurID,
        IDPepers: uuidv4(),
        PrixHT: parseFloat(matiere["Prix HT"].replace(",", ".")),
        Conditionnement: matiere.Conditionnement,
        QteConditionnement: parseFloat(
          matiere.QteConditionnement.replace(",", ".")
        ),
        PrixURecetteHT: matiere.PrixURecetteHT,
        UniteDeRecette: matiere["Unité de recettes"],
        img: "",
      });
    }
  });

  await setDoc(MatiereVisitorDocRef, { MatierePremiere: newAllMatiere });
}
export async function addNewMatiereVisitorV2(Data) {
  try {
    const IDPepers = uuidv4();
    const collectionRef = doc(firestore, "ReferenceMatierePremiere", IDPepers);

    if (
      Data.hasOwnProperty("Conditionnement") &&
      Data.hasOwnProperty("FournisseurID") &&
      Data.hasOwnProperty("IngredientID") &&
      Data.hasOwnProperty("NomChezFournisseur") &&
      Data.hasOwnProperty("PrixHT") &&
      Data.hasOwnProperty("QteConditionnement") &&
      Data.hasOwnProperty("REFfournisseur")
    ) {
    } else {
      alert("Il manque une informations dans le formulaire");
      throw new Error("Il manque une informations dans le formulaire");
    }

    let PrixHT = Data.PrixHT.replace(",", ".");
    let FormatedPrixHT = parseFloat(Data.PrixHT.replace(",", "."));
    let QteConditionnement = Data.QteConditionnement.replace(",", ".");
    let FormatedQteConditionnement = parseFloat(
      Data.QteConditionnement.replace(",", ".")
    );

    if (isNaN(FormatedPrixHT) || !/^\d*\.?\d*$/.test(PrixHT)) {
      alert("Le prix n'est pas un nombre valide");
      throw new Error(
        "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
      );
    }

    if (
      isNaN(FormatedQteConditionnement) ||
      !/^\d*\.?\d*$/.test(QteConditionnement)
    ) {
      alert("La quantité n'est pas un nombre valide");
      throw new Error(
        "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
      );
    }
    let img = "";
    if (Data.img) {
      const imageRef = ref(
        storage,
        `Visitor/Matiere/${Data.NomChezFournisseur}`
      );
      await uploadString(imageRef, Data.img, "data_url");
      img = await getDownloadURL(imageRef);
    }

    let PrixURecetteHT = FormatedPrixHT / FormatedQteConditionnement;

    await setDoc(collectionRef, {
      IDPepers,
      NomChezFournisseur: Data.NomChezFournisseur,
      img,
      IngredientID: Data.IngredientID,
      FournisseurID: Data.FournisseurID,
      PrixHT: FormatedPrixHT,
      Conditionnement: Data.Conditionnement,
      QteConditionnement: FormatedQteConditionnement,
      REFfournisseur: Data.REFfournisseur,
      PrixURecetteHT,
    });
    const IngredientRef = doc(firestore, "Ingredients", Data.IngredientID);
    const IngredientSnapshot = await getDoc(IngredientRef);
    const IngredientData = IngredientSnapshot.data();

    if (IngredientData.IdREFfournisseurParDefault === "") {
      await updateDoc(IngredientRef, {
        IdREFfournisseurParDefault: IDPepers,
      });
    }
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function addNewIngredientVisitorV2(Data) {
  try {
    const IDPepers = uuidv4();
    const collectionRef = doc(firestore, "Ingredients", IDPepers);

    if (Data.hasOwnProperty("UniteDeRecette") && Data.hasOwnProperty("name")) {
    } else {
      alert("Il manque une informations dans le formulaire");
      throw new Error("Il manque une informations dans le formulaire");
    }

    if (Data.img === null) {
      alert("Il manque une image");
      throw new Error("Il manque une image");
    }

    const imageRef = ref(storage, `Visitor/Ingredient/${Data.name}`);
    await uploadString(imageRef, Data.img, "data_url");
    const downloadURL = await getDownloadURL(imageRef);

    await setDoc(collectionRef, {
      IDPepers,
      name: Data.name,
      Img: downloadURL,
      UniteDeRecette: Data.UniteDeRecette,
      IdREFfournisseurParDefault: "",
    });
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

{
  /*export async function transferMatiereRefData() {
  const MatiereDocRef = doc(
    firestore,
    "ProductionVisitorV2",
    "ReferenceMatierePremiere"
  );
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().RefMatiere;

  try {
    AllMatiere.forEach(async (Matiere) => {
      const IDPepers = Matiere.IDPepers;
      const collectionRef = doc(
        firestore,
        "ReferenceMatierePremiere",
        IDPepers
      );

      await setDoc(collectionRef, {
        IDPepers,
        NomChezFournisseur: Matiere.NomChezFournisseur,
        IngredientID: Matiere.IngredientID,
        FournisseurID: Matiere.FournisseurID,
        PrixHT: Matiere.PrixHT,
        Conditionnement: Matiere.Conditionnement,
        QteConditionnement: Matiere.QteConditionnement,
        REFfournisseur: Matiere.REFfournisseur,
        PrixURecetteHT: Matiere.PrixURecetteHT,
      });
    });
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}*/
}

export async function AddNewFournisseurVisitor(Data) {
  const FournisseurVisitorDocRef = doc(
    firestore,
    "ProductionVisitorV2",
    "FournisseursVisitor"
  );
  const FournisseurVisitorSnapshot = await getDoc(FournisseurVisitorDocRef);
  const AllFournisseurs = FournisseurVisitorSnapshot.data().Fournisseurs;

  try {
    if (Data.hasOwnProperty("name")) {
    } else {
      alert("Il manque une informations dans le formulaire");
      throw new Error("Il manque une informations dans le formulaire");
    }

    let TestDoublon = AllFournisseurs.filter(
      (Fournisseur) => Fournisseur.name === Data.name
    );

    if (TestDoublon.length > 0) {
      alert("Ce fournisseur existe déjà");
      throw new Error("Cette matiere existe déjà");
    }

    let NewAllFournisseurs = [
      ...AllFournisseurs,
      { name: Data.name, IDPepers: uuidv4() },
    ];

    await setDoc(FournisseurVisitorDocRef, {
      Fournisseurs: NewAllFournisseurs,
    });

    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function getAllIngredient() {
  const querySnapshot = await getDocs(collection(firestore, "Ingredients"));
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data() });
  });

  return documents;
}
export async function getAllFournisseur() {
  const FournisseursDocRef = doc(
    firestore,
    "ProductionVisitorV2",
    "FournisseursVisitor"
  );
  const FournisseursSnapshot = await getDoc(FournisseursDocRef);
  const AllFournisseurs = FournisseursSnapshot.data().Fournisseurs;

  return AllFournisseurs;
}

export async function test(userID) {
  try {
    const IDPepers = uuidv4();
    const collectionRef = doc(
      firestore,
      "dishes",
      "4d7bac85-bc40-45c8-808f-7e28b92f0af5"
    );
    await setDoc(collectionRef, {
      Production: {
        name: "test2",
        PreparationIntermediaire: [],
        ProductCategories: "Normal",
        img: "",
        StateProduct: "Desactive",
        dateCreation: new Date(),
        IDPepers,
      },
    });
    console.log(userID);
  } catch (error) {
    console.error("Error creating document: ", error);
  }
}
