import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ModalCreateNewProductGroup from "../../Modal/ModalCreateNewProductGroup/ModalCreateNewProductGroup";

const ButtonCreateProductGroup = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Creer un nouveau GDP
      </Button>
      <ModalCreateNewProductGroup open={open} handleClose={handleClose} />
    </>
  );
};

export default ButtonCreateProductGroup;
