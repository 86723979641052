import React, { useState } from "react";
import "./ClientAskToPassProductOnline.css";

const ClientAskToPassProductOnline = () => {
  return (
    <div className="">
      <div>ClientAskToPassProductOnline</div>
    </div>
  );
};

export default ClientAskToPassProductOnline;
