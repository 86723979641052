import React, { useState } from "react";
import "./BottomLesRestos.css";
import Modeconssomation from "./Modeconssomation/Modeconssomation";
import ModePlanDeTravail from "./ModePlanDeTravail/ModePlanDeTravail";
import NavBarHorisontale from "../../../NavBar/NavBarHorisontale/NavBarHorisontale";
import AnalyseZelty from "./AnalyseZelty/AnalyseZelty";

const BottomLesRestos = ({ RestaurantName }) => {
  const [navRow, setNavRow] = useState(0);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255)",
        borderRadius: "0.7rem",
        fontSize: "13px",
      }}
    >
      <NavBarHorisontale
        Data={["Mode plan de travail", "Mode conssomation", "Analyse Zelty"]}
        setNavRow={setNavRow}
        navRow={navRow}
      />
      {navRow === 0 && <ModePlanDeTravail RestaurantName={RestaurantName} />} :
      {navRow === 1 && <Modeconssomation />}
      {navRow === 2 && <AnalyseZelty RestaurantName={RestaurantName} />}
    </div>
  );
};

export default BottomLesRestos;
