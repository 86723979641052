import React, { useState, useEffect } from "react";
import CartDissplay from "./CartDissplay/CartDissplay";

const MainAreaProductAndCart = ({ Data }) => {
  const [ProductInCart, setProductInCart] = useState([]);
  console.log(ProductInCart);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "5fr 2fr",
        maxWidth: "1100px",
      }}
    >
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {Data &&
          Data.map((dish) => (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 3fr",
                width: "45%", // Correction : utilisez "width" au lieu de "Width"
                maxWidth: "45%",
                height: "80px",
                border: "1px solid black",
                borderRadius: "5px",
                marginRight: "1%",
                backgroundColor: "white",
                marginBottom: "5px",
                padding: "10px",
              }}
              onClick={() => {
                setProductInCart((prev) => {
                  const listIDDish = prev.map(
                    (dish) => dish.Production.IDPepers
                  );
                  if (!listIDDish.includes(dish.Production.IDPepers)) {
                    return [...prev, { ...dish }];
                  } else {
                    return [...prev];
                  }
                });
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${dish.Production.img})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "100%",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                }}
              >
                <div style={{ wordWrap: "break-word" }}>
                  {dish.Production.name}
                </div>
                <div>{dish.Production.SellingPrice}</div>
              </div>
            </div>
          ))}
      </div>

      <CartDissplay ProductInCart={ProductInCart} />
    </div>
  );
};

export default MainAreaProductAndCart;
