import React from "react";
import "./RefairePrepAuRestaurant.css";
import PrepEnCoursRefaire from "./PrepEnCoursRefaire/PrepEnCoursRefaire";
import AllPrepActifInRestaurant from "./AllPrepActifInRestaurant/AllPrepActifInRestaurant";

const RefairePrepAuRestaurant = ({ RestaurantName }) => {
  return (
    <div className="ContenerRefairePrepAuRestaurant">
      <AllPrepActifInRestaurant RestaurantName={RestaurantName} />
      {/* <PrepEnCoursRefaire RestaurantName={RestaurantName} />*/}
    </div>
  );
};

export default RefairePrepAuRestaurant;
