import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
  Textarea,
} from "@mui/material";
import "./ModalDisplayPrepInOrder.css";
import { GetOrderWherePrepIs } from "../../../Data/firebaseService";

export default function ModalDisplayPrepInOrder({
  open,
  handleClose,
  Orders,
  PrepName,
}) {
  const [OrdersTrie, setOrdersTrie] = useState(null);
  const [SelectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const Data = await GetOrderWherePrepIs(Orders, PrepName);

      setOrdersTrie(Data);
    };
    fetchData();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ fontSize: "35px" }}>{PrepName}</div>
        {OrdersTrie &&
          OrdersTrie.map((Order) => (
            <div
              onClick={() => {
                setSelectedOrder(Order);
                console.log(Order);
              }}
              className={
                SelectedOrder && SelectedOrder.id === Order.id
                  ? "ContenerOrderSelected"
                  : "ContenerOrder"
              }
            >
              {Order.dataOrder.order.created_at}
            </div>
          ))}

        {SelectedOrder && (
          <div className="ContenerProdUsedPrep">
            PROD USED PREP
            {SelectedOrder.ProductWithThePrep.map((prodUsedPrep) => {
              return (
                <div className="RowProdUsedPrep">
                  {prodUsedPrep.produit.name} : {prodUsedPrep.prep.Name} :{" "}
                  {prodUsedPrep.prep.Qte}
                </div>
              );
            })}
          </div>
        )}

        {SelectedOrder && (
          <div className="ContenerProdUsedPrep">
            PROD IN ORDER
            {SelectedOrder.listeProduitCommande.map((prodInOrder) => {
              return <div className="RowProdUsedPrep">{prodInOrder.name}</div>;
            })}
          </div>
        )}
      </Box>
    </Modal>
  );
}
