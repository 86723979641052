import React from "react";
import "./Header.css";
import Button from "@mui/material-next/Button";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import { signOut } from "firebase/auth";

const Header = () => {
  const [user, loading, error] = useAuthState(auth);

  const logout = () => {
    signOut(auth);
    window.location.reload();
  };

  return (
    <div className="header">
      <img className="Img" src="/img/KitchnClikLogo.png" alt="Pepers Logo" />
      <div className="Button">
        <div onClick={logout}>LOG OUT </div>
      </div>
      <div className="Help">
        <div>Besoin d'aide ?</div>
      </div>
      <a
        className="CustomLink"
        href="https://calendly.com/vbbhouse/30min"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="Button">Prendre rendez vous</div>
      </a>
    </div>
  );
};

export default Header;
