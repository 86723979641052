import React from "react";
import "./RightSidePricing.css";
import CustomerReview from "../../CustomerReview/CustomerReview";
import ClientPricing from "../../Updates/Clientpricing/Clientpricing";

const RightSide = () => {
  return (
    <div className="RightSide">
      <div>
        <h3>Facturation</h3>
        <ClientPricing />
      </div>
      <div>
        <h3>Customer Review</h3>
        <CustomerReview />
      </div>
    </div>
  );
};

export default RightSide;
