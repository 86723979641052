import React, { useState } from "react";
import "./MonActivite.css";
import NavBarTwoChoice from "../../../NavBar/NavBarTwoChoice/NavBarTwoChoice";
import ClientAskToPassProductOnline from "./ClientAskToPassProductOnline/ClientAskToPassProductOnline";

const MonActivite = () => {
  const [navChoice, setNavChoice] = useState(0);
  return (
    <div className="">
      <NavBarTwoChoice
        Data={["Passer en ligne des produits", "Programmer un test de produit"]}
        setNavRow={setNavChoice}
        navRow={navChoice}
      />
      {navChoice === 0 && <ClientAskToPassProductOnline />}
      {navChoice === 1 && <div>Programmer un test de produit</div>}
    </div>
  );
};

export default MonActivite;
