import React, { useState, useEffect } from "react";
import "./AllCommandeEnCours.css";
import { TbTrashXFilled } from "react-icons/tb";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

import {
  getAllOrderMatiereEnLivraisonWithFournisseurData,
  SupMatiereOrder,
} from "../../../../Data/firebaseInteractionManagerV2";
import ValiderCommandeMatierePremiere from "../../../OwnerV2Components/Button/ValiderCommandeMatierePremiere/ValiderCommandeMatierePremiere";

const AllCommandeEnCours = ({ RestaurantName }) => {
  const [OrderEnLivraison, setOrderEnLivraison] = useState(null);

  const [Resto] = useCollection(
    collection(getFirestore(app), `${RestaurantName}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchPrep() {
      const Data = await getAllOrderMatiereEnLivraisonWithFournisseurData(
        RestaurantName
      );
      setOrderEnLivraison(Data);
    }
    fetchPrep();
  }, [Resto]);

  const [isProcessingSupMatiereOrder, setIsProcessingSupMatiereOrder] =
    useState(false);
  const HandleSupMatiereOrder = async (RestaurantName, CommandeMatiereID) => {
    if (isProcessingSupMatiereOrder) return;
    setIsProcessingSupMatiereOrder(true);
    await SupMatiereOrder(RestaurantName, CommandeMatiereID);
    setIsProcessingSupMatiereOrder(false);
  };
  return (
    <div className="ContenerAllCommandeEnCours">
      {OrderEnLivraison &&
        OrderEnLivraison.map((order) => {
          const OrderDate = new Date(order.date.seconds * 1000);
          const year = OrderDate.getFullYear();
          const month = String(OrderDate.getMonth() + 1).padStart(2, "0");
          const day = String(OrderDate.getDate()).padStart(2, "0");
          const FormatedDate = `${day}/${month}/${year} - ${OrderDate.getHours()} : ${OrderDate.getMinutes()}`;
          return (
            <div className="ContenerCommandeM">
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/*<div
                  onClick={() =>
                    HandleSupMatiereOrder(order.RestaurantName, order.IDPepers)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <TbTrashXFilled size={25} />
                </div>*/}
                <div>{order.FournisseurData.name}</div>
              </div>
              <div>{FormatedDate}</div>

              <ValiderCommandeMatierePremiere
                RestaurantName={RestaurantName}
                CommandeMatiereID={order.IDPepers}
              />
            </div>
          );
        })}
    </div>
  );
};

export default AllCommandeEnCours;
