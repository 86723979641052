import React, { useState } from "react";
import "./DisplayProductAndPrep.css";
import NavBarHorizontal from "../../NavBar/NavBarHorisontale/NavBarHorisontale";
import DisplayUserProduct from "../DisplayUserProduct/DisplayUserProduct";
import DisplayUserPreparation from "../DisplayUserPreparation/DisplayUserPreparation";
import MonActivite from "./MonActivite/MonActivite";

const DisplayProductAndPrep = ({
  setProductSelected,
  ProductSelected,
  navRowDisplayProdandPrep,
  setNavRowDisplayProdandPrep,
  PrepSelectedForChangeMatiere,
  setPrepSelectedForChangeMatiere,
}) => {
  return (
    <div className="DisplayProductAndPrep">
      <NavBarHorizontal
        Data={["Mes Produits", "Mes Preparation", "Mon activité"]}
        setNavRow={setNavRowDisplayProdandPrep}
        navRow={navRowDisplayProdandPrep}
      />
      {navRowDisplayProdandPrep === 0 && (
        <DisplayUserProduct
          setProductSelected={setProductSelected}
          ProductSelected={ProductSelected}
        />
      )}
      {navRowDisplayProdandPrep === 1 && (
        <DisplayUserPreparation
          PrepSelectedForChangeMatiere={PrepSelectedForChangeMatiere}
          setPrepSelectedForChangeMatiere={setPrepSelectedForChangeMatiere}
        />
      )}
      {navRowDisplayProdandPrep === 2 && <MonActivite />}
    </div>
  );
};

export default DisplayProductAndPrep;
