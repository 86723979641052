import React, { useEffect, useState } from "react";
import "./AllPrepActifInRestaurant.css";
import { getActivePrep } from "../../../../Data/firebaseInteractionManagerV2";

import RefairePrep from "../../../../components/OwnerV2Components/Button/RefairePrep/RefairePrep";
import SearchBarGenerique from "../../../OwnerV2Components/SearchBarGenerique/SearchBarGenerique";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchbarWithGivenData from "../../../OwnerV2Components/SearchbarWithGivenData/SearchbarWithGivenData";

const AllPrepActifInRestaurant = ({ RestaurantName }) => {
  const [ActivePrep, setActivePrep] = useState(null);
  const [PrepSearchbarResult, setPrepSearchbarResult] = useState(null);

  const [ProductionVisitorV2] = useCollection(
    collection(getFirestore(app), "ProductionVisitorV2"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  async function fetchPrep() {
    const Data = await getActivePrep(RestaurantName);
    setActivePrep(Data);
  }

  useEffect(() => {
    fetchPrep();
  }, []);

  if (PrepSearchbarResult && PrepSearchbarResult.length === 0) {
    if (ActivePrep) {
      setPrepSearchbarResult(ActivePrep);
    }
  }

  return (
    <div className="ContenerAllPrepActifInRestaurant">
      {ActivePrep && (
        <SearchbarWithGivenData
          data={ActivePrep}
          setResult={setPrepSearchbarResult}
          Propriete={"name"}
          TextToSearch={"Search preparation"}
        />
      )}
      <div className="ContenerDisplayPrepActive">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Preparation</TableCell>
                <TableCell></TableCell>
                <TableCell>Categorie</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {PrepSearchbarResult &&
                PrepSearchbarResult.map((Prep, index) => {
                  return (
                    <TableRow
                      key={Prep.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {Prep.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Avatar alt={Prep.name} src={Prep.img} />
                      </TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row">
                        <RefairePrep
                          IDPrep={Prep.IDPepers}
                          RestaurantName={"RestaurantSainteFoy"}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllPrepActifInRestaurant;
