import React, { useState } from "react";
import NavBarHorisontale from "../../../../components/NavBar/NavBarHorisontale/NavBarHorisontale";
import VisibilityStockPrep from "../../../../components/GestionRestaurant/VisibilityStockPrep/VisibilityStockPrep";
import VisibilityStockMatiere from "../../../../components/GestionRestaurant/VisibilityStock/VisibilityStock";

const StockRestaurantPrepAndMatiere = () => {
  const [navRow, setNavRow] = useState(0);

  return (
    <div>
      <NavBarHorisontale
        Data={["Prep", "Matiere"]}
        setNavRow={setNavRow}
        navRow={navRow}
      />
      {navRow === 0 && <VisibilityStockPrep />}
      {navRow === 1 && <VisibilityStockMatiere />}
    </div>
  );
};

export default StockRestaurantPrepAndMatiere;
