import React, { useState } from "react";
import CardsOrders from "../../CardsOrders/CardsOrders";
import "./MainDashPreparationLogistics.css";
import FicheTechniquePrep from "../../FichesTechnique/FicheTechniquePrep/FicheTechniquePrep";

const MainDashPreparationLogistics = ({ setOrderSelected }) => {
  const [prepSelected, setPrepSelected] = useState("");
  return (
    <div className="MainDash">
      <CardsOrders
        setPrepSelected={setPrepSelected}
        setOrderSelected={setOrderSelected}
      />
      <FicheTechniquePrep prepSelected={prepSelected} />
    </div>
  );
};

export default MainDashPreparationLogistics;
