import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { CreateNewProductGroup } from "../../../Functions/HandleClickFunctions";
import { getAllDish } from "../../../Data/firebaseService";
import "./ModalCreateNewProductGroup.css";

export default function ModalCreateNewProductGroup({ open, handleClose }) {
  const [selectedValue, setSelectedValue] = useState("");
  const [GDPName, setGDPName] = useState("");
  const [ListeAllProduct, setListeAllProduct] = useState([]);
  const [ProductIntheGroup, setProductIntheGroup] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      const AllDish = await getAllDish();
      setListeAllProduct(AllDish);
    }

    fetchOrders();
  }, []);

  const HandelSupProd = (NomProd) => {
    setProductIntheGroup((PrevListProd) => {
      return PrevListProd.filter((Prod) => Prod !== NomProd);
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        ></Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom du GDP"
            variant="outlined"
            value={GDPName}
            onChange={(e) => setGDPName(e.target.value)}
          />
        </Typography>
        <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
          <InputLabel id="select-unite-Preparation">Product</InputLabel>
          <Select
            labelId="select-unite-label-Type-Preparation"
            id="select-unite-Type-Preparation"
            value={ProductIntheGroup[ProductIntheGroup.length]}
            onChange={(e) =>
              setProductIntheGroup((prev) => [...prev, e.target.value])
            }
          >
            {ListeAllProduct.map((product) => {
              return (
                <MenuItem value={product.Production.name}>
                  {product.Production.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {ProductIntheGroup.map((Prod) => {
          return (
            <div>
              {Prod}
              <span
                onClick={() => HandelSupProd(Prod)}
                className="SupprimerPrep"
              >
                ___Supprimer
              </span>
            </div>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            CreateNewProductGroup(GDPName, ProductIntheGroup);
            handleClose();
          }}
        >
          Ajouter
        </Button>
      </Box>
    </Modal>
  );
}
