import React, { useState, useEffect } from "react";
import "./ActivePrep.css";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../firebaseConfig";

import { getActivePrep } from "../../../../../Data/firebaseInteractionOwnerV2";
import Avatar from "@mui/material/Avatar";
import { Button, CircularProgress } from "@mui/material";

const ActivePrep = ({ RestaurantName }) => {
  const [ActivePrepInrestaurant, setActivePrepInrestaurant] = useState(null);

  const [dishes] = useCollection(
    collection(getFirestore(app), "RestaurantSainteFoy"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const ActivePrep = await getActivePrep(RestaurantName);
      setActivePrepInrestaurant(ActivePrep);
    }
    fetchOrders();
  }, [dishes]);

  return (
    <div className="ContenerActivePrep">
      {ActivePrepInrestaurant ? (
        ActivePrepInrestaurant.map((prep) => (
          <div className="RowPrepInResto">
            {prep.name}
            <Avatar alt={prep.name} src={prep.img} />{" "}
          </div>
        ))
      ) : (
        <CircularProgress size={24} color="secondary" />
      )}
    </div>
  );
};

export default ActivePrep;
