import React from "react";
import RightSidePricing from "../../components/RightSide/RightSidePricing/RightSidePricing";
import MainDashPricing from "../../components/MainDash/MainDashPricing/MainDashPricing";

const Pricing = () => {
  return (
    <>
      <MainDashPricing />
      <RightSidePricing />
    </>
  );
};

export default Pricing;
