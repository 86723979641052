import React, { useState, useEffect } from "react";
import "./PosteConfection.css";
import { getProductInPosteDeTravail } from "../../Data/firebaseService";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../firebaseConfig";

import { PassProdtuctToFinish } from "../../Functions/HandleClickFunctions";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";

const PosteConfection = ({ WorkPlace }) => {
  const [ProductInPosteDeTravail, setProductInPosteDeTravail] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const [PosteDeTravail] = useCollection(
    collection(getFirestore(app), "PosteDeTravail"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  useEffect(() => {
    async function fetchOrders() {
      const ProducToDo = await getProductInPosteDeTravail(WorkPlace);
      setProductInPosteDeTravail(ProducToDo);
    }
    fetchOrders();
  }, [PosteDeTravail]);

  const HandelPassProdtuctToFinish = async (ProductIDInOrder, OrderID) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    try {
      // Votre code pour allouer le produit au poste de travail
      await PassProdtuctToFinish(
        ProductIDInOrder,
        OrderID,
        WorkPlace,
        user.uid
      );

      setIsProcessing(false);
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div className="PosteConfection">
        {ProductInPosteDeTravail &&
          ProductInPosteDeTravail.map((prod) => {
            return (
              <div className="OrderBox">
                <div className="OrderIDOrdderBox">ID : {prod.OrderID}</div>
                <div className="OrderProductName">
                  {prod.ProductName}
                  {prod.modifiers.map((sup) => {
                    return <div className="ProductSup">{sup.name}</div>;
                  })}
                </div>
                <div
                  className="ButtonFinalisee"
                  onClick={() =>
                    HandelPassProdtuctToFinish(
                      prod.ProductIDInOrder,
                      prod.OrderID,
                      WorkPlace
                    )
                  }
                >
                  <div className="TextButton">Finaliser</div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PosteConfection;
