import React from "react";
import MainDashFichesTechinique from "../../components/MainDash/MainDashFichesTechinique/MainDashFichesTechinique";

const FichesTechinique=()=>{

    return(<>
        <MainDashFichesTechinique/>
        <div>Right Side</div>
        </>
    )
}

export default FichesTechinique