import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { firestore } from "../firebaseConfig";

export async function OrdersAnalytics(Orders, WorkPlace) {
  // on recupere la liste de tout les plans de travail
  const WorkPlaceCollectionRef = collection(firestore, "PosteDeTravail");
  const querySnapshot = await getDocs(WorkPlaceCollectionRef);
  const allDataWorkPlace = [];
  querySnapshot.forEach((doc) => {
    if (doc.id.toString() != "Assemblage") {
      allDataWorkPlace.push(doc.data());
    }
  });
  // Analyse des commande pour ne recuperer que celle ouverte
  let AllProductToDo = [];
  Orders.map((commande) => {
    if (commande.data().status === "production") {
      // on recupere la liste des produits a concevoir
      commande.data().listeProduitCommande.map((produit) => {
        let CheckIfAlocated = false;
        // On verifie si le produit n'est pas deja atribue a un plan de travail en regardant dans chaque plan de travail
        allDataWorkPlace.map((WorkPlace) => {
          WorkPlace.ProductInProduction.map((ProduitEnProd) => {
            if (
              ProduitEnProd.OrderID === commande.id &&
              ProduitEnProd.ProductIDInOrder === produit.id
            ) {
              CheckIfAlocated = true;
            }
          });
        });
        if (CheckIfAlocated === false && produit.etatProduction === false) {
          let product = { ...produit, commandeId: commande.id };
          AllProductToDo.push(product);
        }
      });
    }
  });
  // Parmis les produits a fabriquer nous verifion la liste des prep du produits et les machine et nous la comparons aux elements disponible sur le plan de travail (machine + prep)
  // on recupere la base produit
  const DishDocRef = doc(firestore, "Production", "dishes");
  const DishSnapshot = await getDoc(DishDocRef);
  const AllProduct = DishSnapshot.data().dishes;
  const AllProductNAme = DishSnapshot.data().dishes.map(
    (prod) => prod.Production.name
  );

  // On recup les prep et les machines du plan de travail
  const WorkPlaceDocRef = doc(firestore, "PosteDeTravail", WorkPlace);
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const PrepOfTheworkPlace = docSnapshot.data().Preparations;
  const MachineOfTheworkPlace = docSnapshot.data().Machine;

  let prodWhoMatchAvailableWorkPlace = [];

  AllProductToDo.forEach((prod) => {
    let productValid = true;
    if (!AllProductNAme.includes(prod.name)) {
      productValid = false;
    }
    AllProduct.forEach((PepersProduct) => {
      // on recupere dans la base produit la liste des preparations
      if (prod.name === PepersProduct.Production.name) {
        // On regarde charque preparation et si une n'est pas incluse dans la liste des preparation du plan de travail le produit n'est pas ajoute
        PepersProduct.Production.PreparationIntermediaire.forEach(
          (preparationDuProduit) => {
            if (!PrepOfTheworkPlace.includes(preparationDuProduit.Name)) {
              // Si une préparation n'est pas disponible dans le poste de travail,
              // le produit n'est pas valide
              productValid = false;
            }
          }
        );
        // Si un produit n'a pas de preparation (ce qui est pas possible en temps normal)
        // On ne l'ajoute pas
        if (PepersProduct.Production.PreparationIntermediaire.length === 0) {
          productValid = false;
        }
      }
    });

    if (productValid) {
      prodWhoMatchAvailableWorkPlace.push(prod);
    }
  });
  // Parmis les produits a faire nous identifions les produits a faire en groupe
  // On recupere la liste des groupes de produits pour pouvoir la comparer
  const ProductGroupsDocRef = doc(firestore, "Production", "ProductGroup");
  const ProductGroupsSnapshot = await getDoc(ProductGroupsDocRef);
  const ProductGroups = ProductGroupsSnapshot.data().ListeProductGroup;

  // on identifie parmis les produit a faire ceux qui sont dans un groupe de produit
  let FirstProductGroup = [];
  prodWhoMatchAvailableWorkPlace.forEach((product) => {
    ProductGroups.forEach((group) => {
      group.ListeOfProduct.forEach((productInTheGroup) => {
        if (productInTheGroup === product.name) {
          FirstProductGroup.push({
            ...product,
            GroupName: group.NameOfProductGroup,
          });
        }
      });
    });
  });

  // On Compare les produits du groupe a tout les produit a faire pour voir ceux qui sont dans la meme commande
  // Triez le tableau par la propriété 'commandeID' Nous voulons creer plusieur tableau si le produit est dans la meme commande et dans le meme groupe

  FirstProductGroup.sort((a, b) => a.commandeId - b.commandeId);

  // Créez un tableau de tableaux pour regrouper les objets par 'commandeID'
  const tableauDeTableaux = [];
  let tableauInterne = [];
  let currentCommandeID = null;

  FirstProductGroup.forEach((objet) => {
    if (objet.commandeId !== currentCommandeID) {
      // Nouvelle 'commandeID', créez un nouveau tableau interne
      if (tableauInterne.length > 0) {
        tableauDeTableaux.push(tableauInterne);
      }
      tableauInterne = [];
      currentCommandeID = objet.commandeId;
    }
    tableauInterne.push(objet);
  });

  // Ajoutez le dernier tableau interne au tableau de tableaux
  if (tableauInterne.length > 0) {
    tableauDeTableaux.push(tableauInterne);
  }

  let prodWhoMatchAvailableWorkPlacePlusGroup = [];
  prodWhoMatchAvailableWorkPlace.forEach((ProdWorkplace) => {
    let IsProductInTheGroup = false;
    if (tableauDeTableaux.length != 0) {
      tableauDeTableaux.forEach((group, indexGroup) => {
        if (group.length > 1) {
          group.forEach((ProdGroup) => {
            if (
              ProdGroup.name === ProdWorkplace.name &&
              ProdGroup.commandeId === ProdWorkplace.commandeId &&
              ProdGroup.id === ProdWorkplace.id
            ) {
              IsProductInTheGroup = true;
              prodWhoMatchAvailableWorkPlacePlusGroup.push({
                ...ProdWorkplace,
                ProductGroup: {
                  id: indexGroup,
                  numberOfProduc: tableauDeTableaux[indexGroup].length,
                },
              });
            }
          });
        }
      });
    }
    if (IsProductInTheGroup === false) {
      prodWhoMatchAvailableWorkPlacePlusGroup.push({ ...ProdWorkplace });
    }
  });

  return prodWhoMatchAvailableWorkPlacePlusGroup;
}
