import React, { useState, useEffect } from "react";
import "./PosteConfectionAssemblage.css";
import { getOrderOnGoingPackaging } from "../../../Data/firebaseService";
import { PassOrderToFinish } from "../../../Functions/HandleClickFunctions";

import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  getFirestore,
  limit,
  query,
  orderBy,
} from "firebase/firestore";
import { app } from "../../../firebaseConfig";

const PosteConfectionAssemblage = () => {
  const [OrderOngoing, setOrderOngoing] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"),
    limit(100)
  );

  const [Orders] = useCollection(ordersQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [PosteDeTravail] = useCollection(
    collection(getFirestore(app), "PosteDeTravail"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (Orders) {
      async function fetchOrders() {
        const OrderToDo = await getOrderOnGoingPackaging(Orders.docs);
        setOrderOngoing(OrderToDo);
      }
      fetchOrders();
    }
  }, [Orders, PosteDeTravail]);

  const HandelPassOrderToFinish = async (orderID) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    try {
      // Votre code pour allouer le produit au poste de travail
      await PassOrderToFinish(orderID);

      setIsProcessing(false);
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };

  return (
    <div className="PosteConfection">
      {OrderOngoing &&
        OrderOngoing.map((commande) => {
          return (
            <div
              className="OrderBox"
              onClick={() => HandelPassOrderToFinish(commande.id.toString())}
            >
              {commande.id}
              {commande.listeProduitCommande != 0 &&
                commande.listeProduitCommande.map((prod) => {
                  return <div>{prod.name}</div>;
                })}
            </div>
          );
        })}
    </div>
  );
};

export default PosteConfectionAssemblage;
