import React, {useState, useEffect} from "react";
import {FaSearch} from 'react-icons/fa'
import {AiOutlinePlusCircle} from 'react-icons/ai'
import './SearchBar.css'
import {getPreparationSearchBarData} from "../../Data/firebaseService"
import ModalModifierPreparation from '../Modal/ModalModifierPreparation/ModalModifierPreparation'
import ModalAddNewPreparation from '../Modal/ModalAddNewPreparation/ModalAddNewPreparation'
import {CreationProductAtom} from '../../atoms/CreationProductAtom'
import { useRecoilState } from 'recoil';

const SearchBar = () => {

    const [input, setInput] = useState("")
    const [result, setResult] = useState([])
    const [selectedPreparation, setSelectedPreparation] = useState(null);
    const [open, setOpen] = useState(false);
    const [OpenNewPrep, setOpenNewPrep] = useState(false);
    const [CreationProduct, setCreationProduct] = useRecoilState(CreationProductAtom);

    const handleOpen = (Preparation) => {
        setSelectedPreparation(Preparation);
        setOpen(true);
      };
    
      const handleClose = () => {
        setSelectedPreparation(null);
        setOpen(false);
      };

      const handleCloseModalNewPrep = () => {
        setOpenNewPrep(false);
      };

    /*const fetchData = (value)=>{
        fetch("https://jsonplaceholder.typicode.com/users").then((response)=>response.json()).then(json=>{
            const results = json.filter((user)=>{
                return value && user && user.name && user.name.toLowerCase().includes(value)
            })
            setResult(results)
        })
    }*/
    const fetchData = (value)=>{
        getPreparationSearchBarData().then(json=>{
            const results = json.filter((prep)=>{
                return value && prep.Nom && prep.Nom.toLowerCase().includes(value)
            })
            setResult(results)
        })
    }
    const handleChange = (value)=>{
        setInput(value)
        fetchData(value)
    }

    return (
      <div className="search-bar-container">
        <div className="input-wrapper">
            <FaSearch id = "search-icon"/>
            <input placeholder="Type to search preparation" value={input} onChange={(e)=> handleChange(e.target.value)}/>
            <AiOutlinePlusCircle onClick={()=>setOpenNewPrep(true)} className="Plus-Add-Product"/>
        </div>
        <div className="result-list">
            {result.map((preparation,id)=>{
                return <div className="search-result" key={id} onClick={() => handleOpen(preparation)}>{preparation.Nom}</div>
            })}
        </div>
        {selectedPreparation && (
        <ModalModifierPreparation
          open={open}
          handleClose={handleClose}
          preparation={selectedPreparation}
          product = {CreationProduct.productSelected}
        />
      )}
      <ModalAddNewPreparation
          open={OpenNewPrep}
          handleClose={handleCloseModalNewPrep}
        />
      </div>
    );
  };
  

export default SearchBar