import React, { useState, useEffect } from "react";
import "./MainDashDataKitchen.css";
import CardsDataKitchen from "../../Cards/CardsDataKitchen/CardsDataKitchen";

const MainDashDataKitchen = () => {
  return (
    <div className="MainDash">
      {/*<CardsDataKitchen />*/}
      <div>Here will be the data about workplace and productivities </div>
      <div>TableDataKitchen</div>
    </div>
  );
};

export default MainDashDataKitchen;
