import React, { useState, useEffect } from "react";
import "./FaireInventaire.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import {
  HandleFaireInventaire,
  getActiveIngredientWithMatiereRef,
  getActivePreparationInRestaurant,
} from "../../../../Data/firebaseInteractionOwnerV2";
import SearchBarGenerique from "../../../OwnerV2Components/SearchBarGenerique/SearchBarGenerique";
import SearchbarWithGivenData from "../../../OwnerV2Components/SearchbarWithGivenData/SearchbarWithGivenData";
import Avatar from "@mui/material/Avatar";
import { TextField, Button } from "@material-ui/core";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

const FaireInventaire = ({ RestaurantName }) => {
  const [OpenInventaireModal, setOpenInventaireModal] = useState(false);
  const [DataForm, setDataForm] = useState({ RestaurantName });
  const [ActiveIngredient, setActiveIngredient] = useState(null);
  const [ActivePrep, setActivePrep] = useState(null);
  const [PrepMode, setPrepMode] = useState(true);
  const [SearchbarResultActiveIngredient, setSearchbarResultActiveIngredient] =
    useState(null);
  const [SearchbarResultActivePrep, setSearchbarResultActivePrep] =
    useState(null);

  const handleOpenInventaireModal = () => {
    setOpenInventaireModal(true);
  };

  const handleCloseInventaireModal = () => {
    setOpenInventaireModal(false);
  };
  async function fetchIngredient() {
    const ActiveIngredient = await getActiveIngredientWithMatiereRef(
      RestaurantName
    );
    setActiveIngredient(ActiveIngredient);
    setDataForm((prevdata) => {
      let qteIngredient = {};
      ActiveIngredient.forEach((ingredient) => {
        qteIngredient[ingredient.IDPepers] = 0;
      });

      return {
        ...prevdata,
        QteIngredientInventaire: { ...qteIngredient },
      };
    });

    const ActivePrep = await getActivePreparationInRestaurant(RestaurantName);
    setActivePrep(ActivePrep);
    setDataForm((prevdata) => {
      let qtePrep = {};
      ActivePrep.forEach((Prep) => {
        qtePrep[Prep.IDPepers] = 0;
      });

      return {
        ...prevdata,
        QtePrepInventaire: { ...qtePrep },
      };
    });
  }
  async function fetchPrep() {
    const ActivePrep = await getActivePreparationInRestaurant(RestaurantName);
    setActivePrep(ActivePrep);
    setDataForm((prevdata) => {
      let qtePrep = {};
      ActivePrep.forEach((Prep) => {
        qtePrep[Prep.IDPepers] = 0;
      });

      return {
        ...prevdata,
        QtePrepInventaire: { ...qtePrep },
      };
    });
  }

  useEffect(() => {
    fetchIngredient();
  }, []);

  useEffect(() => {
    fetchPrep();
  }, []);

  if (
    SearchbarResultActiveIngredient &&
    SearchbarResultActiveIngredient.length === 0
  ) {
    setSearchbarResultActiveIngredient(ActiveIngredient);
  }

  if (SearchbarResultActivePrep && SearchbarResultActivePrep.length === 0) {
    setSearchbarResultActivePrep(ActivePrep);
  }

  return (
    <div>
      <div
        className="ButtonAddMatiere"
        onClick={() => handleOpenInventaireModal()}
      >
        Faire inventaire
      </div>
      <ModalAvecFunction
        open={OpenInventaireModal}
        handleClose={handleCloseInventaireModal}
        Form={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            <div
              className="SwitchButtonPrepIngredient"
              onClick={() => setPrepMode((prev) => !prev)}
            >
              {PrepMode ? "Ingredient" : "Prep"}
            </div>
            {PrepMode ? (
              <SearchbarWithGivenData
                data={ActiveIngredient}
                setResult={setSearchbarResultActiveIngredient}
                Propriete={"name"}
                TextToSearch={"Search Ingredient"}
              />
            ) : (
              <>
                <SearchbarWithGivenData
                  data={ActivePrep}
                  setResult={setSearchbarResultActivePrep}
                  Propriete={"name"}
                  TextToSearch={"Search Preparation"}
                />
              </>
            )}

            {PrepMode ? (
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                {SearchbarResultActiveIngredient &&
                  SearchbarResultActiveIngredient.map((ActifIngredient) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>{ActifIngredient.name}</div>
                      <Avatar
                        style={{ marginLeft: "15px", marginRight: "15px" }}
                        alt={ActifIngredient.name}
                        src={ActifIngredient.Img}
                      />
                      <TextField
                        onChange={(event) =>
                          setDataForm((prevData) => ({
                            ...prevData,
                            QteIngredientInventaire: {
                              ...prevData.QteIngredientInventaire,
                              [ActifIngredient.IDPepers]: event.target.value,
                            },
                          }))
                        }
                        style={{ maxWidth: "50px" }}
                        label="Qte"
                        value={
                          DataForm.hasOwnProperty("QteIngredientInventaire") &&
                          DataForm.QteIngredientInventaire[
                            ActifIngredient.IDPepers
                          ]
                        }
                      />
                      {ActifIngredient.UniteDeRecette}
                    </div>
                  ))}
              </div>
            ) : (
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                {SearchbarResultActivePrep &&
                  SearchbarResultActivePrep.map((ActifPrep) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>{ActifPrep.name}</div>
                      <Avatar
                        style={{ marginLeft: "15px", marginRight: "15px" }}
                        alt={ActifPrep.name}
                        src={ActifPrep.img}
                      />
                      <TextField
                        onChange={(event) =>
                          setDataForm((prevData) => ({
                            ...prevData,
                            QtePrepInventaire: {
                              ...prevData.QtePrepInventaire,
                              [ActifPrep.IDPepers]: event.target.value,
                            },
                          }))
                        }
                        style={{ maxWidth: "50px" }}
                        label="Qte"
                        value={
                          DataForm.hasOwnProperty("QtePrepInventaire") &&
                          DataForm.QtePrepInventaire[ActifPrep.IDPepers]
                        }
                      />
                      {ActifPrep.UniteDeMesure}
                    </div>
                  ))}
              </div>
            )}
          </div>
        }
        TextActionButton={"Valider l'inventaire"}
        TitleModal={"Inventaire"}
        DataForm={{ ...DataForm }}
        Function={HandleFaireInventaire}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default FaireInventaire;
