import React, { useState } from "react";
import "./CenterPageVisitorV2.css";
import LeftSideCenterpageVisitorV2 from "../LeftSideCenterpageVisitorV2/LeftSideCenterpageVisitorV2";
import RightSideCenterpageVisitorV2 from "../RightSideCenterpageVisitorV2/RightSideCenterpageVisitorV2";

const CenterPageVisitorV2 = ({
  setProductSelected,
  ProductSelected,
  navRowDisplayProdandPrep,
  setNavRowDisplayProdandPrep,
  PrepSelectedForChangeMatiere,
  setPrepSelectedForChangeMatiere,
}) => {
  return (
    <div className="CenterPage">
      <LeftSideCenterpageVisitorV2
        ProductSelected={ProductSelected}
        PrepSelectedForChangeMatiere={PrepSelectedForChangeMatiere}
        navRowDisplayProdandPrep={navRowDisplayProdandPrep}
      />
      <RightSideCenterpageVisitorV2
        setProductSelected={setProductSelected}
        ProductSelected={ProductSelected}
        navRowDisplayProdandPrep={navRowDisplayProdandPrep}
        setNavRowDisplayProdandPrep={setNavRowDisplayProdandPrep}
        PrepSelectedForChangeMatiere={PrepSelectedForChangeMatiere}
        setPrepSelectedForChangeMatiere={setPrepSelectedForChangeMatiere}
      />
    </div>
  );
};

export default CenterPageVisitorV2;
