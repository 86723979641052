import React, { useState } from "react";
import "./Modeconssomation.css";
import NavBarHorisontale from "../../../../NavBar/NavBarHorisontale/NavBarHorisontale";
import RefairePrep from "../../../Button/RefairePrep/RefairePrep";
import PasserUneCommandeMatiere from "../../../Button/PasserUneCommandeMatiere/PasserUneCommandeMatiere";
import FaireInventaire from "../../../Button/FaireInventaire/FaireInventaire";
import ConssomationEntreDeuxInventaire from "../Modeconssomation/ConssomationEntreDeuxInventaire/ConssomationEntreDeuxInventaire";
import AddRestaurant from "../../../../../components/OwnerV2Components/Button/AddRestaurant/AddRestaurant";
import GestionPreparation from "../Modeconssomation/GestionPreparation/GestionPreparation";
import GestionCommandesMatiere from "../Modeconssomation/GestionCommandesMatiere/GestionCommandesMatiere";
import DailyActivitiesAnalyse from "./DailyActivitiesAnalyse/DailyActivitiesAnalyse";
import DisplayInventaire from "./DisplayInventaire/DisplayInventaire";

const Modeconssomation = () => {
  const [navRow, setNavRow] = useState(0);
  return (
    <>
      <NavBarHorisontale
        Data={[
          "Conssomation",
          "Daily Activities",
          "Gestion Prep",
          "Commandes Matiere",
          "Inventaires",
        ]}
        setNavRow={setNavRow}
        navRow={navRow}
      />
      {navRow === 0 && (
        <>
          <ConssomationEntreDeuxInventaire
            RestaurantName={"RestaurantSainteFoy"}
          />
        </>
      )}
      {navRow === 1 && (
        <>
          {/*<RefairePrep
            IDPrep={"0d7d2be0-67b5-4b9b-817d-1edf07a69595"}
            RestaurantName={"RestaurantSainteFoy"}
          />
          <PasserUneCommandeMatiere RestaurantName={"RestaurantSainteFoy"} />
          <FaireInventaire RestaurantName={"RestaurantSainteFoy"} />
          <AddRestaurant />*/}
          <DailyActivitiesAnalyse />
        </>
      )}
      {navRow === 2 && (
        <>
          <GestionPreparation RestaurantName={"RestaurantSainteFoy"} />
        </>
      )}
      {navRow === 3 && (
        <>
          <GestionCommandesMatiere RestaurantName={"RestaurantSainteFoy"} />
        </>
      )}
      {navRow === 4 && (
        <>
          <DisplayInventaire RestaurantName={"RestaurantSainteFoy"} />
        </>
      )}
    </>
  );
};

export default Modeconssomation;
