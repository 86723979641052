import { atom } from "recoil";

const defaultCreationTESTProduct = {
  productSelected: "",
};

export const CreationTESTProductAtom = atom({
  key: "CreationTESTProductAtom",
  default: defaultCreationTESTProduct,
});
