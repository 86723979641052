import React, { useState, useEffect } from "react";
import "./DisplayUserPreparation.css";
import { getUserInfo } from "../../../Data/firebaseGetUserInfo";
import {
  getVisitorPreparations,
  ModifyQteFinalePrep,
  SupprimerPrep,
} from "../../../Data/firebaseInteractionVisitorsV2";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";

import AddNewUserPrep from "../Button/AddNewUserPrep/AddNewUserPrep";
import ModalAvecFunction from "../Modal/ModalAvecFunction/ModalAvecFunction";

import { TextField, Button } from "@material-ui/core";

const DisplayUserPreparation = ({
  PrepSelectedForChangeMatiere,
  setPrepSelectedForChangeMatiere,
}) => {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState(null);
  const [userPrep, setUserPrep] = useState(null);
  const [prepSelected, setprepSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [DataForm, setDataForm] = useState({});

  const [preparations] = useCollection(
    collection(getFirestore(app), "preparations"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const userInfo = await getUserInfo(user.uid);
      setUserInfo(userInfo);
      const userPrep = await getVisitorPreparations(userInfo.uid);
      setUserPrep(userPrep);
      if (userPrep.length === 0) {
        setPrepSelectedForChangeMatiere(null);
      }
    }
    fetchOrders();
  }, [preparations]);

  const handleOpen = (prep) => {
    setprepSelected(prep);
    setDataForm((prevData) => ({
      ...prevData,
      prepSelected: prep,
      QteFinalePrep: prep.QteFinalePrep,
    }));
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setprepSelected(null);
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const handleSupprimerPrep = (prep) => {
    if (isProcessing) return;
    setIsProcessing(true);
    SupprimerPrep(prep);
    setIsProcessing(false);
  };

  return (
    <div>
      <div>
        {userPrep &&
          userPrep.map((prep) => (
            <div
              className={
                PrepSelectedForChangeMatiere &&
                PrepSelectedForChangeMatiere.IDPepers === prep.IDPepers
                  ? "ProductLineSelected"
                  : "ProductLine"
              }
              onClick={() => setPrepSelectedForChangeMatiere(prep)}
            >
              <div className="">{prep.name}</div>
              <div className="">
                {prep.QteFinalePrep}
                {prep.UniteDeMesure}{" "}
                <span className="Details" onClick={() => handleOpen(prep)}>
                  Modifier
                </span>
              </div>
              <div className="">
                {(prep.PrixHTParUniteDeMesure * 2.5).toFixed(6)}€ /{" "}
                {prep.UniteDeMesure}{" "}
              </div>
              <div className="Red" onClick={() => handleSupprimerPrep(prep)}>
                Supprimer
              </div>
            </div>
          ))}
        <AddNewUserPrep userID={user.uid} />
      </div>
      {prepSelected && (
        <ModalAvecFunction
          open={isOpen}
          handleClose={handleClose}
          Form={
            <div>
              <TextField
                id="outlined-basic"
                style={{ width: "50px" }}
                value={DataForm.QteFinalePrep}
                onChange={(event) =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    QteFinalePrep: event.target.value,
                  }))
                }
              />
              {prepSelected.UniteDeMesure}
            </div>
          }
          TextActionButton={"Modifier la Qte"}
          TitleModal={`Modifier la qte finale de : ${prepSelected.name}`}
          SousParagraphe={
            <div>
              <div></div>
              <div>
                <img style={{ maxWidth: "300px" }} src={prepSelected.img}></img>
              </div>
            </div>
          }
          DataForm={DataForm}
          setDataForm={setDataForm}
          Function={ModifyQteFinalePrep}
        />
      )}
    </div>
  );
};

export default DisplayUserPreparation;
