import React from "react";
import { SidebarDataOwnerV2 } from "../../Data/DataUserModeOwnerV2";
import Sidebar from "../../components/Sidebar/Sidebar";
import CreationProduct from "../../View/Owner/CreationProduct";
import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";
import Production from "../../View/OwnerV2/Production/Production";
import LesRestaurants from "../../View/OwnerV2/LesRestaurants/LesRestaurants";

const OwnerV2 = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <>
      <Sidebar data={SidebarDataOwnerV2} />
      {SideBarAtom.view === 0 && <Production />}
      {SideBarAtom.view === 1 && <LesRestaurants />}
    </>
  );
};

export default OwnerV2;
