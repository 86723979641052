import React, { useState } from "react";
import "./RightSideCenterpageVisitorV2.css";
import DisplayProductAndPrep from "../DisplayProductAndPrep/DisplayProductAndPrep";

const RightSideCenterpageVisitorV2 = ({
  setProductSelected,
  ProductSelected,
  navRowDisplayProdandPrep,
  setNavRowDisplayProdandPrep,
  PrepSelectedForChangeMatiere,
  setPrepSelectedForChangeMatiere,
}) => {
  return (
    <div className="RightSideVisitor">
      <DisplayProductAndPrep
        setProductSelected={setProductSelected}
        ProductSelected={ProductSelected}
        navRowDisplayProdandPrep={navRowDisplayProdandPrep}
        setNavRowDisplayProdandPrep={setNavRowDisplayProdandPrep}
        PrepSelectedForChangeMatiere={PrepSelectedForChangeMatiere}
        setPrepSelectedForChangeMatiere={setPrepSelectedForChangeMatiere}
      />
      <div></div>
    </div>
  );
};

export default RightSideCenterpageVisitorV2;
