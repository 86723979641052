import React, { useEffect, useState } from "react";
import {
  getQteMatiereRecuBetween2Dates,
  getFournisseurSearchBarData,
  getAllOrderMatiereInRestauarantWithAddedData,
} from "../../../../Data/firebaseService";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import "./MatierePremiereRecu.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const MatierePremiereRecu = ({ startDate, endDate }) => {
  const [DataMatiereRecu, setDataMatiereRecu] = useState(null);
  const [AllFournisseur, setAllFournisseur] = useState(null);
  const [Fournisseur, setFournisseur] = useState("ALL");
  const [AllOrderMatiere, setOrderMatiere] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const Data = await getQteMatiereRecuBetween2Dates(
        startDate,
        endDate,
        23,
        59,
        0,
        1
      );

      setDataMatiereRecu(Data);

      let AllFournisseur = await getFournisseurSearchBarData();
      setAllFournisseur([...AllFournisseur, { Nom: "ALL" }]);

      let AllOrderMatiere =
        await getAllOrderMatiereInRestauarantWithAddedData();
      setOrderMatiere(AllOrderMatiere);
    };
    fetchData();
  }, [startDate, endDate]);

  console.log(DataMatiereRecu);
  return (
    <div className="ContenerTable">
      <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
        <InputLabel id="select-unite-Preparation">
          Selection fournisseur
        </InputLabel>
        <Select
          labelId="select-Fournisseeur"
          id="select-Fournisseeur"
          value={Fournisseur}
          onChange={(e) => setFournisseur(e.target.value)}
        >
          {AllFournisseur != null &&
            AllFournisseur.map((fournisseur) => (
              <MenuItem key={fournisseur.Nom} value={fournisseur.Nom}>
                {fournisseur.Nom}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <div>Matiere Premiere Recu au restaurant entre deux date </div>
      <div>
        {DataMatiereRecu &&
          Object.keys(DataMatiereRecu).map((Matiere) => {
            return Fournisseur === "ALL" ? (
              <div className="RowMatiere">
                <div>{Matiere}</div>
                <div>QTE reçu :{DataMatiereRecu[Matiere].Qte}</div>
                <div>QTE commandé :{DataMatiereRecu[Matiere].QteOrder}</div>
                <div>
                  {DataMatiereRecu[Matiere].DataMatiere.Conditionnement}
                </div>
              </div>
            ) : (
              DataMatiereRecu[Matiere].DataMatiere.Fournisseur ===
                Fournisseur && (
                <div className="RowMatiere">
                  <div>{Matiere}</div>
                  <div>QTE reçu : {DataMatiereRecu[Matiere].Qte}</div>
                  <div>QTE commandé : {DataMatiereRecu[Matiere].QteOrder}</div>
                  <div>
                    {DataMatiereRecu[Matiere].DataMatiere.Conditionnement}
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};
export default MatierePremiereRecu;
