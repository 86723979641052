import React, { useState, useEffect } from "react";
import "./SearchBarMatiere.css";

const SearchBarMatiere = ({ UseEffectFunction, setResult }) => {
  const [input, setInput] = useState("");

  const fetchData = (value) => {
    UseEffectFunction().then((json) => {
      const results = json.filter((Matiere) => {
        return (
          value && Matiere.name && Matiere.name.toLowerCase().includes(value)
        );
      });
      setResult(results);
    });
  };
  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="search-bar-container-Prep">
      <div className="input-wrapper-Prep">
        <input
          placeholder="Matiere"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchBarMatiere;
