import React, { useState, useEffect } from "react";
import "./DisplayActifProductInTheRestaurant.css";
import SearchBarGenerique from "../../../SearchBarGenerique/SearchBarGenerique";
import SearchbarWithGivenData from "../../../../OwnerV2Components/SearchbarWithGivenData/SearchbarWithGivenData";
import {
  getActiveProductIntheRestaurant,
  PassProductToOfline,
} from "../../../../../Data/firebaseInteractionOwnerV2";
import SwitchProductToOnline from "../../../Button/SwitchProductToOnline/SwitchProductToOnline";
import Avatar from "@mui/material/Avatar";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../firebaseConfig";
import { Button, CircularProgress } from "@mui/material";

const DisplayActifProductInTheRestaurant = ({ RestaurantName }) => {
  const [ActiveProductInResto, setActiveProductInResto] = useState(null);
  const [ResultSearchProductInResto, setResultSearchProductInResto] =
    useState(null);

  const [dishes] = useCollection(
    collection(getFirestore(app), `${RestaurantName}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const Product = await getActiveProductIntheRestaurant(RestaurantName);
      setActiveProductInResto(Product);
    }
    fetchOrders();
  }, [dishes]);

  if (ResultSearchProductInResto && ResultSearchProductInResto.length === 0) {
    setResultSearchProductInResto(ActiveProductInResto);
  }
  const [isProcessing, setIsProcessing] = useState(false);
  const HandlePassProductToOfline = async (dish, RestaurantName) => {
    if (isProcessing) return;
    setIsProcessing(true);
    await PassProductToOfline([dish], RestaurantName);
    setIsProcessing(false);
  };

  return (
    <div className="ContenerDisplayActifProductInTheRestaurant">
      <div className="SearchBarProductInTheRestaurant">
        <SearchbarWithGivenData
          data={ActiveProductInResto}
          setResult={setResultSearchProductInResto}
          Propriete={"name"}
          TextToSearch={"Search active product"}
        />
      </div>
      <div>
        {ResultSearchProductInResto ? (
          ResultSearchProductInResto.map((dish) => (
            <div className="RowProductActif">
              <div>{dish.name}</div> <Avatar alt={dish.name} src={dish.img} />{" "}
              <span
                className="Red"
                onClick={() => HandlePassProductToOfline(dish, RestaurantName)}
              >
                Supprimer
              </span>
            </div>
          ))
        ) : (
          <CircularProgress size={24} color="secondary" />
        )}
      </div>
      <SwitchProductToOnline RestaurantName={RestaurantName} />
    </div>
  );
};

export default DisplayActifProductInTheRestaurant;
