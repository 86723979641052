import React, { useState } from "react";
import CardsOrders from "../../CardsOrders/CardsOrders";
import "./MainDashPreparationLogisticsV2.css";
import FicheTechniquePrepV2 from "../../FichesTechnique/FicheTechniquePrepV2/FicheTechniquePrepV2";
import DisplayOrderPrepLogistic from "../../DisplayOrderPrepLogistic/DisplayOrderPrepLogistic";

const MainDashPreparationLogisticsV2 = () => {
  const [SelectedPrep, setSelectedPrep] = useState("Sauce aneth");
  const today = new Date();
  const Tomorrow = new Date(today.getTime() + 86400000);
  console.log(SelectedPrep);
  return (
    <div className="MainDash">
      <DisplayOrderPrepLogistic
        setSelectedPrep={setSelectedPrep}
        OrderDate={today}
      />
      <FicheTechniquePrepV2 Nomprep={SelectedPrep} />
    </div>
  );
};

export default MainDashPreparationLogisticsV2;
