import React from "react";
import "./ButtonAddProductInZelty.css";
import axios from "axios";

export default function ButtonAddProductInZelty({ Dish }) {
  const HandleCreateProduct = (Dish) => {
    let Description = "";
    if (Dish.Production.hasOwnProperty("Description")) {
      Description = Dish.Production.Description;
    }
    const Data = [
      {
        id: null,
        remote_id: null,
        id_restaurant: 3019,
        sku: "",
        name: Dish.Production.name,
        description: Description,
        image: Dish.Production.img,
        thumb: Dish.Production.img,
        price: 1399,
        price_togo: 1399,
        price_delivery: 1399,
        happy_price: 1399,
        cost_price: 0,
        tva: 1000,
        tvat: 1000,
        tvad: 1000,
        tax: 1000,
        tax_takeaway: 1000,
        tax_delivery: 1000,
        tags: [75492],
        options: [31639],
        id_fabrication_place: 5863,
        fab_name: "",
        color: 0,
        loyalty_points: 0,
        loyalty_points_discount: 0,
        earn_loyalty: 0,
        price_to_define: false,
        weight_for_price: 0,
        disable: false,
        disable_takeaway: false,
        disable_delivery: false,
        disable_before: "2017-04-28",
        disable_after: "2040-04-28",
        o: -4,
        zc_only: false,
        meta: {},
      },
    ];
    const apiUrl =
      "https://us-central1-reddd-6b0fd.cloudfunctions.net/api/AddZeltyProduct";
    axios
      .post(apiUrl, Data)
      .then((response) => {
        // La réponse de la fonction Cloud.
        console.log("Réponse de la fonction Cloud :", response.data);
      })
      .catch((error) => {
        // Gérez les erreurs ici.
        console.error("Erreur lors de la requête POST :", error);
      });
  };

  return (
    <>
      <button onClick={() => HandleCreateProduct(Dish)}>Add Zelty</button>
    </>
  );
}
