import React, { useState } from "react";
import "./ButtonAddNewSup.css";
import ModalAddNewSup from "../../../components/Modal/ModalAddNewSup/ModalAddNewSup";

export default function ButtonAddNewSup() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <button onClick={handleOpen}>Ajouter un supplement</button>
      <ModalAddNewSup open={open} handleClose={handleClose} />
    </>
  );
}
