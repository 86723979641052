import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ButtonJeterPrep from "../../Button/ButtonJeterPrep/ButtonJeterPrep";

const ModalJetterPrep = ({ open, handleClose, Prep }) => {
  const [RaisonJetter, setRaisonJetter] = useState("");
  const [QtePrepAJetter, setQtePrepAJetter] = useState(0);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {Prep.Nom}
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {Prep.Categorie}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <img
            className="img-modal-Preparation"
            src={Prep.PhotoFirebase}
            alt="Nom de l'image"
          />
        </Typography>
        <TextField
          label="Qte a jeter"
          variant="outlined"
          value={QtePrepAJetter}
          onChange={(e) =>
            setQtePrepAJetter((oldValue) => {
              const inputValue = e.target.value;
              // Vérifier si l'entrée est un nombre
              const isNumber = !isNaN(inputValue);
              let newQteAJetter = oldValue;
              if (isNumber) {
                newQteAJetter = Number(inputValue);
              }
              return newQteAJetter;
            })
          }
        />
        {Prep.Unite}
        <div>
          <br />
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">
              Raison du jeter
            </InputLabel>
            <Select
              labelId="select-TypePreparation"
              id="select-TypePreparation"
              value={RaisonJetter}
              onChange={(e) => setRaisonJetter(e.target.value)}
            >
              <MenuItem key={"Pourrie"} value={"Pourrie"}>
                Pourrie
              </MenuItem>
              <MenuItem key={"Tombee"} value={"Tombee"}>
                Tombee
              </MenuItem>
              <MenuItem key={"abime"} value={"abime"}>
                Cassé ou abimé
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <ButtonJeterPrep
            PrepData={{ ...Prep, QtePrepAJetter, RaisonJetter }}
          />
        </Typography>
      </Box>
    </Modal>
  );
};

export default ModalJetterPrep;
