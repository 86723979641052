import React, { useState, useEffect } from "react";
import "./ConssomationTheorique.css";
import TableAnalysePrepConssomation from "./TableAnalysePrepConssomation/TableAnalysePrepConssomation";
import TableAnalyseIngredientConssomation from "./TableAnalyseIngredientConssomation/TableAnalyseIngredientConssomation";

const ConssomationTheorique = ({ dateStart, dateEnd }) => {
  return (
    <div className="ContenerConssomationTheorique">
      <TableAnalysePrepConssomation
        RestaurantName={"RestaurantSainteFoy"}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
      <TableAnalyseIngredientConssomation
        RestaurantName={"RestaurantSainteFoy"}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
    </div>
  );
};

export default ConssomationTheorique;
