import React, { useState, useEffect } from "react";
import "./SelectProductAssemblage.css";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  getFirestore,
  limit,
  query,
  orderBy,
} from "firebase/firestore";
import { app } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import { PassOrderToInPackage } from "../../../Functions/HandleClickFunctions";
import { GetOrderReadyToAssemblage } from "../../../Data/firebaseService";

const SelectProductAssemblage = () => {
  const [listeOrderToDo, setlisteOrderToDo] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [isProcessing, setIsProcessing] = useState(false);

  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"),
    limit(100)
  );

  const [Orders] = useCollection(ordersQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [PosteDeTravail] = useCollection(
    collection(getFirestore(app), "PosteDeTravail"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  useEffect(() => {
    if (Orders) {
      async function fetchOrders() {
        const OrderToDo = await GetOrderReadyToAssemblage(Orders.docs);
        setlisteOrderToDo(OrderToDo);
      }
      fetchOrders();
    }
  }, [Orders, PosteDeTravail]);

  const HandelPassOrderToInPackage = async (userID, orderID) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    try {
      // Votre code pour allouer le produit au poste de travail
      await PassOrderToInPackage(userID, orderID);

      setIsProcessing(false);
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };

  return (
    <div className="SelctionCommandes">
      {listeOrderToDo &&
        listeOrderToDo.map((order) => {
          return (
            <div
              className="ProductBox"
              onClick={() =>
                HandelPassOrderToInPackage(user.uid, order.id.toString())
              }
            >
              <div className="ProductName"> {order.id}</div>
              <div className="ProductName"> {order.dataOrder.order.mode}</div>
            </div>
          );
        })}
    </div>
  );
};

export default SelectProductAssemblage;
