import React, { useState, useEffect } from "react";
import "./AddMatiere.css";
import ModalAvecFunction from "../../Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField, Button } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import {
  addNewMatiereVisitorV2,
  getAllIngredient,
  getAllFournisseur,
} from "../../../../Data/firebaseInteractionVisitorsV2";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

import SearchBarMatiere from "../../SearchBarMatiere/SearchBarMatiere";
import UploadImage from "../../../UploadImage/UploadImage";

const AddMatiere = ({ userID }) => {
  const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
  const [AllIngredient, setAllIngredient] = useState(null);
  const [AllFournisseur, setAllFournisseur] = useState(null);
  const [ResultMatiere, setResultMatiere] = useState(null);
  const [DataForm, setDataForm] = useState({});
  const [img, setImag] = useState(null);

  const handleOpenCreateProductModal = () => {
    setOpenCreateProductModal(true);
  };

  const handleCloseCreateProductModal = () => {
    setOpenCreateProductModal(false);
  };

  const [ProductionVisitorV2] = useCollection(
    collection(getFirestore(app), "ProductionVisitorV2"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [Ingredients] = useCollection(
    collection(getFirestore(app), "Ingredients"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const Data = await getAllIngredient();
      setAllIngredient(Data);
      const Fournisseur = await getAllFournisseur();
      setAllFournisseur(Fournisseur);
    }
    fetchOrders();
  }, [ProductionVisitorV2, Ingredients]);

  return (
    <div>
      <div
        className="ButtonAddMatiere"
        onClick={() => handleOpenCreateProductModal()}
      >
        ADD Matiere
      </div>
      <ModalAvecFunction
        open={openCreateProductModal}
        handleClose={handleCloseCreateProductModal}
        Form={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            <div className="SelectIngredientContener">
              <div>CHOISIR UN INGREDIENT :</div>
              <SearchBarMatiere
                UseEffectFunction={getAllIngredient}
                setResult={setResultMatiere}
              />
              {ResultMatiere === null ? (
                <div className="ListeIngredient">
                  {AllIngredient &&
                    AllIngredient.map((ingredient) => (
                      <div
                        className={
                          DataForm.IngredientID === ingredient.IDPepers
                            ? "SelectedIngredient"
                            : "SelectIngredient"
                        }
                        onClick={() =>
                          setDataForm((prevData) => ({
                            ...prevData,
                            IngredientID: ingredient.IDPepers,
                          }))
                        }
                      >
                        {ingredient.name}
                      </div>
                    ))}
                </div>
              ) : (
                <div className="ListeIngredient">
                  {ResultMatiere.map((ingredient) => (
                    <div
                      className={
                        DataForm.IngredientID === ingredient.IDPepers
                          ? "SelectedIngredient"
                          : "SelectIngredient"
                      }
                      onClick={() =>
                        setDataForm((prevData) => ({
                          ...prevData,
                          IngredientID: ingredient.IDPepers,
                        }))
                      }
                    >
                      {ingredient.name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="SelectFournisseurContener">
              CHOISIR UN FOURNISSEUR :
              {AllFournisseur &&
                AllFournisseur.map((Fournisseur) => (
                  <div
                    className={
                      DataForm.FournisseurID === Fournisseur.IDPepers
                        ? "SelectedIngredient"
                        : "SelectIngredient"
                    }
                    onClick={() =>
                      setDataForm((prevData) => ({
                        ...prevData,
                        FournisseurID: Fournisseur.IDPepers,
                      }))
                    }
                  >
                    {Fournisseur.name}
                  </div>
                ))}
            </div>

            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  NomChezFournisseur: event.target.value,
                }))
              }
              label="Nom Chez Le Fournisseur"
              value={DataForm.NomChezFournisseur}
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  REFfournisseur: event.target.value,
                }))
              }
              label="REF Fournisseur"
              value={DataForm.REFfournisseur}
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  PrixHT: event.target.value,
                }))
              }
              label="PrixHT"
              value={DataForm.PrixHT}
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  Conditionnement: event.target.value,
                }))
              }
              label="Conditionnement"
              value={DataForm.Conditionnement}
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  QteConditionnement: event.target.value,
                }))
              }
              label="Qte Du Conditionnement Par unite de recette"
            />
            <div style={{ marginTop: "15px" }}>
              <UploadImage setImag={setImag} />
            </div>
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter une matiere"}
        DataForm={{ ...DataForm, img }}
        Function={addNewMatiereVisitorV2}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default AddMatiere;
