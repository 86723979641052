import React, { useState } from "react";
import "./AddNewFournisseur.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { AddNewFournisseurVisitor } from "../../../../Data/firebaseInteractionVisitorsV2";
import { TextField, Button } from "@material-ui/core";

const AddNewFournisseur = () => {
  const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
  const [DataForm, setDataForm] = useState({});

  const handleOpen = () => {
    setOpenCreateProductModal(true);
  };

  const handleClose = () => {
    setOpenCreateProductModal(false);
  };

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpen()}>
        ADD Fourniseur
      </div>
      <ModalAvecFunction
        open={openCreateProductModal}
        handleClose={handleClose}
        Form={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }))
              }
              label="Nom du fournisseur"
              value={DataForm.name}
            />
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter un fournisseur"}
        DataForm={{ ...DataForm }}
        setDataForm={setDataForm}
        Function={AddNewFournisseurVisitor}
      />
    </div>
  );
};

export default AddNewFournisseur;
