import React, { useState, useEffect } from "react";
import "./MainDashCreationNewProductInterface.css";
import { auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

const MainDashCreationNewProductInterface = () => {
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    async function fetchUserBrand() {}
    fetchUserBrand();
  }, [user]);

  return (
    <div className="MainDash">
      <div>cards</div>
      <div>Table</div>
    </div>
  );
};

export default MainDashCreationNewProductInterface;
