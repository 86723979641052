import React,{ useEffect, useState } from "react";
import './Cards.css'
import Card from "../Card/Card";

import {getCardsData } from '../../Data/firebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebaseConfig';
import { useRecoilValue } from 'recoil';
import { selectOrderMode } from '../../atoms/SelectOrderModeAtome';

const Cards=()=>{
    const [user, loading, error] = useAuthState(auth);
    const [CardsData, setCardsData] = useState([]);
    const SelectOrderMode = useRecoilValue(selectOrderMode); 
    
  useEffect(() => {
    async function fetchOrders() {
      const date = [SelectOrderMode.dateSatart,SelectOrderMode.dateEnd]
      const cardData = await getCardsData(user.uid, date);
      setCardsData(cardData);
    }
    fetchOrders();
  }, [SelectOrderMode]);
    return(
        <div className="Cards">
            {CardsData.map((card,id)=>{
                return(
                    <div className="parentContainer">
                        <Card
                        title = {card.title}
                        sousTitre = {card.sousTitre}
                        color = {card.color}
                        barValue = {card.barValue}
                        value={card.value}
                        png = {card.png}
                        series= {card.series}
                        categorie = {card.categorie}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default Cards