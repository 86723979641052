import React from "react";
import RightSide from "../../components/RightSide/RightSide";
import MainDash from "../../components/MainDash/MainDash";

const Dashboard = () =>{

    return(<>
          <MainDash/>
          <RightSide/>
    </>)
}

export default Dashboard