import { atom } from "recoil";

const defaultSelectOrderMode = {
  view: "Deliveroo",
  dateSatart: new Date("2023-05-30T11:00:00+02:00"),
  dateEnd: new Date(),
};

export const selectOrderMode = atom({
  key: "selectOrderMode",
  default: defaultSelectOrderMode,
});
