import React, { useState, useEffect } from "react";
import "./DisplayInventairePrep.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

const DisplayInventairePrep = ({ DataPrep }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "300px", boxShadow: "0px 13px 20px 0px #80808029" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nom Preparation</TableCell>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Qte inv</TableCell>
            <TableCell align="left">Unite</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ color: "white" }}>
          {DataPrep.map((Prep) => (
            <TableRow
              key={Prep.IDPepers}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {Prep.name}
              </TableCell>
              <TableCell align="left">
                {" "}
                <Avatar alt={Prep.name} src={Prep.img} />
              </TableCell>
              <TableCell align="left">{Prep.QteInventaire}</TableCell>
              <TableCell align="left">{Prep.UniteDeMesure}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DisplayInventairePrep;
