import React, { useState, useEffect } from "react";
import "./FicheTechniquePrepV2.css";
import {
  getPrepWithId,
  getMatiereDataInPrep,
} from "../../../Data/firebaseService";

const FicheTechniquePrepV2 = ({ Nomprep }) => {
  const [Prep, setPrep] = useState(null);
  const [ListeMatierePremiereData, setListeMatierePremiereData] =
    useState(null);

  useEffect(() => {
    async function fetchOrders() {
      const Order = await getPrepWithId(Nomprep);
      setPrep(Order);
      const ListeMatiere = Order.PreparationOrder.map((matiere) => matiere.Nom);
      const MatiereDataInPrep = await getMatiereDataInPrep(ListeMatiere);
      setListeMatierePremiereData(MatiereDataInPrep);
    }
    fetchOrders();
  }, [Prep]);

  return (
    <div className="MainFicheTechniQuePrep">
      <div className="Ingredient">
        {Prep &&
          Prep.MatierePremiereUtilisees.map((matiere) => {
            return (
              <div className="SingleIngrediant">
                <div>{matiere.Name}</div>
                <div></div>
                <div>
                  {ListeMatierePremiereData &&
                    ListeMatierePremiereData.filter(
                      (matiereData) => matiereData.Nom === matiere.Name
                    )[0]["Unité de recettes"]}
                </div>
              </div>
            );
          })}
      </div>
      <div className="ExplicationPrep">
        {Prep && Prep.hasOwnProperty("Recette") && <div>{Prep.Recette}</div>}
      </div>
    </div>
  );
};

export default FicheTechniquePrepV2;
