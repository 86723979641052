import React, { useState } from "react";
import "./HeaderChoixDuRestaurant.css";

const HeaderChoixDuRestaurant = () => {
  return (
    <div className="ContenerHeaderChoixDuRestaurant">
      <div className="TextBienvenue">
        Bienvenue au restuarant du : Rue sainte foy{" "}
      </div>
      <div className="choixresto">Choix du restuarant</div>
    </div>
  );
};

export default HeaderChoixDuRestaurant;
