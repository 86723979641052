import React, {useState, useEffect} from "react";
import SearchBar from '../SearchBar/SearchBar'
import SearchBarZeltyProduct from '../SearchBar/SearchBarZeltyProduct/SearchBarZeltyProduct'
import './CreationProduct.css'
import { useRecoilState } from 'recoil';
import {CreationProductAtom} from '../../atoms/CreationProductAtom'
import SearchBarBrand from "../SearchBar/SearchBarBrand/SearchBarBrand";

const CreationProduct = () => {
    const [ProductAtom, setProductAtom] = useRecoilState(CreationProductAtom);
    return (<>
        <div className="Creation-produit">
            <SearchBarZeltyProduct/>
            <SearchBar/>
            <SearchBarBrand/>
            <div></div>
        </div>
        </>
    );
  };
  

export default CreationProduct