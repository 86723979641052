import React, { useState, useEffect } from "react";
import "./ValiderCommandeMatierePremiere.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import {
  HandleValidateMatiereOrder,
  getMatiereOrderDataWithMatiereData,
} from "../../../../Data/firebaseInteractionOwnerV2";
import SearchBarGenerique from "../../../OwnerV2Components/SearchBarGenerique/SearchBarGenerique";
import Avatar from "@mui/material/Avatar";
import { TextField, Button } from "@material-ui/core";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

const ValiderCommandeMatierePremiere = ({
  RestaurantName,
  CommandeMatiereID,
}) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    RestaurantName,
    CommandeMatiereID,
  });
  const [MatiereOrder, setMatiereOrder] = useState(null);

  async function fetchOrders() {
    const MatiereOrder = await getMatiereOrderDataWithMatiereData(
      RestaurantName,
      CommandeMatiereID
    );
    setMatiereOrder(MatiereOrder);
    setDataForm((prevdata) => {
      let qteMatiereInOrder = {};
      MatiereOrder.MatiereOrder.forEach((matiere) => {
        qteMatiereInOrder[matiere.IDPepers] = matiere.QteOrder;
      });

      return {
        ...prevdata,
        QteMatiereOrder: { ...qteMatiereInOrder },
        RestaurantName: RestaurantName,
        CommandeMatiereID: CommandeMatiereID,
      };
    });
  }

  const handleOpenModal = async () => {
    await fetchOrders();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);
  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        Valider
      </div>
      {OpenModal && (
        <ModalAvecFunction
          open={OpenModal}
          handleClose={handleCloseModal}
          Form={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "500px",
              }}
            >
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                {MatiereOrder &&
                  MatiereOrder.MatiereOrder.map((Matiere) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>{Matiere.NomChezFournisseur}</div>
                      {/*<Avatar
                      style={{ marginLeft: "15px", marginRight: "15px" }}
                      alt={ActifIngredient.name}
                      src={ActifIngredient.Img}
                />*/}
                      <TextField
                        onChange={(event) =>
                          setDataForm((prevData) => ({
                            ...prevData,
                            QteMatiereOrder: {
                              ...prevData.QteMatiereOrder,
                              [Matiere.IDPepers]: event.target.value,
                            },
                          }))
                        }
                        style={{ maxWidth: "50px" }}
                        label="Qte"
                        value={
                          DataForm.hasOwnProperty("QteMatiereOrder") &&
                          DataForm.QteMatiereOrder[Matiere.IDPepers]
                        }
                      />
                      {Matiere.Conditionnement}
                    </div>
                  ))}
              </div>
            </div>
          }
          TextActionButton={"Valider"}
          TitleModal={"Valider la commande"}
          DataForm={{ ...DataForm }}
          Function={HandleValidateMatiereOrder}
          setDataForm={setDataForm}
        />
      )}
    </div>
  );
};

export default ValiderCommandeMatierePremiere;
