import React, { useEffect, useState } from "react";
import { GetAnalyseViaCommandesRecuAuRestau } from "../../../../Data/firebaseService";
import GestionPrep from "../GestionPrep/GestionPrep";
import MatiereConssomeEntreDeuxInventaire from "../MatiereConssomeEntreDeuxInventaire/MatiereConssomeEntreDeuxInventaire";
import NavBarHorisontale from "../../../NavBar/NavBarHorisontale/NavBarHorisontale";

import "./AnalyseConssomationReelle.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const AnalyseConssomationReelle = ({ startDate, endDate }) => {
  const [navRow, setNavRow] = useState(0);

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, [startDate, endDate]);

  return (
    <>
      <div className="ContenerTable">
        <div>Analyse Via commandes recu au restuarant </div>
        <div className="MainDataDisplay">
          <div>CA</div>
          <div>Cout Matiere</div>
          <div>Facture par vbb HT</div>
          <div>Marge Moyenne</div>

          {1 === 1 && (
            <>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </>
          )}
        </div>
        <NavBarHorisontale
          Data={[
            "Gestion Preparation",
            "Conssomation entre deux inventaire",
            "Ecart de conssomation",
          ]}
          setNavRow={setNavRow}
          navRow={navRow}
        />
        {navRow === 0 && (
          <GestionPrep startDate={startDate} endDate={endDate} />
        )}
      </div>
      {navRow === 1 && (
        <MatiereConssomeEntreDeuxInventaire
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
};
export default AnalyseConssomationReelle;
