import React, { useEffect, useState } from "react";
import { GetAnalyseViaCommandesRecuAuRestau } from "../../../../Data/firebaseService";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ModalDisplayPrepInOrder from "../../../Modal/ModalDisplayPrepInOrder/ModalDisplayPrepInOrder";

import "./AnalyseViaCommandesRecuAuRestau.css";

import ModalSeeMoreDetailOrderForControlManager from "../../../Modal/ModalSeeMoreDetailOrderForControlManager/ModalSeeMoreDetailOrderForControlManager";
import { set } from "date-fns";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const AnalyseViaCommandesRecuAuRestau = ({ startDate, endDate }) => {
  const [DataMatiereRecu, setDataMatiereRecu] = useState(null);
  const [SelectedCommande, setSelectedCommande] = useState(null);
  const [open, setOpen] = useState(false);
  const [navRow, setNavRow] = useState(0);
  const [SelectedPrep, setSelectedPrep] = useState(null);

  const [selectedPrepInOrder, setSelectedPrepInOrder] = useState(null);
  const [openModalDisplayPrepInOrder, setOpenModalDisplayPrepInOrder] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const Data = await GetAnalyseViaCommandesRecuAuRestau(
        startDate,
        endDate,
        23,
        59,
        0,
        1
      );

      setDataMatiereRecu(Data);
    };
    fetchData();
  }, [startDate, endDate]);
  console.log(startDate);
  console.log(endDate);

  const handleOpen = (Commande) => {
    setSelectedCommande(Commande);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCommande(null);
  };

  const handleOpenModalDisplayPrepInOrder = (PrepName) => {
    setOpenModalDisplayPrepInOrder(true);
    setSelectedPrepInOrder(PrepName);
  };

  const handleCloseModalDisplayPrepInOrder = () => {
    setOpenModalDisplayPrepInOrder(false);
    setSelectedPrepInOrder(null);
  };

  return (
    <>
      <div className="ContenerTable">
        <div>Analyse Via commandes recu au restuarant </div>
        <div className="MainDataDisplay">
          <div>CA</div>
          <div>Cout Matiere</div>
          <div>Facture par vbb HT</div>
          <div>Marge Moyenne</div>

          {DataMatiereRecu && (
            <>
              <div>{DataMatiereRecu.CADeTouteLesCommandesAnalysee}</div>
              <div>{DataMatiereRecu.CoutTotalMatierePourVBB}</div>
              <div>{DataMatiereRecu.TotalPrixDeVentePourVBB}</div>
              <div>{DataMatiereRecu.MargeMoyenne}</div>
            </>
          )}
        </div>
        <div className="navAnaylseCommande">
          <div
            className={navRow === 0 ? "navRowSelected" : "navRow"}
            onClick={() => setNavRow(0)}
          >
            Analyse Cout de revient
          </div>
          <div
            className={navRow === 1 ? "navRowSelected" : "navRow"}
            onClick={() => setNavRow(1)}
          >
            Conssomation Theorique Prep
          </div>
          <div
            className={navRow === 2 ? "navRowSelected" : "navRow"}
            onClick={() => setNavRow(2)}
          >
            Conssomation Theorique Matiere
          </div>
        </div>
        {navRow === 0 && (
          <TableContainer
            component={Paper}
            style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date creation</TableCell>
                  <TableCell align="left">Coef Marge</TableCell>
                  <TableCell align="left">Cout Produit</TableCell>
                  <TableCell align="left">Prix de vente VBB HT</TableCell>
                  <TableCell align="left">Provenance</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ color: "white" }}>
                {DataMatiereRecu != null &&
                  DataMatiereRecu.ListeCommandePrisePourAnalyseWithAddCalculateData.map(
                    (Commande) => (
                      <TableRow
                        key={Commande.dataOrder.order.created_at}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {Commande.dataOrder.order.created_at}
                        </TableCell>
                        <TableCell align="left">
                          {Commande.DataAddParVBB.CoefMargeSurMatierePremiere}
                        </TableCell>
                        <TableCell align="left">
                          {Commande.DataAddParVBB.CoutProduitDansCommande}
                        </TableCell>
                        <TableCell align="left">
                          {Commande.DataAddParVBB.PrixVenteVBBHT}
                        </TableCell>
                        <TableCell align="left">
                          {Commande.dataOrder.order.channel}
                        </TableCell>
                        <TableCell
                          onClick={() => handleOpen(Commande)}
                          align="left"
                          className="Details"
                        >
                          Voir plus
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {SelectedCommande && (
          <ModalSeeMoreDetailOrderForControlManager
            open={open}
            handleClose={handleClose}
            Commande={SelectedCommande}
          />
        )}
      </div>
      {navRow === 1 && (
        <>
          <div className="ConssomationTheoriquePrep">
            {DataMatiereRecu &&
              Object.keys(DataMatiereRecu.CompteurPrep).map((PrepName) => {
                return (
                  <div
                    className="RowConssomationTheoriquePrep"
                    onClick={() => handleOpenModalDisplayPrepInOrder(PrepName)}
                  >
                    <div>{PrepName}</div>
                    <div>Qte :{DataMatiereRecu.CompteurPrep[PrepName]}</div>
                  </div>
                );
              })}
          </div>
        </>
      )}
      {selectedPrepInOrder && (
        <ModalDisplayPrepInOrder
          open={openModalDisplayPrepInOrder}
          handleClose={handleCloseModalDisplayPrepInOrder}
          Orders={
            DataMatiereRecu.ListeCommandePrisePourAnalyseWithAddCalculateData
          }
          PrepName={selectedPrepInOrder}
        />
      )}
    </>
  );
};
export default AnalyseViaCommandesRecuAuRestau;
