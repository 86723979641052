import React from "react";
import './RightSideWallet.css'
import CustomerReview from "../../CustomerReview/CustomerReview";
import ClientPricing from "../../Updates/Clientpricing/Clientpricing";

const RightSideWallet= () =>{
    return(
        <div className="RightSide">
            <div>
                <h3>Facturation</h3>
                <ClientPricing/>
            </div>
            <div>
               
            </div>
        </div>
    )
}

export default RightSideWallet