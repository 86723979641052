import React, { useState } from "react";
import "./ProduitActifPrepAndMatiereAuResto.css";
import DisplayActifProductInTheRestaurant from "../../../OwnerV2Components/LesRestaurantPageComponant/ProduitActifPrepAndMatiereAuResto/DisplayActifProductInTheRestaurant/DisplayActifProductInTheRestaurant";
import DisplayPrepAndIngredientInTheRestaurant from "../../../OwnerV2Components/LesRestaurantPageComponant/DisplayPrepAndIngredientInTheRestaurant/DisplayPrepAndIngredientInTheRestaurant";
const ProduitActifPrepAndMatiereAuResto = () => {
  return (
    <div className="ContenerProduitActifPrepAndMatiereAuResto">
      <DisplayActifProductInTheRestaurant
        RestaurantName={"RestaurantSainteFoy"}
      />
      <DisplayPrepAndIngredientInTheRestaurant
        RestaurantName={"RestaurantSainteFoy"}
      />
    </div>
  );
};

export default ProduitActifPrepAndMatiereAuResto;
