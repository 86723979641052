import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./ListePreparations.css";

import { getListePreparationsData } from "../../../Data/firebaseService";

const ListePreparations = () => {
  const [data, setData] = useState([{ Nom: "Loading" }]);

  useEffect(() => {
    async function fetchData() {
      const data = await getListePreparationsData();
      setData(data);
    }
    fetchData();
  }, []);
  return (
    <div className="Updates">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Preparation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.Nom} -- {row.CoutHT} € / {row.Quantite}
                  {row.Unite}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ListePreparations;
