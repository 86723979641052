import React, { useState } from "react";
import "./NavBarVerticaleSousCategories.css";

const NavBarVerticaleSousCategories = ({ Data, setNavRow, navRow }) => {
  return (
    <div className="NavBarVerticaleSousCategories">
      {Data.map((row, index) => (
        <div
          className={
            navRow === index ? "navRowVerticaleSelected" : "navRowVerticale"
          }
          onClick={() => setNavRow(index)}
        >
          {row}
        </div>
      ))}
    </div>
  );
};

export default NavBarVerticaleSousCategories;
