import React, { useState } from "react";
import "./AddOption.css";
import CenterAddOption from "./CenterAddOption/CenterAddOption";
import ChoosePrepOrMatiereInOption from "./ChoosePrepOrMatiereInOption/ChoosePrepOrMatiereInOption";
import SmallHeaderAddOption from "./SmallHeaderAddOption/SmallHeaderAddOption";

const AddOption = ({ OptionID }) => {
  const [InfoOptionData, setInfoOptionData] = useState({});
  const [OptionChoice, setOptionChoice] = useState([]);
  const [selectedOptionValue, setSelectedOptionValue] = useState(null);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "1fr 5fr 4fr",
          maxHeight: "600px",
          gap: "10px",
        }}
      >
        <SmallHeaderAddOption
          OptionChoice={OptionChoice}
          InfoOptionData={InfoOptionData}
          setInfoOptionData={setInfoOptionData}
          setOptionChoice={setOptionChoice}
        />
        <CenterAddOption
          InfoOptionData={InfoOptionData}
          setInfoOptionData={setInfoOptionData}
          OptionChoice={OptionChoice}
          setOptionChoice={setOptionChoice}
          selectedOptionValue={selectedOptionValue}
          setSelectedOptionValue={setSelectedOptionValue}
        />
        <ChoosePrepOrMatiereInOption
          selectedOptionValue={selectedOptionValue}
          setOptionChoice={setOptionChoice}
        />
      </div>
    </>
  );
};

export default AddOption;
