import React, { useState } from "react";
import "./AddNewConditionnementLogistiqueV2.css";
import ModalAddNewConditionnementLogistiqueV2 from "../../Modal/ModalAddNewConditionnementLogistiqueV2/ModalAddNewConditionnementLogistiqueV2";

export default function AddNewConditionnementLogistiqueV2({ prep }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <button onClick={handleOpen}>Add new conditionnement Logistique</button>
      <ModalAddNewConditionnementLogistiqueV2
        open={open}
        handleClose={handleClose}
        prep={prep}
      />
    </>
  );
}
