import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  getFirestore,
  query,
  orderBy,
  limit,
  collection,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage, app } from "../firebaseConfig";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import {
  GetProductPricing,
  GetSupplementPricing,
  CheckIfModifyStateProductImplicateNewPreparationorRemovePrep,
  CalculChargeFixeForNewPreparationAndNewMatiere,
} from "../Data/firebaseService";
import { el } from "date-fns/locale";
import { all } from "axios";

export async function AlocateProductToWorkPlace(
  userID,
  Product,
  PlanDetravail,
  listeProductToDo
) {
  const WorkPlaceDocRef = doc(firestore, "PosteDeTravail", PlanDetravail);
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allData = docSnapshot.data();
  // On verifie si le workplace est vide avant d'ajouter ce produit
  // Si le workplace est vide on lance le crono pour comptabiliser le temps ou le poste de travail fonctionne
  {
    /*if (allData.ProductInProduction.length === 0) {
    const WorkplaceWorkingTimeDocRef = doc(
      firestore,
      "ManagerRestaurant",
      "WorkplaceWorkingTime"
    );
    const WorkplaceWorkingTimedocSnapshot = await getDoc(
      WorkplaceWorkingTimeDocRef
    );
    const allDataWorkplaceWorkingTime = WorkplaceWorkingTimedocSnapshot.data();

    const NewDataWorkplaceWorkingTime = {
      ...allDataWorkplaceWorkingTime,
      WorkplaceWorkingTime: [
        ...allDataWorkplaceWorkingTime.WorkplaceWorkingTime,
        {
          EvenementType: "Startwatch",
          Workplace: PlanDetravail,
          Time: new Date(),
        },
      ],
    };
    await setDoc(WorkplaceWorkingTimeDocRef, NewDataWorkplaceWorkingTime);
  }*/
  }
  // Met a jours la base de donnée avec les produits deja atribué + le nouveau
  let NewData = null;
  if (Product.hasOwnProperty("ProductGroup")) {
    let ArrayOfAllTheProductInTheGroup = listeProductToDo.filter(
      (productTodo) => {
        return (
          productTodo.hasOwnProperty("ProductGroup") &&
          productTodo.ProductGroup.id === Product.ProductGroup.id
        );
      }
    );
    //On formate les donnees

    ArrayOfAllTheProductInTheGroup = ArrayOfAllTheProductInTheGroup.map(
      (Product) => {
        return {
          ProductIDInOrder: Product.id,
          userID,
          modifiers: Product.modifiers,
          ProductName: Product.name,
          OrderID: Product.commandeId,
          timeMiseEnProduction: new Date(),
        };
      }
    );

    NewData = {
      ...allData,
      ProductInProduction: [
        ...allData.ProductInProduction,
        ...ArrayOfAllTheProductInTheGroup,
      ],
    };
  } else {
    NewData = {
      ...allData,
      ProductInProduction: [
        ...allData.ProductInProduction,
        {
          ProductIDInOrder: Product.id,
          modifiers: Product.modifiers,
          userID,
          ProductName: Product.name,
          OrderID: Product.commandeId,
          timeMiseEnProduction: new Date(),
        },
      ],
    };
  }

  await setDoc(WorkPlaceDocRef, NewData);

  {
    /*// On enregitre l'heure a laquelle l'utilisateur a fait passer le produit dans le plan de travail
  const DataWorkPlaceDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "DataKitchen"
  );
  const DataWorkPlacedocSnapshot = await getDoc(DataWorkPlaceDocRef);
  const allDataDataWorkPlace = DataWorkPlacedocSnapshot.data();

  await setDoc(DataWorkPlaceDocRef, {
    ...allDataDataWorkPlace,
    DataKitchen: [
      ...allDataDataWorkPlace.DataKitchen,
      {
        Type: "AllocateWorkplace",
        ProductIDInOrder: Product.id,
        modifiers: Product.modifiers,
        userID,
        ProductName: Product.name,
        OrderID: Product.commandeId,
        timeMiseEnProduction: new Date(),
        WorkPlace: PlanDetravail,
      },
    ],
  });*/
  }

  return null;
}

export async function CreatAskOfDepositBankTransfer(userID, Amount) {
  const WorkPlaceDocRef = doc(firestore, "users", userID.toString());
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allBankTransaction = docSnapshot.data().Wallet.BankTransaction;
  const allUserData = docSnapshot.data();
  // Met a jours la base de donnée avec les produits deja atribué + le nouveau
  const NewData = {
    ...allUserData,
    Wallet: {
      ...allUserData.Wallet,
      BankTransaction: [
        ...allBankTransaction,
        { Amount, Detail: "Dépôt", Etat: "Pending", Time: new Date() },
      ],
    },
  };
  await setDoc(WorkPlaceDocRef, NewData);

  return null;
}

export async function CancelAskOfDepositBankTransfer(userID, index) {
  const WorkPlaceDocRef = doc(firestore, "users", userID.toString());
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allBankTransaction = docSnapshot.data().Wallet.BankTransaction;
  allBankTransaction.splice(index, 1);
  const allUserData = docSnapshot.data();
  // Met a jours la base de donnée avec les produits deja atribué + le nouveau
  const NewData = {
    ...allUserData,
    Wallet: { ...allUserData.Wallet, BankTransaction: [...allBankTransaction] },
  };
  await setDoc(WorkPlaceDocRef, NewData);

  return null;
}

export async function ChangeProductPreparationQte(
  PreparationName,
  ProductName,
  QtePreparation
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;
  // defini les nouvelles preparation du produit avec les qte
  let newPreparationQte = [];
  allDishes.forEach((dishe) => {
    if (ProductName === dishe.Production.name) {
      dishe.Production.PreparationIntermediaire.forEach(
        (preparation, index) => {
          if (preparation.Name === PreparationName) {
            newPreparationQte = dishe.Production.PreparationIntermediaire.slice(
              0,
              index
            );
            newPreparationQte = [
              ...newPreparationQte,
              { Name: PreparationName, Qte: QtePreparation },
              ...dishe.Production.PreparationIntermediaire.slice(index + 1),
            ];
          }
        }
      );
    }
  });
  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (ProductName === dishe.Production.name) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        {
          ...dishe,
          Production: {
            ...dishe.Production,
            PreparationIntermediaire: newPreparationQte,
          },
        },
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { dishes: allNewDishes });
  return null;
}
export async function ChangeProductPreparationOrdreDesPreparations(
  ListePrep,
  ProductName
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;
  //On formate la liste des preparations recu au bon format
  const NewProductPrep = ListePrep.map((prep) => ({
    Name: prep.Nom,
    Qte: prep.Qte,
  }));
  // Ajouter dans la base de données le nouvelles Ordre des preparation dans le produit
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (ProductName === dishe.Production.name) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        {
          ...dishe,
          Production: {
            ...dishe.Production,
            PreparationIntermediaire: NewProductPrep,
          },
        },
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { dishes: allNewDishes });
  return null;
}

export async function ChangeProductPreparationQteForTestProduct(
  PreparationName,
  ProductName,
  QtePreparation
) {
  if (ProductName === "") {
    console.log("Le produit n'exites pas");
    return;
  } else {
    const WorkPlaceDocRef = doc(firestore, "Production", "DishTestUser");
    const docSnapshot = await getDoc(WorkPlaceDocRef);
    const allDishes = docSnapshot.data().DishTestUser;
    // defini les nouvelles preparation du produit avec les qte
    let newPreparationQte = [];
    allDishes.forEach((dishe) => {
      if (ProductName === dishe.Production.name) {
        dishe.Production.PreparationIntermediaire.forEach(
          (preparation, index) => {
            if (preparation.Name === PreparationName) {
              newPreparationQte =
                dishe.Production.PreparationIntermediaire.slice(0, index);
              newPreparationQte = [
                ...newPreparationQte,
                { Name: PreparationName, Qte: QtePreparation },
                ...dishe.Production.PreparationIntermediaire.slice(index + 1),
              ];
            }
          }
        );
      }
    });
    // Ajouter dans la base de données le nouvelles qte des preparation du produits
    let allNewDishes = [];
    allDishes.forEach((dishe, index) => {
      if (ProductName === dishe.Production.name) {
        allNewDishes = [
          ...allDishes.slice(0, index),
          {
            ...dishe,
            Production: {
              ...dishe.Production,
              PreparationIntermediaire: newPreparationQte,
            },
          },
          ...allDishes.slice(index + 1),
        ];
      }
    });

    await setDoc(WorkPlaceDocRef, { DishTestUser: allNewDishes });
  }
  return null;
}

export async function ChangePrepQteForPrepOrdersrequest(
  PreparationName,
  QtePreparation,
  DateLivraison
) {
  const WorkPlaceDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allOrdersPreparations = docSnapshot.data().Commandes;
  // defini les nouvelles preparation du produit avec les qte
  let newPreparationQte = [];
  allOrdersPreparations.forEach((OrderPreparation) => {
    let DateLivraisonOrders = new Date(
      OrderPreparation.DateLivraison.seconds * 1000
    );
    if (
      DateLivraison.getDate() === DateLivraisonOrders.getDate() &&
      DateLivraison.getMonth() === DateLivraisonOrders.getMonth() &&
      DateLivraison.getFullYear() === DateLivraisonOrders.getFullYear()
    ) {
      OrderPreparation.PreparationOrder.forEach((preparation, index) => {
        if (preparation.Nom === PreparationName) {
          newPreparationQte = OrderPreparation.PreparationOrder.slice(0, index);
          newPreparationQte = [
            ...newPreparationQte,
            {
              ...OrderPreparation.PreparationOrder[index],
              Qte: QtePreparation,
            },
            ...OrderPreparation.PreparationOrder.slice(index + 1),
          ];
        }
      });
    }
  });

  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewDishes = [];
  allOrdersPreparations.forEach((Order, index) => {
    let DateLivraisonOrders = new Date(Order.DateLivraison.seconds * 1000);
    if (
      DateLivraison.getDate() === DateLivraisonOrders.getDate() &&
      DateLivraison.getMonth() === DateLivraisonOrders.getMonth() &&
      DateLivraison.getFullYear() === DateLivraisonOrders.getFullYear()
    ) {
      allNewDishes = [
        ...allOrdersPreparations.slice(0, index),
        {
          ...Order,
          PreparationOrder: newPreparationQte,
        },
        ...allOrdersPreparations.slice(index + 1),
      ];
    }
  });
  await setDoc(WorkPlaceDocRef, { Commandes: allNewDishes });
  return null;
}

export async function AddProductPreparationQte(
  PreparationName,
  ProductName,
  QtePreparation
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;
  // defini les nouvelles preparation du produit avec les qte
  let newPreparationQte = [];
  allDishes.forEach((dishe) => {
    if (ProductName === dishe.Production.name) {
      if (dishe.Production.PreparationIntermediaire.length === 0) {
        newPreparationQte = [{ Name: PreparationName, Qte: QtePreparation }];
        return;
      }
      dishe.Production.PreparationIntermediaire.forEach(
        (preparation, index) => {
          if (preparation.Name === PreparationName) {
            newPreparationQte = dishe.Production.PreparationIntermediaire.slice(
              0,
              index
            );
            newPreparationQte = [
              ...newPreparationQte,
              { Name: PreparationName, Qte: QtePreparation },
              ...dishe.Production.PreparationIntermediaire.slice(index + 1),
            ];
          } else {
            newPreparationQte = dishe.Production.PreparationIntermediaire.slice(
              0,
              index + 1
            );
            newPreparationQte = [
              ...newPreparationQte,
              { Name: PreparationName, Qte: QtePreparation },
              ...dishe.Production.PreparationIntermediaire.slice(index + 2),
            ];
          }
        }
      );
    }
  });

  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (ProductName === dishe.Production.name) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        {
          Production: {
            ...dishe.Production,
            PreparationIntermediaire: newPreparationQte,
          },
        },
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { dishes: allNewDishes });
  return null;
}

export async function AddProductPreparationQteForTestProduct(
  PreparationName,
  ProductName,
  QtePreparation
) {
  if (ProductName === "") {
    console.log("Le produit n'exites pas");
    return;
  } else {
    const WorkPlaceDocRef = doc(firestore, "Production", "DishTestUser");
    const docSnapshot = await getDoc(WorkPlaceDocRef);
    const allDishes = docSnapshot.data().DishTestUser;
    // defini les nouvelles preparation du produit avec les qte
    let newPreparationQte = [];
    allDishes.forEach((dishe) => {
      if (ProductName === dishe.Production.name) {
        if (dishe.Production.PreparationIntermediaire.length === 0) {
          newPreparationQte = [{ Name: PreparationName, Qte: QtePreparation }];
          return;
        }
        dishe.Production.PreparationIntermediaire.forEach(
          (preparation, index) => {
            if (preparation.Name === PreparationName) {
              newPreparationQte =
                dishe.Production.PreparationIntermediaire.slice(0, index);
              newPreparationQte = [
                ...newPreparationQte,
                { Name: PreparationName, Qte: QtePreparation },
                ...dishe.Production.PreparationIntermediaire.slice(index + 1),
              ];
            } else {
              newPreparationQte =
                dishe.Production.PreparationIntermediaire.slice(0, index + 1);
              newPreparationQte = [
                ...newPreparationQte,
                { Name: PreparationName, Qte: QtePreparation },
                ...dishe.Production.PreparationIntermediaire.slice(index + 2),
              ];
            }
          }
        );
      }
    });

    // Ajouter dans la base de données le nouvelles qte des preparation du produits
    let allNewDishes = [];
    allDishes.forEach((dishe, index) => {
      if (ProductName === dishe.Production.name) {
        allNewDishes = [
          ...allDishes.slice(0, index),
          {
            Production: {
              ...dishe.Production,
              PreparationIntermediaire: newPreparationQte,
            },
          },
          ...allDishes.slice(index + 1),
        ];
      }
    });

    await setDoc(WorkPlaceDocRef, { DishTestUser: allNewDishes });
  }
  return null;
}

export async function CreateNewOrderPreparationRequest(date, ListePreparation) {
  const WorkPlaceDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allCommandesPreparations = docSnapshot.data().Commandes;
  let ListePreparationWithQte = [];
  ListePreparation.length !== 0 &&
    ListePreparation.forEach((preparation) => {
      ListePreparationWithQte.push({
        Nom: preparation.DataPreparation.Nom,
        PhotoFirebase: preparation.DataPreparation.PhotoFirebase,
        Unite: preparation.DataPreparation.Unite,
        Qte: preparation.Qte,
      });
    });

  await setDoc(WorkPlaceDocRef, {
    Commandes: [
      ...allCommandesPreparations,
      {
        TimeOfCreation: new Date(),
        PreparationOrder: ListePreparationWithQte,
        DateLivraison: date,
      },
    ],
  });
  return null;
}

export async function AddPrepToOrderPreparations(
  DateLivraison,
  ListePreparation
) {
  const WorkPlaceDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allOrdersPreparations = docSnapshot.data().Commandes;

  let ListePreparationWithQte = [];
  ListePreparation.length !== 0 &&
    ListePreparation.forEach((preparation) => {
      ListePreparationWithQte.push({
        Nom: preparation.DataPreparation.Nom,
        PhotoFirebase: preparation.DataPreparation.PhotoFirebase,
        Qte: preparation.Qte,
        Unite: preparation.DataPreparation.Unite,
      });
    });

  // Ajouter dans la base de données le nouvelles qte des preparation de la commande
  let allNewCommande = [];
  // On verifie si une commande pour la meme date est deja creer
  let testCommandeSameDate = false;
  allOrdersPreparations.forEach((Order, index) => {
    let DateLivraisonOrders = new Date(Order.DateLivraison.seconds * 1000);
    if (
      DateLivraison.getDate() === DateLivraisonOrders.getDate() &&
      DateLivraison.getMonth() === DateLivraisonOrders.getMonth() &&
      DateLivraison.getFullYear() === DateLivraisonOrders.getFullYear()
    ) {
      testCommandeSameDate = true;
      allNewCommande = [
        ...allOrdersPreparations.slice(0, index),
        {
          ...Order,
          PreparationOrder: ListePreparationWithQte,
        },
        ...allOrdersPreparations.slice(index + 1),
      ];
    }
  });
  // si aucune commande n'est creer pour cette date on crreer une nouvelle
  if (testCommandeSameDate === false) {
    allNewCommande = [
      ...allOrdersPreparations,
      {
        PreparationOrder: ListePreparationWithQte,
        DateLivraison: new Date(DateLivraison),
        TimeOfCreation: new Date(),
      },
    ];
  }
  await setDoc(WorkPlaceDocRef, { Commandes: allNewCommande });
  return null;
}

export async function OrderPreparationQte(Nom, date, QtePreparation) {
  const WorkPlaceDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allCommandesPreparations = docSnapshot.data().Commandes;

  await setDoc(WorkPlaceDocRef, {
    Commandes: [
      ...allCommandesPreparations,
      {
        Time: new Date(),
        PreparationOrder: [],
        DateLivraison: date,
      },
    ],
  });
  let Today = new Date();
  console.log(date.getUTCDate());
  return null;
}

export async function SupprimerProductPreparationQte(
  PreparationName,
  ProductName,
  QtePreparation
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;
  // defini les nouvelles preparation du produit avec les qte
  let newPreparationQte = [];
  allDishes.forEach((dishe) => {
    if (ProductName === dishe.Production.name) {
      dishe.Production.PreparationIntermediaire.forEach(
        (preparation, index) => {
          if (preparation.Name === PreparationName) {
            newPreparationQte = dishe.Production.PreparationIntermediaire.slice(
              0,
              index
            );
            newPreparationQte = [
              ...newPreparationQte,
              ...dishe.Production.PreparationIntermediaire.slice(index + 1),
            ];
          }
        }
      );
    }
  });
  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (ProductName === dishe.Production.name) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        {
          Production: {
            ...dishe.Production,
            PreparationIntermediaire: newPreparationQte,
          },
        },
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { dishes: allNewDishes });
  return null;
}
export async function SupprimerProductPreparationQteForTestProduct(
  PreparationName,
  ProductName,
  QtePreparation
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "DishTestUser");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().DishTestUser;
  // defini les nouvelles preparation du produit avec les qte
  let newPreparationQte = [];
  allDishes.forEach((dishe) => {
    if (ProductName === dishe.Production.name) {
      dishe.Production.PreparationIntermediaire.forEach(
        (preparation, index) => {
          if (preparation.Name === PreparationName) {
            newPreparationQte = dishe.Production.PreparationIntermediaire.slice(
              0,
              index
            );
            newPreparationQte = [
              ...newPreparationQte,
              ...dishe.Production.PreparationIntermediaire.slice(index + 1),
            ];
          }
        }
      );
    }
  });
  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (ProductName === dishe.Production.name) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        {
          Production: {
            ...dishe.Production,
            PreparationIntermediaire: newPreparationQte,
          },
        },
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { DishTestUser: allNewDishes });
  return null;
}

export async function SupprimerPreparationInOrderPreparation(
  PreparationName,
  DateLivraison
) {
  const WorkPlaceDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allOrdersPreparation = docSnapshot.data().Commandes;
  // defini les nouvelles preparation du produit avec les qte
  let newPreparationQte = [];
  allOrdersPreparation.forEach((OrderPreparation) => {
    let DateLivraisonOrders = new Date(
      OrderPreparation.DateLivraison.seconds * 1000
    );
    if (
      DateLivraison.getDate() === DateLivraisonOrders.getDate() &&
      DateLivraison.getMonth() === DateLivraisonOrders.getMonth() &&
      DateLivraison.getFullYear() === DateLivraisonOrders.getFullYear()
    ) {
      OrderPreparation.PreparationOrder.forEach((preparation, index) => {
        if (preparation.Nom === PreparationName) {
          newPreparationQte = OrderPreparation.PreparationOrder.slice(0, index);
          newPreparationQte = [
            ...newPreparationQte,
            ...OrderPreparation.PreparationOrder.slice(index + 1),
          ];
        }
      });
    }
  });
  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewDishes = [];
  allOrdersPreparation.forEach((OrderPreparation, index) => {
    let DateLivraisonOrders = new Date(
      OrderPreparation.DateLivraison.seconds * 1000
    );
    if (
      DateLivraison.getDate() === DateLivraisonOrders.getDate() &&
      DateLivraison.getMonth() === DateLivraisonOrders.getMonth() &&
      DateLivraison.getFullYear() === DateLivraisonOrders.getFullYear()
    ) {
      allNewDishes = [
        ...allOrdersPreparation.slice(0, index),
        {
          ...allOrdersPreparation[index],
          PreparationOrder: newPreparationQte,
        },
        ...allOrdersPreparation.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { Commandes: allNewDishes });
  return null;
}

export async function AddNewProduct(NomProduit, img, ProductCategories) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;
  // Upload dans firebase lurl de limage
  const imageRef = ref(storage, `Product/${NomProduit}`);
  await uploadString(imageRef, img, "data_url");
  const downloadURL = await getDownloadURL(imageRef);

  let allNewDishes = [
    ...allDishes,
    {
      Production: {
        name: NomProduit,
        img: downloadURL,
        PreparationIntermediaire: [],
        ProductCategories,
        IDPepers: uuidv4(),
      },
    },
  ];

  await setDoc(WorkPlaceDocRef, { dishes: allNewDishes });

  return null;
}
export async function AddNewTestProduct(NomProduit, IDUser, ProductCategories) {
  const WorkPlaceDocRef = doc(firestore, "Production", "DishTestUser");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().DishTestUser;

  let allNewDishes = [
    ...allDishes,
    {
      Production: {
        name: NomProduit,
        PreparationIntermediaire: [],
        UserOwner: IDUser,
        IDPepers: uuidv4(),
        ProductCategories,
      },
    },
  ];

  await setDoc(WorkPlaceDocRef, { DishTestUser: allNewDishes });

  return null;
}
export async function AddNewCategorie(NomCategorie, CoefficientMarge) {
  const WorkPlaceDocRef = doc(firestore, "Production", "ProductCategories");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allCategories = docSnapshot.data().Categories;

  let allNewCategories = [...allCategories, { NomCategorie, CoefficientMarge }];

  await setDoc(WorkPlaceDocRef, { Categories: allNewCategories });

  return null;
}

export async function AddNewPreparation(
  NomPreparation,
  TypePreparation,
  ListeMatierePremiere,
  img,
  UniteMesurePreparation,
  QteTotalPrep,
  DLCsecondaire,
  explicationCookPrep
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "preparations");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allPreparations = docSnapshot.data().preparation;
  // Upload dans firebase lurl de limage
  const imageRef = ref(storage, `Preparations/${NomPreparation}`);
  await uploadString(imageRef, img, "data_url");
  const downloadURL = await getDownloadURL(imageRef);
  // Calcul du cout total de la preparation en fonction de la liste des ingredients

  let CoutTotalPrep = 0;
  ListeMatierePremiere.forEach((Matiere) => {
    let Qte = Matiere.Qte.replace(/,/g, ".");
    let Price = Matiere.DataMatierePremiere["PrixURecetteHT"];
    let ajout = parseFloat(Qte) * parseFloat(Price);
    CoutTotalPrep = CoutTotalPrep + ajout;
  });

  // On enrigre dans la base que le nom de la matiere premiere utilisée et la Qte
  const MatierePremiereUtilisees = [];
  ListeMatierePremiere.forEach((matiere) => {
    MatierePremiereUtilisees.push({
      Qte: parseFloat(matiere.Qte.replace(/,/g, ".")),
      Name: matiere.DataMatierePremiere.Nom,
      UniteDeMesure: matiere.DataMatierePremiere["Unité de recettes"],
      MatiereID: matiere.DataMatierePremiere.IDPErpers,
    });
  });

  let IDPErpers = uuidv4();

  let allNewPreparation = [
    ...allPreparations,
    {
      Categorie: TypePreparation,
      CoutHT: CoutTotalPrep.toFixed(2),
      DLCSecondaire: DLCsecondaire,
      MatierePremiereUtilisees: MatierePremiereUtilisees,
      Nom: NomPreparation,
      PhotoFirebase: downloadURL,
      Quantite: QteTotalPrep,
      Unite: UniteMesurePreparation,
      Recette: explicationCookPrep,
      IDPErpers,
    },
  ];

  await setDoc(WorkPlaceDocRef, { preparation: allNewPreparation });

  // On doit ajouter en stock la preparation pour faire les inventaires
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const docStockSnapshot = await getDoc(StockDocRef);
  const allStock = docStockSnapshot.data();

  const newStock = {
    ...allStock,
    Preparation: [...allStock.Preparation, { IDPErpers, QteEnStock: 0 }],
  };

  await setDoc(StockDocRef, newStock);

  return null;
}

export async function AddNewFournisseur(NomFournisseur) {
  const WorkPlaceDocRef = doc(firestore, "Production", "Fournisseurs");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allFournisseurs = docSnapshot.data().Fournisseurs;

  let allNewFournisseurs = [
    ...allFournisseurs,
    {
      Categorie: "Aucune",
      "Ingrédients fournisseurs": null,
      Nom: NomFournisseur,
      Restaurants: 1,
      Statut: "Actif",
      Ville: "Aucune",
    },
  ];

  await setDoc(WorkPlaceDocRef, { Fournisseurs: allNewFournisseurs });

  return null;
}

export async function AddNewMatierePremiere(
  NomMatiere,
  Fournisseur,
  NomRefFournisseur,
  PrixHT,
  UniteMesure,
  SKU,
  Conditionnement,
  QteConditionnement
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "Matiere Premiere");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allMatierePremiere = docSnapshot.data().MatierePremiere;

  const PrixURecetteHT =
    parseFloat(PrixHT.replace(",", ".")) /
    parseFloat(QteConditionnement.replace(",", "."));

  let Prix = PrixHT.replace(",", ".");
  QteConditionnement = QteConditionnement.replace(",", ".");

  let IDPErpers = uuidv4();

  let allNewMatierePremiere = [
    ...allMatierePremiere,
    {
      Devise: "Euro (EUR)",
      Fournisseur: Fournisseur,
      Ingrédient: NomMatiere,
      Nom: NomRefFournisseur,
      "Pertes (%)": "",
      "Poids du colis (kg)": "",
      "Prix HT": Prix,
      SKU: SKU,
      "Unité de recettes": UniteMesure,
      Conditionnement,
      IDPErpers,
      QteConditionnement,
      PrixURecetteHT,
    },
  ];

  await setDoc(WorkPlaceDocRef, { MatierePremiere: allNewMatierePremiere });

  // On doit ajouter en stock la matiere premiere pour faire les inventaires
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const docStockSnapshot = await getDoc(StockDocRef);
  const allStock = docStockSnapshot.data();

  const newStock = {
    ...allStock,
    MatierePremiere: [
      ...allStock.MatierePremiere,
      { IDPErpers, QteEnStock: 0 },
    ],
  };

  await setDoc(StockDocRef, newStock);

  return null;
}

export async function AddNewBrand(
  BrandName,
  ProductIntheGroup,
  supInTheGroup,
  ProductIntheEaticTest
) {
  const BrandDocRef = doc(firestore, "pepers", BrandName);
  const BrandDocSnapshot = await getDoc(BrandDocRef);

  if (!BrandDocSnapshot.exists()) {
    // If the "BrandName" document doesn't exist, create it with some initial data
    async function fetchPricingData() {
      const Pricing = [];
      const EaticTest = [];

      for (const prod of ProductIntheGroup) {
        let Price = await GetProductPricing(prod.Production.name);
        Price = Price.toFixed(2);
        Pricing.push({ Cost: Price, NomZelty: prod.Production.name, Type: "" });
      }
      for (const prod of supInTheGroup) {
        let Price = await GetSupplementPricing(prod.Name);
        Price = Price.toFixed(2);
        Pricing.push({ Cost: Price, NomZelty: prod.Name, Type: "" });
      }
      for (const prod of ProductIntheEaticTest) {
        EaticTest.push({ NomZelty: prod.Production.name });
      }
      const initialData = { Pricing: Pricing, EaticTest: EaticTest };

      // Utilisez le tableau Pricing ici, une fois qu'il est rempli avec les données de tarification.
      return initialData;
    }

    const initialData = await fetchPricingData();
    console.log(initialData);
    await setDoc(BrandDocRef, initialData, { merge: true });
  }

  // Get the data from the "dishes" document
  const BrandData = BrandDocSnapshot.data();
  return null;
}

export async function CreateNewWorkplace(
  WorkPlaceName,
  WorkPlaceMachine,
  WorkPlacePreparations
) {
  const WorkplaceDocRef = doc(firestore, "PosteDeTravail", WorkPlaceName);
  const WorkplaceDocSnapshot = await getDoc(WorkplaceDocRef);

  const ListePrepName = WorkPlacePreparations.map(
    (prep) => prep.DataPreparation.Nom
  );
  if (!WorkplaceDocSnapshot.exists()) {
    // If the "BrandName" document doesn't exist, create it with some initial data
    const initialData = {
      ProductInProduction: [],
      Machine: WorkPlaceMachine,
      Preparations: ListePrepName,
    };
    await setDoc(WorkplaceDocRef, initialData, { merge: true });
  }

  // Get the data from the "Plqn de trqvqil document" document
  const WorkplaceData = WorkplaceDocSnapshot.data();
  return null;
}
export async function HandleCreateWorplaceWithListOfProduct(
  WorkPlaceName,
  ListOfProduct
) {
  const WorkplaceDocRef = doc(firestore, "PosteDeTravail", WorkPlaceName);
  const WorkplaceDocSnapshot = await getDoc(WorkplaceDocRef);

  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;

  const preparationsDocRef = doc(firestore, "Production", "preparations");
  const docPreparationsSnapshot = await getDoc(preparationsDocRef);
  const allpreparations = docPreparationsSnapshot.data().preparation;
  // La liste de produit que nous recevons est uniquement un tableau de nom de produit.
  // Nous avons besoin de recuperer tout l'objet dish correspondant
  let ReconstitutionListOfProduct = [];
  ListOfProduct.map((productInTheList) => {
    allDishes.forEach((dish) => {
      if (productInTheList === dish.Production.name) {
        ReconstitutionListOfProduct.push(dish);
      }
    });
  });
  // On regarde dans chaque produit la liste des prep et on constitue le tableau de prep
  let ListePrepInAllProductToDo = [];
  ReconstitutionListOfProduct.forEach((product) => {
    product.Production.PreparationIntermediaire.forEach((prep) => {
      ListePrepInAllProductToDo.push(prep.Name);
    });
  });
  // On enleve les doublon
  let ListePrepInAllProductToDoWithoutCopy = [
    ...new Set(ListePrepInAllProductToDo),
  ];
  // On reformate la liste de prep au bon format d'objet
  let listPrepGoodFormat = [];
  ListePrepInAllProductToDoWithoutCopy.forEach((prepInProdToDo) => {
    allpreparations.forEach((prep) => {
      if (prepInProdToDo === prep.Nom) {
        listPrepGoodFormat.push({ DataPreparation: prep });
      }
    });
  });

  // On regarde dans chaque produit la liste des machines et on constitue le tableau de machine
  let ListeMachineInAllProductToDo = [];
  ReconstitutionListOfProduct.forEach((product) => {
    if (product.Production.hasOwnProperty("Machine")) {
      product.Production.Machine.forEach((Machine) => {
        ListeMachineInAllProductToDo.push(Machine);
      });
    } else {
    }
  });
  // On enleve les doublon
  let ListeMachineInAllProductToDoWithoutCopy = [
    ...new Set(ListeMachineInAllProductToDo),
  ];

  if (!WorkplaceDocSnapshot.exists()) {
    // If the "BrandName" document doesn't exist, create it with some initial data
    const initialData = {
      ProductInProduction: [],
      Machine: [],
      Preparations: [],
    };
    //await setDoc(WorkplaceDocRef, initialData, { merge: true });
  }
  return {
    machine: ListeMachineInAllProductToDoWithoutCopy,
    Prep: listPrepGoodFormat,
  };
}

export async function UpdateDish(NewDish, ListePreparation) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;

  const FormatedListePreparation = ListePreparation.map((prep) => {
    return { Name: prep.DataPreparation.Nom, Qte: prep.Qte };
  });

  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (NewDish.Production.IDPepers === dishe.Production.IDPepers) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        {
          ...allDishes[index],
          Production: {
            ...dishe.Production,
            ...NewDish.Production,
            PreparationIntermediaire: FormatedListePreparation,
          },
        },
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { dishes: allNewDishes });

  return null;
}
// Cette fonction prend en entree un tableau d'objet sous cette forme [{Fournisseur: "METRO", FournisseurID : "755c7eca-d13a-49cb-abe3-490a758eab11", Order:[{...MatiereObject}, {...MagtiereObject}]},..{}]
// Return une date a laquelle les commandes seront toutes arrivees au restuarant, et d'autres informations
export async function PassOrderMatiereToArriveAlltogetherInRestaurant(
  ListeMatiereObjectACommanderByFournisseur
) {
  const FournisseursDocRef = doc(firestore, "Production", "Fournisseurs");
  const FournisseursSnapshot = await getDoc(FournisseursDocRef);
  const AllFournisseurs = FournisseursSnapshot.data().Fournisseurs;

  const MatiereOrderDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "MatierePremiereOrder"
  );
  const MatiereOrderSnapshot = await getDoc(MatiereOrderDocRef);
  const AllMatiereOrder = MatiereOrderSnapshot.data().MatierePremiereOrder;

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  const CommandeAttentesDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "CommandesMatiereEnAttente"
  );
  const CommandeAttentesSnapshot = await getDoc(CommandeAttentesDocRef);
  const AllCommandeAttentes =
    CommandeAttentesSnapshot.data().CommandesMatiereEnAttente;

  let ListMatiereACommanderByFournisseurWithDeliveryDate = [];
  ListeMatiereObjectACommanderByFournisseur.forEach((OrderByFournisseur) => {
    const FournisseurData = AllFournisseurs.filter(
      (Fournisseur) =>
        OrderByFournisseur.FournisseurID === Fournisseur.IDPErpers
    )[0];
    let NbDayOfdelivery = FournisseurData.DelaisDeLivraison;
    // On simule le prochain jour de livraison du fournisseur si la commande est passee aujourd'hui
    const DayWhereTheOrderIsPLace = new Date();
    let deliveryDate = new Date(
      DayWhereTheOrderIsPLace.getTime() + NbDayOfdelivery * 86400 * 1000
    );

    FournisseurData.DayNoDelivery.forEach((NoDeliveryDay) => {
      if (deliveryDate.getDay() === NoDeliveryDay) {
        deliveryDate = new Date(deliveryDate.getTime() + 86400 * 1000);
      }
    });
    ListMatiereACommanderByFournisseurWithDeliveryDate.push({
      ...OrderByFournisseur,
      deliveryDate,
      FournisseurData,
    });
  });
  // on cherche la date de livraison la plus tardive
  const maxDeliveryDate =
    ListMatiereACommanderByFournisseurWithDeliveryDate.reduce(
      (maxDate, matiere) => {
        if (matiere.deliveryDate > maxDate) {
          return matiere.deliveryDate;
        } else {
          return maxDate;
        }
      },
      ListMatiereACommanderByFournisseurWithDeliveryDate[0]?.deliveryDate
    );
  // une fois la date de livraison la plus tardive trouver on calcule pour toute les autre date la date de commande ideal pour que cette commande arrive le meme jour que la plus tardive
  const ListMatiereACommanderByFournisseurWithOrderDate = [];
  ListMatiereACommanderByFournisseurWithDeliveryDate.forEach(
    (FournisseurOrder) => {
      let NbDayOfdelivery = FournisseurOrder.FournisseurData.DelaisDeLivraison;

      let orderDate = new Date(
        maxDeliveryDate.getTime() - NbDayOfdelivery * 86400 * 1000
      );
      // On recalcule la date de commande avec la nouvelle date de livraison
      const DayWhereTheOrderIsPLace = orderDate;
      let NewdeliveryDate = new Date(
        DayWhereTheOrderIsPLace.getTime() + NbDayOfdelivery * 86400 * 1000
      );
      FournisseurOrder.FournisseurData.DayNoDelivery.forEach(
        (NoDeliveryDay) => {
          if (NewdeliveryDate.getDay() === NoDeliveryDay) {
            NewdeliveryDate = new Date(
              NewdeliveryDate.getTime() + 86400 * 1000
            );
          }
        }
      );

      // ce tableau est un tableau d'objet avec les commande par fournisseur avec la date de livraison et la date de commande de chacun
      ListMatiereACommanderByFournisseurWithOrderDate.push({
        ...FournisseurOrder,
        orderDate,
        deliveryDate: NewdeliveryDate,
      });
    }
  );

  // On calcul maintenant la Qte a coomander pour chaque matiere premiere
  // Pour le moment on part du principe qu'on ne commande que 1 conditionnement pour chaque matiere premiere
  let ListMatiereACommanderByFournisseurWithQte = [];
  ListMatiereACommanderByFournisseurWithOrderDate.forEach(
    (FournisseurOrder) => {
      let Order = [];
      FournisseurOrder.Order.forEach((MatiereOrdered) => {
        let Qte = 1;
        Order.push({ DataMatiere: MatiereOrdered, qteToOrder: Qte });
      });
      ListMatiereACommanderByFournisseurWithQte.push({
        ...FournisseurOrder,
        Order,
      });
    }
  );

  // On place l'enssemble des commandes chez les differents fournisseurs dans un groupe de commande pour signifier que ces commandes doivent arriver en meme temps
  const GroupeDeCommandePerFournisseur = {
    GroupeID: uuidv4(),
    ListMatiereACommanderByFournisseurWithQte,
  };
  // on doit maintenant calculer le prix total de la commande pour chaque fournisseur et voir si le min de commande est atteint
  // Si le min de commande n'est pas atteint on doit estimer quand sera la prochaine commande pour ce fournisseur et refaire le calcul
  // on placera ensuite la commande dans une liste de commande en attente.
  let CheckIfOneOrderNotReachMinOrder = false;
  let CompteurNbCommandeMinNonAtteint = 0;
  let AllNewOrderEnAttente = [];
  let DataOutPutFunction = [];
  GroupeDeCommandePerFournisseur.ListMatiereACommanderByFournisseurWithQte.forEach(
    async (FournisseurOrder) => {
      let PrixTotalOrder = 0;
      let FournisseurMinOrder = FournisseurOrder.FournisseurData.MinDeCommande;
      FournisseurOrder.Order.forEach((MatiereOrderer) => {
        PrixTotalOrder =
          PrixTotalOrder +
          parseFloat(MatiereOrderer.DataMatiere["Prix HT"].replace(",", ".")) *
            MatiereOrderer.qteToOrder;
      });
      if (PrixTotalOrder < FournisseurMinOrder) {
        // si le min de commande n'est pas atteint alors on place la commande dans une liste de commande en attente
        // en Paralele on doit estimer quand sera la prochaine commande pour ce fournisseur et refaire le calcul pour estimer la date de mise en ligne
        CheckIfOneOrderNotReachMinOrder = true;
        CompteurNbCommandeMinNonAtteint = CompteurNbCommandeMinNonAtteint + 1;
        // On reformate la liste de matiere commande pour ne stocker que l'id
        let Order = FournisseurOrder.Order.map((MatiereOrderer) => ({
          IdMatiere: MatiereOrderer.DataMatiere.IDPErpers,
          qteToOrder: MatiereOrderer.qteToOrder,
        }));
        // On place la commande dans la liste de commande en attente
        AllNewOrderEnAttente.push({
          FournisseurName: FournisseurOrder.Fournisseur,
          FournisseurID: FournisseurOrder.FournisseurID,
          Order,
          GroupeOfFournisseurOrderID: GroupeDeCommandePerFournisseur.GroupeID,
          CreationTimeMatiereEnAttente: new Date(),
          EtatCommande: "En attente",
          Raison: "Min de commande non atteint",
        });
      }
    }
  );
  // Si une commande n'a pas atteint le min de commande alors cela change toute les autre commandes

  if (CheckIfOneOrderNotReachMinOrder) {
    // Une des commandes ne respecte pas le min de commande
    GroupeDeCommandePerFournisseur.ListMatiereACommanderByFournisseurWithQte.forEach(
      async (FournisseurOrder) => {
        let PrixTotalOrder = 0;
        let FournisseurMinOrder =
          FournisseurOrder.FournisseurData.MinDeCommande;
        FournisseurOrder.Order.forEach((MatiereOrderer) => {
          PrixTotalOrder =
            PrixTotalOrder +
            parseFloat(
              MatiereOrderer.DataMatiere["Prix HT"].replace(",", ".")
            ) *
              MatiereOrderer.qteToOrder;
        });
        if (PrixTotalOrder > FournisseurMinOrder) {
          // On reformate la liste de matiere commande pour ne stocker que l'id
          let Order = FournisseurOrder.Order.map((MatiereOrderer) => ({
            IdMatiere: MatiereOrderer.DataMatiere.IDPErpers,
            qteToOrder: MatiereOrderer.qteToOrder,
          }));
          AllNewOrderEnAttente.push({
            FournisseurName: FournisseurOrder.Fournisseur,
            FournisseurID: FournisseurOrder.FournisseurID,
            Order,
            GroupeOfFournisseurOrderID: GroupeDeCommandePerFournisseur.GroupeID,
            CreationTimeMatiereEnAttente: new Date(),
            EtatCommande: "En attente",
            Raison:
              "En attente des commandes qui n'ont pas atteint le min de commande",
          });
        }
      }
    );
    // Avant d'enregister dans la base commande en Attente on formate les donnees
    let FormatedAllNewOrderData = [];
    AllNewOrderEnAttente.forEach((Order) => {
      Order.Order.forEach((MatiereOrderer) =>
        FormatedAllNewOrderData.push(MatiereOrderer)
      );
    });

    let FormatedAllListOfFournisseur = [];
    AllNewOrderEnAttente.forEach((Order) => {
      FormatedAllListOfFournisseur.push({
        FournisseurName: Order.FournisseurName,
        FournisseurID: Order.FournisseurID,
        Raison: Order.Raison,
        EtatCommande: Order.EtatCommande,
      });
    });

    // Si le nb de commande en attente pour raison de min de commande non atteint est de 1 uniquement alors on enregistre sinon on dit a l'utilisateur de trouver une autre solution
    if (CompteurNbCommandeMinNonAtteint === 1) {
      await setDoc(CommandeAttentesDocRef, {
        CommandesMatiereEnAttente: [
          ...AllCommandeAttentes,
          {
            CreationTimeOrderEnAttente: new Date(),
            ListeFournisseurOrdered: FormatedAllListOfFournisseur,
            DataOrder: FormatedAllNewOrderData,
            IdCommandeEnAttente: uuidv4(),
          },
        ],
      });

      FormatedAllListOfFournisseur.forEach((Fournisseur) => {
        if (Fournisseur.Raison === "Min de commande non atteint") {
          // On estime quand sera la prochaine commande pour ce fournisseur
          const listeFournisseurOrder = [];
          AllMatiereOrder.forEach((MatiereOrder) => {
            MatiereOrder.ListeFournisseurOrdered.forEach((FournisseurOrder) => {
              if (
                FournisseurOrder.fournisseurName === Fournisseur.FournisseurName
              ) {
                listeFournisseurOrder.push({
                  ...MatiereOrder,
                });
              }
            });
          });

          const TempsMoyenDeCommande =
            (listeFournisseurOrder[0].date.seconds -
              listeFournisseurOrder[listeFournisseurOrder.length - 1].date
                .seconds) /
            (listeFournisseurOrder.length - 1) /
            86400;

          DataOutPutFunction.push("Commende mise en attente");
          DataOutPutFunction.push(
            `Le founisseur qui a le min non atteint est : ${
              Fournisseur.FournisseurName
            } temps moyen a chaque commande : ${TempsMoyenDeCommande} jours, La derniere commande pour ce fournisseur a ete pasee le : ${new Date(
              listeFournisseurOrder[listeFournisseurOrder.length - 1].date
                .seconds * 1000
            )}`
          );
        }
      });
    } else {
      DataOutPutFunction.push(
        "Il y a plus d'une commande en attente pour raison de min de commande non atteint, il faut trouver une autre solution"
      );
    }
  } else {
    // Toute les commandes on attein le min de commande on passe les commandes
    let ListeFournisseurOrdered = [];
    let DataOrder = [];
    ListMatiereACommanderByFournisseurWithQte.forEach((FournisseurOrder) => {
      ListeFournisseurOrdered.push({
        Commentaire: "",
        EtatCommande: "En Livraison",
        fournisseurName: FournisseurOrder.Fournisseur,
        DeliveryDate: FournisseurOrder.deliveryDate,
        DateMaxOuIlFautPasserLaCommande: FournisseurOrder.orderDate,
      });
      const formatedDataOrder = FournisseurOrder.Order.map((MatiereOrder) => ({
        DataMatiere: MatiereOrder.DataMatiere,
        Qte: MatiereOrder.qteToOrder,
      }));
      DataOrder = [...DataOrder, ...formatedDataOrder];
    });

    let NewMatiereOrder = {
      DataOrder,
      ListeFournisseurOrdered,
      date: new Date(),
      IDOrderPepers: uuidv4(),
      EtatOrder: "En Livraison",
    };
    DataOutPutFunction.push(
      "Toutes les commandes ont atteint le min de commande, les commandes sont passees"
    );
    DataOutPutFunction.push(NewMatiereOrder);

    await setDoc(MatiereOrderDocRef, {
      MatierePremiereOrder: [...AllMatiereOrder, { ...NewMatiereOrder }],
    });
  }
  // On regade la commande dont le min de commande n'est pas atteint et on estime quand sera la prochaine commande pour ce fournisseur

  return DataOutPutFunction;
}

export async function UpdateAllDish(AllNewDish) {
  const DishDocRef = doc(firestore, "Production", "dishes");
  const DishSnapshot = await getDoc(DishDocRef);
  const AllDish = DishSnapshot.data();

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data();

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPrep = PrepSnapshot.data().preparation;

  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const docStockSnapshot = await getDoc(StockDocRef);
  const allStock = docStockSnapshot.data();

  const FournisseurDocRef = doc(firestore, "Production", "Fournisseurs");
  const FournisseurSnapshot = await getDoc(FournisseurDocRef);
  const allFournisseur = FournisseurSnapshot.data().Fournisseurs;

  const NewDishData = {
    ...AllDish,
    dishes: AllNewDish,
  };
  // Avant de mettre a jour on verifie si le ou les produit passe en Actif implique de faire e nouvelle preparation
  // On verifie si il y a des produits qui psse en inactif si cela implique de retirer des preparations
  const Ckeck =
    await CheckIfModifyStateProductImplicateNewPreparationorRemovePrep(
      AllNewDish
    );
  console.log(Ckeck);
  // On doit calculer le prix que ca coute en charge fixe d'implementer les nouvelles preparations et les nouvelles matieres premieres

  const CalculChargeFixe = await CalculChargeFixeForNewPreparationAndNewMatiere(
    Ckeck
  );
  console.log(CalculChargeFixe);
  // On doit retirer des matieres de la logistique On regarde alors dans le stock les matieres en question et on indiaue a l'utilisateur combien de matiere il vas perdre dans le stock

  if (Ckeck.MatiereInLess.length > 0) {
    let ListeMatierePerdue = [];
    Ckeck.MatiereInLess.forEach((matiere) => {
      let QteDeMatierePerdue = 0;
      AllMatiere.MatierePremiere.forEach((MatiereObject) => {
        if (MatiereObject.Nom === matiere) {
          allStock.MatierePremiere.forEach((matiereStock, index) => {
            if (matiereStock.IDPErpers === MatiereObject.IDPErpers) {
              QteDeMatierePerdue =
                matiereStock.QteEnStock *
                parseFloat(MatiereObject.QteConditionnement.replace(",", "."));
            }
          });
        }
      });
      if (QteDeMatierePerdue !== 0) {
        ListeMatierePerdue.push({
          matiere,
          QteDeMatierePerdue,
          Unite: "unite de recette de la matiere",
        });
      }
    });
    if (ListeMatierePerdue.length > 0) {
      console.log(
        "attention vous allez perdre des matieres premieres dans le stock"
      );
      console.log(ListeMatierePerdue);
    } else {
      console.log("Aucune matiere premiere perdue");
    }
  }

  // On fait la meme chose en regardant si il y a des prepataions perdues, on regarde dans le stock les preparations en question et on indiaue a l'utilisateur combien de preparation il vas perdre dans le stock

  if (Ckeck.prepInLess.length > 0) {
    let ListePreparationPerdue = [];
    Ckeck.prepInLess.forEach((prep) => {
      let QteDePreparationPerdue = 0;
      AllPrep.forEach((PrepObject) => {
        if (PrepObject.Nom === prep) {
          allStock.Preparation.forEach((prepStock) => {
            if (prepStock.IDPErpers === PrepObject.IDPErpers) {
              QteDePreparationPerdue = prepStock.QteEnStock;
            }
          });
        }
      });
      if (QteDePreparationPerdue !== 0) {
        ListePreparationPerdue.push({
          prep,
          QteDePreparationPerdue,
          Unite: "unite de recette de la preparation",
        });
      }
    });
    if (ListePreparationPerdue.length > 0) {
      console.log("attention vous allez perdre des preparations dans le stock");
      console.log(ListePreparationPerdue);
    } else {
      console.log("Aucune preparation perdue");
    }
  }

  // Si il y a des matieres en plus alors il faut les commander et les ajouter dans la logistique
  if (Ckeck.MatiereInMore.length > 0) {
    let ListeMatiereObjectACommander = [];
    Ckeck.MatiereInMore.forEach((matiereInMore) => {
      AllMatiere.MatierePremiere.forEach((MatiereObject) => {
        if (MatiereObject.Nom === matiereInMore) {
          ListeMatiereObjectACommander.push(MatiereObject);
        }
      });
    });

    // On regroupe les matiere a commander par fournisseur
    let ListeMatiereObjectACommanderByFournisseur = [];
    allFournisseur.forEach((fournisseur) => {
      let OrderOfThisFournisseur = [];
      ListeMatiereObjectACommander.forEach((matiere) => {
        if (fournisseur.Nom === matiere.Fournisseur) {
          OrderOfThisFournisseur.push(matiere);
        }
      });
      if (OrderOfThisFournisseur.length > 0) {
        ListeMatiereObjectACommanderByFournisseur.push({
          Fournisseur: fournisseur.Nom,
          FournisseurID: fournisseur.IDPErpers,
          Order: OrderOfThisFournisseur,
        });
      }
    });
    // il faut maintenant passer les commandes en fonction des delais de livraison de chacun
    // on cherche a minimiser le temps ou une matiere est commander mais que toute les matieres premieres ne sont pas encore recu au restaurant
    const PassOrder = await PassOrderMatiereToArriveAlltogetherInRestaurant(
      ListeMatiereObjectACommanderByFournisseur
    );
    console.log(PassOrder);
  }

  // On doit maintenant organiser les commandes des nouvelles matieres premieres en fonction des delais de livraison de chacun
  // Pour pouvoir determiner la date a l'aquelle toute les matieres premieres seront presente au restuarant
  // Il faut aussi faire attentions que la DLC primaire des produits commandé en premier ne soit pas depasser avant que les autres matieres premieres soit arrivées
  // Il faut definir la qte de matiere à commander, on la defini en fonction de la qte previsionel de produit vendu
  // il faut aussi verifier les qte min de commande, si on doit passer une commande chez un fournisseur mais que la qte est trop petite il faut attendre la prochaine livraion que le restaurant devra faire avec ce fournisseur

  // On regarde les matieres premieres en plus et on les classe par fournisseur pour pouvoir les commander

  // On Calcul la Qte de matiere premiere à commander en fonction des qte previsionelles pour les nouveaux produits à activer
  // En fonction de cette meme Qte previsionelle on regarde comment cela devrait impacter les commandes de matiere qui sont deja entree en logistique

  await setDoc(DishDocRef, NewDishData);
}

export async function SupprimerProduct(Dish) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().dishes;

  // Reconstitue la base dish sans le produits a supprimer
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (Dish.Production.IDPepers === dishe.Production.IDPepers) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { dishes: allNewDishes });

  return null;
}

export async function SupprimerTestProduct(DishName, userID) {
  const WorkPlaceDocRef = doc(firestore, "Production", "DishTestUser");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allDishes = docSnapshot.data().DishTestUser;

  // Reconstitue la base dish sans le produits a supprimer
  let allNewDishes = [];
  allDishes.forEach((dishe, index) => {
    if (
      DishName === dishe.Production.name &&
      dishe.Production.UserOwner === userID
    ) {
      allNewDishes = [
        ...allDishes.slice(0, index),
        ...allDishes.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, { DishTestUser: allNewDishes });

  return null;
}

export async function PassProdtuctToFinish(
  ProductIDInOrder,
  OrderID,
  WorkPlace,
  userID
) {
  const OrderDocRef = doc(firestore, "Orders", OrderID);
  const OrderSnapshot = await getDoc(OrderDocRef);
  const Order = OrderSnapshot.data();

  let NewListOfProduct = [];
  Order.listeProduitCommande.forEach((Prod, index) => {
    if (Prod.id === ProductIDInOrder) {
      NewListOfProduct = [
        ...Order.listeProduitCommande.slice(0, index),
        { ...Prod, etatProduction: true },
        ...Order.listeProduitCommande.slice(index + 1),
      ];
    }
  });

  const NewOrderData = {
    ...Order,
    listeProduitCommande: NewListOfProduct,
  };

  await setDoc(OrderDocRef, NewOrderData);

  //Supprimer le produit a faire dans le poste de travail
  const WorkPlaceDocRef = doc(firestore, "PosteDeTravail", WorkPlace);
  const WorkPlaceSnapshot = await getDoc(WorkPlaceDocRef);
  const WorkPlaceData = WorkPlaceSnapshot.data();

  // On verifie si le workplace vas devenir vide avant de supprimier ce produit
  // Si le workplace vas devenir vide on arrete le crono pour comptabiliser le temps ou le poste de travail a fonctionné
  {
    /*if (WorkPlaceData.ProductInProduction.length === 1) {
    const WorkplaceWorkingTimeDocRef = doc(
      firestore,
      "ManagerRestaurant",
      "WorkplaceWorkingTime"
    );
    const WorkplaceWorkingTimedocSnapshot = await getDoc(
      WorkplaceWorkingTimeDocRef
    );
    const allDataWorkplaceWorkingTime = WorkplaceWorkingTimedocSnapshot.data();

    const NewDataWorkplaceWorkingTime = {
      ...allDataWorkplaceWorkingTime,
      WorkplaceWorkingTime: [
        ...allDataWorkplaceWorkingTime.WorkplaceWorkingTime,
        {
          EvenementType: "Stopwatch",
          Workplace: WorkPlace,
          Time: new Date(),
        },
      ],
    };
    await setDoc(WorkplaceWorkingTimeDocRef, NewDataWorkplaceWorkingTime);
  }*/
  }

  let NewListeOfProductInProduction = [];
  WorkPlaceData.ProductInProduction.forEach((produit, index) => {
    if (
      produit.ProductIDInOrder === ProductIDInOrder &&
      produit.OrderID === OrderID
    ) {
      NewListeOfProductInProduction = [
        ...WorkPlaceData.ProductInProduction.slice(0, index),
        ...WorkPlaceData.ProductInProduction.slice(index + 1),
      ];
    }
  });

  await setDoc(WorkPlaceDocRef, {
    ...WorkPlaceData,
    ProductInProduction: NewListeOfProductInProduction,
  });

  // On enregitre l'heure a laquelle l'utilisateur a fait passer le produit dans le plan de travail
  {
    /*const DataWorkPlaceDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "DataKitchen"
  );
  const DataWorkPlacedocSnapshot = await getDoc(DataWorkPlaceDocRef);
  const allDataDataWorkPlace = DataWorkPlacedocSnapshot.data();

  await setDoc(DataWorkPlaceDocRef, {
    ...allDataDataWorkPlace,
    DataKitchen: [
      ...allDataDataWorkPlace.DataKitchen,
      {
        Type: "ProductFinish",
        ProductIDInOrder: ProductIDInOrder,
        userID,
        OrderID: OrderID,
        timeProductFinish: new Date(),
        WorkPlace,
      },
    ],
  });*/
  }

  return null;
}

export async function CreateNewProductGroup(GDPName, ProductIntheGroup) {
  const ProductGroupDocRef = doc(firestore, "Production", "ProductGroup");
  const ProductGroupDocSnapshot = await getDoc(ProductGroupDocRef);
  const AllProductGoup = ProductGroupDocSnapshot.data().ListeProductGroup;

  const NewListeProductGroup = [
    ...AllProductGoup,
    { ListeOfProduct: ProductIntheGroup, NameOfProductGroup: GDPName },
  ];

  const NewdataProductGroupDocument = {
    ...ProductGroupDocSnapshot,
    ListeProductGroup: NewListeProductGroup,
  };

  await setDoc(
    ProductGroupDocRef,
    { ListeProductGroup: NewListeProductGroup },
    {
      merge: true,
    }
  );

  return null;
}

export async function AddNewPackaging(
  PackagingName,
  Fournisseur,
  NomRefFournisseur,
  PrixHTConditionnement,
  PrixHTUnite,
  Statut,
  SKU,
  Conditionnement
) {
  const packagingDocRef = doc(firestore, "Production", "packaging");
  const packagingSnapshot = await getDoc(packagingDocRef);
  const packaging = packagingSnapshot.data().packaging;

  let Prix = PrixHTConditionnement.replace(/\./g, ",");
  let PrixUnite = PrixHTUnite.replace(/\./g, ",");
  let allNewPackaging = [
    ...packaging,
    {
      PepersID: uuidv4(),
      Devise: "Euro (EUR)",
      Fournisseur: Fournisseur,
      PackagingName: PackagingName,
      NomFournisseur: NomRefFournisseur,
      "Prix HT Conditionnement": Prix,
      PrixHTParUnite: PrixUnite,
      SKU: SKU,
      Statut: Statut,
      Conditionnement,
    },
  ];

  console.log(allNewPackaging);
  await setDoc(packagingDocRef, { packaging: allNewPackaging });

  return null;
}
export async function HandleCreateNewSup(
  NomSupplement,
  ListePreparation,
  img,
  QteSup,
  UniteDeMesure
) {
  const SupplementDocRef = doc(firestore, "Production", "Supplement");
  const SupplementSnapshot = await getDoc(SupplementDocRef);
  const allSupp = SupplementSnapshot.data().Supplement;

  const FormatedListePreparation = ListePreparation.map((prep) => {
    return { Name: prep.DataPreparation.Nom, Qte: prep.Qte };
  });

  const imageRef = ref(storage, `Sup/${NomSupplement}`);
  await uploadString(imageRef, img, "data_url");
  const downloadURL = await getDownloadURL(imageRef);

  // Ajouter dans la base de données le nouvelles qte des preparation du produits
  let allNewPrep = [
    ...allSupp,
    {
      Name: NomSupplement,
      img: downloadURL,
      PreparationIntermediaire: FormatedListePreparation,
      QteOfTheSup: QteSup,
      UniteDeMesure: UniteDeMesure,
      IDPepers: uuidv4(),
    },
  ];

  await setDoc(SupplementDocRef, { Supplement: allNewPrep });

  return null;
}
export async function PassOrderToFinish(OrderID) {
  const OrderDocRef = doc(firestore, "Orders", OrderID);
  const OrderSnapshot = await getDoc(OrderDocRef);
  const Order = OrderSnapshot.data();

  const NewOrderData = {
    ...Order,
    InPackageState: false,
    status: "ended",
  };

  await setDoc(OrderDocRef, NewOrderData);

  return null;
}
export async function PassOrderToInPackage(userID, OrderID) {
  const OrderDocRef = doc(firestore, "Orders", OrderID);
  const OrderSnapshot = await getDoc(OrderDocRef);
  const Order = OrderSnapshot.data();

  const NewOrderData = {
    ...Order,
    InPackageState: true,
  };

  await setDoc(OrderDocRef, NewOrderData);

  return null;
}
export async function UpdateWorkplace(
  WorkPlaceName,
  WorkPlaceMachine,
  WorkPlacePreparations
) {
  const WorkplaceDocRef = doc(firestore, "PosteDeTravail", WorkPlaceName);
  const WorkplaceDocSnapshot = await getDoc(WorkplaceDocRef);
  const AllWorkPlacePreparations = WorkplaceDocSnapshot.data().Preparations;

  const ListePrepName = WorkPlacePreparations.map(
    (prep) => prep.DataPreparation.Nom
  );
  const ListePrepNotContainInWorkplace = [];

  ListePrepName.forEach((prep) => {
    if (AllWorkPlacePreparations.includes(prep)) {
    } else {
      ListePrepNotContainInWorkplace.push(prep);
    }
  });
  const NewListPrepName = [
    ...WorkPlacePreparations,
    ...ListePrepNotContainInWorkplace,
  ];

  if (WorkplaceDocSnapshot.exists()) {
    // If the "BrandName" document doesn't exist, create it with some initial data
    const initialData = {
      Preparations: NewListPrepName,
    };

    await setDoc(WorkplaceDocRef, initialData, { merge: true });
  }

  // Get the data from the "Plqn de trqvqil document" document
  const WorkplaceData = WorkplaceDocSnapshot.data();
  return null;
}
export async function SaveOderPreparation(PrepData) {
  const Today = new Date();
  const unJourEnMillisecondes = 24 * 60 * 60 * 1000;
  const Demain = new Date(Today.getTime() + unJourEnMillisecondes);

  const PrepSelected = PrepData.filter((prep) => {
    return prep.Selected === true;
  });

  //On formate la liste de prep pour utiliser la fonction AddPrepToOrderPreparations
  const PrepSelectedFormated = PrepSelected.map((prep) => {
    return { DataPreparation: prep, Qte: 10 };
  });
  AddPrepToOrderPreparations(Demain, PrepSelectedFormated);
  return null;
}
export async function UpdatePrep(AllDataPrep, prep) {
  const PreparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PreparationDocRef);
  const PreparationData = PreparationSnapshot.data().preparation;

  let allPreparationData = [];
  PreparationData.forEach((Prep, index) => {
    if (prep.Nom === Prep.Nom) {
      allPreparationData = [
        ...PreparationData.slice(0, index),
        {
          ...PreparationData[index],
          ...AllDataPrep,
        },
        ...PreparationData.slice(index + 1),
      ];
    }
  });

  await setDoc(PreparationDocRef, { preparation: allPreparationData });
}

export async function handleCreateNewConditionnementLigistique(
  prep,
  NomConditionnementLogistic
) {
  const PreparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PreparationDocRef);
  const PreparationData = PreparationSnapshot.data().preparation;

  let allNewPrep = [];
  PreparationData.forEach((Prep, index) => {
    if (Prep.Nom === prep.Nom) {
      const ListeMatiere = Prep.MatierePremiereUtilisees.map((matiere) => ({
        Name: matiere.Name,
        Qte: 0,
      }));

      if (Prep.hasOwnProperty("ConditionnementLogistique")) {
        allNewPrep = [
          ...PreparationData.slice(0, index),
          {
            ...PreparationData[index],
            ConditionnementLogistique: [
              ...PreparationData[index].ConditionnementLogistique,
              {
                ConditionnementName: NomConditionnementLogistic,
                ListeMatiere,
                IDPepers: uuidv4(),
              },
            ],
          },
          ...PreparationData.slice(index + 1),
        ];
      } else {
        allNewPrep = [
          ...PreparationData.slice(0, index),
          {
            ...PreparationData[index],
            ConditionnementLogistique: [
              {
                ConditionnementName: NomConditionnementLogistic,
                ListeMatiere,
                IDPepers: uuidv4(),
              },
            ],
          },
          ...PreparationData.slice(index + 1),
        ];
      }
    }
  });

  await setDoc(PreparationDocRef, { preparation: allNewPrep });
}
export async function handleCreateNewConditionnementLigistiqueV2(
  prep,
  NomConditionnementLogistic,
  QteConditionnementLogistic
) {
  const PreparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PreparationDocRef);
  const PreparationData = PreparationSnapshot.data().preparation;

  let allNewPrep = [];
  PreparationData.forEach((Prep, index) => {
    if (Prep.Nom === prep.Nom) {
      if (Prep.hasOwnProperty("ConditionnementLogistique")) {
        allNewPrep = [
          ...PreparationData.slice(0, index),
          {
            ...PreparationData[index],
            ConditionnementLogistique: [
              ...PreparationData[index].ConditionnementLogistique,
              {
                ConditionnementName: NomConditionnementLogistic,
                QteConditionnementLogistic,
                IDPepers: uuidv4(),
              },
            ],
          },
          ...PreparationData.slice(index + 1),
        ];
      } else {
        allNewPrep = [
          ...PreparationData.slice(0, index),
          {
            ...PreparationData[index],
            ConditionnementLogistique: [
              {
                ConditionnementName: NomConditionnementLogistic,
                QteConditionnementLogistic,
                IDPepers: uuidv4(),
              },
            ],
          },
          ...PreparationData.slice(index + 1),
        ];
      }
    }
  });

  await setDoc(PreparationDocRef, { preparation: allNewPrep });
}
export async function UpdateMatierePremiere(AllDataMatiere) {
  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const allMatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;

  // Ajouter dans la base de données la nouvelles Matiere premiere
  let allNewMatiere = [];
  allMatierePremiere.forEach((Matiere, index) => {
    if (AllDataMatiere.Nom === Matiere.Nom) {
      allNewMatiere = [
        ...allMatierePremiere.slice(0, index),
        {
          ...allMatierePremiere[index],
          ...AllDataMatiere,
        },
        ...allMatierePremiere.slice(index + 1),
      ];
    }
  });

  await setDoc(MatierePremiereDocRef, { MatierePremiere: allNewMatiere });

  return null;
}
export async function SetProportionIngredientAllPrep() {
  const preparationsDocRef = doc(firestore, "Production", "preparations");
  const preparationsSnapshot = await getDoc(preparationsDocRef);
  const allpreparations = preparationsSnapshot.data().preparation;

  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const allMatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;

  const AllPrepWithProportion = allpreparations.map((prep) => {
    prep.MatierePremiereUtilisees.map((matiere) => {
      let MatiereData = allMatierePremiere.filter(
        (MatiereData) => MatiereData.Nom === matiere.Name
      );
    });
  });

  //await setDoc(MatierePremiereDocRef, { MatierePremiere: allNewMatiere });

  return null;
}

export async function SetIngredientPrixURecetteHT() {
  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const allMatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;

  const allMatierePremiereWithPrixURecette = allMatierePremiere.map(
    (Matiere) => {
      let PrixURecetteHT = 0;
      if (Matiere.hasOwnProperty("QteConditionnement")) {
        const PrixConditionnement = Matiere["Prix HT"].replace(/,/g, ".");
        const QteConditionnement = Matiere["QteConditionnement"].replace(
          /,/g,
          "."
        );
        PrixURecetteHT =
          parseFloat(PrixConditionnement) / parseFloat(QteConditionnement);

        PrixURecetteHT = parseFloat(PrixURecetteHT.toFixed(4));
        console.log(PrixURecetteHT);
      }
      return Matiere.hasOwnProperty("QteConditionnement")
        ? { ...Matiere, PrixURecetteHT }
        : { ...Matiere };
    }
  );

  await setDoc(MatierePremiereDocRef, {
    MatierePremiere: allMatierePremiereWithPrixURecette,
  });

  return null;
}

export async function SetIDPepersToProductionData(
  collectionFirebase,
  documentFirebase,
  Propriete
) {
  const MatierePremiereDocRef = doc(
    firestore,
    collectionFirebase,
    documentFirebase
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const allMatierePremiere = MatierePremiereSnapshot.data()[Propriete];

  const AllMatierePremiereWithID = allMatierePremiere.map((Matiere) => {
    return { ...Matiere, IDPErpers: uuidv4() };
  });

  console.log(AllMatierePremiereWithID);
  await setDoc(MatierePremiereDocRef, {
    [Propriete]: AllMatierePremiereWithID,
  });

  return null;
}

export async function ModifyPrepMatiereUtiliseQteWithUniteDerecette() {
  const preparationDocRef = doc(firestore, "Production", "preparations");
  const preparationSnapshot = await getDoc(preparationDocRef);
  const Allpreparation = preparationSnapshot.data().preparation;

  const RawMaterialDocRef = doc(firestore, "Production", "Matiere Premiere");
  const RawMaterialDocSnapshot = await getDoc(RawMaterialDocRef);
  const RawMaterial = RawMaterialDocSnapshot.data().MatierePremiere;

  let newListePrep = [];
  Allpreparation.forEach((prep, index) => {
    let newListeMatierePremiereUtilisees = [];
    prep.MatierePremiereUtilisees.forEach((MatierePremiereUtilisees) => {
      RawMaterial.forEach((matiere) => {
        if (matiere.Nom === MatierePremiereUtilisees.Name) {
          const newQte =
            MatierePremiereUtilisees.Qte * matiere.QteConditionnement;
          const UniteDeMesure = matiere["Unité de recettes"];
          newListeMatierePremiereUtilisees.push({
            ...MatierePremiereUtilisees,
            Qte: newQte,
            UniteDeMesure,
          });
        }
      });
    });
    newListePrep.push({
      ...prep,
      MatierePremiereUtilisees: newListeMatierePremiereUtilisees,
    });
  });

  console.log(newListePrep);
  //await setDoc(preparationDocRef, {
  //  preparation: newListePrep,
  //});

  return null;
}

export async function CreateWalletDataEveryDay(Brand, NbDayBeforeToday, date) {
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(7000)
  );

  // Utilisez getDocs pour obtenir le snapshot.
  const ordersSnapshot = await getDocs(ordersQuery);

  // Cette fonction creer un tableau de date

  function createListOfDates(startDate, numberOfDays) {
    const datesList = [];
    const currentDate = new Date(startDate);

    for (let i = 0; i < numberOfDays; i++) {
      datesList.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesList;
  }

  const startDate = new Date(
    new Date().getTime() - 86400000 * NbDayBeforeToday
  );
  const futureDates = createListOfDates(startDate, NbDayBeforeToday);
  const listeDate = [new Date(), new Date(new Date().getTime() - 86400000)];
  const dateStart = new Date(date);
  // on utilise ce tableau de date pour creer un tableau de tableau chaque tableau represente l'enssemble des commande d'une journee
  let listeOrderPerDay = [];
  futureDates.forEach((date) => {
    let ordersData = [];
    ordersSnapshot.docs.forEach((doc) => {
      let DateOrder = new Date(doc.data().dataOrder.order.created_at);
      if (
        DateOrder.getDate() === date.getDate() &&
        DateOrder.getMonth() === date.getMonth() &&
        DateOrder.getFullYear() === date.getFullYear() &&
        DateOrder.getTime() > dateStart.getTime()
      ) {
        ordersData.push(doc.data());
      }
    });
    listeOrderPerDay.push(ordersData);
  });

  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());

  let userDataOfBrandOwner = {};
  usersData.forEach((user) => {
    if (user.OwnerBrand === Brand) {
      userDataOfBrandOwner = user;
    }
  });

  const pricingDoc = doc(getFirestore(app), "pepers", Brand);
  const pricingSnapshot = await getDoc(pricingDoc);
  const pricing = pricingSnapshot.data();
  //On parcours chaque jours et on recupere que les commande de la marque du client
  let OrderClientBrand = []; // ce tableau detien un tableau avec les commandes de la marque pour chaque jours
  let OrderClientBrandModuleCommande = [];
  listeOrderPerDay.forEach((day) => {
    let OrderDay = [];
    let OrderDayModuleCommande = [];
    day.forEach((commande) => {
      if (commande.dataOrder.order.virtual_brand_name === Brand) {
        OrderDay.push(commande);
      }

      // Si une commande vient de eatic et que un des produit propre a la marque est contenu dans la commande alors la commande est comptabilisée pour cette marque
      if (commande.dataOrder.order.channel === "EATIC") {
        let test = false;
        commande.listeProduitCommande.forEach((produit) => {
          pricing.EaticTest.forEach((produitEaticTest) => {
            if (produit.name === produitEaticTest.NomZelty) {
              test = true;
            }
          });
        });
        test === true && OrderDay.push(commande);
      }
      // si une commande n'a pas de marque c'est probalement qu'elle est passé depuis le module de commande alors on effecture le meme test
      if (
        commande.dataOrder.order.virtual_brand_name === null &&
        commande.dataOrder.order.channel === "Zelty Web"
      ) {
        let test = false;
        commande.listeProduitCommande.forEach((produit) => {
          pricing.EaticTest.forEach((produitEaticTest) => {
            if (produit.name === produitEaticTest.NomZelty) {
              test = true;
            }
          });
        });
        test === true && OrderDay.push(commande);
        test === true && OrderDayModuleCommande.push(commande);
      }
    });
    OrderClientBrand.push(OrderDay);
    if (OrderDayModuleCommande.lenght != 0) {
      OrderClientBrandModuleCommande.push(OrderDayModuleCommande);
    }
  });

  // On retire les commandes annulée de la liste des commandes a facturée
  let OrderClientBrandWithOutCancelled = [];
  OrderClientBrand.forEach((day) => {
    let OrderPerDay = [];
    day.forEach((commande) => {
      if (commande.status !== "cancelled") {
        OrderPerDay.push(commande);
      }
    });
    OrderClientBrandWithOutCancelled.push(OrderPerDay);
  });

  const ListeProduitAFacturePerDay = [];
  //Pour chaque jour on creer un tableau des prduit vendu
  OrderClientBrandWithOutCancelled.forEach((day) => {
    let ListeProduitAFacture = [];
    day.forEach((commande) => {
      commande.listeProduitCommande.forEach((produit) => {
        ListeProduitAFacture.push(produit.name);
        if (produit.modifiers.lenght != 0) {
          produit.modifiers.forEach((sup) => {
            ListeProduitAFacture.push(sup.name);
          });
        }
      });
    });
    if (ListeProduitAFacture.length !== 0) {
      ListeProduitAFacturePerDay.push({
        DateOfFacture: day[0].dataOrder.order.created_at,
        ListeProduitVendu: ListeProduitAFacture,
        Sac: day.length,
      });
    }
  });
  //On compte le nombre de chaque produits vendu par jours
  const ListeProduitAFacturePerDayWithQte = [];
  ListeProduitAFacturePerDay.forEach((day) => {
    const CompteurProduit = day.ListeProduitVendu.reduce((acc, produit) => {
      acc[produit] = (acc[produit] || 0) + 1;
      return acc;
    }, {});

    ListeProduitAFacturePerDayWithQte.push({
      ...day,
      ListeProduitVendu: CompteurProduit,
    });
  });

  const ListeProduitAFacturePerDayWithQteAndPrice = [];
  ListeProduitAFacturePerDayWithQte.forEach((day) => {
    let produitAfactuer = [];
    let PrixAfacturer = 0;
    Object.keys(day.ListeProduitVendu).forEach((produitAfacturer) => {
      pricing.Pricing.forEach((produit) => {
        if (produit.NomZelty === produitAfacturer) {
          PrixAfacturer =
            PrixAfacturer +
            produit.Cost * day.ListeProduitVendu[produit.NomZelty];
          produitAfactuer.push({
            Nom: produit.NomZelty,
            Qte: day.ListeProduitVendu[produit.NomZelty],
            PrixProduit: produit.Cost,
          });
        }
      });
    });
    PrixAfacturer = (PrixAfacturer + day.Sac * 0.35) * 1.2;
    ListeProduitAFacturePerDayWithQteAndPrice.push({
      ...day,
      ListeProduitVendu: produitAfactuer,
      Amount: PrixAfacturer.toFixed(2),
    });
  });

  // On reformate les data pour qu'elle soit mise dans le wallet
  let sumallwallet = 0;
  let DataWallet = ListeProduitAFacturePerDayWithQteAndPrice.map(
    (oneFacturation) => {
      sumallwallet = sumallwallet + oneFacturation.Amount * -1;
      return {
        Amount: oneFacturation.Amount * -1,
        Detail: `Achat Produit ${oneFacturation.DateOfFacture}`,
        Etat: "Approved",
        Time: new Date(oneFacturation.DateOfFacture),
        Data: {
          ListeProduitVendu: oneFacturation.ListeProduitVendu,
          Sac: { Prix: 0.35, Qte: oneFacturation.Sac },
        },
      };
    }
  );

  const WalletUser = userDataOfBrandOwner.Wallet;
  console.log(DataWallet);

  const MergeData = await MergeWalletWithCustomerWallet(DataWallet, WalletUser);

  return MergeData;
}

export async function MergeWalletWithCustomerWallet(
  NewWalletData,
  OldWalletBrand
) {
  {
    /*const Data = NewWalletData.map((AchatMatiereDay) => {
    return [AchatMatiereDay.Time, AchatMatiereDay.Time.getDate()];
  });*/
  }
  let NewBankTransaction = [];
  OldWalletBrand.BankTransaction.forEach((transaction, index) => {
    let dateWallettransaction1;
    let dateWallettransaction2;

    NewBankTransaction.push({
      ...transaction,
      Time: new Date(transaction.Time.seconds * 1000),
    });

    if (index + 1 < OldWalletBrand.BankTransaction.length) {
      dateWallettransaction1 = new Date(transaction.Time.seconds * 1000);
      dateWallettransaction2 = new Date(
        OldWalletBrand.BankTransaction[index + 1].Time.seconds * 1000
      );

      NewWalletData.forEach((AchatMatiere) => {
        if (
          AchatMatiere.Time.getTime() > dateWallettransaction1.getTime() &&
          AchatMatiere.Time.getTime() < dateWallettransaction2.getTime()
        ) {
          NewBankTransaction.push(AchatMatiere);
        }
      });
    } else {
      dateWallettransaction1 = new Date(transaction.Time.seconds * 1000);
      dateWallettransaction2 = new Date();

      NewWalletData.forEach((AchatMatiere) => {
        if (
          AchatMatiere.Time.getTime() > dateWallettransaction1.getTime() &&
          AchatMatiere.Time.getTime() < dateWallettransaction2.getTime()
        ) {
          NewBankTransaction.push(AchatMatiere);
        }
      });
    }
  });

  let Solde = 0;
  NewBankTransaction.forEach((transaction) => {
    Solde = Solde + transaction.Amount;
  });

  const Wallet = {
    BankTransaction: NewBankTransaction,
    Solde,
  };

  return Wallet;
}
export function CalculSoldWallet(WalletData) {
  let Solde = 0;
  WalletData.BankTransaction.forEach((transaction) => {
    Solde = Solde + transaction.Amount;
  });

  return Solde;
}
export async function UpdateDatabaseWallet(WalletData, SelectedBrand) {
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());

  let userDataOfBrandOwner = {};
  usersData.forEach((user) => {
    if (user.OwnerBrand === SelectedBrand) {
      userDataOfBrandOwner = user;
    }
  });
  let UpadedWallet = { ...userDataOfBrandOwner, Wallet: WalletData };

  const UserDocRef = doc(firestore, "users", userDataOfBrandOwner.uid);
  //const UserSnapshot = await getDoc(UserDocRef);
  //const User = UserSnapshot.data();

  console.log(UpadedWallet);

  await setDoc(UserDocRef, UpadedWallet);

  return null;
}

export async function SumtransactionWallet(UserID) {
  const WalletDocRef = doc(firestore, "users", UserID);
  const WalletSnapshot = await getDoc(WalletDocRef);
  const WalletUser = WalletSnapshot.data().Wallet;

  let SumWallet = 0;
  WalletUser.BankTransaction.forEach((transaction) => {
    SumWallet = SumWallet + transaction.Amount;
  });

  //console.log(SumWallet);
  return null;
}

export async function SumSoldAllWallets() {
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());

  let SumWallet = 0;
  usersData.forEach((transaction) => {
    if (transaction.Role === "Client") {
      console.log(transaction.OwnerBrand);
      console.log(transaction.Wallet.Solde);
      SumWallet = SumWallet + transaction.Wallet.Solde;
    }
  });

  console.log(SumWallet);
  return SumWallet;
}
export async function CreateNewInventaireInDataBase(
  InventaireMatiere,
  InventairePrep
) {
  const InventaireDocRef = doc(firestore, "ManagerRestaurant", "Inventaire");
  const InventaireSnapshot = await getDoc(InventaireDocRef);
  const AllInventaireData = InventaireSnapshot.data();

  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const StockPrep = StockSnapshot.data().Preparation;

  InventaireMatiere = InventaireMatiere.map((matiere) => {
    if (typeof matiere.Qte === "string") {
      let Qte = parseFloat(matiere.Qte.replace(/,/g, "."));
      return { ...matiere, Qte };
    }
    return matiere;
  });

  InventairePrep = InventairePrep.map((Prep) => {
    if (typeof Prep.Qte === "string") {
      let Qte = parseFloat(Prep.Qte.replace(/,/g, "."));
      return { ...Prep, Qte };
    }
    return Prep;
  });

  const NewInventaireData = {
    ...AllInventaireData,
    Inventaire: [
      ...AllInventaireData.Inventaire,
      {
        date: new Date(),
        DataInventaireMatiere: InventaireMatiere,
        DataInventairePrep: InventairePrep,
        IDPepersInventaire: uuidv4(),
      },
    ],
  };

  await setDoc(InventaireDocRef, NewInventaireData);

  const FormatedDataForUpdatedStockMatiere = InventaireMatiere.map(
    (matiere) => ({
      IDPErpers: matiere.DataMatiere.IDPErpers,
      QteEnStock: matiere.Qte,
    })
  );

  const FormatedDataForUpdatedStockPrep = InventairePrep.map((Prep) => ({
    IDPErpers: Prep.DataPrep.IDPErpers,
    QteEnStock: Prep.Qte,
  }));

  const FormatedDataForUpdatedStock = {
    MatierePremiere: FormatedDataForUpdatedStockMatiere,
    Preparation: FormatedDataForUpdatedStockPrep,
  };
  await UpdateStockRestaurantAfterInventaire(FormatedDataForUpdatedStock);
  //await UpdateStockRestaurantWithAddValue(FormatedDataForUpdatedStock);
  //await UpdateStockRestaurantWithLessValue(FormatedDataForUpdatedStock);
  return null;
}
export async function CreateNewInventairePrepInDataBase(NewInventaire) {
  const InventaireDocRef = doc(firestore, "ManagerRestaurant", "Inventaire");
  const InventaireSnapshot = await getDoc(InventaireDocRef);
  const AllInventaireData = InventaireSnapshot.data();

  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const StockMatierePremiere = StockSnapshot.data().MatierePremiere;

  const NewInventaireData = {
    ...AllInventaireData,
    Inventaire: [
      ...AllInventaireData.Inventaire,
      {
        date: new Date(),
        Type: "InventairePrep",
        DataInventaire: NewInventaire,
        IDPepersInventaire: uuidv4(),
      },
    ],
  };

  await setDoc(InventaireDocRef, NewInventaireData);

  const FormatedDataForUpdatedStockPrep = NewInventaire.map((Prep) => ({
    IDPErpers: Prep.DataPrep.IDPErpers,
    QteEnStock: Prep.Qte,
  }));
  const FormatedDataForUpdatedStockMatiere = StockMatierePremiere.map(
    (Stock) => ({
      IDPErpers: Stock.IDPErpers,
      QteEnStock: Stock.QteEnStock,
    })
  );
  const FormatedDataForUpdatedStock = {
    MatierePremiere: FormatedDataForUpdatedStockMatiere,
    Preparation: FormatedDataForUpdatedStockPrep,
  };
  await UpdateStockRestaurantAfterInventaire(FormatedDataForUpdatedStock);

  return null;
}
export async function CreateNewMatierePremiereOrder(NewMatierePremiereOrder) {
  const MatiereOrderDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "MatierePremiereOrder"
  );
  const MatiereOrderSnapshot = await getDoc(MatiereOrderDocRef);
  const AllMatiereOrder = MatiereOrderSnapshot.data();

  const MatiereOrderEnAttenteDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "CommandesMatiereEnAttente"
  );
  const MatiereOrderEnAttenteSnapshot = await getDoc(
    MatiereOrderEnAttenteDocRef
  );
  const AllMatiereOrderEnAttente =
    MatiereOrderEnAttenteSnapshot.data().CommandesMatiereEnAttente;

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  const FournisseurDocRef = doc(firestore, "Production", "Fournisseurs");
  const FournisseurSnapshot = await getDoc(FournisseurDocRef);
  const AllFournisseurs = FournisseurSnapshot.data().Fournisseurs;

  // On formate la commande pour ne prendre que les produit ou la qte est sup a 0
  const FormatedMatierePremiereOrder = NewMatierePremiereOrder.filter(
    (matiere) => matiere.Qte > 0
  );
  // On recupere la liste des fournisseurs
  const listeFournisseurs = FormatedMatierePremiereOrder.map(
    (order) => order.DataMatiere.Fournisseur
  );

  const listeFournisseursSansDoublon = [...new Set(listeFournisseurs)];
  //On formate les donnees fournisseur
  let listeFournisseursFormated = listeFournisseursSansDoublon.map(
    (fournisseur) => ({
      fournisseurName: fournisseur,
      EtatCommande: "En Livraison",
      Commentaire: "",
    })
  );
  // On formate les commandes en attente pou recuperer les dataMatieres
  const AllMatiereOrderEnAttenteFormated = [];
  AllMatiereOrderEnAttente.forEach((orderEnAttente) => {
    let newDataOrder = orderEnAttente.DataOrder.map((MatiereOrder) => {
      let dataMatiere = AllMatiere.filter(
        (matiereObject) => matiereObject.IDPErpers === MatiereOrder.IdMatiere
      );
      return { DataMatiere: dataMatiere[0], Qte: MatiereOrder.qteToOrder };
    });
    AllMatiereOrderEnAttenteFormated.push({
      ...orderEnAttente,
      DataOrder: newDataOrder,
    });
  });

  // On regarde si parmis les commandes en attente il y a des commandes pour les fournisseurs commandee
  let AdedDataOrder = [];
  let NewAllMatiereOrderEnAttenteFormated = [];
  let checkIfOrderIsToUpdate;
  listeFournisseursFormated.forEach((fournisseur) => {
    checkIfOrderIsToUpdate = false;
    AllMatiereOrderEnAttenteFormated.forEach((orderEnAttente) => {
      let newListeFournisseurOrdered = [];
      orderEnAttente.ListeFournisseurOrdered.forEach((fournisseurOrdered) => {
        if (
          fournisseurOrdered.FournisseurName === fournisseur.fournisseurName &&
          fournisseurOrdered.Raison === "Min de commande non atteint" &&
          fournisseurOrdered.EtatCommande === "En attente"
        ) {
          // On ajoute les matiere de la commande en attente a la commande en cours
          AdedDataOrder = [
            ...AdedDataOrder,
            ...orderEnAttente.DataOrder.filter(
              (matiere) =>
                matiere.DataMatiere.Fournisseur === fournisseur.fournisseurName
            ),
          ];
          // On calcul le jour quand la commande arrivera
          const FournisseurData = AllFournisseurs.filter(
            (Fournisseur) =>
              fournisseurOrdered.FournisseurName === Fournisseur.Nom
          )[0];
          let NbDayOfdelivery = FournisseurData.DelaisDeLivraison;

          // On simule le prochain jour de livraison du fournisseur si la commande est passee aujourd'hui
          const DayWhereTheOrderIsPLace = new Date();
          let deliveryDateOrderMinNonAtteint = new Date(
            DayWhereTheOrderIsPLace.getTime() + NbDayOfdelivery * 86400 * 1000
          );

          FournisseurData.DayNoDelivery.forEach((NoDeliveryDay) => {
            if (deliveryDateOrderMinNonAtteint.getDay() === NoDeliveryDay) {
              deliveryDateOrderMinNonAtteint = new Date(
                deliveryDateOrderMinNonAtteint.getTime() + 86400 * 1000
              );
            }
          });
          // On change l'etat de la commande en attente celle dont le min de commande n'etait pas atteinte pour dire qu'elle est commandee
          newListeFournisseurOrdered.push({
            ...fournisseurOrdered,
            EtatCommande: "Commandee",
            DeliveryDate: deliveryDateOrderMinNonAtteint,
          });

          // On programme les autre commandes qui attendait la commande
          const OtherOrderByFournisseur =
            orderEnAttente.ListeFournisseurOrdered.filter(
              (fournisseurOrdered) =>
                fournisseurOrdered.Raison !== "Min de commande non atteint" &&
                fournisseurOrdered.EtatCommande === "En attente"
            );
          OtherOrderByFournisseur.forEach((OtherFournisseur) => {
            const FournisseurData = AllFournisseurs.filter(
              (Fournisseur) =>
                OtherFournisseur.FournisseurID === Fournisseur.IDPErpers
            )[0];
            let NbDayOfdelivery = FournisseurData.DelaisDeLivraison;
            let DateLivraisonOtherOrder = new Date(
              DayWhereTheOrderIsPLace.getTime() + NbDayOfdelivery * 86400 * 1000
            );

            let DateMaxOuIlFautPasserLaCommande;
            FournisseurData.DayNoDelivery.forEach((NoDeliveryDay) => {
              if (DateLivraisonOtherOrder.getDay() === NoDeliveryDay) {
                DateLivraisonOtherOrder = new Date(
                  DateLivraisonOtherOrder.getTime() + 86400 * 1000
                );
              }
              DateMaxOuIlFautPasserLaCommande = new Date(
                DateLivraisonOtherOrder.getTime() -
                  86400 * 1000 * NbDayOfdelivery
              );
            });
            newListeFournisseurOrdered.push({
              ...OtherFournisseur,
              EtatCommande: "Commandee",
              DeliveryDate: DateLivraisonOtherOrder,
              DateMaxOuIlFautPasserLaCommande,
            });
          });
        }
      });
      if (newListeFournisseurOrdered.length === 0) {
        newListeFournisseurOrdered = orderEnAttente.ListeFournisseurOrdered;
        checkIfOrderIsToUpdate = true;
      }
      NewAllMatiereOrderEnAttenteFormated.push({
        ...orderEnAttente,
        ListeFournisseurOrdered: newListeFournisseurOrdered,
      });
    });
  });
  let NewDataOrder = [];
  if (checkIfOrderIsToUpdate === false) {
    // On retourne dans les commandes en attente mis a jour et on regarde si la date de delivery la plus lointaine est bien celle dont la commande min n'etait pas atteinte
    let UpdateAll = [];
    NewAllMatiereOrderEnAttenteFormated.forEach((orderEnAttente) => {
      let DateDeliveryMax = new Date(0);
      orderEnAttente.ListeFournisseurOrdered.forEach((fournisseurOrdered) => {
        if (
          fournisseurOrdered.DeliveryDate.getTime() > DateDeliveryMax.getTime()
        ) {
          DateDeliveryMax = fournisseurOrdered.DeliveryDate;
        }
      });

      let newListeFournisseurOrdered = [];
      orderEnAttente.ListeFournisseurOrdered.forEach((fournisseurOrdered) => {
        let fournisseurDeliveryTime = AllFournisseurs.filter(
          (fournisseur) =>
            fournisseur.IDPErpers === fournisseurOrdered.FournisseurID
        )[0].DelaisDeLivraison;
        newListeFournisseurOrdered.push({
          ...fournisseurOrdered,
          DeliveryDate: new Date(DateDeliveryMax.getTime()),
          DateMaxOuIlFautPasserLaCommande: new Date(
            DateDeliveryMax.getTime() - 86400 * 1000 * fournisseurDeliveryTime
          ),
        });
      });

      UpdateAll.push({
        ...orderEnAttente,
        ListeFournisseurOrdered: newListeFournisseurOrdered,
      });
      // On Passe les autres commandes en attente a commande qui n'avait pas de probelme de min de commande

      const CommandeParFournisseurPasProblemMin =
        newListeFournisseurOrdered.filter(
          (fournisseurOrder) =>
            fournisseurOrder.Raison !== "Min de commande non atteint"
        );

      CommandeParFournisseurPasProblemMin.forEach((fournisseurOrder) => {
        const OrderDuFournisseurEnAttente = orderEnAttente.DataOrder.filter(
          (matiere) =>
            matiere.DataMatiere.Fournisseur === fournisseurOrder.FournisseurName
        );
        AdedDataOrder = [...AdedDataOrder, ...OrderDuFournisseurEnAttente];
      });
      let AddedListeFournisseurOrdered =
        CommandeParFournisseurPasProblemMin.map((comPasProblem) => ({
          Commentaire: "",
          EtatCommande: "En Livraison",
          fournisseurName: comPasProblem.FournisseurName,
          DateMaxOuIlFautPasserLaCommande:
            comPasProblem.DateMaxOuIlFautPasserLaCommande,
          DeliveryDate: comPasProblem.DeliveryDate,
        }));

      listeFournisseursFormated = [
        ...listeFournisseursFormated,
        ...AddedListeFournisseurOrdered,
      ];
    });
    //Il faut maintenant supprier le groupe de commande des commandes en attente
    UpdateAll = UpdateAll.filter(
      (OrderEnAttente) =>
        OrderEnAttente.ListeFournisseurOrdered[0].hasOwnProperty(
          "DeliveryDate"
        ) === false
    );
    await setDoc(MatiereOrderEnAttenteDocRef, {
      CommandesMatiereEnAttente: UpdateAll,
    });
  }

  // Cette variable contient la commande complete avec les produits en attente
  let newdataOrder = [...FormatedMatierePremiereOrder, ...AdedDataOrder];

  const NewMatiereOrder = {
    ...AllMatiereOrder,
    MatierePremiereOrder: [
      ...AllMatiereOrder.MatierePremiereOrder,
      {
        date: new Date(),
        EtatOrder: "En Livraison",
        IDOrderPepers: uuidv4(),
        DataOrder: newdataOrder,
        ListeFournisseurOrdered: listeFournisseursFormated,
      },
    ],
  };

  await setDoc(MatiereOrderDocRef, NewMatiereOrder);

  return null;
}
export async function CreateDepagangeMatiereOrder(NewMatierePremiereOrder) {
  const MatiereOrderDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "DepanageMatierePremiere"
  );

  const MatiereOrderSnapshot = await getDoc(MatiereOrderDocRef);
  const AllMatiereOrder = MatiereOrderSnapshot.data();

  // On formate la commande pour ne prendre que les produit ou la qte est sup a 0

  const FormatedOrderMatiereDepanage = NewMatierePremiereOrder.filter(
    (Matiere) => Matiere.Qte > 0
  );

  const NewMatiereOrder = {
    ...AllMatiereOrder,
    DepanageMatierePremiere: [
      ...AllMatiereOrder.DepanageMatierePremiere,
      {
        TimeOrderMiseEnStock: new Date(),
        IDOrderPepers: uuidv4(),
        DataOrder: FormatedOrderMatiereDepanage,
        MoyenDePaiement: "Carte Qonto",
        LieuAchat: "Magasin",
      },
    ],
  };

  await setDoc(MatiereOrderDocRef, NewMatiereOrder);

  // on formatte les donne pour ajouter dans le stock les nouvelles matiere recu
  const FormatedDataForUpdatedStockMatiere = FormatedOrderMatiereDepanage.map(
    (matiere) => {
      let QteEnStock = parseFloat(matiere.Qte.replace(",", "."));
      return {
        IDPErpers: matiere.DataMatiere.IDPErpers,
        QteEnStock,
      };
    }
  );

  const formatData = {
    MatierePremiere: FormatedDataForUpdatedStockMatiere,
    Preparation: [],
  };
  await UpdateStockRestaurantWithAddValue(formatData);

  return null;
}

export async function UpdateStockRestaurantAfterInventaire(
  ListeMatiereOrPreparation
) {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const Stock = StockSnapshot.data();

  const StockMatierePremiere = [];
  Stock.MatierePremiere.forEach((matiereStock) => {
    let TestIfToUpdate = false;
    ListeMatiereOrPreparation.MatierePremiere.forEach((MatiereToUpdate) => {
      if (MatiereToUpdate.IDPErpers === matiereStock.IDPErpers) {
        TestIfToUpdate = true;
        StockMatierePremiere.push({
          ...MatiereToUpdate,
          QteEnStock: parseFloat(MatiereToUpdate.QteEnStock),
        });
      }
    });
    if (TestIfToUpdate === false) {
      StockMatierePremiere.push({ ...matiereStock, QteEnStock: 0 });
    }
  });

  const StockPreparation = [];
  Stock.Preparation.forEach((PreparationStock) => {
    let TestIfToUpdate = false;
    ListeMatiereOrPreparation.Preparation.forEach((PreparationToUpdate) => {
      if (PreparationToUpdate.IDPErpers === PreparationStock.IDPErpers) {
        TestIfToUpdate = true;
        StockPreparation.push({
          ...PreparationToUpdate,
          QteEnStock: parseFloat(PreparationToUpdate.QteEnStock),
        });
      }
    });
    if (TestIfToUpdate === false) {
      StockPreparation.push({ ...PreparationStock, QteEnStock: 0 });
    }
  });

  const NewStockAll = {
    MatierePremiere: StockMatierePremiere,
    Preparation: StockPreparation,
  };

  await setDoc(StockDocRef, NewStockAll);
  return null;
}

// On prends une liste de matiere et prep et on ajoute en plus a la qte precedente uniquement les nouvelles valeur les autre reste identique
export async function UpdateStockRestaurantWithAddValue(
  ListeMatiereOrPreparation
) {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const Stock = StockSnapshot.data();

  const StockMatierePremiere = [];
  Stock.MatierePremiere.forEach((matiereStock) => {
    let TestIfToUpdate = false;
    ListeMatiereOrPreparation.MatierePremiere.forEach((MatiereToUpdate) => {
      if (MatiereToUpdate.IDPErpers === matiereStock.IDPErpers) {
        TestIfToUpdate = true;
        if (typeof MatiereToUpdate.QteEnStock === "string") {
          StockMatierePremiere.push({
            ...MatiereToUpdate,
            QteEnStock:
              matiereStock.QteEnStock +
              parseFloat(MatiereToUpdate.QteEnStock.replace(",", ".")),
          });
        } else {
          StockMatierePremiere.push({
            ...MatiereToUpdate,
            QteEnStock: matiereStock.QteEnStock + MatiereToUpdate.QteEnStock,
          });
        }
      }
    });
    if (TestIfToUpdate === false) {
      StockMatierePremiere.push(matiereStock);
    }
  });

  const StockPreparation = [];
  Stock.Preparation.forEach((PreparationStock) => {
    let TestIfToUpdate = false;
    ListeMatiereOrPreparation.Preparation.forEach((PreparationToUpdate) => {
      if (PreparationToUpdate.IDPErpers === PreparationStock.IDPErpers) {
        TestIfToUpdate = true;
        if (typeof PreparationToUpdate.QteEnStock === "string") {
          StockPreparation.push({
            ...PreparationToUpdate,
            QteEnStock:
              PreparationStock.QteEnStock +
              parseFloat(PreparationToUpdate.QteEnStock.replace(",", ".")),
          });
        } else {
          StockPreparation.push({
            ...PreparationToUpdate,
            QteEnStock:
              PreparationStock.QteEnStock + PreparationToUpdate.QteEnStock,
          });
        }
      }
    });
    if (TestIfToUpdate === false) {
      StockPreparation.push(PreparationStock);
    }
  });

  const NewStockAll = {
    MatierePremiere: StockMatierePremiere,
    Preparation: StockPreparation,
  };

  await setDoc(StockDocRef, NewStockAll);
  return null;
}
export async function UpdateStockRestaurantWithLessValue(
  ListeMatiereOrPreparation
) {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const Stock = StockSnapshot.data();

  const StockMatierePremiere = [];
  Stock.MatierePremiere.forEach((matiereStock) => {
    let TestIfToUpdate = false;
    ListeMatiereOrPreparation.MatierePremiere.forEach((MatiereToUpdate) => {
      if (MatiereToUpdate.IDPErpers === matiereStock.IDPErpers) {
        TestIfToUpdate = true;
        if (typeof MatiereToUpdate.QteEnStock === "string") {
          StockMatierePremiere.push({
            ...MatiereToUpdate,
            QteEnStock:
              matiereStock.QteEnStock -
              parseFloat(MatiereToUpdate.QteEnStock.replace(",", ".")),
          });
        } else {
          StockMatierePremiere.push({
            ...MatiereToUpdate,
            QteEnStock: matiereStock.QteEnStock - MatiereToUpdate.QteEnStock,
          });
        }
      }
    });
    if (TestIfToUpdate === false) {
      StockMatierePremiere.push(matiereStock);
    }
  });

  const StockPreparation = [];
  Stock.Preparation.forEach((PreparationStock) => {
    let TestIfToUpdate = false;
    ListeMatiereOrPreparation.Preparation.forEach((PreparationToUpdate) => {
      if (PreparationToUpdate.IDPErpers === PreparationStock.IDPErpers) {
        TestIfToUpdate = true;
        if (typeof PreparationToUpdate.QteEnStock === "string") {
          StockPreparation.push({
            ...PreparationToUpdate,
            QteEnStock:
              PreparationStock.QteEnStock -
              parseFloat(PreparationToUpdate.QteEnStock.replace(",", ".")),
          });
        } else {
          StockPreparation.push({
            ...PreparationToUpdate,
            QteEnStock:
              PreparationStock.QteEnStock - PreparationToUpdate.QteEnStock,
          });
        }
      }
    });
    if (TestIfToUpdate === false) {
      StockPreparation.push(PreparationStock);
    }
  });

  const NewStockAll = {
    MatierePremiere: StockMatierePremiere,
    Preparation: StockPreparation,
  };

  await setDoc(StockDocRef, NewStockAll);
  return null;
}
export async function HandleValidateMatierePremiereOrder(
  ListeMatiereToValidate
) {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const Stock = StockSnapshot.data();

  const MatiereOrdersDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "MatierePremiereOrder"
  );
  const MatiereOrdersSnapshot = await getDoc(MatiereOrdersDocRef);
  const MatiereOrderAllData = MatiereOrdersSnapshot.data();
  const MatiereOrders = MatiereOrdersSnapshot.data().MatierePremiereOrder;
  const OrderInitialeToValidate = MatiereOrders.filter(
    (order) => order.IDOrderPepers === ListeMatiereToValidate.IDOrderPepers
  );
  // on isole le nom du founisseur en question
  const FournisseurToValidate =
    ListeMatiereToValidate.OrderPerFournisseur[0].DataMatiere.Fournisseur;
  // On regarde les produits commandé chez le fournisseur en question dans la commande initiale
  const ProduitCommandeChezLeFournisseur =
    OrderInitialeToValidate[0].DataOrder.filter(
      (prod) => prod.DataMatiere.Fournisseur === FournisseurToValidate
    );

  // On regarde si il y a des modification par rapport a la commande initiale
  // Si il y a des modifs c'est que ce qui avait ete commandé initialement n'a pas ete livré

  let TestIfModifictaion = false;
  let DifQteOrder = 0;
  ProduitCommandeChezLeFournisseur.forEach((OrderedMAtiere) => {
    ListeMatiereToValidate.OrderPerFournisseur.forEach((MatiereDeliver) => {
      if (
        OrderedMAtiere.DataMatiere.IDPErpers ===
        MatiereDeliver.DataMatiere.IDPErpers
      ) {
        if (OrderedMAtiere.Qte === MatiereDeliver.Qte) {
        } else {
          TestIfModifictaion = true;
          let CMatiereDeliver = parseFloat(MatiereDeliver.Qte);
          let COrderedMAtiere = parseFloat(OrderedMAtiere.Qte);
          DifQteOrder = CMatiereDeliver - COrderedMAtiere;
          if (DifQteOrder > 0) {
            console.log(
              `Nous avons recu plus que commandé ${DifQteOrder} ${MatiereDeliver.DataMatiere.Nom}`
            );
          } else {
            console.log(
              `Nous avons recu moin que commandé ${DifQteOrder} ${MatiereDeliver.DataMatiere.Nom}`
            );
          }
        }
      }
    });
  });

  let NewMatierePremiereOrdersAll = [];
  if (TestIfModifictaion) {
    console.log("Probleme dans la livraison");
    MatiereOrders.forEach((order, index) => {
      if (order.IDOrderPepers === ListeMatiereToValidate.IDOrderPepers) {
        let etatcommandeFournisseur = "";
        if (DifQteOrder > 0) {
          etatcommandeFournisseur = "Trop Recu";
        }
        if (DifQteOrder < 0) {
          etatcommandeFournisseur = "Moins Recu";
        }
        // On modifie l'etat du fournisseur
        let NewListeFournisseurOrdered = [];
        MatiereOrders[index].ListeFournisseurOrdered.forEach(
          (fournisseur, indexF) => {
            if (fournisseur.fournisseurName === FournisseurToValidate) {
              NewListeFournisseurOrdered = [
                ...MatiereOrders[index].ListeFournisseurOrdered.slice(
                  0,
                  indexF
                ),
                {
                  ...MatiereOrders[index].ListeFournisseurOrdered[indexF],
                  EtatCommande: etatcommandeFournisseur,
                  TimeOrderMiseEnStock: new Date(),
                },
                ...MatiereOrders[index].ListeFournisseurOrdered.slice(
                  indexF + 1
                ),
              ];
            }
          }
        );
        // On modifie la liste des produits commander pour indiquer la qte reelement recu
        let NewDataOrderOrdered = [];
        MatiereOrders[index].DataOrder.forEach((matiereOrdered) => {
          let isMatiereExist = false;
          ListeMatiereToValidate.OrderPerFournisseur.forEach(
            (matiereReceive) => {
              if (
                matiereOrdered.DataMatiere.IDPErpers ===
                matiereReceive.DataMatiere.IDPErpers
              ) {
                isMatiereExist = true;
                NewDataOrderOrdered.push({
                  ...matiereOrdered,
                  QteRecived: matiereReceive.Qte,
                  TimeOrderMiseEnStock: new Date(),
                });
              }
            }
          );
          if (!isMatiereExist) {
            NewDataOrderOrdered.push({ ...matiereOrdered });
          }
        });

        // On verifie si cette commande fournisseur est la derniere commande a validé de la commande si c'est la derniere alors on passe la commande total au statut livree
        const NombreDeCommandeEncoreEnCours =
          order.ListeFournisseurOrdered.filter(
            (fournisseur) => fournisseur.EtatCommande === "En Livraison"
          ).length - 1;

        let EtatOrder = "En Livraison";
        if (NombreDeCommandeEncoreEnCours === 0) {
          EtatOrder = "Livree";
        }

        NewMatierePremiereOrdersAll = [
          ...MatiereOrders.slice(0, index),
          {
            ...MatiereOrders[index],
            ListeFournisseurOrdered: NewListeFournisseurOrdered,
            DataOrder: NewDataOrderOrdered,
            EtatOrder,
          },
          ...MatiereOrders.slice(index + 1),
        ];
      }
    });
  } else {
    console.log("All GOOD");
    MatiereOrders.forEach((order, index) => {
      if (order.IDOrderPepers === ListeMatiereToValidate.IDOrderPepers) {
        let etatcommandeFournisseur = "Recu All Good";

        let NewListeFournisseurOrdered = [];
        MatiereOrders[index].ListeFournisseurOrdered.forEach(
          (fournisseur, indexF) => {
            if (fournisseur.fournisseurName === FournisseurToValidate) {
              NewListeFournisseurOrdered = [
                ...MatiereOrders[index].ListeFournisseurOrdered.slice(
                  0,
                  indexF
                ),
                {
                  ...MatiereOrders[index].ListeFournisseurOrdered[indexF],
                  EtatCommande: etatcommandeFournisseur,
                  TimeOrderMiseEnStock: new Date(),
                },
                ...MatiereOrders[index].ListeFournisseurOrdered.slice(
                  indexF + 1
                ),
              ];
            }
          }
        );
        // On modifie la liste des produits commander pour indiquer la qte reelement recu
        let NewDataOrderOrdered = [];
        MatiereOrders[index].DataOrder.forEach((matiereOrdered) => {
          let isMatiereExist = false;
          ListeMatiereToValidate.OrderPerFournisseur.forEach(
            (matiereReceive) => {
              if (
                matiereOrdered.DataMatiere.IDPErpers ===
                matiereReceive.DataMatiere.IDPErpers
              ) {
                isMatiereExist = true;
                NewDataOrderOrdered.push({
                  ...matiereOrdered,
                  QteRecived: matiereReceive.Qte,
                  TimeOrderMiseEnStock: new Date(),
                });
              }
            }
          );
          if (!isMatiereExist) {
            NewDataOrderOrdered.push({ ...matiereOrdered });
          }
        });

        // On verifie si cette commande fournisseur est la derniere commande a validé de la commande si c'est la derniere alors on passe la commande total au statut livree
        const NombreDeCommandeEncoreEnCours =
          order.ListeFournisseurOrdered.filter(
            (fournisseur) => fournisseur.EtatCommande === "En Livraison"
          ).length - 1;

        let EtatOrder = "En Livraison";
        if (NombreDeCommandeEncoreEnCours === 0) {
          EtatOrder = "Livree";
        }

        NewMatierePremiereOrdersAll = [
          ...MatiereOrders.slice(0, index),
          {
            ...MatiereOrders[index],
            ListeFournisseurOrdered: NewListeFournisseurOrdered,
            DataOrder: NewDataOrderOrdered,
            EtatOrder,
          },
          ...MatiereOrders.slice(index + 1),
        ];
      }
    });
  }
  await setDoc(MatiereOrdersDocRef, {
    ...MatiereOrderAllData,
    MatierePremiereOrder: NewMatierePremiereOrdersAll,
  });

  // on formatte les donne pour ajouter dans le stock les nouvelles matiere recu
  const FormatedDataForUpdatedStockMatiere =
    ListeMatiereToValidate.OrderPerFournisseur.map((matiere) => ({
      IDPErpers: matiere.DataMatiere.IDPErpers,
      QteEnStock: parseFloat(matiere.Qte),
    }));

  const formatData = {
    MatierePremiere: FormatedDataForUpdatedStockMatiere,
    Preparation: [],
  };
  await UpdateStockRestaurantWithAddValue(formatData);

  return null;
}
export async function HandleRefairePrep(Prep) {
  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiereObject = MatiereSnapshot.data().MatierePremiere;

  // On reformate les data de matiere utiliser pour verifier si la qte de matiere est bien un nombr evant de l'entree dans la base
  const MatiereUsed = Prep.MatierePremiereUtilisees.map((matiereUtilisee) => {
    if (typeof matiereUtilisee.Qte === "string") {
      let Qte = parseFloat(matiereUtilisee.Qte.replace(/,/g, "."));
      return { ...matiereUtilisee, Qte };
    } else {
      return matiereUtilisee;
    }
  });
  const newPrepData = { ...Prep, MatierePremiereUtilisees: MatiereUsed };

  const FormatedDataForUpdatedStockMatiere =
    newPrepData.MatierePremiereUtilisees.map((matiere) => {
      let Data = {};
      AllMatiereObject.forEach((MatireObject) => {
        if (MatireObject.Nom === matiere.Name) {
          let QteEnValeurDeConditionnement =
            matiere.Qte /
            parseFloat(MatireObject.QteConditionnement.replace(",", "."));
          Data = {
            IDPErpers: MatireObject.IDPErpers,
            QteEnStock: QteEnValeurDeConditionnement,
          };
        }
      });
      return Data;
    });

  const formatDataAddStock = {
    MatierePremiere: [],
    Preparation: [
      { IDPErpers: newPrepData.IDPErpers, QteEnStock: newPrepData.Quantite },
    ],
  };
  await UpdateStockRestaurantWithAddValue(formatDataAddStock);

  const formatDataLessStock = {
    MatierePremiere: FormatedDataForUpdatedStockMatiere,
    Preparation: [],
  };
  await UpdateStockRestaurantWithLessValue(formatDataLessStock);

  const HistoricRefairePrepDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "HistoricRefairePrep"
  );
  const HistoricRefairePrepSnapshot = await getDoc(HistoricRefairePrepDocRef);
  const HistoricRefairePrep =
    HistoricRefairePrepSnapshot.data().EventRefairePrep;

  await setDoc(HistoricRefairePrepDocRef, {
    EventRefairePrep: [
      ...HistoricRefairePrep,
      {
        date: new Date(),
        IDPrepPepers: Prep.IDPErpers,
        MatiereUtiliseAuStock: newPrepData.MatierePremiereUtilisees,
        PrepData: newPrepData,
        QteRefait: newPrepData.Quantite,
      },
    ],
  });

  return null;
}
export async function HandleJetterPrep(Prep) {
  let FormatedDataForUpdatedStockPrep = [
    {
      IDPErpers: Prep.IDPErpers,
      QteEnStock: Prep.QtePrepAJetter,
    },
  ];

  const formatDataLessStock = {
    MatierePremiere: [],
    Preparation: FormatedDataForUpdatedStockPrep,
  };

  await UpdateStockRestaurantWithLessValue(formatDataLessStock);
  const HistoricPrepJetteDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "HistoricPrepJette"
  );
  const HistoricPrepJetteSnapshot = await getDoc(HistoricPrepJetteDocRef);
  const HistoricPrepJette =
    HistoricPrepJetteSnapshot.data().PrepEventJetterPrep;

  await setDoc(HistoricPrepJetteDocRef, {
    PrepEventJetterPrep: [
      ...HistoricPrepJette,
      { date: new Date(), PrepData: Prep, RaisonJetter: Prep.RaisonJetter },
    ],
  });

  return null;
}

export async function HandleSwitchPrep(Data) {
  let FormatedDataForUpdatedAddPrepToSwitch = [
    {
      IDPErpers: Data.PrepToSwitch.IDPErpers,
      QteEnStock: Data.PrepToSwitch.QtePrepASwitch,
    },
  ];

  const formatDataAddStock = {
    MatierePremiere: [],
    Preparation: FormatedDataForUpdatedAddPrepToSwitch,
  };

  await UpdateStockRestaurantWithAddValue(formatDataAddStock);

  let FormatedDataForUpdatedLessPrepSwitched = [
    {
      IDPErpers: Data.SwitchWith.IDPErpers,
      QteEnStock: Data.SwitchWith.QtePrepASwitch2,
    },
  ];

  const formatDataLessStock = {
    MatierePremiere: [],
    Preparation: FormatedDataForUpdatedLessPrepSwitched,
  };

  await UpdateStockRestaurantWithLessValue(formatDataLessStock);

  const HistoricPrepSwitchDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "HistoricPrepSwitch"
  );
  const HistoricPrepSwitchSnapshot = await getDoc(HistoricPrepSwitchDocRef);
  const HistoricPrepSwitch = HistoricPrepSwitchSnapshot.data();

  await setDoc(HistoricPrepSwitchDocRef, {
    ...HistoricPrepSwitch,
    PrepEventSwitchPrep: [
      ...HistoricPrepSwitch.PrepEventSwitchPrep,
      { date: new Date(), Detail: Data },
    ],
  });

  return null;
}

export async function HandleJeterMatierePremiere(MatiereData) {
  let FormatedDataForUpdatedStockMatiere = [
    {
      IDPErpers: MatiereData.IDPErpers,
      QteEnStock: MatiereData.QteMatiereAJetter,
    },
  ];

  const formatDataLessStock = {
    MatierePremiere: FormatedDataForUpdatedStockMatiere,
    Preparation: [],
  };

  await UpdateStockRestaurantWithLessValue(formatDataLessStock);

  const HistoricjeterMatiereDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "HistoricJeterMatierePremiere"
  );
  const HistoricjeterMatiereSnapshot = await getDoc(HistoricjeterMatiereDocRef);
  const HistoricjeterMatiere =
    HistoricjeterMatiereSnapshot.data().EventJetterMatiere;

  await setDoc(HistoricjeterMatiereDocRef, {
    EventJetterMatiere: [
      ...HistoricjeterMatiere,
      { date: new Date(), MatiereData, RaisonJetter: MatiereData.RaisonJetter },
    ],
  });

  return null;
}

export async function CreateActualitie() {
  const ActuDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "FilDactualiteRestaurant"
  );
  const ActuSnapshot = await getDoc(ActuDocRef);
  const AllActu = ActuSnapshot.data().Actualite;

  await setDoc(ActuDocRef, {
    Actualite: [
      {
        DateToDisplay: new Date(),
        Data: {
          Message: "Attention nouvelle prep a faire aujourd'huit 11h 30---1",
          Video: "",
        },
        Type: "New Prep",
      },
      {
        DateToDisplay: new Date(),
        Data: {
          Message: "Attention nouvelle prep a faire aujourd'huit 11h 3---2",
          Video: "",
        },
        Type: "New Prep",
      },
      {
        DateToDisplay: new Date(),
        Data: {
          Message: "Attention nouvelle prep a faire aujourd'huit 11h 30 ---3",
          Video: "",
        },
        Type: "New Prep",
      },
    ],
  });

  return null;
}
