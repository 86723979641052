import React, { useState, useEffect } from "react";
import {
  getEtatDesStockMatiereAuRestaurant,
  getFournisseurSearchBarData,
} from "../../../Data/firebaseService";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ModalJeterMatierePremiere from "../../Modal/ModalJeterMatierePremiere/ModalJeterMatierePremiere";
import "./VisibilityStock.css";

const VisibilityStockMatiere = () => {
  const [StockInRestauarant, setStockInRestauarant] = useState(null);
  const [Fournisseur, setFournisseur] = useState("ALL");
  const [AllFournisseur, setAllFournisseur] = useState(null);
  const [MaiereSelected, setMatiereSelected] = useState(null);
  const [isOpenModalJeterMatiere, setIsOpenModalJeterMatiere] = useState(false);

  useEffect(() => {
    async function fetchUserBrand() {
      const Stock = await getEtatDesStockMatiereAuRestaurant();
      setStockInRestauarant(Stock);

      let AllFournisseur = await getFournisseurSearchBarData();
      setAllFournisseur([...AllFournisseur, { Nom: "ALL" }]);
    }
    fetchUserBrand();
  }, []);

  const HandleOpen = (Matiere) => {
    setIsOpenModalJeterMatiere(true);
    setMatiereSelected(Matiere);
  };
  const HandleClose = () => {
    setIsOpenModalJeterMatiere(false);
    setMatiereSelected(null);
  };

  return (
    <div>
      <div className="CreateInventaire">
        <div className="TrieMatiere">
          <div className="menuItem1">Recherche</div>
          <div className="menuItem1">
            <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
              <InputLabel id="select-unite-Preparation">
                Selection fournisseur
              </InputLabel>
              <Select
                labelId="select-Fournisseeur"
                id="select-Fournisseeur"
                value={Fournisseur}
                onChange={(e) => setFournisseur(e.target.value)}
              >
                {AllFournisseur != null &&
                  AllFournisseur.map((fournisseur) => (
                    <MenuItem key={fournisseur.Nom} value={fournisseur.Nom}>
                      {fournisseur.Nom}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {StockInRestauarant &&
          StockInRestauarant.map((matiere, index) => {
            return Fournisseur === "ALL" ? (
              <div
                className={
                  matiere.DataMatiere.Statut === "Inactif"
                    ? "RowInactiv"
                    : "RowMatiere"
                }
              >
                <div>{matiere.DataMatiere.Nom}</div>
                <div>{matiere.DataMatiere.Fournisseur}</div>
                <div>{matiere.QteEnStock}</div>
                <div>{matiere.DataMatiere.Conditionnement}</div>
                <div
                  style={{ color: "red" }}
                  onClick={() => HandleOpen(matiere)}
                >
                  Jeter
                </div>
              </div>
            ) : (
              matiere.DataMatiere.Fournisseur === Fournisseur && (
                <div className="RowMatiere">
                  <div>{matiere.DataMatiere.Nom}</div>
                  <div>{matiere.DataMatiere.Fournisseur}</div>
                  <div>{matiere.QteEnStock}</div>
                  <div>{matiere.DataMatiere.Conditionnement}</div>
                  <div
                    style={{ color: "red" }}
                    onClick={() => HandleOpen(matiere)}
                  >
                    Jeter
                  </div>
                </div>
              )
            );
          })}
      </div>
      {MaiereSelected && (
        <ModalJeterMatierePremiere
          open={isOpenModalJeterMatiere}
          handleClose={HandleClose}
          MatierePremiere={MaiereSelected}
        />
      )}
    </div>
  );
};

export default VisibilityStockMatiere;
