import React, { useState, useEffect } from "react";
import "./GestionPreparation.css";
import { GetGestionPreparationData } from "../../../../../../Data/firebaseInteractionOwnerV2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import DetailRefairePrep from "./DetailRefairePrep/DetailRefairePrep";
import { CircularProgress } from "@mui/material";

const GestionPreparation = ({ RestaurantName }) => {
  const [GestionPreparation, setGestionPreparation] = useState(null);
  const [SelectedPrep, setSelectedPrep] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const Preparation = await GetGestionPreparationData(RestaurantName);
      setGestionPreparation(Preparation);
    }

    fetchData();
  }, [RestaurantName]);
  return (
    <div className="ContenerGestionPreparation">
      <div className="TableDisplayGestionPrep">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Preparation</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="left">Last refait date</TableCell>
                <TableCell align="left">Last refait Qte</TableCell>
                <TableCell align="left">Qte theorique stock</TableCell>
                <TableCell align="left">Qte jetee</TableCell>
                <TableCell align="left">Unite De Mesure</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {GestionPreparation ? (
                GestionPreparation.map((Prep) => {
                  const LastRefaitDate = Prep.lasteRefaitDate;

                  const year = LastRefaitDate.getFullYear();
                  const month = String(LastRefaitDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const day = String(LastRefaitDate.getDate()).padStart(2, "0");
                  const FormatedDate = `${day}/${month}/${year} - ${LastRefaitDate.getHours()} : ${LastRefaitDate.getMinutes()}`;

                  return (
                    <TableRow
                      key={Prep.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onClick={() => setSelectedPrep(Prep.IDPrep)}
                    >
                      <TableCell component="th" scope="row">
                        {Prep.name}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Avatar alt={Prep.name} src={Prep.img} />
                      </TableCell>
                      <TableCell align="left">{FormatedDate}</TableCell>
                      <TableCell align="left">{Prep.lasteRefaitQte}</TableCell>
                      <TableCell align="left">
                        {Prep.QteTheoriqueStock}
                      </TableCell>
                      <TableCell align="left">{Prep.QteJete}</TableCell>
                      <TableCell align="left">{Prep.UniteDeMesure}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  key={1}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <CircularProgress size={24} color="secondary" />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DetailRefairePrep
        RestaurantName={RestaurantName}
        PrepID={SelectedPrep}
      />
    </div>
  );
};

export default GestionPreparation;
