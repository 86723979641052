import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getModuleCommandeData } from "../../Data/firebaseInteractionMarketing";
import HeaderModuleDeCommande from "./HeaderModuleDeCommande/HeaderModuleDeCommande";
import AdresseInfo from "./AdresseInfo/AdresseInfo";
import DisplayCategoriesModuleCommande from "./DisplayCategoriesModuleCommande/DisplayCategoriesModuleCommande";
import MainAreaProductAndCart from "./MainAreaProductAndCart/MainAreaProductAndCart";

const ModuleDeCommande = () => {
  let { ModuleID } = useParams();
  const [DishModule, setDishModule] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const Data = await getModuleCommandeData(ModuleID);
      setDishModule(Data);
    }
    fetchData();
  }, []);

  console.log(DishModule);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto auto auto auto",
        rowGap: "10px",
        justifyContent: "center",
        paddingLeft: "5vw", // Marge à gauche proportionnelle à la largeur de la fenêtre
        paddingRight: "5vw",
      }}
    >
      <HeaderModuleDeCommande />
      <AdresseInfo />
      <DisplayCategoriesModuleCommande />
      {DishModule && <MainAreaProductAndCart Data={DishModule} />}
    </div>
  );
};

export default ModuleDeCommande;
