import React, { useState } from "react";
import { getZeltyDishRupture } from "../../../../Data/firebaseInteractionOwnerV2";
import CircularProgress from "@material-ui/core/CircularProgress";

const GetAvailableProductFromZeltyForProductWhoShoudBeInTheRestaurant = ({
  data,
  setData,
  RestaurantName,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleFetchData = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setData(null);
    const rupture = await getZeltyDishRupture(RestaurantName);
    setData(rupture);
    setIsProcessing(false);
  };

  return (
    <div>
      {isProcessing ? (
        <div style={{ textAlign: "center", padding: "10px" }}>
          <CircularProgress size={24} color="secondary" />
          <p>Chargement des données...</p>
        </div>
      ) : (
        <div
          onClick={handleFetchData}
          style={{
            padding: "15px",
            border: "1px solid black",
            cursor: "pointer",
          }}
        >
          FetchData etat zelty produit qui devrait etre dans le restaurant
        </div>
      )}
    </div>
  );
};

export default GetAvailableProductFromZeltyForProductWhoShoudBeInTheRestaurant;
