import React, { useState, useEffect } from "react";
import "./FicheTechniquePrep.css";
import { getFicheTechniquePrep } from "../../../Data/firebaseService";

const FicheTechniquePrep = ({ prepSelected }) => {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchOrders() {
      const cardData = await getFicheTechniquePrep(prepSelected);
      setData(cardData);
    }
    fetchOrders();
  }, [prepSelected]);
  console.log(data);

  return (
    <div className="MainFicheTechniquePrep">
      <div className="ParentFicheTechniquePrep">
        {data &&
          data.NomMatiereAvecQte.length != 0 &&
          data.NomMatiereAvecQte.map((matiere) => {
            return (
              <div>
                <span className="Matiere-Name">{matiere.Name}</span>
                <span className="Matiere-Qte">{matiere.Qte}</span>
                <span className="Matiere-Conditionnement">
                  {matiere.Conditionnement}
                </span>
              </div>
            );
          })}
      </div>
      <div className="ParentFicheTechniquePrep">
        {data && data.PrepData.hasOwnProperty("Recette") ? (
          <div>{data.PrepData.Recette}</div>
        ) : (
          <div>Selectionner une prep</div>
        )}
      </div>
    </div>
  );
};

export default FicheTechniquePrep;
