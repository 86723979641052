import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ButtonJeterMatierePremiere from "../../Button/ButtonJeterMatierePremiere/ButtonJeterMatierePremiere";

const ModalJeterMatierePremiere = ({ open, handleClose, MatierePremiere }) => {
  const [RaisonJetter, setRaisonJetter] = useState("");
  const [QteMatiereAJetter, setQteMatiereAJetter] = useState(0);
  console.log(MatierePremiere);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {MatierePremiere.DataMatiere.Nom}
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          QTE EN STOCK : {MatierePremiere.QteEnStock}{" "}
          {MatierePremiere.DataMatiere.Conditionnement}
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {MatierePremiere.DataMatiere.Fournisseur}
        </Typography>
        <TextField
          label="Qte a jeter"
          variant="outlined"
          type="number"
          step="0.01"
          value={QteMatiereAJetter}
          onChange={(e) =>
            setQteMatiereAJetter((oldValue) => {
              const inputValue = e.target.value;
              // Vérifier si l'entrée est un nombre et n'est pas une chaîne vide
              const isNumber = !isNaN(inputValue) && inputValue !== "";
              let newQteAJetter = oldValue;
              if (isNumber) {
                newQteAJetter = parseFloat(inputValue);
              }
              return newQteAJetter;
            })
          }
        />
        {MatierePremiere.DataMatiere.Conditionnement}
        <div>
          <br />
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">
              Raison du jeter
            </InputLabel>
            <Select
              labelId="select-TypePreparation"
              id="select-TypePreparation"
              value={RaisonJetter}
              onChange={(e) => setRaisonJetter(e.target.value)}
            >
              <MenuItem key={"Pourrie"} value={"Pourrie"}>
                Pourrie
              </MenuItem>
              <MenuItem key={"Tombee"} value={"Tombee"}>
                Tombee
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <ButtonJeterMatierePremiere
            MatiereData={{
              ...MatierePremiere.DataMatiere,
              QteMatiereAJetter,
              RaisonJetter,
            }}
          />
        </Typography>
      </Box>
    </Modal>
  );
};

export default ModalJeterMatierePremiere;
