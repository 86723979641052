import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ModalUpdateWorkplanWithListOfProduct from "../../Modal/ModalUpdateWorkplanWithListOfProduct/ModalUpdateWorkplanWithListOfProduct";

const ButtonUpdateWorkplanWithListOfProduct = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Update PDT
      </Button>
      <ModalUpdateWorkplanWithListOfProduct
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default ButtonUpdateWorkplanWithListOfProduct;
