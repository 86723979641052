import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography ,
} from "@mui/material";
import "./ModaladdNewCategorie.css";
import {AddNewCategorie} from '../../../Functions/HandleClickFunctions'


export default function ModaladdNewCategorie({ open, handleClose }) {

    const [NomCategorie, setNomCategorie] = useState("")
    const [CoefficientMarge, setCoefficientMarge] = useState(2.5)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
        <div style={{ fontSize: '35px'}}>Ajouter une categorie</div>
        <Typography id="modal-modal-title-AddProduct" variant="h6" component="h2">
            <TextField label="Nom Categorie" variant="outlined" value={NomCategorie} onChange={(e)=>setNomCategorie(e.target.value)}/>
        </Typography>
        <Typography id="modal-PosteConfection-AddProduct" sx={{ mt: 2 }}>
            <TextField label="coefficient de Marge" variant="outlined" value={CoefficientMarge} onChange={(e)=>setCoefficientMarge(e.target.value)}/>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={(e)=>{
                AddNewCategorie(NomCategorie, CoefficientMarge)
                handleClose()
                }}>
                Creer categorie
            </Button>
        </Typography>
      </Box>
    </Modal>
  );
}