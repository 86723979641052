import React, { useState } from "react";
import HeaderChoixDuRestaurant from "../../../components/OwnerV2Components/LesRestaurantPageComponant/HeaderChoixDuRestaurant/HeaderChoixDuRestaurant";
import SecondAreaLesResto from "../../../components/OwnerV2Components/LesRestaurantPageComponant/SecondAreaLesResto/SecondAreaLesResto";
import BottomLesRestos from "../../../components/OwnerV2Components/LesRestaurantPageComponant/BottomLesRestos/BottomLesRestos";

const LesRestaurants = () => {
  const [DisplayStockAndDish, setDisplayStockAndDish] = useState(false);
  return (
    <div
      style={
        DisplayStockAndDish
          ? { display: "grid", gridTemplateRows: "1fr 4fr 4fr", gap: "10px" }
          : { display: "grid", gridTemplateRows: "1fr 8fr", gap: "10px" }
      }
    >
      <HeaderChoixDuRestaurant />
      {DisplayStockAndDish && <SecondAreaLesResto />}
      <BottomLesRestos RestaurantName={"RestaurantSainteFoy"} />
    </div>
  );
};

export default LesRestaurants;
