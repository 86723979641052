import React, { useState } from "react";
import "./ConssomationEntreDeuxInventaire.css";
import SelectInventaire from "./SelectInventaire/SelectInventaire";
import DataConsomationEntreDeuxInventaire from "./DataConsomationEntreDeuxInventaire/DataConsomationEntreDeuxInventaire";

const ConssomationEntreDeuxInventaire = ({ RestaurantName }) => {
  const [InventaireSelectedStart, setInventaireSelectedStart] = useState(null);
  const [InventaireSelectedEnd, setInventaireSelectedEnd] = useState(null);

  return (
    <div className="ContenerConssomationEntreDeuxInventaire">
      <SelectInventaire
        RestaurantName={RestaurantName}
        InventaireSelectedStart={InventaireSelectedStart}
        setInventaireSelectedStart={setInventaireSelectedStart}
        InventaireSelectedEnd={InventaireSelectedEnd}
        setInventaireSelectedEnd={setInventaireSelectedEnd}
      />
      <div>
        Les inventaires doivent etre fait le matin avant la premiere commande et
        avant de rentrer en stock les commandes recus{" "}
      </div>
      {InventaireSelectedStart && InventaireSelectedEnd && (
        <DataConsomationEntreDeuxInventaire
          IDInventaireStart={InventaireSelectedStart}
          IDInventaireEnd={InventaireSelectedEnd}
          RestaurantName={RestaurantName}
        />
      )}
    </div>
  );
};

export default ConssomationEntreDeuxInventaire;
