import React, { useState } from "react";
import HeaderVisiteur from "../../components/VisiteurComponents/HeaderVisiteur/HeaderVisiteur";
import CenterPageVisitorV2 from "../../components/VisiteurComponents/CenterPageVisitorV2/CenterPageVisitorV2";
import BottomPageVisitorV2 from "../../components/VisiteurComponents/BottomPageVisitorV2/BottomPageVisitorV2";

const VisiteurV2PageNoRightSideBar = () => {
  const [ProductSelected, setProductSelected] = useState(null);
  const [PrepSelected, setPrepSelected] = useState(null);
  const [navRowDisplayProdandPrep, setNavRowDisplayProdandPrep] = useState(0);
  const [PrepSelectedForChangeMatiere, setPrepSelectedForChangeMatiere] =
    useState(null);

  return (
    <>
      <HeaderVisiteur />
      <CenterPageVisitorV2
        ProductSelected={ProductSelected}
        setProductSelected={setProductSelected}
        navRowDisplayProdandPrep={navRowDisplayProdandPrep}
        setNavRowDisplayProdandPrep={setNavRowDisplayProdandPrep}
        PrepSelectedForChangeMatiere={PrepSelectedForChangeMatiere}
        setPrepSelectedForChangeMatiere={setPrepSelectedForChangeMatiere}
      />
      <BottomPageVisitorV2
        ProductSelected={ProductSelected}
        navRowDisplayProdandPrep={navRowDisplayProdandPrep}
        PrepSelected={PrepSelected}
        PrepSelectedForChangeMatiere={PrepSelectedForChangeMatiere}
      />
    </>
  );
};

export default VisiteurV2PageNoRightSideBar;
