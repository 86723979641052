import React, { useEffect, useState } from "react";
import MainDashPreparationLogistics from "../../components/MainDash/MainDashPreparationLogistics/MainDashPreparationLogistics";
import RightSidePreparationLogistics from "../../components/RightSide/RightSidePreparationLogistics/RightSidePreparationLogistics";

const PreparationLogisticsOrders = () => {
  const [OrderSelected, setOrderSelected] = useState();

  return (
    <>
      <MainDashPreparationLogistics setOrderSelected={setOrderSelected} />
      <RightSidePreparationLogistics OrderSelected={OrderSelected} />
    </>
  );
};

export default PreparationLogisticsOrders;
