import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./UpdatesDEMO.css";

import { getFacturationDataDEMO } from "../../../Data/firebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import ModalOrderTable from "../../Modal/ModalOrderTable";
import { useRecoilValue } from "recoil";
import { selectOrderMode } from "../../../atoms/SelectOrderModeAtome";

const Updates = () => {
  const [data, setData] = useState({ ProduitAFacturer: ["Loading"] });
  const [user, loading, error] = useAuthState(auth);
  const SelectOrderMode = useRecoilValue(selectOrderMode);

  useEffect(() => {
    async function fetchData() {
      const date = [SelectOrderMode.dateSatart, SelectOrderMode.dateEnd];
      const data = await getFacturationDataDEMO(user.uid, date);
      setData(data);
    }
    fetchData();
  }, [SelectOrderMode]);
  return (
    <div className="Updates">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Facturation: {data.PrixAfacturer} €</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.ProduitAFacturer.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Updates;
