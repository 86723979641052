import React, { useEffect, useState } from "react";
import SelectionProdDuMenu from "../../SelectionProdDuMenu/SelectionProdDuMenu";
import DisplayMonMenu from "../../DisplayMonMenu/DisplayMonMenu";
import { CreateModuleDeCommande } from "../../../../Data/firebaseInteractionMarketing";

const Step1 = ({ SelectedDish, setSelectedDish, setStepCreationMenu }) => {
  return (
    <div className="MainDashMarketingPage">
      <SelectionProdDuMenu setSelectedDish={setSelectedDish} />

      <div className="rightSideMarketing">
        <DisplayMonMenu
          SelectedDish={SelectedDish}
          setSelectedDish={setSelectedDish}
        />

        <div
          style={{
            width: "80%",
            padding: "15px",
            border: "1px solid",
            borderRadius: "5px",
            marginTop: "10px",
            cursor: "pointer",
            transition: "background-color 0.3s", // Transition de couleur de fond
            backgroundColor: "transparent", // Couleur de fond par défaut
            textAlign: "center", // Centrer le texte
            fontWeight: "bold", // Texte en gras
            fontSize: "16px", // Taille de police légèrement plus grande
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "white")} // Changement de couleur de fond lorsque la souris passe dessus
          onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")} // Réinitialiser la couleur de fond lorsque la souris quitte la zone
          onClick={() => CreateModuleDeCommande(SelectedDish)}
        >
          Suivant
        </div>
      </div>
    </div>
  );
};

export default Step1;
