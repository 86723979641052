import React, { useState, useEffect } from "react";
import "./MainDashDEMO.css";
import CardsDEMO from "../../Cards/CardsDEMO/CardsDEMO";
import TableDEMO from "../../Table/TableDEMO/TableDEMO";
import { auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserInfo } from "../../../Data/firebaseGetUserInfo";
import SelectionDate from "../../SelectionDate/SelectionDate";

const MainDashDEMO = () => {
  const [user, loading, error] = useAuthState(auth);
  const [Brand, setBrand] = useState("Loading...");

  useEffect(() => {
    async function fetchUserBrand() {
      const Userdata = await getUserInfo(user.uid);
      setBrand(Userdata.OwnerBrand);
    }
    fetchUserBrand();
  }, [user]);

  return (
    <div className="MainDash">
      <h1>{Brand}</h1>
      {/*<SelectionDate/>*/}
      <CardsDEMO />
      <TableDEMO />
    </div>
  );
};

export default MainDashDEMO;
