import React from "react";
import "./RightSidePreparationLogistics.css";
import ListeRawMaterialForOrderPreparation from "../../Updates/ListeRawMaterialForOrderPreparation/ListeRawMaterialForOrderPreparation";

const RightSidePreparationLogistics = ({ OrderSelected }) => {
  return (
    <div className="RightSide">
      <div>
        <h3>Matiére premiere à sortir</h3>
        <ListeRawMaterialForOrderPreparation OrderSelected={OrderSelected} />
      </div>
      <div></div>
    </div>
  );
};

export default RightSidePreparationLogistics;
