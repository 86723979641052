import React, { useState } from "react";
import DisplayProductAvailable from "./DisplayProductAvailable/DisplayProductAvailable";
const AnalyseZelty = ({ RestaurantName }) => {
  return (
    <div>
      <DisplayProductAvailable RestaurantName={RestaurantName} />
    </div>
  );
};

export default AnalyseZelty;
