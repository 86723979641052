import React,{ useEffect, useState } from "react";
import './CardsWallet.css'
import Card from "../../Card/Card";

import {getCardsWalletDataNEWWALLET } from '../../../Data/firebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebaseConfig';
import { useRecoilValue } from 'recoil';
import { selectOrderMode } from '../../../atoms/SelectOrderModeAtome';

import DepositBankTransfer from'../../Button/DepositBankTransfer/DepositBankTransfer'

const CardsWallet=()=>{
    const [user, loading, error] = useAuthState(auth);
    const [CardsData, setCardsData] = useState([]);
    const SelectOrderMode = useRecoilValue(selectOrderMode); 
    
  useEffect(() => {
    async function fetchOrders() {
      const cardData = await getCardsWalletDataNEWWALLET(user.uid);
      setCardsData(cardData);
    }
    fetchOrders();
  }, [SelectOrderMode]);
    return(
        <div className="Cards">
            {CardsData.length === 0? (<h2>Loading...</h2>) : CardsData.map((card,id)=>{
                return(
                    <div className="parentContainer">
                        <Card
                        title = {card.title}
                        sousTitre = {card.sousTitre}
                        color = {card.color}
                        barValue = {card.barValue}
                        value={card.value}
                        png = {card.png}
                        series= {card.series}
                        categorie = {card.categorie}
                        />
                    </div>
                )
            })}
            <div className="parentContainer">
            <DepositBankTransfer/>
            </div>
        </div>
    )
}

export default CardsWallet