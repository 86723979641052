import React, { useState } from "react";
import "./AnnalyseGenerale.css";

const AnnalyseGenerale = () => {
  const [navRow, setNavRow] = useState(0);
  return (
    <div className="ContenerAnnalyseGenerale">
      <div>!</div>
      <div>MainData</div>
    </div>
  );
};

export default AnnalyseGenerale;
