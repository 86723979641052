import React, { useState, useEffect } from "react";
import "./SelectInventaire.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  GetConssomationMatiereEntreDeuxInventaires,
  getAllInventaire,
} from "../../../../../../../Data/firebaseInteractionOwnerV2";

const SelectInventaire = ({
  RestaurantName,
  InventaireSelectedStart,
  setInventaireSelectedStart,
  InventaireSelectedEnd,
  setInventaireSelectedEnd,
}) => {
  const [allInventaire, setAllInventaire] = useState(null);
  const [DataConssomationMatiere, setDataConssomationMatiere] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const DataInventaire = await getAllInventaire(RestaurantName);
      setAllInventaire(DataInventaire);
    };
    fetchData();
  }, [InventaireSelectedStart, InventaireSelectedEnd]);

  return (
    <div className="ContenerSelectInventaire">
      <FormControl sx={{ display: "flex", width: "100%" }}>
        <div className="ContenerSlector">
          <div style={{ width: "30%", display: "flex", alignItems: "center" }}>
            <Select
              labelId="select-StartInventaire"
              id="select-StartInventaire"
              value={InventaireSelectedStart}
              onChange={(e) => setInventaireSelectedStart(e.target.value)}
              label="Inventaire Début"
              sx={{ width: "100%" }}
            >
              {allInventaire &&
                allInventaire.map((Inventaire) => (
                  <MenuItem
                    key={Inventaire.IDPepers}
                    value={Inventaire.IDPepers}
                  >
                    {new Date(Inventaire.date.seconds * 1000).toString()}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Select
              labelId="select-EndInventaire"
              id="select-EndInventaire"
              value={InventaireSelectedEnd}
              onChange={(e) => setInventaireSelectedEnd(e.target.value)}
              label="Inventaire Fin"
              sx={{ width: "100%" }}
            >
              {allInventaire &&
                allInventaire.map((Inventaire) => (
                  <MenuItem
                    key={Inventaire.IDPepers}
                    value={Inventaire.IDPepers}
                  >
                    {new Date(Inventaire.date.seconds * 1000).toString()}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </FormControl>
    </div>
  );
};

export default SelectInventaire;
