import React, { useEffect } from "react";
import "./SelectionProdDuMenu.css";
import CategorieFood from "../CategorieFood/CategorieFood";
import DisplayProduct from "../DisplayProduct/DisplayProduct";
import SelectSousCategories from "../SelectSousCategories/SelectSousCategories";

const SelectionProdDuMenu = ({ setSelectedDish }) => {
  return (
    <div className="SelectionProdDuMenu">
      <div className="SectionCategorie">{/* <CategorieFood />*/}</div>
      <div></div>
      {/*<SelectSousCategories />*/}
      <DisplayProduct setSelectedDish={setSelectedDish} />
    </div>
  );
};

export default SelectionProdDuMenu;
