import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography ,
} from "@mui/material";
import "./ModalmodifyBrand.css";
import {AddNewCategorie} from '../../../Functions/HandleClickFunctions'


export default function ModalmodifyBrand({ open, handleClose }) {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
        <div style={{ fontSize: '35px'}}>Modifier une Marque</div>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={(e)=>{
                AddNewCategorie()
                handleClose()
                }}>
                Modifier
            </Button>
        </Typography>
      </Box>
    </Modal>
  );
}