import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TabledisplayMatierePremiereShouldBeAvailableInLogistics.css";

import {
  getMatierePremiereShouldBeAvailableInLogistics,
  getListOfPrepWhoUseThisMatierePremiere,
} from "../../../Data/firebaseService";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

export default function TabledisplayMatierePremiereShouldBeAvailableInLogistics() {
  const [AllActiveMatiere, setAllActiveMatiere] = useState(null);

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const ActiveMatiere =
        await getMatierePremiereShouldBeAvailableInLogistics();
      setAllActiveMatiere(ActiveMatiere);
    }
    fetchOrders();
  }, [Production]);

  return (
    <>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Matiere</TableCell>
                <TableCell>Fournisseur</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {AllActiveMatiere != null &&
                AllActiveMatiere.map((Matiere, index) => (
                  <TableRow
                    key={Matiere.Nom}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div
                        onClick={() =>
                          getListOfPrepWhoUseThisMatierePremiere(Matiere.Nom)
                        }
                      >
                        {Matiere.Nom}
                      </div>{" "}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {Matiere.Fournisseur}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
