import React, { useState, useEffect } from "react";
import "./DetailRefairePrep.css";
import SelectPrepRefaitForPrepID from "./SelectPrepRefaitForPrepID/SelectPrepRefaitForPrepID";
import DetailSelectedRefairePrep from "./DetailSelectedRefairePrep/DetailSelectedRefairePrep";

const DetailRefairePrep = ({ RestaurantName, PrepID }) => {
  const [SelectedRefaitPrep, setSelectedRefaitPrep] = useState(null);
  return (
    <div className="ContenerDetailRefairePrep">
      <SelectPrepRefaitForPrepID
        RestaurantName={RestaurantName}
        PrepID={PrepID}
        SelectedRefaitPrep={SelectedRefaitPrep}
        setSelectedRefaitPrep={setSelectedRefaitPrep}
      />
      {SelectedRefaitPrep && (
        <DetailSelectedRefairePrep
          SelectedRefaitPrep={SelectedRefaitPrep}
          RestaurantName={RestaurantName}
        />
      )}
    </div>
  );
};

export default DetailRefairePrep;
