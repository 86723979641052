import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";

import GetAvailableProductFromZeltyForProductWhoShoudBeInTheRestaurant from "../../../../../../components/OwnerV2Components/Button/GetAvailableProductFromZeltyForProductWhoShoudBeInTheRestaurant/GetAvailableProductFromZeltyForProductWhoShoudBeInTheRestaurant";
import GetAvailableProductFromZeltyForProductWhoShoudNotBeInTheRestaurant from "../../../../../../components/OwnerV2Components/Button/GetAvailableProductFromZeltyForProductWhoShoudNotBeInTheRestaurant/GetAvailableProductFromZeltyForProductWhoShoudNotBeInTheRestaurant";
const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Cancel") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

const DisplayProductAvailable = ({ RestaurantName }) => {
  const [data, setData] = useState(null);
  console.log(data);

  return (
    <div style={{ maxHeight: "70vh", overflow: "auto" }}>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Etat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {data ? (
              data.map((Product, index) => (
                <TableRow
                  key={`${Product.ZeltyID}${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {Product.Production.name}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Avatar
                      alt={Product.Production.name}
                      src={Product.Production.img}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <span
                      className="status"
                      style={
                        Product.InfoRuptureZelty.outofstock === false
                          ? makeStyle("Approved")
                          : makeStyle("Cancel")
                      }
                    >
                      {Product.InfoRuptureZelty.outofstock === false
                        ? "En Ligne"
                        : "Rupture"}
                    </span>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                key={"1"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <GetAvailableProductFromZeltyForProductWhoShoudBeInTheRestaurant
                    data={data}
                    setData={setData}
                    RestaurantName={RestaurantName}
                  />
                  <GetAvailableProductFromZeltyForProductWhoShoudNotBeInTheRestaurant
                    data={data}
                    setData={setData}
                    RestaurantName={RestaurantName}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DisplayProductAvailable;
