import React from "react";
import CreationProduct from "../../View/Marketing/Page1";

import { sideBar } from "../../atoms/SideBarAtome";
import Page1 from "../../View/Marketing/Page1";

const Marketing = () => {
  return (
    <>
      {/*<Sidebar data={SidebarDataOwner} />*/}
      {0 === 0 && <Page1 />}
    </>
  );
};

export default Marketing;
