import React, { useState, useEffect } from "react";
import "./App.css";
import { RecoilRoot } from "recoil";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseConfig";
import ClientPepers from "./UserMode/ClientPepers/ClientPepers";
import Cuisinier from "./UserMode/Cuisinier/Cuisinier";
import { getUserInfo } from "./Data/firebaseGetUserInfo";
import Authentification from "./components/Authentification/Authentification";
import Owner from "./UserMode/Owner/Owner";
import Manager from "./UserMode/Manager/Manager";
import Visiteur from "./UserMode/Visiteur/Visiteur";
import PreparationLogisticsOrders from "./UserMode/PreparationLogistic/PreparationLogistic";
import ClientPepersDEMO from "./UserMode/ClientPepersDEMO/ClientPepersDEMO";
import ConceptionProduit from "./UserMode/ConceptionProduit/ConceptionProduit";
import Marketing from "./UserMode/Marketing/Marketing";
import OwnerV2 from "./UserMode/OwnerV2/OwnerV2";
import CuisinierV2 from "./UserMode/CuisinierV2/CuisinierV2";
import ModuleDeCommande from "./components/ModuleDeCommande/ModuleDeCommande";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState(undefined);

  useEffect(() => {
    async function fetchOrders() {
      const userInfo = await getUserInfo(user.uid);
      setUserInfo(userInfo);
    }
    fetchOrders();
  }, [user]);

  return (
    <RecoilRoot>
      {user && userInfo && userInfo.Role !== "Marketing" ? (
        <div
          className={
            user && userInfo && userInfo.Role === "Visiteur"
              ? "AppVisiteur"
              : "App"
          }
        >
          <div
            className={
              (user && userInfo && userInfo.Role === "Cuisinier") ||
              (user && userInfo && userInfo.Role === "CuisinierV2")
                ? "AppGlassCuisinierOrManager"
                : "AppGlass" && user && userInfo && userInfo.Role === "Visiteur"
                ? "AppGlassVisiteur"
                : "AppGlass" && user && userInfo && userInfo.Role === "OwnerV2"
                ? "AppGlassOwnerV2"
                : "AppGlass"
            }
          >
            {user ? (
              <></>
            ) : (
              <>
                <Authentification />
              </>
            )}
            {user && userInfo && userInfo.Role === "Client" && <ClientPepers />}
            {user && userInfo && userInfo.Role === "Cuisinier" && <Cuisinier />}
            {user && userInfo && userInfo.Role === "CuisinierV2" && (
              <CuisinierV2 RestaurantName={"RestaurantSainteFoy"} />
            )}
            {user && userInfo && userInfo.Role === "Owner" && <Owner />}
            {user && userInfo && userInfo.Role === "Manager" && <Manager />}
            {user && userInfo && userInfo.Role === "Visiteur" && <Visiteur />}
            {user && userInfo && userInfo.Role === "OwnerV2" && <OwnerV2 />}
            {user && userInfo && userInfo.Role === "PreparationLogistic" && (
              <PreparationLogisticsOrders />
            )}
            {user && userInfo && userInfo.Role === "Demo" && (
              <ClientPepersDEMO />
            )}
            {user && userInfo && userInfo.Role === "ConceptionProduit" && (
              <ConceptionProduit />
            )}
          </div>
        </div>
      ) : (
        <div
          className={
            user && userInfo && userInfo.Role === "Marketing"
              ? "AppMarketing"
              : "App" && !user && "none"
          }
        >
          <div
            className={
              user && userInfo && userInfo.Role === "Marketing"
                ? "AppGlassMarketing"
                : "AppGlass" && !user && "none"
            }
          >
            {user ? (
              <></>
            ) : (
              <>
                <Router>
                  <Routes>
                    <Route path="/" element={<Authentification />} />
                    <Route
                      path="/ModuleCommande/:ModuleID"
                      element={<ModuleDeCommande />}
                    />
                  </Routes>
                </Router>
              </>
            )}
            {user && userInfo && userInfo.Role === "Marketing" && <Marketing />}
          </div>
        </div>
      )}
    </RecoilRoot>
  );
}

export default App;
