import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableDEMO.css";

import { getOrdersFromUserBrandDEMO } from "../../../Data/firebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import ModalOrderTable from "../../Modal/ModalOrderTable";

import { useRecoilState } from "recoil";
import { selectOrderMode } from "../../../atoms/SelectOrderModeAtome";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Cancel") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function TableDEMO() {
  const [user, loading, error] = useAuthState(auth);
  const [orders, setOrders] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [DateRange, setDateRange] = useRecoilState(selectOrderMode);

  useEffect(() => {
    async function fetchOrders() {
      const date = [DateRange.dateSatart, DateRange.dateEnd];
      const orders = await getOrdersFromUserBrandDEMO(user.uid, date);
      setOrders(orders);
    }
    fetchOrders();
  }, [DateRange]);

  const handleOpen = (order) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setOpen(false);
  };

  return (
    <>
      <h3>Commandes</h3>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell align="left">Tracking ID</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {orders != null &&
                orders.map((order) => (
                  <TableRow
                    key={order.dataOrder.order.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {order.dataOrder.order.channel}
                    </TableCell>
                    <TableCell align="left">
                      {order.dataOrder.order.id}
                    </TableCell>
                    <TableCell align="left">
                      {order.dataOrder.order.created_at}
                    </TableCell>
                    <TableCell align="left">
                      <span
                        className="status"
                        style={
                          order.status !== "cancelled"
                            ? makeStyle("Approved")
                            : makeStyle("Cancel")
                        }
                      >
                        {order.status !== "cancelled" ? "Approved" : "Cancel"}
                      </span>
                    </TableCell>
                    <TableCell
                      onClick={() => handleOpen(order)}
                      align="left"
                      className="Details"
                    >
                      Details
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedOrder && (
          <ModalOrderTable
            open={open}
            handleClose={handleClose}
            order={selectedOrder}
          />
        )}
      </div>
    </>
  );
}
