import React, { useState, useEffect } from "react";
import "./MainDash.css";
import Cards from "../Cards/Cards";
import Table from "../Table/Table";
import { auth } from "../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserInfo } from "../../Data/firebaseGetUserInfo";
import SelectionDate from "../SelectionDate/SelectionDate";

const MainDash = () => {
  const [user, loading, error] = useAuthState(auth);
  const [Brand, setBrand] = useState("Loading...");

  useEffect(() => {
    async function fetchUserBrand() {
      const Userdata = await getUserInfo(user.uid);
      setBrand(Userdata.OwnerBrand);
    }
    fetchUserBrand();
  }, [user]);

  return (
    <div className="MainDash">
      <h1>{Brand}</h1>
      {/*<SelectionDate/>*/}
      <Cards />
      <Table />
    </div>
  );
};

export default MainDash;
