import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import DashboardDEMO from "../../View/ClientPepersDEMO/DashboardDEMO";
import { SidebarData } from "../../Data/Data";
import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";
import Wallet from "../../View/ClientPepers/Wallet";
import ProductSimulation from "../../View/ClientPepers/ProductSimulation";
import Pricing from "../../View/ClientPepers/Pricing";

const ClientPepersDEMO = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <>
      <Sidebar data={SidebarData} />
      {SideBarAtom.view === 0 && <DashboardDEMO />}
      {SideBarAtom.view === 1 && <Wallet />}
      {SideBarAtom.view === 2 && <Pricing />}
      {/*SideBarAtom.view === 3 && <ProductSimulation />*/}
    </>
  );
};

export default ClientPepersDEMO;
