import React, { useState, useEffect } from "react";
import "./ActiveIngredient.css";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../firebaseConfig";

import { getActiveIngredient } from "../../../../../Data/firebaseInteractionOwnerV2";
import Avatar from "@mui/material/Avatar";
import { Button, CircularProgress } from "@mui/material";

const ActiveIngredient = ({ RestaurantName }) => {
  const [ActiveIngredientInrestaurant, setActiveIngredientInrestaurant] =
    useState(null);

  const [dishes] = useCollection(
    collection(getFirestore(app), "RestaurantSainteFoy"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const ActivePrep = await getActiveIngredient(RestaurantName);
      setActiveIngredientInrestaurant(ActivePrep);
    }
    fetchOrders();
  }, [dishes]);

  return (
    <div>
      {ActiveIngredientInrestaurant ? (
        ActiveIngredientInrestaurant.map((Ingredient) => (
          <div className="RowIngredientInResto">
            {Ingredient.name}
            <Avatar alt={Ingredient.name} src={Ingredient.Img} />{" "}
          </div>
        ))
      ) : (
        <CircularProgress size={24} color="secondary" />
      )}
    </div>
  );
};

export default ActiveIngredient;
