import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import "./SearchBarPreparationSetListePreparations.css";
import { getPreparationSearchBarData } from "../../../Data/firebaseService";

const SearchBarPreparationSetListePreparations = ({ setListePreparation }) => {
  const [input, setInput] = useState("");
  const [result, setResult] = useState([]);

  const fetchData = (value) => {
    getPreparationSearchBarData().then((json) => {
      const results = json.filter((prep) => {
        return value && prep.Nom && prep.Nom.toLowerCase().includes(value);
      });
      setResult(results);
    });
  };
  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="search-bar-container">
      <div className="input-wrapper">
        <FaSearch id="search-icon" />
        <input
          placeholder="Preparation"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="result-list">
        {result.map((preparation, id) => {
          return (
            <div
              className="search-result"
              key={id}
              onClick={() => {
                setListePreparation((oldpreparation) => [
                  ...oldpreparation,
                  { DataPreparation: { ...preparation }, Qte: 0 },
                ]);
                setInput("");
                setResult([]);
              }}
            >
              {preparation.Nom}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchBarPreparationSetListePreparations;
