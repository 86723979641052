import React, { useState, useEffect } from "react";
import "./DisplayInventaire.css";
import SelectOnlyOneInventaires from "./SelectOnlyOneInventaires/SelectOnlyOneInventaires";
import DisplayInventaireIngredient from "./DisplayInventaireIngredient/DisplayInventaireIngredient";
import DisplayInventairePrep from "./DisplayInventairePrep/DisplayInventairePrep";
import { getIngredientAndPrepObjectUsedInInventaire } from "../../../../../../Data/firebaseInteractionOwnerV2";
import FaireInventaire from "../../../../Button/FaireInventaire/FaireInventaire";

const DisplayInventaire = ({ RestaurantName }) => {
  const [InventaireSelected, setInventaireSelected] = useState(null);

  const [DataIngredientAndPrep, setDataIngredientAndPrep] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const DataIngredient = await getIngredientAndPrepObjectUsedInInventaire(
        RestaurantName,
        InventaireSelected
      );
      setDataIngredientAndPrep(DataIngredient);
    };
    InventaireSelected && fetchData();
  }, [InventaireSelected]);

  return (
    <>
      <div className="ContenerDisplayInventaire">
        <div style={{ minWidth: "150px" }}>
          <SelectOnlyOneInventaires
            RestaurantName={RestaurantName}
            InventaireSelected={InventaireSelected}
            setInventaireSelected={setInventaireSelected}
          />
          <div>
            <FaireInventaire RestaurantName={RestaurantName} />
          </div>
        </div>
        {DataIngredientAndPrep && (
          <>
            <DisplayInventaireIngredient
              DataIngredient={DataIngredientAndPrep.ListeIngredientObject}
            />
            <DisplayInventairePrep
              DataPrep={DataIngredientAndPrep.ListePrepObject}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DisplayInventaire;
