import React, { useState, useEffect } from "react";
import "./MatiereOrderDisplayDetail.css";
import { GetMatiereOrderDetail } from "../../../../../../../Data/firebaseInteractionOwnerV2";
import { Avatar } from "@mui/material";

const MatiereOrderDisplayDetail = ({ IDMatiereOrder, RestaurantName }) => {
  const [MatiereOrderDetail, setMatiereOrderDetail] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const Data = await GetMatiereOrderDetail(RestaurantName, IDMatiereOrder);
      setMatiereOrderDetail(Data);
    }

    fetchData();
  }, [IDMatiereOrder]);
  console.log(MatiereOrderDetail);

  return (
    <div className="">
      {MatiereOrderDetail &&
        MatiereOrderDetail.map((matiere) => (
          <div className="RowDisplayMatiereOrderMatiere">
            <div>{matiere.NomChezFournisseur}</div>
            <div>Qte Order : {matiere.QteOrder}</div>
            <div>
              {matiere.hasOwnProperty("QteRecu") && (
                <>Qte Recu :{matiere.QteRecu}</>
              )}
            </div>
            <div>{matiere.Conditionnement}</div>
            <div>{matiere.PrixHT} € HT</div>
            {matiere.hasOwnProperty("img") && <Avatar src={matiere.img} />}
          </div>
        ))}
    </div>
  );
};

export default MatiereOrderDisplayDetail;
