import React, { useState, useEffect } from "react";
import "./SelectOnlyOneInventaires.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAllInventaire } from "../../../../../../../Data/firebaseInteractionOwnerV2";

const SelectOnlyOneInventaires = ({
  RestaurantName,
  InventaireSelected,
  setInventaireSelected,
}) => {
  const [allInventaire, setAllInventaire] = useState(null);
  const [DataConssomationMatiere, setDataConssomationMatiere] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setAllInventaire(null);
      const DataInventaire = await getAllInventaire(RestaurantName);
      setAllInventaire(DataInventaire);
    };
    fetchData();
  }, [InventaireSelected]);

  return (
    <FormControl style={{ maxWidth: "100%" }}>
      <Select
        labelId="select-StartInventaire"
        id="select-StartInventaire"
        value={InventaireSelected}
        onChange={(e) => setInventaireSelected(e.target.value)}
        label="Inventaire Début"
        sx={{ maxWidth: "100%" }}
      >
        {allInventaire &&
          allInventaire.map((Inventaire) => (
            <MenuItem key={Inventaire.IDPepers} value={Inventaire.IDPepers}>
              {new Date(Inventaire.date.seconds * 1000).toString()}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectOnlyOneInventaires;
