import React, { useState } from "react";
import "./CenterAddOption.css";
import InfoOption from "./InfoOption/InfoOption";
import AddOptionChoice from "./AddOptionChoice/AddOptionChoice";

const CenterAddOption = ({
  InfoOptionData,
  setInfoOptionData,
  OptionChoice,
  setOptionChoice,
  selectedOptionValue,
  setSelectedOptionValue,
}) => {
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          overflowY: "auto",
        }}
      >
        <InfoOption
          InfoOptionData={InfoOptionData}
          setInfoOptionData={setInfoOptionData}
        />
        <AddOptionChoice
          OptionChoice={OptionChoice}
          setOptionChoice={setOptionChoice}
          selectedOptionValue={selectedOptionValue}
          setSelectedOptionValue={setSelectedOptionValue}
        />
      </div>
    </>
  );
};

export default CenterAddOption;
