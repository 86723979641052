import React, { useState, useEffect } from "react";
import TabledisplayMatierePremiereShouldBeAvailableInLogistics from "../../components/Table/TabledisplayMatierePremiereShouldBeAvailableInLogistics/TabledisplayMatierePremiereShouldBeAvailableInLogistics";

const Preparation = () => {
  useEffect(() => {}, []);

  return (
    <>
      <div>
        <TabledisplayMatierePremiereShouldBeAvailableInLogistics />
      </div>
    </>
  );
};

export default Preparation;
