import React, { useEffect, useState } from "react";
import AlocateDishToPlanDeTravail from "../../components/CuisinierV2Components/AlocateDishToPlanDeTravail/AlocateDishToPlanDeTravail";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Registration from "../../components/Authentification/Authentification";
import FichetechniqueView from "../../View/CuisinierV2/FichetechniqueView";

import { getAllPlanDeTravailData } from "../../Data/firebaseInteractionCuisinierV2";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../firebaseConfig";

const CuisinierV2 = ({ RestaurantName }) => {
  const [planDeTravail, setPlanDeTravail] = useState(null);
  const [RestoWorkplace] = useCollection(
    collection(getFirestore(app), `${RestaurantName}PlanDeTravail`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  useEffect(() => {
    async function fetchOrders() {
      const Data = await getAllPlanDeTravailData(RestaurantName);
      setPlanDeTravail(Data);
    }
    fetchOrders();
  }, [RestoWorkplace]);

  return (
    <Router>
      <Routes>
        {" "}
        <Route path="/" element={<Registration />} />{" "}
        {planDeTravail &&
          planDeTravail.map((plan) => (
            <>
              <Route
                path={`/in-production-${plan.info.name}`}
                element={
                  <AlocateDishToPlanDeTravail
                    Workplace={plan.info.IDPepers}
                    RestaurantName={RestaurantName}
                  />
                }
              />{" "}
              <Route
                path={`/fiche-technique-${plan.info.name}`}
                element={
                  <FichetechniqueView
                    WorkplaceID={plan.info.IDPepers}
                    RestaurantName={RestaurantName}
                  />
                }
              />
            </>
          ))}
      </Routes>{" "}
    </Router>
  );
};

export default CuisinierV2;
