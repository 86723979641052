import React, { useState } from "react";
import RigthSideOwnerUserData from "../../components/RightSide/RigthSideOwnerUserData/RigthSideOwnerUserData";
import MainDashOwnerUserData from "../../components/MainDash/MainDashOwnerUserData/MainDashOwnerUserData";

const UserData = () => {
  const [SelectedBrand, setSelectedBrand] = useState("");
  const [WalletData, setWalletData] = useState(null);

  return (
    <>
      <MainDashOwnerUserData
        WalletData={WalletData}
        setWalletData={setWalletData}
        SelectedBrand={SelectedBrand}
      />
      <RigthSideOwnerUserData
        SelectedBrand={SelectedBrand}
        setSelectedBrand={setSelectedBrand}
        setWalletData={setWalletData}
      />
    </>
  );
};

export default UserData;
