import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, CircularProgress } from "@material-ui/core";

import "./ModalDisplayData.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "90%",
    maxHeight: "90vh",
    overflowY: "auto",
    border: "2px solid #000",
    borderRadius: "5px",
  },
  formContainer: {
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "5px",
  },
}));

const ModalDisplayData = ({
  open,
  handleClose,
  DataToDisplay,
  TitleModal,
  SousParagraphe,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className={classes.paper}>
          <h2 id="modal-title">{TitleModal}</h2>
          {SousParagraphe}
          <div
            className="Form"
            style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            {DataToDisplay}
          </div>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: "#283747" }}
            onClick={() => {
              handleClose();
            }}
          >
            Fermer
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalDisplayData;
