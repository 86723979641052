import React, { useState, useEffect } from "react";
import "./DisplayDishDetail.css";
import RelierDishToZeltyID from "../../Button/RelierDishToZeltyID/RelierDishToZeltyID";
import { getZeltyDishWithIDZelty } from "../../../../Data/firebaseInteractionOwnerV2";
import AddOptionToPepersDish from "../../Button/AddOptionToPepersDish/AddOptionToPepersDish";
import ModifictaionDish from "../../Button/ModifictaionDish/ModifictaionDish";
import ModifierCategorieAllDish from "../../Button/ModifierCategorieAllDish/ModifierCategorieAllDish";

const DisplayDishDetail = ({ SelectedDish }) => {
  const [ZeltyDishData, setZeltyDishData] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      const ZeltyDish = await getZeltyDishWithIDZelty(SelectedDish.ZeltyID);
      setZeltyDishData(ZeltyDish);
    }
    fetchOrders();
  }, [SelectedDish]);
  return (
    <div className="ContenerDisplayDishDetail">
      <div>{SelectedDish.Production.name}</div>
      <div>{SelectedDish.Production.IDPepers}</div>
      <div
        style={{
          backgroundImage: `url(${SelectedDish.Production.img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px", // Spécifiez la hauteur de votre div ici
        }}
      ></div>

      <div>PRIX DE VENTE : {SelectedDish.Production.SellingPrice}</div>
      {SelectedDish.hasOwnProperty("ZeltyID") ? (
        <div>
          <div>ZeltyID: {SelectedDish.ZeltyID}</div>
          <div>{ZeltyDishData && ZeltyDishData.name}</div>
        </div>
      ) : (
        <div className="Red">
          Attention ce produit n'est pas relié a un produit zelty
        </div>
      )}
      <RelierDishToZeltyID Dish={SelectedDish} />
      <AddOptionToPepersDish Dish={SelectedDish} />
      <ModifictaionDish IDDish={SelectedDish.Production.IDPepers} />
      <ModifierCategorieAllDish />
    </div>
  );
};

export default DisplayDishDetail;
