import React, { useState, useEffect } from "react";
import "./ChangeDefaultRefMatiereForIngredient.css";
import { ChangeDefaultRefMatiere } from "../../../../Data/firebaseInteractionOwnerV2";

const ChangeDefaultRefMatiereForIngredient = ({
  IngredientObject,
  RefMatiereObject,
}) => {
  const [isProcessing, setIsprocessing] = useState(false);

  const HandleInteract = async () => {
    if (isProcessing) return;
    setIsprocessing(true);
    await ChangeDefaultRefMatiere(IngredientObject, RefMatiereObject);
    setIsprocessing(false);
  };
  return (
    <div
      onClick={() => HandleInteract()}
      className="ButtonChangeDefaultRefMatiereForIngredient"
    >
      CHANGE MATIERE PAR DEFAULT
    </div>
  );
};

export default ChangeDefaultRefMatiereForIngredient;
