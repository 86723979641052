import React, { useState, useEffect } from "react";
import AddMachineToPlanDeTravail from "../../../../../../Button/AddMachineToPlanDeTravail/AddMachineToPlanDeTravail";
import { SupprimerMachineInPlanDeTravail } from "../../../../../../../../Data/firebaseInteractionOwnerV2";

const DisplayPlanDeTravailMachine = ({
  DataMachine,
  RestaurantName,
  PlanDeTravailID,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const HandleSupprimerMachineInPlanDeTravail = async (
    machine,
    PlanDeTravailID,
    RestaurantName
  ) => {
    setIsProcessing(true);
    if (isProcessing) {
      return;
    }
    await SupprimerMachineInPlanDeTravail(
      machine,
      PlanDeTravailID,
      RestaurantName
    );
    setIsProcessing(false);
  };

  return (
    <div style={{ height: "280px", overflowY: "auto" }}>
      {DataMachine.map((machine) => {
        return (
          <div
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "yellow",
              margin: "5px",
            }}
          >
            {machine.name}{" "}
            <span
              className="Red"
              onClick={() =>
                HandleSupprimerMachineInPlanDeTravail(
                  machine,
                  PlanDeTravailID,
                  RestaurantName
                )
              }
            >
              Supprimer
            </span>
          </div>
        );
      })}
      <AddMachineToPlanDeTravail
        RestaurantName={RestaurantName}
        PlanDeTravailID={PlanDeTravailID}
      />
    </div>
  );
};

export default DisplayPlanDeTravailMachine;
