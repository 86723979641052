import React, { useState } from "react";
import NavBarHorisontale from "../../../../NavBar/NavBarHorisontale/NavBarHorisontale";
import ParamettrePlanDeTravail from "./ParamettrePlanDeTravail/ParamettrePlanDeTravail";

const ModePlanDeTravail = ({ RestaurantName }) => {
  const [navRow, setNavRow] = useState(0);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavBarHorisontale
        Data={["Paramettre plan de travail", "DATA PLAN DE TRAVAIL"]}
        setNavRow={setNavRow}
        navRow={navRow}
      />
      {navRow === 0 && (
        <>
          <ParamettrePlanDeTravail RestaurantName={RestaurantName} />
        </>
      )}
      {navRow === 1 && (
        <>
          <div>DATA PLAN DE TRAVAIL</div>
        </>
      )}
    </div>
  );
};

export default ModePlanDeTravail;
