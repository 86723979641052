import React, { useState, useEffect } from "react";
import "./DisplayInventaireIngredient.css";
import { getIngredientObjectUsedInInventaire } from "../../../../../../../Data/firebaseInteractionOwnerV2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

const DisplayInventaireIngredient = ({ DataIngredient }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "300px", boxShadow: "0px 13px 20px 0px #80808029" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nom Ingredient</TableCell>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Qte inv</TableCell>
            <TableCell align="left">Unite</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ color: "white" }}>
          {DataIngredient.map((Ingredient) => (
            <TableRow
              key={Ingredient.IDPepers}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {Ingredient.name}
              </TableCell>
              <TableCell align="left">
                {" "}
                <Avatar alt={Ingredient.name} src={Ingredient.Img} />
              </TableCell>
              <TableCell align="left">{Ingredient.QteInventaire}</TableCell>
              <TableCell align="left">{Ingredient.UniteDeRecette}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DisplayInventaireIngredient;
