import React, { useEffect, useState} from "react";
import './MainDashWallet.css'
import TableWallet from "../../TableWallet/TableWallet";
import {getUserInfo} from '../../../Data/firebaseGetUserInfo'
import {auth } from '../../../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import CardsWallet from '../../Cards/CardsWallet/CardsWallet'

const MainDashWallet = () => {
    const [user, loading, error] = useAuthState(auth);
    const [Brand, setBrand] = useState("Loading...");

    useEffect(() => {
      async function fetchUserBrand() {
        const Userdata = await getUserInfo(user.uid)
        setBrand(Userdata.OwnerBrand);
      }
      fetchUserBrand()
    }, [user]);

    return (
      <div className="MainDash">
        <h1>Wallet</h1>
        <CardsWallet/>
        <TableWallet />
      </div>
    );
  };
  

export default MainDashWallet