import React, { useState, useEffect } from "react";
import "./ModifictaionDish.css";
import DisplayDishDetail from "../DisplayDishDetail/DisplayDishDetail";
import DisplayPrepDetailUsedInDish from "../DisplayPrepDetailUsedInDish/DisplayPrepDetailUsedInDish";

const ModifictaionDish = ({ SelectedDish }) => {
  return (
    <div className="ContenerModificationDish">
      <DisplayDishDetail SelectedDish={SelectedDish} />
      <DisplayPrepDetailUsedInDish SelectedDish={SelectedDish} />
    </div>
  );
};

export default ModifictaionDish;
