import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import { HandleRefairePrep } from "../../../Functions/HandleClickFunctions";
import ButtonRefairePrep from "../../Button/ButtonRefairePrep/ButtonRefairePrep";

const ModalRefairePrep = ({ open, handleClose, Prep }) => {
  const [AllMatiereInPrep, setAllMatiereInPrep] = useState(
    Prep.MatierePremiereUtilisees
  );
  const [QtePrePFaite, setQtePrePFaite] = useState(0);

  const HandleRefairePrepButton = (Prep) => {
    HandleRefairePrep(Prep);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {Prep.Nom}
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {Prep.Categorie}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <img
            className="img-modal-Preparation"
            src={Prep.PhotoFirebase}
            alt="Nom de l'image"
          />
        </Typography>
        {Prep.hasOwnProperty("Recette") && (
          <div>
            {Prep.Recette}
            <br />
          </div>
        )}
        --- Les matieres ---
        {Prep.MatierePremiereUtilisees.map((Matiere) => (
          <>
            <div>{Matiere.Name}</div>
            <div>
              {Matiere.Qte} {Matiere.UniteDeMesure}
            </div>
          </>
        ))}
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Qte final pour la recette : {Prep.Quantite} {Prep.Unite}
        </Typography>
        {AllMatiereInPrep.map((Matiere, index) => (
          <>
            <div>{Matiere.Name}</div>
            <div>
              <TextField
                label="Nom de la marque"
                variant="outlined"
                value={Matiere.Qte}
                onChange={(e) =>
                  setAllMatiereInPrep((oldMatiereData) => {
                    const inputValue = e.target.value;
                    // Vérifier si l'entrée est un nombre
                    const isNumber = !isNaN(inputValue);

                    let newMatiereData = [...oldMatiereData];
                    if (isNumber) {
                      newMatiereData = [
                        ...oldMatiereData.slice(0, index),
                        { ...oldMatiereData[index], Qte: inputValue },
                        ...oldMatiereData.slice(index + 1),
                      ];
                    }

                    return newMatiereData;
                  })
                }
              />
              {Matiere.UniteDeMesure}
            </div>
          </>
        ))}
        <div>
          <br />
          <TextField
            label="QTE FINALE DE LA PREP"
            variant="outlined"
            value={QtePrePFaite}
            onChange={(e) =>
              setQtePrePFaite(() => {
                const inputValue = e.target.value;
                // Vérifier si l'entrée est un nombre
                const isNumber = !isNaN(inputValue);

                let newQte = 0;
                if (isNumber) {
                  newQte = e.target.value;
                }

                return newQte;
              })
            }
          />
          {Prep.Unite}
        </div>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <ButtonRefairePrep
            Prep={{
              ...Prep,
              MatierePremiereUtilisees: AllMatiereInPrep,
              Quantite: QtePrePFaite,
            }}
            handleClose={handleClose}
          />
        </Typography>
      </Box>
    </Modal>
  );
};

export default ModalRefairePrep;
