import React, { useEffect, useState } from "react";
import {
  getQteMatiereRecuBetween2Dates,
  getFournisseurSearchBarData,
  getAllOrderMatiereInRestauarantWithAddedData,
} from "../../../../Data/firebaseService";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ModaldisplayDetailMatiereOrder from "../../../Modal/ModaldisplayDetailMatiereOrder/ModaldisplayDetailMatiereOrder";

import "./CommandeMatierePremierePasse.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const CommandeMatierePremierePasse = ({ startDate, endDate }) => {
  const [AllFournisseur, setAllFournisseur] = useState(null);
  const [Fournisseur, setFournisseur] = useState("ALL");
  const [AllOrderMatiere, setOrderMatiere] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [CoutPourVBBAllOrder, setCoutPourVBBAllOrder] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let eDate = endDate !== "" ? new Date(endDate) : new Date();
      eDate.setHours(23);
      eDate.setMinutes(59);
      let sDate = new Date(startDate);
      sDate.setHours(0);
      sDate.setMinutes(1);

      let Fournisseurs = await getFournisseurSearchBarData();
      setAllFournisseur([...Fournisseurs, { Nom: "ALL" }]);

      let AllOrderMatiere =
        await getAllOrderMatiereInRestauarantWithAddedData();

      let CoutTotalAllOrder = 0;
      AllOrderMatiere.forEach((order) => {
        let CoutTotal = 0;
        order.DataOrder.forEach((matiere) => {
          CoutTotal =
            CoutTotal +
            matiere.Qte *
              parseFloat(matiere.DataMatiere["Prix HT"].replace(",", "."));
        });
        CoutTotalAllOrder = CoutTotalAllOrder + CoutTotal;
      });
      setOrderMatiere(AllOrderMatiere);
      setCoutPourVBBAllOrder(CoutTotalAllOrder);
    };
    fetchData();
  }, [startDate, endDate]);

  const [openModaldisplayMatiereOrder, setOpenModaldisplayMatiereOrder] =
    useState(false);
  const [SelectedOrderMatiere, setSelectedOrderMatiere] = useState(null);

  const handleCloseModaldisplayMatiereOrder = () => {
    setOpenModaldisplayMatiereOrder(false);
    setSelectedOrderMatiere(null);
  };

  const handleOpenModaldisplayMatiereOrder = (MatiereOrder) => {
    let CoutTotal = 0;
    MatiereOrder.DataOrder.forEach((matiere) => {
      CoutTotal =
        CoutTotal +
        matiere.Qte *
          parseFloat(matiere.DataMatiere["Prix HT"].replace(",", "."));
    });
    setOpenModaldisplayMatiereOrder(true);
    setSelectedOrderMatiere({ ...MatiereOrder, CoutTotal });
  };

  console.log(AllOrderMatiere);

  return (
    <>
      <div>COUT TOTAL MATIERE PREMIERE COMMANDÉ : {CoutPourVBBAllOrder}</div>
      <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
        <InputLabel id="select-unite-Preparation">
          Selection fournisseur
        </InputLabel>
        <Select
          labelId="select-Fournisseeur"
          id="select-Fournisseeur"
          value={Fournisseur}
          onChange={(e) => setFournisseur(e.target.value)}
        >
          {AllFournisseur != null &&
            AllFournisseur.map((fournisseur) => (
              <MenuItem key={fournisseur.Nom} value={fournisseur.Nom}>
                {fournisseur.Nom}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <div className="title">ORDER PAR FOURNISSEUR DEPUIS LE DEBUT</div>
      <div>
        {AllOrderMatiere &&
          AllOrderMatiere.map((MatiereOrder) => {
            let ProdCommandeDansOrderPerFournisseur =
              MatiereOrder.DataOrder.filter(
                (Matiere) => Matiere.DataMatiere.Fournisseur === Fournisseur
              );
            return (
              ProdCommandeDansOrderPerFournisseur.length !== 0 && (
                <div
                  onClick={() =>
                    setSelectedOrder(ProdCommandeDansOrderPerFournisseur)
                  }
                >
                  {
                    ProdCommandeDansOrderPerFournisseur[0].DataMatiere
                      .Fournisseur
                  }
                  {new Date(
                    MatiereOrder.date.seconds * 1000
                  ).toLocaleDateString()}
                </div>
              )
            );
          })}
      </div>
      ALL ORDERS
      <div className="AllOrderDisplay">
        {AllOrderMatiere &&
          AllOrderMatiere.map((MatiereOrder) => {
            return (
              <div
                className="CommandeArea"
                onClick={() => handleOpenModaldisplayMatiereOrder(MatiereOrder)}
              >
                <div>
                  Commande passee le :{" "}
                  {new Date(
                    MatiereOrder.date.seconds * 1000
                  ).toLocaleDateString()}
                </div>

                {MatiereOrder.ListeFournisseurOrdered.map((fournisseur) => (
                  <div>
                    {fournisseur.fournisseurName}
                    ------ {fournisseur.EtatCommande}
                  </div>
                ))}
              </div>
            );
          })}
      </div>
      {SelectedOrderMatiere && (
        <ModaldisplayDetailMatiereOrder
          open={openModaldisplayMatiereOrder}
          handleClose={handleCloseModaldisplayMatiereOrder}
          DataOrderMatiere={SelectedOrderMatiere}
        />
      )}
    </>
  );
};
export default CommandeMatierePremierePasse;
