import React from "react";
import "./RightSideOwnerKitchenSettings.css";
import UpdatesOwnerKitchen from "../../../components/Updates/UpdatesOwnerKitchen/UpdatesOwnerKitchen";
import UpdatesProductGroup from "../../../components/Updates/UpdatesProductGroup/UpdatesProductGroup";

const RightSideOwnerKitchenSettings = () => {
  return (
    <div className="RightSide">
      <div>
        <h3>26 rue sainte foy</h3>
        <UpdatesOwnerKitchen />
      </div>
      <div>
        <h3>Creer un groupe de produit</h3>
        <UpdatesProductGroup />
      </div>
    </div>
  );
};

export default RightSideOwnerKitchenSettings;
