import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableWallet.css";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../firebaseConfig";

import { getUserBankTransaction } from "../../Data/firebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import ModalViewDetailBankTransaction from "../../components/Modal/ModalViewDetailBankTransaction/ModalViewDetailBankTransaction";

import { useRecoilState } from "recoil";
import { selectOrderMode } from "../../atoms/SelectOrderModeAtome";
import { CancelAskOfDepositBankTransfer } from "../../Functions/HandleClickFunctions";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Cancel") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else if (status === "Pending") {
    return {
      background: "#ff9e59",
      color: "white",
    };
  }
};

export default function TableWallet() {
  const [user, loading, error] = useAuthState(auth);
  const [BankTransaction, setBankTransaction] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [DateRange, setDateRange] = useRecoilState(selectOrderMode);

  const [UserInfo] = useCollection(collection(getFirestore(app), "users"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      const BankTransaction = await getUserBankTransaction(user.uid);
      setBankTransaction(BankTransaction);
    }
    fetchOrders();
  }, [UserInfo]);

  const handleOpen = (Transaction) => {
    setSelectedTransaction(Transaction);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedTransaction(null);
    setOpen(false);
  };

  return (
    <>
      <h3>Bank Transaction</h3>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Bank Transaction</TableCell>
                <TableCell align="left">Tracking ID</TableCell>
                <TableCell align="left">libellé</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {BankTransaction != null &&
                BankTransaction.map((Transaction, index) => (
                  <TableRow
                    key={Transaction.Time}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {Transaction.Amount}
                    </TableCell>
                    <TableCell align="left">{Transaction.Amount}</TableCell>
                    <TableCell align="left">{Transaction.Detail}</TableCell>
                    <TableCell align="left">
                      <span
                        className="status"
                        style={
                          Transaction.Etat === "Pending"
                            ? makeStyle("Pending")
                            : makeStyle("Approved")
                        }
                      >
                        {Transaction.Etat !== "Pending"
                          ? "Approved"
                          : "Pending"}
                      </span>
                    </TableCell>
                    {Transaction.Etat === "Pending" && (
                      <TableCell
                        onClick={() =>
                          CancelAskOfDepositBankTransfer(user.uid, index)
                        }
                        align="left"
                        className="Details"
                      >
                        Cancel
                      </TableCell>
                    )}
                    {Transaction.hasOwnProperty("Data") && (
                      <TableCell
                        onClick={(e) => handleOpen(Transaction)}
                        align="left"
                        className="Details"
                      >
                        Voir
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedTransaction && (
          <ModalViewDetailBankTransaction
            open={open}
            handleClose={handleClose}
            transaction={selectedTransaction}
          />
        )}
      </div>
    </>
  );
}
