import React from "react";
import { SidebarDataPreparationLogistic } from "../../Data/DataPreparationLogistic";
import Sidebar from "../../components/Sidebar/Sidebar";
import PreparationLogisticsOrders from "../../View/PreparationLogistic/PreparationLogisticsOrders";
import PreparationLogisticsOrdersV2 from "../../View/PreparationLogistic/PreparationLogisticsOrdersV2";
import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";

const PreparationLogistic = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <>
      <Sidebar data={SidebarDataPreparationLogistic} />
      {SideBarAtom.view === 0 && <PreparationLogisticsOrders />}
      {SideBarAtom.view === 1 && <PreparationLogisticsOrdersV2 />}
    </>
  );
};

export default PreparationLogistic;
