import React, { useState } from "react";
import "./AddNewProduct.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField, Button } from "@material-ui/core";
import {
  addNewProductVisitorV2,
  test,
} from "../../../../Data/firebaseInteractionVisitorsV2";

const AddNewProduct = ({ userID }) => {
  const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
  const [DataForm, setDataForm] = useState({ UserIdOwner: userID });

  const handleOpenCreateProductModal = () => {
    setOpenCreateProductModal(true);
  };

  const handleCloseCreateProductModal = () => {
    setOpenCreateProductModal(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Ajoutez ici la logique pour traiter les données du formulaire
  };

  return (
    <div>
      <div
        className="ButtonAddProduct"
        onClick={() => handleOpenCreateProductModal()}
      >
        ADD PRODUCT
      </div>
      <ModalAvecFunction
        open={openCreateProductModal}
        handleClose={handleCloseCreateProductModal}
        Form={
          <form onSubmit={handleFormSubmit}>
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }))
              }
              label="Nom du produit"
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  SellingPrice: event.target.value,
                }))
              }
              label="Prix du produit"
            />
          </form>
        }
        TextActionButton={"Ajouter le produit"}
        TitleModal={"Nouveau produit"}
        DataForm={DataForm}
        setDataForm={setDataForm}
        Function={addNewProductVisitorV2}
      />
    </div>
  );
};

export default AddNewProduct;
