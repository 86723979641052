import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ModalCreateWorkplan from "../../Modal/ModalCreateWorkplan/ModalCreateWorkplan";

const ButtonCreateWorkplan = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Creer un plan de travail
      </Button>
      <ModalCreateWorkplan open={open} handleClose={handleClose} />
    </>
  );
};

export default ButtonCreateWorkplan;
