import React, {useState} from "react";
import Logo from '../../imgs/logo.png'
import './Sidebar.css';
import {UilSignOutAlt, UilBars} from "@iconscout/react-unicons"
import { motion } from "framer-motion";
import Authentification from "../Authentification/Authentification"
import { useRecoilState } from 'recoil';
import {sideBar} from '../../atoms/SideBarAtome'

const Sidebar=(props)=>{

    const [selected, setSelected] = useState(0)
    const[expanded, setExepended] = useState(true)
    const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);

    const sidebarVariants={
        ture:{
            left:'0'
        },
        false:{
            left: '-60%'
        }
    }

    return(
        <>
        <div className="bars" style={expanded?{left:"60%"}:{left:"5%"}}
        onClick={()=>setExepended(!expanded)}
        >
            <UilBars/>
        </div>
        <motion.div className="Sidebar"
        variants={sidebarVariants}
        animate = {window.innerWidth<=768?`${expanded}`:''}
        >
            {/*logo*/}
            <div className="logo">
                <img src={Logo} alt=""/>
                <span>
                Pe<span>p</span>ers
                </span>
            </div>
            {/*menu*/}
            <div className="menu">
                {props.data.map((item,index)=>{
                    return(
                        <div className={selected ===index? 'menuItem active':'menuItem'}
                        key = {index}
                        onClick={()=>{
                            setSelected(index)
                            setSideBarAtom({view:index})
                        }}
                        >
                            <item.icon/>
                            <span>
                                {item.heading}
                            </span>
                        </div>
                    )
                })}
                <div className="menuItem">
                    <Authentification/>
                </div>
            </div>
        </motion.div>
        </>
    )
}

export default Sidebar