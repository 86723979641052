import React, { useState } from "react";
import "./SecondAreaLesResto.css";
import StockDisplay from "../StockDisplay/StockDisplay";
import ProduitActifPrepAndMatiereAuResto from "../../../OwnerV2Components/LesRestaurantPageComponant/ProduitActifPrepAndMatiereAuResto/ProduitActifPrepAndMatiereAuResto";

const SecondAreaLesResto = () => {
  const [fetchDataStock, setFetchDataStock] = useState(false);
  const [fetchDataProductInResto, setFetchDataProductInResto] = useState(false);
  return (
    <div className="ContenerSecondAreaLesResto">
      {fetchDataStock ? (
        <StockDisplay RestaurantName={"RestaurantSainteFoy"} />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "380px",
            background: "rgba(255, 255, 255)",
            borderRadius: "0.7rem",
            gap: "1rem",
            fontSize: "13px",
            overflowY: "auto",
            cursor: "pointer",
          }}
          onClick={() => setFetchDataStock(true)}
        ></div>
      )}
      {fetchDataProductInResto ? (
        <ProduitActifPrepAndMatiereAuResto />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "380px",
            background: "rgba(255, 255, 255)",
            borderRadius: "0.7rem",
            gap: "1rem",
            fontSize: "13px",
            overflowY: "auto",
            cursor: "pointer",
          }}
          onClick={() => setFetchDataProductInResto(true)}
        ></div>
      )}
    </div>
  );
};

export default SecondAreaLesResto;
