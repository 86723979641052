import React, { useEffect, useState } from "react";
import {} from "../../../../Data/firebaseService";
import MatierePremiereRecu from "../MatierePremiereRecu/MatierePremiereRecu";
import CommandeMatierePremierePasse from "../CommandeMatierePremierePasse/CommandeMatierePremierePasse";

import "./AnalyseMatiereOrders.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const AnalyseMatiereOrders = ({ startDate, endDate }) => {
  const [Nav, setNav] = useState(0);

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, [startDate, endDate]);

  return (
    <div>
      <div className="NavMatiereIOrderAnalyse">
        <div
          className={Nav === 0 ? "navRowSelected" : "navRow"}
          onClick={() => setNav(0)}
        >
          Matiere Recu au restuarant
        </div>
        <div
          className={Nav === 1 ? "navRowSelected" : "navRow"}
          onClick={() => setNav(1)}
        >
          Liste des commandes passees{" "}
        </div>
      </div>
      {Nav === 0 && (
        <MatierePremiereRecu startDate={startDate} endDate={endDate} />
      )}
      {Nav === 1 && (
        <CommandeMatierePremierePasse startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};
export default AnalyseMatiereOrders;
