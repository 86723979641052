import React, {useState, useEffect} from "react";
import {FaSearch} from 'react-icons/fa'
import {AiOutlinePlusCircle} from 'react-icons/ai'
import './SearchBarBrand.css'
import {getBrandSearchBarData} from "../../../Data/firebaseService"
import ModalmodifyBrand from '../../Modal/ModalmodifyBrand/ModalmodifyBrand'
import ModaladdNewBrand from '../../Modal/ModaladdNewBrand/ModaladdNewBrand'
import {CreationProductAtom} from '../../../atoms/CreationProductAtom'
import { useRecoilState } from 'recoil';

const SearchBarBrand = () => {

    const [input, setInput] = useState("")
    const [result, setResult] = useState([])
    const [selectedBrand, setselectedBrand] = useState(null);
    const [open, setOpen] = useState(false);
    const [OpenModaladdNewBrand, setOpenModaladdNewBrand] = useState(false);

    const handleOpen = (Brand) => {
        setselectedBrand(Brand);
        setOpen(true);
      };
    
      const handleClose = () => {
        setselectedBrand(null);
        setOpen(false);
      };

      const handleCloseModaladdNewBrand = () => {
        setOpenModaladdNewBrand(false);
      };

    const fetchData = (value)=>{
        getBrandSearchBarData().then(json=>{
            const results = json.filter((Brand)=>{
                return value && Brand.BrandName && Brand.BrandName.toLowerCase().includes(value)
            })
            setResult(results)
        })
    }
    const handleChange = (value)=>{
        setInput(value)
        fetchData(value)
    }

    return (
      <div className="search-bar-container">
        <div className="input-wrapper">
            <FaSearch id = "search-icon"/>
            <input placeholder="Type to search Brand" value={input} onChange={(e)=> handleChange(e.target.value)}/>
            <AiOutlinePlusCircle onClick={()=>setOpenModaladdNewBrand(true)} className="Plus-Add-Product"/>
        </div>
        <div className="result-list">
            {result.map((Brand,id)=>{
                return <div className="search-result" key={id} onClick={() =>{ 
                    handleOpen(Brand)
                    setInput("")
                    setResult([])
                }}>{Brand.BrandName}</div>
            })}
        </div>
        {selectedBrand && (
        <ModalmodifyBrand
          open={open}
          handleClose={handleClose}
          Brand={selectedBrand}
        />
      )}
      <ModaladdNewBrand
          open={OpenModaladdNewBrand}
          handleClose={handleCloseModaladdNewBrand}
        />
      </div>
    );
  };
  

export default SearchBarBrand