import React, { useState } from "react";
import MainDashCuisinier from "../../components/MainDash/MainDashCuisinier/MainDashCuisinier";

const InProduction = ({ Workplace }) => {
  return (
    <>
      <MainDashCuisinier Workplace={Workplace} />
    </>
  );
};

export default InProduction;
