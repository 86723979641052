import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Avatar from "@mui/material/Avatar";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableWalletBrand.css";
import {
  CalculSoldWallet,
  UpdateDatabaseWallet,
  SumSoldAllWallets,
} from "../../../Functions/HandleClickFunctions";

import { getOnlyPreparations } from "../../../Data/firebaseService";
import ModalModificationPrep from "../../../components/Modal/ModalModificationPrep/ModalModificationPrep";

import ButtonCalculateWallet from "../../Button/ButtonCalculateWallet/ButtonCalculateWallet";

export default function TableWalletBrand({
  setWalletData,
  WalletData,
  SelectedBrand,
}) {
  const handleSupData = (index) => {
    setWalletData((prev) => {
      let NewDate = {
        ...prev,
        BankTransaction: [
          ...prev.BankTransaction.slice(0, index),
          ...prev.BankTransaction.slice(index + 1),
        ],
      };
      return NewDate;
    });
  };

  const handleUpdateSolde = () => {
    const SoldeWallet = CalculSoldWallet(WalletData);
    setWalletData((prev) => {
      let NewDate = {
        ...prev,
        Solde: SoldeWallet,
      };
      return NewDate;
    });
  };

  const handleUpdateDatabaseWallet = async () => {
    await UpdateDatabaseWallet(WalletData, SelectedBrand);
  };

  return (
    <>
      <h3>Wallet : {WalletData != null && WalletData.Solde.toFixed(2)}</h3>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Montant</TableCell>
                <TableCell>Detail</TableCell>
                <TableCell>But</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {WalletData != null &&
                WalletData.BankTransaction.map((Row, index) => (
                  <div onClick={() => handleSupData(index)}>
                    <TableRow
                      key={Row.Detail}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {Row.Amount}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Row.Detail}
                      </TableCell>
                    </TableRow>
                  </div>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <button onClick={() => handleUpdateSolde()}>Calcul Sold</button>
        <button onClick={() => handleUpdateDatabaseWallet()}>
          Update Database
        </button>
        <button onClick={() => SumSoldAllWallets()}>Sum all wallet </button>
      </div>
    </>
  );
}
