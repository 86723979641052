import {
  getFirestore,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { app, firestore, storage } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { m } from "framer-motion";
import { ca, el } from "date-fns/locale";

export async function calculCoutProductionDish(ListeDishObject) {
  try {
    let AllNewListeDishObject = [];

    for (const Dish of ListeDishObject) {
      let CoutHT = 0;

      // Créer un tableau de promesses pour chaque appel asynchrone
      const promises = Dish.Production.PreparationIntermediaire.map(
        async (PrepUSed) => {
          const PrepDocRef = doc(firestore, "preparations", PrepUSed.IDPrep);
          const PrepSnapshot = await getDoc(PrepDocRef);
          if (PrepSnapshot.exists()) {
            const PrepData = PrepSnapshot.data();
            CoutHT += PrepData.PrixHTParUniteDeMesure * PrepUSed.Qte;
          } else {
            console.log("IngredientSnapshot does not exist");
            throw new Error("IngredientSnapshot does not exist");
          }
        }
      );

      // Attendre que toutes les promesses se terminent
      await Promise.all(promises);

      AllNewListeDishObject.push({
        ...Dish,
        CoutRevientHTVBB: parseFloat(CoutHT.toFixed(6)),
      });
    }

    return AllNewListeDishObject;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}

export async function getAllDish() {
  try {
    const DishdocRef = collection(firestore, "dishes");
    const querySnapshot = await getDocs(DishdocRef);
    const data = querySnapshot.docs.map((doc) => doc.data());
    const AllDishPrice = await calculCoutProductionDish(data);

    return AllDishPrice;
  } catch (e) {
    console.log(e);
  }
}

export async function CreateModuleDeCommande(ListeDish) {
  try {
    let ListeDishInModule = ListeDish.map((Dish) => Dish.Production.IDPepers);
    const IDModule = uuidv4();
    const ModuleDeCommandedocRef = doc(
      firestore,
      `RestaurantSainteFoyModuleDeCommande`,
      IDModule
    );
    await setDoc(ModuleDeCommandedocRef, {
      ListeDishInModule,
    });

    return true;
  } catch (e) {
    console.log(e);
  }
}
export async function getModuleCommandeData(ModuleID) {
  try {
    const ModuleDeCommandedocRef = doc(
      firestore,
      `RestaurantSainteFoyModuleDeCommande`,
      ModuleID
    );
    const querySnapshot = await getDoc(ModuleDeCommandedocRef);

    const data = querySnapshot.data();

    let ListeDishData = [];
    for (const DishID of data.ListeDishInModule) {
      const DishDocRef = doc(firestore, "dishes", DishID);
      const DishSnapshot = await getDoc(DishDocRef);
      if (DishSnapshot.exists()) {
        let DishData = DishSnapshot.data();
        ListeDishData.push({ ...DishData });
      } else {
        console.log("DishSnapshot does not exist");
      }
    }
    return ListeDishData;
  } catch (e) {
    console.log(e);
  }
}
