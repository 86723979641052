import React from "react";
import RightSideDEMO from "../../components/RightSide/RightSideDEMO/RightSideDEMO";
import MainDashDEMO from "../../components/MainDash/MainDashDEMO/MainDashDEMO";

const DashboardDEMO = () => {
  return (
    <>
      <MainDashDEMO />
      <RightSideDEMO />
    </>
  );
};

export default DashboardDEMO;
