import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./ModalAddNewSup.css";
import { HandleCreateNewSup } from "../../../Functions/HandleClickFunctions";
import UploadImage from "../../../components/UploadImage/UploadImage";

import { getAllDish } from "../../../Data/firebaseService";
import SearchBarPreparationSetListePreparations from "../../../components/SearchBar/SearchBarPreparationSetListePreparations/SearchBarPreparationSetListePreparations";

export default function ModalAddNewSup({ open, handleClose }) {
  const [ListePreparation, setListePreparation] = useState([]);
  const [NomSupplement, setNomSupplement] = useState("");
  const [img, setImag] = useState(null);
  const [QteSup, setQteSup] = useState(0);
  const [UniteDeMesure, setUniteDeMesure] = useState("");

  const HandleChangeQtePrep = (e, index) => {
    setListePreparation((prevPreparation) => [
      ...prevPreparation.slice(0, index),
      {
        DataPreparation: { ...prevPreparation[index].DataPreparation },
        Qte: e.target.value,
      },
      ...prevPreparation.slice(index + 1),
    ]);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <TextField
          label="Nom du supplement"
          variant="outlined"
          value={NomSupplement}
          onChange={(e) => setNomSupplement(e.target.value)}
        />
        <TextField
          label="Qte sup dans le produit"
          variant="outlined"
          value={QteSup}
          onChange={(e) => setQteSup(e.target.value)}
        />
        <Select
          labelId="select-unite-label-Type-Preparation"
          id="select-unite-Type-Preparation"
          value={UniteDeMesure}
          onChange={(e) => setUniteDeMesure(e.target.value)}
        >
          <MenuItem value={"Unite"}>Unite</MenuItem>
          <MenuItem value={"G"}>G</MenuItem>
        </Select>
        <SearchBarPreparationSetListePreparations
          setListePreparation={setListePreparation}
        />
        {ListePreparation.map((prep, index) => (
          <div>
            {prep.DataPreparation.Nom}
            <TextField
              label="Qte"
              variant="outlined"
              value={prep.Qte}
              onChange={(e) => HandleChangeQtePrep(e, index)}
            />
            {prep.DataPreparation.Unite}
          </div>
        ))}

        <Typography id="uploadImage-AddProduct" sx={{ mt: 2 }}>
          <UploadImage setImag={setImag} />
        </Typography>
        <Button
          onClick={() => {
            HandleCreateNewSup(
              NomSupplement,
              ListePreparation,
              img,
              QteSup,
              UniteDeMesure
            );
            handleClose();
          }}
        >
          Creer le sup
        </Button>
      </Box>
    </Modal>
  );
}
