import React, { useState, useEffect } from "react";
import "./DataConsomationEntreDeuxInventaire.css";
import { GetConssomationMatiereEntreDeuxInventaires } from "../../../../../../../Data/firebaseInteractionOwnerV2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";

const DataConsomationEntreDeuxInventaire = ({
  IDInventaireStart,
  IDInventaireEnd,
  RestaurantName,
}) => {
  const [Consso, setConsso] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const ConssomationData = await GetConssomationMatiereEntreDeuxInventaires(
        IDInventaireStart,
        IDInventaireEnd,
        RestaurantName
      );
      setConsso(ConssomationData);
    }

    fetchData();
  }, [IDInventaireStart, IDInventaireEnd, RestaurantName]);

  return (
    <div className="ContenerDataConsomationEntreDeuxInventaire">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Matiere</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Qte conssomee</TableCell>
              <TableCell align="left">Qte theorique</TableCell>
              <TableCell align="left">Ecart de consso</TableCell>
              <TableCell align="left">Unite de mesure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {Consso ? (
              Object.keys(Consso).map((IngredientID) => (
                <TableRow
                  key={Consso[IngredientID].name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {Consso[IngredientID].name}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Avatar
                      alt={Consso[IngredientID].name}
                      src={Consso[IngredientID].Img}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {Consso[IngredientID].ConssomationMatiere}
                  </TableCell>
                  <TableCell align="left">
                    {Consso[IngredientID].ConssomationTheorique}
                  </TableCell>
                  <TableCell align="left">
                    {Consso[IngredientID].EcartConssomation}
                  </TableCell>
                  <TableCell align="left">
                    {Consso[IngredientID].UniteDeRecette}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                key={"1"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <CircularProgress size={24} color="secondary" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataConsomationEntreDeuxInventaire;
