import React, { useState, useEffect } from "react";
import "./MainDashOwnerUserData.css";
import CardsDEMO from "../../Cards/CardsDEMO/CardsDEMO";
import TableWalletBrand from "../../Table/TableWalletBrand/TableWalletBrand";
import { getWalletOfABrand } from "../../../Data/firebaseService";

const MainDashOwnerUserData = ({
  setWalletData,
  WalletData,
  SelectedBrand,
}) => {
  const [WalletInTheDatabase, SetWalletInTheDatabase] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      const WalletDat = await getWalletOfABrand(SelectedBrand);
      SetWalletInTheDatabase(WalletDat);
    }
    fetchOrders();
  }, [SelectedBrand]);

  return (
    <div className="MainDash">
      <TableWalletBrand
        WalletData={WalletInTheDatabase}
        setWalletData={setWalletData}
        SelectedBrand={SelectedBrand}
      />
      <TableWalletBrand
        WalletData={WalletData}
        setWalletData={setWalletData}
        SelectedBrand={SelectedBrand}
      />
    </div>
  );
};

export default MainDashOwnerUserData;
