import React, { useState } from "react";
import "./DisplayPrepAndIngredientInTheRestaurant.css";
import DisplayActivePrepAndIngredientInRestaurant from "../DisplayActivePrepAndIngredientInRestaurant/DisplayActivePrepAndIngredientInRestaurant";

const DisplayPrepAndIngredientInTheRestaurant = ({ RestaurantName }) => {
  return (
    <div className="ContenerPrepAndIngredientInTheRestaurant">
      <DisplayActivePrepAndIngredientInRestaurant
        RestaurantName={RestaurantName}
      />
      <div>DisplayListOfProductOrPrepUsedInAProduct</div>
    </div>
  );
};

export default DisplayPrepAndIngredientInTheRestaurant;
