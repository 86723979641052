import React, { useEffect, useState } from "react";
import AnalyseConssomationTheoriquePrepEntreDeuxDates from "../../components/GestionRestaurant/AnalyseOwnerSide/AnalyseConssomationTheoriquePrepEntreDeuxDates/AnalyseConssomationTheoriquePrepEntreDeuxDates";
import MatierePremiereRecu from "../../components/GestionRestaurant/AnalyseOwnerSide/MatierePremiereRecu/MatierePremiereRecu";
import {
  getConssomationTheoriqueMatiereEnFonctionDuneConsommationDePrep,
  GetAnalyseViaCommandesRecuAuRestau,
} from "../../Data/firebaseService";
import { Input } from "@chakra-ui/react";
import NavBarAnalyseManagerRestaurant from "../../components/NavBar/NavBarAnalyseManagerRestaurant/NavBarAnalyseManagerRestaurant";
import AnalyseViaCommandesRecuAuRestau from "../../components/GestionRestaurant/AnalyseOwnerSide/AnalyseViaCommandesRecuAuRestau/AnalyseViaCommandesRecuAuRestau";
import AnalyseConssomationReelle from "../../components/GestionRestaurant/AnalyseOwnerSide/AnalyseConssomationReelle/AnalyseConssomationReelle";
import AnalyseMatiereOrders from "../../components/GestionRestaurant/AnalyseOwnerSide/AnalyseMatiereOrders/AnalyseMatiereOrders";
import StockRestaurantPrepAndMatiere from "../../components/GestionRestaurant/AnalyseOwnerSide/StockRestaurantPrepAndMatiere/StockRestaurantPrepAndMatiere";

const AnalyseStockRestaurant = () => {
  const [startDate, setStartDate] = useState("2024-01-08");
  const [endDate, setEndDate] = useState("");
  const [Nav, setNav] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const Data = await GetAnalyseViaCommandesRecuAuRestau();
    };
    fetchData();
  }, []);

  return (
    <>
      <div>
        <h1>Page De controle du manager</h1>
        <div>
          <Input
            placeholder="Date de début"
            size="md"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <Input
            placeholder="Date de fin"
            size="md"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <NavBarAnalyseManagerRestaurant Nav={Nav} setNav={setNav} />

        {/*<AnalyseConssomationTheoriquePrepEntreDeuxDates />*/}
        {Nav === 0 && (
          <AnalyseMatiereOrders startDate={startDate} endDate={endDate} />
        )}

        {Nav === 1 && (
          <AnalyseViaCommandesRecuAuRestau
            startDate={startDate}
            endDate={endDate}
          />
        )}
        {Nav === 2 && (
          <AnalyseConssomationReelle startDate={startDate} endDate={endDate} />
        )}
        {Nav === 3 && <StockRestaurantPrepAndMatiere />}
      </div>
      <div>Right side</div>
    </>
  );
};

export default AnalyseStockRestaurant;
