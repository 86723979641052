import React, { useState } from "react";
import "./DailyActivitiesAnalyse.css";
import NavBarVerticaleSousCategories from "../../../../../NavBar/NavBarVerticaleSousCategories/NavBarVerticaleSousCategories";
import AnnalyseGenerale from "./AnnalyseGenerale/AnnalyseGenerale";
import AnalyseProduitsVendus from "./AnalyseProduitsVendus/AnalyseProduitsVendus";
import ConssomationTheorique from "./ConssomationTheorique/ConssomationTheorique";
import ChoixRangeDate from "./ChoixRangeDate/ChoixRangeDate";
import PrepRefait from "./PrepRefait/PrepRefait";

const DailyActivitiesAnalyse = () => {
  const [navRow, setNavRow] = useState(0);
  const [dateStart, setDateStart] = useState("2023-05-24T00:00:00");
  const [dateEnd, setDateEnd] = useState("2023-05-24T00:00:00");
  console.log(dateStart);
  console.log(dateEnd);
  return (
    <div className="ContenerDailyActivitiesAnalyse">
      <div>
        <ChoixRangeDate setDateStart={setDateStart} setDateEnd={setDateEnd} />
        <NavBarVerticaleSousCategories
          Data={[
            "General",
            "Consso theorique",
            "Produits vendus",
            "Prep Refait",
          ]}
          setNavRow={setNavRow}
          navRow={navRow}
        />
      </div>
      {navRow === 0 && <AnnalyseGenerale />}
      {navRow === 1 && (
        <ConssomationTheorique dateStart={dateStart} dateEnd={dateEnd} />
      )}
      {navRow === 2 && (
        <AnalyseProduitsVendus dateStart={dateStart} dateEnd={dateEnd} />
      )}
      {navRow === 3 && (
        <PrepRefait
          dateStart={dateStart}
          dateEnd={dateEnd}
          RestaurantName={"RestaurantSainteFoy"}
        />
      )}
    </div>
  );
};

export default DailyActivitiesAnalyse;
