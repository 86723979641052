import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography ,
} from "@mui/material";
import {auth } from '../../../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import {CreatAskOfDepositBankTransfer} from '../../../Functions/HandleClickFunctions'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function ModalBankTransaction({ open, handleClose }) {
    const [user, loading, error] = useAuthState(auth);
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Ref de la transaction a inscrire dans motif : {user.uid}
        </Typography>
        <Typography id="modal-modal-title" variant="h5" component="h5">
          RIB VBB HOUSE : FR76 3000 4004 8200 0100 9707 049
        </Typography>
        <Typography id="modal-modal-title" variant="h8" component="h8">
          Une fois la demande de versement validée veuillez effectuer un virement du montant selctionné sur le RIB ci dessus  
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Total: {selectedValue} € 
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: '10px', mb:'10px'}}>
        
            <FormControl sx={{ width: '200px'}}>
                <InputLabel id="select-label">Sélectionnez un montant</InputLabel>
                <Select
                labelId="select-label"
                id="select-input"
                value={selectedValue}
                onChange={handleChange}
                >
                <MenuItem value="500">500 €</MenuItem>
                <MenuItem value="1000">1000 €</MenuItem>
                <MenuItem value="2000">2000 €</MenuItem>
                <MenuItem value="5000">5000 €</MenuItem>
                </Select>
            </FormControl>

        </Typography>
        <Button variant="contained" color="primary" onClick={()=>{
          CreatAskOfDepositBankTransfer(user.uid, parseInt(selectedValue))
          handleClose()
          }}>
            Ajouter 
        </Button>
      </Box>
    </Modal>
  );
}