import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";

export default function ModalViewDetailBankTransaction({
  open,
  handleClose,
  transaction,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Total: {transaction.Amount}
        </Typography>
        {transaction.Data.ListeProduitVendu.map((item) => (
          <Typography key={item.id} id="modal-modal-description" sx={{ mt: 2 }}>
            - {item.Nom} : {item.Qte} : Prix unitaire : {item.PrixProduit} €
          </Typography>
        ))}
      </Box>
    </Modal>
  );
}
