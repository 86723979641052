import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableOwnerKitchenSettings.css";

import {
  getAllDish,
  getListeMatireForPreparation,
} from "../../../Data/firebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import ModalModificationDish from "../../../components/Modal/ModalModificationDish/ModalModificationDish";

import { useRecoilState } from "recoil";
import { CreationProductAtom } from "../../../atoms/CreationProductAtom";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import ButtonAddProductInZelty from "../../Button/ButtonAddProductInZelty/ButtonAddProductInZelty";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import {
  SupprimerProduct,
  UpdateAllDish,
} from "../../../Functions/HandleClickFunctions";

import ButtonUpdateAllStateOfDishes from "../../Button/ButtonUpdateAllStateOfDishes/ButtonUpdateAllStateOfDishes";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Cancel") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function TableOwnerKitchenSettings() {
  const [user, loading, error] = useAuthState(auth);
  const [AllDish, setAllDish] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedDish, setSelectedDish] = useState(null);
  const [CreationProduct, setCreationProduct] =
    useRecoilState(CreationProductAtom);

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const AllDish = await getAllDish();
      setAllDish(AllDish);
    }
    fetchOrders();
  }, [CreationProduct, Production]);

  const handleOpen = (Dish) => {
    setSelectedDish(Dish);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedDish(null);
    setOpen(false);
  };

  const handleSwitchChange = (index) => {
    let EtatProduct = false;
    if (
      AllDish[index].Production.hasOwnProperty("StateProduct") &&
      AllDish[index].Production.StateProduct === "Actif"
    ) {
      EtatProduct = false;
    }
    if (
      AllDish[index].Production.hasOwnProperty("StateProduct") &&
      AllDish[index].Production.StateProduct === "Desactive"
    ) {
      EtatProduct = true;
    }
    if (AllDish[index].Production.hasOwnProperty("StateProduct")) {
    } else {
      EtatProduct = true;
    }

    setAllDish((AllDishes) => {
      let newAllDish = [
        ...AllDishes.slice(0, index),
        {
          ...AllDishes[index],
          Production: {
            ...AllDishes[index].Production,
            StateProduct: EtatProduct ? "Actif" : "Desactive",
          },
        },
        ...AllDishes.slice(index + 1),
      ];
      return newAllDish;
    });
  };

  return (
    <>
      <h3>Liste des produits</h3>{" "}
      {AllDish && <ButtonUpdateAllStateOfDishes AllDish={AllDish} />}
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Produit</TableCell>
                <TableCell align="left">Img</TableCell>
                <TableCell align="left">Activate</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {AllDish != null &&
                AllDish.map((Dish, index) => (
                  <TableRow
                    key={Dish.Production.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {Dish.Production.name}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Avatar
                        alt={Dish.Production.name}
                        src={Dish.Production.img}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        value="top"
                        control={
                          <Switch
                            color="primary"
                            onChange={() => handleSwitchChange(index)}
                            checked={
                              Dish.Production.hasOwnProperty("StateProduct") &&
                              Dish.Production.StateProduct === "Actif"
                                ? true
                                : false
                            }
                          />
                        }
                        labelPlacement="top"
                      />
                    </TableCell>

                    <TableCell
                      onClick={() => handleOpen(Dish)}
                      align="left"
                      className="Details"
                    >
                      Modifier
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <ButtonAddProductInZelty Dish={Dish} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedDish && (
          <ModalModificationDish
            open={open}
            handleClose={handleClose}
            dish={selectedDish}
          />
        )}
      </div>
    </>
  );
}
