import React, { useState, useEffect } from "react";
import "./DisplayUserProduct.css";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import { getUserInfo } from "../../../Data/firebaseGetUserInfo";
import {
  getVisitorProduct,
  SupprimerProduit,
} from "../../../Data/firebaseInteractionVisitorsV2";

import { UilEuroCircle } from "@iconscout/react-unicons";
import AddNewProduct from "../Button/AddNewProduct/AddNewProduct";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

const DisplayUserProduct = ({ setProductSelected, ProductSelected }) => {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState(null);
  const [userProduct, setUserProduct] = useState(null);

  const [dishes] = useCollection(collection(getFirestore(app), "dishes"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [preparation] = useCollection(
    collection(getFirestore(app), "preparation"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const userInfo = await getUserInfo(user.uid);
      setUserInfo(userInfo);
      const userProduct = await getVisitorProduct(userInfo.uid);
      setUserProduct(userProduct);
    }
    fetchOrders();
  }, [dishes, preparation]);

  const [isProcessing, setIsProcessing] = useState(false);
  const HandleSupprimerProduit = (product) => {
    if (isProcessing) return;
    setIsProcessing(true);
    SupprimerProduit(product);
    setIsProcessing(false);
  };
  return (
    <>
      <div>
        {userProduct &&
          userProduct.map((product) => (
            <div
              className={
                ProductSelected &&
                ProductSelected.Production.IDPepers ===
                  product.Production.IDPepers
                  ? "ProductLineSelected"
                  : "ProductLine" && product.Actif === true
                  ? "ProductLineActif"
                  : "ProductLine"
              }
              onClick={() => setProductSelected(product)}
            >
              <div className="">{product.Production.name}</div>
              <div>{product.Production.SellingPrice}</div>
              <div>{product.CoutRevientClient}</div>
              <div
                className="Red"
                onClick={() => HandleSupprimerProduit(product)}
              >
                Supprimer
              </div>
            </div>
          ))}
        <AddNewProduct userID={user.uid} />

        {/*<UilEuroCircle size="50" color="#e74c3c" />*/}
      </div>
    </>
  );
};

export default DisplayUserProduct;
