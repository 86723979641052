import React, { useState, useEffect } from "react";
import "./TableAnalyseProduitVendu.css";
import { getDailyAnalyuseProduitVendu } from "../../../../../../../../Data/firebaseInteractionOwnerV2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";

const TableAnalyseProduitVendu = ({ RestaurantName, dateStart, dateEnd }) => {
  const [ProduitsVendu, setProduitsVendu] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const DailyProduitVendu = await getDailyAnalyuseProduitVendu(
        RestaurantName,
        dateStart,
        dateEnd
      );
      setProduitsVendu(DailyProduitVendu);
    }
    fetchData();
  }, []);

  return (
    <div className="ContenerTableAnalyseProduitVendu">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Produit</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Qte vendu</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {ProduitsVendu ? (
              ProduitsVendu.map((Produit) => {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, "0");
                const day = String(today.getDate()).padStart(2, "0");
                const FormatedDate = `${day}/${month}/${year} - ${today.getHours()} : ${today.getMinutes()}`;

                return (
                  <TableRow
                    key={Produit.Production.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {Produit.Production.name}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Avatar
                        alt={Produit.Production.name}
                        src={Produit.Production.img}
                      />
                    </TableCell>
                    <TableCell align="left">{Produit.QteVendu}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <CircularProgress size={24} color="secondary" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableAnalyseProduitVendu;
