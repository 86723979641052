import React, { useEffect, useState } from "react";
import NavBarHorisontale from "../../../../NavBar/NavBarHorisontale/NavBarHorisontale";
import { GetAllPrepToUseInProduct } from "../../../../../Data/firebaseInteractionVisitorsV2";
import { AddPrepToOptionValue } from "../../../../../Data/firebaseInteractionOwnerV2";
import SearchBarPrep from "../../../../VisiteurComponents/SearchBarPrep/SearchBarPrep";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../firebaseConfig";

const ChoosePrepOrMatiereInOption = ({
  selectedOptionValue,
  setOptionChoice,
}) => {
  const [DataDisplay, setDataDisplay] = useState(null);
  const [result, setResult] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      const Data = await GetAllPrepToUseInProduct();
      setDataDisplay(Data);
    }
    fetchOrders();
  }, []);

  const HandlePutPrepInOtionValue = (prep) => {
    setOptionChoice((prev) => {
      let newOptionData = [];
      prev.forEach((option, index) => {
        if (option.id === selectedOptionValue.id) {
          if (option.hasOwnProperty("preparationIntermediaire")) {
            if (
              option.preparationIntermediaire
                .map((Interm) => Interm.IDPrep)
                .includes(prep.IDPepers)
            ) {
              newOptionData = [...prev];
            } else {
              newOptionData = [
                ...prev.slice(0, index),
                {
                  ...prev[index],
                  preparationIntermediaire: [
                    ...prev[index].preparationIntermediaire,
                    { IDPrep: prep.IDPepers, Qte: 0 },
                  ],
                },
                ...prev.slice(index + 1),
              ];
            }
          } else {
            newOptionData = [
              ...prev.slice(0, index),
              {
                ...prev[index],
                preparationIntermediaire: [{ IDPrep: prep.IDPepers, Qte: 0 }],
              },
              ...prev.slice(index + 1),
            ];
          }
        }
      });

      return newOptionData;
    });
  };

  return (
    <div
      style={{
        padding: "10px",
        marginRight: "5px",
        marginLeft: "5px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        overflowX: "auto",
        border: "1px solid #e5e5e5",
        maxHeight: "200px",
      }}
    >
      <div
        style={{
          marginRight: "15px",
          background: "rgba(255, 255, 255)",
          padding: "20px",
          borderRadius: "0.7rem",
          height: "100%",
        }}
      >
        <SearchBarPrep
          UseEffectFunction={GetAllPrepToUseInProduct}
          setResult={setResult}
        />
        <div className="CategorieContener" onClick={() => setResult(null)}>
          All
        </div>
      </div>
      {result
        ? result.map((prep) => {
            return (
              <div
                style={{
                  marginRight: "15px",
                  background: "rgba(255, 255, 255)",
                  padding: "10px",
                  borderRadius: "0.7rem",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    backgroundSize: "cover",
                    backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                    height: "150px",
                    width: "150px",
                  }}
                  onClick={() => HandlePutPrepInOtionValue(prep)}
                >
                  {" "}
                  <img
                    style={{ height: "150px", width: "150px" }}
                    src={prep.img}
                    alt=""
                  />
                </div>
                <div className="NomPrep">{prep.name}</div>
              </div>
            );
          })
        : DataDisplay &&
          DataDisplay.map((prep) => {
            return (
              <div
                style={{
                  marginRight: "15px",
                  background: "rgba(255, 255, 255)",
                  padding: "10px",
                  borderRadius: "0.7rem",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    backgroundSize: "cover",
                    backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                    height: "150px",
                    width: "150px",
                  }}
                  onClick={() => HandlePutPrepInOtionValue(prep)}
                >
                  {" "}
                  <img
                    style={{ height: "150px", width: "150px" }}
                    src={prep.img}
                    alt=""
                  />
                </div>
                <div className="NomPrep">{prep.name}</div>
              </div>
            );
          })}
    </div>
  );
};

export default ChoosePrepOrMatiereInOption;
