import React, { useState, useEffect } from "react";

const MatierePremiere = () => {
  useEffect(() => {}, []);

  return (
    <>
      <div>Main Dash</div>
      <div>Right Side</div>
    </>
  );
};

export default MatierePremiere;
