import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

export default function ModalUpdateFournisseurData({
  open,
  handleClose,
  DataFournisseur,
}) {
  const [AllDataFournisseur, setAllDataFournisseur] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      setAllDataFournisseur(DataFournisseur);
    }

    fetchOrders();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="container">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%", // Ajustez cette valeur selon vos besoins
            maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {AllDataFournisseur && AllDataFournisseur.Nom}
          <div>TEST : {AllDataFournisseur && AllDataFournisseur.Nom}</div>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Update fournisseur
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
