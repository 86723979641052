import React from "react";
import MainDashWallet from '../../components/MainDash/MainDashWallet/MainDashWallet'
import RightSideWallet from'../../components/RightSide/RightSideWallet/RightSideWallet'
const Wallet = () =>{

    return(<>
          <MainDashWallet/>
          <RightSideWallet/>
    </>)
}

export default Wallet