import React, { useState, useEffect } from "react";
import AddPrepToPlanDeTravail from "../../../../../../Button/AddPrepToPlanDeTravail/AddPrepToPlanDeTravail";
import Avatar from "@mui/material/Avatar";
import { SupprimerPrepInPlanDeTravail } from "../../../../../../../../Data/firebaseInteractionOwnerV2";

const DisplayPlanDetravailPrep = ({
  DataPrep,
  RestaurantName,
  PlanDeTravailID,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const HandleSupprimerPrepInPlanDeTravail = async (
    machine,
    PlanDeTravailID,
    RestaurantName
  ) => {
    setIsProcessing(true);
    if (isProcessing) {
      return;
    }
    await SupprimerPrepInPlanDeTravail(
      machine,
      PlanDeTravailID,
      RestaurantName
    );
    setIsProcessing(false);
  };

  return (
    <div style={{ height: "180px", overflowY: "auto" }}>
      {DataPrep.map((Prep) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "yellow",
              margin: "5px",
            }}
          >
            {Prep.name}
            <Avatar alt={Prep.name} src={Prep.img} sizes="small" />
            <span
              className="Red"
              onClick={() =>
                HandleSupprimerPrepInPlanDeTravail(
                  Prep,
                  PlanDeTravailID,
                  RestaurantName
                )
              }
            >
              Supprimer
            </span>
          </div>
        );
      })}
      <AddPrepToPlanDeTravail
        RestaurantName={RestaurantName}
        PlanDeTravailID={PlanDeTravailID}
      />
    </div>
  );
};

export default DisplayPlanDetravailPrep;
