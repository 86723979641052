import React, { useState, useEffect } from "react";
import "./BigSelectorIngredient.css";
import { getAllIngredient } from "../../../Data/firebaseInteractionVisitorsV2";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

const BigSelectorIngredient = ({ SetSelected, Selected }) => {
  const [Data, setData] = useState(null);

  const [Ingredients] = useCollection(
    collection(getFirestore(app), "Ingredients"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const Data = await getAllIngredient();
      setData(Data);
      if (Selected) {
        SetSelected((PrevData) => {
          return {
            ...Data.filter((item) => item.IDPepers === PrevData.IDPepers)[0],
          };
        });
      }
    }
    fetchOrders();
  }, [Ingredients]);

  return (
    <div className="ContenerSelectorIngredient">
      {Data &&
        Data.map((item, index) => (
          <div
            className={
              Selected && item.IDPepers === Selected.IDPepers
                ? "RowSelectorIngredientSelected"
                : "RowSelectorIngredient"
            }
            onClick={() => SetSelected(item)}
          >
            {item.name}
          </div>
        ))}
    </div>
  );
};

export default BigSelectorIngredient;
