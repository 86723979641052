import React, {useState, useEffect} from "react";
import {getZeltyProductSearchBarData} from '../../../Data/firebaseService'
import {FaSearch} from 'react-icons/fa'
import {AiOutlinePlusCircle} from 'react-icons/ai'
import './SearchBarZeltyProduct.css'
import { useRecoilState } from 'recoil';
import {CreationProductAtom} from '../../../atoms/CreationProductAtom'
import ModalAddNewProduct from '../../Modal/ModalAddNewProduct/ModalAddNewProduct'

const SearchBarZeltyProduct = () => {
    const [input, setInput] = useState("")
    const [result, setResult] = useState([])
    const [ProductAtom, setProductAtom] = useRecoilState(CreationProductAtom);
    const [open, setOpen] = useState(false);

    const fetchData = (value)=>{
        getZeltyProductSearchBarData().then(json=>{
            const results = json.filter((product)=>{
                return value && product.Production.name && product.Production.name.toLowerCase().includes(value)
            })
            setResult(results)
        })
    }
    const handleChange = (value)=>{
        setInput(value)
        fetchData(value)
    }

    const handleClose = () => {
        setOpen(false);
      };

    return (
        <div className="search-bar-container">
        <div className="input-wrapper">
            <FaSearch id = "search-icon"/>
            <input placeholder="Type to search product" value={input} onChange={(e)=> handleChange(e.target.value)}/>
            <AiOutlinePlusCircle onClick={()=>setOpen(true)} className="Plus-Add-Product"/>
        </div>
        <div className="result-list">
            {result.map((result,id)=>{
                return <div className="search-result" key={id} onClick={(e)=>{
                    setProductAtom({productSelected:result.Production.name})
                    setInput("")
                    setResult([])
                }}>{result.Production.name}</div>
            })}
        </div>
        <ModalAddNewProduct
          open={open}
          handleClose={handleClose}
        />
      </div>
    );
  };
  

export default SearchBarZeltyProduct