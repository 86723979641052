import React, { useState, useEffect } from "react";
import "./MainDashOwnerKitchenSettings.css";
import CardsOwnerKitchen from "../../Cards/CardsOwnerKitchen/CardsOwnerKitchen";
import TableOwnerKitchenSettings from "../../Table/TableOwnerKitchenSettings/TableOwnerKitchenSettings";
import TabledisplayPrepShouldBeAvailableInRestaurant from "../../Table/TabledisplayPrepShouldBeAvailableInRestaurant/TabledisplayPrepShouldBeAvailableInRestaurant";
import TabledisplayMatierePremiereShouldBeAvailableInLogistics from "../../Table/TabledisplayMatierePremiereShouldBeAvailableInLogistics/TabledisplayMatierePremiereShouldBeAvailableInLogistics";
import { auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserInfo } from "../../../Data/firebaseGetUserInfo";
import NavBarHorisontale from "../../NavBar/NavBarHorisontale/NavBarHorisontale";
import TablePreparations from "../../Table/TablePreparations/TablePreparations";
import TableMatierePremiere from "../../Table/TableMatierePremiere/TableMatierePremiere";
import TableDisplayFournisseur from "../../Table/TableDisplayFournisseur/TableDisplayFournisseur";

const MainDashOwnerKitchenSettings = () => {
  const [user, loading, error] = useAuthState(auth);
  const [Brand, setBrand] = useState("Loading...");
  const [ChoixTable, setChoixTable] = useState(0);

  const [NavRow, setNavRow] = useState(0);

  useEffect(() => {
    async function fetchUserBrand() {
      const Userdata = await getUserInfo(user.uid);
      setBrand(Userdata.OwnerBrand);
    }
    fetchUserBrand();
  }, [user]);

  return (
    <div className="MainDash">
      {/*<div className="navTablePrepMatiere">
        <div
          className={ChoixTable === 0 ? "RowSelected" : "RowButton"}
          onClick={() => setChoixTable(0)}
        >
          Prep qui doivent etre au restaurant
        </div>
        <div
          className={ChoixTable === 1 ? "RowSelected" : "RowButton"}
          onClick={() => setChoixTable(1)}
        >
          Matiere qui doivent etre au restaurant
        </div>
      </div>
      {ChoixTable === 0 && <TabledisplayPrepShouldBeAvailableInRestaurant />}
      {ChoixTable === 1 && (
        <TabledisplayMatierePremiereShouldBeAvailableInLogistics />
      )}*/}
      <NavBarHorisontale
        Data={[
          "All Dish Firebase",
          "All Prep Firebase",
          "All Matiere Firebse",
          "All Fournisseur",
        ]}
        setNavRow={setNavRow}
        navRow={NavRow}
      />
      {NavRow === 0 && <TableOwnerKitchenSettings />}
      {NavRow === 1 && <TablePreparations />}
      {NavRow === 2 && <TableMatierePremiere />}
      {NavRow === 3 && <TableDisplayFournisseur />}
    </div>
  );
};

export default MainDashOwnerKitchenSettings;
