import React, { useEffect, useState } from "react";
import "./BottomPageVisitorV2.css";
import NavBarHorisontale from "../../NavBar/NavBarHorisontale/NavBarHorisontale";
import {
  AddPrepToProduct,
  GetAllIngredientVisitorMode,
  AddMatiereToPrep,
} from "../../../Data/firebaseInteractionVisitorsV2";
import SearchBarPrep from "../../VisiteurComponents/SearchBarPrep/SearchBarPrep";
import SearchBarMatiere from "../../VisiteurComponents/SearchBarMatiere/SearchBarMatiere";
import AddIngredient from "../Button/AddIngredient/AddIngredient";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import AddNewFournisseur from "../../VisiteurComponents/Button/AddNewFournisseur/AddNewFournisseur";
import AddMatiere from "../../VisiteurComponents/Button/AddMatiere/AddMatiere";

import { GetAllPrepToUseInProduct } from "../../../Data/firebaseInteractionVisitorsV2";

const BottomPageVisitorV2 = ({
  ProductSelected,
  navRowDisplayProdandPrep,
  PrepSelected,
  PrepSelectedForChangeMatiere,
}) => {
  const [DataDisplay, setDataDisplay] = useState(null);
  const [DataDisplayMatiere, setDataDisplayMatiere] = useState(null);
  const [result, setResult] = useState(null);
  const [resultMatiere, setResultMatiere] = useState(null);

  const [preparations] = useCollection(
    collection(getFirestore(app), "preparations"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [ProductionVisitorV2] = useCollection(
    collection(getFirestore(app), "ProductionVisitorV2"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const Data = await GetAllPrepToUseInProduct();
      setDataDisplay(Data);
      const ListeMatireForPreparation = await GetAllIngredientVisitorMode();
      setDataDisplayMatiere(ListeMatireForPreparation);
    }
    fetchOrders();
  }, [ProductionVisitorV2, preparations]);

  return (
    <>
      {/*<NavBarHorisontale
        Data={["Matiere", "Prep", "Mes Prep"]}
        setNavRow={setNavRow}
        navRow={navRow}
  />*/}
      {navRowDisplayProdandPrep === 0 && (
        <div className="ContenerBottomPage">
          <div className="RechecheBox">
            <SearchBarPrep
              UseEffectFunction={GetAllPrepToUseInProduct}
              setResult={setResult}
            />
            <div className="ContenerSelectionCategories">
              <div className="CategorieContener">Fruits</div>
              <div className="CategorieContener">Legume</div>
              <div className="CategorieContener">viande</div>
              <div className="CategorieContener">Cat4</div>
              <div
                className="CategorieContener"
                onClick={() => setResult(null)}
              >
                All
              </div>
            </div>
          </div>
          {result
            ? result.map((prep) => {
                return (
                  <div className="Box">
                    <div
                      style={{
                        display: "flex",
                        backgroundSize: "cover",
                        backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                        height: "150px",
                        width: "150px",
                      }}
                      onClick={() => AddPrepToProduct(ProductSelected, prep)}
                    >
                      {" "}
                      <img
                        style={{ height: "150px", width: "150px" }}
                        src={prep.img}
                        alt=""
                      />
                    </div>
                    <div className="NomPrep">{prep.name}</div>
                  </div>
                );
              })
            : DataDisplay &&
              DataDisplay.map((prep) => (
                <div className="Box">
                  <div
                    style={{
                      display: "flex",
                      backgroundSize: "cover",
                      backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                      height: "150px",
                      width: "150px",
                    }}
                    onClick={() => AddPrepToProduct(ProductSelected, prep)}
                  >
                    {" "}
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src={prep.img}
                      alt=""
                    />
                  </div>
                  <div className="NomPrep">{prep.name}</div>
                </div>
              ))}
        </div>
      )}

      {navRowDisplayProdandPrep === 1 && (
        <div className="ContenerBottomPage">
          <div className="RechecheBox">
            <SearchBarMatiere
              UseEffectFunction={GetAllIngredientVisitorMode}
              setResult={setResultMatiere}
            />
            <div className="ContenerSelectionCategories">
              {/*<div className="CategorieContener">
                <AddIngredient />
              </div>
              <div className="CategorieContener">
                <AddMatiere />
              </div>
              <div className="CategorieContener">
                <AddNewFournisseur />
      </div>*/}
              <div
                className="CategorieContener"
                onClick={() => setResultMatiere(null)}
              >
                All
              </div>
            </div>
          </div>

          <div className="RechecheBox">
            <div className="ContenerSelectionCategories">
              <div className="CategorieContener">
                <AddIngredient />
              </div>
              <div className="CategorieContener">
                <AddMatiere />
              </div>
              <div className="CategorieContener">
                <AddNewFournisseur />
              </div>
            </div>
          </div>

          {resultMatiere
            ? resultMatiere.map((Matiere) => {
                return (
                  <div className="Box">
                    <div
                      style={{
                        display: "flex",
                        backgroundSize: "cover",
                        backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                        height: "150px",
                        width: "150px",
                      }}
                      onClick={() =>
                        AddMatiereToPrep(Matiere, PrepSelectedForChangeMatiere)
                      }
                    >
                      {" "}
                      <img
                        style={{ height: "150px", width: "150px" }}
                        src={Matiere.Img}
                        alt=""
                      />
                    </div>
                    <div className="NomPrep">{Matiere.name}</div>
                  </div>
                );
              })
            : DataDisplayMatiere &&
              DataDisplayMatiere.map((Matiere) => (
                <div className="Box">
                  <div
                    style={{
                      display: "flex",
                      backgroundSize: "cover",
                      backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                      height: "150px",
                      width: "150px",
                    }}
                    onClick={() =>
                      AddMatiereToPrep(Matiere, PrepSelectedForChangeMatiere)
                    }
                  >
                    {" "}
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src={Matiere.Img}
                      alt=""
                    />
                  </div>
                  <div className="NomPrep">{Matiere.name}</div>
                </div>
              ))}
        </div>
      )}

      {navRowDisplayProdandPrep === 2 && (
        <div className="ContenerBottomPage">
          <div className="RechecheBox">
            <SearchBarMatiere
              UseEffectFunction={GetAllIngredientVisitorMode}
              setResult={setResultMatiere}
            />
            <div className="ContenerSelectionCategories">
              {/*<div className="CategorieContener">
                <AddIngredient />
              </div>
              <div className="CategorieContener">
                <AddMatiere />
              </div>
              <div className="CategorieContener">
                <AddNewFournisseur />
      </div>*/}
              <div
                className="CategorieContener"
                onClick={() => setResultMatiere(null)}
              >
                All
              </div>
            </div>
          </div>

          <div className="RechecheBox">
            <div className="ContenerSelectionCategories">
              <div className="CategorieContener">
                <AddIngredient />
              </div>
              <div className="CategorieContener">
                <AddMatiere />
              </div>
              <div className="CategorieContener">
                <AddNewFournisseur />
              </div>
            </div>
          </div>

          {resultMatiere
            ? resultMatiere.map((Matiere) => {
                return (
                  <div className="Box">
                    <div
                      style={{
                        display: "flex",
                        backgroundSize: "cover",
                        backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                        height: "150px",
                        width: "150px",
                      }}
                      onClick={() =>
                        AddMatiereToPrep(Matiere, PrepSelectedForChangeMatiere)
                      }
                    >
                      {" "}
                      <img
                        style={{ height: "150px", width: "150px" }}
                        src={Matiere.Img}
                        alt=""
                      />
                    </div>
                    <div className="NomPrep">{Matiere.name}</div>
                  </div>
                );
              })
            : DataDisplayMatiere &&
              DataDisplayMatiere.map((Matiere) => (
                <div className="Box">
                  <div
                    style={{
                      display: "flex",
                      backgroundSize: "cover",
                      backgroundPosition: "center", // Ajout de cette ligne pour centrer l'image de fond
                      height: "150px",
                      width: "150px",
                    }}
                    onClick={() =>
                      AddMatiereToPrep(Matiere, PrepSelectedForChangeMatiere)
                    }
                  >
                    {" "}
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src={Matiere.Img}
                      alt=""
                    />
                  </div>
                  <div className="NomPrep">{Matiere.name}</div>
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default BottomPageVisitorV2;
