import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { HandleJetterPrep } from "../../../Functions/HandleClickFunctions";

export default function ButtonJeterPrep({ PrepData }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleInteract = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);
    try {
      await HandleJetterPrep(PrepData);
      setIsProcessing(false);
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleInteract()}
        disabled={isProcessing} // Désactivez le bouton pendant le traitement
      >
        {isProcessing ? (
          <CircularProgress size={24} color="secondary" /> // Affichez un indicateur de chargement si isProcessing est vrai
        ) : (
          "Jeter" // Affichez le texte du bouton si isProcessing est faux
        )}
      </Button>
    </>
  );
}
