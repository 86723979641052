import React, { useState, useEffect } from "react";
import MainDashOwnerKitchenSettings from "../../components/MainDash/MainDashOwnerKitchenSettings/MainDashOwnerKitchenSettings";
import RightSideOwnerKitchenSettings from "../../components/RightSide/RightSideOwnerKitchenSettings/RightSideOwnerKitchenSettings";

const KitchenSettings = () => {
  useEffect(() => {}, []);

  return (
    <>
      <MainDashOwnerKitchenSettings />
      <RightSideOwnerKitchenSettings />
    </>
  );
};

export default KitchenSettings;
