import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography ,
} from "@mui/material";


export default function ModalOrderTable({ open, handleClose, order }) {
    
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Order {order.dataOrder.order.id}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Total: {order.dataOrder.order.price.final_amount_exc_tax/100} € 
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Items:
        </Typography>
        {order.dataOrder.order.items.map((item) => (
          <Typography key={item.id} id="modal-modal-description" sx={{ mt: 2 }}>
            - {item.name} : {item.price.base_original_amount_inc_tax/100} €
            {item.modifiers.length != 0 && item.modifiers.map((sup)=>(<div>-----{sup.name}</div>))}  
          </Typography>
        ))}
      </Box>
    </Modal>
  );
}