import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchBarPreparationSetListePreparations from "../../SearchBar/SearchBarPreparationSetListePreparations/SearchBarPreparationSetListePreparations";
import {
  CreateNewWorkplace,
  HandleCreateWorplaceWithListOfProduct,
} from "../../../Functions/HandleClickFunctions";
import {
  getMachineDisponible,
  getAllDish,
} from "../../../Data/firebaseService";
import "./ModalCreateWorkplanWithListOfProduct.css";

export default function ModalCreateWorkplanWithListOfProduct({
  open,
  handleClose,
}) {
  const [WorkPlanName, setWorkPlanName] = useState("");
  const [MachineInTheWorkPlace, setMachineInTheWorkPlace] = useState([]);
  const [ListePreparations, setListePreparations] = useState([]);
  const [ListeAllProduct, setListeAllProduct] = useState([]);
  const [ProductIntheGroup, setProductIntheGroup] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      const AllDish = await getAllDish();
      setListeAllProduct(AllDish);
    }

    fetchOrders();
  }, []);

  const HandelSupPrep = (NomPrep) => {
    setListePreparations((PrevListPrep) => {
      return PrevListPrep.filter(
        (Prep) => Prep.DataPreparation.Nom !== NomPrep
      );
    });
  };
  const HandelSupProd = (NomProd) => {
    setProductIntheGroup((PrevListProd) => {
      return PrevListProd.filter((Prod) => Prod !== NomProd);
    });
  };

  const HandleGenerateWorkplaceData = async () => {
    async function fetchMachine() {
      const NewWorkPlaceData = await HandleCreateWorplaceWithListOfProduct(
        WorkPlanName,
        ProductIntheGroup
      );
      setMachineInTheWorkPlace(NewWorkPlaceData.machine);
      setListePreparations(NewWorkPlaceData.Prep);
    }

    await fetchMachine();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        ></Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom du plan de travail"
            variant="outlined"
            value={WorkPlanName}
            onChange={(e) => setWorkPlanName(e.target.value)}
          />
        </Typography>
        <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
          <InputLabel id="select-unite-Preparation">Product</InputLabel>
          <Select
            labelId="select-unite-label-Type-Preparation"
            id="select-unite-Type-Preparation"
            value={ProductIntheGroup[ProductIntheGroup.length]}
            onChange={(e) =>
              setProductIntheGroup((prev) => [...prev, e.target.value])
            }
          >
            {ListeAllProduct.map((product) => {
              return (
                <MenuItem value={product.Production.name}>
                  {product.Production.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {ProductIntheGroup.map((Prod) => {
          return (
            <div>
              {Prod}
              <span
                onClick={() => HandelSupProd(Prod)}
                className="SupprimerPrep"
              >
                ___Supprimer
              </span>
            </div>
          );
        })}
        <button onClick={() => HandleGenerateWorkplaceData()}>
          Generer le WP
        </button>

        {MachineInTheWorkPlace.map((machinneSelected) => (
          <div>{machinneSelected}</div>
        ))}
        <SearchBarPreparationSetListePreparations
          setListePreparation={setListePreparations}
        />
        {ListePreparations.map((prep) => {
          return (
            <div>
              {prep.DataPreparation.Nom}
              <span
                onClick={() => HandelSupPrep(prep.DataPreparation.Nom)}
                className="SupprimerPrep"
              >
                ___Supprimer
              </span>
            </div>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            CreateNewWorkplace(
              WorkPlanName,
              MachineInTheWorkPlace,
              ListePreparations
            );
            handleClose();
          }}
        >
          Ajouter
        </Button>
      </Box>
    </Modal>
  );
}
