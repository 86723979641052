import React from "react";
import RefairePrepAuRestaurant from "../../components/ManagerV2Component/RefairePrepAuRestaurant/RefairePrepAuRestaurant";
import DisplayCommandeMatiereEnLivraison from "../../components/ManagerV2Component/DisplayCommandeMatiereEnLivraison/DisplayCommandeMatiereEnLivraison";

const PageRefairePreparation = ({ RestaurantName }) => {
  return (
    <>
      <RefairePrepAuRestaurant RestaurantName={RestaurantName} />
      <DisplayCommandeMatiereEnLivraison RestaurantName={RestaurantName} />
    </>
  );
};

export default PageRefairePreparation;
