import React, { useState, useEffect } from "react";
import "./TableAnalyseIngredientConssomation.css";
import DisplayPrepWhereIngredientIsUsed from "../../../../../../../../components/OwnerV2Components/Button/DisplayPrepWhereIngredientIsUsed/DisplayPrepWhereIngredientIsUsed";

import { getDailyConssomationIngredient } from "../../../../../../../../Data/firebaseInteractionOwnerV2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";

const TableAnalyseIngredientConssomation = ({
  RestaurantName,
  dateStart,
  dateEnd,
}) => {
  const [DataConsso, setDataConsso] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const DailyConssoPrep = await getDailyConssomationIngredient(
        RestaurantName,
        dateStart,
        dateEnd
      );
      setDataConsso(DailyConssoPrep);
    }
    fetchData();
  }, []);

  return (
    <div className="ContenerTableAnalysePrepConssomation">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Ingredient</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Qte vendu</TableCell>
              <TableCell align="left">Unite De Recette</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {DataConsso ? (
              DataConsso.map((Ingredient) => {
                return (
                  <TableRow
                    key={Ingredient.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {Ingredient.name}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Avatar alt={Ingredient.name} src={Ingredient.Img} />
                    </TableCell>
                    <TableCell align="left">{Ingredient.QteConssome}</TableCell>
                    <TableCell align="left">
                      {Ingredient.UniteDeRecette}
                    </TableCell>
                    <TableCell align="left">
                      <DisplayPrepWhereIngredientIsUsed
                        PrepID={Ingredient.IDPepers}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <CircularProgress size={24} color="secondary" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableAnalyseIngredientConssomation;
