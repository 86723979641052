import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./ListeUserBrand.css";

import { getListAllUserBrand } from "../../../Data/firebaseService";

const ListeUserBrand = ({ SelectedBrand, setSelectedBrand }) => {
  const [data, setData] = useState(["Loading"]);

  useEffect(() => {
    async function fetchData() {
      const data = await getListAllUserBrand();
      setData(data);
    }
    fetchData();
  }, []);
  return (
    <div className="Updates">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Facturation: {} €</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return SelectedBrand === row ? (
                <div
                  className="BrandNameSelected"
                  onClick={() => setSelectedBrand(row)}
                >
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row}
                    </TableCell>
                  </TableRow>
                </div>
              ) : (
                <div onClick={() => setSelectedBrand(row)}>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row}
                    </TableCell>
                  </TableRow>
                </div>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ListeUserBrand;
