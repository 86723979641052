export function formatDateISO(date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const milliseconds = ('00' + date.getMilliseconds()).slice(-3);
    const timezoneOffset = ('00' + Math.abs(date.getTimezoneOffset() / 60)).slice(-2);
    const timezoneSign = date.getTimezoneOffset() > 0 ? '-' : '+';
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneOffset}:00`;
  }

  export function formatDateReadable(date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const milliseconds = ('00' + date.getMilliseconds()).slice(-3);
    const timezoneOffset = ('00' + Math.abs(date.getTimezoneOffset() / 60)).slice(-2);
    const timezoneSign = date.getTimezoneOffset() > 0 ? '-' : '+';
  
    return `${year}-${month}-${day}`;
  }