import React, { useState, useEffect } from "react";
import "./BigSelectorDishAndPrep.css";
import {
  getAllDish,
  getAllPrep,
} from "../../../Data/firebaseInteractionOwnerV2";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import NavBarHorisontale from "../../../components/NavBar/NavBarHorisontale/NavBarHorisontale";

const BigSelectorDishAndPrep = ({
  SelectedDish,
  SetSelectedDish,
  SelectedPrep,
  setSelectedPrep,
}) => {
  const [DataDish, setDataDish] = useState(null);
  const [DataPrep, setDataPrep] = useState(null);
  const [navRow, setNavRow] = useState(0);

  const [dishes] = useCollection(collection(getFirestore(app), "dishes"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      const DataDish = await getAllDish();
      setDataDish(DataDish);
      const DataPrep = await getAllPrep();
      setDataPrep(DataPrep);
    }
    fetchOrders();
  }, [dishes]);

  useEffect(() => {
    setSelectedPrep(null);
    SetSelectedDish(null);
  }, [navRow]);

  return (
    <div className="ContenerSelectorDish">
      <NavBarHorisontale
        Data={["Produit", "Preparation"]}
        setNavRow={setNavRow}
        navRow={navRow}
      />
      {navRow === 0 &&
        DataDish &&
        DataDish.map((item, index) => (
          <div
            className={
              SelectedDish &&
              item.Production.IDPepers === SelectedDish.Production.IDPepers
                ? "RowSelectorDishSelected"
                : "RowSelectorDish"
            }
            onClick={() => SetSelectedDish(item)}
          >
            {item.Production.name}
          </div>
        ))}

      {navRow === 1 &&
        DataPrep &&
        DataPrep.map((item, index) => (
          <div
            className={
              SelectedPrep && item.IDPepers === SelectedPrep.IDPepers
                ? "RowSelectorDishSelected"
                : "RowSelectorDish"
            }
            onClick={() => setSelectedPrep(item)}
          >
            {item.name}
          </div>
        ))}
    </div>
  );
};

export default BigSelectorDishAndPrep;
