import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import {
  getPrepData,
  CalculQteMatiereForOnePrepConditionementLogistic,
} from "../../../Data/firebaseService";
import { UpdatePrep } from "../../../Functions/HandleClickFunctions";
import SearchBarMatierePremiere from "../../../components/SearchBar/SearchBarMatierePremiere/SearchBarMatierePremiere";
import AddNewConditionnementLogistiqueV2 from "../../../components/Button/AddNewConditionnementLogistiqueV2/AddNewConditionnementLogistiqueV2";
import "./ModalModificationPrep.css";
import SetIDPepersToProduction from "../../Button/SetIDPepersToProduction/SetIDPepersToProduction";

export default function ModalModificationPrep({ open, handleClose, prep }) {
  const [AllDataPrep, setAllDataPrep] = useState(null);
  const [QteMatiereForConditionnement, setQteMatiereForConditionnement] =
    useState(null);
  const [ListeMatierePremiereData, setListeMatierePremiereData] = useState([]);
  const [
    SelectedConditionnementLigistique,
    setSelectedConditionnementLigistique,
  ] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      const PrepData = await getPrepData(prep.Nom);
      setAllDataPrep(PrepData);

      let MatiereForConditionnement = {};
      if (SelectedConditionnementLigistique !== null) {
        MatiereForConditionnement =
          await CalculQteMatiereForOnePrepConditionementLogistic(
            AllDataPrep.IDPErpers,
            SelectedConditionnementLigistique.IDPepers
          );
        setQteMatiereForConditionnement(MatiereForConditionnement);
      }
    }
    fetchOrders();
  }, [SelectedConditionnementLigistique]);

  const handleChangeRecette = (e) => {
    setAllDataPrep((oldData) => {
      return {
        ...oldData,
        Recette: e.target.value,
      };
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="container">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%", // Ajustez cette valeur selon vos besoins
            maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="box">
            <img src={prep.PhotoFirebase} style={{ width: "300px" }}></img>
          </div>
          {AllDataPrep !== null && (
            <TextField
              label="Nom de la preparation"
              variant="outlined"
              value={AllDataPrep.Nom}
              onChange={(e) =>
                setAllDataPrep((oldData) => ({
                  ...oldData,
                  Nom: e.target.value,
                }))
              }
            />
          )}
          <TextField
            multiline
            rows={4} // Vous pouvez ajuster le nombre de lignes selon vos préférences
            variant="outlined"
            fullWidth
            placeholder="Saisissez votre description ici..."
            value={
              AllDataPrep && AllDataPrep.hasOwnProperty("Recette")
                ? AllDataPrep.Recette
                : ""
            }
            onChange={(e) => handleChangeRecette(e)}
          />
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">
              Choix conditionnement logistique
            </InputLabel>
            <AddNewConditionnementLogistiqueV2 prep={prep} />
            <Select
              labelId="select-Conditionnement"
              id="select-Conditionnement"
              value={""}
              onChange={(e) =>
                setSelectedConditionnementLigistique(e.target.value)
              }
            >
              {AllDataPrep &&
                AllDataPrep.hasOwnProperty("ConditionnementLogistique") &&
                AllDataPrep.ConditionnementLogistique.map((Conditionnement) => {
                  return (
                    <MenuItem value={Conditionnement}>
                      {Conditionnement.ConditionnementName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {SelectedConditionnementLigistique && (
            <div className="NameConditionnement">
              {SelectedConditionnementLigistique.ConditionnementName}
            </div>
          )}

          {QteMatiereForConditionnement &&
            QteMatiereForConditionnement.NomMatiereAvecQte.map((Matiere) => {
              return (
                <div className="contenerMateiereContition">
                  <span>{Matiere.Name}</span>
                  <span className="QteMatiereConditionnement">
                    {Matiere.Qte}
                  </span>
                  <span>{Matiere.UniteMesure}</span>
                </div>
              );
            })}

          {SelectedConditionnementLigistique && (
            <TextField
              style={{ width: "60px" }}
              label="Qte"
              variant="outlined"
              value={
                SelectedConditionnementLigistique.QteConditionnementLogistic
              }
              onChange={(e) => e}
            />
          )}

          {/*<SearchBarMatierePremiere
            setListeMatierePremiere={setListeMatierePremiere}
          />
          ---------- LISTE MATIERE ----------
          {ListeMatierePremiere.map((matiere, index) => {
            return (
              <div>
                {matiere.DataMatierePremiere.Nom}
                <TextField
                  style={{ width: "60px" }}
                  label="Qte"
                  variant="outlined"
                  value={matiere.Qte}
                  onChange={(e) => HandleChangeQteMatiere(e, index)}
                />
              </div>
            );
          })}*/}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              UpdatePrep(AllDataPrep, prep);
              handleClose();
            }}
          >
            Modifier
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
