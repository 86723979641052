import React, { useState } from "react";

const HeaderModuleDeCommande = () => {
  return (
    <div style={{ minWidth: "800px" }}>
      <img
        src="/img/KitchnClikLogo.png"
        style={{ maxWidth: "200px", padding: "15px" }}
      />
    </div>
  );
};

export default HeaderModuleDeCommande;
