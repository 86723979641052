import React, { useEffect } from "react";
import Header from "../../components/MarketingComponents/Header/Header";
import MainDashMarketingPage from "../../components/MarketingComponents/MainDashMarketingPage/MainDashMarketingPage";

const Page1 = () => {
  return (
    <>
      <Header />
      <div>
        <MainDashMarketingPage />
      </div>
    </>
  );
};

export default Page1;
