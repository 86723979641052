import React, { useState, useEffect } from "react";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import TextField from "@material-ui/core/TextField";
import {
  getAllActiveDishInrestaurant,
  HandleCreatePlanDeTravailWithSelectedProduct,
} from "../../../../Data/firebaseInteractionOwnerV2";

const SetPlanDeTravailWithProductYouWantDo = ({ RestaurantName }) => {
  const [openModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    RestaurantName: RestaurantName,
  });
  const [allDish, setAllDish] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const Data = await getAllActiveDishInrestaurant(RestaurantName);
      setAllDish(Data);
    };
    fetchData();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={handleOpenModal}>
        Set plan avec les produits
      </div>
      <ModalAvecFunction
        open={openModal}
        handleClose={handleCloseModal}
        Form={
          <div>
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }))
              }
              label="Nom du plan de travail"
            />
            {allDish &&
              allDish.map((Dish) => {
                return (
                  <div
                    style={
                      DataForm.hasOwnProperty("ListeDish") &&
                      DataForm.ListeDish.includes(Dish.Production.IDPepers)
                        ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            boxShadow: "0px 0px 10px 0px #c68787",
                          }
                        : {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            boxShadow: "0px 0px 10px 0px #000000",
                          }
                    }
                    onClick={() =>
                      setDataForm((prev) => {
                        let newListeDish = [];
                        if (prev.hasOwnProperty("ListeDish")) {
                          if (
                            !prev.ListeDish.includes(Dish.Production.IDPepers)
                          ) {
                            newListeDish = [
                              ...prev.ListeDish,
                              Dish.Production.IDPepers,
                            ];
                          } else {
                            newListeDish = prev.ListeDish.filter(
                              (id) => id !== Dish.Production.IDPepers
                            );
                          }
                        } else {
                          newListeDish = [Dish.Production.IDPepers];
                        }
                        return { ...prev, ListeDish: newListeDish };
                      })
                    }
                  >
                    {Dish.Production.name}
                  </div>
                );
              })}
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter un nouveau restaurant"}
        DataForm={{ ...DataForm }}
        Function={HandleCreatePlanDeTravailWithSelectedProduct}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default SetPlanDeTravailWithProductYouWantDo;
