import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import LogIn from "./LogIn/LogIn";

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';

const Registration = () => {
  const [user, loading, error] = useAuthState(auth);
  const logout = () => {
      signOut(auth)
      window.location.reload();
};
  
  return (
    <>
      {user ? (<Button onClick={logout}>Log Out</Button>):(<LogIn/>)}
    </>
  );
};

export default Registration;
