import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import "./ModalPreparationSelection.css";
import { ChangeProductPreparationQte } from "../../../Functions/HandleClickFunctions";

export default function ModalPreparationSelection({
  open,
  handleClose,
  preparation,
  product,
}) {
  const [QtePreparation, setQtePreparation] = useState(preparation.Qte);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Preparation {preparation.Nom}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <img
            className="img-modal-Preparation"
            src={preparation.PhotoFirebase}
            alt="Nom de l'image"
          />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          QTE : {preparation.Qte} {preparation.Unite}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
            label="Input"
            variant="outlined"
            value={QtePreparation}
            onChange={(e) => setQtePreparation(e.target.value)}
          />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              ChangeProductPreparationQte(
                preparation.Nom,
                product,
                QtePreparation
              );
              handleClose();
            }}
          >
            Changer
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}
