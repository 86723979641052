import React, { useEffect, useState } from "react";
import "./NavBarTwoChoice.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const NavBarTwoChoice = ({ Data, setNavRow, navRow }) => {
  return (
    <div className="NavBarTwoChoice">
      {Data.map((row, index) => (
        <div
          className={
            navRow === index ? "navRowTwoChoiceSelected" : "navRowTwoChoice"
          }
          onClick={() => setNavRow(index)}
        >
          {row}
        </div>
      ))}
    </div>
  );
};
export default NavBarTwoChoice;
