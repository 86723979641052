import React, { useState, useEffect } from "react";
import "./DisplayFicheTechniquePreparation.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import {
  getMatiereDataForPreparationSelected,
  SupprimerMatiereInPrep,
} from "../../../Data/firebaseInteractionVisitorsV2";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";
import { Modal } from "@mui/material";
import ModalAvecFunction from "../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { ModifyQteMatiereInPrep } from "../../../Data/firebaseInteractionVisitorsV2";
import { max } from "date-fns";

const DisplayFicheTechniquePreparation = ({ PrepSelectedForChangeMatiere }) => {
  const [MatiereInSelectedPreparation, setMatiereInSelectedPreparation] =
    useState(null);
  const [MatiereSelected, setMatiereSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [DataForm, setDataForm] = useState({
    PrepSelected: PrepSelectedForChangeMatiere,
    MatiereSelected: MatiereSelected,
  });

  const [preparations] = useCollection(
    collection(getFirestore(app), "preparations"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      let MatiereInSelectedPrep = null;
      if (PrepSelectedForChangeMatiere) {
        MatiereInSelectedPrep = await getMatiereDataForPreparationSelected(
          PrepSelectedForChangeMatiere
        );
      }
      setMatiereInSelectedPreparation(MatiereInSelectedPrep);
    }
    fetchOrders();
  }, [PrepSelectedForChangeMatiere, preparations]);

  const handleOpen = (Matiere) => {
    setMatiereSelected(Matiere);
    setDataForm((prevData) => ({
      ...prevData,
      MatiereSelected: Matiere,
      QteMatiereDansPrep: Matiere.QteInThePrep,
      PrepSelected: PrepSelectedForChangeMatiere,
    }));
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setMatiereSelected(null);
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const HandleSupprimerMatiereInPrep = (
    Matiere,
    PrepSelectedForChangeMatiere
  ) => {
    if (isProcessing) return;
    setIsProcessing(true);
    SupprimerMatiereInPrep(Matiere, PrepSelectedForChangeMatiere);
    setIsProcessing(false);
  };

  return (
    <div className="ContenerDisplayFiche">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Matiere</TableCell>
              <TableCell align="left">Unité de recette</TableCell>
              <TableCell align="left">Qte</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {MatiereInSelectedPreparation &&
              MatiereInSelectedPreparation.map((Matiere) => (
                <TableRow
                  key={Matiere.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {Matiere.name}
                  </TableCell>
                  <TableCell align="left">{Matiere.UniteDeRecette}</TableCell>
                  <TableCell align="left">
                    {Matiere.QteInThePrep}{" "}
                    <span
                      className="Details"
                      onClick={() => handleOpen(Matiere)}
                    >
                      Mofidier Qte
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Avatar alt={Matiere.name} src={Matiere.Img} />
                  </TableCell>

                  <TableCell
                    align="left"
                    className="Details"
                    onClick={() =>
                      HandleSupprimerMatiereInPrep(
                        Matiere,
                        PrepSelectedForChangeMatiere
                      )
                    }
                  >
                    Supprimer
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {MatiereSelected && (
        <ModalAvecFunction
          open={isOpen}
          handleClose={handleClose}
          Form={
            <div>
              <TextField
                id="outlined-basic"
                style={{ width: "50px" }}
                value={DataForm.QteMatiereDansPrep}
                onChange={(event) =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    QteMatiereDansPrep: event.target.value,
                  }))
                }
              />
              {MatiereSelected.UniteDeRecette}
            </div>
          }
          TextActionButton={"Modifier la Qte"}
          TitleModal={`Modifier la qte de : ${MatiereSelected.name}`}
          SousParagraphe={
            <div>
              <div></div>
              <div>
                <img
                  style={{ maxWidth: "300px" }}
                  src={MatiereSelected.Img}
                ></img>
              </div>
            </div>
          }
          DataForm={DataForm}
          setDataForm={setDataForm}
          Function={ModifyQteMatiereInPrep}
        />
      )}
    </div>
  );
};

export default DisplayFicheTechniquePreparation;
