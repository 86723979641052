import React, { useState, useEffect } from "react";
import "./TableAnalysePrepConssomation.css";
import DisplayProductWhereThePrepIsUsed from "../../../../../../../../components/OwnerV2Components/Button/DisplayProductWhereThePrepIsUsed/DisplayProductWhereThePrepIsUsed";

import { getDailyConssomationPrep } from "../../../../../../../../Data/firebaseInteractionOwnerV2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";

const TableAnalysePrepConssomation = ({
  RestaurantName,
  dateStart,
  dateEnd,
}) => {
  const [DataConsso, setDataConsso] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const DailyConssoPrep = await getDailyConssomationPrep(
        RestaurantName,
        dateStart,
        dateEnd
      );
      setDataConsso(DailyConssoPrep);
    }
    fetchData();
  }, []);

  return (
    <div className="ContenerTableAnalysePrepConssomation">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Prep</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Qte vendu</TableCell>
              <TableCell align="left">Unite De Mesure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {DataConsso ? (
              DataConsso.map((Prep) => {
                return (
                  <TableRow
                    key={Prep.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {Prep.name}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Avatar alt={Prep.name} src={Prep.img} />
                    </TableCell>
                    <TableCell align="left">{Prep.QteVendu}</TableCell>
                    <TableCell align="left">{Prep.UniteDeMesure}</TableCell>
                    <TableCell align="left">
                      <DisplayProductWhereThePrepIsUsed
                        PrepID={Prep.IDPepers}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <CircularProgress size={24} color="secondary" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableAnalysePrepConssomation;
