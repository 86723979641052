import React, { useState } from "react";
import "./Production.css";
import NavBarHorisontale from "../../../components/NavBar/NavBarHorisontale/NavBarHorisontale";
import GestionMatieresAndIngredients from "../../../components/OwnerV2Components/GestionMatieresAndIngredients/GestionMatieresAndIngredients";
import GestionDishAndPrep from "../../../components/OwnerV2Components/GestionDishAndPrep/GestionDishAndPrep";
import AddOption from "../../../components/OwnerV2Components/OptionManagement/AddOption/AddOption";
import OptionManagement from "../../../components/OwnerV2Components/OptionManagement/OptionManagement";

const Production = () => {
  const [navRow, setNavRow] = useState(0);

  return (
    <>
      <div className="ProductionPageContener">
        <NavBarHorisontale
          Data={["Matiere / Ingredient", "Fournisseur", "Add option", "Dish"]}
          setNavRow={setNavRow}
          navRow={navRow}
        />
        {navRow === 0 && <GestionMatieresAndIngredients />}
        {navRow === 2 && <OptionManagement />}
        {navRow === 3 && <GestionDishAndPrep />}
      </div>
    </>
  );
};

export default Production;
