import React, { useEffect, useState } from "react";
import { DisplayProductToDoInTheWorkplace } from "../../../Data/firebaseInteractionCuisinierV2";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import { CircularProgress } from "@mui/material";
import {
  OpenOrderTodo,
  CheckIfTheIsChangeInTheProductToDo,
} from "../../../Data/firebaseInteractionCuisinierV2";
import { set } from "date-fns";
import ProductCard from "./ProductCard/ProductCard";

const AlocateDishToPlanDeTravail = ({ Workplace, RestaurantName }) => {
  const [DisplayProductToDo, setDisplayProductToDo] = useState(null);

  const [OrdersEnCours] = useCollection(
    collection(getFirestore(app), `${RestaurantName}OrdersEnCours`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [RestoWorkplace] = useCollection(
    collection(getFirestore(app), `${RestaurantName}PlanDeTravail`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const NewProductToDo = await DisplayProductToDoInTheWorkplace(
        RestaurantName,
        Workplace
      );

      let Ckeck = CheckIfTheIsChangeInTheProductToDo(
        DisplayProductToDo,
        NewProductToDo
      );

      if (!Ckeck) {
        setDisplayProductToDo(null);
        setDisplayProductToDo(NewProductToDo);
      }
    }
    fetchOrders();
  }, [OrdersEnCours]);
  return (
    <div style={{ maxHeight: "90vh", overflowY: "auto" }}>
      {DisplayProductToDo ? (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {DisplayProductToDo.map((productToDo) => {
            return (
              <ProductCard
                productToDo={productToDo}
                setDisplayProductToDo={setDisplayProductToDo}
                Workplace={Workplace}
                RestaurantName={RestaurantName}
              />
            );
          })}
          {/*<div onClick={() => OpenOrderTodo()}>ALOCATE</div>*/}
        </div>
      ) : (
        <CircularProgress size={24} color="secondary" />
      )}
    </div>
  );
};

export default AlocateDishToPlanDeTravail;
