import React, { useState, useEffect } from "react";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import TextField from "@material-ui/core/TextField";
import { HandleCreatePlanDeTravail } from "../../../../Data/firebaseInteractionOwnerV2";

const AddPlanDeTravail = ({ RestaurantName }) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({ RestaurantName: RestaurantName });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        ADD PLAN DE TRAVAIL
      </div>
      <ModalAvecFunction
        open={OpenModal}
        handleClose={handleCloseModal}
        Form={
          <div>
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }))
              }
              label="Nom du restaurant"
            />
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter un nouveau restaurant"}
        DataForm={{ ...DataForm }}
        Function={HandleCreatePlanDeTravail}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default AddPlanDeTravail;
