import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchBarPreparationSetListePreparations from "../../SearchBar/SearchBarPreparationSetListePreparations/SearchBarPreparationSetListePreparations";
import { CreateNewWorkplace } from "../../../Functions/HandleClickFunctions";
import { getMachineDisponible } from "../../../Data/firebaseService";
import "./ModalCreateWorkplan.css";

export default function ModalBankTransaction({ open, handleClose }) {
  const [selectedValue, setSelectedValue] = useState("");
  const [WorkPlanName, setWorkPlanName] = useState("");
  const [MachineInTheWorkPlace, setMachineInTheWorkPlace] = useState([]);
  const [ListePreparations, setListePreparations] = useState([]);

  const [ListeAllMachine, setListeAllMachine] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      const MachineDisponible = await getMachineDisponible();
      setListeAllMachine(MachineDisponible);
    }

    fetchOrders();
  }, []);

  const HandelSupPrep = (NomPrep) => {
    setListePreparations((PrevListPrep) => {
      return PrevListPrep.filter(
        (Prep) => Prep.DataPreparation.Nom !== NomPrep
      );
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        ></Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom du plan de travail"
            variant="outlined"
            value={WorkPlanName}
            onChange={(e) => setWorkPlanName(e.target.value)}
          />
        </Typography>
        <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
          <InputLabel id="select-unite-Preparation">
            Machine du poste
          </InputLabel>
          <Select
            labelId="select-unite-label-Type-Preparation"
            id="select-unite-Type-Preparation"
            value={MachineInTheWorkPlace[MachineInTheWorkPlace.length]}
            onChange={(e) =>
              setMachineInTheWorkPlace((prev) => [...prev, e.target.value])
            }
          >
            {ListeAllMachine.map((machine) => {
              return <MenuItem value={machine}>{machine}</MenuItem>;
            })}
          </Select>
        </FormControl>
        {MachineInTheWorkPlace.map((machinneSelected) => (
          <div>{machinneSelected}</div>
        ))}
        <SearchBarPreparationSetListePreparations
          setListePreparation={setListePreparations}
        />
        {ListePreparations.map((prep) => {
          return (
            <div>
              {prep.DataPreparation.Nom}
              <span
                onClick={() => HandelSupPrep(prep.DataPreparation.Nom)}
                className="SupprimerPrep"
              >
                ___Supprimer
              </span>
            </div>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            CreateNewWorkplace(
              WorkPlanName,
              MachineInTheWorkPlace,
              ListePreparations
            );
            handleClose();
          }}
        >
          Ajouter
        </Button>
      </Box>
    </Modal>
  );
}
