import React, { useState } from "react";
import SelectorPlanDeTravail from "./SelectorPlanDeTravail/SelectorPlanDeTravail";
import ModifyPlanDeTravailParametre from "./ModifyPlanDeTravailParametre/ModifyPlanDeTravailParametre";

const ParamettrePlanDeTravail = ({ RestaurantName }) => {
  const [SelectedPlanDeTravail, setSelectedPlanDeTravail] = useState(null);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 4fr",
        overflow: "auto",
        height: "100%",
      }}
    >
      <SelectorPlanDeTravail
        RestaurantName={RestaurantName}
        SelectedPlanDeTravail={SelectedPlanDeTravail}
        setSelectedPlanDeTravail={setSelectedPlanDeTravail}
      />
      {SelectedPlanDeTravail && (
        <ModifyPlanDeTravailParametre
          SelectedPlanDeTravailID={SelectedPlanDeTravail.IDPepers}
          RestaurantName={RestaurantName}
        />
      )}
    </div>
  );
};

export default ParamettrePlanDeTravail;
