import React, { useState, useEffect } from "react";
import "./SelectProduct.css";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  getFirestore,
  limit,
  query,
  orderBy,
} from "firebase/firestore";
import { app } from "../../firebaseConfig";
import { OrdersAnalytics } from "../../Data/firebaseOrdersAnalytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { AlocateProductToWorkPlace } from "../../Functions/HandleClickFunctions";

const SelectProduct = ({ WorkPlace }) => {
  const [listeProductToDo, setListeProductToDo] = useState();
  const [user, loading, error] = useAuthState(auth);
  const [isProcessing, setIsProcessing] = useState(false);

  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(ordersCollection, orderBy("id", "desc"), limit(50));

  const [Orders] = useCollection(ordersQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [PosteDeTravail] = useCollection(
    collection(getFirestore(app), "PosteDeTravail"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  useEffect(() => {
    if (Orders) {
      async function fetchOrders() {
        const ProducToDo = await OrdersAnalytics(Orders.docs, WorkPlace);
        setListeProductToDo(ProducToDo);
      }
      fetchOrders();
    }
  }, [Orders, PosteDeTravail]);
  console.log(listeProductToDo);

  const HandelAlocateProductToWorkPlace = async (
    userID,
    Product,
    PlanDetravail,
    listeProductToDo
  ) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    try {
      // Votre code pour allouer le produit au poste de travail
      await AlocateProductToWorkPlace(
        userID,
        Product,
        PlanDetravail,
        listeProductToDo
      );

      setIsProcessing(false);
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };

  return (
    <div className="SelctionCommandes">
      {listeProductToDo &&
        listeProductToDo.map((produit) => {
          return (
            <div
              className={
                produit.hasOwnProperty("ProductGroup")
                  ? "ProductBoxGroup"
                  : "ProductBox"
              }
              onClick={() =>
                HandelAlocateProductToWorkPlace(
                  user.uid,
                  produit,
                  WorkPlace,
                  listeProductToDo
                )
              }
            >
              <div className="ProductName">
                {" "}
                {produit.name}
                {produit.modifiers != 0 &&
                  produit.modifiers.map((sup) => {
                    return <div>{sup.name}</div>;
                  })}
                {produit.hasOwnProperty("ProductGroup") && (
                  <div>⚠️ GROUPE DE PRODUIT : {produit.ProductGroup.id}</div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SelectProduct;
