import React, { useState, useEffect } from "react";
import axios from "axios";

const CartDissplay = ({ ProductInCart }) => {
  const [responseData, setResponseData] = useState(null);

  // Remplacez 'YOUR_CLOUD_FUNCTION_URL' par l'URL de votre Cloud Function
  const HandleSendPaiement = async (ProductInCart) => {
    const requestBody = {
      ProductInCart,
      userID: "YOUR_USER_ID",
      // Ajoutez d'autres clés/valeurs selon vos besoins
    };

    const response = await axios.post(
      "https://us-central1-reddd-6b0fd.cloudfunctions.net/api/testStripe",
      requestBody
    );

    setResponseData(response.data);
    window.location.href = response.data.url;
  };
  console.log(ProductInCart);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxHeight: "500px",
        minHeight: "250px",
        overflowY: "auto",
        backgroundColor: "#f5f5f5",
        borderRadius: "10px",
        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <div>Cart</div>
      <div>Horaire</div>
      <div>
        {ProductInCart.map((dish) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>{dish.Production.name}</div>
            <div>0</div>
            <div>{dish.Production.SellingPrice.toFixed(2)} €</div>
          </div>
        ))}
      </div>
      <div
        style={{
          width: "80%",
          padding: "15px",
          border: "1px solid",
          borderRadius: "5px",
          marginTop: "10px",
          cursor: "pointer",
          transition: "background-color 0.3s", // Transition de couleur de fond
          backgroundColor: "transparent", // Couleur de fond par défaut
          textAlign: "center", // Centrer le texte
          fontWeight: "bold", // Texte en gras
          fontSize: "16px", // Taille de police légèrement plus grande
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "white")} // Changement de couleur de fond lorsque la souris passe dessus
        onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")} // Réinitialiser la couleur de fond lorsque la souris quitte la zone
        onClick={() => HandleSendPaiement(ProductInCart)}
      >
        Valider
      </div>
    </div>
  );
};

export default CartDissplay;
