import {
  getFirestore,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { app, firestore, storage } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { m } from "framer-motion";
import { el } from "date-fns/locale";
import { getDailyConssomationPrep } from "./firebaseInteractionOwnerV2";

export async function getActiveProductIntheRestaurant(RestaurantName) {
  const documents = [];
  const docRef = doc(firestore, RestaurantName, "ActifProductInTheRestaurant");
  const docSnapShot = await getDoc(docRef);
  const AllActiveProduct = docSnapShot.data().ActiveProduct;

  const AllActiveProductObject = [];
  for (const prod of AllActiveProduct) {
    const docRefProd = doc(firestore, "dishes", prod);
    const docSnapshotProd = await getDoc(docRefProd);

    if (docSnapshotProd.exists()) {
      AllActiveProductObject.push({ ...docSnapshotProd.data().Production });
    } else {
      console.warn("La ref n'existe pas");
    }
  }

  return AllActiveProductObject;
}

export async function getActivePrep(RestaurantName) {
  const AllActiveProduct = await getActiveProductIntheRestaurant(
    RestaurantName
  );

  let ListeIDPrepUsed = [];
  AllActiveProduct.forEach((prod) => {
    prod.PreparationIntermediaire.forEach((predUsed) => {
      ListeIDPrepUsed.push(predUsed.IDPrep);
    });
  });
  ListeIDPrepUsed = [...new Set(ListeIDPrepUsed)];

  const AllActivePrepObject = [];
  for (const IDPrep of ListeIDPrepUsed) {
    const docRefProd = doc(firestore, "preparations", IDPrep);
    const docSnapshotProd = await getDoc(docRefProd);

    if (docSnapshotProd.exists()) {
      AllActivePrepObject.push({ ...docSnapshotProd.data() });
    } else {
      console.warn("La ref n'existe pas");
    }
  }

  return AllActivePrepObject;
}
export async function getAllOrderMatiereEnLivraisonWithFournisseurData(
  RestaurantName
) {
  const docRefCommandeEnLivraison = doc(
    firestore,
    RestaurantName,
    "CommandeMatiereEnCoursDeLivraison"
  );
  const CommandeEnLivraisonSnapshot = await getDoc(docRefCommandeEnLivraison);
  const CommandeEnLivraison =
    CommandeEnLivraisonSnapshot.data().EnCoursDeLivraison;

  const AllCommandeEnLivraison = [];
  for (const CommandeMatiereID of CommandeEnLivraison) {
    const Commande = doc(
      firestore,
      `${RestaurantName}CommandeMatiere`,
      CommandeMatiereID
    );
    const SnapshotCommande = await getDoc(Commande);

    const Fournisseur = doc(
      firestore,
      `ProductionVisitorV2`,
      "FournisseursVisitor"
    );
    const SnapshotFournisseur = await getDoc(Fournisseur);
    const FournisseurData = SnapshotFournisseur.data().Fournisseurs.filter(
      (fournisseur) =>
        fournisseur.IDPepers === SnapshotCommande.data().FournisseurID
    );

    if (SnapshotCommande.exists() && SnapshotFournisseur.exists()) {
      AllCommandeEnLivraison.push({
        ...SnapshotCommande.data(),
        FournisseurData: FournisseurData[0],
      });
    } else {
      console.warn("La commande matiere n'existe pas");
    }
  }

  return AllCommandeEnLivraison;
}
export async function SupMatiereOrder(RestaurantName, CommandeMatiereID) {
  try {
    const docRefCommandeEnLivraison = doc(
      firestore,
      RestaurantName,
      "CommandeMatiereEnCoursDeLivraison"
    );

    const SnapshotCommandeEnLivraison = await getDoc(docRefCommandeEnLivraison);
    if (SnapshotCommandeEnLivraison.exists()) {
      const docRefCommandeMatiere = doc(
        firestore,
        `${RestaurantName}CommandeMatiere`,
        CommandeMatiereID
      );
      const SnapshotCommandeMatiere = await getDoc(docRefCommandeMatiere);
      if (SnapshotCommandeMatiere.exists()) {
        const DataCommandeMatiere = SnapshotCommandeMatiere.data();
        // On genegere l'id du Daily activity a changer
        const DateCommandeMatiere = new Date(
          DataCommandeMatiere.date.seconds * 1000
        );
        const year = DateCommandeMatiere.getFullYear();
        const month = String(DateCommandeMatiere.getMonth() + 1).padStart(
          2,
          "0"
        );
        const day = String(DateCommandeMatiere.getDate()).padStart(2, "0");
        const IDDailyActivity = `${day}${month}${year}`;
        const DailyactivityDocRef = doc(
          firestore,
          `${RestaurantName}DailyActivities`,
          IDDailyActivity
        );
        const DailyActivitySnapshot = await getDoc(DailyactivityDocRef);
        if (DailyActivitySnapshot.exists()) {
          await updateDoc(DailyactivityDocRef, {
            CommandeMatierePassee: arrayRemove(CommandeMatiereID),
          });

          await updateDoc(docRefCommandeEnLivraison, {
            EnCoursDeLivraison: arrayRemove(CommandeMatiereID),
          });

          await deleteDoc(docRefCommandeMatiere);
        } else {
          throw new Error("Le document DailyActivity n'existe pas");
        }
      } else {
        throw new Error("Le document de la commande matiere n'existe pas");
      }
    } else {
      throw new Error(
        "Le document CommandeMatiereEnCoursDeLivraison n'existe pas"
      );
    }
    return true;
  } catch (e) {
    console.error(e);
  }
}

export async function getaverageConssoPrep(RestaurantName) {
  const data = await getDailyConssomationPrep(
    RestaurantName,
    "2024-04-10",
    "2024-04-21"
  );
  const Average = data.map((prep) => ({
    name: prep.name,
    AverageConsso: prep.QteVendu / 10,
    IDPepers: prep.IDPepers,
    img: prep.img,
    UniteDeMesure: prep.UniteDeMesure,
  }));

  const docRef = doc(firestore, RestaurantName, "ActifProductInTheRestaurant");
  const docSnapShot = await getDoc(docRef);
  const ActivitiesPrep = docSnapShot.data().ActivePrep;

  const docRefStock = doc(firestore, RestaurantName, "Stock");
  const docSnapShotStock = await getDoc(docRefStock);
  const Stock = docSnapShotStock.data();

  let PrepARefaire = [];
  for (const Prep of ActivitiesPrep) {
    // Vérifier si Prep est une clé valide dans l'objet Stock
    if (Stock.hasOwnProperty(Prep)) {
      let qtePrepEnStock = Stock[Prep].Qte;
      let averageConsso = Average.find(
        (prep) => prep.IDPepers === Prep
      )?.AverageConsso;
      // Vérifier si averageConsso est défini
      if (averageConsso && qtePrepEnStock < averageConsso) {
        let prepName = Average.find((prep) => prep.IDPepers === Prep)?.name;
        let ImgPrep = Average.find((prep) => prep.IDPepers === Prep)?.img;
        let UniteDeMesure = Average.find(
          (prep) => prep.IDPepers === Prep
        )?.UniteDeMesure;
        let qteEnStock = Stock[Prep].Qte;
        PrepARefaire.push({
          prepName,
          IdPrep: Prep,
          qteEnStock,
          averageConsso,
          ImgPrep,
          UniteDeMesure,
        });
      }
    } else {
      console.log(`Clé '${Prep}' non trouvée dans l'objet Stock.`);
    }
  }

  return PrepARefaire;
}
