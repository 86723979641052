import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Dashboard from "../../View/ClientPepers/Dashboard";
import { SidebarDataConceptionProduit } from "../../Data/DataConceptionProduit";
import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";
import CreateNewProduct from "../../View/ConceptionProduit/CreateNewProduct";
import Wallet from "../../View/ClientPepers/Wallet";
import ProductSimulation from "../../View/ClientPepers/ProductSimulation";
import Pricing from "../../View/ClientPepers/Pricing";

const ConceptionProduit = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <>
      <Sidebar data={SidebarDataConceptionProduit} />
      {SideBarAtom.view === 0 && <CreateNewProduct />}
    </>
  );
};

export default ConceptionProduit;
