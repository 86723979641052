import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography ,
} from "@mui/material";
import "./ModalAddNewFournisseurs.css";
import {AddNewFournisseur} from '../../../Functions/HandleClickFunctions'

export default function ModalAddNewFournisseurs({ open, handleClose }) {
    const [NomFournisseur, setNomFournisseur] = useState("")
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
        <div style={{ fontSize: '35px'}}>Ajouter un nouveau fournisseur</div>
        <Typography id="modal-modal-title-AddProduct" variant="h6" component="h2">
            <TextField label="Nom du fournisseur" variant="outlined" value={NomFournisseur} onChange={(e)=>setNomFournisseur(e.target.value)}/>
        </Typography>
        
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={(e)=>{
                AddNewFournisseur(NomFournisseur)
                handleClose()
                }}>
                Ajouter
            </Button>
        </Typography>
      </Box>
    </Modal>
  );
}