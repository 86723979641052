import React, { useEffect, useState } from "react";
import CardOrder from "../CardOrder/CardOrder";
import "./CardsOrders.css";
import { getCardsOrdersLogisticsData } from "../../Data/firebaseService";
import { formatDateReadable } from "../../Data/FormatDate";

const CardsOrders = ({ setPrepSelected, setOrderSelected }) => {
  const [CardsData, setCardsData] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      const cardData = await getCardsOrdersLogisticsData();
      setCardsData(cardData);
    }
    fetchOrders();
  }, []);

  return (
    <div className="CardsOrders">
      {CardsData.map((card, id) => {
        return (
          <div className="parentContainer">
            <CardOrder
              OrderDate={formatDateReadable(
                new Date(card.DateLivraison.toMillis())
              )}
              PreparationOrder={card.PreparationOrder}
              setPrepSelected={setPrepSelected}
              setOrderSelected={setOrderSelected}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CardsOrders;
