import React, { useState, useEffect } from "react";

const SearchbarWithGivenData = ({
  data,
  setResult,
  Propriete,
  TextToSearch,
}) => {
  const [input, setInput] = useState("");

  useEffect(() => {
    setResult(data);
  }, []);

  const fetchData = (value) => {
    const results = data.filter((result) => {
      return (
        value &&
        result[Propriete] &&
        result[Propriete].toLowerCase().includes(value)
      );
    });
    setResult(results);
  };
  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="search-bar-container-Generique">
      <div className="input-wrapper">
        <input
          placeholder={TextToSearch}
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchbarWithGivenData;
