import React, { useState, useEffect } from "react";
import "./SelectFournisseur.css";
import { GetAllFournisseur } from "../../../../../../../../Data/firebaseInteractionOwnerV2";

const SelectFournisseur = ({
  RestaurantName,
  SelectedFournisseur,
  setSelectedFournisseur,
}) => {
  const [allFournisseur, setAllFournisseur] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const AllFournisseur = await GetAllFournisseur(RestaurantName);
      setAllFournisseur(AllFournisseur);
    }

    fetchData();
  }, []);

  return (
    <div className="ContenerSelectFournisseur">
      {allFournisseur &&
        allFournisseur.map((fournisseur) => (
          <div
            className={
              SelectedFournisseur &&
              SelectedFournisseur.IDPepers === fournisseur.IDPepers
                ? "SelectFournisseur"
                : "RowSelectFournisseur"
            }
            onClick={() => setSelectedFournisseur(fournisseur)}
          >
            {fournisseur.name}
          </div>
        ))}
    </div>
  );
};

export default SelectFournisseur;
