import React, { useState, useEffect } from "react";
import "./ModifictaionIngredient.css";
import DisplayIngredientDetail from "./DisplayIngredientDetail/DisplayIngredientDetail";
import DisplayMatiereForAnIngredient from "../../OwnerV2Components/DisplayMatiereForAnIngredient/DisplayMatiereForAnIngredient";

const ModifictaionIngredient = ({ Selected }) => {
  return (
    <div className="ContenerModificationIngredient">
      <DisplayIngredientDetail Selected={Selected} />
      <DisplayMatiereForAnIngredient Selected={Selected} />
    </div>
  );
};

export default ModifictaionIngredient;
