import React, { useEffect, useState } from "react";
import MainDashPreparationLogisticsV2 from "../../components/MainDash/MainDashPreparationLogisticsV2/MainDashPreparationLogisticsV2";
import RightSidePreparationLogistics from "../../components/RightSide/RightSidePreparationLogistics/RightSidePreparationLogistics";

const PreparationLogisticsOrdersV2 = () => {
  return (
    <>
      <MainDashPreparationLogisticsV2 />
      <RightSidePreparationLogistics />
    </>
  );
};

export default PreparationLogisticsOrdersV2;
