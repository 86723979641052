import React, { useState, useEffect } from "react";
import "./FicheTechniqueForClientPricing.css";
import { useRecoilState } from "recoil";
import { CreationProductAtom } from "../../../atoms/CreationProductAtom";
import {
  GetIngredientForFicheTechnique,
  GetPhotoProduit,
  GetProductPricing,
} from "../../../Data/firebaseService";

const FicheTechniqueForClientPricing = ({ ProductName }) => {
  const [ingredient, setIngredient] = useState(null);
  const [PhotoProduit, setPhotoProduit] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const IngredientProduct = await GetIngredientForFicheTechnique(
        ProductName
      );
      const Photo = await GetPhotoProduit(ProductName);
      setIngredient(IngredientProduct);
      setPhotoProduit(Photo);
    }
    fetchData();
  }, []);
  return (
    <div>
      <div style={{ fontSize: "35px" }}>{ProductName}</div>
      <div className="FichesTechnique">
        {ingredient &&
          ingredient.map((preparation) => {
            return (
              <div className="IngredientFiche">
                <div style={{ marginBottom: "15px", fontSize: "20px" }}>
                  {preparation.Nom}
                </div>
                <div style={{ marginBottom: "15px", fontSize: "20px" }}>
                  {" "}
                  Qte : {preparation.Qte} {preparation.Unite}
                </div>
                <div style={{ marginBottom: "15px", fontSize: "20px" }}>
                  <img
                    className="img-prep"
                    src={preparation.PhotoFirebase}
                    alt="Preparation"
                  />
                </div>
              </div>
            );
          })}
        <div className="">
          <div style={{ marginBottom: "15px", fontSize: "20px" }}>
            {PhotoProduit != null && (
              <img
                style={{ maxWidth: "320px" }}
                src={PhotoProduit}
                alt="Product"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FicheTechniqueForClientPricing;
