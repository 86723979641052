import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
  Textarea,
} from "@mui/material";
import "./ModaldisplayPrepRefait.css";

export default function ModaldisplayPrepRefait({
  open,
  handleClose,
  DataPrepRefait,
}) {
  const [SelectedPrepRefait, setSelectedPrepRefait] = useState(null);

  console.log(DataPrepRefait);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ fontSize: "35px" }}>Preparation refait</div>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <img
            src={DataPrepRefait[0].PrepData.PhotoFirebase}
            style={{ maxWidth: "30vh", height: "auto" }}
          />
          {DataPrepRefait.map((refait) => {
            return (
              <div onClick={() => setSelectedPrepRefait(refait)}>
                <div>
                  {new Date(refait.date.seconds * 1000).toLocaleString()}{" "}
                  {refait.QteRefait} -- {refait.PrepData.Unite}
                </div>
              </div>
            );
          })}
          {SelectedPrepRefait && (
            <div className="displayMatiereUsed">
              {SelectedPrepRefait.MatiereUtiliseAuStock.map((matiereUsed) => {
                return (
                  <div>
                    {matiereUsed.Name} {matiereUsed.Qte}{" "}
                    {matiereUsed.UniteDeMesure}
                  </div>
                );
              })}
            </div>
          )}
        </Typography>
      </Box>
    </Modal>
  );
}
