import React, { useState, useEffect } from "react";
import "./MainDashPricing.css";
import { getClientPricingAllData } from "../../../Data/firebaseService";
import FicheTechniqueForClientPricing from "../../../components/FichesTechnique/FicheTechniqueForClientPricing/FicheTechniqueForClientPricing";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";

const MainDashPricing = ({ setOrderSelected }) => {
  const [Pricing, setPricing] = useState(null);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    async function fetchOrders() {
      const Pricing = await getClientPricingAllData(user.uid);
      setPricing(Pricing);
    }
    fetchOrders();
  }, []);

  return (
    <div className="MainDashPricing">
      {Pricing &&
        Pricing.map((product) => {
          return (
            <FicheTechniqueForClientPricing ProductName={product.NomZelty} />
          );
        })}
    </div>
  );
};

export default MainDashPricing;
