import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, CircularProgress } from "@material-ui/core";

import "./ModalAvecFunction.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "90%",
    maxHeight: "90vh",
    overflowY: "auto",
    border: "2px solid #000",
    borderRadius: "5px",
  },
  formContainer: {
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "5px",
  },
}));

const ModalAvecFunction = ({
  open,
  handleClose,
  Form,
  TextActionButton,
  TitleModal,
  SousParagraphe,
  DataForm,
  setDataForm,
  Function,
}) => {
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [InitialDataForm, setInitialDataForm] = useState(DataForm);

  const handleInteract = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);
    try {
      const result = await Function(DataForm);
      console.log(result);

      setIsProcessing(false);
      if (result) {
        setDataForm(InitialDataForm);
        handleClose();
      } else {
      }
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };

  console.log(DataForm);

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className={classes.paper}>
          <h2 id="modal-title">{TitleModal}</h2>
          {SousParagraphe}
          <div
            className="Form"
            style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            {Form}
          </div>
          {isProcessing ? (
            <CircularProgress size={24} color="secondary" /> // Affichez un indicateur de chargement si isProcessing est vrai
          ) : (
            <Button
              variant="contained"
              color="secondary"
              style={{ backgroundColor: "#283747" }}
              onClick={() => {
                handleInteract();
              }}
            >
              {TextActionButton}
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ModalAvecFunction;
