import React, { useState } from "react";
import "./NavBarAnalyseManagerRestaurant.css";

const NavBarAnalyseManagerRestaurant = ({ Nav, setNav }) => {
  return (
    <div className="navTablePrepMatiere">
      <div
        className={Nav === 0 ? "RowSelected" : "RowButton"}
        onClick={() => setNav(0)}
      >
        Annalyse des commandes de matiere premiere
      </div>
      <div
        className={Nav === 1 ? "RowSelected" : "RowButton"}
        onClick={() => setNav(1)}
      >
        Analyse theorique Conssomation
      </div>
      <div
        className={Nav === 2 ? "RowSelected" : "RowButton"}
        onClick={() => setNav(2)}
      >
        Analyse Consommation Réelle
      </div>
      <div
        className={Nav === 3 ? "RowSelected" : "RowButton"}
        onClick={() => setNav(3)}
      >
        Stock
      </div>
    </div>
  );
};

export default NavBarAnalyseManagerRestaurant;
