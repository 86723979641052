import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import "./ModalAddNewConditionnementLogistiqueV2.css";
import { handleCreateNewConditionnementLigistiqueV2 } from "../../../Functions/HandleClickFunctions";

export default function ModalAddNewConditionnementLogistiqueV2({
  open,
  handleClose,
  prep,
}) {
  const [NomConditionnementLogistic, setNomConditionnementLogistic] =
    useState("");
  const [QteConditionnementLogistic, setQteConditionnementLogistic] =
    useState("");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ fontSize: "35px" }}>
          Ajouter un conditionnement logigistique
        </div>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom Conditionnement Logistic"
            variant="outlined"
            value={NomConditionnementLogistic}
            onChange={(e) => setNomConditionnementLogistic(e.target.value)}
          />
          <TextField
            label={`Qte Conditionnement(${prep.Unite})`}
            variant="outlined"
            value={QteConditionnementLogistic}
            onChange={(e) =>
              setQteConditionnementLogistic(e.target.value.replace(/,/g, "."))
            }
          />
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleClose();
              handleCreateNewConditionnementLigistiqueV2(
                prep,
                NomConditionnementLogistic,
                QteConditionnementLogistic
              );
            }}
          >
            Creer Conditionnement
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}
