import React, { useState, useEffect } from "react";
import {
  getAllPlanDeTravail,
  SupprimerPlanDeTravail,
} from "../../../../../../../Data/firebaseInteractionOwnerV2";
import AddPlanDeTravail from "../../../../../Button/AddPlanDeTravail/AddPlanDeTravail";
import SetPlanDeTravailWithProductYouWantDo from "../../../../../Button/SetPlanDeTravailWithProductYouWantDo/SetPlanDeTravailWithProductYouWantDo";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../../../firebaseConfig";
import AddMachine from "../../../../../Button/AddMachine/AddMachine";

const SelectorPlanDeTravail = ({
  RestaurantName,
  SelectedPlanDeTravail,
  setSelectedPlanDeTravail,
}) => {
  const [PlanDeTravail, setPlanDeTravail] = useState(null);

  const [PlanDeTravailResto] = useCollection(
    collection(getFirestore(app), `${RestaurantName}PlanDeTravail`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      const Data = await getAllPlanDeTravail(RestaurantName);
      setPlanDeTravail(Data);
    };
    fetchData();
  }, [PlanDeTravailResto]);

  const [isProcessing, setIsProcessing] = useState(false);

  const HandleSupprimerPlanDeTravail = async (
    PlanDeTravailID,
    RestaurantName
  ) => {
    setIsProcessing(true);
    if (isProcessing) {
      return;
    }
    await SupprimerPlanDeTravail(PlanDeTravailID, RestaurantName);
    setIsProcessing(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {PlanDeTravail &&
        PlanDeTravail.map((PlanDeTravail) => (
          <div
            style={
              SelectedPlanDeTravail &&
              SelectedPlanDeTravail.IDPepers === PlanDeTravail.IDPepers
                ? {
                    padding: "15px",
                    borderRadius: "5px",
                    border: "1px solid black",
                    margin: "5px",
                    cursor: "pointer",
                    backgroundColor: "lightblue",
                  }
                : {
                    padding: "15px",
                    borderRadius: "5px",
                    border: "1px solid black",
                    margin: "5px",
                    cursor: "pointer",
                  }
            }
            onClick={() => setSelectedPlanDeTravail(PlanDeTravail)}
          >
            {PlanDeTravail.name}{" "}
            <span
              className="Red"
              onClick={() =>
                HandleSupprimerPlanDeTravail(
                  PlanDeTravail.IDPepers,
                  RestaurantName
                )
              }
            >
              Supprimer
            </span>
          </div>
        ))}
      <AddPlanDeTravail RestaurantName={RestaurantName} />
      <SetPlanDeTravailWithProductYouWantDo RestaurantName={RestaurantName} />
      <AddMachine />
    </div>
  );
};

export default SelectorPlanDeTravail;
