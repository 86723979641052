import { getFirestore, doc ,collection, getDocs, getDoc  } from 'firebase/firestore';
import { app } from '../firebaseConfig';

export async function getUserInfo(userID) {
    const usersCollection = collection(getFirestore(app), 'users');
    const userSnapshot = await getDocs(usersCollection);
    let userData = null
    userSnapshot.docs.forEach(doc => {
         if(doc.data().uid === userID){
            userData = doc.data()
         } 
    });
    return userData
}