import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import "./ModalSeeMoreDetailOrderForControlManager.css";

export default function ModalSeeMoreDetailOrderForControlManager({
  open,
  handleClose,
  Commande,
}) {
  console.log(Commande);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Prix TTC Commande :{" "}
          {Commande.dataOrder.order.price.final_amount_exc_tax / 100} €
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {Commande.dataOrder.order.virtual_brand_name}
        </Typography>

        <Typography id="List-Prod-Order" variant="h6" component="h2">
          <div className="grid-container-title">
            <div>NOM DU PRODUIT</div>
            <div>PRIX DE REVIENT VBB</div>
          </div>
          {Commande.listeProduitCommande.map((prod) => (
            <div className="grid-container">
              <div>{prod.name}</div>
              <div>{prod.CoutDuProduit}</div>
            </div>
          ))}{" "}
        </Typography>
      </Box>
    </Modal>
  );
}
