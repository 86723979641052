import React from "react";
import "./LeftSideCenterpageVisitorV2.css";
import DisplayDataCharge from "../DisplayDataCharge/DisplayDataCharge";
import DisplayFicheTechniqueVisitorV2 from "../DisplayFicheTechniqueVisitorV2/DisplayFicheTechniqueVisitorV2";
import DisplayFicheTechniquePreparation from "../DisplayFicheTechniquePreparation/DisplayFicheTechniquePreparation";

const LeftSideCenterpageVisitorV2 = ({
  ProductSelected,
  navRowDisplayProdandPrep,
  PrepSelectedForChangeMatiere,
}) => {
  return (
    <div className="leftSideMainDash">
      {/* <DisplayDataCharge />*/}
      {navRowDisplayProdandPrep === 0 && (
        <DisplayFicheTechniqueVisitorV2 ProductSelected={ProductSelected} />
      )}
      {navRowDisplayProdandPrep === 1 && (
        <DisplayFicheTechniquePreparation
          PrepSelectedForChangeMatiere={PrepSelectedForChangeMatiere}
        />
      )}
      {navRowDisplayProdandPrep === 2 && (
        <div>DisplayParamettreApremTestAuResto</div>
      )}
    </div>
  );
};

export default LeftSideCenterpageVisitorV2;
