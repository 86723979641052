import React from "react";
import "./RightSideCreationProduit.css";
import ListePreparations from "../../Updates/ListePreparations/ListePreparations";
import CustomerReview from "../../CustomerReview/CustomerReview";

const RightSideCreationProduit = () => {
  return (
    <div className="RightSide">
      <div>
        <h3>Preparations</h3>
        <ListePreparations />
      </div>
      <div>
        <h3>Customer Review</h3>
        <CustomerReview />
      </div>
    </div>
  );
};

export default RightSideCreationProduit;
