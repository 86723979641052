import {
  getFirestore,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { app, firestore, storage } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { m } from "framer-motion";
import { ca, el } from "date-fns/locale";
import { isEqual } from "lodash";

export async function DisplayProductToDoInTheWorkplace(
  RestaurantName,
  WorkPlace
) {
  try {
    // get all product in production
    const allPlanDeTravailDocRef = collection(
      firestore,
      `${RestaurantName}PlanDeTravail`
    );
    const allPlanDeTravailSnapshot = await getDocs(allPlanDeTravailDocRef);
    const allProductInProduction = [];
    allPlanDeTravailSnapshot.docs.forEach((doc) => {
      if (doc.id !== WorkPlace) {
        doc.data().ProductInProduction.forEach((product) => {
          allProductInProduction.push(product);
        });
      }
    });
    // get data du plan de travail
    const PosteDeTravailDocRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      WorkPlace
    );
    const PosteDeTravailSnapshot = await getDoc(PosteDeTravailDocRef);
    let PosteDeTravailData;
    if (PosteDeTravailSnapshot.exists()) {
      PosteDeTravailData = PosteDeTravailSnapshot.data();
    } else {
      console.log("No such document! PosteDeTravail");
      throw "No such document! PosteDeTravail";
    }

    const OrderOpenDocRef = collection(
      firestore,
      `${RestaurantName}OrdersEnCours`
    );
    const OrderOpenSnapshot = await getDocs(OrderOpenDocRef);
    const AllOpenOrders = OrderOpenSnapshot.docs.map((doc) => doc.data());
    let ListeProductToDo = [];
    for (const Order of AllOpenOrders) {
      for (const ProductOrdered of Order.listeProduitCommande) {
        if (ProductOrdered.etatProduction === false) {
          const ProductZeltyDocRef = doc(
            firestore,
            "zeltyDishes",
            ProductOrdered.idZelty
          );
          const ProductZeltySnapshot = await getDoc(ProductZeltyDocRef);
          if (ProductZeltySnapshot.exists()) {
            const ProductZeltyData = ProductZeltySnapshot.data();
            if (ProductZeltyData.hasOwnProperty("IDPepers")) {
              const ProductPepersDocRef = doc(
                firestore,
                "dishes",
                ProductZeltyData.IDPepers
              );
              const ProductPepersSnapshot = await getDoc(ProductPepersDocRef);
              if (ProductPepersSnapshot.exists()) {
                const ProductPepersData = ProductPepersSnapshot.data();

                let productCanBemadeInThisWorkPlace = true;
                ProductPepersData.Production.PreparationIntermediaire.forEach(
                  (PrepUsed) => {
                    if (
                      PosteDeTravailData.ListePreparation.includes(
                        PrepUsed.IDPrep
                      ) === false
                    ) {
                      productCanBemadeInThisWorkPlace = false;
                    }
                  }
                );
                if (productCanBemadeInThisWorkPlace) {
                  //On regarde si le produit est deja attribue au plan de travail
                  let isAlreadyAllocatedToWorkPlace = false;
                  let ProductToDo = {
                    OrderID: Order.id,
                    ProductIDInTheOrder: ProductOrdered.id,
                    ZeltyIDProduct: ProductOrdered.idZelty,
                  };
                  for (const item of PosteDeTravailData.ProductInProduction) {
                    // On regarde si le produit n'appartient pas a un autre plan de travail
                    let ProdPost = {
                      OrderID: item.OrderID,
                      ProductIDInTheOrder: item.ProductIDInTheOrder,
                      ZeltyIDProduct: item.ZeltyIDProduct,
                    };
                    if (isEqual(ProdPost, ProductToDo)) {
                      isAlreadyAllocatedToWorkPlace = true;
                    }
                  }
                  // On verifie que le produit n'est pas deja attribue a un autre plan de travail plan de travail
                  let isAlrealreadyAllocatedInAnotherWorkPlace = false;

                  allProductInProduction.forEach((product) => {
                    if (
                      product.ZeltyIDProduct === ProductToDo.ZeltyIDProduct &&
                      product.OrderID === ProductToDo.OrderID &&
                      product.ProductIDInTheOrder ===
                        ProductToDo.ProductIDInTheOrder
                    ) {
                      isAlrealreadyAllocatedInAnotherWorkPlace = true;
                    }
                  });
                  if (!isAlrealreadyAllocatedInAnotherWorkPlace) {
                    if (ProductOrdered.modifiers.length > 0) {
                      let newProductModifiers = [];
                      for (const modifier of ProductOrdered.modifiers) {
                        const ZeltyOptionValueDocRef = doc(
                          firestore,
                          "zeltyOptionsValue",
                          modifier.id.toString()
                        );
                        const ZeltyOptionValueSnapshot = await getDoc(
                          ZeltyOptionValueDocRef
                        );
                        if (ZeltyOptionValueSnapshot.exists()) {
                          const ZeltyOptionValueData =
                            ZeltyOptionValueSnapshot.data();
                          if (
                            ZeltyOptionValueData.hasOwnProperty(
                              "IDPepersOptionValue"
                            )
                          ) {
                            const PepersOptionvalurDocRef = doc(
                              firestore,
                              "OptionsValue",
                              ZeltyOptionValueData.IDPepersOptionValue
                            );
                            const PepersOptionValueSnapshot = await getDoc(
                              PepersOptionvalurDocRef
                            );
                            if (PepersOptionValueSnapshot.exists()) {
                              const PepersOptionValueData =
                                PepersOptionValueSnapshot.data();
                              newProductModifiers.push(PepersOptionValueData);
                            } else {
                              console.log("L'optionValue Pepers n'existe pas");
                            }
                          } else {
                            console.log(
                              "L'optionValue Zelty n'est pas relie a un optionValue Pepers"
                            );
                          }
                        }
                      }
                      ListeProductToDo.push({
                        ...ProductOrdered,
                        OrderID: Order.id,
                        ...ProductPepersData,
                        modifiers: newProductModifiers,
                        isAlreadyAllocatedToWorkPlace,
                      });
                    } else {
                      ListeProductToDo.push({
                        ...ProductOrdered,
                        OrderID: Order.id,
                        ...ProductPepersData,
                        isAlreadyAllocatedToWorkPlace,
                      });
                    }
                  } else {
                    console.log(
                      "Le produit est deja attribue a un autre plan de travail"
                    );
                  }
                }
              } else {
                console.log("No such document! ProductPepers");
                throw "No such document! ProductPepers";
              }
            } else {
              console.log(
                "Le produit Zelty n'est pas relie a un produit Pepers"
              );
            }
          }
        }
      }
    }
    return ListeProductToDo;
  } catch (error) {
    console.log("Error getting document:", error);
  }
}

export async function SetAlocateDishToPlanDeTravail(
  productToDo,
  WorkPlace,
  RestaurantName
) {
  try {
    // On verifie que le produit n'est pas deja attribue a un autre plan de travail plan de travail
    const AllPosteDeTravailDocRef = collection(
      firestore,
      `${RestaurantName}PlanDeTravail`
    );
    const AllPosteDeTravailSnapshot = await getDocs(AllPosteDeTravailDocRef);

    AllPosteDeTravailSnapshot.docs.map((doc) => {
      const PosteDeTravailData = doc.data();
      PosteDeTravailData.ProductInProduction.map((product) => {
        if (
          product.ZeltyIDProduct === productToDo.ZeltyID &&
          product.OrderID === productToDo.OrderID &&
          product.ProductIDInTheOrder === productToDo.id
        ) {
          throw "Le produit est deja attribue a un autre plan de travail";
        }
      });
    });
    const PosteDeTravailDocRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      WorkPlace
    );
    const PosteDeTravailSnapshot = await getDoc(PosteDeTravailDocRef);
    if (PosteDeTravailSnapshot.exists()) {
      await updateDoc(PosteDeTravailDocRef, {
        ProductInProduction: arrayUnion({
          ZeltyIDProduct: productToDo.ZeltyID,
          OrderID: productToDo.OrderID,
          ProductIDInTheOrder: productToDo.id,
          Supplements: productToDo.modifiers,
        }),
      });
    }
  } catch (error) {
    console.log("Error getting document:", error);
  }
}

export async function SetFinishProductl(
  productToDo,
  WorkPlace,
  RestaurantName
) {
  try {
    const PosteDeTravailDocRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      WorkPlace
    );
    const PosteDeTravailSnapshot = await getDoc(PosteDeTravailDocRef);
    if (PosteDeTravailSnapshot.exists()) {
      const OrderEnCoursDocRef = doc(
        firestore,
        `${RestaurantName}OrdersEnCours`,
        productToDo.OrderID.toString()
      );
      const OrderEnCoursSnapshot = await getDoc(OrderEnCoursDocRef);
      if (OrderEnCoursSnapshot.exists()) {
        const Order = OrderEnCoursSnapshot.data();
        //On regarde si c'est le dernier produit a valider de la commande pour la marquer comme terminee
        let isLastProduct = 0;
        for (const product of Order.listeProduitCommande) {
          if (product.etatProduction === false) {
            isLastProduct = isLastProduct + 1;
          }
        }
        if (isLastProduct === 1) {
          // on update le document plan de travail en premier
          await updateDoc(PosteDeTravailDocRef, {
            ProductInProduction: arrayRemove({
              ZeltyIDProduct: productToDo.ZeltyID,
              OrderID: productToDo.OrderID,
              ProductIDInTheOrder: productToDo.id,
              Supplements: productToDo.modifiers,
            }),
          });
          // On supprime le document order
          await deleteDoc(OrderEnCoursDocRef);
        } else {
          // on modifie la valeur etatProduction du produit dans la commande
          const ProductIndex = Order.listeProduitCommande.findIndex(
            (product) => product.id === productToDo.id
          );
          Order.listeProduitCommande[ProductIndex].etatProduction = true;
          await updateDoc(OrderEnCoursDocRef, {
            listeProduitCommande: Order.listeProduitCommande,
          });
          await updateDoc(PosteDeTravailDocRef, {
            ProductInProduction: arrayRemove({
              ZeltyIDProduct: productToDo.ZeltyID,
              OrderID: productToDo.OrderID,
              ProductIDInTheOrder: productToDo.id,
              Supplements: productToDo.modifiers,
            }),
          });
        }
      } else {
        console.log("No such document! OrderEnCours");
        throw "No such document! OrderEnCours";
      }
    }
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
export async function CreateOrderEnCours() {
  try {
    const OrderEnCoursDocRef = doc(firestore, "Orders", "273381821");
    const OrderEnCoursSnapshot = await getDoc(OrderEnCoursDocRef);
    if (OrderEnCoursSnapshot.exists()) {
      const Order = OrderEnCoursSnapshot.data();
      const OrderEnProductionDocRef = doc(
        firestore,
        "RestaurantSainteFoyOrdersEnCours",
        "273381821"
      );
      await setDoc(OrderEnProductionDocRef, Order);
    } else {
      console.log("No such document! OrderEnCours");
      throw "No such document! OrderEnCours";
    }
    return true;
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
export async function GetProductInTheWorkPlace(WorkPlaceID, RestaurantName) {
  try {
    const PosteDeTravailDocRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      WorkPlaceID
    );
    const PosteDeTravailSnapshot = await getDoc(PosteDeTravailDocRef);
    if (PosteDeTravailSnapshot.exists()) {
      const PosteDeTravailData = PosteDeTravailSnapshot.data();
      let ListeProductInTheWorkPlace = [];
      for (const Product of PosteDeTravailData.ProductInProduction) {
        const ProductZeltyDocRef = doc(
          firestore,
          "zeltyDishes",
          Product.ZeltyIDProduct
        );
        const ProductZeltySnapshot = await getDoc(ProductZeltyDocRef);
        if (ProductZeltySnapshot.exists()) {
          const ProductZeltyData = ProductZeltySnapshot.data();
          if (ProductZeltyData.hasOwnProperty("IDPepers")) {
            const ProductPepersDocRef = doc(
              firestore,
              "dishes",
              ProductZeltyData.IDPepers
            );
            const ProductPepersSnapshot = await getDoc(ProductPepersDocRef);
            if (ProductPepersSnapshot.exists()) {
              const ProductPepersData = ProductPepersSnapshot.data();
              let newListePrepUsed = [];
              for (const prepUsed of ProductPepersData.Production
                .PreparationIntermediaire) {
                const PrepDocRef = doc(
                  firestore,
                  "preparations",
                  prepUsed.IDPrep
                );
                const PrepSnapshot = await getDoc(PrepDocRef);
                if (PrepSnapshot.exists()) {
                  const PrepData = PrepSnapshot.data();
                  newListePrepUsed.push({
                    PrepData,
                    QteUsedInProduct: prepUsed.Qte,
                  });
                } else {
                  console.log("No such document! preparation");
                  throw "No such document! preparation";
                }
              }
              // On ajoute la liste des supplements
              let newSupplements = [];
              for (const supplement of Product.Supplements) {
                let newListePrepIntermediaire = [];
                for (const prepUsed of supplement.preparationIntermediaire) {
                  const PrepDocRef = doc(
                    firestore,
                    "preparations",
                    prepUsed.IDPrep
                  );
                  const PrepSnapshot = await getDoc(PrepDocRef);
                  if (PrepSnapshot.exists()) {
                    const PrepData = PrepSnapshot.data();
                    newListePrepIntermediaire.push({
                      PrepData,
                      QteUsedInSuplement: prepUsed.Qte,
                    });
                  } else {
                    console.log("No such document! preparation");
                    throw "No such document! preparation";
                  }
                }
                newSupplements.push({
                  ...supplement,
                  preparationIntermediaire: newListePrepIntermediaire,
                });
              }
              ListeProductInTheWorkPlace.push({
                Production: {
                  ...ProductPepersData.Production,
                  PreparationIntermediaire: newListePrepUsed,
                },
                Supplements: newSupplements,
              });
            } else {
              console.log("No such document! ProductPepers");
              throw "No such document! ProductPepers";
            }
          } else {
            console.log("Le produit Zelty n'est pas relie a un produit Pepers");
          }
        } else {
          console.log("No such document! ProductZelty");
          throw "No such document! ProductZelty";
        }
      }
      return ListeProductInTheWorkPlace;
    } else {
      console.log("No such document! PosteDeTravail");
      throw "No such document! PosteDeTravail";
    }
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
export async function OpenOrderTodo() {
  try {
    const OrderEnCoursDocRef = doc(firestore, "Orders", "274782536");
    const OrderEnCoursSnapshot = await getDoc(OrderEnCoursDocRef);
    if (OrderEnCoursSnapshot.exists()) {
      const Order = OrderEnCoursSnapshot.data();
      const OrderEnProductionDocRef = doc(
        firestore,
        "RestaurantSainteFoyOrdersEnCours",
        "274782536"
      );
      await setDoc(OrderEnProductionDocRef, Order);
    } else {
      console.log("No such document! OrderEnCours");
      throw "No such document! OrderEnCours";
    }
    return true;
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
export function CheckIfTheIsChangeInTheProductToDo(
  OldProducToDo,
  NewProductToDo
) {
  try {
    let isIdentique = true;
    if (OldProducToDo === null || NewProductToDo === null) {
      isIdentique = false;
    }
    if (isEqual(OldProducToDo, NewProductToDo)) {
      isIdentique = true;
    } else {
      isIdentique = false;
    }
    return isIdentique;
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
export async function PUTPICHTURE() {
  try {
    const docRefOldDish = doc(firestore, "Production", "dishes");
    const OldDishSnapshoot = await getDoc(docRefOldDish);
    if (OldDishSnapshoot.exists()) {
      const OldDishData = OldDishSnapshoot.data().dishes;

      const docRefNewDish = collection(firestore, "dishes");
      const NewDishSnapshoot = await getDocs(docRefNewDish);
      let ListNewDish = [];
      NewDishSnapshoot.docs.map((doc) => {
        ListNewDish.push(doc.data());
      });

      for (const NewDish of ListNewDish) {
        const OldDish = OldDishData.find(
          (dish) => dish.Production.name === NewDish.Production.name
        );

        let refNewDish = doc(firestore, "dishes", NewDish.Production.IDPepers);

        if (OldDish !== undefined) {
          let oldImg = OldDish.Production.img;
          let newDishData = {
            ...NewDish,
            Production: { ...NewDish.Production, img: oldImg },
          };

          await updateDoc(refNewDish, { ...newDishData });
        } else {
          await updateDoc(refNewDish, { ...NewDish });
        }
      }

      return ListNewDish;
    } else {
      console.log("No such document! OldDish");
      throw "No such document! OldDish";
    }
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
export async function getAllPlanDeTravailData(RestaurantName) {
  try {
    const allPlanDeTravailDocRef = collection(
      firestore,
      `${RestaurantName}PlanDeTravail`
    );
    const allPlanDeTravailSnapshot = await getDocs(allPlanDeTravailDocRef);
    const allProductInProduction = [];
    allPlanDeTravailSnapshot.docs.forEach((doc) => {
      allProductInProduction.push(doc.data());
    });
    return allProductInProduction;
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
