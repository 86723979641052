import React, { useEffect, useState } from "react";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import {
  getAllZeltyOptionValue,
  HandleAssocietePepersOptionValueToZeltyOptionValue,
} from "../../../../Data/firebaseInteractionOwnerV2";
import Avatar from "@mui/material/Avatar";
import { TextField, Button } from "@material-ui/core";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

const AssocietePepersOptionValueToZeltyOptionValue = ({
  IDPeperOptionValue,
}) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    IDPeperOptionValue: IDPeperOptionValue,
  });
  const [AllOptionsValueZelty, setAllPepersOption] = useState(null);

  const handleOpenModal = () => {
    setDataForm((prevData) => ({
      ...prevData,
    }));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [Options] = useCollection(collection(getFirestore(app), "Options"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      const OptionsValueZelty = await getAllZeltyOptionValue();
      setAllPepersOption(OptionsValueZelty);
    }
    fetchOrders();
  }, [Options]);

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        Ajouter une option au plat
      </div>
      <ModalAvecFunction
        open={OpenModal}
        handleClose={handleCloseModal}
        Form={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            {AllOptionsValueZelty &&
              AllOptionsValueZelty.map((OptionZelty) => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setDataForm((prev) => ({
                      ...prev,
                      OptionValueZelty: OptionZelty.id,
                    }));
                  }}
                >
                  {OptionZelty.name}
                </div>
              ))}
          </div>
        }
        TextActionButton={"Valider"}
        SousParagraphe={`Associe une option value pepepers a une option value Zelty`}
        TitleModal={`Associe une option value pepepers a une option value Zelty `}
        DataForm={{ ...DataForm }}
        Function={HandleAssocietePepersOptionValueToZeltyOptionValue}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default AssocietePepersOptionValueToZeltyOptionValue;
