import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const AddOptionChoice = ({
  OptionChoice,
  setOptionChoice,
  selectedOptionValue,
  setSelectedOptionValue,
}) => {
  console.log(OptionChoice);
  const HandleSupPrepInOtionValue = (prepID, OptionValueID) => {
    setOptionChoice((prev) => {
      let newOptionData = [];
      prev.forEach((option, index) => {
        if (option.id === OptionValueID) {
          let newListPrepUSed = [];
          option.preparationIntermediaire.forEach((prepUsed, indexPrepUsed) => {
            if (prepUsed.IDPrep === prepID) {
              newListPrepUSed = [
                ...prev[index].preparationIntermediaire.slice(0, indexPrepUsed),
                ...prev[index].preparationIntermediaire.slice(
                  indexPrepUsed + 1
                ),
              ];
            }
          });
          newOptionData = [
            ...prev.slice(0, index),
            {
              ...prev[index],
              preparationIntermediaire: newListPrepUSed,
            },
            ...prev.slice(index + 1),
          ];
        }
      });

      return newOptionData;
    });
  };

  return (
    <div
      style={{
        background: "rgba(255, 255, 255)",
        borderRadius: "0.7rem",
        padding: "1rem",
        fontSize: "13px",
        overflowY: "auto",
      }}
    >
      {OptionChoice.map((choice, index) => (
        <>
          <div
            style={
              selectedOptionValue && selectedOptionValue.id === choice.id
                ? {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "0.7rem",
                    border: "1px solid #e0e0e0",
                    marginBottom: "0.5rem",
                    background: "#f0f0f0",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "0.7rem",
                    border: "1px solid #e0e0e0",
                    marginBottom: "0.5rem",
                  }
            }
            onClick={() => setSelectedOptionValue(choice)}
          >
            <div>
              <TextField
                label="Nom de l'option"
                variant="outlined"
                fullWidth
                value={choice.hasOwnProperty("name") ? choice.name : ""}
                onChange={(e) =>
                  setOptionChoice((prev) => {
                    const newOptionData = [
                      ...prev.slice(0, index),
                      { ...prev[index], name: e.target.value },
                      ...prev.slice(index + 1),
                    ];
                    return newOptionData;
                  })
                }
              />
            </div>
            <div>
              <TextField
                label="Prix"
                variant="outlined"
                fullWidth
                value={choice.hasOwnProperty("price") ? choice.price : ""}
                onChange={(e) =>
                  setOptionChoice((prev) => {
                    const newOptionData = [
                      ...prev.slice(0, index),
                      { ...prev[index], price: e.target.value },
                      ...prev.slice(index + 1),
                    ];
                    return newOptionData;
                  })
                }
                style={{
                  maxWidth: "100px",
                }}
              />
            </div>
            <div
              className="Red"
              onClick={() =>
                setOptionChoice((prev) => {
                  const newOptionData = [
                    ...prev.slice(0, index),
                    ...prev.slice(index + 1),
                  ];
                  return newOptionData;
                })
              }
            >
              Supprimer
            </div>
          </div>
          {choice.hasOwnProperty("preparationIntermediaire") && (
            <div>
              {choice.preparationIntermediaire.map(
                (prepInterm, IndexprepInterm) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "0.7rem",
                        maxWidth: "70%",
                        allignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <div>{prepInterm.IDPrep}</div>{" "}
                      <div>
                        <TextField
                          label="Qte"
                          variant="outlined"
                          fullWidth
                          value={
                            OptionChoice[index].preparationIntermediaire[
                              IndexprepInterm
                            ].Qte
                          }
                          onChange={(e) =>
                            setOptionChoice((prev) => {
                              const newListPrepUSed = [
                                ...prev[index].preparationIntermediaire.slice(
                                  0,
                                  IndexprepInterm
                                ),
                                {
                                  ...prev[index].preparationIntermediaire[
                                    IndexprepInterm
                                  ],
                                  Qte: e.target.value,
                                },
                                ...prev[index].preparationIntermediaire.slice(
                                  IndexprepInterm + 1
                                ),
                              ];
                              const newOptionData = [
                                ...prev.slice(0, index),
                                {
                                  ...prev[index],
                                  preparationIntermediaire: newListPrepUSed,
                                },
                                ...prev.slice(index + 1),
                              ];
                              return newOptionData;
                            })
                          }
                          style={{
                            maxWidth: "100px",
                          }}
                        />
                      </div>{" "}
                      <span
                        className="Red"
                        onClick={() =>
                          HandleSupPrepInOtionValue(
                            prepInterm.IDPrep,
                            choice.id
                          )
                        }
                      >
                        Supprimer
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </>
      ))}
      <Button
        onClick={() =>
          setOptionChoice((prev) => [
            ...prev,
            {
              id: uuidv4(),
              remote_id: null,
              name: "",
              description: "",
              image: "",
              price: 0,
            },
          ])
        }
      >
        Ajouter un choix possible
      </Button>
    </div>
  );
};

export default AddOptionChoice;
