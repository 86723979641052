import React, { useState, useEffect } from "react";
import "./DisplayOrderPrepLogistic.css";
import { getLogisticOrder } from "../../Data/firebaseService";

const DisplayOrderPrepLogistic = ({ OrderDate, setSelectedPrep }) => {
  const [LogisticOrder, setLogisticOrder] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      const Order = await getLogisticOrder(OrderDate);
      setLogisticOrder(Order);
    }
    fetchOrders();
  }, []);

  console.log(LogisticOrder);
  return (
    <div className="DisplayOrderPrepLogistic">
      <div className="DateOrder">
        COMMANDE DU :{" "}
        {LogisticOrder &&
          new Date(LogisticOrder.DateLivraison.seconds * 1000).getDate()}{" "}
        /{" "}
        {LogisticOrder &&
          new Date(LogisticOrder.DateLivraison.seconds * 1000).getMonth() +
            1}{" "}
        /
        {LogisticOrder &&
          new Date(LogisticOrder.DateLivraison.seconds * 1000).getFullYear()}
      </div>
      {LogisticOrder &&
        LogisticOrder.PreparationOrder.map((prep) => {
          return (
            <div
              className="PrepOrder"
              onClick={() => setSelectedPrep(prep.Nom)}
            >
              <div>{prep.Nom}</div>
              <div>
                <img className="imgPrep" src={prep.PhotoFirebase}></img>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DisplayOrderPrepLogistic;
