import { UilEstate, UilPackage } from "@iconscout/react-unicons";

export const SidebarDataManager = [
  {
    icon: UilEstate,
    heading: "Manager Dashboard",
  },
  {
    icon: UilEstate,
    heading: "Quand Refaire prep",
  },
];
