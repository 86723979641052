import React, { useState, useEffect } from "react";
import "./MainDashOwnerLogistics.css";
import TableGetRawMaterial from "../../../components/Table/TableGetRawMaterial/TableGetRawMaterial";
import { auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserInfo } from "../../../Data/firebaseGetUserInfo";
import CreationProduct from "../../../components/CreationProduct/CreationProduct";

const MainDashOwnerLogistics = () => {
  const [user, loading, error] = useAuthState(auth);
  const [Brand, setBrand] = useState("Loading...");

  useEffect(() => {
    async function fetchUserBrand() {
      const Userdata = await getUserInfo(user.uid);
      setBrand(Userdata.OwnerBrand);
    }
    fetchUserBrand();
  }, [user]);

  return (
    <div className="MainDash">
      <h1>Creation produit / Preparations</h1>
      <CreationProduct />
      <TableGetRawMaterial />
    </div>
  );
};

export default MainDashOwnerLogistics;
