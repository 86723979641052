import React, { useEffect, useState } from "react";
import { getaverageConssoPrep } from "../../../Data/firebaseInteractionManagerV2";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const IndicationQuandRefairePrep = ({ RestaurantName }) => {
  const [DataConsso, setDataConsso] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const AverageConssoPrep = await getaverageConssoPrep(RestaurantName);
      setDataConsso(AverageConssoPrep);
    }
    fetchData();
  }, []);

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Prep</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">qteEnStock</TableCell>
              <TableCell align="left">averageConsso</TableCell>
              <TableCell align="left">Unite De Mesure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {DataConsso ? (
              DataConsso.map((Prep) => {
                return (
                  <TableRow
                    key={Prep.prepName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {Prep.prepName}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Avatar alt={Prep.prepName} src={Prep.ImgPrep} />
                    </TableCell>
                    <TableCell align="left">{Prep.qteEnStock}</TableCell>
                    <TableCell align="left">{Prep.averageConsso}</TableCell>
                    <TableCell align="left">{Prep.UniteDeMesure}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <CircularProgress size={24} color="secondary" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default IndicationQuandRefairePrep;
