import React from "react";
import InProduction from "../../View/Cuisinier/InProduction";
import { SidebarDataCuisine } from "../../Data/DataUserModeCuisinier";
import Sidebar from "../../components/Sidebar/Sidebar";
import FicheTechinqueView from "../../View/Cuisinier/FicheTechinqueView";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Registration from "../../components/Authentification/Authentification";

import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";

const Cuisnier = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <Router>
      <Routes>
        {" "}
        <Route path="/" element={<Registration />} />{" "}
        <Route
          path="/in-production-Chaud"
          element={<InProduction Workplace="Chaud" />}
        />{" "}
        <Route
          path="/in-production-Froid"
          element={<InProduction Workplace="Froid" />}
        />{" "}
        <Route
          path="/in-production-Assemblage"
          element={<InProduction Workplace="Assemblage" />}
        />{" "}
        <Route
          path="/fiche-technique-Chaud"
          element={<FicheTechinqueView Workplace="Chaud" />}
        />{" "}
        <Route
          path="/fiche-technique-Froid"
          element={<FicheTechinqueView Workplace="Froid" />}
        />{" "}
      </Routes>{" "}
    </Router>
  );
};

export default Cuisnier;
