import React, { useState, useEffect } from "react";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import TextField from "@material-ui/core/TextField";
import {
  HandleAddMachineToPlanDeTravail,
  getAllMachine,
} from "../../../../Data/firebaseInteractionOwnerV2";

const AddMachineToPlanDeTravail = ({ RestaurantName, PlanDeTravailID }) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    RestaurantName: RestaurantName,
    PlanDeTravailID: PlanDeTravailID,
  });
  const [AllMachine, setAllMachine] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const Data = await getAllMachine();
      setAllMachine(Data);
    };
    fetchData();
  }, []);

  console.log(AllMachine);
  console.log(DataForm);
  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        ADD MACHINE
      </div>
      <ModalAvecFunction
        open={OpenModal}
        handleClose={handleCloseModal}
        Form={
          <div>
            {AllMachine &&
              AllMachine.map((machine) => {
                return (
                  <div
                    style={
                      DataForm.hasOwnProperty("ListeMachine") &&
                      DataForm.ListeMachine.includes(machine.IDPepers)
                        ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            boxShadow: "0px 0px 10px 0px #c68787",
                          }
                        : {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            boxShadow: "0px 0px 10px 0px #000000",
                          } &&
                          machine.hasOwnProperty(
                            "AssociateToPlanDeTravailID"
                          ) &&
                          machine.AssociateToPlanDeTravailID !== ""
                        ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            boxShadow: "0px 0px 10px 0px #c68787",
                          }
                        : null
                    }
                    onClick={() =>
                      setDataForm((prev) => {
                        let newListeMachine = [];
                        if (prev.hasOwnProperty("ListeMachine")) {
                          newListeMachine = [
                            ...prev.ListeMachine,
                            machine.IDPepers,
                          ];
                        } else {
                          newListeMachine = [machine.IDPepers];
                        }
                        return { ...prev, ListeMachine: newListeMachine };
                      })
                    }
                  >
                    {machine.name}
                  </div>
                );
              })}
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter une machine au plan de travail"}
        DataForm={{ ...DataForm }}
        Function={HandleAddMachineToPlanDeTravail}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default AddMachineToPlanDeTravail;
