import React from "react";
import "./RightSideDEMO.css";
import UpdatesDEMO from "../../Updates/UpdatesDEMO/UpdatesDEMO";
import CustomerReview from "../../CustomerReview/CustomerReview";

const RightSideDEMO = () => {
  return (
    <div className="RightSide">
      <div>
        <h3>Facturation</h3>
        <UpdatesDEMO />
      </div>
      <div>
        <h3>Customer Review</h3>
        <CustomerReview />
      </div>
    </div>
  );
};

export default RightSideDEMO;
