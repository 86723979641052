import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { UpdateMatierePremiere } from "../../../Functions/HandleClickFunctions";
export default function ModalModificationMatierePremiere({
  open,
  handleClose,
  Matiere,
}) {
  const [AllDataMatiere, setAllDataMatiere] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      setAllDataMatiere(Matiere);
    }

    fetchOrders();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="container">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%", // Ajustez cette valeur selon vos besoins
            maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {AllDataMatiere && AllDataMatiere.Nom}
          <div>
            Unite de mesure :{" "}
            {AllDataMatiere && AllDataMatiere["Unité de recettes"]}
          </div>
          <div>
            Conditionnement : {AllDataMatiere && AllDataMatiere.Conditionnement}
          </div>
          <div>
            Prix du conditionnement :{" "}
            {AllDataMatiere && AllDataMatiere["Prix HT"]}
          </div>
          {AllDataMatiere !== null && (
            <>
              <TextField
                label="Prix/Unite De recette"
                variant="outlined"
                value={
                  AllDataMatiere.hasOwnProperty("PrixURecetteHT")
                    ? AllDataMatiere.PrixURecetteHT
                    : ""
                }
                onChange={(e) =>
                  setAllDataMatiere((oldData) => ({
                    ...oldData,
                    PrixURecetteHT: e.target.value,
                  }))
                }
              />
              <TextField
                label="CONDITIONNEMENT"
                variant="outlined"
                value={
                  AllDataMatiere.hasOwnProperty("Conditionnement")
                    ? AllDataMatiere.Conditionnement
                    : ""
                }
                onChange={(e) =>
                  setAllDataMatiere((oldData) => ({
                    ...oldData,
                    Conditionnement: e.target.value,
                  }))
                }
              />
              <TextField
                label="QTE CONDITIONNEMENT"
                variant="outlined"
                value={
                  AllDataMatiere.hasOwnProperty("QteConditionnement")
                    ? AllDataMatiere.QteConditionnement
                    : ""
                }
                onChange={(e) =>
                  setAllDataMatiere((oldData) => ({
                    ...oldData,
                    QteConditionnement: e.target.value.replace(/,/g, "."),
                  }))
                }
              />
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              UpdateMatierePremiere(AllDataMatiere);
              handleClose();
            }}
          >
            Ajouter
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
