import { Avatar } from "@mui/material";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { IoIosWarning } from "react-icons/io";

const DisplayFicheTechinique = ({ ProductFormatedData }) => {
  console.log(ProductFormatedData);
  return (
    <div style={{ maxWidth: "45%", maxHeight: "90vh", overflow: "auto" }}>
      <div
        style={{
          backgroundImage: `url(${ProductFormatedData.Production.img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "500px", // Définissez la hauteur souhaitée de votre div
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // Fond blanc semi-transparent
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          {ProductFormatedData.Production.name}
        </div>
      </div>

      {ProductFormatedData.hasOwnProperty("Warning") && <div>WARNING</div>}
      {ProductFormatedData.Supplements.length > 0 && (
        <div>
          {ProductFormatedData.Supplements.map((sup) => (
            <div
              style={{
                display: "flex",
                flexWrap: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IoIosWarning size={40} />{" "}
                <span
                  style={{ color: "red", fontWeight: "bold", fontSize: "26px" }}
                >
                  SUPPLEMENT
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    border: "1px solid black",
                    borderRadius: "5px",
                    margin: "5px",
                  }}
                >
                  {sup.preparationIntermediaire.map((prep) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>{prep.PrepData.name}</div>
                      <Avatar
                        alt={prep.PrepData.name}
                        src={prep.PrepData.img}
                        style={{ width: "80px", height: "80px" }}
                      />
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {prep.QteUsedInSuplement}
                      </div>
                      <div>{prep.PrepData.UniteDeMesure}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Prep</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="left">Qte vendu</TableCell>
                <TableCell align="left">Unite De Mesure</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {ProductFormatedData.Production.PreparationIntermediaire.map(
                (Prep) => {
                  return (
                    <TableRow
                      key={Prep.PrepData.name}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontSize: "26px", fontWeight: "bold" }}
                      >
                        {Prep.PrepData.name}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Avatar
                          alt={Prep.PrepData.name}
                          src={Prep.PrepData.img}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {Prep.QteUsedInProduct}
                      </TableCell>
                      <TableCell align="left">
                        {Prep.PrepData.UniteDeMesure}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DisplayFicheTechinique;
