import React from "react";
import "./DisplayCommandeMatiereEnLivraison.css";
import AllCommandeEnCours from "./AllCommandeEnCours/AllCommandeEnCours";
import PasserUneCommandeMatiere from "../../OwnerV2Components/Button/PasserUneCommandeMatiere/PasserUneCommandeMatiere";

const DisplayCommandeMatiereEnLivraison = ({ RestaurantName }) => {
  return (
    <div className="ContenerDisplayCommandeMatiereEnLivraison">
      <AllCommandeEnCours RestaurantName={RestaurantName} />
      <PasserUneCommandeMatiere RestaurantName={RestaurantName} />
    </div>
  );
};

export default DisplayCommandeMatiereEnLivraison;
