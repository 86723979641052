import React, { useState, useEffect } from "react";
import "./DisplayPrepDetailUsedInDish.css";
import {
  getPrepObjectForPrepUsedInDish,
  getIngredientsObjectInSelectedPrep,
} from "../../../../Data/firebaseInteractionOwnerV2";
import Avatar from "@mui/material/Avatar";

const DisplayPrepDetailUsedInDish = ({ SelectedDish }) => {
  const [PrepObjectInSelectedDish, setPrepObjectInSelectedDish] =
    useState(null);

  const [SlectedPrep, SetSlectedPrep] = useState(null);
  const [ListeIngredientsObject, setListeIngredientsObject] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      let PrepObjectInSelectedDish = null;
      if (SelectedDish) {
        PrepObjectInSelectedDish = await getPrepObjectForPrepUsedInDish(
          SelectedDish
        );
      }
      setPrepObjectInSelectedDish(PrepObjectInSelectedDish);
    }
    fetchOrders();
  }, [SelectedDish]);

  useEffect(() => {
    async function fetchOrders() {
      let IngredientsObjectInSelectedPrep = null;
      if (SlectedPrep) {
        IngredientsObjectInSelectedPrep =
          await getIngredientsObjectInSelectedPrep(SlectedPrep);
      }
      setListeIngredientsObject(IngredientsObjectInSelectedPrep);
    }
    fetchOrders();
  }, [SlectedPrep]);

  useEffect(() => {
    SetSlectedPrep(null);
  }, [SelectedDish]);

  return (
    <div className="">
      {PrepObjectInSelectedDish &&
        PrepObjectInSelectedDish.map((Prep) => (
          <div
            className={
              SlectedPrep && SlectedPrep.IDPepers === Prep.IDPepers
                ? "RowRefIngredientMatiere"
                : "RowRefMatiere"
            }
            onClick={() => SetSlectedPrep(Prep)}
          >
            {Prep.name} Qte : {Prep.QteUsedInDish} {Prep.UniteDeMesure}
          </div>
        ))}
      {SlectedPrep && (
        <div className="DetailRefMatiere" onClick={() => SetSlectedPrep(null)}>
          <h1>Nom PREP : {SlectedPrep.name}</h1>
          {ListeIngredientsObject && (
            <div>
              LISTE DES INGREDIENTS :
              {ListeIngredientsObject.map((Ingredient) => (
                <div className="RowMatiereInPrep">
                  <div>{Ingredient.name}</div>
                  <div>
                    // QTE IN THE PREP : {Ingredient.QteUsedInPrep}{" "}
                    {Ingredient.UniteDeRecette}
                  </div>
                  <Avatar alt={Ingredient.name} src={Ingredient.Img} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DisplayPrepDetailUsedInDish;
