import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./ModaladdNewBrand.css";
import { AddNewBrand } from "../../../Functions/HandleClickFunctions";

import { getAllDish, getAllSup } from "../../../Data/firebaseService";
import ButtonAddNewSup from "../../../components/Button/ButtonAddNewSup/ButtonAddNewSup";

export default function ModaladdNewBrand({ open, handleClose }) {
  const [BrandName, setBrandName] = useState("");
  const [ListeAllProduct, setListeAllProduct] = useState(null);
  const [ProductIntheGroup, setProductIntheGroup] = useState([]);
  const [ListeAllSup, setListeAllSup] = useState([]);
  const [supInTheGroup, setSupInTheGroup] = useState([]);
  const [ProductIntheEaticTest, setProductIntheEaticTest] = useState([]);

  console.log(ProductIntheGroup);

  useEffect(() => {
    async function fetchOrders() {
      const AllDish = await getAllDish();
      setListeAllProduct(AllDish);
      const Allsup = await getAllSup();
      setListeAllSup(Allsup);
    }

    fetchOrders();
  }, []);

  const HandelSupProdEaticTest = (NomProd) => {
    setProductIntheEaticTest((PrevListProd) => {
      return PrevListProd.filter((Prod) => Prod.Production.name !== NomProd);
    });
  };

  const HandelSupProd = (NomProd) => {
    setProductIntheGroup((PrevListProd) => {
      return PrevListProd.filter((Prod) => Prod.Production.name !== NomProd);
    });
  };
  const HandelSupSupplement = (NomSupplement) => {
    setProductIntheGroup((PrevListSup) => {
      return PrevListSup.filter((Sup) => Sup !== NomSupplement);
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ fontSize: "35px" }}>Ajouter une Marque</div>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom de la marque"
            variant="outlined"
            value={BrandName}
            onChange={(e) => setBrandName(e.target.value)}
          />
        </Typography>
        <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
          <InputLabel id="select-unite-Preparation">Product</InputLabel>
          <Select
            labelId="select-unite-label-Type-Preparation"
            id="select-unite-Type-Preparation"
            value={ProductIntheGroup[ProductIntheGroup.length]}
            onChange={(e) =>
              setProductIntheGroup((prev) => [...prev, e.target.value])
            }
          >
            {ListeAllProduct &&
              ListeAllProduct.map((product) => {
                return (
                  <MenuItem value={product}>{product.Production.name}</MenuItem>
                );
              })}
          </Select>
          <ButtonAddNewSup />
        </FormControl>

        {ProductIntheGroup.map((Prod) => {
          return (
            <div>
              {Prod.Production.name}
              <span
                onClick={() => HandelSupProd(Prod.Production.name)}
                className="SupprimerPrep"
              >
                ___Supprimer
              </span>
            </div>
          );
        })}
        <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
          <InputLabel id="select-unite-Preparation">Eatic Test</InputLabel>
          <Select
            labelId="select-unite-label-Type-Preparation"
            id="select-unite-Type-Preparation"
            value={ProductIntheEaticTest[ProductIntheEaticTest.length]}
            onChange={(e) =>
              setProductIntheEaticTest((prev) => [...prev, e.target.value])
            }
          >
            {ListeAllProduct &&
              ListeAllProduct.map((product) => {
                return (
                  <MenuItem value={product}>{product.Production.name}</MenuItem>
                );
              })}
          </Select>
        </FormControl>

        {ProductIntheEaticTest.map((Prod) => {
          return (
            <div>
              {Prod.Production.name}
              <span
                onClick={() => HandelSupProdEaticTest(Prod.Production.name)}
                className="SupprimerPrep"
              >
                ___Supprimer
              </span>
            </div>
          );
        })}

        <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
          <InputLabel id="select-unite-Preparation">Sup</InputLabel>
          <Select
            labelId="select-unite-label-Type-Preparation"
            id="select-unite-Type-Preparation"
            value={supInTheGroup[supInTheGroup.length]}
            onChange={(e) =>
              setSupInTheGroup((prev) => [...prev, e.target.value])
            }
          >
            {ListeAllSup.map((Sup) => {
              return <MenuItem value={Sup}>{Sup.Name}</MenuItem>;
            })}
          </Select>
        </FormControl>

        {supInTheGroup.map((Sup) => {
          return (
            <div>
              {Sup.Name}
              <span
                onClick={() => HandelSupSupplement(Sup.Name)}
                className="SupprimerPrep"
              >
                ___Supprimer
              </span>
            </div>
          );
        })}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              AddNewBrand(
                BrandName,
                ProductIntheGroup,
                supInTheGroup,
                ProductIntheEaticTest
              );
              handleClose();
            }}
          >
            Nouvelle Marque
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}
