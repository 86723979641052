import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./UpdatesProductGroup.css";

import { GetProductGroupData } from "../../../Data/firebaseService";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import ButtonCreateProductGroup from "../../../components/Button/ButtonCreateProductGroup/ButtonCreateProductGroup";

const UpdatesProductGroup = () => {
  const [data, setData] = useState([]);

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchData() {
      const data = await GetProductGroupData();
      setData(data);
    }
    fetchData();
  }, [Production]);

  return (
    <div className="Updates">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Groupe de produit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.NameOfProductGroup}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonCreateProductGroup />
    </div>
  );
};

export default UpdatesProductGroup;
