import React, { useState, useEffect } from "react";
import { getAllPepersOptionsValue } from "../../../../Data/firebaseInteractionOwnerV2";
import AssocietePepersOptionValueToZeltyOptionValue from "../../Button/AssocietePepersOptionValueToZeltyOptionValue/AssocietePepersOptionValueToZeltyOptionValue";

const DisplayOptionDetails = () => {
  const [Data, setData] = useState([]);
  useEffect(() => {
    async function fetchOrders() {
      const AllPepersOptions = await getAllPepersOptionsValue();
      setData(AllPepersOptions);
    }
    fetchOrders();
  }, []);

  console.log(Data);

  return (
    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
      {Data &&
        Data.map((OptionValuePeper) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "15px",
                borderRadius: "15px",
                background: "gray",
                margin: "10px",
              }}
            >
              <div>{OptionValuePeper.name}</div>
              <AssocietePepersOptionValueToZeltyOptionValue
                IDPeperOptionValue={OptionValuePeper.IDPepers}
              />
            </div>
          );
        })}
    </div>
  );
};

export default DisplayOptionDetails;
