import {
  getFirestore,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { app, firestore, storage } from "../firebaseConfig";
import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilSignOutAlt,
  UilUsdSquare,
  UilMoneyWithdrawal,
} from "@iconscout/react-unicons";
import { formatDateReadable, formatDateISO } from "./FormatDate";
import { compterOccurrencesInStringarray } from "../Functions/SubFunctions";

export async function getCardsData(userID, date) {
  // recupere toute les commandes
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(500)
  );
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((doc) => doc.data());
  // Recupere le nom de la marque du client
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());
  let userBrand = [];
  usersData.forEach(
    (userdata) => userdata.uid === userID && userBrand.push(userdata.OwnerBrand)
  );
  // Recupere le pricing de la marque du client
  const pepersDoc = doc(getFirestore(app), "pepers", userBrand[0]);
  const pepersSnapshot = await getDoc(pepersDoc);
  let UserBrandPricing = null;
  if (pepersSnapshot.exists()) {
    UserBrandPricing = pepersSnapshot.data();
  }
  // Trie les datas pour avoir que les commandes de la marque du client
  let ordersClientBrand = [];
  ordersData.forEach((commande) => {
    commande.dataOrder.order.virtual_brand_name === userBrand[0] &&
      ordersClientBrand.push(commande);
    // Si une commande vient de eatic et que un des produit propre a la marque est contenu dans la commande alors la commande est comptabilisée pour cette marque
    if (commande.dataOrder.order.channel === "EATIC") {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
    // si une commande n'a pas de marque c'est probalement qu'elle est passé depuis le module de commande alors on effecture le meme test
    if (
      commande.dataOrder.order.virtual_brand_name === null &&
      commande.dataOrder.order.channel === "Zelty Web"
    ) {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
  });
  // Trie les commandes pour avoir que les commandes de la marque du client et entre deux date
  let ordersClientBrandbetweenDate = [];
  ordersClientBrand.forEach((commande) => {
    let dateCommande = new Date(commande.dataOrder.order.created_at);
    if (dateCommande > date[0] && dateCommande < date[1]) {
      ordersClientBrandbetweenDate.push(commande);
    }
  });
  // Chiffre d'affaire entre les deux date de la variable d'etat
  let CAEntreDeuxDate = 0;
  ordersClientBrandbetweenDate.forEach((commande) => {
    CAEntreDeuxDate =
      CAEntreDeuxDate +
      commande.dataOrder.order.price.final_amount_exc_tax / 100;
  });
  CAEntreDeuxDate = CAEntreDeuxDate.toFixed(2);
  // Panier moyen
  let PanierMoyen = CAEntreDeuxDate / ordersClientBrandbetweenDate.length;
  PanierMoyen = Number(PanierMoyen.toFixed(2));
  // NB Jours entre les deux date de la variable d'etat
  let NBJoursEntreDeuxDate =
    (date[1].getTime() - date[0].getTime()) / (1000 * 3600 * 24);
  NBJoursEntreDeuxDate = NBJoursEntreDeuxDate.toFixed(0);
  // NB de commandes par j
  let NBCommandeParJ =
    ordersClientBrandbetweenDate.length / NBJoursEntreDeuxDate;
  NBCommandeParJ = Number(NBCommandeParJ.toFixed(2));
  // CA par jours + Liste des jours pour abscisse de la coube (x)
  let CAParJour = ordersClientBrandbetweenDate.reduce((acc, cur) => {
    const DateCreation = new Date(cur.dataOrder.order.created_at);
    const key = `${DateCreation.getFullYear()}-${
      DateCreation.getMonth() + 1
    }-${DateCreation.getDate()}`;
    acc[key] =
      (acc[key] || 0) + cur.dataOrder.order.price.final_amount_exc_tax / 100;
    return acc;
  }, {});
  let listeDesJours = Object.keys(CAParJour);
  listeDesJours = listeDesJours.map((jour) => formatDateISO(new Date(jour)));
  CAParJour = Object.values(CAParJour);
  CAParJour = CAParJour.map((ca) => Number(ca.toFixed(2)));
  // Nombre de commandes par jour
  let nbCommandesParJour = ordersClientBrandbetweenDate.reduce((acc, cur) => {
    const DateCreation = new Date(cur.dataOrder.order.created_at);
    const key = `${DateCreation.getFullYear()}-${
      DateCreation.getMonth() + 1
    }-${DateCreation.getDate()}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});
  let listeDesJoursNBCommande = Object.keys(nbCommandesParJour);
  listeDesJoursNBCommande = listeDesJoursNBCommande.map((jour) =>
    formatDateISO(new Date(jour))
  );
  nbCommandesParJour = Object.values(nbCommandesParJour);
  // Panier moyen Par jour
  let PanierMoyenParJour = [];
  for (let i = 0; i < CAParJour.length; i++) {
    let PanierMoyenJ = CAParJour[i] / nbCommandesParJour[i];
    PanierMoyenParJour.push(Number(PanierMoyenJ.toFixed(2)));
  }
  // Formate les data sous forme d'un tableau
  const CardsData = [
    {
      title: "Chiffre D'affaire",
      sousTitre: `Pepers`,
      color: {
        backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
        boxShadow: "0px 10px 20px 0px #e0c6f5",
      },
      barValue: 70,
      value: `${CAEntreDeuxDate} €`,
      png: UilUsdSquare,
      series: [
        {
          name: "CA",
          data: CAParJour,
        },
      ],
      categorie: listeDesJours,
    },
    {
      title: "Commandes",
      sousTitre: `Pepers`,
      color: {
        backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
        boxShadow: "0px 10px 20px 0px #FDC0C7",
      },
      barValue: 80,
      value: `${12.6} /J`,
      png: UilMoneyWithdrawal,
      series: [
        {
          name: "Commandes",
          data: nbCommandesParJour,
        },
      ],
      categorie: listeDesJoursNBCommande,
    },
    {
      title: "Panier Moyen",
      sousTitre: `Pepers`,
      color: {
        backGround:
          "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
        boxShadow: "0px 10px 20px 0px #F9D59B",
      },
      barValue: 60,
      value: `${PanierMoyen} €`,
      png: UilClipboardAlt,
      series: [
        {
          name: "Panier Moyen",
          data: PanierMoyenParJour,
        },
        {
          name: "Commandes",
          data: nbCommandesParJour,
        },
      ],
      categorie: listeDesJoursNBCommande,
    },
  ];
  return CardsData;
}
export async function getCardsDEMOData(userID, date) {
  // recupere toute les commandes
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(500)
  );
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((doc) => doc.data());
  // Recupere le nom de la marque du client
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());
  let userBrand = [];
  usersData.forEach(
    (userdata) => userdata.uid === userID && userBrand.push(userdata.OwnerBrand)
  );
  // Recupere le pricing de la marque du client
  const pepersDoc = doc(getFirestore(app), "pepers", userBrand[0]);
  const pepersSnapshot = await getDoc(pepersDoc);
  let UserBrandPricing = null;
  if (pepersSnapshot.exists()) {
    UserBrandPricing = pepersSnapshot.data();
  }
  // Trie les datas pour avoir que les commandes de la marque du client
  let ordersClientBrand = [];
  ordersData.forEach((commande) => {
    commande.dataOrder.order.virtual_brand_name === userBrand[0] &&
      ordersClientBrand.push(commande);
    // Si une commande vient de eatic et que un des produit propre a la marque est contenu dans la commande alors la commande est comptabilisée pour cette marque
    if (commande.dataOrder.order.channel === "EATIC") {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
    // si une commande n'a pas de marque c'est probalement qu'elle est passé depuis le module de commande alors on effecture le meme test
    if (
      commande.dataOrder.order.virtual_brand_name === null &&
      commande.dataOrder.order.channel === "Zelty Web"
    ) {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
  });
  // Trie les commandes pour avoir que les commandes de la marque du client et entre deux date
  let ordersClientBrandbetweenDate = [];
  ordersClientBrand.forEach((commande) => {
    let dateCommande = new Date(commande.dataOrder.order.created_at);
    if (dateCommande > date[0] && dateCommande < date[1]) {
      ordersClientBrandbetweenDate.push(commande);
    }
  });
  // Chiffre d'affaire entre les deux date de la variable d'etat
  let CAEntreDeuxDate = 0;
  ordersClientBrandbetweenDate.forEach((commande) => {
    CAEntreDeuxDate =
      CAEntreDeuxDate +
      commande.dataOrder.order.price.final_amount_exc_tax / 100;
  });
  CAEntreDeuxDate = CAEntreDeuxDate.toFixed(2);
  // Panier moyen
  let PanierMoyen = CAEntreDeuxDate / ordersClientBrandbetweenDate.length;
  PanierMoyen = Number(PanierMoyen.toFixed(2));
  // NB Jours entre les deux date de la variable d'etat
  let NBJoursEntreDeuxDate =
    (date[1].getTime() - date[0].getTime()) / (1000 * 3600 * 24);
  NBJoursEntreDeuxDate = NBJoursEntreDeuxDate.toFixed(0);
  // NB de commandes par j
  let NBCommandeParJ =
    ordersClientBrandbetweenDate.length / NBJoursEntreDeuxDate;
  NBCommandeParJ = Number(NBCommandeParJ.toFixed(2));
  // CA par jours + Liste des jours pour abscisse de la coube (x)
  let CAParJour = ordersClientBrandbetweenDate.reduce((acc, cur) => {
    const DateCreation = new Date(cur.dataOrder.order.created_at);
    const key = `${DateCreation.getFullYear()}-${
      DateCreation.getMonth() + 1
    }-${DateCreation.getDate()}`;
    acc[key] =
      (acc[key] || 0) + cur.dataOrder.order.price.final_amount_exc_tax / 100;
    return acc;
  }, {});
  let listeDesJours = Object.keys(CAParJour);
  listeDesJours = listeDesJours.map((jour) => formatDateISO(new Date(jour)));
  CAParJour = Object.values(CAParJour);
  CAParJour = CAParJour.map((ca) => Number(ca.toFixed(2)));
  // Nombre de commandes par jour
  let nbCommandesParJour = ordersClientBrandbetweenDate.reduce((acc, cur) => {
    const DateCreation = new Date(cur.dataOrder.order.created_at);
    const key = `${DateCreation.getFullYear()}-${
      DateCreation.getMonth() + 1
    }-${DateCreation.getDate()}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});
  let listeDesJoursNBCommande = Object.keys(nbCommandesParJour);
  listeDesJoursNBCommande = listeDesJoursNBCommande.map((jour) =>
    formatDateISO(new Date(jour))
  );
  nbCommandesParJour = Object.values(nbCommandesParJour);
  // Panier moyen Par jour
  let PanierMoyenParJour = [];
  for (let i = 0; i < CAParJour.length; i++) {
    let PanierMoyenJ = CAParJour[i] / nbCommandesParJour[i];
    PanierMoyenParJour.push(Number(PanierMoyenJ.toFixed(2)));
  }
  // Formate les data sous forme d'un tableau
  const CardsData = [
    {
      title: "Chiffre D'affaire",
      sousTitre: `Pepers`,
      color: {
        backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
        boxShadow: "0px 10px 20px 0px #e0c6f5",
      },
      barValue: 70,
      value: `102 786,98 €`,
      png: UilUsdSquare,
      series: [
        {
          name: "CA",
          data: CAParJour,
        },
      ],
      categorie: listeDesJours,
    },
    {
      title: "Commandes",
      sousTitre: `Pepers`,
      color: {
        backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
        boxShadow: "0px 10px 20px 0px #FDC0C7",
      },
      barValue: 80,
      value: `48,62 /J`,
      png: UilMoneyWithdrawal,
      series: [
        {
          name: "Commandes",
          data: nbCommandesParJour,
        },
      ],
      categorie: listeDesJoursNBCommande,
    },
    {
      title: "Panier Moyen",
      sousTitre: `Pepers`,
      color: {
        backGround:
          "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
        boxShadow: "0px 10px 20px 0px #F9D59B",
      },
      barValue: 60,
      value: `30,89 €`,
      png: UilClipboardAlt,
      series: [
        {
          name: "Panier Moyen",
          data: PanierMoyenParJour,
        },
        {
          name: "Commandes",
          data: nbCommandesParJour,
        },
      ],
      categorie: listeDesJoursNBCommande,
    },
  ];
  return CardsData;
}

export async function getCardsDataCommandePreparation(userID, date) {
  const CardsData = [
    {
      title: "Dépenses matiére",
      sousTitre: `Depuis le ${formatDateReadable(date[0])}`,
      color: {
        backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
        boxShadow: "0px 10px 20px 0px #e0c6f5",
      },
      barValue: 70,
      value: `2500 €`,
      png: UilUsdSquare,
      series: [
        {
          name: "CA",
          data: [1, 1],
        },
      ],
      categorie: [1, 1],
    },
    {
      title: "Commandes",
      sousTitre: `Depuis le ${formatDateReadable(date[0])}`,
      color: {
        backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
        boxShadow: "0px 10px 20px 0px #FDC0C7",
      },
      barValue: 80,
      value: `10 /J`,
      png: UilMoneyWithdrawal,
      series: [
        {
          name: "Commandes",
          data: [1, 1],
        },
      ],
      categorie: [1, 1],
    },
    {
      title: "Commandes",
      sousTitre: `Depuis le ${formatDateReadable(date[0])}`,
      color: {
        backGround:
          "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
        boxShadow: "0px 10px 20px 0px #F9D59B",
      },
      barValue: 80,
      value: `10 /J`,
      png: UilMoneyWithdrawal,
      series: [
        {
          name: "Commandes",
          data: [1, 1],
        },
      ],
      categorie: [1, 1],
    },
  ];
  return CardsData;
}

export async function getFacturationData(userID, date) {
  // recupere toute les commandes
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(500)
  );
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((doc) => doc.data());
  // Recupere le nom de la marque du client
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());
  let userBrand = [];
  usersData.forEach(
    (userdata) => userdata.uid === userID && userBrand.push(userdata.OwnerBrand)
  );
  // Recupere le pricing de la marque du client
  const pepersDoc = doc(getFirestore(app), "pepers", userBrand[0]);
  const pepersSnapshot = await getDoc(pepersDoc);
  let UserBrandPricing = null;
  if (pepersSnapshot.exists()) {
    UserBrandPricing = pepersSnapshot.data();
  }
  // Trie les datas pour avoir que les commandes de la marque du client
  let ordersClientBrand = [];
  ordersData.forEach((commande) => {
    commande.dataOrder.order.virtual_brand_name === userBrand[0] &&
      ordersClientBrand.push(commande);
    // Si une commande vient de eatic et que un des produit propre a la marque est contenu dans la commande alors la commande est comptabilisée pour cette marque
    if (commande.dataOrder.order.channel === "EATIC") {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
    // si une commande n'a pas de marque c'est probalement qu'elle est passé depuis le module de commande alors on effecture le meme test
    if (
      commande.dataOrder.order.virtual_brand_name === null &&
      commande.dataOrder.order.channel === "Zelty Web"
    ) {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
  });
  // On retire les commandes annulée de la liste des commandes a facturée
  let ordersClientBrandWithOutCancelled = [];
  ordersClientBrand.forEach((commande) => {
    if (commande.status !== "cancelled") {
      ordersClientBrandWithOutCancelled.push(commande);
    }
  });
  // Trie les commandes pour avoir que les commandes de la marque du client et entre deux date
  let ordersClientBrandbetweenDate = [];
  ordersClientBrandWithOutCancelled.forEach((commande) => {
    let dateCommande = new Date(commande.dataOrder.order.created_at);
    if (dateCommande > date[0] && dateCommande < date[1]) {
      ordersClientBrandbetweenDate.push(commande);
    }
  });
  // Compte les produits a facturer
  const ListeProduitAFacture = [];
  const ListeSupAFacture = [];
  ordersClientBrandbetweenDate.forEach((commande) => {
    commande.listeProduitCommande.forEach((produit) => {
      ListeProduitAFacture.push(produit.name);
      if (produit.modifiers.lenght != 0) {
        produit.modifiers.forEach((sup) => {
          ListeSupAFacture.push(sup.name);
        });
      }
    });
  });
  const compteurProduit = ListeProduitAFacture.reduce((acc, produit) => {
    acc[produit] = (acc[produit] || 0) + 1;
    return acc;
  }, {});

  const compteurSup = ListeSupAFacture.reduce((acc, sup) => {
    acc[sup] = (acc[sup] || 0) + 1;
    return acc;
  }, {});

  // Calcul de la prochaine facture pour les produits vendu
  let PrixAfacturer = 0;
  Object.keys(compteurProduit).forEach((produitAfacturer) => {
    console.log(produitAfacturer);
    UserBrandPricing.Pricing.forEach((produit) => {
      if (produit.NomZelty === produitAfacturer) {
        console.log(produitAfacturer);
        console.log(produit.Cost);
        console.log(compteurProduit[produit.NomZelty]);
        PrixAfacturer =
          PrixAfacturer + produit.Cost * compteurProduit[produit.NomZelty];
      }
    });
  });
  Object.keys(compteurSup).forEach((produitAfacturer) => {
    console.log(produitAfacturer);
    UserBrandPricing.Pricing.forEach((produit) => {
      if (produit.NomZelty === produitAfacturer) {
        console.log(produitAfacturer);
        console.log(produit.Cost);
        console.log(compteurSup[produit.NomZelty]);
        PrixAfacturer =
          PrixAfacturer + produit.Cost * compteurSup[produit.NomZelty];
      }
    });
  });
  console.log(ordersClientBrandbetweenDate.length);
  console.log(ordersClientBrandbetweenDate.length * 0.35);
  PrixAfacturer = PrixAfacturer + ordersClientBrandbetweenDate.length * 0.35;
  PrixAfacturer = Number(PrixAfacturer.toFixed(2));
  // Trier les produit du meilleur au moins bon
  function sortObject(obj) {
    const entries = Object.entries(obj);
    entries.sort((a, b) => b[1] - a[1]);

    const keys = [];
    const values = [];
    for (const [key, value] of entries) {
      keys.push(key);
      values.push(value);
    }
    return { keys, values };
  }

  const keysProd = sortObject(compteurProduit);
  const keysSup = sortObject(compteurSup);

  // Formater data en tableau de chaine de caractére
  const sumProduit = keysProd.keys.map((produit) => {
    return `${produit}: ${compteurProduit[produit]}`;
  });

  const sumSup = keysSup.keys.map((sup) => {
    return `${sup}: ${compteurSup[sup]}`;
  });

  // Formater data sous forme d'objet
  const Data = {
    ProduitAFacturer: sumProduit.concat(sumSup),
    PrixAfacturer: PrixAfacturer,
  };

  return Data;
}
export async function getFacturationDataDEMO(userID, date) {
  // recupere toute les commandes
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(500)
  );
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((doc) => doc.data());
  // Recupere le nom de la marque du client
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());
  let userBrand = [];
  usersData.forEach(
    (userdata) => userdata.uid === userID && userBrand.push(userdata.OwnerBrand)
  );
  // Recupere le pricing de la marque du client
  const pepersDoc = doc(getFirestore(app), "pepers", userBrand[0]);
  const pepersSnapshot = await getDoc(pepersDoc);
  let UserBrandPricing = null;
  if (pepersSnapshot.exists()) {
    UserBrandPricing = pepersSnapshot.data();
  }
  // Trie les datas pour avoir que les commandes de la marque du client
  let ordersClientBrand = [];
  ordersData.forEach((commande) => {
    commande.dataOrder.order.virtual_brand_name === userBrand[0] &&
      ordersClientBrand.push(commande);
    // Si une commande vient de eatic et que un des produit propre a la marque est contenu dans la commande alors la commande est comptabilisée pour cette marque
    if (commande.dataOrder.order.channel === "EATIC") {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
    // si une commande n'a pas de marque c'est probalement qu'elle est passé depuis le module de commande alors on effecture le meme test
    if (
      commande.dataOrder.order.virtual_brand_name === null &&
      commande.dataOrder.order.channel === "Zelty Web"
    ) {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
  });
  // On retire les commandes annulée de la liste des commandes a facturée
  let ordersClientBrandWithOutCancelled = [];
  ordersClientBrand.forEach((commande) => {
    if (commande.status !== "cancelled") {
      ordersClientBrandWithOutCancelled.push(commande);
    }
  });
  // Trie les commandes pour avoir que les commandes de la marque du client et entre deux date
  let ordersClientBrandbetweenDate = [];
  ordersClientBrandWithOutCancelled.forEach((commande) => {
    let dateCommande = new Date(commande.dataOrder.order.created_at);
    if (dateCommande > date[0] && dateCommande < date[1]) {
      ordersClientBrandbetweenDate.push(commande);
    }
  });
  // Compte les produits a facturer
  const ListeProduitAFacture = [];
  const ListeSupAFacture = [];
  ordersClientBrandbetweenDate.forEach((commande) => {
    commande.listeProduitCommande.forEach((produit) => {
      ListeProduitAFacture.push(produit.name);
      if (produit.modifiers.lenght != 0) {
        produit.modifiers.forEach((sup) => {
          ListeSupAFacture.push(sup.name);
        });
      }
    });
  });
  const compteurProduit = ListeProduitAFacture.reduce((acc, produit) => {
    acc[produit] = (acc[produit] || 0) + 1;
    return acc;
  }, {});

  const compteurSup = ListeSupAFacture.reduce((acc, sup) => {
    acc[sup] = (acc[sup] || 0) + 1;
    return acc;
  }, {});

  // Calcul de la prochaine facture pour les produits vendu
  let PrixAfacturer = 0;
  Object.keys(compteurProduit).forEach((produitAfacturer) => {
    console.log(produitAfacturer);
    UserBrandPricing.Pricing.forEach((produit) => {
      if (produit.NomZelty === produitAfacturer) {
        console.log(produitAfacturer);
        console.log(produit.Cost);
        console.log(compteurProduit[produit.NomZelty]);
        PrixAfacturer =
          PrixAfacturer + produit.Cost * compteurProduit[produit.NomZelty];
      }
    });
  });
  Object.keys(compteurSup).forEach((produitAfacturer) => {
    console.log(produitAfacturer);
    UserBrandPricing.Pricing.forEach((produit) => {
      if (produit.NomZelty === produitAfacturer) {
        console.log(produitAfacturer);
        console.log(produit.Cost);
        console.log(compteurSup[produit.NomZelty]);
        PrixAfacturer =
          PrixAfacturer + produit.Cost * compteurSup[produit.NomZelty];
      }
    });
  });
  console.log(ordersClientBrandbetweenDate.length);
  console.log(ordersClientBrandbetweenDate.length * 0.35);
  PrixAfacturer = PrixAfacturer + ordersClientBrandbetweenDate.length * 0.35;
  PrixAfacturer = Number(PrixAfacturer.toFixed(2));
  // Trier les produit du meilleur au moins bon
  function sortObject(obj) {
    const entries = Object.entries(obj);
    entries.sort((a, b) => b[1] - a[1]);

    const keys = [];
    const values = [];
    for (const [key, value] of entries) {
      keys.push(key);
      values.push(value);
    }
    return { keys, values };
  }

  const keysProd = sortObject(compteurProduit);
  const keysSup = sortObject(compteurSup);

  // Formater data en tableau de chaine de caractére
  const sumProduit = keysProd.keys.map((produit) => {
    return `${produit}: ${compteurProduit[produit]}`;
  });

  const sumSup = keysSup.keys.map((sup) => {
    return `${sup}: ${compteurSup[sup]}`;
  });

  // Formater data sous forme d'objet
  const Data = {
    ProduitAFacturer: sumProduit.concat(sumSup),
    PrixAfacturer: 75650,
  };

  return Data;
}

export async function getOrdersFromUserBrand(userID, date) {
  // recupere toute les commandes
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(500)
  );
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((doc) => doc.data());
  // Recupere le nom de la marque du client
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());
  let userBrand = [];
  usersData.forEach(
    (userdata) => userdata.uid === userID && userBrand.push(userdata.OwnerBrand)
  );
  // Recupere le pricing de la marque du client
  const pepersDoc = doc(getFirestore(app), "pepers", userBrand[0]);
  const pepersSnapshot = await getDoc(pepersDoc);
  let UserBrandPricing = null;
  if (pepersSnapshot.exists()) {
    UserBrandPricing = pepersSnapshot.data();
  }
  // Trie les datas pour avoir que les commandes de la marque du client
  let ordersClientBrand = [];
  let SumModuleCA = 0;
  ordersData.forEach((commande) => {
    commande.dataOrder.order.virtual_brand_name === userBrand[0] &&
      ordersClientBrand.push(commande);
    // Si une commande vient de eatic et que un des produit propre a la marque est contenu dans la commande alors la commande est comptabilisée pour cette marque
    if (commande.dataOrder.order.channel === "EATIC") {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      test === true && ordersClientBrand.push(commande);
    }
    // si une commande n'a pas de marque c'est probalement qu'elle est passé depuis le module de commande alors on effecture le meme test celui de eatic
    // Par la meme occasion on calcul la somme de toute les vente qui ont effectuées depuis le module
    if (
      commande.dataOrder.order.virtual_brand_name === null &&
      commande.dataOrder.order.channel === "Zelty Web"
    ) {
      let test = false;
      commande.listeProduitCommande.forEach((produit) => {
        UserBrandPricing.EaticTest.forEach((produitEaticTest) => {
          if (produit.name === produitEaticTest.NomZelty) {
            test = true;
          }
        });
      });
      if (test === true) {
        ordersClientBrand.push(commande);
        SumModuleCA =
          SumModuleCA +
          commande.dataOrder.order.price.final_amount_exc_tax / 100;
      }
    }
  });
  // Trie les commandes pour avoir que les commandes de la marque du client et entre deux date
  let ordersClientBrandbetweenDate = [];
  ordersClientBrand.forEach((commande) => {
    let dateCommande = new Date(commande.dataOrder.order.created_at);
    if (dateCommande > date[0] && dateCommande < date[1]) {
      ordersClientBrandbetweenDate.push(commande);
    }
  });
  console.log(
    `-- consol log de firebaseservices function getOrdersFromUserBrand --La sum de toute les commandes du module est : ${SumModuleCA}`
  );
  return ordersClientBrandbetweenDate;
}
export async function getOrdersFromUserBrandDEMO(userID, date) {
  // recupere toute les commandes
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(50)
  );
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((doc) => doc.data());

  return ordersData;
}

export async function getOrderOfPreparation(date, ManagerID) {
  // Recupere Toute les commandes de preparation du manager
  const OrderPreparationsDoc = doc(
    getFirestore(app),
    "ManagerRestaurant",
    "Commandes"
  );
  const OrderPreparationsSnapshot = await getDoc(OrderPreparationsDoc);
  const orderPreparations = OrderPreparationsSnapshot.data().Commandes;

  const filteredOrders = orderPreparations.filter((order) => {
    let DateLivraison = new Date(order.DateLivraison.seconds * 1000);
    if (order.DateLivraison) {
      // Vérifie si la propriété dateLivraison existe
      return (
        DateLivraison.getDate() === date.getDate() &&
        DateLivraison.getMonth() + 1 === date.getMonth() + 1 &&
        DateLivraison.getFullYear() === date.getFullYear()
      );
    }
    return false; // Si dateLivraison n'existe pas, ne pas inclure l'objet dans le résultat
  });

  return filteredOrders;
}

export async function getListOfPreparationInOrderPrep(date, ManagerID) {
  // Recupere Toute les commandes de preparation du manager
  const OrderPreparationsDoc = doc(
    getFirestore(app),
    "ManagerRestaurant",
    "Commandes"
  );
  const OrderPreparationsSnapshot = await getDoc(OrderPreparationsDoc);
  const orderPreparations = OrderPreparationsSnapshot.data().Commandes;

  const filteredOrders = orderPreparations.filter((order) => {
    let DateLivraison = new Date(order.DateLivraison.seconds * 1000);
    if (order.DateLivraison) {
      // Vérifie si la propriété dateLivraison existe
      return (
        DateLivraison.getDate() === date.getDate() &&
        DateLivraison.getMonth() + 1 === date.getMonth() + 1 &&
        DateLivraison.getFullYear() === date.getFullYear()
      );
    }
    return false; // Si dateLivraison n'existe pas, ne pas inclure l'objet dans le résultat
  });
  // Formater les donnée avec la propriété DataPreparation et Qte
  let ListePrep = [];
  filteredOrders.forEach((Order) => {
    Order.PreparationOrder.forEach((Prep) => {
      ListePrep.push({ DataPreparation: { ...Prep }, Qte: Prep.Qte });
    });
  });

  return ListePrep;
}

export async function getPreparationFromProduct(ProductName) {
  const WorkPlaceDocRef = doc(firestore, "Production", "dishes");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const AllProduct = docSnapshot.data().dishes;
  /* TEST 
  {
    const WorkPlaceDocRefDish = doc(firestore, "Production", "dishes");
    const docSnapshotDish = await getDoc(WorkPlaceDocRefDish);
    const AllDish = docSnapshotDish.data();
    console.log(JSON.stringify(AllDish));
  }
*/
  let PreparationIntermediaireOfTheProduct = null;
  AllProduct.forEach((product) => {
    if (product.Production.name === ProductName) {
      PreparationIntermediaireOfTheProduct =
        product.Production.PreparationIntermediaire;
    }
  });
  // Recupere dans la base de donnée les informations de chaque preparation
  const PrepaparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PrepaparationDocRef);
  const Allprepapation = PreparationSnapshot.data().preparation;

  let PreparationProductDetail = [];
  PreparationIntermediaireOfTheProduct.forEach((preparationProduct) => {
    Allprepapation.forEach((preparation) => {
      if (preparation.Nom === preparationProduct.Name) {
        PreparationProductDetail.push({
          ...preparation,
          Qte: preparationProduct.Qte,
        });
      }
    });
  });

  return PreparationProductDetail;
}

export async function getPreparationFromProductForCreationTestProduct(
  ProductName
) {
  const WorkPlaceDocRef = doc(firestore, "Production", "DishTestUser");
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const AllProduct = docSnapshot.data().DishTestUser;

  {
    /*//TEST
  const WorkPlaceDocRefDish = doc(firestore, "Production", "Fournisseurs");
  const docSnapshotDish = await getDoc(WorkPlaceDocRefDish);
  const AllDish = docSnapshotDish.data();
  console.log(JSON.stringify(AllDish));
//FIN TEST*/
  }

  let PreparationIntermediaireOfTheProduct = null;
  AllProduct.forEach((product) => {
    if (product.Production.name === ProductName) {
      PreparationIntermediaireOfTheProduct =
        product.Production.PreparationIntermediaire;
    }
  });
  // Recupere dans la base de donnée les informations de chaque preparation
  const PrepaparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PrepaparationDocRef);
  const Allprepapation = PreparationSnapshot.data().preparation;

  let PreparationProductDetail = [];
  PreparationIntermediaireOfTheProduct.forEach((preparationProduct) => {
    Allprepapation.forEach((preparation) => {
      if (preparation.Nom === preparationProduct.Name) {
        PreparationProductDetail.push({
          ...preparation,
          Qte: preparationProduct.Qte,
        });
      }
    });
  });

  return PreparationProductDetail;
}

export async function getProductInPosteDeTravail(PosteDetravail) {
  // On recupere la liste des produits afecte au plans de travail
  const WorkPlaceDocRefPosteDeTravail = doc(
    firestore,
    "PosteDeTravail",
    PosteDetravail
  );
  const docSnapshotPosteDeTravail = await getDoc(WorkPlaceDocRefPosteDeTravail);
  const allProdinPosteDeTravail =
    docSnapshotPosteDeTravail.data().ProductInProduction;

  return allProdinPosteDeTravail;
}
export async function getProductInPosteDeTravailForFicheTechnique(
  PosteDetravail
) {
  console.log(PosteDetravail);
  // On recupere la liste des produits afecte au plans de travail
  const WorkPlaceDocRefPosteDeTravail = doc(
    firestore,
    "PosteDeTravail",
    PosteDetravail
  );
  const docSnapshotPosteDeTravail = await getDoc(WorkPlaceDocRefPosteDeTravail);
  const allProdinPosteDeTravail = docSnapshotPosteDeTravail
    .data()
    .ProductInProduction.map((prod) => prod.ProductName);

  const allProdinPosteDeTravailWithoutDoublon = Array.from(
    new Set(allProdinPosteDeTravail)
  );

  return allProdinPosteDeTravailWithoutDoublon;
}

export async function getListeMatireForPreparation() {
  const WorkPlaceDocRefPrep = doc(firestore, "Production", "preparations");
  const docSnapshotPrep = await getDoc(WorkPlaceDocRefPrep);
  const allPrep = docSnapshotPrep
    .data()
    .preparation.filter((prep) => prep.Categorie === "Preparation");

  let Tableaudematierepremiere = [];
  allPrep.forEach((prep) => {
    prep.MatierePremiereUtilisees.forEach((Matiere) => {
      Tableaudematierepremiere.push(Matiere.Name);
    });
  });
  // Utiliser un objet pour stocker les comptes
  const counts = {};
  Tableaudematierepremiere.forEach((string) => {
    if (counts[string]) {
      counts[string] += 1;
    } else {
      counts[string] = 1;
    }
  });

  // Créer un tableau de résultats à partir de l'objet de comptes
  const resultArray = Object.keys(counts).map((string) => ({
    value: string,
    count: counts[string],
  }));

  const WorkPlaceDocRefMatiere = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const docSnapshotMatiere = await getDoc(WorkPlaceDocRefMatiere);
  const allMatiere = docSnapshotMatiere.data().MatierePremiere;

  let matierePremiereUtilPlusData = [];
  resultArray.forEach((MatiereUtilise) => {
    allMatiere.forEach((Matiere) => {
      if (
        MatiereUtilise.value === Matiere.Nom &&
        Matiere.Fournisseur === "PRIMLAND"
      ) {
        matierePremiereUtilPlusData.push(Matiere.Nom);
      }
    });
  });

  //console.log(JSON.stringify(allPrep));
  return null;
}

export async function getUserBankTransaction(userID) {
  const WorkPlaceDocRef = doc(firestore, "users", userID.toString());
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allBankTransaction = docSnapshot.data().Wallet.BankTransaction;
  return allBankTransaction;
}

export async function getCardsWalletData(userID) {
  // Recupere la liste de toute les transactions bancaire effecturé du client et la date de la derniére facturation
  const WorkPlaceDocRef = doc(firestore, "users", userID.toString());
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const allBankTransaction = docSnapshot.data().BankTransaction;
  const DerniereFacturation = docSnapshot.data().DerniereFacturation;
  // Calcul du sold de compte en faisant la somme de toute les transactions approuvées
  let Solde = 0;
  allBankTransaction.forEach((transaction) => {
    if (transaction.Etat === "Approved") {
      Solde = Solde + transaction.Amount;
    }
  });
  // Deduire le cout que le client nous doit depuis la derniére facturation
  let CoutFacture =
    (
      await getFacturationData(userID, [
        new Date(DerniereFacturation),
        new Date(),
      ])
    ).PrixAfacturer * 1.2;
  CoutFacture = parseFloat(CoutFacture.toFixed(2));
  Solde = Solde - CoutFacture;
  Solde = parseFloat(Solde.toFixed(2));

  const CardsData = [
    {
      title: "Solde Compte",
      sousTitre: `utilisateur ${userID}`,
      color: {
        backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
        boxShadow: "0px 10px 20px 0px #e0c6f5",
      },
      barValue: 70,
      value: `${Solde} €`,
      png: UilUsdSquare,
      series: [
        {
          name: "CA",
          data: [0],
        },
      ],
      categorie: [0],
    },
  ];
  return CardsData;
}
export async function getCardsWalletDataNEWWALLET(userID) {
  // Recupere la liste de toute les transactions bancaire effecturé du client et la date de la derniére facturation
  const WorkPlaceDocRef = doc(firestore, "users", userID.toString());
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const SoldeCompteClient = parseFloat(docSnapshot.data().Wallet.Solde);

  const CardsData = [
    {
      title: "Solde Compte",
      sousTitre: `utilisateur ${userID}`,
      color: {
        backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
        boxShadow: "0px 10px 20px 0px #e0c6f5",
      },
      barValue: 70,
      value: `${SoldeCompteClient} €`,
      png: UilUsdSquare,
      series: [
        {
          name: "CA",
          data: [0],
        },
      ],
      categorie: [0],
    },
  ];
  return CardsData;
}

export async function getClientPricing(userID) {
  // Recupere le nom de la marque du client
  const ClientDocRef = doc(firestore, "users", userID.toString());
  const ClientSnapshot = await getDoc(ClientDocRef);
  const NomMarque = ClientSnapshot.data().OwnerBrand;

  // Recupere le pricing de la marque du client
  const WorkPlaceDocRef = doc(firestore, "pepers", NomMarque);
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const PricingBrand = docSnapshot.data().Pricing.map((produit) => {
    return produit.NomZelty.concat(" = ")
      .concat(produit.Cost.toString())
      .concat("€");
  });
  return PricingBrand;
}
export async function getClientPricingAllData(userID) {
  // Recupere le nom de la marque du client
  const ClientDocRef = doc(firestore, "users", userID.toString());
  const ClientSnapshot = await getDoc(ClientDocRef);
  const NomMarque = ClientSnapshot.data().OwnerBrand;

  // Recupere le pricing de la marque du client
  const WorkPlaceDocRef = doc(firestore, "pepers", NomMarque);
  const docSnapshot = await getDoc(WorkPlaceDocRef);
  const PricingBrand = docSnapshot.data().Pricing;
  return PricingBrand;
}

export async function getPreparationSearchBarData() {
  // Recupere la liste des preparation avant service
  const PreparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PreparationDocRef);
  const Preparations = PreparationSnapshot.data().preparation;

  return Preparations;
}

export async function getPreparationSearchBarDataForCommandePreparation() {
  // Recupere la liste des preparation avant service
  const PreparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PreparationDocRef);
  const Preparations = PreparationSnapshot.data().preparation.filter((prep) => {
    return prep.Categorie === "Preparation"; // Filtrer par la catégorie "Preparation"
  });

  return Preparations;
}

export async function getBrandSearchBarData() {
  // Recupere la liste de toutes les marque
  const BrandCollectionRef = collection(firestore, "pepers");
  const querySnapshot = await getDocs(BrandCollectionRef);

  // Transforme les documents en un tableau d'objets
  const Brand = [];
  querySnapshot.forEach((doc) => {
    Brand.push({ BrandName: doc.id, ...doc.data() });
  });

  return Brand;
}

export async function getMatierePremiereSearchBarData() {
  // Recupere la liste des preparation avant service
  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const MatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;

  return MatierePremiere;
}

export async function getZeltyProductSearchBarData() {
  // Recupere la liste des preparation avant service
  const ZeltyProductDocRef = doc(firestore, "Production", "dishes");
  const ZeltyProductSnapshot = await getDoc(ZeltyProductDocRef);
  const ZeltyProduct = ZeltyProductSnapshot.data().dishes;

  return ZeltyProduct;
}
export async function getProductTestSearchBarData(userID) {
  // Recupere la liste des preparation avant service
  const ZeltyProductDocRef = doc(firestore, "Production", "DishTestUser");
  const ZeltyProductSnapshot = await getDoc(ZeltyProductDocRef);
  const ZeltyProduct = ZeltyProductSnapshot.data().DishTestUser.filter(
    (prod) => prod.Production.UserOwner === userID
  );

  return ZeltyProduct;
}

export async function getFournisseurSearchBarData() {
  // Recupere la liste des preparation avant service
  const ZeltyFournisseursDocRef = doc(firestore, "Production", "Fournisseurs");
  const ZeltyFournisseursSnapshot = await getDoc(ZeltyFournisseursDocRef);
  const ZeltyFournisseurs = ZeltyFournisseursSnapshot.data().Fournisseurs;

  return ZeltyFournisseurs;
}

export async function GetIngredientForFicheTechnique(ProductName) {
  // Recupere la liste des produits
  const ZeltyProductDocRef = doc(firestore, "Production", "dishes");
  const ZeltyProductSnapshot = await getDoc(ZeltyProductDocRef);
  const ZeltyProduct = ZeltyProductSnapshot.data().dishes;
  // On recupere la liste des preparation que le produit contiens
  let listePreparation = [];
  ZeltyProduct.forEach((product) => {
    if (product.Production.name === ProductName) {
      listePreparation = product.Production.PreparationIntermediaire;
    }
  });
  // On recupere toute les information de chaque preparation
  const PreparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationSnapshot = await getDoc(PreparationDocRef);
  const allPreparations = PreparationSnapshot.data().preparation;
  // On enregistre la nouvelle liste de preparation avec toute les informations dont on a besoin
  let preparationAllInfo = [];
  listePreparation.forEach((preparationProduct) => {
    allPreparations.forEach((preparation) => {
      if (preparationProduct.Name === preparation.Nom) {
        preparationAllInfo.push({
          ...preparation,
          Qte: preparationProduct.Qte,
        });
      }
    });
  });
  return preparationAllInfo;
}

export async function GetPhotoProduit(ProductName) {
  // Recupere la liste des produits
  const ZeltyProductDocRef = doc(firestore, "Production", "dishes");
  const ZeltyProductSnapshot = await getDoc(ZeltyProductDocRef);
  const ZeltyProduct = ZeltyProductSnapshot.data().dishes;
  // On recherche le produit en question et on enregistre l'url de l'image
  let PhotoProduitFini = null;
  ZeltyProduct.forEach((product) => {
    if (product.Production.name === ProductName) {
      PhotoProduitFini = product.Production.img;
    }
  });

  return PhotoProduitFini;
}

export async function getWorkplanData() {
  // Recupere la liste des categories
  const PosteDeTravailDocRef = collection(firestore, "PosteDeTravail");
  const PosteDeTravailSnapshot = await getDocs(PosteDeTravailDocRef);
  const AllPosteDeTravailID = PosteDeTravailSnapshot.docs.map((Poste) => {
    return Poste.id.toString();
  });

  return AllPosteDeTravailID;
}

export async function GetProductPricing(ProductName) {
  // Recupere la liste des produits
  const ZeltyProductDocRef = doc(firestore, "Production", "dishes");
  const ZeltyProductSnapshot = await getDoc(ZeltyProductDocRef);
  const ZeltyProduct = ZeltyProductSnapshot.data().dishes;
  // On recupere la liste de toute les preparation pour pouvoir recuperer le prix
  const ZeltyPreparationDocRef = doc(firestore, "Production", "preparations");
  const ZeltyPreparationSnapshot = await getDoc(ZeltyPreparationDocRef);
  const ZeltyPreparation = ZeltyPreparationSnapshot.data().preparation;
  // On recupere la liste de ProductCategories pour connaitre le coef de chaque prod
  const ProductionProductCategoriesDocRef = doc(
    firestore,
    "Production",
    "ProductCategories"
  );
  const ProductionProductCategoriesSnapshot = await getDoc(
    ProductionProductCategoriesDocRef
  );
  const AllProductionProductCategories =
    ProductionProductCategoriesSnapshot.data().Categories;
  // On recherche le produit en question et on enregistre l'url de l'image
  let ProductPrice = 0;
  let AllDataProduct = null;
  let ProductCategories = 0;
  ZeltyProduct.forEach((product) => {
    if (product.Production.name === ProductName) {
      AllDataProduct = product;
      // On recupere le coef du produit pour faire le pricing
      AllProductionProductCategories.forEach((categorie) => {
        if (categorie.NomCategorie === product.Production.ProductCategories) {
          ProductCategories = parseFloat(categorie.CoefficientMarge);
        }
      });

      product.Production.PreparationIntermediaire.forEach((preparation) => {
        ZeltyPreparation.forEach((zeltyPrep) => {
          if (zeltyPrep.Nom === preparation.Name) {
            console.log(zeltyPrep.Nom);
            console.log(preparation.Qte);

            let prix =
              (zeltyPrep.CoutHT / zeltyPrep.Quantite) *
              preparation.Qte *
              ProductCategories;

            console.log(prix);

            ProductPrice = ProductPrice + prix;
          }
        });
      });
    }
  });

  // On ajoute le prix du packaging au produit si il y en a un
  const PackagingDocRef = doc(firestore, "Production", "packaging");
  const PackagingSnapshot = await getDoc(PackagingDocRef);
  const Packaging = PackagingSnapshot.data().packaging;
  // On regarde si la propriete Packaging existe
  if (AllDataProduct.Production.hasOwnProperty("Packaging")) {
    // Si elle exite on regarde le prix de chaque packaging et on l'ajoute au prix du produit
    AllDataProduct.Production.Packaging.forEach((ProductPackaging) => {
      Packaging.forEach((packaging) => {
        if (ProductPackaging.NomFournisseur === packaging.NomFournisseur) {
          console.log(packaging.NomFournisseur);
          console.log(packaging.PrixHTParUnite);

          let PackagingPrice = packaging.PrixHTParUnite.replace(",", ".");
          PackagingPrice = parseFloat(PackagingPrice);
          PackagingPrice = PackagingPrice.toFixed(4);

          ProductPrice = ProductPrice + ProductCategories * PackagingPrice;
          console.log(ProductCategories * PackagingPrice);
        }
      });
    });
  }
  return ProductPrice;
}
export async function GetSupplementPricing(SupplementName) {
  // Recupere la liste des produits
  const SupplementDocRef = doc(firestore, "Production", "Supplement");
  const SupplementSnapshot = await getDoc(SupplementDocRef);
  const Supplement = SupplementSnapshot.data().Supplement;
  // On recupere la liste de toute les preparation pour pouvoir recuperer le prix
  const ZeltyPreparationDocRef = doc(firestore, "Production", "preparations");
  const ZeltyPreparationSnapshot = await getDoc(ZeltyPreparationDocRef);
  const ZeltyPreparation = ZeltyPreparationSnapshot.data().preparation;
  // On recupere la liste de ProductCategories pour connaitre le coef de chaque prod
  const ProductionProductCategoriesDocRef = doc(
    firestore,
    "Production",
    "ProductCategories"
  );
  const ProductionProductCategoriesSnapshot = await getDoc(
    ProductionProductCategoriesDocRef
  );
  const AllProductionProductCategories =
    ProductionProductCategoriesSnapshot.data().Categories;
  // On recherche le produit en question et on enregistre l'url de l'image
  let ProductPrice = 0;
  let AllDataSupplement = null;
  let ProductCategories = 2.5;
  Supplement.forEach((sup) => {
    if (sup.Name === SupplementName) {
      AllDataSupplement = sup;
      sup.PreparationIntermediaire.forEach((preparation) => {
        ZeltyPreparation.forEach((zeltyPrep) => {
          if (zeltyPrep.Nom === preparation.Name) {
            let prix =
              (zeltyPrep.CoutHT / zeltyPrep.Quantite) *
              preparation.Qte *
              ProductCategories;

            ProductPrice = ProductPrice + prix;
          }
        });
      });
    }
  });

  return ProductPrice;
}
export async function GetProductPricingForTestProduct(ProductName, userID) {
  // Recupere la liste des produits
  const ZeltyProductDocRef = doc(firestore, "Production", "DishTestUser");
  const ZeltyProductSnapshot = await getDoc(ZeltyProductDocRef);
  const ZeltyProduct = ZeltyProductSnapshot.data().DishTestUser;
  // On recupere la liste de toute les preparation pour pouvoir recuperer le prix
  const ZeltyPreparationDocRef = doc(firestore, "Production", "preparations");
  const ZeltyPreparationSnapshot = await getDoc(ZeltyPreparationDocRef);
  const ZeltyPreparation = ZeltyPreparationSnapshot.data().preparation;
  // On recupere la liste de ProductCategories pour connaitre le coef de chaque prod
  const ProductionProductCategoriesDocRef = doc(
    firestore,
    "Production",
    "ProductCategories"
  );
  const ProductionProductCategoriesSnapshot = await getDoc(
    ProductionProductCategoriesDocRef
  );
  const AllProductionProductCategories =
    ProductionProductCategoriesSnapshot.data().Categories;
  // On recherche le produit en question et on enregistre l'url de l'image
  let ProductPrice = 0;
  ZeltyProduct.forEach((product) => {
    if (
      product.Production.name === ProductName &&
      userID === product.Production.UserOwner
    ) {
      // On recupere le coef du produit pour faire le pricing
      let ProductCategories = 0;
      AllProductionProductCategories.forEach((categorie) => {
        if (categorie.NomCategorie === product.Production.ProductCategories) {
          ProductCategories = parseFloat(categorie.CoefficientMarge);
        }
      });

      product.Production.PreparationIntermediaire.forEach((preparation) => {
        ZeltyPreparation.forEach((zeltyPrep) => {
          if (zeltyPrep.Nom === preparation.Name) {
            let prix =
              (zeltyPrep.CoutHT / zeltyPrep.Quantite) *
              preparation.Qte *
              ProductCategories;
            //console.log(zeltyPrep.CoutHT)
            //console.log(zeltyPrep.Quantite)
            //console.log(preparation.Qte)
            console.log(prix);
            console.log(zeltyPrep.Nom);
            ProductPrice = ProductPrice + prix;
          }
        });
      });
    }
  });

  return ProductPrice;
}

export async function GetProductCategories() {
  // Recupere la liste des categories
  const ZeltyCategoriesDocRef = doc(
    firestore,
    "Production",
    "ProductCategories"
  );
  const ZeltyCategoriesSnapshot = await getDoc(ZeltyCategoriesDocRef);
  const ZeltyCategories = ZeltyCategoriesSnapshot.data().Categories;

  return ZeltyCategories;
}

export async function GetFirstDish() {
  // Recupere la liste des categories
  const ZeltyDishesDocRef = doc(firestore, "Production", "dishes");
  const ZeltyDishesSnapshot = await getDoc(ZeltyDishesDocRef);
  const AllDishes = ZeltyDishesSnapshot.data().dishes;

  return AllDishes[0];
}

export async function GetOrderEvent() {
  // Recupere la liste des categories
  const ZeltyDishesDocRef = collection(firestore, "OrderEvent");
  const ZeltyDishesSnapshot = await getDocs(ZeltyDishesDocRef);
  const AllDishes = ZeltyDishesSnapshot.docs.map((orderEvent) => ({
    ...orderEvent.data(),
  }));

  const DataOfSpecificOrder = [];
  AllDishes.forEach((date) => {
    date.id >= 217207108 && DataOfSpecificOrder.push({ ...date });
  });

  const Datetableau = [];
  AllDishes.forEach((date) => {
    date.id >= 217207108 &&
      date.status === "production" &&
      Datetableau.push({ ...date, created_at: new Date(date.created_at) });
  });

  function compareDates(a, b) {
    return b.created_at - a.created_at;
  }

  Datetableau.sort(compareDates);

  let FormatedDate = [];
  Datetableau.forEach((data) => {
    AllDishes.forEach((orderEvent) => {
      if (data.id === orderEvent.id && orderEvent.status === "production") {
        FormatedDate.push({ ...data, created_at: orderEvent.created_at });
      }
    });
  });
  //console.log(Datetableau.slice(0,101))
  console.log(FormatedDate);
  return Datetableau;
}

export async function getListePreparationsData() {
  // recupere toute les preparations
  const PreparationsDocument = doc(firestore, "Production", "preparations");
  const PreparationsSnapshot = await getDoc(PreparationsDocument);
  const PreparationsData = PreparationsSnapshot.data().preparation;

  return PreparationsData;
}
export async function getOnlyPreparations() {
  // recupere toute les preparations
  const PreparationsDocument = doc(firestore, "Production", "preparations");
  const PreparationsSnapshot = await getDoc(PreparationsDocument);
  const PreparationsData = PreparationsSnapshot.data().preparation;
  const onlyPrepData = PreparationsData.filter(
    (prep) => prep.Categorie === "Preparation"
  );

  return onlyPrepData;
}
export async function getListeTestProductOfUser(UserID) {
  // recupere toute les preparations
  const DishTestDocument = doc(firestore, "Production", "DishTestUser");
  const DishTestSnapshot = await getDoc(DishTestDocument);
  const DishTestData = DishTestSnapshot.data().DishTestUser.filter(
    (product) => product.Production.UserOwner === UserID
  );

  return DishTestData;
}
export async function getFirstProductTestOfUser(UserID) {
  // recupere toute les preparations
  const DishTestDocument = doc(firestore, "Production", "DishTestUser");
  const DishTestSnapshot = await getDoc(DishTestDocument);
  let DishTestData = [{ Production: { name: "" } }];
  if (DishTestSnapshot.data().DishTestUser.length != 0) {
    // On creer un tableau de tout les UserId qui ont creer des produits test et on verifie si notre user a creer un produit
    const ListeUserIDWhoCreateProduct =
      DishTestSnapshot.data().DishTestUser.map(
        (product) => product.Production.UserOwner
      );
    if (ListeUserIDWhoCreateProduct.includes(UserID)) {
      DishTestData = DishTestSnapshot.data().DishTestUser.filter(
        (product) => product.Production.UserOwner === UserID
      );
    }
  }

  return DishTestData[0].Production.name;
}

export async function getAllDish() {
  // Recupere la liste des categories
  const ZeltyDishesDocRef = doc(firestore, "Production", "dishes");
  const ZeltyDishesSnapshot = await getDoc(ZeltyDishesDocRef);
  const AllDishes = ZeltyDishesSnapshot.data().dishes;

  return AllDishes;
}

export async function getMachineDisponible() {
  // Recupere la liste des categories
  const MachineDocRef = doc(firestore, "Production", "Machines");
  const MachineSnapshot = await getDoc(MachineDocRef);
  const AllMachine = MachineSnapshot.data().Machines;

  return AllMachine;
}

// Met a jour le document firebase quand cette fonction est appelee
export async function SetMachineData() {
  const BrandDocRef = doc(firestore, "Production", "Machines");
  const BrandDocSnapshot = await getDoc(BrandDocRef);

  await setDoc(
    BrandDocRef,
    {
      Machines: [
        "Plancha",
        "Friteuse",
        "grille pain",
        "Plaque induction",
        "Machine panini",
      ],
    },
    { merge: true }
  );

  // Get the data from the "dishes" document
  const BrandData = BrandDocSnapshot.data();
  return null;
}
export async function getProductcategorie() {
  // Recupere la liste des categories
  const ProductCategoriesDocRef = doc(
    firestore,
    "Production",
    "ProductCategories"
  );
  const ProductCategoriesSnapshot = await getDoc(ProductCategoriesDocRef);
  const AllCategories = ProductCategoriesSnapshot.data().Categories;

  return AllCategories;
}
export async function SetInitialProductGroupData() {
  const ProductGroupDocRef = doc(firestore, "Production", "ProductGroup");
  const ProductGroupDocSnapshot = await getDoc(ProductGroupDocRef);

  await setDoc(
    ProductGroupDocRef,
    {
      ListeProductGroup: [
        {
          ListeOfProduct: [{ id: "" }, { id: "" }],
          NameOfProductGroup: "",
        },
      ],
    },

    { merge: true }
  );

  return null;
}
export async function GetProductGroupData() {
  const ProductGroupDocRef = doc(firestore, "Production", "ProductGroup");
  const ProductGroupDocSnapshot = await getDoc(ProductGroupDocRef);
  const ListeProductGroup = ProductGroupDocSnapshot.data().ListeProductGroup;

  return ListeProductGroup;
}

export async function getRawMAterialFromPreparation() {
  const PreparationDocRef = doc(firestore, "Production", "preparations");
  const PreparationDocSnapshot = await getDoc(PreparationDocRef);
  const Preparation = PreparationDocSnapshot.data().preparation;

  let PreparationOnly = Preparation.filter(
    (prep) => prep.Categorie === "Preparation"
  );

  let ListeRawMAtiere = [];
  PreparationOnly.forEach((prep) => {
    prep.MatierePremiereUtilisees.forEach((matiere) => {
      ListeRawMAtiere.push(matiere.Name);
    });
  });

  const ListeRawMAtiereFinal = [...new Set(ListeRawMAtiere)];

  return {
    RawMAterial: ListeRawMAtiereFinal,
    ListePrepForCalcul: PreparationOnly,
  };
}
export async function getPreparationForActiveProductInRestaurant() {
  const DishDocRef = doc(firestore, "Production", "dishes");
  const DishDocSnapshot = await getDoc(DishDocRef);
  const AllDishes = DishDocSnapshot.data().dishes;

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepDocSnapshot = await getDoc(PrepDocRef);
  const AllPrepObject = PrepDocSnapshot.data().preparation;

  //On Parcours la liste des produit on regarde ceux qui sont actif et on met dans le meme tableau la liste des nom de chaque prep
  const AllPrepOfActiveDish = [];
  AllDishes.forEach((dish) => {
    if (
      dish.Production.hasOwnProperty("StateProduct") &&
      dish.Production.StateProduct === "Actif"
    ) {
      dish.Production.PreparationIntermediaire.forEach((prep) => {
        AllPrepOfActiveDish.push(prep.Name);
      });
    }
  });
  // On enleve les prep en doublon
  const AllPrepOfActiveDishSansDoublons = [...new Set(AllPrepOfActiveDish)];
  // On Recupere l'enssemble des datas de la prep dans la base
  let AllPrepWithAllPrepObject = [];
  AllPrepOfActiveDishSansDoublons.forEach((prep) => {
    AllPrepObject.forEach((prepObject) => {
      if (prep === prepObject.Nom) {
        AllPrepWithAllPrepObject.push(prepObject);
      }
    });
  });

  return AllPrepWithAllPrepObject;
}
export async function getMatierePremiereShouldBeAvailableInLogistics() {
  const AllPrepActiveInRestauarant =
    await getPreparationForActiveProductInRestaurant();

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereDocSnapshot = await getDoc(MatiereDocRef);
  const AllMatiereObject = MatiereDocSnapshot.data().MatierePremiere;

  //On Parcours la liste des Matiere presentent dans les prep et on met dans le meme tableau la liste des nom de chaque matiere
  const AllMatiereOfActivePrep = [];
  AllPrepActiveInRestauarant.forEach((Prep) => {
    Prep.MatierePremiereUtilisees.forEach((Matiere) => {
      AllMatiereOfActivePrep.push(Matiere.Name);
    });
  });
  // On enleve les matiere en doublon
  const AllMatiereOfActivePrepSansDoublons = [
    ...new Set(AllMatiereOfActivePrep),
  ];
  // On Recupere l'enssemble des datas de la matiere dans la base
  let AllMatiereWithAllPrepObject = [];
  AllMatiereOfActivePrepSansDoublons.forEach((Matiere) => {
    AllMatiereObject.forEach((MatiereObject) => {
      if (Matiere === MatiereObject.Nom) {
        AllMatiereWithAllPrepObject.push(MatiereObject);
      }
    });
  });

  return AllMatiereWithAllPrepObject;
}
export async function getMatierePremiereDataForCreateInventaire() {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockDocSnapshot = await getDoc(StockDocRef);
  const AllStockObject = StockDocSnapshot.data();
  const StockMatiere = AllStockObject.MatierePremiere;

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereDocSnapshot = await getDoc(MatiereDocRef);
  const AllMatiereObject = MatiereDocSnapshot.data().MatierePremiere;

  //On Parcours la liste des Matiere presentent dans le stock matiere et on met dans le meme tableau  avec toute les datas de la matiere
  const AllMatiereDataForInventaire = [];
  StockMatiere.forEach((MatiereStock) => {
    AllMatiereObject.forEach((MatiereObject) => {
      if (MatiereStock.IDPErpers === MatiereObject.IDPErpers) {
        AllMatiereDataForInventaire.push(MatiereObject);
      }
    });
  });

  return AllMatiereDataForInventaire;
}
export async function getPrepDataForCreateInventaire() {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockDocSnapshot = await getDoc(StockDocRef);
  const AllStockObject = StockDocSnapshot.data();
  const StockPrep = AllStockObject.Preparation;

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepDocSnapshot = await getDoc(PrepDocRef);
  const AllPrepObject = PrepDocSnapshot.data().preparation;

  //On Parcours la liste des Matiere presentent dans le stock matiere et on met dans le meme tableau  avec toute les datas de la matiere
  const AllPrepDataForInventaire = [];
  StockPrep.forEach((PrepStock) => {
    AllPrepObject.forEach((PrepObject) => {
      if (PrepStock.IDPErpers === PrepObject.IDPErpers) {
        AllPrepDataForInventaire.push(PrepObject);
      }
    });
  });
  return AllPrepDataForInventaire;
}
export async function getListOfPrepWhoUseThisMatierePremiere(MatiereName) {
  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepDocSnapshot = await getDoc(PrepDocRef);
  const AllPrepObject = PrepDocSnapshot.data().preparation;

  const AllPrepUsedMatiereSelected = [];
  AllPrepObject.forEach((Prep) => {
    Prep.MatierePremiereUtilisees.forEach((Matiere) => {
      if (Matiere.Name === MatiereName) {
        AllPrepUsedMatiereSelected.push(Prep);
      }
    });
  });

  console.log(AllPrepUsedMatiereSelected);

  return AllPrepUsedMatiereSelected;
}
export async function getListOfActiveProductWhoUseThisPrep(PrepName) {
  const dishesDocRef = doc(firestore, "Production", "dishes");
  const dishesDocSnapshot = await getDoc(dishesDocRef);
  const AlldishesObject = dishesDocSnapshot.data().dishes;

  const AllDishesWhoUsedPrepSelected = [];
  AlldishesObject.forEach((dish) => {
    if (
      dish.Production.hasOwnProperty("StateProduct") &&
      dish.Production.StateProduct === "Actif"
    ) {
      dish.Production.PreparationIntermediaire.forEach((Prep) => {
        if (Prep.Name === PrepName) {
          AllDishesWhoUsedPrepSelected.push(dish);
        }
      });
    }
  });

  console.log(AllDishesWhoUsedPrepSelected);

  return AllDishesWhoUsedPrepSelected;
}

export async function getWalletOfABrand(Brand) {
  const usersCollection = collection(getFirestore(app), "users");
  const usersSnapshot = await getDocs(usersCollection);
  const usersData = usersSnapshot.docs.map((doc) => doc.data());

  let userDataOfBrandOwner = {};
  usersData.forEach((user) => {
    if (user.OwnerBrand === Brand) {
      userDataOfBrandOwner = user;
    }
  });

  return userDataOfBrandOwner.Wallet;
}

export async function getCardsOrdersLogisticsData() {
  const CommandesDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const CommandesDocSnapshot = await getDoc(CommandesDocRef);
  const Commandes = CommandesDocSnapshot.data().Commandes;
  return Commandes.slice(-2);
}
export async function getLogisticOrder(OrderDate) {
  const CommandesDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const CommandesDocSnapshot = await getDoc(CommandesDocRef);
  const Commandes = CommandesDocSnapshot.data().Commandes;

  let CommandeOfTheDate = {};
  Commandes.forEach((commande) => {
    let deliveryDate = new Date(commande.DateLivraison.seconds * 1000);

    if (
      OrderDate.getDate() === deliveryDate.getDate() &&
      OrderDate.getMonth() === deliveryDate.getMonth() &&
      OrderDate.getFullYear() === deliveryDate.getFullYear()
    ) {
      CommandeOfTheDate = commande;
    }
  });

  return CommandeOfTheDate;
}

export async function getFicheTechniquePrep(prepSelected) {
  const PreparationsDocRef = doc(firestore, "Production", "preparations");
  const PreparationsDocSnapshot = await getDoc(PreparationsDocRef);
  const Preparations = PreparationsDocSnapshot.data().preparation;

  // On recupere toute les data de la preparation selctionne
  let PrepData = null;
  Preparations.forEach((prep) => {
    if (prepSelected.Nom === prep.Nom) {
      PrepData = prep;
    }
  });

  // On regarde dans la preparation selectionne et on recupere toute les data des matieres preprieres presente dans la preparation.
  const RawMaterialDocRef = doc(firestore, "Production", "Matiere Premiere");
  const RawMaterialDocSnapshot = await getDoc(RawMaterialDocRef);
  const RawMaterial = RawMaterialDocSnapshot.data().MatierePremiere;

  let ListRawMaterialData = [];
  PrepData.MatierePremiereUtilisees.forEach((matiere) => {
    let RawMaterialSelected = RawMaterial.filter(
      (material) => material.Nom === matiere.Name
    );
    RawMaterialSelected = RawMaterialSelected[0];
    ListRawMaterialData.push({ ...RawMaterialSelected, Qte: matiere.Qte });
  });

  // On Calcul la propotion de chque matiere qu'il faut utiliser pour faire la Qte de la preparation commandee
  const PropQte = prepSelected.Qte / PrepData.Quantite;

  const NomMatiereAvecQte = ListRawMaterialData.map((matiere) => {
    let NewQte = PropQte * matiere.Qte;
    NewQte = NewQte.toFixed(6);
    return {
      Name: matiere.Nom,
      Qte: NewQte,
      Conditionnement: matiere.Conditionnement,
    };
  });

  return { PrepData, NomMatiereAvecQte };
}

export async function SetInitialPackagingData() {
  const packagingDocRef = doc(firestore, "Production", "packaging");
  const packagingDocSnapshot = await getDoc(packagingDocRef);

  await setDoc(
    packagingDocRef,
    {
      packaging: [
        {
          PepersId: "",
          Name: "",
          SupplierName: "",
          Conditionnement: "",
          PriceHT: 0.5,
          Fournisseur: "",
        },
      ],
    },

    { merge: true }
  );

  return null;
}

export async function SetInitialSupplementData() {
  const SupplementDocRef = doc(firestore, "Production", "Supplement");
  const SupplementDocSnapshot = await getDoc(SupplementDocRef);

  await setDoc(
    SupplementDocRef,
    {
      Supplement: [
        {
          PepersId: "",
          Name: "",
          PrepartionUtilisee: [],
          QteOfTheSup: "",
          UniteDeMesure: "G",
          PrixDeRevientHT: 0.5,
        },
      ],
    },

    { merge: true }
  );

  return null;
}

export async function getAllPackaging() {
  const packagingDocRef = doc(firestore, "Production", "packaging");
  const packagingDocSnapshot = await getDoc(packagingDocRef);
  const packaging = packagingDocSnapshot.data().packaging;

  return packaging;
}
export async function getAllSup() {
  // Recupere la liste des categories
  const SupplementDocRef = doc(firestore, "Production", "Supplement");
  const SupplementSnapshot = await getDoc(SupplementDocRef);
  const AllSupplement = SupplementSnapshot.data().Supplement;

  return AllSupplement;
}
export async function SetInitialAssemblageWorkplaceData() {
  const AssemblageWorkplaceDocRef = doc(
    firestore,
    "PosteDeTravail",
    "Assemblage"
  );
  const AssemblageWorkplaceDocSnapshot = await getDoc(
    AssemblageWorkplaceDocRef
  );

  await setDoc(
    AssemblageWorkplaceDocRef,
    {
      Machine: [],
      Preparations: [],
      OrderInProduction: [],
    },

    { merge: true }
  );

  return null;
}

export async function GetOrderReadyToAssemblage(Orders) {
  // Analyse des commande pour ne recuperer que celle ouverte
  let AllOrdersToDo = [];
  Orders.map((commande) => {
    if (commande.data().status === "production") {
      // on recupere la liste des produits a concevoir
      let CheckIfOrderReadyForPackage = true;
      commande.data().listeProduitCommande.map((produit) => {
        // On verifie si tout les produit sont fait c'est a dire produit.etatProduction === true
        if (produit.etatProduction === false) {
          CheckIfOrderReadyForPackage = false;
        }
      });
      // On verifie aussi que la commande n'est pas deja prie en charge
      if (
        commande.data().hasOwnProperty("InPackageState") &&
        commande.data().InPackageState === true
      ) {
        CheckIfOrderReadyForPackage = false;
      }
      if (CheckIfOrderReadyForPackage === true) {
        AllOrdersToDo.push(commande.data());
      }
    }
  });
  return AllOrdersToDo;
}

export async function getOrderOnGoingPackaging(Orders) {
  // Analyse des commande pour ne recuperer que celle ouverte
  let AllOrdersToDo = [];
  Orders.forEach((commande) => {
    if (
      commande.data().status === "production" &&
      commande.data().hasOwnProperty("InPackageState") &&
      commande.data().InPackageState === true
    ) {
      AllOrdersToDo.push(commande.data());
    }
  });

  console.log(AllOrdersToDo);
  return AllOrdersToDo;
}

export async function getPrepToOrderInterfaceCards() {
  const PreparationsDocRef = doc(firestore, "Production", "preparations");
  const PreparationsDocSnapshot = await getDoc(PreparationsDocRef);
  const OrderPrepDocRef = doc(firestore, "ManagerRestaurant", "Commandes");
  const OrderPrepDocSnapshot = await getDoc(OrderPrepDocRef);

  const OrderPrep = OrderPrepDocSnapshot.data().Commandes.filter((commande) => {
    const Livraison = new Date(commande.DateLivraison.seconds * 1000);
    const Today = new Date();
    const unJourEnMillisecondes = 24 * 60 * 60 * 1000;
    const Demain = new Date(Today.getTime() + unJourEnMillisecondes);
    return (
      Livraison.getDate() === Demain.getDate() &&
      Livraison.getMonth() === Demain.getMonth() &&
      Livraison.getFullYear() === Demain.getFullYear()
    );
  });

  const Preparations = PreparationsDocSnapshot.data().preparation.filter(
    (prep) => prep.Categorie === "Preparation"
  );

  // Pour chacune des preparation on regade si elle est deja dans la commande du lendemain si elle est deja on passe l'atrubut Selected a true
  const PrepSelected = Preparations.map((prep) => {
    let isInTheOrder = false;
    if (OrderPrep != 0) {
      OrderPrep[0].PreparationOrder.forEach((prepInOrder) => {
        if (prep.Nom === prepInOrder.Nom) {
          isInTheOrder = true;
        }
      });
    }
    let DatatoReturn = {};
    if (isInTheOrder) {
      DatatoReturn = { ...prep, Selected: true };
    } else {
      DatatoReturn = { ...prep, Selected: false };
    }
    return DatatoReturn;
  });
  // On regarde pour chacune des preparation la date a laquelle elle a ette commande la derniere foi
  const PrepSelectedPlusLastOrderPrep = PrepSelected.map((prep) => {
    let DateLastOrder = null;
    let PrepExsistInOrderPrep = false;
    OrderPrepDocSnapshot.data().Commandes.forEach((commande) => {
      const Today = new Date();
      const unJourEnMillisecondes = 24 * 60 * 60 * 1000;
      const Demain = new Date(Today.getTime() + unJourEnMillisecondes);
      const DateLivraison = new Date(commande.DateLivraison.seconds * 1000);
      if (
        Demain.getDate() === DateLivraison.getDate() &&
        Demain.getMonth() === DateLivraison.getMonth() &&
        Demain.getFullYear() === DateLivraison.getFullYear()
      ) {
        return;
      }
      commande.PreparationOrder.forEach((prepInOrder) => {
        if (prepInOrder.Nom === prep.Nom) {
          PrepExsistInOrderPrep = true;
          DateLastOrder = new Date(commande.DateLivraison.seconds * 1000);
        }
      });
    });
    return { ...prep, DateLastOrder };
  });

  PrepSelectedPlusLastOrderPrep.sort((a, b) => {
    if (a.DateLastOrder != null && b.DateLastOrder != null) {
      const dateA = a.DateLastOrder.getTime(); // Convertit le Timestamp en Date
      const dateB = b.DateLastOrder.getTime();

      return dateA - dateB; // Trie par ordre croissant des dates
    } else if (a.DateLastOrder != null) {
      return -1; // a a une date, b n'en a pas
    } else if (b.DateLastOrder != null) {
      return 1; // b a une date, a n'en a pas
    } else {
      return 0; // ni a ni b n'ont de date
    }
  });

  return PrepSelectedPlusLastOrderPrep;
}

export async function SetInitialDataDataWorkPlace() {
  const AssemblageWorkplaceDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "DataKitchen"
  );

  await setDoc(
    AssemblageWorkplaceDocRef,
    {
      DataKitchen: [],
    },

    { merge: true }
  );

  return null;
}
export async function getDataKitchen() {
  const DataKitchenDocRef = doc(firestore, "ManagerRestaurant", "DataKitchen");
  const DataKitchenSnapshot = await getDoc(DataKitchenDocRef);
  const AllDataKitchen = DataKitchenSnapshot.data().DataKitchen;
  // Calcul du temps de preparation par produit
  const Date1 = new Date(
    AllDataKitchen[0].timeMiseEnProduction.seconds * 1000
  ).getTime();

  // Calcul du temps ou le poste de travail a ete actif
  const WorkplaceWorkingTimeDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "WorkplaceWorkingTime"
  );
  const WorkplaceWorkingTimeSnapshot = await getDoc(WorkplaceWorkingTimeDocRef);
  const AllDataWorkplaceWorkingTime =
    WorkplaceWorkingTimeSnapshot.data().WorkplaceWorkingTime;

  const DataWorkplaceChaud = AllDataWorkplaceWorkingTime.filter(
    (data) => data.Workplace === "Chaud"
  );
  const DataWorkplaceFroid = AllDataWorkplaceWorkingTime.filter(
    (data) => data.Workplace === "Froid"
  );

  let TempsTotalChaud = 0;
  const DataWorkplaceChaudEventStart = DataWorkplaceChaud.filter(
    (event) => event.EvenementType === "Startwatch"
  );
  const DataWorkplaceChaudEventStop = DataWorkplaceChaud.filter(
    (event) => event.EvenementType === "Stopwatch"
  );
  DataWorkplaceChaudEventStart.forEach((Startevent, index) => {
    const DifTime =
      DataWorkplaceChaudEventStop[index].Time.seconds - Startevent.Time.seconds;
    TempsTotalChaud = TempsTotalChaud + DifTime;
  });

  let TempsTotalFroid = 0;
  const DataWorkplaceFroidEventStart = DataWorkplaceFroid.filter(
    (event) => event.EvenementType === "Startwatch"
  );
  const DataWorkplaceFroidEventStop = DataWorkplaceFroid.filter(
    (event) => event.EvenementType === "Stopwatch"
  );
  DataWorkplaceFroidEventStart.forEach((Startevent, index) => {
    const DifTime =
      DataWorkplaceFroidEventStop[index].Time.seconds - Startevent.Time.seconds;
    TempsTotalFroid = TempsTotalFroid + DifTime;
  });

  const Start = new Date(DataWorkplaceChaudEventStart[0].Time.seconds * 1000);
  const End = new Date(
    DataWorkplaceChaudEventStop[DataWorkplaceChaudEventStop.length - 1].Time
      .seconds * 1000
  );
  const result = await getNbProductWhoWasInAllocatedToWorkplace("Chaud", [
    Start,
    End,
  ]);
  const tempsParProd = TempsTotalChaud / result.length / 60;
  console.log(tempsParProd);
  console.log(`nb prod Chaud ${result.length}`);

  const StartFroid = new Date(
    DataWorkplaceFroidEventStart[0].Time.seconds * 1000
  );
  const EndFroid = new Date(
    DataWorkplaceFroidEventStop[DataWorkplaceFroidEventStop.length - 1].Time
      .seconds * 1000
  );
  const resultFroid = await getNbProductWhoWasInAllocatedToWorkplace("Froid", [
    StartFroid,
    EndFroid,
  ]);
  const tempsParProdFroid = TempsTotalFroid / resultFroid.length / 60;
  console.log(tempsParProdFroid);
  console.log(`nb prod Froid ${resultFroid.length}`);
  console.log(
    new Date(
      resultFroid[resultFroid.length - 1].timeMiseEnProduction.seconds * 1000
    )
  );
  console.log(EndFroid);
  console.log(
    compterOccurrencesInStringarray(
      resultFroid.map((result) => result.ProductName)
    )
  );
  CalculTempsdepriseEnchargeProduit("Froid", [StartFroid, EndFroid]);
  const CardsData = [
    {
      title: "Dépenses matiére",
      sousTitre: `Pepers`,
      color: {
        backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
        boxShadow: "0px 10px 20px 0px #e0c6f5",
      },
      barValue: 70,
      value: `2500 €`,
      png: UilUsdSquare,
      series: [
        {
          name: "CA",
          data: [1, 1],
        },
      ],
      categorie: [1, 1],
    },
    {
      title: "Commandes",
      sousTitre: `Pepers`,
      color: {
        backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
        boxShadow: "0px 10px 20px 0px #FDC0C7",
      },
      barValue: 80,
      value: `10 /J`,
      png: UilMoneyWithdrawal,
      series: [
        {
          name: "Commandes",
          data: [1, 1],
        },
      ],
      categorie: [1, 1],
    },
    {
      title: "Commandes",
      sousTitre: `Pepers`,
      color: {
        backGround:
          "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
        boxShadow: "0px 10px 20px 0px #F9D59B",
      },
      barValue: 80,
      value: `10 /J`,
      png: UilMoneyWithdrawal,
      series: [
        {
          name: "Commandes",
          data: [1, 1],
        },
      ],
      categorie: [1, 1],
    },
  ];

  return CardsData;
}
export async function SetInitialDataWorkplaceWorkingTime() {
  const WorkplaceWorkingTimeDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "WorkplaceWorkingTime"
  );

  await setDoc(
    WorkplaceWorkingTimeDocRef,
    {
      WorkplaceWorkingTime: [],
    },

    { merge: true }
  );

  return null;
}

export async function GetPrepPricingPerUnit() {
  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPrep = PrepSnapshot.data().preparation;
  const AllName = AllPrep.map((prep) => prep.Nom);
  const AllUnit = AllPrep.map((prep) => prep.Unite);
  const AllPrice = AllPrep.map((prep) => {
    let Prix = (prep.CoutHT / prep.Quantite) * 2.5;
    return Prix.toFixed(4);
  });
  console.log(AllName);
  console.log(AllUnit);
  console.log(AllPrice);

  return null;
}
export async function getNbProductWhoWasInAllocatedToWorkplace(
  workplace,
  date
) {
  const DataKitchenDocRef = doc(firestore, "ManagerRestaurant", "DataKitchen");
  const DataKitchenSnapshot = await getDoc(DataKitchenDocRef);
  const AllDataKitchen = DataKitchenSnapshot.data().DataKitchen;
  // Calcul du temps de preparation par produit
  // On regarde dans la base dataKitchen les evenement qui correspondent au Workplace selectionne
  // On regarde que les evenements ou le cuisiner a pris en charge un produit
  const DateStart = date[0].getTime();
  const DateEnd = date[1].getTime();
  let DataSelected = AllDataKitchen.filter((data) => {
    return data.WorkPlace === workplace && data.Type === "AllocateWorkplace";
  });

  // On regarde que les evenements qui ont eu lieu entre les dates selectionnee
  DataSelected = DataSelected.filter((data) => {
    let Time = data.timeMiseEnProduction.seconds * 1000;
    return Time > DateStart && Time < DateEnd;
  });

  return DataSelected;
}

export async function CalculTempsdepriseEnchargeProduit(workplace, date) {
  const ProductAlocated = await getNbProductWhoWasInAllocatedToWorkplace(
    workplace,
    date
  );
  let TempsTotalPrisEnCharge = 0;

  // Utilisation de map pour générer un tableau de promesses
  const promises = ProductAlocated.map(async (prod) => {
    const OrderDocRef = doc(firestore, "Orders", prod.OrderID);
    const OrderSnapshot = await getDoc(OrderDocRef);
    const Order = OrderSnapshot.data();
    const OrederCreateAt = new Date(Order.dataOrder.order.created_at).getTime();
    const TimeOfPrisEnCharge = prod.timeMiseEnProduction.seconds * 1000;
    const DurationForTakeInChargeMinute =
      (TimeOfPrisEnCharge - OrederCreateAt) / 1000 / 60;
    console.log(prod.ProductName);
    console.log(Order.dataOrder.order.created_at);
    console.log(new Date(TimeOfPrisEnCharge));
    console.log(DurationForTakeInChargeMinute);
    TempsTotalPrisEnCharge =
      TempsTotalPrisEnCharge + DurationForTakeInChargeMinute;
  });
  // Utilisation de Promise.all pour attendre la résolution de toutes les promesses
  await Promise.all(promises);

  const MoyennePrisEnCharge = TempsTotalPrisEnCharge / ProductAlocated.length;
  console.log(`Temps Moyen :${MoyennePrisEnCharge}`);
  console.log(`Temps total :${TempsTotalPrisEnCharge}`);
  return null;
}

export async function getPrepData(PrepName) {
  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPrep = PrepSnapshot.data().preparation;

  let Prep = {};
  AllPrep.forEach((prep) => {
    if (prep.Nom === PrepName) {
      Prep = prep;
    }
  });
  return Prep;
}

export async function getMatiereDataInPrep(ListeMatiere) {
  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  let AllDataMatiere = AllMatiere.filter((matiere) => {
    return ListeMatiere.includes(matiere.Nom);
  });

  return AllDataMatiere;
}

export async function getMatierePremiere() {
  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const AllMatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;

  return AllMatierePremiere;
}

export async function getPrepWithId(Id) {
  const preparationDocRef = doc(firestore, "Production", "preparations");
  const preparationSnapshot = await getDoc(preparationDocRef);
  const Allpreparation = preparationSnapshot.data().preparation;

  let InterestedPrep = {};
  Allpreparation.forEach((prep) => {
    if (prep.Nom === Id) {
      InterestedPrep = prep;
    }
  });
  return InterestedPrep;
}

export async function CalculQteMatiereForOnePrepConditionementLogistic(
  prepID,
  ConditionnementLogisticID
) {
  const preparationDocRef = doc(firestore, "Production", "preparations");
  const preparationSnapshot = await getDoc(preparationDocRef);
  const Allpreparation = preparationSnapshot.data().preparation;

  let QteMatiereForConditionement = {};
  let PrepData = null;
  let ConditionnementData = null;
  Allpreparation.forEach((prep, index) => {
    if (prep.IDPErpers === prepID) {
      PrepData = prep;
      Allpreparation[index].ConditionnementLogistique.forEach(
        (conditionnement) => {
          if (conditionnement.IDPepers === ConditionnementLogisticID) {
            ConditionnementData = conditionnement;
          }
        }
      );
    }
  });

  // On regarde dans la preparation selectionne et on recupere toute les data des matieres preprieres presente dans la preparation.
  const RawMaterialDocRef = doc(firestore, "Production", "Matiere Premiere");
  const RawMaterialDocSnapshot = await getDoc(RawMaterialDocRef);
  const RawMaterial = RawMaterialDocSnapshot.data().MatierePremiere;

  let ListRawMaterialData = [];
  PrepData.MatierePremiereUtilisees.forEach((matiere) => {
    let RawMaterialSelected = RawMaterial.filter(
      (material) => material.Nom === matiere.Name
    );
    RawMaterialSelected = RawMaterialSelected[0];
    ListRawMaterialData.push({ ...RawMaterialSelected, Qte: matiere.Qte });
  });

  // On Calcul la propotion de chque matiere qu'il faut utiliser pour faire la Qte de la preparation commandee
  const PropQte =
    ConditionnementData.QteConditionnementLogistic / PrepData.Quantite;

  const NomMatiereAvecQte = ListRawMaterialData.map((matiere) => {
    let NewQte = PropQte * matiere.Qte;
    //NewQte = NewQte * matiere.QteConditionnement;
    NewQte = NewQte.toFixed(6);
    return {
      Name: matiere.Nom,
      Qte: NewQte,
      UniteMesure: matiere["Unité de recettes"],
    };
  });

  return { PrepData, NomMatiereAvecQte };
}

const prep = {
  preparation: [
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPain%20burger%20classique?alt=media&token=5168aca9-2644-478e-a508-3f6bd5e4f722",
      CoutHT: "0.55",
      Quantite: "1",
      Nom: "Pain burger classique",
      Statut: "Actif",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Bun Vegan Moulé Nature 100" },
      ],
      DLCSecondaire: "3",
      Categorie: "Mise a disposition",
      Unite: "unit",
    },
    {
      Categorie: "Mise a disposition",
      Statut: "Actif",
      DLCSecondaire: "3",
      Quantite: "2400",
      CoutHT: "32.80",
      Nom: "Steak beyond mince 75g",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "STEAK BEYOND MINCE (8X300G) RETAILSURG" },
      ],
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSteak%20beyond%20mince%2075g?alt=media&token=6c9f752a-fda1-4de3-bc48-938190f92c4a",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FConfit%20d'oignon?alt=media&token=5ac6c9f8-cec8-4731-9c40-87e0334f29ef",
      Nom: "Confit d'oignon",
      Quantite: "3434",
      CoutHT: "5.85",
      Categorie: "Preparation",
      DLCSecondaire: "5",
      Unite: "g",
      MatierePremiereUtilisees: [
        {
          Name: "Margarine cuisine et pâtisserie 500 g METRO Chef",
          Qte: "0.36",
        },
        { Qte: "0.22", Name: "Sucre vergeoise brune 500 g Saint Louis" },
        {
          Qte: "0.042",
          Name: "Vinaigre de cidre 1 L METRO Chef (convertir en g). (0,05L= 52g) (1L=1040g)",
        },
        { Qte: "3.1", Name: "OIGNON FLT ROUGE  (PAYS-BAS)" },
      ],
      Statut: "Actif",
    },
    {
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Confiture de figues Bonne Maman 6 x 370 g" },
      ],
      Categorie: "Mise a disposition",
      CoutHT: "9.54",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FConfiture%20de%20figues?alt=media&token=38750cbb-f042-4e7d-921f-5754c0078bb2",
      Unite: "g",
      DLCSecondaire: "30",
      Statut: "Actif",
      Quantite: "2220",
      Nom: "Confiture de figues",
    },
    {
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSalade%20mesclun?alt=media&token=9eb0e1bf-ab02-4cc7-be4b-ab60a8a0cedb",
      Categorie: "Mise a disposition",
      Unite: "g",
      CoutHT: "5.98",
      Quantite: "1000",
      Nom: "Salade mesclun",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "SALADE MESCLUN BQ SOUS-VIDE 1KG (ITALIE)" },
      ],
      DLCSecondaire: "3",
    },
    {
      Statut: "Actif",
      MatierePremiereUtilisees: [{ Name: "Faux Gras 125g GAIA", Qte: "1" }],
      Nom: "Faux foie gras",
      CoutHT: "2.81",
      Quantite: "125",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFaux%20foie%20gras?alt=media&token=24105cc3-3f15-4cc7-9b06-3abd7dee9cdd",
      Categorie: "Mise a disposition",
      DLCSecondaire: "3",
      Unite: "g",
    },
    {
      Statut: "Actif",
      Nom: "Steak beyond classique",
      Unite: "unit",
      Quantite: "40",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSteak%20beyond%20classique?alt=media&token=f95e110a-7e73-4705-a8a1-b4d29baa1581",
      Categorie: "Mise a disposition",
      CoutHT: "74.13",
      DLCSecondaire: "3",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "BEYOND BURGER 40X113G - BEYOND MEAT" },
      ],
    },
    {
      CoutHT: "50.70",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "NEXT BACON VÉGÉTAL STYLE STRIPS (12X1KG)" },
      ],
      DLCSecondaire: "3",
      Unite: "unit",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FBacon%20v%C3%A9g%C3%A9tal%20coup%C3%A9%20en%202?alt=media&token=c28630e8-a363-474a-858b-b6ebdb2861d6",
      Nom: "Bacon végétal coupé en 2",
      Quantite: "333",
      Categorie: "Decoupe formatage",
    },
    {
      DLCSecondaire: "7",
      Quantite: "200",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FTranche%20fromage%20cheddar%20violife?alt=media&token=adfca7d8-269c-4169-babd-9959fb7c7407",
      Unite: "unit",
      CoutHT: "46.00",
      Categorie: "Mise a disposition",
      Nom: "Tranche fromage cheddar violife",
      MatierePremiereUtilisees: [
        { Name: "VIOLIFE SAVEUR CHEDDAR VEGAN (8X500G)", Qte: "1" },
      ],
      Statut: "Actif",
    },
    {
      MatierePremiereUtilisees: [
        { Name: "OIGNON FLT ROUGE  (PAYS-BAS)", Qte: "1" },
      ],
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FOignons%20rouges%20coup%C3%A9%20en%20lamelle?alt=media&token=dfd2b1cc-380e-485e-bf9f-a479e153599b",
      DLCSecondaire: "3",
      CoutHT: "1.49",
      Categorie: "Decoupe formatage",
      Nom: "Oignons rouges coupé en lamelle",
      Quantite: "1000",
      Unite: "g",
    },
    {
      Statut: "Actif",
      Unite: "unit",
      DLCSecondaire: "",
      MatierePremiereUtilisees: [
        { Name: "TOMATE 3 VRAC (MAROC)", Qte: "0.156" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FTomate%20coup%C3%A9%20en%20lamelle?alt=media&token=97157908-10cf-4023-b5de-60260966fd26",
      Quantite: "9",
      Nom: "Tomate coupé en lamelle",
      Categorie: "Decoupe formatage",
      CoutHT: "0.31",
    },
    {
      Quantite: "5172",
      MatierePremiereUtilisees: [
        { Qte: "0.24", Name: "Ketchup 5 kg METRO Chef" },
        {
          Qte: "0.738",
          Name: "MAYO VEGAN 5L (convertir en g) > 0,2L=195g    5L> 4875g",
        },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.003" },
        { Name: "Sucre en poudre sachet 6 x 1 kg Rochambeau", Qte: "0.0266" },
        {
          Name: "Tabasco Chipotle 60 ml (convertir en g) > 0,01L=10g. (0,06l=60g)",
          Qte: "0.2",
        },
        {
          Qte: "0.192",
          Name: "Vinaigre de cidre 1 L METRO Chef (convertir en g). (0,05L= 52g) (1L=1040g)",
        },
      ],
      Nom: "Sauce VBB - Burger - Cocktail",
      CoutHT: "18.15",
      Categorie: "Preparation",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20VBB%20-%20Burger%20-%20Cocktail?alt=media&token=b934db9f-af13-42ae-9ea2-f8c010e28704",
      DLCSecondaire: "3",
      Statut: "Actif",
      Unite: "g",
    },
    {
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "BUNS PETIT PAIN NATURE (48X55G) 2141 EX 1002" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPetit%20pain%20burger?alt=media&token=b03560a8-96de-4746-92d8-11a74cab46a1",
      Categorie: "Mise a disposition",
      CoutHT: "9.90",
      Statut: "Actif",
      Quantite: "48",
      Unite: "unit",
      DLCSecondaire: "3",
      Nom: "Petit pain burger",
    },
    {
      Unite: "unit",
      Nom: "Concombre coupé en rondelle",
      Quantite: "74",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "CONCOMBRE 4/500 CAT 1 (ESPAGNE)" },
      ],
      Statut: "Actif",
      CoutHT: "1.25",
      Categorie: "Decoupe formatage",
      DLCSecondaire: "3",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FConcombre%20coup%C3%A9%20en%20rondelle?alt=media&token=b67bd97c-92f2-4c51-bd17-c742c8701de1",
    },
    {
      Quantite: "600",
      Nom: "Sauce cacahuète",
      DLCSecondaire: "3",
      CoutHT: "3.02",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20cacahu%C3%A8te?alt=media&token=b5811926-f990-4c8d-bf5a-5dbf228082e7",
      Statut: "Actif",
      MatierePremiereUtilisees: [
        {
          Qte: "0.082",
          Name: "MAYO VEGAN 5L (convertir en g) > 0,2L=195g    5L> 4875g",
        },
        { Qte: "0.2352", Name: "Pâte d’arachide en boîte 4/4 850 g Dakatine" },
      ],
      Categorie: "Preparation",
      Unite: "g",
    },
    {
      Statut: "Actif",
      Categorie: "Mise a disposition",
      DLCSecondaire: "7",
      Nom: "Tomates séchées",
      Quantite: "900",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Tomates séchées en lanières 1.7 L METRO Chef" },
      ],
      CoutHT: "10.32",
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FTomates%20s%C3%A9ch%C3%A9es?alt=media&token=a1664088-fa62-435f-b1fd-181f3875e20b",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSTEAK%20DE%20POULET?alt=media&token=04dd6e22-6703-434c-ae6d-25349989f24f",
      DLCSecondaire: "3",
      CoutHT: "43.00",
      Categorie: "Mise a disposition",
      Quantite: "27",
      Unite: "unit",
      MatierePremiereUtilisees: [{ Name: "STEAK DE POULET", Qte: "1" }],
      Statut: "Actif",
      Nom: "STEAK DE POULET",
    },
    {
      Unite: "g",
      MatierePremiereUtilisees: [
        { Name: "Ail semoule fine 1 kg Aro", Qte: "0.02" },
        { Name: "Aneth en feuilles Saisse 250 g", Qte: "0.076" },
        {
          Name: "Jus de citron vert 1 L Aro (convertir en g). (0,05L=53g) (1L=1060g)",
          Qte: "0.132",
        },
        { Qte: "0.0083", Name: "Piment dit de Cayenne 120 g METRO Chef" },
        {
          Qte: "0.729",
          Name: "Préparation végétale 31% MG 1 L Flora (convertir en g).   0,1L=96g. (1L= 960g",
        },
        { Qte: "0.0333", Name: "Sel fin 750 g Aro" },
        { Name: "YAOURT NATURE AU SOJA", Qte: "3.225" },
      ],
      Categorie: "Preparation",
      Statut: "Actif",
      DLCSecondaire: "3",
      CoutHT: "14.48",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20aneth?alt=media&token=f009f086-670c-4a94-abee-9d6270892b0b",
      Nom: "Sauce aneth",
      Quantite: "2195",
    },
    {
      Nom: "Jalapenos",
      CoutHT: "4.91",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FJalapenos?alt=media&token=0871f615-9b78-493b-b60c-26e463f2393e",
      Quantite: "400",
      DLCSecondaire: "7",
      Categorie: "Mise a disposition",
      Statut: "Actif",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Piment Jalapeno rond 4/4 810 g Emperatriz" },
      ],
      Unite: "g",
    },
    {
      Unite: "g",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Oignons frits 500 g METRO Chef" },
      ],
      Nom: "Oignons frits",
      DLCSecondaire: "30",
      CoutHT: "4.49",
      Categorie: "Mise a disposition",
      Statut: "Actif",
      Quantite: "500",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FOignons%20frits?alt=media&token=a23d9dca-e6de-4d48-8b8c-9256bd1a061f",
    },
    {
      MatierePremiereUtilisees: [
        {
          Qte: "0.656",
          Name: "MAYO VEGAN 5L (convertir en g) > 0,2L=195g    5L> 4875g",
        },
        { Name: "Piment dit de Cayenne 120 g METRO Chef", Qte: "0.0166" },
        {
          Qte: "0.156",
          Name: "SAUCE SAKARI FORTE BIDON 10 L (convertir en g) (0,05L=51g) (10L=10200g)",
        },
      ],
      Quantite: "4802",
      Unite: "g",
      Statut: "Actif",
      DLCSecondaire: "3",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20spicy%20mayo?alt=media&token=11d19918-9287-4a3b-950d-4c7bafb77e63",
      Categorie: "Preparation",
      Nom: "Sauce spicy mayo",
      CoutHT: "24.30",
    },
    {
      CoutHT: "11.78",
      DLCSecondaire: "60",
      Categorie: "Mise a disposition",
      MatierePremiereUtilisees: [
        { Name: "Moutarde Classic Yellow 2.9 kg French's", Qte: "1" },
      ],
      Statut: "Actif",
      Quantite: "2900",
      Unite: "g",
      Nom: "Moutarde",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMoutarde?alt=media&token=0b068160-f440-4e3b-8a61-e1078fbf98ed",
    },
    {
      Quantite: "180.3",
      Categorie: "Mise a disposition",
      MatierePremiereUtilisees: [
        {
          Name: "Cornichons aigres-doux recette paysanne 850 g Kühne",
          Qte: "1",
        },
      ],
      Unite: "unit",
      DLCSecondaire: "7",
      CoutHT: "4.10",
      Statut: "Actif",
      Nom: "Cornichon coupé en lamelle (tranche)",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCornichon%20coup%C3%A9%20en%20lamelle%20(tranche)?alt=media&token=ccf4274c-3f71-4435-9de7-eae7bf137793",
    },
    {
      Statut: "Actif",
      DLCSecondaire: "30",
      Nom: "Chips tortilla nature",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Chips Tortilla nature 750 g METRO Chef" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FChips%20tortilla%20nature?alt=media&token=92ae0d81-0947-4a91-a247-58ffecc3d275",
      CoutHT: "4.96",
      Quantite: "750",
      Unite: "g",
      Categorie: "Mise a disposition",
    },
    {
      MatierePremiereUtilisees: [
        { Qte: "2", Name: "AVOCAT HASS 18 C (ESPAGNE)" },
        {
          Qte: "0.0254",
          Name: "Jus de citron vert 1 L Aro (convertir en g). (0,05L=53g) (1L=1060g)",
        },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.001" },
        { Qte: "0.42", Name: "TOMATE 3 VRAC (MAROC)" },
        { Qte: "0.0000888", Name: "Sel fin 750 g Aro" },
      ],
      Nom: "Guacamole",
      CoutHT: "3.71",
      Unite: "g",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FGuacamole?alt=media&token=726bf077-3dc2-48b0-bcc9-56d041100c3e",
      Categorie: "Preparation",
      DLCSecondaire: "30",
      Quantite: "471",
    },
    {
      Statut: "Actif",
      Categorie: "Preparation",
      CoutHT: "18.36",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20fromage?alt=media&token=3a3beba2-635e-4b64-8907-ff97664abed0",
      Nom: "Sauce fromage",
      Quantite: "3094",
      DLCSecondaire: "7",
      MatierePremiereUtilisees: [
        { Qte: "0.033", Name: "Ail semoule fine 1 kg Aro" },
        {
          Name: "Boisson végétale soja 1 L Alpro (convertir en g) > 0,1L=91g. (1L= 910g)",
          Qte: "1.559",
        },
        { Qte: "0.025", Name: "Piment dit de Cayenne 120 g METRO Chef" },
        {
          Qte: "0.40",
          Name: "Préparation végétale 31% MG 1 L Flora (convertir en g).   0,1L=96g. (1L= 960g",
        },
        { Qte: "0.004", Name: "Sel fin 750 g Aro" },
        {
          Name: "Vinaigre de cidre 1 L METRO Chef (convertir en g). (0,05L= 52g) (1L=1040g)",
          Qte: "0.0288",
        },
        { Qte: "0.2925", Name: "VIOLIFE SAVEUR CHEDDAR VEGAN (8X500G)" },
      ],
      Unite: "g",
    },
    {
      DLCSecondaire: "2",
      Unite: "unit",
      MatierePremiereUtilisees: [
        { Name: "FRAISE IMPORT KG (BELGIQUE)", Qte: "1" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFraise?alt=media&token=b8007c80-19c6-4917-9f07-39ea0582b9d2",
      Statut: "Actif",
      CoutHT: "6.49",
      Categorie: "Mise a disposition",
      Quantite: "16",
      Nom: "Fraise",
    },
    {
      MatierePremiereUtilisees: [
        { Name: "Pommes paillassons 1.5 kg Mc Cain", Qte: "1" },
      ],
      Statut: "Actif",
      DLCSecondaire: "7",
      CoutHT: "5.86",
      Categorie: "Mise a disposition",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPommes%20paillasson?alt=media&token=edf9e5c4-c07f-42a8-9b95-877bcaada8e4",
      Unite: "unit",
      Nom: "Pommes paillasson",
      Quantite: "45",
    },
    {
      Quantite: "4875",
      Statut: "Actif",
      DLCSecondaire: "10",
      MatierePremiereUtilisees: [
        {
          Name: "MAYO VEGAN 5L (convertir en g) > 0,2L=195g    5L> 4875g",
          Qte: "1",
        },
      ],
      Nom: "mayo vegan",
      Categorie: "Mise a disposition",
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2Fmayo%20vegan?alt=media&token=3b71c657-cc93-49b7-93c5-a098773cb7f4",
      CoutHT: "18.90",
    },
    {
      Unite: "unit",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPain%20Panini?alt=media&token=76543037-ce01-4f64-856e-0207e6647895",
      Quantite: "4",
      Statut: "Actif",
      DLCSecondaire: "3",
      CoutHT: "2.60",
      Nom: "Pain Panini",
      MatierePremiereUtilisees: [{ Name: "PAIN PANINI", Qte: "1" }],
      Categorie: "Mise a disposition",
    },
    {
      Statut: "Actif",
      Categorie: "Mise a disposition",
      CoutHT: "22.60",
      Nom: "Frites Crinkle",
      Quantite: "10000",
      DLCSecondaire: "7",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "FRITE 9/9 CRINKLE SURECRISP (4X2.5KG)" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFrites%20Crinkle?alt=media&token=ef7e3947-14c3-48a7-a179-c78eb84caec8",
      Unite: "g",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSel?alt=media&token=7365381a-006c-4f4a-b9c3-5de35840be65",
      DLCSecondaire: "90",
      Statut: "Actif",
      Unite: "g",
      Quantite: "11250",
      MatierePremiereUtilisees: [{ Name: "Sel fin 750 g Aro", Qte: "1" }],
      Nom: "Sel",
      Categorie: "Mise a disposition",
      CoutHT: "7.01",
    },
    {
      Categorie: "Mise a disposition",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2F%C3%89pices%20cuisine%20italienne?alt=media&token=c4ccad34-8ab2-4335-b91f-835ea1399a10",
      Quantite: "300",
      Nom: "Épices cuisine italienne",
      CoutHT: "4.83",
      Statut: "Actif",
      Unite: "g",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Epices cuisine italienne 300 g" },
      ],
      DLCSecondaire: "90",
    },
    {
      MatierePremiereUtilisees: [
        { Name: "Ail semoule fine 1 kg Aro", Qte: "0.02" },
        { Qte: "0.06", Name: "Oignons frits 500 g METRO Chef" },
        { Qte: "0.007228", Name: "Sauce soja sucrée" },
        { Name: "STEAK BEYOND MINCE (8X300G) RETAILSURG", Qte: "0.125" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FBoyond%20meat%20hach%C3%A9?alt=media&token=128fc026-4d8d-4a0e-9fac-60419c073f57",
      Nom: "Boyond meat haché",
      Categorie: "Preparation",
      DLCSecondaire: "3",
      Unite: "g",
      CoutHT: "4.60",
      Quantite: "380",
      Statut: "Actif",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFrite%20patate%20douce?alt=media&token=7f6e0134-8471-42fd-8547-90bb041362dd",
      MatierePremiereUtilisees: [
        { Name: "FRITE PATATE DOUCE FARM FRITE (5X2KG)", Qte: "1" },
      ],
      Unite: "g",
      DLCSecondaire: "90",
      Categorie: "Mise a disposition",
      CoutHT: "41.50",
      Quantite: "10000",
      Nom: "Frite patate douce",
      Statut: "Actif",
    },
    {
      Unite: "g",
      Quantite: "5000",
      CoutHT: "11.40",
      Nom: "Ketchup (pot de sauce)",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "Ketchup 5 kg METRO Chef" }],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FKetchup%20(pot%20de%20sauce)?alt=media&token=2bb8203d-1da4-4d79-a1ec-070506efd657",
      Statut: "Actif",
      DLCSecondaire: "90",
      Categorie: "Preparation",
    },
    {
      Quantite: "2900",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMoutarde%20(pot%20de%20sauce)?alt=media&token=f76584ed-78d5-4dd5-9151-6d15e0406961",
      Categorie: "Preparation",
      Unite: "g",
      DLCSecondaire: "90",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Moutarde Classic Yellow 2.9 kg French's" },
      ],
      Nom: "Moutarde (pot de sauce)",
      Statut: "Actif",
      CoutHT: "11.78",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20aneth%20(pot%20de%20sauce)?alt=media&token=429f7f03-8226-4660-8785-34c6117fae1f",
      Statut: "Actif",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "Sauce aneth" }],
      CoutHT: "11.34",
      Unite: "g",
      DLCSecondaire: "3",
      Quantite: "2195",
      Categorie: "Preparation",
      Nom: "Sauce aneth (pot de sauce)",
    },
    {
      CoutHT: "18.17",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20VBB%20(Pot%20de%20sauce)?alt=media&token=cfb4170e-242f-4ec3-919f-3824bf7412cb",
      MatierePremiereUtilisees: [{ Name: "Sauce VBB", Qte: "1" }],
      Categorie: "Preparation",
      Statut: "Actif",
      Nom: "Sauce VBB (Pot de sauce)",
      DLCSecondaire: "3",
      Quantite: "5172",
      Unite: "g",
    },
    {
      CoutHT: "18.38",
      Categorie: "Preparation",
      Quantite: "3094",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20fromage%20(Pot%20de%20sauce)?alt=media&token=f88f5489-0b12-49c7-b824-9625b8ed6d82",
      MatierePremiereUtilisees: [{ Name: "Sauce fromage", Qte: "1" }],
      Statut: "Actif",
      DLCSecondaire: "3",
      Nom: "Sauce fromage (Pot de sauce)",
      Unite: "g",
    },
    {
      Nom: "Framboise",
      DLCSecondaire: "2",
      Quantite: "18",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFramboise?alt=media&token=81831909-6c72-44d7-ba8e-e4bae9b567aa",
      CoutHT: "1.98",
      Categorie: "Mise a disposition",
      Statut: "Actif",
      MatierePremiereUtilisees: [
        {
          Qte: "1",
          Name: 'FRAMBOISE BQ "DRISCOLL\'S" SELECTION\n\n(PORTUGAL)',
        },
      ],
      Unite: "unit",
    },
    {
      Nom: "Base de TIRAMISU, Speculoos",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FBase%20de%20TIRAMISU%2C%20Speculoos?alt=media&token=ebe473fd-d3ad-4664-93d0-221f72508689",
      MatierePremiereUtilisees: [
        { Name: "Cream mascarpone", Qte: "0.047" },
        {
          Name: "Sirop de fraise 75 cl Rochambeau (0,05L=75g) (0,75L=1125g)",
          Qte: "0.00711",
        },
        { Qte: "0.01171", Name: "Speculoos 2 kg Lotus" },
        {
          Qte: "0.000333",
          Name: "COUV PET POUR POT DIAMETRE 65MM X3000PCS (30X100PCS)",
        },
        {
          Name: 'POT/COUPE DESSERT PET "FINESSE" 270ML/9 OZ DIAM 92MM - X1000PCS (20X50PCS)',
          Qte: "0.001",
        },
      ],
      DLCSecondaire: "3",
      CoutHT: "0.76",
      Quantite: "1",
      Categorie: "Preparation",
      Unite: "unit",
      Statut: "Actif",
    },
    {
      Categorie: "Mise a disposition",
      Nom: "Mayo vegan (pot de sauce)",
      Unite: "g",
      CoutHT: "18.90",
      DLCSecondaire: "7",
      Quantite: "4875",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMayo%20vegan%20(pot%20de%20sauce)?alt=media&token=7eae7b82-44f1-46f8-8d12-a3fdf5408be0",
      Statut: "Actif",
      MatierePremiereUtilisees: [
        {
          Qte: "1",
          Name: "MAYO VEGAN 5L (convertir en g) > 0,2L=195g    5L> 4875g",
        },
      ],
    },
    {
      Categorie: "Mise a disposition",
      CoutHT: "1.30",
      Unite: "unit",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPain%20brioch%C3%A9%20sandwich%20(Halots)?alt=media&token=ae5358c5-a65e-47bd-af4a-d5199e974250",
      MatierePremiereUtilisees: [
        { Name: "HALOTS BIO SANDWICH PAR 2", Qte: "1" },
      ],
      DLCSecondaire: "3",
      Nom: "Pain brioché sandwich (Halots)",
      Quantite: "2",
    },
    {
      CoutHT: "0.50",
      MatierePremiereUtilisees: [{ Name: "Hot-dog Vegan Nature 20", Qte: "1" }],
      DLCSecondaire: "3",
      Categorie: "Mise a disposition",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPain%20hot%20dog?alt=media&token=9500cb43-141c-400e-ab3b-f4ad8b2d52eb",
      Quantite: "1",
      Nom: "Pain hot dog",
      Unite: "unit",
      Statut: "Actif",
    },
    {
      Quantite: "24",
      Unite: "unit",
      DLCSecondaire: "7",
      Nom: "Saucisse hot dog",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "SAUCISSE HOT DOG MOVING MOUTAINS" },
      ],
      Categorie: "Mise a disposition",
      CoutHT: "35.20",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSaucisse%20hot%20dog?alt=media&token=6883c193-ea53-463b-9b67-07501794d547",
    },
    {
      Statut: "Actif",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "MYRTILLES BQ (MAROC)" }],
      CoutHT: "2.98",
      Nom: "Myrtille",
      Quantite: "83",
      DLCSecondaire: "2",
      Categorie: "Mise a disposition",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMyrtille?alt=media&token=bf8c5c67-f0b9-4b3f-b9df-ed583ebe0a84",
      Unite: "unit",
    },
    {
      Quantite: "27",
      Categorie: "Mise a disposition",
      Nom: "Mure",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMure?alt=media&token=1db94f77-b853-4134-86f2-db13e594f51f",
      DLCSecondaire: "2",
      Unite: "unit",
      CoutHT: "2.98",
      MatierePremiereUtilisees: [{ Name: "MURES BQ (PORTUGAL)", Qte: "1" }],
      Statut: "Actif",
    },
    {
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FAlgue%20grill%C3%A9e%20pour%20maki?alt=media&token=8f2cc775-bae4-4f8f-a76f-6160e08d7fc8",
      Quantite: "1000",
      Unite: "unit",
      DLCSecondaire: "60",
      MatierePremiereUtilisees: [
        { Name: "Algue Grillée pour Maki Coupé", Qte: "1" },
      ],
      Nom: "Algue grillée pour maki",
      CoutHT: "138.29",
      Categorie: "Mise a disposition",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCream%20cheese%20Violife?alt=media&token=1e235f22-e7a3-4a7b-86a4-d89edc786546",
      DLCSecondaire: "7",
      Unite: "g",
      CoutHT: "5.44",
      Quantite: "500",
      Nom: "Cream cheese Violife",
      Categorie: "Mise a disposition",
      MatierePremiereUtilisees: [{ Name: "Cream Cheese Violife", Qte: "1" }],
      Statut: "Actif",
    },
    {
      DLCSecondaire: "2",
      Unite: "g",
      Statut: "Actif",
      MatierePremiereUtilisees: [
        { Qte: "0.0979", Name: "Mélange vinaigre de riz" },
        { Name: "Riz pour sushi SHINODE", Qte: "0.144" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FRiz%20vinaigr%C3%A9?alt=media&token=5b555b59-68c6-42e5-b6f7-12750154daac",
      CoutHT: "4.13",
      Quantite: "3300",
      Nom: "Riz vinaigré",
      Categorie: "Preparation",
    },
    {
      CoutHT: "4.38",
      Categorie: "Preparation",
      Statut: "Actif",
      Unite: "g",
      MatierePremiereUtilisees: [
        { Qte: "0.52", Name: "AUBERGINE CAT 1 (PAYS-BAS)" },
        { Qte: "0.5", Name: "AVOCAT HASS 18 C (ESPAGNE)" },
        { Name: "CEBETTE IMPORT BTE (EGYPTE)", Qte: "0.15" },
        { Qte: "0.5", Name: "CORIANDRE BOTTE (FRANCE)" },
        { Qte: "0.2", Name: "Oignons frits 500 g METRO Chef" },
        {
          Name: "Sauce soja kikkoman (convertir en g) (0,05L=61g) (20L=24400g)",
          Qte: "0.004",
        },
      ],
      Nom: "Aubergine sauté",
      Quantite: "620",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FAubergine%20saut%C3%A9?alt=media&token=4cb950c5-93ce-4776-bf4d-7bc0cb3c0bda",
      DLCSecondaire: "3",
    },
    {
      CoutHT: "4.50",
      Statut: "Actif",
      Categorie: "",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMangue?alt=media&token=9731ba08-2521-406f-9d0e-d6c397146426",
      Unite: "g",
      DLCSecondaire: "2",
      Nom: "Mangue",
      MatierePremiereUtilisees: [
        { Name: "MANGUE BATEAU KG (BRESIL)", Qte: "1" },
      ],
      Quantite: "700",
    },
    {
      CoutHT: "1.39",
      Nom: "Avocat",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FAvocat?alt=media&token=d113f37d-9c77-4742-97da-2a63663113b0",
      Categorie: "Mise a disposition",
      Quantite: "1",
      Unite: "unit",
      Statut: "Actif",
      MatierePremiereUtilisees: [
        { Name: "AVOCAT HASS 18 C (ESPAGNE)", Qte: "1" },
      ],
      DLCSecondaire: "2",
    },
    {
      Quantite: "1",
      DLCSecondaire: "3",
      CoutHT: "1.25",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FConcombre?alt=media&token=15ebb4b6-796b-450d-972e-0a3a8feee321",
      Categorie: "Mise a disposition",
      Unite: "unit",
      Nom: "Concombre",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "CONCOMBRE 4/500 CAT 1 (ESPAGNE)" },
      ],
    },
    {
      Categorie: "Decoupe formatage",
      DLCSecondaire: "60",
      Quantite: "24000",
      Nom: "Algue coupée en lamelle",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FAlgue%20coup%C3%A9e%20en%20lamelle?alt=media&token=0415bced-2bfd-4dd5-9cae-cbd5ddb39a0c",
      CoutHT: "138.29",
      MatierePremiereUtilisees: [
        { Name: "Algue Grillée pour Maki Coupé", Qte: "1" },
      ],
      Unite: "unit",
    },
    {
      DLCSecondaire: "7",
      Quantite: "500",
      Nom: "Oignons frits écrasé",
      Unite: "g",
      MatierePremiereUtilisees: [
        { Name: "Oignons frits 500 g METRO Chef", Qte: "1" },
      ],
      CoutHT: "4.49",
      Categorie: "Mise a disposition",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FOignons%20frits%20%C3%A9cras%C3%A9?alt=media&token=b737ebea-c0e0-4519-a759-aa1130faeca5",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FThon%20coup%C3%A9%20pour%20sushi?alt=media&token=ede84c1a-faf1-4904-be05-9223a4ef576d",
      CoutHT: "7.50",
      DLCSecondaire: "3",
      Unite: "unit",
      MatierePremiereUtilisees: [{ Name: "SASHIMI NO TUNA", Qte: "1" }],
      Quantite: "51",
      Nom: "Thon coupé pour sushi",
      Statut: "Actif",
      Categorie: "Decoupe formatage",
    },
    {
      Statut: "Actif",
      DLCSecondaire: "3",
      Unite: "unit",
      Categorie: "Decoupe formatage",
      Quantite: "51",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSaumon%20coup%C3%A9%20pour%20sushi?alt=media&token=cfc4f19d-005d-434d-ae6d-7e672c0cc357",
      Nom: "Saumon coupé pour sushi",
      CoutHT: "7.50",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "VEGAN SASHIMI ZALMON" }],
    },
    {
      MatierePremiereUtilisees: [
        { Name: "STEAK BEYOND MINCE (8X300G) RETAILSURG", Qte: "1" },
      ],
      Nom: "Beyond mince cuit pour sushi",
      DLCSecondaire: "2",
      Unite: "unit",
      Quantite: "128",
      Categorie: "Mise a disposition",
      CoutHT: "32.80",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FBeyond%20mince%20cuit%20pour%20sushi?alt=media&token=3392d669-dc36-454e-a988-c95163c0cff9",
      Statut: "Actif",
    },
    {
      Categorie: "Preparation",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FConcombre%20marin%C3%A9?alt=media&token=6afe01a5-48be-407c-8798-1247aaba7478",
      DLCSecondaire: "3",
      CoutHT: "1.60",
      Statut: "",
      Unite: "unit",
      Nom: "Concombre mariné",
      Quantite: "148",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "CONCOMBRE 4/500 CAT 1 (ESPAGNE)" },
        { Name: "Mélange vinaigre de riz", Qte: "0.04" },
      ],
    },
    {
      CoutHT: "20.13",
      Statut: "Actif",
      Quantite: "2000",
      Nom: "Mélange sésame blanc & noir",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FM%C3%A9lange%20s%C3%A9same%20blanc%20%26%20noir?alt=media&token=a402a16f-aee8-44ed-9ffc-ca20b46519af",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Grains de sésame sendo blanc grille" },
        { Name: "Grains de sésame sendo noir grille", Qte: "1" },
      ],
      Categorie: "Mise a disposition",
      Unite: "g",
      DLCSecondaire: "90",
    },
    {
      DLCSecondaire: "3",
      Nom: "Thon coupé pour roll",
      CoutHT: "7.50",
      Statut: "Actif",
      Quantite: "15",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FThon%20coup%C3%A9%20pour%20roll?alt=media&token=2cc26a11-c870-425e-86c8-b9df5ee9fb58",
      Categorie: "Decoupe formatage",
      Unite: "unit",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "SASHIMI NO TUNA" }],
    },
    {
      Categorie: "Decoupe formatage",
      DLCSecondaire: "3",
      CoutHT: "7.50",
      Nom: "Saumon coupé pour roll",
      Unite: "unit",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSaumon%20coup%C3%A9%20pour%20roll?alt=media&token=1d2616c5-36d4-4694-aaa7-858117c4132a",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "VEGAN SASHIMI ZALMON" }],
      Statut: "Actif",
      Quantite: "15",
    },
    {
      MatierePremiereUtilisees: [
        { Name: "Ail semoule fine 1 kg Aro", Qte: "0.002" },
        { Qte: "0.25", Name: "AVOCAT HASS 18 C (ESPAGNE)" },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.001" },
        { Name: "Sel fin 750 g Aro", Qte: "0.0001777" },
        { Name: "YAOURT NATURE AU SOJA", Qte: "0.5" },
        { Qte: "0.2", Name: "Violife Blanc Grec" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20yaourt%20feta?alt=media&token=d083031d-4632-4a17-845e-61bcf8950583",
      Statut: "Actif",
      DLCSecondaire: "3",
      CoutHT: "2.79",
      Unite: "g",
      Categorie: "Preparation",
      Quantite: "324",
      Nom: "Sauce yaourt feta",
    },
    {
      DLCSecondaire: "3",
      Unite: "g",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "Choux blanc" }],
      Nom: "Chou blanc éffiloché",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FChou%20blanc%20%C3%A9ffiloch%C3%A9?alt=media&token=8325d466-4970-4c63-bb84-e46e8ab7636f",
      CoutHT: "1.39",
      Statut: "Actif",
      Categorie: "Decoupe formatage",
      Quantite: "1000",
    },
    {
      Categorie: "Mise a disposition",
      CoutHT: "1.65",
      DLCSecondaire: "3",
      Statut: "Actif",
      Quantite: "4",
      Nom: "Pain pita",
      Unite: "unit",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPain%20pita?alt=media&token=6b16cb4d-0bcd-45a4-836c-13d638bd7483",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Pita bio Pocket mezonot par 4" },
      ],
    },
    {
      Unite: "g",
      Statut: "Actif",
      Nom: "Violife blanc grec coupé en dé",
      Quantite: "400",
      MatierePremiereUtilisees: [{ Name: "Violife Blanc Grec", Qte: "1" }],
      Categorie: "Decoupe formatage",
      CoutHT: "4.16",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FViolife%20blanc%20grec%20coup%C3%A9%20en%20d%C3%A9?alt=media&token=58781650-6654-489d-8850-76de9cd1032e",
      DLCSecondaire: "7",
    },
    {
      Categorie: "Preparation",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPoivrons%20rouge%20et%20vert%20marin%C3%A9?alt=media&token=7f7e8c5c-f5bb-4693-8b0d-f08fff788e1a",
      Statut: "Actif",
      DLCSecondaire: "3",
      Unite: "g",
      MatierePremiereUtilisees: [
        { Qte: "0.00219", Name: "Huile d'olive vierge extra 5 L Ondoliva" },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.001" },
        { Name: "Sel fin 750 g Aro", Qte: "0.0000888" },
        { Name: "POIVRON ROUGE", Qte: "0.08" },
        { Name: "POIVRON VERT", Qte: "0.08" },
      ],
      CoutHT: "0.68",
      Quantite: "171",
      Nom: "Poivrons rouge et vert mariné",
    },
    {
      CoutHT: "5.69",
      Nom: "Houmous",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FHoumous?alt=media&token=559e371e-1e13-4457-8dac-eedb55d30f7b",
      Quantite: "500",
      Categorie: "Mise a disposition",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "Houmous" }],
      Unite: "g",
      DLCSecondaire: "3",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFalafel?alt=media&token=bf428e95-6bed-4648-bc8a-bf5120f78f9d",
      Categorie: "Mise a disposition",
      CoutHT: "10.12",
      Nom: "Falafel",
      Statut: "Actif",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "Falafel" }],
      DLCSecondaire: "3",
      Unite: "unit",
      Quantite: "58",
    },
    {
      Unite: "unit",
      Categorie: "Mise a disposition",
      Quantite: "250",
      Statut: "Actif",
      Nom: "Menthe coupé en lamelle",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "MENTHE BOTTE (EGYPTE)" }],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMenthe%20coup%C3%A9%20en%20lamelle?alt=media&token=c59d3ad8-5c1c-4b8e-bd26-c21da33ce16c",
      CoutHT: "0.45",
      DLCSecondaire: "3",
    },
    {
      Categorie: "Decoupe formatage",
      Quantite: "400",
      CoutHT: "4.91",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Piment Jalapeno rond 4/4 810 g Emperatriz" },
      ],
      DLCSecondaire: "3",
      Nom: "Jalapenos haché",
      Statut: "Actif",
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FJalapenos%20hach%C3%A9?alt=media&token=9afe6d51-a564-46bc-a6fe-1e9b492b08d8",
    },
    {
      Quantite: "36",
      Categorie: "Mise a disposition",
      Nom: "Basilic",
      Statut: "Actif",
      DLCSecondaire: "3",
      CoutHT: "0.65",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "BASILIC BOTTE (ESPAGNE)" }],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FBasilic?alt=media&token=4a0b071a-4fe3-4491-9f6a-0950600ac8b7",
      Unite: "unit",
    },
    {
      Statut: "Actif",
      CoutHT: "8.80",
      MatierePremiereUtilisees: [
        { Qte: "0.25", Name: "STEAK BEYOND MINCE (8X300G) RETAILSURG" },
        {
          Name: "Sauce soja kikkoman (convertir en g) (0,05L=61g) (20L=24400g)",
          Qte: "0.00675",
        },
        { Qte: "0.064", Name: "MENTHE BOTTE (EGYPTE)" },
        { Qte: "0.032", Name: "Ciboulette 250 g Saisse et fils" },
      ],
      Nom: "Meatball beyond meat menthe",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMeatball%20beyond%20meat%20menthe?alt=media&token=3e14990a-8339-470c-b1e8-ed98ffd45b07",
      Quantite: "37",
      Categorie: "",
      DLCSecondaire: "3",
      Unite: "unit",
    },
    {
      Quantite: "480",
      MatierePremiereUtilisees: [
        { Name: "COURGETTE", Qte: "0.58" },
        { Name: "Huile d'olive vierge extra 5 L Ondoliva", Qte: "0.021834" },
        { Name: "Ail semoule fine 1 kg Aro", Qte: "0.003" },
        { Qte: "0.0001777", Name: "Sel fin 750 g Aro" },
      ],
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCourgette%20coup%C3%A9%20en%20rondelle%20et%20saut%C3%A9?alt=media&token=1691d28e-05b7-4442-bb7c-057f5e9eebb1",
      DLCSecondaire: "3",
      CoutHT: "2.58",
      Nom: "Courgette coupé en rondelle et sauté",
      Categorie: "Preparation",
      Statut: "Actif",
    },
    {
      Unite: "g",
      MatierePremiereUtilisees: [
        { Name: "Crème de coco 1 L Thai Kitchen", Qte: "0.26" },
        { Qte: "0.00008889", Name: "Sel fin 750 g Aro" },
        { Qte: "0.001", Name: "Poivre noir moulu 1 kg Aro" },
        { Name: "CHICK'N CHUNKS - VEGAN CORNER - 350G", Qte: "0.02678" },
      ],
      CoutHT: "3.87",
      DLCSecondaire: "3",
      Statut: "Actif",
      Quantite: "450",
      Nom: "Poulet émincé sauté coco",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPoulet%20%C3%A9minc%C3%A9%20saut%C3%A9%20coco?alt=media&token=295c84ed-8ac1-49fa-9425-1cbaadea6676",
      Categorie: "Preparation",
    },
    {
      DLCSecondaire: "7",
      Quantite: "500",
      Statut: "Actif",
      Unite: "g",
      MatierePremiereUtilisees: [{ Name: "Mozarella rapée", Qte: "1" }],
      Nom: "mozarella rapée",
      CoutHT: "4.22",
      Categorie: "Mise a disposition",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2Fmozarella%20rap%C3%A9e?alt=media&token=5fa928f9-dd42-4314-99b6-7a3991322dd8",
    },
    {
      Nom: "Radis rose coupé en lamelle & marinée",
      Categorie: "Preparation",
      Quantite: "120",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FRadis%20rose%20coup%C3%A9%20en%20lamelle%20%26%20marin%C3%A9e?alt=media&token=8467c910-bb69-4485-8510-c7fce14d1fc2",
      Unite: "unit",
      DLCSecondaire: "4",
      Statut: "Actif",
      CoutHT: "2.07",
      MatierePremiereUtilisees: [
        { Name: "RADIS ROSE BTE (FRANCE)", Qte: "1" },
        { Name: "Mélange vinaigre de riz", Qte: "0.02" },
      ],
    },
    {
      Statut: "Actif",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Noix de cajou 1 kg METRO Chef" },
      ],
      Quantite: "1000",
      Nom: "Noix de cajou écrasé",
      CoutHT: "17.18",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FNoix%20de%20cajou%20%C3%A9cras%C3%A9?alt=media&token=4fe8994c-e113-46b4-912a-f9edde516ac0",
      Categorie: "Decoupe formatage",
      Unite: "g",
      DLCSecondaire: "7",
    },
    {
      DLCSecondaire: "3",
      Unite: "g",
      MatierePremiereUtilisees: [
        { Name: "CHAMPIGNON BLANC", Qte: "1" },
        { Name: "Ail semoule fine 1 kg Aro", Qte: "0.002" },
        { Name: "Sel fin 750 g Aro", Qte: "0.0000888" },
        { Qte: "0.0108", Name: "Huile d'olive vierge extra 5 L Ondoliva" },
      ],
      Quantite: "340",
      Nom: "Champignon blanc sauté",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FChampignon%20blanc%20saut%C3%A9?alt=media&token=cb6f6047-5685-47ad-a641-390ce51bae4f",
      Statut: "Actif",
      CoutHT: "3.51",
      Categorie: "Preparation",
    },
    {
      DLCSecondaire: "3",
      Categorie: "Mise a disposition",
      CoutHT: "1.92",
      MatierePremiereUtilisees: [
        { Name: "Maïs doux supersweet boîte 4/4 METRO Chef", Qte: "0.1666" },
      ],
      Unite: "g",
      Statut: "Actif",
      Nom: "Mais",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMais?alt=media&token=c1a456b9-5d09-4951-9562-fbb7514ed276",
      Quantite: "570",
    },
    {
      Statut: "Actif",
      Categorie: "Mise a disposition",
      DLCSecondaire: "7",
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FLait%20de%20coco?alt=media&token=9eca3327-8ed3-419e-a08e-19173b9aa29b",
      MatierePremiereUtilisees: [
        { Name: "Lait de coco 1 L Chaokoh", Qte: "1" },
      ],
      Quantite: "1000",
      Nom: "Lait de coco",
      CoutHT: "4.47",
    },
    {
      MatierePremiereUtilisees: [
        { Name: "Huile d'olive vierge extra 5 L Ondoliva", Qte: "1" },
      ],
      Nom: "Huile d'olive",
      Categorie: "Mise a disposition",
      DLCSecondaire: "90",
      Quantite: "4565",
      Unite: "g",
      Statut: "Actif",
      CoutHT: "37.39",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FHuile%20d'olive?alt=media&token=69ae880c-6e88-4075-b68d-1320ac729637",
    },
    {
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "CEBETTE IMPORT BTE (EGYPTE)" },
      ],
      Quantite: "8",
      Nom: "Cebette",
      Statut: "Actif",
      DLCSecondaire: "3",
      CoutHT: "1.69",
      Categorie: "Mise a disposition",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCebette?alt=media&token=f1569c3f-18af-4352-8ba1-6644204f233c",
      Unite: "unit",
    },
    {
      Quantite: "1060",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FJus%20de%20citron%20vert?alt=media&token=a1c21a40-aea8-4980-a6c8-28971e2fe005",
      MatierePremiereUtilisees: [
        {
          Qte: "1",
          Name: "Jus de citron vert 1 L Aro (convertir en g). (0,05L=53g) (1L=1060g)",
        },
      ],
      Statut: "Actif",
      CoutHT: "3.21",
      DLCSecondaire: "90",
      Unite: "g",
      Nom: "Jus de citron vert",
      Categorie: "Mise a disposition",
    },
    {
      Categorie: "Mise a disposition",
      DLCSecondaire: "90",
      Statut: "Actif",
      CoutHT: "15.58",
      Unite: "g",
      Quantite: "1000",
      MatierePremiereUtilisees: [
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "1" },
      ],
      Nom: "Poivre",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPoivre?alt=media&token=bae752e6-67fb-4b50-981d-fd0b1a02c58a",
    },
    {
      Categorie: "Mise a disposition",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "METRO Chef Couscous moyen 5 kg" },
      ],
      Statut: "Actif",
      Quantite: "11150",
      Nom: "Couscous cuit",
      DLCSecondaire: "3",
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCouscous%20cuit?alt=media&token=385621a6-9c6e-4c94-85ea-16fe5473edbd",
      CoutHT: "11.93",
    },
    {
      Nom: "Coriandre",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCoriandre?alt=media&token=940e78ec-1f6b-427d-aca3-412d0ffbfb75",
      DLCSecondaire: "3",
      Categorie: "Mise a disposition",
      MatierePremiereUtilisees: [
        { Name: "CORIANDRE BOTTE (FRANCE)", Qte: "1" },
      ],
      Unite: "g",
      Statut: "Actif",
      Quantite: "23",
      CoutHT: "0.57",
    },
    {
      Quantite: "150",
      Statut: "Actif",
      DLCSecondaire: "3",
      MatierePremiereUtilisees: [
        { Name: "Vermicelle de riz nids 60 x 50 g Wai Wai", Qte: "0.01666" },
      ],
      Nom: "Vermicelle de riz",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FVermicelle%20de%20riz?alt=media&token=1db49977-6906-46d8-9f4e-2b9bec5becce",
      Categorie: "Preparation",
      Unite: "g",
      CoutHT: "0.35",
    },
    {
      CoutHT: "1.25",
      Categorie: "Decoupe formatage",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FConcombre%20coup%C3%A9%20en%20lamelle?alt=media&token=3a2939c9-abc5-4309-bdfe-286e806c484c",
      Quantite: "370",
      Unite: "unit",
      MatierePremiereUtilisees: [
        { Name: "CONCOMBRE 4/500 CAT 1 (ESPAGNE)", Qte: "1" },
      ],
      Statut: "Actif",
      DLCSecondaire: "2",
      Nom: "Concombre coupé en lamelle",
    },
    {
      MatierePremiereUtilisees: [
        { Qte: "0.03", Name: "OIGNON FLT ROUGE  (PAYS-BAS)" },
        { Qte: "1", Name: "Riz basmati cuit" },
      ],
      Categorie: "Preparation",
      Unite: "g",
      DLCSecondaire: "2",
      CoutHT: "1.37",
      Quantite: "1070",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FRiz%20saut%C3%A9%20oignons?alt=media&token=e0252544-5ed6-41b0-b827-cf399bd6cd4b",
      Nom: "Riz sauté oignons",
    },
    {
      CoutHT: "0.19",
      Categorie: "Preparation",
      Nom: "Quinoa cuit",
      MatierePremiereUtilisees: [
        { Qte: "0.014", Name: "Quinoa blanc 2.5 kg METRO Chef" },
      ],
      DLCSecondaire: "3",
      Unite: "g",
      Quantite: "122",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FQuinoa%20cuit?alt=media&token=7a08b061-4886-4a93-96a8-d8b5ce62179a",
    },
    {
      Unite: "g",
      MatierePremiereUtilisees: [{ Name: "TOMATE 3 VRAC (MAROC)", Qte: "1" }],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FTomate%20coup%C3%A9%20en%20d%C3%A9?alt=media&token=cfa6d158-ea5d-4c4f-8cc1-fa11e54c2a5e",
      Quantite: "1000",
      CoutHT: "1.98",
      Statut: "Actif",
      DLCSecondaire: "2",
      Nom: "Tomate coupé en dé",
      Categorie: "Decoupe formatage",
    },
    {
      Categorie: "Mise a disposition",
      Statut: "Actif",
      Nom: "Poulet frit coupé en cube",
      Quantite: "3000",
      DLCSecondaire: "2",
      CoutHT: "43.00",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "STEAK DE POULET" }],
      Unite: "g",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPoulet%20frit%20coup%C3%A9%20en%20cube?alt=media&token=9272c42e-39e6-4636-a6e8-185dfe716c33",
    },
    {
      MatierePremiereUtilisees: [
        { Qte: "0.00217", Name: "Huile d'olive vierge extra 5 L Ondoliva" },
        { Qte: "0.032", Name: "MENTHE BOTTE (EGYPTE)" },
        { Name: "CEBETTE IMPORT BTE (EGYPTE)", Qte: "0.0625" },
        {
          Name: "Jus de citron vert 1 L Aro (convertir en g). (0,05L=53g) (1L=1060g)",
          Qte: "0.00943",
        },
        { Qte: "0.0000888", Name: "Sel fin 750 g Aro" },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.001" },
        { Qte: "0.01866", Name: "METRO Chef Couscous moyen 5 kg" },
      ],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FTaboul%C3%A9%20libanais?alt=media&token=bc1ab125-860c-43a7-8e70-574a1c190a66",
      Statut: "Actif",
      Categorie: "Preparation",
      DLCSecondaire: "3",
      Nom: "Taboulé libanais",
      Quantite: "225",
      CoutHT: "0.47",
      Unite: "g",
    },
    {
      Categorie: "Preparation",
      MatierePremiereUtilisees: [
        { Name: "STEAK BEYOND MINCE (8X300G) RETAILSURG", Qte: "0.25" },
        {
          Name: "Sauce soja kikkoman (convertir en g) (0,05L=61g) (20L=24400g)",
          Qte: "0.00675",
        },
        { Qte: "0.032", Name: "Ciboulette 250 g Saisse et fils" },
        { Name: "MENTHE BOTTE (EGYPTE)", Qte: "0.064" },
      ],
      Nom: "Meatball beyond meat menthe coupé en 2",
      DLCSecondaire: "3",
      Quantite: "74",
      Statut: "Actif",
      CoutHT: "8.80",
      Unite: "unit",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FMeatball%20beyond%20meat%20menthe%20coup%C3%A9%20en%202?alt=media&token=ef7c1527-5849-468c-9014-9badd4aa10d6",
    },
    {
      MatierePremiereUtilisees: [
        { Name: "CONCOMBRE 4/500 CAT 1 (ESPAGNE)", Qte: "1" },
      ],
      DLCSecondaire: "2",
      Statut: "Actif",
      Nom: "Concombre coupé en dé",
      Categorie: "Preparation",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FConcombre%20coup%C3%A9%20en%20d%C3%A9?alt=media&token=1eb62156-bd27-42a9-9631-32ca2676e854",
      Unite: "g",
      Quantite: "369",
      CoutHT: "1.25",
    },
    {
      Categorie: "Decoupe formatage",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FTomate%20cerise%20coup%C3%A9%20en%202?alt=media&token=b7c25fa0-f9b0-47b0-9580-fa46b9dd509f",
      MatierePremiereUtilisees: [
        { Name: 'TOMATE"CERISE"BQ (ESPAGNE)', Qte: "1" },
      ],
      CoutHT: "1.98",
      Statut: "Actif",
      DLCSecondaire: "2",
      Quantite: "76",
      Unite: "unit",
      Nom: "Tomate cerise coupé en 2",
    },
    {
      MatierePremiereUtilisees: [
        {
          Name: "Olives noires confites dénoyautées calibre 30/33 boîte 4/4 METRO Chef",
          Qte: "1",
        },
      ],
      Statut: "Actif",
      CoutHT: "2.56",
      Nom: "Olive noir",
      DLCSecondaire: "7",
      Categorie: "Mise a disposition",
      Quantite: "360",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FOlive%20noir?alt=media&token=657d6c13-2e60-4bc4-9d80-b02d475d150b",
      Unite: "g",
    },
    {
      DLCSecondaire: "3",
      Unite: "unit",
      MatierePremiereUtilisees: [{ Name: "Focaccia", Qte: "1" }],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPain%20focaccia%20coup%C3%A9%20en%2012?alt=media&token=5a44f0d3-60d0-4fc1-a066-b1fb40b95860",
      Statut: "Actif",
      CoutHT: "9.95",
      Nom: "Pain focaccia coupé en 12",
      Categorie: "Decoupe formatage",
      Quantite: "12",
    },
    {
      DLCSecondaire: "3",
      Nom: "Pain focaccia coupé en 24",
      Categorie: "Decoupe formatage",
      Quantite: "24",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPain%20focaccia%20coup%C3%A9%20en%2024?alt=media&token=64025470-e81e-43bf-a20b-d2fb20a5492d",
      Unite: "unit",
      MatierePremiereUtilisees: [{ Name: "Focaccia", Qte: "1" }],
      CoutHT: "9.95",
      Statut: "Actif",
    },
    {
      Categorie: "Mise a disposition",
      DLCSecondaire: "2",
      Statut: "Actif",
      Quantite: "32",
      CoutHT: "6.49",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "FRAISE IMPORT KG (BELGIQUE)" },
      ],
      Unite: "unit",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFraise%20coup%C3%A9%20en%20deux%20(puis%20en%20lamelle)?alt=media&token=cc022fb8-6ee2-4093-8332-5821e8925a73",
      Nom: "Fraise coupé en deux (puis en lamelle)",
    },
    {
      Statut: "Actif",
      CoutHT: "0.27",
      Nom: "Sauce vinaigrette",
      MatierePremiereUtilisees: [
        { Qte: "0.004347", Name: "Huile d'olive vierge extra 5 L Ondoliva" },
        {
          Name: "Jus de citron vert 1 L Aro (convertir en g). (0,05L=53g) (1L=1060g)",
          Qte: "0.00566",
        },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.0005" },
        { Qte: "0.0000888", Name: "Sel fin 750 g Aro" },
        { Name: "Vinaigre balsamique 50 cl Maille", Qte: "0.02" },
      ],
      Unite: "g",
      Categorie: "Preparation",
      DLCSecondaire: "3",
      Quantite: "39",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20vinaigrette?alt=media&token=f9368787-1724-4f45-ae71-d6610bf00f48",
    },
    {
      Categorie: "Decoupe formatage",
      Unite: "g",
      DLCSecondaire: "3",
      CoutHT: "3.29",
      MatierePremiereUtilisees: [{ Qte: "1", Name: "POIVRON VERT" }],
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FPoivron%20vert%20en%20lamelle%20cru?alt=media&token=4b4b320f-1391-4931-b7fb-e7746ca649b1",
      Nom: "Poivron vert en lamelle cru",
      Quantite: "1000",
      Statut: "Actif",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FJalapenos%20coup%C3%A9%20en%20lamelle?alt=media&token=12f9c78a-b154-47ea-8ef0-f2437a6fb368",
      Nom: "Jalapenos coupé en lamelle",
      Categorie: "Decoupe formatage",
      DLCSecondaire: "4",
      Unite: "g",
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "Piment Jalapeno rond 4/4 810 g Emperatriz" },
      ],
      Quantite: "400",
      Statut: "Actif",
      CoutHT: "4.91",
    },
    {
      CoutHT: "1.91",
      MatierePremiereUtilisees: [
        { Qte: "0.04166", Name: "STEAK BEYOND MINCE (8X300G) RETAILSURG" },
        { Qte: "0.125", Name: "CEBETTE IMPORT BTE (EGYPTE)" },
        { Qte: "0.2", Name: "CORIANDRE BOTTE (FRANCE)" },
        {
          Name: "Jus de citron vert 1 L Aro (convertir en g). (0,05L=53g) (1L=1060g)",
          Qte: "0.009433",
        },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.001" },
        { Qte: "0.00217", Name: "Huile d'olive vierge extra 5 L Ondoliva" },
        { Qte: "0.02", Name: "Oignons frits 500 g METRO Chef" },
      ],
      Statut: "Actif",
      DLCSecondaire: "3",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFarce%20meat%20?alt=media&token=5d6db896-37fd-41e7-b650-b166ad5554ca",
      Categorie: "Preparation",
      Unite: "g",
      Nom: "Farce meat ",
      Quantite: "130",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFeuille%20de%20brick%20coup%C3%A9%20en%203?alt=media&token=01f93b49-4c8f-4b93-a02a-869ea269aea2",
      Statut: "Actif",
      DLCSecondaire: "30",
      Quantite: "30",
      Nom: "Feuille de brick coupé en 3",
      CoutHT: "1.02",
      MatierePremiereUtilisees: [
        { Name: "METRO Chef Feuille de brick 170 g", Qte: "1" },
      ],
      Unite: "unit",
      Categorie: "Mise a disposition",
    },
    {
      Categorie: "Preparation",
      Statut: "Actif",
      CoutHT: "0.72",
      Quantite: "150",
      MatierePremiereUtilisees: [
        { Qte: "0.12", Name: "TOMATE 3 VRAC (MAROC)" },
        { Name: "CEBETTE IMPORT BTE (EGYPTE)", Qte: "0.125" },
        { Qte: "0.2", Name: "CORIANDRE BOTTE (FRANCE)" },
        {
          Name: "Jus de citron vert 1 L Aro (convertir en g). (0,05L=53g) (1L=1060g)",
          Qte: "0.00943",
        },
        { Name: "Poivre noir moulu 1 kg Aro", Qte: "0.001" },
        { Qte: "0.00217", Name: "Huile d'olive vierge extra 5 L Ondoliva" },
        { Qte: "0.01", Name: "OIGNON FLT ROUGE  (PAYS-BAS)" },
        { Name: "Ail semoule fine 1 kg Aro", Qte: "0.002" },
      ],
      Nom: "Farce tomate",
      DLCSecondaire: "3",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FFarce%20tomate?alt=media&token=0974397f-adee-4f43-aece-edebb9baaf4c",
      Unite: "g",
    },
    {
      MatierePremiereUtilisees: [
        { Name: "CIVETTE-CIBOULETTE BOTTE (ESPAGNE)", Qte: "1" },
      ],
      Unite: "g",
      Categorie: "Decoupe formatage",
      CoutHT: "0.49",
      Statut: "Actif",
      Quantite: "22",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCiboulette%20coup%C3%A9%20en%20d%C3%A9?alt=media&token=40eefc84-7c6d-44be-b7f2-b78a320309ed",
      Nom: "Ciboulette coupé en dé",
      DLCSecondaire: "3",
    },
    {
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FGalette%20Tortilla%20wraps?alt=media&token=6d32d8c3-338f-4182-af27-561cc2c8ef0d",
      Categorie: "Mise a disposition",
      Statut: "Actif",
      Unite: "unit",
      MatierePremiereUtilisees: [
        {
          Qte: "1",
          Name: "METRO Chef Tortillas wraps 30 cm 18 pièces 1.62 kg",
        },
      ],
      Quantite: "18",
      Nom: "Galette Tortilla wraps",
      DLCSecondaire: "5",
      CoutHT: "4.95",
    },
    {
      MatierePremiereUtilisees: [
        { Qte: "1", Name: "CAROTTE PLATEAU 12KG (FRANCE)" },
      ],
      DLCSecondaire: "3",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FCarotte%20saut%C3%A9%20cuite?alt=media&token=27e25867-075b-4ddf-b090-cd582b64e2a0",
      Unite: "g",
      Categorie: "Preparation",
      Nom: "Carotte sauté cuite",
      Quantite: "870",
      CoutHT: "1.15",
      Statut: "Actif",
    },
    {
      CoutHT: "32.80",
      Unite: "unit",
      DLCSecondaire: "3",
      Quantite: "64",
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSteak%20beyond%20mince%2075g%20coup%C3%A9%20en%202%20(37%2C5g)?alt=media&token=5d94f1d8-6b6c-405f-ab09-47de7fad8bcb",
      MatierePremiereUtilisees: [
        { Name: "STEAK BEYOND MINCE (8X300G) RETAILSURG", Qte: "1" },
      ],
      Nom: "Steak beyond mince 75g coupé en 2 (37,5g)",
      Categorie: "Mise a disposition",
    },
    {
      Quantite: "1183.4",
      MatierePremiereUtilisees: [
        {
          Qte: "1",
          Name: "Sauce aigre-douce california Lesieur 970 ml (convertir en g) > 0,05L=61g (0,970L >1183,4g)",
        },
      ],
      Statut: "Actif",
      PhotoFirebase:
        "https://firebasestorage.googleapis.com/v0/b/reddd-6b0fd.appspot.com/o/Preparations%2FSauce%20aigre%20douce?alt=media&token=25fd9f3b-0c64-4360-af9c-3dde64bba044",
      Unite: "g",
      DLCSecondaire: "90",
      Categorie: "Mise a disposition",
      Nom: "Sauce aigre douce",
      CoutHT: "5.53",
    },
  ],
};

export async function UploadPrepDataSave() {
  const preparationDocRef = doc(firestore, "Production", "preparations");
  const preparationSnapshot = await getDoc(preparationDocRef);
  const Allpreparation = preparationSnapshot.data().preparation;

  let newallPrep = [];
  Allpreparation.forEach((prepall) => {
    let isExist = false;
    prep.preparation.forEach((prepSave) => {
      if (prepSave.Nom === prepall.Nom) {
        isExist = true;
        newallPrep.push({ ...prepall, ...prepSave });
      }
    });
    if (isExist === false) {
      newallPrep.push({ ...prepall });
    }
  });

  await setDoc(preparationDocRef, { preparation: newallPrep });

  return null;
}

export async function getListAllUserBrand() {
  const usersDocRef = collection(firestore, "users");
  const usersSnapshot = await getDocs(usersDocRef);
  const Allusers = usersSnapshot.docs.map((user) => ({
    ...user.data(),
  }));

  const AllBrand = [];
  Allusers.forEach((user) => {
    if (user.Role === "Client") {
      AllBrand.push(user.OwnerBrand);
    }
  });
  return AllBrand;
}
export async function getEnCoursDeLivraisonMatierePremiereOrdersPerSupplier() {
  const MatierePremiereOrdersDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "MatierePremiereOrder"
  );
  const MatierePremiereOrdersSnapshot = await getDoc(
    MatierePremiereOrdersDocRef
  );
  const AllMatierePremiereOrders =
    MatierePremiereOrdersSnapshot.data().MatierePremiereOrder;

  // on trie pour ne recuperer que les commande en cour de livraison
  const AllMatierePremiereOrdersEnLivraison = AllMatierePremiereOrders.filter(
    (order) => order.EtatOrder === "En Livraison"
  );
  // on recupere la liste de tout les fournisseurs
  const FournisseursDocRef = doc(firestore, "Production", "Fournisseurs");
  const FournisseursSnapshot = await getDoc(FournisseursDocRef);
  const AllFournisseurs = FournisseursSnapshot.data().Fournisseurs;
  // On recupere la liste de tout les fournisseurs qui sont dans la commande

  let OrderWithF = [];
  AllMatierePremiereOrdersEnLivraison.forEach((Order) => {
    let OrderperFournisseur = [];
    AllFournisseurs.forEach((fournisseur) => {
      let MatiereOrderofFournisseur = Order.DataOrder.filter(
        (Matiere) => Matiere.DataMatiere.Fournisseur === fournisseur.Nom
      );
      if (MatiereOrderofFournisseur.length > 0) {
        OrderperFournisseur.push(MatiereOrderofFournisseur);
      }
    });
    OrderWithF.push(OrderperFournisseur);
  });

  let ReformatedOrder = [];
  AllMatierePremiereOrdersEnLivraison.forEach((Order, index) => {
    ReformatedOrder.push({ ...Order, DataOrder: OrderWithF[index] });
  });

  //const FournisseurSansDoublons = [...new Set(AllFournisseur)];

  return ReformatedOrder;
}
export async function getEtatDesStockMatiereAuRestaurant() {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const AllStockMatiere = StockSnapshot.data().MatierePremiere;

  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const AllMatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;
  // on recupere la liste de toute les preparations qui doivent etre activent au resturant pour pouvoir indiquer au stock que c'est normal que cette preparation doit etre egale a 0
  const MatiereQuiDoiventEtreActiventAuRestaurant = (
    await getMatierePremiereShouldBeAvailableInLogistics()
  ).map((prep) => prep.IDPErpers);

  const AllstockWithDataMatiere = [];
  AllStockMatiere.forEach((matiereEnStock) => {
    AllMatierePremiere.forEach((MatiereData) => {
      if (matiereEnStock.IDPErpers === MatiereData.IDPErpers) {
        if (
          MatiereQuiDoiventEtreActiventAuRestaurant.includes(
            MatiereData.IDPErpers
          )
        ) {
          AllstockWithDataMatiere.push({
            DataMatiere: { ...MatiereData, Statut: "Actif" },
            QteEnStock: matiereEnStock.QteEnStock,
          });
        } else {
          AllstockWithDataMatiere.push({
            DataMatiere: { ...MatiereData, Statut: "Inactif" },
            QteEnStock: matiereEnStock.QteEnStock,
          });
        }
      }
    });
  });

  AllstockWithDataMatiere.sort((a, b) => a.QteEnStock - b.QteEnStock);

  return AllstockWithDataMatiere;
}
export async function getEtatDesStockPrepAuRestaurant() {
  const StockDocRef = doc(firestore, "ManagerRestaurant", "Stock");
  const StockSnapshot = await getDoc(StockDocRef);
  const AllStockPreparation = StockSnapshot.data().Preparation;

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPrep = PrepSnapshot.data().preparation;

  // on recupere la liste de toute les preparations qui doivent etre activent au resturant pour pouvoir indiquer au stock que c'est normal que cette preparation doit etre egale a 0
  const prepQuiDoiventEtreActiventAuRestaurant = (
    await getPreparationForActiveProductInRestaurant()
  ).map((prep) => prep.IDPErpers);

  // On recupere la conssomation theorique de chaque preparation depuis le dernier inventaire
  // On recupere la date du dernier inventaire
  const InventaireDocRef = doc(firestore, "ManagerRestaurant", "Inventaire");
  const InventaireSnapshot = await getDoc(InventaireDocRef);
  const AllInventaire = InventaireSnapshot.data().Inventaire;
  const DernierInventaire = AllInventaire[AllInventaire.length - 1];

  const DateDernierInventaire = new Date(DernierInventaire.date.seconds * 1000);
  const formattedDate = DateDernierInventaire.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const HourDernierInventaire = DateDernierInventaire.getHours();
  const MinDernierInventaire = DateDernierInventaire.getMinutes();

  // On recupere en fonction de la date du dernier inventaire la conso theorique de chaque preparation

  let DataConssoTheorique = await GetAnalyseViaCommandesRecuAuRestau(
    formattedDate,
    "",
    23,
    59,
    HourDernierInventaire,
    MinDernierInventaire
  );

  const AllstockWithDataPrep = [];
  AllStockPreparation.forEach((PrepEnStock) => {
    AllPrep.forEach((PrepData) => {
      if (PrepEnStock.IDPErpers === PrepData.IDPErpers) {
        if (
          prepQuiDoiventEtreActiventAuRestaurant.includes(PrepData.IDPErpers)
        ) {
          let conssotheoriquePrep = 0;
          if (DataConssoTheorique.CompteurPrep.hasOwnProperty(PrepData.Nom)) {
            conssotheoriquePrep =
              DataConssoTheorique.CompteurPrep[PrepData.Nom];
          }
          let QteEnStock = PrepEnStock.QteEnStock - conssotheoriquePrep;

          AllstockWithDataPrep.push({
            DataPrep: { ...PrepData, Statut: "Actif" },
            QteEnStock,
          });
        } else {
          let conssotheoriquePrep = 0;
          if (DataConssoTheorique.CompteurPrep.hasOwnProperty(PrepData.Nom)) {
            conssotheoriquePrep =
              DataConssoTheorique.CompteurPrep[PrepData.Nom];
          }
          let QteEnStock = PrepEnStock.QteEnStock - conssotheoriquePrep;

          AllstockWithDataPrep.push({
            DataPrep: { ...PrepData, Statut: "Inactif" },
            QteEnStock,
          });
        }
      }
    });
  });

  AllstockWithDataPrep.sort((a, b) => a.QteEnStock - b.QteEnStock);

  return AllstockWithDataPrep;
}
// Recupere la liste de toute les commandes de matiere premiere faite pour le restauarnt
export async function getAllOrderMatiereInRestauarantWithAddedData() {
  const MatiereOrderDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "MatierePremiereOrder"
  );
  const MatiereOrderSnapshot = await getDoc(MatiereOrderDocRef);
  const AllMatiereOrder = MatiereOrderSnapshot.data().MatierePremiereOrder;

  return AllMatiereOrder;
}
// Cette fonction analyse les commandes recu au restauant
// on prend la liste des commandes on regarde chacune des prep on calcul le cout de reviens et en fonction du prix de la commande on calcul la marge
// cette fonction fournie aussi un enseble de donnée de comptabilite comme la marge des clients, la TVA, les commissions des plateformes, le cout de reviens des matieres premieres
export async function GetAnalyseViaCommandesRecuAuRestau(
  startDate,
  endDate,
  endDatehour,
  endDateMin,
  startDatehour,
  startDateMin
) {
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(1000)
  );

  // Utilisez getDocs pour obtenir le snapshot.
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((order) => order.data());

  //const startDate = new Date(new Date("12/15/2023").getTime() - 86400000 * 15);
  if (endDate === "") {
    endDate = new Date();
  } else {
    endDate = new Date(endDate);
    endDate.setHours(endDatehour);
    endDate.setMinutes(endDateMin);
  }
  startDate = new Date(startDate);
  startDate.setHours(startDatehour);
  startDate.setMinutes(startDateMin);

  const listeDate = [endDate, startDate];

  let ListeCommandeEntreDeuxDates = [];
  ordersData.forEach((order) => {
    if (
      new Date(order.dataOrder.order.created_at).getTime() >
        listeDate[1].getTime() &&
      new Date(order.dataOrder.order.created_at).getTime() <
        listeDate[0].getTime()
    ) {
      ListeCommandeEntreDeuxDates.push(order);
    }
  });

  const DishDocRef = doc(firestore, "Production", "dishes");
  const DishSnapshot = await getDoc(DishDocRef);
  const AllDish = DishSnapshot.data().dishes;

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPreparation = PrepSnapshot.data().preparation;

  const pepersCollection = collection(db, "pepers");
  const pepersSnapshot = await getDocs(pepersCollection);
  const AllBrandName = pepersSnapshot.docs.map((doc) => doc.id);
  const AllPepersBrandPricing = pepersSnapshot.docs.map((doc) => doc.data());

  let CAZelty = 0;
  let CoutTotalMatiere = 0;
  let TotalPrixDeVente = 0;
  let commandeEaticPasAVbb = [];
  let TotalDesCommissionsCollecteParLesPlateformesPourLesProprietaireDeMarqueHT = 0;
  let TotalDesCommissionsCollecteParLesPlateformesPourLesMarqueVBBHT = 0;
  let TotalMargeCollecteParClientEnEuro = 0;
  let DataAboutMargeClient = [];
  let AllOrdersDataWithAddCalculateData = [];

  let ListeProdErrorCost0 = [];
  let listeProdAvecCoutDeRevien = [];

  ListeCommandeEntreDeuxDates.forEach((commande) => {
    CAZelty =
      CAZelty + commande.dataOrder.order.price.final_amount_inc_tax / 100;
    let CoutProduitDansCommande = 0;
    let coutDeRevientParProduitDansCommande = [];
    commande.listeProduitCommande.forEach((produit) => {
      // on calcul le cout de reviens de chaque produit dans la commande
      let CoutDuProduit = 0;
      AllDish.forEach((produitData) => {
        if (produitData.Production.name === produit.name) {
          produitData.Production.PreparationIntermediaire.forEach((prep) => {
            AllPreparation.forEach((PrepData) => {
              if (PrepData.Nom === prep.Name) {
                CoutProduitDansCommande =
                  CoutProduitDansCommande +
                  (parseFloat(PrepData.CoutHT) /
                    parseFloat(PrepData.Quantite)) *
                    parseFloat(prep.Qte);
                CoutDuProduit =
                  CoutDuProduit +
                  (parseFloat(PrepData.CoutHT) /
                    parseFloat(PrepData.Quantite)) *
                    parseFloat(prep.Qte);
              }
            });
          });
          coutDeRevientParProduitDansCommande.push({
            ...produit,
            CoutDuProduit,
          });
          if (CoutDuProduit === 0) {
            ListeProdErrorCost0.push({ IdCommande: commande.id, produit });
          }
        }
      });
    });
    listeProdAvecCoutDeRevien.push({
      coutDeRevientParProduitDansCommande,
      IdCommande: commande.id,
    });
    // On determine le prix de vente HT precu par VBB en fonction d'ou vient la commande
    let PrixVenteHT = 0;
    let Marge = 0;
    // Si la commande vient de deliveroo ou uber alors on regarde si elle appartient a un client ou a nous
    if (
      commande.dataOrder.order.channel === "Uber Eats" ||
      commande.dataOrder.order.channel === "Deliveroo"
    ) {
      let CheckIfTheOrderIsFromClientBrand = false;
      AllBrandName.map((brand) => {
        if (commande.dataOrder.order.virtual_brand_name === brand) {
          CheckIfTheOrderIsFromClientBrand = true;
        }
      });
      // Si elle apartient a un client alors le prix percu par vbb n'est pas le meme
      if (CheckIfTheOrderIsFromClientBrand) {
        PrixVenteHT = CoutProduitDansCommande * 2.5;
        Marge = PrixVenteHT / CoutProduitDansCommande;
        TotalDesCommissionsCollecteParLesPlateformesPourLesProprietaireDeMarqueHT =
          TotalDesCommissionsCollecteParLesPlateformesPourLesProprietaireDeMarqueHT +
          (commande.dataOrder.order.price.final_amount_exc_tax / 100) * 0.3;
        // on calcul la marge en euro que collecte le client
        let comPlateforme =
          (commande.dataOrder.order.price.final_amount_exc_tax / 100) * 0.3;
        TotalMargeCollecteParClientEnEuro =
          TotalMargeCollecteParClientEnEuro +
          (commande.dataOrder.order.price.final_amount_inc_tax / 100 / 1.1 -
            PrixVenteHT -
            comPlateforme);
        // On envoie la Data dans le tableau pour pouvoir analyser et repartir par clients
        DataAboutMargeClient.push({
          Marque: commande.dataOrder.order.virtual_brand_name,
          MargeEnValeurHT:
            commande.dataOrder.order.price.final_amount_inc_tax / 100 / 1.1 -
            PrixVenteHT -
            comPlateforme,
          MargeEnPourcentage:
            (commande.dataOrder.order.price.final_amount_inc_tax / 100 / 1.1 -
              comPlateforme) /
            PrixVenteHT,
          commande,
          PrixTTCCommande:
            commande.dataOrder.order.price.final_amount_inc_tax / 100,
          PrixHTCommande:
            commande.dataOrder.order.price.final_amount_exc_tax / 100 / 1.1,
          ComPlateforme: comPlateforme,
        });

        if (CoutProduitDansCommande !== 0) {
        } else {
        }
        // Si elle n'appartient pas a un client alors le prix percu par vbb est egale au prix de vente moins la com des plateforme
      } else {
        let CommissionPlateforme =
          (commande.dataOrder.order.price.final_amount_exc_tax / 100) * 0.3;

        PrixVenteHT =
          commande.dataOrder.order.price.final_amount_exc_tax / 100 / 1.1 -
          CommissionPlateforme;

        Marge = PrixVenteHT / CoutProduitDansCommande;

        TotalDesCommissionsCollecteParLesPlateformesPourLesMarqueVBBHT =
          TotalDesCommissionsCollecteParLesPlateformesPourLesMarqueVBBHT +
          CommissionPlateforme;
        if (CoutProduitDansCommande !== 0) {
        } else {
        }
      }
      // Si la commande arrive de eatic alors il faut effectuer un test particulier pour voir a qui appartient la commande car eatic ne comunique pas a zelty la marque
    } else if (commande.dataOrder.order.channel === "EATIC") {
      let CheckIfTheOrderIsFromClientBrand = false;

      commande.listeProduitCommande.forEach((produit) => {
        AllPepersBrandPricing.forEach((brand) => {
          brand.EaticTest.forEach((produitEaticTest) => {
            if (produit.name === produitEaticTest.NomZelty) {
              CheckIfTheOrderIsFromClientBrand = true;
            }
          });
        });
      });
      if (CheckIfTheOrderIsFromClientBrand) {
        commandeEaticPasAVbb.push(commande);

        PrixVenteHT = CoutProduitDansCommande * 2.5;
        Marge = PrixVenteHT / CoutProduitDansCommande;

        TotalDesCommissionsCollecteParLesPlateformesPourLesProprietaireDeMarqueHT =
          TotalDesCommissionsCollecteParLesPlateformesPourLesProprietaireDeMarqueHT +
          (commande.dataOrder.order.price.final_amount_exc_tax / 100) * 0.3;

        let comPlateforme =
          (commande.dataOrder.order.price.final_amount_exc_tax / 100) * 0.3;
        TotalMargeCollecteParClientEnEuro =
          TotalMargeCollecteParClientEnEuro +
          (commande.dataOrder.order.price.final_amount_inc_tax / 100 / 1.1 -
            PrixVenteHT -
            comPlateforme);

        // On envoie la Data dans le tableau pour pouvoir analyser et repartir par clients
        DataAboutMargeClient.push({
          Marque: commande.dataOrder.order.virtual_brand_name,
          MargeEnValeurHT:
            commande.dataOrder.order.price.final_amount_inc_tax / 100 / 1.1 -
            PrixVenteHT -
            comPlateforme,
          MargeEnPourcentage:
            (commande.dataOrder.order.price.final_amount_inc_tax / 100 / 1.1 -
              comPlateforme) /
            PrixVenteHT,
          commande,
          PrixTTCCommande:
            commande.dataOrder.order.price.final_amount_inc_tax / 100,
          PrixHTCommande:
            commande.dataOrder.order.price.final_amount_exc_tax / 100 / 1.1,
          ComPlateforme: comPlateforme,
        });

        if (CoutProduitDansCommande !== 0) {
        } else {
        }
      } else {
        let CommissionPlateforme =
          (commande.dataOrder.order.price.final_amount_exc_tax / 100) * 0.3;

        PrixVenteHT =
          commande.dataOrder.order.price.final_amount_exc_tax / 100 / 1.1 -
          CommissionPlateforme;

        Marge = PrixVenteHT / CoutProduitDansCommande;

        TotalDesCommissionsCollecteParLesPlateformesPourLesMarqueVBBHT =
          TotalDesCommissionsCollecteParLesPlateformesPourLesMarqueVBBHT +
          CommissionPlateforme;

        if (CoutProduitDansCommande !== 0) {
        } else {
        }
      }
      // si la commande ne vient pas d'une plateforme de livraison alors la marge est egale au prix de vente moins la TVA
    } else {
      PrixVenteHT =
        commande.dataOrder.order.price.final_amount_exc_tax / 100 / 1.1;

      Marge = PrixVenteHT / CoutProduitDansCommande;
      if (CoutProduitDansCommande !== 0) {
      } else {
      }
    }
    // console.log(
    //   `Cout de la commande : ${CoutProduitDansCommande}€ Prix de vente HT = ${PrixVenteHT}€ Marge = ${Marge} Commande : ${commande.dataOrder.order.channel}`
    // );

    AllOrdersDataWithAddCalculateData.push({
      ...commande,
      DataAddParVBB: {
        PrixVenteVBBHT: PrixVenteHT.toFixed(2),
        CoefMargeSurMatierePremiere: Marge.toFixed(2),
        ProvenanceDeLaCommande: commande.dataOrder.order.channel,
        CoutProduitDansCommande: CoutProduitDansCommande.toFixed(2),
      },
    });

    CoutTotalMatiere = CoutTotalMatiere + CoutProduitDansCommande;
    TotalPrixDeVente = TotalPrixDeVente + PrixVenteHT;
  });
  {
    /*console.log(ListeCommandeEntreDeuxDates);
  console.log(
    `MargeMoyenne: ${
      MoyenneMarge /
      (ListeCommandeEntreDeuxDates.length - CompteurCommanePasPriseEnCompte)
    }`
  );
  console.log(
    `NB Commande pas pris en compte : ${CompteurCommanePasPriseEnCompte}`
  );
  console.log(`Commande pas pris en compte ${DataCommanePasPriseEnCompte}`);
  console.log(`CA ZELTY : ${CAZelty}`);
  console.log(`Total du cout matiere pour VBB : ${CoutTotalMatiere}`);
  console.log(`Total de l'argent percu par VBB : ${TotalPrixDeVente}`);
  console.log(commandeEaticPasAVbb);
  console.log(
    `Com plateforme collecte pour proprietaire de marque : ${TotalDesCommissionsCollecteParLesPlateformesPourLesProprietaireDeMarqueHT}`
  );
  console.log(
    `Com plateforme collecte pour VBB :  ${TotalDesCommissionsCollecteParLesPlateformesPourLesMarqueVBBHT}`
  );
  console.log(
    `Total marge (Argent Gagne par le client) collecte par client en euro : ${TotalMargeCollecteParClientEnEuro}`
  );
  console.log(DataAboutMargeClient);*/
  }

  // On formate les dada des commandes qu'on a utiliser dans les calcules et on a joute a chaque produits le cout de reviens
  let ListeCommandePrisePourAnalyseWithAddCalculateData =
    AllOrdersDataWithAddCalculateData.map((Order) => {
      let newlisteProduitCommande = [];
      Order.listeProduitCommande.forEach((produit) => {
        listeProdAvecCoutDeRevien.forEach((produitAvecCout) => {
          if (Order.id === produitAvecCout.IdCommande) {
            produitAvecCout.coutDeRevientParProduitDansCommande.forEach(
              (produitCout) => {
                if (produit.id === produitCout.id) {
                  newlisteProduitCommande.push({
                    ...produit,
                    CoutDuProduit: produitCout.CoutDuProduit.toFixed(2),
                  });
                }
              }
            );
          }
        });
      });

      return { ...Order, listeProduitCommande: newlisteProduitCommande };
    });
  // On mets a jours les Datas pour retirer les produits qui ont un cout de reviens de 0
  ListeCommandePrisePourAnalyseWithAddCalculateData.forEach((Order) => {
    Order.listeProduitCommande.forEach((produit) => {
      if (produit.CoutDuProduit === 0) {
      }
    });
  });

  // On calcul en fonction des commandes pris en compte la conssomation de preparation theorique
  const ListeProduitAFacture = [];
  const ListeSupAFacture = [];
  ListeCommandePrisePourAnalyseWithAddCalculateData.forEach((commande) => {
    commande.listeProduitCommande.forEach((produit) => {
      ListeProduitAFacture.push(produit.name);
      if (produit.modifiers.lenght != 0) {
        produit.modifiers.forEach((sup) => {
          ListeSupAFacture.push(sup.name);
        });
      }
    });
  });
  const compteurProduit = ListeProduitAFacture.reduce((acc, produit) => {
    acc[produit] = (acc[produit] || 0) + 1;
    return acc;
  }, {});

  const compteurSup = ListeSupAFacture.reduce((acc, sup) => {
    acc[sup] = (acc[sup] || 0) + 1;
    return acc;
  }, {});

  // on recupere les datas des produits

  let allPrepUsed = [];
  Object.keys(compteurProduit).forEach((produit) => {
    AllDish.forEach((produitData) => {
      if (produitData.Production.name === produit) {
        produitData.Production.PreparationIntermediaire.forEach((prep) => {
          let nbProductOrder = compteurProduit[produit];
          for (let i = 0; i < nbProductOrder; i++) {
            allPrepUsed.push(prep);
          }
        });
      }
    });
  });
  // On recupere la liste de toute les preparations

  let CompteurPrep = {};

  AllPreparation.forEach((Prep) => {
    allPrepUsed.forEach((PrepUsed) => {
      if (Prep.Nom === PrepUsed.Name) {
        const qte = parseFloat(PrepUsed.Qte); // Convertir la valeur en flottant
        if (!isNaN(qte)) {
          // Vérifier si la valeur est un nombre valide
          if (CompteurPrep.hasOwnProperty(Prep.Nom)) {
            let newQte = CompteurPrep[Prep.Nom] + qte;
            CompteurPrep = { ...CompteurPrep, [Prep.Nom]: newQte };
          } else {
            CompteurPrep = {
              ...CompteurPrep,
              [Prep.Nom]: qte,
            };
          }
        } else {
          console.log("erreur");
        }
      }
    });
  });

  // On calcul la conssomation theorique de matiere premiere en fonction de la conssomation theorique des preparations

  const ConssomationMatiere = await CalculQteMatiereUsedPourUneQteDonneDePrep(
    CompteurPrep,
    AllPreparation,
    AllMatiere
  );

  return {
    MargeMoyenne: (TotalPrixDeVente / CoutTotalMatiere).toFixed(2),
    ListeProdErrorCost0,
    listeProdAvecCoutDeRevien,
    CADeTouteLesCommandesAnalysee: CAZelty.toFixed(2),
    CoutTotalMatierePourVBB: CoutTotalMatiere.toFixed(2),
    TotalPrixDeVentePourVBB: TotalPrixDeVente.toFixed(2),
    ListeCommandePrisePourAnalyseWithAddCalculateData,
    DataAboutMargeClient,
    CompteurPrep,
    ConssomationMatiere,
  };
}

export async function getLastOrder() {
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(10)
  );

  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((order) => order.data());

  return ordersData;
}
export async function GetOrderWherePrepIs(Orders, PrepName) {
  const dishesDocRef = doc(firestore, "Production", "dishes");
  const dishesSnapshot = await getDoc(dishesDocRef);
  const Alldishes = dishesSnapshot.data().dishes;

  let ListOrderWiththePrep = [];
  Orders.forEach((order) => {
    let PrepIsInTheProduct = false;
    let ProductWithThePrep = [];
    order.listeProduitCommande.forEach((produit) => {
      Alldishes.forEach((dish) => {
        if (produit.name === dish.Production.name) {
          dish.Production.PreparationIntermediaire.forEach((prep) => {
            if (prep.Name === PrepName) {
              PrepIsInTheProduct = true;
              ProductWithThePrep.push({ produit, prep });
            }
          });
        }
      });
    });
    if (PrepIsInTheProduct) {
      ListOrderWiththePrep.push({ ...order, ProductWithThePrep });
    }
  });

  return ListOrderWiththePrep;
}

export async function getConssomationTheoriquePrepEntreDeuxDates() {
  const db = getFirestore(app);
  const ordersCollection = collection(db, "Orders");
  const ordersQuery = query(
    ordersCollection,
    orderBy("id", "desc"), // Tri par rapport à l'ID dans l'ordre décroissant
    limit(1500)
  );

  // Utilisez getDocs pour obtenir le snapshot.
  const ordersSnapshot = await getDocs(ordersQuery);
  const ordersData = ordersSnapshot.docs.map((order) => order.data());

  //const startDate = new Date(new Date("12/15/2023").getTime() - 86400000 * 15);
  const startDate = new Date("12/16/2023");
  startDate.setDate(startDate.getDate() - 15);

  const EndDate = new Date("12/16/2023");
  const listeDate = [EndDate, startDate];

  let ListeCommandeEntreDeuxDates = [];
  ordersData.forEach((order) => {
    if (
      new Date(order.dataOrder.order.created_at).getTime() >
        listeDate[1].getTime() &&
      new Date(order.dataOrder.order.created_at).getTime() <
        listeDate[0].getTime()
    ) {
      ListeCommandeEntreDeuxDates.push(order);
    }
  });

  // Compte le nombre de produits vendus
  const ListeProduitAFacture = [];
  const ListeSupAFacture = [];
  ListeCommandeEntreDeuxDates.forEach((commande) => {
    commande.listeProduitCommande.forEach((produit) => {
      ListeProduitAFacture.push(produit.name);
      if (produit.modifiers.lenght != 0) {
        produit.modifiers.forEach((sup) => {
          ListeSupAFacture.push(sup.name);
        });
      }
    });
  });
  const compteurProduit = ListeProduitAFacture.reduce((acc, produit) => {
    acc[produit] = (acc[produit] || 0) + 1;
    return acc;
  }, {});

  const compteurSup = ListeSupAFacture.reduce((acc, sup) => {
    acc[sup] = (acc[sup] || 0) + 1;
    return acc;
  }, {});

  // on recupere les datas des produits
  const DishDocRef = doc(firestore, "Production", "dishes");
  const DishSnapshot = await getDoc(DishDocRef);
  const AllDish = DishSnapshot.data().dishes;

  let allPrepUsed = [];
  Object.keys(compteurProduit).forEach((produit) => {
    AllDish.forEach((produitData) => {
      if (produitData.Production.name === produit) {
        produitData.Production.PreparationIntermediaire.forEach((prep) => {
          let nbProductOrder = compteurProduit[produit];
          for (let i = 0; i < nbProductOrder; i++) {
            allPrepUsed.push(prep);
          }
        });
      }
    });
  });
  // On recupere la liste de toute les preparations

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPreparation = PrepSnapshot.data().preparation;

  let CompteurPrep = {};

  AllPreparation.forEach((Prep) => {
    allPrepUsed.forEach((PrepUsed) => {
      if (Prep.Nom === PrepUsed.Name) {
        const qte = parseFloat(PrepUsed.Qte); // Convertir la valeur en flottant
        if (!isNaN(qte)) {
          // Vérifier si la valeur est un nombre valide
          if (CompteurPrep.hasOwnProperty(Prep.Nom)) {
            let newQte = CompteurPrep[Prep.Nom] + qte;
            CompteurPrep = { ...CompteurPrep, [Prep.Nom]: newQte };
          } else {
            CompteurPrep = {
              ...CompteurPrep,
              [Prep.Nom]: qte,
            };
          }
        } else {
          console.log("erreur");
        }
      }
    });
  });

  console.log(compteurProduit);
  console.log(CompteurPrep);
  console.log(ListeCommandeEntreDeuxDates);
  //PrixAfacturer = PrixAfacturer + produit.Cost * compteurProduit[produit.NomZelty];

  return CompteurPrep;
}
// Data Input  = [{dataPrep : Prep , QtePrepEnUniteDeRecette : 50}]
export function getProportionMatierePourUneQteDePrepAFaire(ListePrep) {}
// Data ConsomationPrep = {}
export async function getConssomationTheoriqueMatiereEnFonctionDuneConsommationDePrep(
  ConsomationPrep
) {
  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPrep = PrepSnapshot.data().preparation;

  const conssomationPrep = await getConssomationTheoriquePrepEntreDeuxDates();

  Object.keys(conssomationPrep).map((prep) => {
    AllPrep.forEach((prepData) => {
      if (prepData.Nom === prep) {
        prepData.MatierePremiereUtilisees.forEach((matiere) => {});
      }
    });
  });
  return null;
}
// Data input ListeProdWithQtePrev = [{ "DishID": "", "QtePrevEnJ": 5 },...]
// Data Output = [{ "DishID": "", "PrepUsed": {prepID : 234} },...]
// L'output est exprimer en unite de recette par jours et par preparation
export async function getConssomationTheoriquePrepEnFonctionPreviConssomationProduits(
  ListeProdWithQtePrev
) {
  // on recupere les datas des produits
  const DishDocRef = doc(firestore, "Production", "dishes");
  const DishSnapshot = await getDoc(DishDocRef);
  const AllDish = DishSnapshot.data().dishes;

  let allPrepUsed = [];
  ListeProdWithQtePrev.forEach((produit) => {
    AllDish.forEach((produitData) => {
      if (produitData.Production.IDPepers === produit.DishID) {
        produitData.Production.PreparationIntermediaire.forEach((prep) => {
          let nbProductPrevi = produit.QtePrevEnJ;
          for (let i = 0; i < nbProductPrevi; i++) {
            allPrepUsed.push(prep);
          }
        });
      }
    });
  });
  // On recupere la liste de toute les preparations

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPreparation = PrepSnapshot.data().preparation;

  let CompteurPrep = {};

  AllPreparation.forEach((Prep) => {
    allPrepUsed.forEach((PrepUsed) => {
      if (Prep.Nom === PrepUsed.Name) {
        const qte = parseFloat(PrepUsed.Qte); // Convertir la valeur en flottant
        if (!isNaN(qte)) {
          // Vérifier si la valeur est un nombre valide
          if (CompteurPrep.hasOwnProperty(Prep.Nom)) {
            let newQte = CompteurPrep[Prep.Nom] + qte;
            CompteurPrep = { ...CompteurPrep, [Prep.Nom]: newQte };
          } else {
            CompteurPrep = {
              ...CompteurPrep,
              [Prep.Nom]: qte,
            };
          }
        } else {
          console.log("erreur");
        }
      }
    });
  });

  return CompteurPrep;
}
export async function getIngredientsUsedWithListOfPrep(preparations) {
  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPreparation = PrepSnapshot.data().preparation;

  const ingredientsUsed = {};

  Object.keys(preparations).forEach((prepName) => {
    const prepQty = preparations[prepName];

    const prep = AllPreparation.find((prep) => prep.Nom === prepName);

    if (prep) {
      const ingredients = prep.MatierePremiereUtilisees;

      ingredients.forEach((ingredient) => {
        if (ingredientsUsed.hasOwnProperty(ingredient.Name)) {
          let newquantity = ingredientsUsed[ingredient.Name] + ingredient.Qte;
          ingredientsUsed[ingredient.Name] = newquantity;
        } else {
          ingredientsUsed[ingredient.Name] = ingredient.Qte;
        }
      });
    }
  });
  console.log(ingredientsUsed);

  return ingredientsUsed;
}
export async function getQteMatiereCommandeBetween2Dates() {
  const MatierePremiereOrderDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "MatierePremiereOrder"
  );
  const MatierePremiereOrderSnapshot = await getDoc(MatierePremiereOrderDocRef);
  const AllMatierePremiereOrder =
    MatierePremiereOrderSnapshot.data().MatierePremiereOrder;

  const startDate = new Date("12/23/2023");

  const EndDate = new Date();
  const listeDate = [EndDate, startDate];

  let ListeCommandeEntreDeuxDates = [];
  AllMatierePremiereOrder.forEach((order) => {
    if (
      new Date(order.date.seconds * 1000).getTime() > listeDate[1].getTime() &&
      new Date(order.date.seconds * 1000).getTime() < listeDate[0].getTime()
    ) {
      ListeCommandeEntreDeuxDates.push(order);
    }
  });
  // on isole dans les commandes de matieres les commandes qui ont ete livré
  let allMatiereRecived = [];
  ListeCommandeEntreDeuxDates.forEach((order) => {
    order.DataOrder.forEach((matiere) => {
      if (matiere.hasOwnProperty("QteRecived")) {
        allMatiereRecived.push(matiere);
      }
    });
  });

  // on recupere toute les data des matieres premieres
  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const AllMatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;

  console.log(allMatiereRecived);

  let matierePremiereRecive = {};
  AllMatierePremiere.forEach((matiereObject) => {
    allMatiereRecived.forEach((matiereRecived) => {
      if (matiereObject.IDPErpers === matiereRecived.DataMatiere.IDPErpers) {
        if (
          matierePremiereRecive.hasOwnProperty(matiereRecived.DataMatiere.Nom)
        ) {
          let newquantity =
            matierePremiereRecive[matiereRecived.DataMatiere.Nom] +
            parseFloat(matiereRecived.QteRecived) *
              parseFloat(matiereObject.QteConditionnement);
          matierePremiereRecive[matiereRecived.DataMatiere.Nom] = newquantity;
        } else {
          matierePremiereRecive[matiereRecived.DataMatiere.Nom] =
            parseFloat(matiereRecived.QteRecived) *
            parseFloat(matiereObject.QteConditionnement);
        }
      }
    });
  });
  console.log(matierePremiereRecive);
  return null;
}
export async function getQteMatiereRecuBetween2Dates(
  startDate,
  endDate,
  endDatehour,
  endDateMin,
  startDatehour,
  startDateMin
) {
  const MatierePremiereOrderDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "MatierePremiereOrder"
  );
  const MatierePremiereOrderSnapshot = await getDoc(MatierePremiereOrderDocRef);
  const AllMatierePremiereOrder =
    MatierePremiereOrderSnapshot.data().MatierePremiereOrder;

  let eDate = endDate !== "" ? new Date(endDate) : new Date();
  eDate.setHours(endDatehour);
  eDate.setMinutes(endDateMin);
  let sDate = new Date(startDate);
  sDate.setHours(startDatehour);
  sDate.setMinutes(startDateMin);

  const listeDate = [eDate, sDate];

  // on isole dans les commandes de matieres les commandes qui ont ete livré et qui sont entre les deux dates
  let allMatiereRecived = [];
  AllMatierePremiereOrder.forEach((order) => {
    order.DataOrder.forEach((matiere) => {
      if (matiere.hasOwnProperty("QteRecived")) {
        if (
          new Date(matiere.TimeOrderMiseEnStock.seconds * 1000).getTime() >
            listeDate[1].getTime() &&
          new Date(matiere.TimeOrderMiseEnStock.seconds * 1000).getTime() <
            listeDate[0].getTime()
        ) {
          allMatiereRecived.push(matiere);
        }
      }
    });
  });

  // on recupere toute les data des matieres premieres
  const MatierePremiereDocRef = doc(
    firestore,
    "Production",
    "Matiere Premiere"
  );
  const MatierePremiereSnapshot = await getDoc(MatierePremiereDocRef);
  const AllMatierePremiere = MatierePremiereSnapshot.data().MatierePremiere;

  let matierePremiereRecive = {};
  AllMatierePremiere.forEach((matiereObject) => {
    allMatiereRecived.forEach((matiereRecived) => {
      if (matiereObject.IDPErpers === matiereRecived.DataMatiere.IDPErpers) {
        if (
          matierePremiereRecive.hasOwnProperty(matiereRecived.DataMatiere.Nom)
        ) {
          let newquantity =
            matierePremiereRecive[matiereRecived.DataMatiere.Nom].Qte +
            parseFloat(matiereRecived.QteRecived);

          let QteOrder =
            matierePremiereRecive[matiereRecived.DataMatiere.Nom].QteOrder +
            parseFloat(matiereRecived.Qte);

          matierePremiereRecive[matiereRecived.DataMatiere.Nom] = {
            ...matierePremiereRecive[matiereRecived.DataMatiere.Nom],
            Qte: newquantity,
            QteOrder,
          };
        } else {
          let qte = parseFloat(matiereRecived.QteRecived);

          let QteOrder = parseFloat(matiereRecived.Qte);

          matierePremiereRecive[matiereRecived.DataMatiere.Nom] = {
            DataMatiere: matiereObject,
            Qte: qte,
            QteOrder,
          };
        }
      }
    });
  });

  return matierePremiereRecive;
}
// Fonction qui prend en entre le nom d'une matiere premiere et qui retourne la qte de cette matiere presente dans toute les prep de l'inventaire de prep
export function getQteMatierePrepInPrepInventaire(
  MatiereName,
  Inventaire,
  AllMatierePremiereData,
  ListePrepRefaitPerPerpAprendreEnCompte
) {
  AllMatierePremiereData = AllMatierePremiereData.filter(
    (matiere) => matiere.Nom === MatiereName
  );

  let ListePrepWhoUseThisMatiere = [];
  // On regarde dans chaque preparation dans l'inventaire et on regarde si une des prep utilise la matiere premiere en question si oui on calcul combien de matiere on ete decremente du stock
  Inventaire.DataInventairePrep.forEach((prepInInventaire) => {
    if (prepInInventaire.Qte > 0) {
      prepInInventaire.DataPrep.MatierePremiereUtilisees.forEach(
        (MatiereUtiliseInPrep) => {
          if (MatiereUtiliseInPrep.Name === MatiereName) {
            // si la preparation contien la matiere premiere alors on calcul la qte de matiere premiere utilise en fonction de la qte de preparation restante
            let PrepRefaite = ListePrepRefaitPerPerpAprendreEnCompte.filter(
              (prepRefaite) =>
                prepRefaite.PrepData.Nom === prepInInventaire.DataPrep.Nom
            );
            if (PrepRefaite.length > 0) {
              PrepRefaite = PrepRefaite[0];
              let DataMatiereUtiliseAuStock =
                PrepRefaite.MatiereUtiliseAuStock.filter(
                  (matiere) => matiere.Name === MatiereName
                );

              let QteConvertEnConditionnement =
                (prepInInventaire.Qte /
                  parseFloat(PrepRefaite.QteRefait.replace(",", "."))) *
                (DataMatiereUtiliseAuStock[0].Qte /
                  AllMatierePremiereData[0].QteConditionnement);

              ListePrepWhoUseThisMatiere.push({
                PrepName: prepInInventaire.DataPrep.Nom,
                NomMatiere: MatiereName,
                QteMatiereUsedEnConditionnement: QteConvertEnConditionnement,
                conditionnement: AllMatierePremiereData[0].Conditionnement,
                conditionnementValue:
                  AllMatierePremiereData[0].QteConditionnement,
                type: "Use PrepRefait",
              });
            } else {
              let QteConvertEnConditionnement =
                (prepInInventaire.Qte / prepInInventaire.DataPrep.Quantite) *
                (MatiereUtiliseInPrep.Qte /
                  AllMatierePremiereData[0].QteConditionnement);

              ListePrepWhoUseThisMatiere.push({
                PrepName: prepInInventaire.DataPrep.Nom,
                NomMatiere: MatiereName,
                QteMatiereUsedEnConditionnement: parseFloat(
                  QteConvertEnConditionnement
                ),
                conditionnement: AllMatierePremiereData[0].Conditionnement,
                conditionnementValue:
                  AllMatierePremiereData[0].QteConditionnement,
                type: "use Standard",
              });
            }
          }
        }
      );
    }
  });

  let SumAllQteMatiereUsedInConditionnementValue = 0;

  ListePrepWhoUseThisMatiere.forEach((conso) => {
    SumAllQteMatiereUsedInConditionnementValue =
      SumAllQteMatiereUsedInConditionnementValue +
      conso.QteMatiereUsedEnConditionnement;
  });
  SumAllQteMatiereUsedInConditionnementValue =
    SumAllQteMatiereUsedInConditionnementValue.toFixed(2);

  return SumAllQteMatiereUsedInConditionnementValue;
}

export async function GetConssomationMatiereEntreDeuxInventaires(
  IDInventaireSelectedStart,
  IDInventaireSelectedEnd
) {
  const InventaireDocRef = doc(firestore, "ManagerRestaurant", "Inventaire");
  const InventaireSnapshot = await getDoc(InventaireDocRef);
  const AllInventaire = InventaireSnapshot.data().Inventaire;

  // On filter parmis tout les inventaire l'inventaire de depart et l'inventaire de fin
  let InventaireStart = AllInventaire.filter(
    (order) => order.IDPepersInventaire === IDInventaireSelectedStart
  );

  let InventaireEnd = AllInventaire.filter(
    (order) => order.IDPepersInventaire === IDInventaireSelectedEnd
  );

  // On recupere les dates des inventaires
  let InventaireStartDate = null;
  let InventaireStartDateString = null;
  if (InventaireStart.length !== 0) {
    InventaireStartDate = new Date(InventaireStart[0].date.seconds * 1000);
    const year = InventaireStartDate.getFullYear();
    const month = String(InventaireStartDate.getMonth() + 1).padStart(2, "0");
    const day = String(InventaireStartDate.getDate()).padStart(2, "0");
    InventaireStartDateString = `${year}/${month}/${day}`;
  } else {
    InventaireStartDate = new Date();
  }

  let InventaireEndDate = null;
  let InventaireEndDateString = null;
  if (InventaireEnd.length !== 0) {
    InventaireEndDate = new Date(InventaireEnd[0].date.seconds * 1000);
    const year = InventaireEndDate.getFullYear();
    const month = String(InventaireEndDate.getMonth() + 1).padStart(2, "0");
    const day = String(InventaireEndDate.getDate()).padStart(2, "0");
    InventaireEndDateString = `${year}/${month}/${day}`;
  } else {
    InventaireEndDate = new Date();
  }

  // On recupere la quantite de matiere premiere recu au restuarant entre les deux dates d'inventaire
  const QteMatiereRecuAuRestauarnt = await getQteMatiereRecuBetween2Dates(
    InventaireStartDateString,
    InventaireEndDateString,
    InventaireEndDate.getHours(),
    InventaireEndDate.getMinutes(),
    InventaireStartDate.getHours(),
    InventaireStartDate.getMinutes()
  );

  console.log(QteMatiereRecuAuRestauarnt);

  // Il fauut maintenant aditionner la qte de matiere dans les preparations et dans les mises a disposition
  // c'est a dire si il reste 100 g de confit d'oignon alors on calculen fonction de la propostion de matiere
  // mise dans cette prep specifiquement en utilisant l'historique des prep refaites

  const PrepRefaitDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "HistoricRefairePrep"
  );
  const PrepRefaitSnapshot = await getDoc(PrepRefaitDocRef);
  const AllPrepRefait = PrepRefaitSnapshot.data().EventRefairePrep;

  const PrepDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPrep = PrepSnapshot.data().preparation;

  // on selectionne la derniere prep refait pour chaque prep
  let DernierePrepRefaitPerPerpInvenatireStart = [];
  AllPrep.forEach((prepObject) => {
    let PrepRefait = AllPrepRefait.filter(
      (prepRefait) =>
        prepRefait.IDPrepPepers === prepObject.IDPErpers &&
        prepRefait.date.seconds * 1000 < InventaireStartDate.getTime()
    );
    if (PrepRefait.length !== 0) {
      DernierePrepRefaitPerPerpInvenatireStart.push(
        PrepRefait[PrepRefait.length - 1]
      );
    }
  });

  let DernierePrepRefaitPerPerpInvenatireEnd = [];
  AllPrep.forEach((prepObject) => {
    let PrepRefait = AllPrepRefait.filter(
      (prepRefait) =>
        prepRefait.IDPrepPepers === prepObject.IDPErpers &&
        prepRefait.date.seconds * 1000 < InventaireEndDate.getTime()
    );
    if (PrepRefait.length !== 0) {
      DernierePrepRefaitPerPerpInvenatireEnd.push(
        PrepRefait[PrepRefait.length - 1]
      );
    }
  });

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  // On calcul la quantite de matiere premiere consomme entre les deux dates d'inventaire
  // c'est a dire la qte de matiere present au premier inventaire + la qte recu au restuarant - la qte present au deuxieme inventaire
  let QteMatiereConssomee = [];
  if (InventaireStart.length !== 0 && InventaireEnd.length !== 0) {
    InventaireStart[0].DataInventaireMatiere.forEach((matiere) => {
      let newQteMatiere = 0;
      let QteMatiereRecuAurestuarant = 0;

      InventaireEnd[0].DataInventaireMatiere.forEach((matiereEnd) => {
        if (
          matiere.DataMatiere.IDPErpers === matiereEnd.DataMatiere.IDPErpers
        ) {
          Object.keys(QteMatiereRecuAuRestauarnt).forEach((matiereRecu) => {
            if (matiereRecu === matiere.DataMatiere.Nom) {
              QteMatiereRecuAurestuarant =
                QteMatiereRecuAuRestauarnt[matiereRecu].Qte;
            }
          });

          let QteMatiereUsedInPrepInventaireStart =
            getQteMatierePrepInPrepInventaire(
              matiere.DataMatiere.Nom,
              InventaireStart[0],
              AllMatiere,
              DernierePrepRefaitPerPerpInvenatireStart
            );

          let QteMatiereUsedInPrepInventaireEnd =
            getQteMatierePrepInPrepInventaire(
              matiere.DataMatiere.Nom,
              InventaireEnd[0],
              AllMatiere,
              DernierePrepRefaitPerPerpInvenatireEnd
            );
          newQteMatiere =
            matiere.Qte +
            parseFloat(QteMatiereUsedInPrepInventaireStart) +
            QteMatiereRecuAurestuarant -
            matiereEnd.Qte -
            parseFloat(QteMatiereUsedInPrepInventaireEnd);
        }
      });
      QteMatiereConssomee.push({
        matiereName: matiere.DataMatiere.Nom,
        IDPErpers: matiere.DataMatiere.IDPErpers,
        QteConssomee: newQteMatiere,
      });
    });
  }

  //console.log(QteMatiereRecuAuRestauarnt);
  //console.log(InventaireStart);
  // console.log(InventaireEnd);
  //console.log(QteMatiereConssomee);

  const ConsoTheoriqueEntreDeuxInventaire =
    await GetAnalyseViaCommandesRecuAuRestau(
      InventaireStartDateString,
      InventaireEndDateString,
      InventaireEndDate.getHours(),
      InventaireEndDate.getMinutes(),
      InventaireStartDate.getHours(),
      InventaireStartDate.getMinutes()
    );

  console.log(ConsoTheoriqueEntreDeuxInventaire.CompteurPrep);

  return {
    QteMatiereConssomee: QteMatiereConssomee,
    ConsoTheoriquePrepEntreDeuxInventaire:
      ConsoTheoriqueEntreDeuxInventaire.CompteurPrep,
    ConssomationTheoriqueMatiere:
      ConsoTheoriqueEntreDeuxInventaire.ConssomationMatiere,
  };
}
export async function GetAnalyseConssomationPrep(StartDate, EndDate) {
  const PrepRefaitDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "HistoricRefairePrep"
  );
  const PrepRefaitSnapshot = await getDoc(PrepRefaitDocRef);
  const AllPrepRefait = PrepRefaitSnapshot.data().EventRefairePrep;

  let AllPrepRefaitEntre2Dates = [];
  AllPrepRefait.forEach((prep) => {
    if (
      new Date(prep.date.seconds * 1000).getTime() > StartDate.getTime() &&
      new Date(prep.date.seconds * 1000).getTime() < EndDate.getTime()
    ) {
      AllPrepRefaitEntre2Dates.push(prep);
    }
  });

  // on recupere la base de toute les preparations pour classer les preparations et compter la consommation de chacune
  const preparationsDocRef = doc(firestore, "Production", "preparations");
  const preparationsSnapshot = await getDoc(preparationsDocRef);
  const AllPrep = preparationsSnapshot.data().preparation;

  let QtePrepRefaiteParPrep = {};
  AllPrep.forEach((prep) => {
    AllPrepRefaitEntre2Dates.forEach((prepRefait) => {
      if (prep.Nom === prepRefait.PrepData.Nom) {
        if (QtePrepRefaiteParPrep.hasOwnProperty(prep.Nom)) {
          let newQte =
            QtePrepRefaiteParPrep[prep.Nom] + parseFloat(prepRefait.QteRefait);
          QtePrepRefaiteParPrep[prep.Nom] = newQte;
        } else {
          QtePrepRefaiteParPrep[prep.Nom] = parseFloat(prepRefait.QteRefait);
        }
      }
    });
  });
  return { AllPrepRefaitEntre2Dates, QtePrepRefaiteParPrep };
}

export async function getQtePrepRefaiteEntre2Dates() {
  const PrepDocRef = doc(firestore, "ManagerRestaurant", "HistoricRefairePrep");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPreparation = PrepSnapshot.data().preparation;
  return null;
}

export async function getConsommationPrepEntreDeuxInventaires() {
  return null;
}

export async function VerifyIfQtePrepRefaitIsOkWithMatiereUsedForThat() {
  return null;
}

export async function getAllInventaireDataBetween2Date(startDate, endDate) {
  const InventaireDocRef = doc(firestore, "ManagerRestaurant", "Inventaire");
  const InventaireSnapshot = await getDoc(InventaireDocRef);
  const AllInventaire = InventaireSnapshot.data().Inventaire;

  let eDate = endDate !== "" ? new Date(endDate) : new Date();
  eDate.setHours(23);
  eDate.setMinutes(59);
  let sDate = new Date(startDate);
  sDate.setHours(0);
  sDate.setMinutes(1);

  const listeDate = [eDate, sDate];

  let AllInventaireEntre2Dates = [];
  AllInventaire.forEach((inventaire) => {
    if (
      new Date(inventaire.date.seconds * 1000).getTime() >
        listeDate[1].getTime() &&
      new Date(inventaire.date.seconds * 1000).getTime() <
        listeDate[0].getTime()
    ) {
      AllInventaireEntre2Dates.push(inventaire);
    }
  });

  return AllInventaireEntre2Dates;
}

export async function getQtePrepJeterEntre2Dates() {
  const PrepDocRef = doc(firestore, "ManagerRestaurant", "HistoricRefairePrep");
  const PrepSnapshot = await getDoc(PrepDocRef);
  const AllPreparation = PrepSnapshot.data().preparation;
  return null;
}

export async function CheckIfModifyStateProductImplicateNewPreparationorRemovePrep(
  ListeProduct
) {
  const DishDocRef = doc(firestore, "Production", "dishes");
  const DishSnapshot = await getDoc(DishDocRef);
  const AllDish = DishSnapshot.data().dishes;

  let ListProductPasseDeActifAInactif = [];
  let ListProductPasseDeInactifAActif = [];
  ListeProduct.forEach((productToUpdate) => {
    AllDish.forEach((product) => {
      if (productToUpdate.Production.IDPepers === product.Production.IDPepers) {
        if (product.Production.hasOwnProperty("StateProduct")) {
          if (
            product.Production.StateProduct === "Actif" &&
            productToUpdate.Production.StateProduct === "Desactive"
          ) {
            ListProductPasseDeActifAInactif.push(productToUpdate);
          } else if (
            product.Production.StateProduct === "Desactive" &&
            productToUpdate.Production.StateProduct === "Actif"
          ) {
            ListProductPasseDeInactifAActif.push(productToUpdate);
          }
        } else {
          if (productToUpdate.Production.hasOwnProperty("StateProduct")) {
            if (productToUpdate.Production.StateProduct === "Actif") {
              ListProductPasseDeInactifAActif.push(productToUpdate);
            }
          }
        }
      }
    });
  });

  // On fasit la liste de toute les preparataions qui doivent etre activent au restaurant avec la configuartion des produits actif
  let listPrepPreviousConfig = [];
  AllDish.forEach((product) => {
    if (product.Production.hasOwnProperty("StateProduct")) {
      if (product.Production.StateProduct === "Actif") {
        product.Production.PreparationIntermediaire.forEach((prep) => {
          listPrepPreviousConfig.push(prep);
        });
      }
    }
  });
  let listPrepPreviousConfigSansDoublons = [...new Set(listPrepPreviousConfig)];
  let listPrepPreviousConfigSansDoublonsName =
    listPrepPreviousConfigSansDoublons.map((prep) => prep.Name);
  // On fasit la liste de toute les preparataions qui doivent etre activent au restaurant avec la nouvelle configuartion
  let listPrepNewConfig = [];
  ListeProduct.forEach((product) => {
    if (product.Production.hasOwnProperty("StateProduct")) {
      if (product.Production.StateProduct === "Actif") {
        product.Production.PreparationIntermediaire.forEach((prep) => {
          listPrepNewConfig.push(prep);
        });
      }
    }
  });
  let listPrepNewConfigSansDoublons = [...new Set(listPrepNewConfig)];
  let listPrepNewConfigSansDoublonsName = listPrepNewConfigSansDoublons.map(
    (prep) => prep.Name
  );

  function comparePreparations(PrepPreviousConfig, PrepNewConfig) {
    const prepInPreviousConfig = new Set(PrepPreviousConfig);
    const prepInNewConfig = new Set(PrepNewConfig);

    const prepInLess = [...prepInPreviousConfig].filter(
      (prep) => !prepInNewConfig.has(prep)
    );
    const prepInMore = [...prepInNewConfig].filter(
      (prep) => !prepInPreviousConfig.has(prep)
    );

    return {
      prepInLess,
      prepInMore,
    };
  }

  const comparisonResult = comparePreparations(
    listPrepPreviousConfigSansDoublonsName,
    listPrepNewConfigSansDoublonsName
  );
  const prepInLess = comparisonResult.prepInLess;
  const prepInMore = comparisonResult.prepInMore;

  //console.log("Préparations en moins :", prepInLess);
  //console.log("Préparations en plus :", prepInMore);

  // On effectue la meme operation pour verifier si cela implique des matieres premieres en plus ou en moins

  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  const preparationsDocRef = doc(firestore, "Production", "preparations");
  const preparationsSnapshot = await getDoc(preparationsDocRef);
  const Allpreparations = preparationsSnapshot.data().preparation;

  let listMatierePreviousConfig = [];
  listPrepPreviousConfigSansDoublonsName.forEach((prepName) => {
    Allpreparations.forEach((prepObject) => {
      if (prepObject.Nom === prepName) {
        prepObject.MatierePremiereUtilisees.forEach((matiere) => {
          listMatierePreviousConfig.push(matiere);
        });
      }
    });
  });
  let listMatierePreviousConfigSansDoublons = [
    ...new Set(listMatierePreviousConfig),
  ];
  let listMatierePreviousConfigSansDoublonsName =
    listMatierePreviousConfigSansDoublons.map((matiere) => matiere.Name);

  let listMatiereNewConfig = [];
  listPrepNewConfigSansDoublonsName.forEach((prepName) => {
    Allpreparations.forEach((prepObject) => {
      if (prepObject.Nom === prepName) {
        prepObject.MatierePremiereUtilisees.forEach((matiere) => {
          listMatiereNewConfig.push(matiere);
        });
      }
    });
  });
  let listMatiereNewConfigSansDoublons = [...new Set(listMatiereNewConfig)];
  let listMatiereNewConfigSansDoublonsName =
    listMatiereNewConfigSansDoublons.map((matiere) => matiere.Name);

  const comparisonResultMatiere = comparePreparations(
    listMatierePreviousConfigSansDoublonsName,
    listMatiereNewConfigSansDoublonsName
  );
  const MatiereInLess = comparisonResultMatiere.prepInLess;
  const MatiereInMore = comparisonResultMatiere.prepInMore;

  //console.log("Matiere en moins :", MatiereInLess);
  //console.log("Matiere en plus :", MatiereInMore);

  return { prepInLess, prepInMore, MatiereInLess, MatiereInMore };

  {
    /*
  // On doit maintenant organiser les commandes des nouvelles matieres premieres en fonction des delais de livraison de chacun
  // Pour pouvoir determiner la date a l'aquelle toute les matieres premieres seront presente au restuarant
  // Il faut aussi faire attentions que la DLC primaire des produits commandé en premier ne soit pas depasser avant que les autres matieres premieres soit arrivées
  // Il faut definir la qte de matiere à commander, on la defini en fonction de la qte previsionel de produit vendu
  // il faut aussi verifier les qte min de commande, si on doit passer une commande chez un fournisseur mais que la qte est trop petite il faut attendre la prochaine livraion que le restaurant devra faire avec ce fournisseur

  // On regarde les matieres premieres en plus et on les classe par fournisseur pour pouvoir les commander


  // On Calcul la Qte de matiere premiere à commander en fonction des qte previsionelles pour les nouveaux produits à activer
  // En fonction de cette meme Qte previsionelle on regarde comment cela devrait impacter les commandes de matiere qui sont deja entree en logistique

  let ListProductPasseDeInactifAActifFormated =
    ListProductPasseDeInactifAActif.map((productPasseActif) => {
      return { DishID: productPasseActif.Production.IDPepers, QtePrevEnJ: 2 };
    });

  let result =
    await getConssomationTheoriquePrepEnFonctionPreviConssomationProduits(
      ListProductPasseDeInactifAActifFormated
    );

  console.log(result);
  return null;
*/
  }
}

export async function getAllActualitiesManagerRestaurant() {
  const ActuDocRef = doc(
    firestore,
    "ManagerRestaurant",
    "FilDactualiteRestaurant"
  );
  const ActuSnapshot = await getDoc(ActuDocRef);
  const AllActu = ActuSnapshot.data().Actualite;

  function createListOfDates(startDate, numberOfDays) {
    const datesList = [];
    const currentDate = new Date(startDate);

    for (let i = 0; i < numberOfDays; i++) {
      datesList.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesList;
  }

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}/${month}/${day}`;

  const startDate = new Date(new Date(formattedDate).getTime() - 86400000 * 9);
  let futureDates = createListOfDates(startDate, 10);
  futureDates = [...futureDates, new Date()];

  let ActuPerDay = [];
  for (let i = 0; i < 10; i++) {
    let ActuDujour = [];
    AllActu.forEach((actu) => {
      if (
        new Date(actu.DateToDisplay.seconds * 1000).getTime() >
          startDate.getTime() + 86400000 * i &&
        new Date(actu.DateToDisplay.seconds * 1000).getTime() <
          startDate.getTime() + 86400000 * (i + 1)
      ) {
        ActuDujour.push(actu);
      }
    });
    if (ActuDujour.length > 0) {
      const today = new Date(startDate.getTime() + 86400000 * i);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;

      ActuPerDay.push({ Day: formattedDate, ActuDujour });
    }
  }

  return ActuPerDay;
}

export async function CalculCoutProductionPourUneQteDonneDePrep(
  ListePrepObject
) {
  console.log(ListePrepObject);
}
// output est calcule pour chaque matiere en conditionnement pour chaque mariere
export async function CalculQteMatiereUsedPourUneQteDonneDePrep(
  CompteurPrep,
  AllPreparation,
  AllMatierePremiereData
) {
  let ListeMatierePremiereACompter = {};
  Object.keys(CompteurPrep).forEach((prepCompteur) => {
    AllPreparation.forEach((prepObject) => {
      if (prepObject.Nom === prepCompteur) {
        let PropostionDePrep =
          CompteurPrep[prepCompteur] /
          parseFloat(prepObject.Quantite.replace(",", "."));
        prepObject.MatierePremiereUtilisees.forEach((matiere) => {
          let QteConditionnement = 0;
          let DataMatiere = AllMatierePremiereData.filter(
            (matiereObject) => matiereObject.Nom === matiere.Name
          );
          if (DataMatiere.length > 0) {
            QteConditionnement = parseFloat(
              DataMatiere[0].QteConditionnement.replace(",", ".")
            );
          }

          if (ListeMatierePremiereACompter.hasOwnProperty(matiere.Name)) {
            let newQte =
              ListeMatierePremiereACompter[matiere.Name].Qte +
              (matiere.Qte * PropostionDePrep) / QteConditionnement;
            ListeMatierePremiereACompter[matiere.Name] = parseFloat(
              newQte.toFixed(2)
            );
          } else {
            let newQte = (matiere.Qte * PropostionDePrep) / QteConditionnement;
            ListeMatierePremiereACompter[matiere.Name] = parseFloat(
              newQte.toFixed(2)
            );
          }
        });
      }
    });
  });
  return ListeMatierePremiereACompter;
}
export async function getAllFournisseurData() {
  const FournisseurDocRef = doc(firestore, "Production", "Fournisseurs");
  const FournisseurSnapshot = await getDoc(FournisseurDocRef);
  const AllFournisseur = FournisseurSnapshot.data().Fournisseurs;

  return AllFournisseur;
}

// Input : {MatiereInLess:[], MatiereInMore:[], prepInLess : [], prepInMore: []}
// OutPut : cout pour le client d'implementer ca
export async function CalculChargeFixeForNewPreparationAndNewMatiere(Data) {
  // Le cout pour implementer une nouvelle matiere premiere est:
  // 1- le cout du stockage de la matiere premiere qui correcpond a la qte du stock tempond * volume de stockage par unite de mesure
  // 2- Un minimum de commande qui correspond a l'utilisation theorique de la matiere premiere en fonction de la duree de la DLC primaire
  const MatiereDocRef = doc(firestore, "Production", "Matiere Premiere");
  const MatiereSnapshot = await getDoc(MatiereDocRef);
  const AllMatiere = MatiereSnapshot.data().MatierePremiere;

  const PrepeDocRef = doc(firestore, "Production", "preparations");
  const PrepSnapshot = await getDoc(PrepeDocRef);
  const AllPrep = PrepSnapshot.data().preparation;

  let CoutStockageNewMatiere = 0;
  let GestionReapprovisionnement = 0;
  Data.MatiereInMore.forEach((matiere) => {
    GestionReapprovisionnement = GestionReapprovisionnement + 15;
    AllMatiere.forEach((matiereObject) => {
      if (matiereObject.Nom === matiere) {
        if (matiereObject.hasOwnProperty("Stockage")) {
          if (matiereObject.Stockage === "Frigo") {
            // On calcul le cout de stockage au frigo

            CoutStockageNewMatiere = CoutStockageNewMatiere + 25;
          } else if (matiereObject.Stockage === "Congelateur") {
            // On calcul le cout de stockage au congelateur

            CoutStockageNewMatiere = CoutStockageNewMatiere + 35;
          } else if (matiereObject.Stockage === "Air Libre") {
            // On calcul le cout de stockage a l'air libre

            CoutStockageNewMatiere = CoutStockageNewMatiere + 15;
          }
        } else {
          console.log(
            `ATTENTION La matiere premiere n'a pas de stockage defini ${matiereObject.Nom}`
          );
        }
      }
    });
  });
  // Pour les preparation c'est pareil il y a un cout de stockage plus un min de commande si ce min de commande n'est pas fait alors le client doit payer
  let CoutStockageNewPrep = 0;
  let ListePrepInMoreObject = [];
  Data.prepInMore.forEach((Prep) => {
    GestionReapprovisionnement = GestionReapprovisionnement + 25;
    AllPrep.forEach((PrepObject) => {
      if (PrepObject.Nom === Prep) {
        ListePrepInMoreObject.push(PrepObject);
        if (PrepObject.Categorie === "Mise a disposition") {
          CoutStockageNewPrep = CoutStockageNewPrep + 15;
        } else if (PrepObject.Categorie === "Preparation") {
          CoutStockageNewPrep = CoutStockageNewPrep + 85;
        } else if (PrepObject.Categorie === "Decoupe formatage") {
          CoutStockageNewPrep = CoutStockageNewPrep + 35;
        }
      }
    });
  });
  // On doit maintenant calculer le volume min de commande a atteinde pour chaque preparation

  const FormatedListePrepInMoreObject = ListePrepInMoreObject.map((prep) => ({
    PrepId: prep.IDPErpers,
    Qte: 1000,
  }));
  const CoutProductionPourUneQteDonneDePrep =
    CalculCoutProductionPourUneQteDonneDePrep(FormatedListePrepInMoreObject);

  return {
    CoutStockageNewMatiere,
    GestionReapprovisionnement,
    CoutStockageNewPrep,
  };
  // Le cout pour implementer une nouvelle preparation est:
  // 1- Le cout du stockage de la preparation quand la cuisine est fermee (faut il le stocker au frigo, au congelateur ou a l'air libre)
  // 2- Le cout de la mise a disposition quand la cuisine est ouverte (faut il le stocker au frigo, au congelateur ou a l'air libre)
}
