import React, { useState, useEffect } from "react";
import "./DisplayMatiereForAnIngredient.css";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import { getAllMatiereOfAnIngredientWithFormatedData } from "../../../Data/firebaseInteractionOwnerV2";
import ChangeDefaultRefMatiereForIngredient from "../Button/ChangeDefaultRefMatiereForIngredient/ChangeDefaultRefMatiereForIngredient";

const DisplayMatiereForAnIngredient = ({ Selected }) => {
  const [DataDisplay, setDataDisplay] = useState(null);
  const [SlectedRefMatiere, SetSlectedRefMatiere] = useState(null);

  const [ProductionVisitorV2] = useCollection(
    collection(getFirestore(app), "ProductionVisitorV2"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const Data = await getAllMatiereOfAnIngredientWithFormatedData(Selected);
      setDataDisplay(Data);
    }
    fetchOrders();
  }, [ProductionVisitorV2, Selected]);

  useEffect(() => {
    SetSlectedRefMatiere(null);
  }, [Selected]);

  console.log(SlectedRefMatiere);

  return (
    <div className="">
      {DataDisplay &&
        DataDisplay.map((refMatiere) => (
          <div
            className={
              Selected.IdREFfournisseurParDefault === refMatiere.IDPepers
                ? "RowRefIngredientMatiere"
                : "RowRefMatiere" &&
                  SlectedRefMatiere &&
                  SlectedRefMatiere.IDPepers === refMatiere.IDPepers
                ? "RowRefMatiereSelected"
                : "RowRefMatiere"
            }
            onClick={() => SetSlectedRefMatiere(refMatiere)}
          >
            {refMatiere.NomChezFournisseur}
          </div>
        ))}
      {SlectedRefMatiere && (
        <div className="DetailRefMatiere">
          <h1>Nom Chez Fournisseur : {SlectedRefMatiere.NomChezFournisseur}</h1>
          <div>PRIX HT Du conditionnement: {SlectedRefMatiere.PrixHT}</div>
          <div>PRIX par U de recette : {SlectedRefMatiere.PrixURecetteHT}</div>
          <div>Conditionnement : {SlectedRefMatiere.Conditionnement}</div>
          <div>Fournisseur : {SlectedRefMatiere.FournisseurData.name}</div>
          <div>ID : {SlectedRefMatiere.IDPepers}</div>
        </div>
      )}
      <ChangeDefaultRefMatiereForIngredient
        IngredientObject={Selected}
        RefMatiereObject={SlectedRefMatiere}
      />
    </div>
  );
};

export default DisplayMatiereForAnIngredient;
