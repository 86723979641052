import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CircularProgress from "@mui/material/CircularProgress";

import { getDailyAnalysePrepRefait } from "../../../../../../../Data/firebaseInteractionOwnerV2";

const PrepRefait = ({ RestaurantName, dateStart, dateEnd }) => {
  const [PrepRefait, setPrepRefait] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const DailyProduitVendu = await getDailyAnalysePrepRefait(
        RestaurantName,
        dateStart,
        dateEnd
      );
      setPrepRefait(DailyProduitVendu);
    }
    fetchData();
  }, []);

  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom Prep</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Qte refait</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {PrepRefait ? (
              PrepRefait.map((PrepRefait) => {
                const today = new Date(PrepRefait.dateRefait.seconds * 1000);
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, "0");
                const day = String(today.getDate()).padStart(2, "0");
                const FormatedDate = `${day}/${month}/${year} - ${today.getHours()} : ${today.getMinutes()}`;

                return (
                  <TableRow
                    key={PrepRefait.PrepData.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {PrepRefait.PrepData.name}
                    </TableCell>

                    <TableCell align="left">{FormatedDate}</TableCell>
                    <TableCell align="left">
                      {" "}
                      <Avatar
                        alt={PrepRefait.PrepData.name}
                        src={PrepRefait.PrepData.img}
                      />
                    </TableCell>
                    <TableCell align="left">{PrepRefait.qteRefait}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                key={1}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <CircularProgress size={24} color="secondary" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PrepRefait;
