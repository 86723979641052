import React, { useState } from "react";
import DisplayFicheTechinique from "../../components/DisplayFicheTechinique/DisplayFicheTechinique";
import SideBarWorkplace from "../../components/Sidebar/SideBarWorkplace/SideBarWorkplace";
const FicheTechinqueView = ({ Workplace }) => {
  return (
    <>
      <DisplayFicheTechinique Workplace={Workplace} />
    </>
  );
};

export default FicheTechinqueView;
