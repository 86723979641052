import { atom } from "recoil";

const defaultCreationProduct = {
  productSelected: '🦆🎉 🍾 HAPPY DUCK "FOIE GRAS" BURGER',
};

export const CreationProductAtom = atom({
  key: "CreationProductAtom",
  default: defaultCreationProduct,
});
