import React, { useState, useEffect } from "react";
import "./DisplayPrepWhereIngredientIsUsed.css";
import ModalDisplayData from "../../../VisiteurComponents/Modal/ModalDisplayData/ModalDisplayData";
import { getPrepWhereIngredientIsUsed } from "../../../../Data/firebaseInteractionOwnerV2";
import { Avatar } from "@mui/material";

const DisplayPrepWhereIngredientIsUsed = ({ PrepID }) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataModalaForm, setDataModal] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    async function fetchData() {
      const DataModal = await getPrepWhereIngredientIsUsed(PrepID);
      setDataModal(DataModal);
    }
    fetchData();
  }, []);

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        Voir
      </div>
      <ModalDisplayData
        open={OpenModal}
        handleClose={handleCloseModal}
        DataToDisplay={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            {DataModalaForm &&
              DataModalaForm.map((Prep) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <p>{Prep.name}</p>
                    <p>{Prep.Type}</p>
                    <Avatar src={Prep.img} />
                  </div>
                );
              })}
          </div>
        }
        TitleModal={"Preparation où l'ingrédient est utilisé"}
      />
    </div>
  );
};

export default DisplayPrepWhereIngredientIsUsed;
