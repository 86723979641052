import React, { useEffect } from "react";
import "./DisplayMonMenu.css";

const DisplayMonMenu = ({ setSelectedDish, SelectedDish }) => {
  return (
    <div className="MonMenuContener">
      <div>Mon menu</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {SelectedDish &&
          SelectedDish.map((dish) => (
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                border: "1px solid black",
                borderRadius: "5px",
                margin: "5px",
              }}
            >
              {dish.Production.name}{" "}
              <span
                className="Red"
                onClick={() =>
                  setSelectedDish((prev) => {
                    return prev.filter(
                      (Prevdish) =>
                        Prevdish.Production.IDPepers !==
                        dish.Production.IDPepers
                    );
                  })
                }
              >
                X
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DisplayMonMenu;
