import React, { useEffect, useState } from "react";
import {
  GetConssomationMatiereEntreDeuxInventaires,
  getAllInventaireDataBetween2Date,
} from "../../../../Data/firebaseService";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import "./MatiereConssomeEntreDeuxInventaire.css";

// Ce compossant prends en props un tableau avec deux objets date, une date de début et une date de fin
const MatiereConssomeEntreDeuxInventaire = ({ startDate, endDate }) => {
  const [allInventaire, setAllInventaire] = useState(null);
  const [InventaireSelectedStart, setInventaireSelectedStart] = useState("");
  const [InventaireSelectedEnd, setInventaireSelectedEnd] = useState("");
  const [DataConssomationMatiere, setDataConssomationMatiere] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const DataInventaire = await getAllInventaireDataBetween2Date(
        startDate,
        endDate
      );
      const Data = await GetConssomationMatiereEntreDeuxInventaires(
        InventaireSelectedStart,
        InventaireSelectedEnd
      );

      setAllInventaire(DataInventaire);
      setDataConssomationMatiere(Data);
    };
    fetchData();
  }, [InventaireSelectedStart, InventaireSelectedEnd, startDate, endDate]);

  console.log(DataConssomationMatiere);

  return (
    <div>
      <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
        <InputLabel id="select-unite-Preparation">Inventaire</InputLabel>
        <Select
          labelId="select-StratInventaire"
          id="select-StratInventaire"
          value={InventaireSelectedStart}
          onChange={(e) => setInventaireSelectedStart(e.target.value)}
        >
          {allInventaire != null &&
            allInventaire.map((Inventaire) => (
              <MenuItem
                key={Inventaire.IDPepersInventaire}
                value={Inventaire.IDPepersInventaire}
              >
                {new Date(Inventaire.date.seconds * 1000).toString()}
              </MenuItem>
            ))}
        </Select>
        <Select
          labelId="select-EndInventaire"
          id="select-EndInventaire"
          value={InventaireSelectedEnd}
          onChange={(e) => setInventaireSelectedEnd(e.target.value)}
        >
          {allInventaire != null &&
            allInventaire.map((Inventaire) => (
              <MenuItem
                key={Inventaire.IDPepersInventaire}
                value={Inventaire.IDPepersInventaire}
              >
                {new Date(Inventaire.date.seconds * 1000).toString()}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <div className="ConsoMatiere">
        {DataConssomationMatiere &&
          DataConssomationMatiere.QteMatiereConssomee.map((Data) => {
            return (
              Data.QteConssomee > 0 && (
                <div>
                  {Data.matiereName}
                  <span className="Red">{Data.QteConssomee}</span> / Conso
                  Theorique :
                  <span className="Green">
                    {
                      DataConssomationMatiere.ConssomationTheoriqueMatiere[
                        Data.matiereName
                      ]
                    }
                  </span>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};
export default MatiereConssomeEntreDeuxInventaire;
