import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableCreationProduct.css";

import {
  getPreparationFromProduct,
  getListeMatireForPreparation,
} from "../../../Data/firebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import ModalPreparationSelection from "../../../components/Modal/ModalPreparationSelection/ModalPreparationSelection";

import { useRecoilState } from "recoil";
import { CreationProductAtom } from "../../../atoms/CreationProductAtom";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import { SupprimerProductPreparationQte } from "../../../Functions/HandleClickFunctions";

const makeStyle = (status) => {
  if (status === "Approved") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (status === "Cancel") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function TableCreationProduct() {
  const [user, loading, error] = useAuthState(auth);
  const [ProductPreparation, setProductPreparation] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPreparation, setSelectedPreparation] = useState(null);
  const [CreationProduct, setCreationProduct] =
    useRecoilState(CreationProductAtom);

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchOrders() {
      const ProductPrepaparation = await getPreparationFromProduct(
        CreationProduct.productSelected
      );
      setProductPreparation(ProductPrepaparation);
      //const ListeMatireForPreparation = await getListeMatireForPreparation();
    }
    fetchOrders();
  }, [CreationProduct, Production]);

  const handleOpen = (Preparation) => {
    setSelectedPreparation(Preparation);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPreparation(null);
    setOpen(false);
  };

  return (
    <>
      <h3>PRODUIT SELECTIONE : {CreationProduct.productSelected}</h3>
      <div className="Table">
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Preparation</TableCell>
                <TableCell align="left">Unité de mesure</TableCell>
                <TableCell align="left">Qte</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {ProductPreparation != null &&
                ProductPreparation.map((preparation) => (
                  <TableRow
                    key={preparation.Nom}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {preparation.Nom}
                    </TableCell>
                    <TableCell align="left">{preparation.Unite}</TableCell>
                    <TableCell align="left">{preparation.Qte}</TableCell>
                    <TableCell align="left">
                      {" "}
                      <Avatar
                        alt={preparation.Nom}
                        src={preparation.PhotoFirebase}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => handleOpen(preparation)}
                      align="left"
                      className="Details"
                    >
                      Modifier
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        SupprimerProductPreparationQte(
                          preparation.Nom,
                          CreationProduct.productSelected
                        )
                      }
                      align="left"
                      className="Details"
                    >
                      Supprimer
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedPreparation && (
          <ModalPreparationSelection
            open={open}
            handleClose={handleClose}
            preparation={selectedPreparation}
            product={CreationProduct.productSelected}
          />
        )}
      </div>
    </>
  );
}
