import React, { useState, useEffect } from "react";
import "./PasserUneCommandeMatiere.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import {
  HandleOrderIngredient,
  getActiveIngredientWithMatiereRef,
} from "../../../../Data/firebaseInteractionOwnerV2";
import SearchBarGenerique from "../../../OwnerV2Components/SearchBarGenerique/SearchBarGenerique";
import SearchbarWithGivenData from "../../../OwnerV2Components/SearchbarWithGivenData/SearchbarWithGivenData";
import Avatar from "@mui/material/Avatar";
import { TextField, Button } from "@material-ui/core";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

const PasserUneCommandeMatiere = ({ RestaurantName }) => {
  const [OpenPasserCommandeModal, setOpenPasserCommandeModal] = useState(false);
  const [DataForm, setDataForm] = useState({ RestaurantName });
  const [ActiveIngredient, setActiveIngredient] = useState(null);
  const [SearchBarIngredient, setSearchBarIngredient] = useState(null);

  const [RestaurantSainteFoyCommandeMatiere] = useCollection(
    collection(getFirestore(app), "RestaurantSainteFoyCommandeMatiere"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const handleOpenPasserCommandeModal = () => {
    setDataForm((prevdata) => ({ ...prevdata }));
    setOpenPasserCommandeModal(true);
  };

  const handleClosePasserCommandeModal = () => {
    setOpenPasserCommandeModal(false);
  };
  async function fetchOrders() {
    const ActiveIngredient = await getActiveIngredientWithMatiereRef(
      RestaurantName
    );
    setActiveIngredient(ActiveIngredient);
    setDataForm((prevdata) => {
      let qteIngredient = {};
      ActiveIngredient.forEach((ingredient) => {
        qteIngredient[ingredient.IDPepers] = 0;
      });

      return {
        ...prevdata,
        QteIngredientOrder: { ...qteIngredient },
      };
    });
  }

  useEffect(() => {
    fetchOrders();
  }, [RestaurantSainteFoyCommandeMatiere]);

  if (SearchBarIngredient && SearchBarIngredient.length === 0) {
    if (ActiveIngredient) {
      setSearchBarIngredient(ActiveIngredient);
    }
  }

  return (
    <div>
      <div
        className="ButtonAddMatiere"
        onClick={() => handleOpenPasserCommandeModal()}
      >
        Passer Une Commande
      </div>
      {OpenPasserCommandeModal && (
        <ModalAvecFunction
          open={OpenPasserCommandeModal}
          handleClose={handleClosePasserCommandeModal}
          Form={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "500px",
              }}
            >
              {/*<SearchBarGenerique
                UseEffectFunction={getActiveIngredientWithMatiereRef}
                ParamettreFunction={[RestaurantName]}
                setResult={setActiveIngredient}
                Propriete={"name"}
                TextToSearch={"Search"}
            />*/}
              {ActiveIngredient && (
                <SearchbarWithGivenData
                  data={ActiveIngredient}
                  setResult={setSearchBarIngredient}
                  Propriete={"name"}
                  TextToSearch={"Search"}
                />
              )}
              <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                {SearchBarIngredient &&
                  SearchBarIngredient.map((ActifIngredient) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div>{ActifIngredient.name}</div>
                      <Avatar
                        style={{ marginLeft: "15px", marginRight: "15px" }}
                        alt={ActifIngredient.name}
                        src={ActifIngredient.Img}
                      />
                      <TextField
                        onChange={(event) =>
                          setDataForm((prevData) => ({
                            ...prevData,
                            QteIngredientOrder: {
                              ...prevData.QteIngredientOrder,
                              [ActifIngredient.IDPepers]: event.target.value,
                            },
                          }))
                        }
                        style={{ maxWidth: "50px" }}
                        label="Qte"
                        value={
                          DataForm.hasOwnProperty("QteIngredientOrder") &&
                          DataForm.QteIngredientOrder[ActifIngredient.IDPepers]
                        }
                      />
                      {ActifIngredient.MatiereRefDefault.Conditionnement}
                    </div>
                  ))}
              </div>
            </div>
          }
          TextActionButton={"Commander"}
          TitleModal={"Passer une commande"}
          DataForm={{ ...DataForm }}
          Function={HandleOrderIngredient}
          setDataForm={setDataForm}
        />
      )}
    </div>
  );
};

export default PasserUneCommandeMatiere;
