import React from "react";
import { SidebarDataOwner } from "../../Data/DataUserModeOwner";
import Sidebar from "../../components/Sidebar/Sidebar";
import CreationProduct from "../../View/Owner/CreationProduct";
import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";
import FichesTechinique from "../../View/Owner/FichesTechinique";
import KitchenSettings from "../../View/Owner/KitchenSettings";
import Logistique from "../../View/Owner/Logistique";
import Preparation from "../../View/Owner/Preparation";
import DataKitchen from "../../View/Owner/DataKitchen";
import MatierePremiere from "../../View/Owner/MatierePremiere";
import UserData from "../../View/Owner/UserData";
import AnalyseStockRestaurant from "../../View/Owner/AnalyseStockRestaurant";

const Owner = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <>
      <Sidebar data={SidebarDataOwner} />
      {SideBarAtom.view === 0 && <CreationProduct />}
      {SideBarAtom.view === 1 && <FichesTechinique />}
      {SideBarAtom.view === 2 && <KitchenSettings />}
      {SideBarAtom.view === 3 && <UserData />}
      {SideBarAtom.view === 4 && <AnalyseStockRestaurant />}
      {SideBarAtom.view === 5 && <Preparation />}
      {SideBarAtom.view === 6 && <DataKitchen />}
      {SideBarAtom.view === 7 && <MatierePremiere />}
    </>
  );
};

export default Owner;
