import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import "./ModalAddNewProduct.css";
import { AddNewProduct } from "../../../Functions/HandleClickFunctions";
import UploadImage from "../../../components/UploadImage/UploadImage";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModaladdNewCategorie from "../../Modal/ModaladdNewCategorie/ModaladdNewCategorie";

import { GetProductCategories } from "../../../Data/firebaseService";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function ModalAddNewProduct({ open, handleClose }) {
  const [NomProduit, setNomProduit] = useState("");
  const [img, setImag] = useState(null);
  const [openModaladdCategorie, setOpenModaladdCategorie] = useState(false);
  const [ListProductCategories, setListProductCategories] = useState(null);
  const [ProductCategories, setProductCategories] = useState(null);

  const handleCloseModaladdCategorie = () => {
    setOpenModaladdCategorie(false);
  };

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      let ProductCategories = await GetProductCategories();
      setListProductCategories(ProductCategories);
    };
    fetchData();
  }, [Production]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          Nom du produit :{" "}
          <TextField
            label="Input"
            variant="outlined"
            value={NomProduit}
            onChange={(e) => setNomProduit(e.target.value)}
          />
        </Typography>
        Ajouter une categorie de produit
        <AiOutlinePlusCircle
          onClick={() => setOpenModaladdCategorie(true)}
          className="Plus-Add-Product"
        />
        <Typography id="modal-Select-Unite-Preparation">
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">
              Etat Preparation
            </InputLabel>
            <Select
              labelId="select-unite-label-Type-Preparation"
              id="select-unite-Type-Preparation"
              value={ProductCategories}
              onChange={(e) => setProductCategories(e.target.value)}
            >
              {ListProductCategories &&
                ListProductCategories.map((categorie) => {
                  return (
                    <MenuItem value={categorie.NomCategorie}>
                      {categorie.NomCategorie}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Typography>
        <Typography id="uploadImage-AddProduct" sx={{ mt: 2 }}>
          <UploadImage setImag={setImag} />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              AddNewProduct(NomProduit, img, ProductCategories);
              handleClose();
            }}
          >
            Creer produit
          </Button>
        </Typography>
        <ModaladdNewCategorie
          open={openModaladdCategorie}
          handleClose={handleCloseModaladdCategorie}
        />
      </Box>
    </Modal>
  );
}
