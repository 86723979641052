import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchBarPreparationSetListePreparations from "../../SearchBar/SearchBarPreparationSetListePreparations/SearchBarPreparationSetListePreparations";
import { UpdateDish } from "../../../Functions/HandleClickFunctions";
import {
  getMachineDisponible,
  getProductcategorie,
  getAllPackaging,
} from "../../../Data/firebaseService";
import ButtonAddNewPackaging from "../../Button/ButtonAddNewPackaging/ButtonAddNewPackaging";

export default function ModalModificationDish({ open, handleClose, dish }) {
  const [AllDataDish, setAllDataDish] = useState(null);
  const [ListeAllMachine, setListeAllMachine] = useState([]);
  const [ListeAllPackaging, setListeAllPackaging] = useState([]);
  const [ListeAllProductcategorie, setListeAllProductcategorie] = useState([]);
  const [ListePreparation, setListePreparation] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      setAllDataDish(dish);
      const MachineDisponible = await getMachineDisponible();
      setListeAllMachine(MachineDisponible);
      const Productcategorie = await getProductcategorie();
      setListeAllProductcategorie(Productcategorie);
      const listePrepOfTheDish = dish.Production.PreparationIntermediaire.map(
        (prep) => {
          return { DataPreparation: { Nom: prep.Name }, Qte: prep.Qte };
        }
      );
      setListePreparation(listePrepOfTheDish);
      const PackaingDisponible = await getAllPackaging();
      setListeAllPackaging(PackaingDisponible);
    }

    fetchOrders();
  }, []);

  const handleChangeMachine = (e) => {
    if ("Machine" in AllDataDish.Production) {
      setAllDataDish((oldData) => ({
        ...oldData,
        Production: {
          ...oldData.Production,
          Machine: [...oldData.Production.Machine, e.target.value],
        },
      }));
    } else {
      setAllDataDish((oldData) => ({
        ...oldData,
        Production: {
          ...oldData.Production,
          Machine: [e.target.value],
        },
      }));
    }
  };

  const handleChangeCategorie = (e) => {
    setAllDataDish((oldData) => ({
      ...oldData,
      Production: {
        ...oldData.Production,
        ProductCategories: e.target.value,
      },
    }));
  };

  const HandleSupPrepInProduct = (index) => {
    setListePreparation((prevPreparation) => [
      ...prevPreparation.slice(0, index),
      ...prevPreparation.slice(index + 1),
    ]);
  };

  const HandleChangeQtePrep = (e, index) => {
    setListePreparation((prevPreparation) => [
      ...prevPreparation.slice(0, index),
      {
        DataPreparation: { ...prevPreparation[index].DataPreparation },
        Qte: e.target.value,
      },
      ...prevPreparation.slice(index + 1),
    ]);
  };
  const handleChangeAddPackaging = (e) => {
    setAllDataDish((oldData) => {
      return AllDataDish.Production.hasOwnProperty("Packaging")
        ? {
            ...oldData,
            Production: {
              ...oldData.Production,
              Packaging: [...oldData.Production.Packaging, e.target.value],
            },
          }
        : {
            ...oldData,
            Production: {
              ...oldData.Production,
              Packaging: [e.target.value],
            },
          };
    });
  };
  const handleChangeAddStateDish = (e) => {
    setAllDataDish((oldData) => {
      return {
        ...oldData,
        Production: {
          ...oldData.Production,
          StateProduct: e.target.value,
        },
      };
    });
  };

  const handleChangeDescriptionDish = (e) => {
    setAllDataDish((oldData) => {
      return {
        ...oldData,
        Production: {
          ...oldData.Production,
          Description: e.target.value,
        },
      };
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="container">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%", // Ajustez cette valeur selon vos besoins
            maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="box">
            <img src={dish.Production.img} style={{ width: "300px" }}></img>
          </div>
          {AllDataDish !== null && (
            <TextField
              label="Nom du produit"
              variant="outlined"
              value={AllDataDish.Production.name}
              onChange={(e) =>
                setAllDataDish((oldData) => ({
                  ...oldData,
                  Production: { ...oldData.Production, name: e.target.value },
                }))
              }
            />
          )}
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          ></Typography>
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">
              Ajouter Machine
            </InputLabel>
            <Select
              labelId="select-unite-label-Type-Preparation"
              id="select-unite-Type-Preparation"
              value=""
              onChange={(e) => handleChangeMachine(e)}
            >
              {ListeAllMachine.map((machine) => {
                return <MenuItem value={machine}>{machine}</MenuItem>;
              })}
            </Select>
          </FormControl>
          {AllDataDish !== null && (
            <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
              <InputLabel id="Categorie-produit">Categorie produit</InputLabel>

              <Select
                labelId="select-Product-Categories"
                id="select-unite-Product-Categories"
                value={AllDataDish.Production.ProductCategories}
                onChange={(e) => handleChangeCategorie(e)}
              >
                {ListeAllProductcategorie.map((Categorie) => {
                  return (
                    <MenuItem value={Categorie.NomCategorie}>
                      {Categorie.NomCategorie}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {AllDataDish &&
            AllDataDish.Production.hasOwnProperty("Machine") &&
            AllDataDish.Production.Machine.map((machinneSelected) => (
              <div>{machinneSelected}</div>
            ))}
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">
              Ajouter Packaging
            </InputLabel>
            <Select
              labelId="select-Packaging"
              id="select-Packaging"
              value=""
              onChange={(e) => handleChangeAddPackaging(e)}
            >
              {ListeAllPackaging.map((Packaging) => {
                return (
                  <MenuItem value={Packaging}>
                    {Packaging.NomFournisseur}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <ButtonAddNewPackaging />
          {AllDataDish &&
            AllDataDish.Production.hasOwnProperty("Packaging") &&
            AllDataDish.Production.Packaging.map((PackagingSelected) => (
              <div>{PackagingSelected.NomFournisseur}</div>
            ))}
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">Etat Produit</InputLabel>
            <Select
              labelId="Change-state-Product"
              id="Change-state-Product"
              value=""
              onChange={(e) => handleChangeAddStateDish(e)}
            >
              <MenuItem value={"Actif"}>Actif</MenuItem>
              <MenuItem value={"Desactive"}>Desactive</MenuItem>
            </Select>
          </FormControl>
          {AllDataDish &&
            AllDataDish.Production.hasOwnProperty("StateProduct") && (
              <div>{AllDataDish.Production.StateProduct}</div>
            )}
          <TextField
            multiline
            rows={4} // Vous pouvez ajuster le nombre de lignes selon vos préférences
            variant="outlined"
            fullWidth
            placeholder="Saisissez votre description ici..."
            value={
              AllDataDish &&
              AllDataDish.Production.hasOwnProperty("Description")
                ? AllDataDish.Production.Description
                : ""
            }
            onChange={(e) => handleChangeDescriptionDish(e)}
          />
          ---------- PREPARATION INTERMEDIAIRE ----------
          <SearchBarPreparationSetListePreparations
            setListePreparation={setListePreparation}
          />
          {AllDataDish &&
            ListePreparation.map((Prep, index) => (
              <div>
                {Prep.DataPreparation.Nom}{" "}
                <TextField
                  style={{ width: "60px" }}
                  label="Qte"
                  variant="outlined"
                  value={Prep.Qte}
                  onChange={(e) => HandleChangeQtePrep(e, index)}
                />
                {Prep.DataPreparation.hasOwnProperty("Unite") &&
                  Prep.DataPreparation.Unite}
                <span onClick={() => HandleSupPrepInProduct(index)}>
                  {" "}
                  --Supprimer
                </span>
              </div>
            ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              UpdateDish(AllDataDish, ListePreparation);
              handleClose();
            }}
          >
            Ajouter
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
