import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
  Textarea,
} from "@mui/material";
import "./ModalAddNewPreparation.css";
import { AddNewPreparation } from "../../../Functions/HandleClickFunctions";
import UploadImage from "../../../components/UploadImage/UploadImage";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import SearchBarMatierePremiere from "../../SearchBar/SearchBarMatierePremiere/SearchBarMatierePremiere";

export default function ModalAddNewPreparation({ open, handleClose }) {
  const [NomPreparation, setNomPreparation] = useState("");
  const [ListeMatierePremiere, setListeMatierePremiere] = useState([]);
  const [TypePreparation, setTypePreparation] = useState("");
  const [UniteMesurePreparation, setUniteMesurePreparation] = useState("unit");
  const [QteTotalPrep, setQteTotalPrep] = useState(0);
  const [DLCsecondaire, setDLCsecondaire] = useState(0);
  const [img, setImag] = useState(null);
  const [explicationCookPrep, setExplicationCookPrep] = useState("");

  const handleChangeTypePreparation = (event) => {
    setTypePreparation(event.target.value);
  };
  const handleChangeUniteMesurePreparation = (event) => {
    setUniteMesurePreparation(event.target.value);
  };

  const handleSupMatiere = (index) => {
    setListeMatierePremiere((previousList) => [
      ...previousList.slice(0, index),
      ...previousList.slice(index + 1),
    ]);
  };
  const AddQteToMatierePremiere = (e, index) => {
    setListeMatierePremiere((previousPrem) => [
      ...previousPrem.slice(0, index),
      {
        ...previousPrem[index],
        DataMatierePremiere: previousPrem[index].DataMatierePremiere,
        Qte: e.target.value,
      },
      ...previousPrem.slice(index + 1),
    ]);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ fontSize: "35px" }}>Ajouter une nouvelle preparation</div>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom de la preparation"
            variant="outlined"
            value={NomPreparation}
            onChange={(e) => setNomPreparation(e.target.value)}
          />
        </Typography>
        <Typography id="modal-Select-Type-Preparation">
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-label-Type-Preparation">
              Sélectionnez un type
            </InputLabel>
            <Select
              labelId="select-label-Type-Preparation"
              id="select-input-Type-Preparation"
              value={TypePreparation}
              onChange={handleChangeTypePreparation}
            >
              <MenuItem value="Preparation">Preparation</MenuItem>
              <MenuItem value="Mise a disposition">Mise a disposition</MenuItem>
              <MenuItem value="Decoupe formatage">Découpe formatage</MenuItem>
            </Select>
          </FormControl>
        </Typography>
        <SearchBarMatierePremiere
          setListeMatierePremiere={setListeMatierePremiere}
        />
        <Typography
          id="modal-liste-matiere-premiere"
          variant="h6"
          component="h6"
        >
          {ListeMatierePremiere.length != 0 &&
            ListeMatierePremiere.map((matiere, index) => {
              return (
                <div style={{ fontSize: "12px" }}>
                  {matiere.DataMatierePremiere.Nom}
                  <TextField
                    style={{ width: "60px" }}
                    label="Qte"
                    variant="outlined"
                    value={matiere.Qte}
                    onChange={(e) => AddQteToMatierePremiere(e, index)}
                  />
                  {matiere.DataMatierePremiere["Unité de recettes"]}
                  <span onClick={() => handleSupMatiere(index)}>
                    {" "}
                    Supprimer{" "}
                  </span>
                </div>
              );
            })}
        </Typography>
        <TextField
          multiline
          rows={4} // Vous pouvez ajuster le nombre de lignes selon vos préférences
          variant="outlined"
          fullWidth
          placeholder="Saisissez votre texte ici..."
          value={explicationCookPrep}
          onChange={(e) => setExplicationCookPrep(e.target.value)}
        />

        <Typography id="modal-Select-Unite-Preparation">
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">Unitée</InputLabel>
            <Select
              labelId="select-unite-label-Type-Preparation"
              id="select-unite-Type-Preparation"
              value={UniteMesurePreparation}
              onChange={handleChangeUniteMesurePreparation}
            >
              <MenuItem value="unit">unit</MenuItem>
              <MenuItem value="g">g</MenuItem>
            </Select>
          </FormControl>
        </Typography>

        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Qte total de preparation"
            variant="outlined"
            value={QteTotalPrep}
            onChange={(e) => setQteTotalPrep(e.target.value)}
          />
        </Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
          sx={{ mt: 2 }}
        >
          <TextField
            label="DLC Secondaire"
            variant="outlined"
            value={DLCsecondaire}
            onChange={(e) => setDLCsecondaire(e.target.value)}
          />
        </Typography>
        <Typography id="uploadImage-AddProduct" sx={{ mt: 2 }}>
          <UploadImage setImag={setImag} />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              AddNewPreparation(
                NomPreparation,
                TypePreparation,
                ListeMatierePremiere,
                img,
                UniteMesurePreparation,
                QteTotalPrep,
                DLCsecondaire,
                explicationCookPrep
              );
              handleClose();
            }}
          >
            Creer une preparation
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}
