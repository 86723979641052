import React, { useState, useEffect } from "react";
import "./SelectPrepRefaitForPrepID.css";
import {
  GetPrepRefaitForPrepID,
  SupprimerPrepRefait,
  AllPrepIsRefait,
} from "../../../../../../../../Data/firebaseInteractionOwnerV2";

const SelectPrepRefaitForPrepID = ({
  RestaurantName,
  PrepID,
  SelectedRefaitPrep,
  setSelectedRefaitPrep,
}) => {
  const [GestionPreparation, setGestionPreparation] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const Preparation = await GetPrepRefaitForPrepID(PrepID, RestaurantName);
      setGestionPreparation(Preparation);
      await AllPrepIsRefait();
    }

    fetchData();
  }, [RestaurantName, PrepID]);

  return (
    <div className="ContenerSelectPrepRefaitForPrepID">
      {GestionPreparation &&
        GestionPreparation.map((Refait) => {
          console.log(Refait);
          const RefaitDate = new Date(Refait.date.seconds * 1000);
          const year = RefaitDate.getFullYear();
          const month = String(RefaitDate.getMonth() + 1).padStart(2, "0");
          const day = String(RefaitDate.getDate()).padStart(2, "0");
          const FormatedDate = `${day}/${month}/${year} - ${RefaitDate.getHours()} : ${RefaitDate.getMinutes()}`;

          return (
            <div
              className={
                SelectedRefaitPrep &&
                SelectedRefaitPrep.IDPepers === Refait.IDPepers
                  ? "RowSelectPrepRefaitSelected"
                  : "RowSelectPrepRefait"
              }
              onClick={() => setSelectedRefaitPrep(Refait)}
            >
              {FormatedDate}{" "}
              <span
                className="Red"
                onClick={() =>
                  SupprimerPrepRefait(
                    Refait.IDPrepRefait,
                    Refait.IDPepers,
                    RestaurantName
                  )
                }
              >
                Supprimer
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default SelectPrepRefaitForPrepID;
