import React from "react";
import { SidebarDataVisiteur } from "../../Data/DataVisiteur";
import Sidebar from "../../components/Sidebar/Sidebar";
import ProductSimulation from "../../View/ClientPepers/ProductSimulation";
import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";
import VisiteurV2PageNoRightSideBar from "../../View/Visiteur/VisiteurV2PageNoRightSideBar";

const Visiteur = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <>
      {/*<Sidebar data={SidebarDataVisiteur} />*/}
      {/*SideBarAtom.view === 0 && <ProductSimulation />*/}
      {SideBarAtom.view === 0 && <VisiteurV2PageNoRightSideBar />}
    </>
  );
};

export default Visiteur;
