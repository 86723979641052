import React, { useEffect, useState } from "react";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import "./MainDashMarketingPage.css";

const MainDashMarketingPage = () => {
  const [SelectedDish, setSelectedDish] = useState([]);
  const [StepCreationMenu, setStepCreationMenu] = useState(true);
  return (
    <>
      {StepCreationMenu && (
        <Step1
          SelectedDish={SelectedDish}
          setSelectedDish={setSelectedDish}
          setStepCreationMenu={setStepCreationMenu}
        />
      )}
      {!StepCreationMenu && <Step2 />}
    </>
  );
};

export default MainDashMarketingPage;
