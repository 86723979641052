import React, { useState } from "react";
import "./DisplayActivePrepAndIngredientInRestaurant.css";
import ActivePrep from "./ActivePrep/ActivePrep";
import ActiveIngredient from "./ActiveIngredient/ActiveIngredient";

const DisplayActivePrepAndIngredientInRestaurant = ({ RestaurantName }) => {
  return (
    <div className="ContenerActivePrepAndIngredientInRestaurant">
      <ActivePrep RestaurantName={RestaurantName} />
      <ActiveIngredient RestaurantName={RestaurantName} />
    </div>
  );
};

export default DisplayActivePrepAndIngredientInRestaurant;
