import React, { useState, useEffect } from "react";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

import ModalAvecFunction from "../../../../components/VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField } from "@material-ui/core";

import {
  getDishDataWithId,
  HandleModifierDish,
} from "../../../../Data/firebaseInteractionOwnerV2";

import UploadImage from "../../../../components/UploadImage/UploadImage";

const ModifictaionDish = ({ IDDish }) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    IDDish: IDDish,
  });
  const [DishData, setDishData] = useState(null);
  const [img, setImag] = useState(null);

  const [dishes] = useCollection(collection(getFirestore(app), "dishes"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      const Dish = await getDishDataWithId(IDDish);
      setDataForm((prevData) => ({
        ...prevData,
        IDDish: IDDish,
        ...Dish,
      }));
      setDishData(Dish);
    }
    fetchOrders();
  }, [dishes, IDDish]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        Modifier produit
      </div>
      <ModalAvecFunction
        open={OpenModal}
        handleClose={handleCloseModal}
        Form={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            {DishData && (
              <div>
                <TextField
                  onChange={(event) =>
                    setDataForm((prevData) => ({
                      ...prevData,
                      Production: {
                        ...prevData.Production,
                        name: event.target.value,
                      },
                    }))
                  }
                  style={{ maxWidth: "250px" }}
                  label="Nom du plat"
                  value={
                    DataForm.hasOwnProperty("Production") &&
                    DataForm.Production.name
                  }
                />
                <UploadImage setImag={setImag} />
              </div>
            )}
          </div>
        }
        TextActionButton={"Valider"}
        SousParagraphe={`Modification du plat ${IDDish}`}
        TitleModal={`Modification du plat `}
        DataForm={{ ...DataForm, img }}
        Function={HandleModifierDish}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default ModifictaionDish;
