import React, { useState } from "react";
import MainDashCreationNewProductInterface from "../../components/MainDash/MainDashCreationNewProductInterface/MainDashCreationNewProductInterface";

const CreateNewProduct = () => {
  return (
    <>
      <MainDashCreationNewProductInterface />
      <div>Right Side</div>
    </>
  );
};

export default CreateNewProduct;
