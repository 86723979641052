import React, { useEffect, useState } from "react";
import {
  SetAlocateDishToPlanDeTravail,
  SetFinishProductl,
} from "../../../../Data/firebaseInteractionCuisinierV2";

const ProductCard = ({
  productToDo,
  setDisplayProductToDo,
  Workplace,
  RestaurantName,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const HandleAlocateDishToPlanDeTravail = async (productToDo) => {
    if (isProcessing) {
      return;
    }
    setIsProcessing(true);

    setDisplayProductToDo((prevProductToDo) => {
      let newProductToDo = [];
      prevProductToDo.forEach((product) => {
        if (
          product.OrderID === productToDo.OrderID &&
          product.ZeltyID === productToDo.ZeltyID &&
          product.id === productToDo.id
        ) {
          newProductToDo.push({
            ...product,
            isAlreadyAllocatedToWorkPlace: true,
          });
        } else {
          newProductToDo.push({ ...product });
        }
      });
      return newProductToDo;
    });

    await SetAlocateDishToPlanDeTravail(productToDo, Workplace, RestaurantName);

    setIsProcessing(false);
  };

  const [isProcessingFinishProduct, setIsProcessingFinishProduct] =
    useState(false);

  const HandleFinishProduct = async (productToDo) => {
    if (isProcessingFinishProduct) {
      return;
    }

    setIsProcessingFinishProduct(true);

    setDisplayProductToDo((prevProductToDo) => {
      let newProductToDo = [];
      prevProductToDo.forEach((product) => {
        if (
          product.OrderID === productToDo.OrderID &&
          product.ZeltyID === productToDo.ZeltyID &&
          product.id === productToDo.id
        ) {
        } else {
          newProductToDo.push({ ...product });
        }
      });
      return newProductToDo;
    });

    await SetFinishProductl(productToDo, Workplace, RestaurantName);

    setIsProcessingFinishProduct(false);
  };

  return (
    <div style={{ maxHeight: "90vh", overflowY: "auto" }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          key={productToDo.Production.IDPepers}
          style={
            productToDo.isAlreadyAllocatedToWorkPlace === true
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  maxWidth: "150px",
                  padding: "10px",
                  border: "1px solid black",
                  borderRadius: "5px",
                  margin: "10px",
                  backgroundColor: "green",
                }
              : {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  maxWidth: "150px",
                  padding: "10px",
                  border: "1px solid black",
                  borderRadius: "5px",
                  margin: "10px",
                  backgroundColor: "white",
                }
          }
          onClick={() => {
            if (productToDo.isAlreadyAllocatedToWorkPlace === true) {
              HandleFinishProduct(productToDo);
            } else {
              HandleAlocateDishToPlanDeTravail(productToDo);
            }
          }}
        >
          <div>{productToDo.Production.name}</div>
          {productToDo.modifiers.length > 0 &&
            productToDo.modifiers.map((Sup) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    backgroundColor: "red",
                  }}
                >
                  <div>{Sup.name}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
