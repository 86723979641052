import React, { useState, useEffect } from "react";
import "./SwitchProductToOnline.css";
import ModalAvecFunction from "../../../../components/VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField, Button } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { HandleSwitchProductToOnline } from "../../../../Data/firebaseInteractionOwnerV2";
import { getAllDish } from "../../../../Data/firebaseInteractionOwnerV2";

const SwitchProductToOnline = ({ RestaurantName }) => {
  const [openSwitchProductModal, setOpenSwitchProductModal] = useState(false);
  const [AllDish, setAllDish] = useState(null);
  const [DataForm, setDataForm] = useState({});

  useEffect(() => {
    async function fetchOrders() {
      let AllDish = await getAllDish();
      setAllDish(AllDish);
    }
    fetchOrders();
  }, []);

  const handleOpenSwitchProductModal = () => {
    setDataForm((prev) => ({ ...prev, RestaurantName: RestaurantName }));
    setOpenSwitchProductModal(true);
  };

  const handleCloseSwitchProductModal = () => {
    setOpenSwitchProductModal(false);
  };

  const handleAddDishToActive = (IdDish) => {
    setDataForm((prev) => {
      if (prev.hasOwnProperty("SelectedDish")) {
        if (prev.SelectedDish.includes(IdDish)) {
          return { ...prev };
        } else {
          return {
            ...prev,
            SelectedDish: [...prev.SelectedDish, IdDish],
          };
        }
      } else {
        return {
          ...prev,
          SelectedDish: [IdDish],
        };
      }
    });
  };

  return (
    <div>
      <div
        className="ButtonAddMatiere"
        onClick={() => handleOpenSwitchProductModal()}
      >
        ADD Actif Product
      </div>
      <ModalAvecFunction
        open={openSwitchProductModal}
        handleClose={handleCloseSwitchProductModal}
        Form={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="SelectDishContener">
              Dish :
              {AllDish &&
                AllDish.map((dish) => (
                  <div
                    className={
                      DataForm.hasOwnProperty("SelectedDish") &&
                      DataForm.SelectedDish.includes(dish.Production.IDPepers)
                        ? "SelectedDish"
                        : "SelectDish"
                    }
                    onClick={() =>
                      handleAddDishToActive(dish.Production.IDPepers)
                    }
                  >
                    {dish.Production.name}
                  </div>
                ))}
            </div>
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Passer les produits en actif"}
        DataForm={{ ...DataForm }}
        setDataForm={setDataForm}
        Function={HandleSwitchProductToOnline}
      />
    </div>
  );
};

export default SwitchProductToOnline;
