import React, {useState, useEffect} from "react";
import {FaSearch} from 'react-icons/fa'
import {AiOutlinePlusCircle} from 'react-icons/ai'
import {getMatierePremiereSearchBarData} from '../../../Data/firebaseService'
import './SearchBarMatierePremiere.css'
import ModalAddNewMatierePremiere from '../../Modal/ModalAddNewMatierePremiere/ModalAddNewMatierePremiere'

const SearchBarMatierePremiere = ({setListeMatierePremiere}) => {

    const [input, setInput] = useState("")
    const [result, setResult] = useState([])
    const [open, setOpen] = useState(false);


    const fetchData = (value)=>{
        getMatierePremiereSearchBarData().then(json=>{
            const results = json.filter((MatierePremiere)=>{
                return value && MatierePremiere.Nom && MatierePremiere.Nom.toLowerCase().includes(value)
            })
            setResult(results)
        })
    }
    const handleChange = (value)=>{
        setInput(value)
        fetchData(value)
    }

    const SelectionMatierePremiere = (e, MatierePremiere)=>{
        setListeMatierePremiere((previousListMatiere)=>[...previousListMatiere, {DataMatierePremiere: MatierePremiere, Qte:0}])
        setInput("")
        setResult([])
        
    }

    const handleClose = ()=>{
        setOpen(false)
    }

    return (
      <div className="search-bar-container">
        <div className="input-wrapper">
            <FaSearch id = "search-icon"/>
            <input placeholder="Matiere Premiere" value={input} onChange={(e)=> handleChange(e.target.value)}/>
            <AiOutlinePlusCircle onClick={()=>setOpen(true)} className="Plus-Add-Product"/>
        </div>
        <div className="result-list">
            {result.map((MatierePremiere,id)=>{
                return <div className="search-result" key={id} onClick={(e)=>SelectionMatierePremiere(e, MatierePremiere)}>{MatierePremiere.Nom}</div>
            })}
        </div>
        <ModalAddNewMatierePremiere
          open={open}
          handleClose={handleClose}
        />
      </div>
    );
  };
  

export default SearchBarMatierePremiere