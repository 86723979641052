import React, { useState, useEffect } from "react";
import "./GestionCommandesMatiere.css";
import { GetGestionMatiereOrderData } from "../../../../../../Data/firebaseInteractionOwnerV2";
import DisplayAllOrders from "./DisplayAllOrders/DisplayAllOrders";
import MatiereOrderDisplayDetail from "./MatiereOrderDisplayDetail/MatiereOrderDisplayDetail";

const GestionCommandesMatiere = ({ RestaurantName }) => {
  const [MatiereOrder, setMatiereOrder] = useState(null);
  const [SelectedMatiereOrder, setSelectedMatiereOrder] = useState(null);

  return (
    <div className="ContenerGestionCommandesMatiere">
      <DisplayAllOrders
        RestaurantName={RestaurantName}
        SelectedMatiereOrder={SelectedMatiereOrder}
        setSelectedMatiereOrder={setSelectedMatiereOrder}
      />
      {SelectedMatiereOrder && (
        <MatiereOrderDisplayDetail
          IDMatiereOrder={SelectedMatiereOrder.IDPepers}
          RestaurantName={RestaurantName}
        />
      )}
    </div>
  );
};

export default GestionCommandesMatiere;
