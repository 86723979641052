import React, { useState, useEffect } from "react";
import { getProductTestSearchBarData } from "../../../Data/firebaseService";
import { FaSearch } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "./SearchBarTestProduct.css";
import { useRecoilState } from "recoil";
import { CreationTESTProductAtom } from "../../../atoms/CreationTESTProductAtom";
import ModalAddNewTestProduct from "../../Modal/ModalAddNewTestProduct/ModalAddNewTestProduct";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";

const SearchBarTestProduct = () => {
  const [input, setInput] = useState("");
  const [result, setResult] = useState([]);
  const [ProductAtom, setProductAtom] = useRecoilState(CreationTESTProductAtom);
  const [open, setOpen] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const fetchData = (value) => {
    getProductTestSearchBarData(user.uid).then((json) => {
      const results = json.filter((product) => {
        return (
          value &&
          product.Production.name &&
          product.Production.name.toLowerCase().includes(value)
        );
      });
      setResult(results);
    });
  };
  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="search-bar-container">
      <div className="input-wrapper">
        <FaSearch id="search-icon" />
        <input
          placeholder="Recherche produit"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
        <AiOutlinePlusCircle
          onClick={() => setOpen(true)}
          className="Plus-Add-Product"
        />
      </div>
      <div className="result-list">
        {result.map((result, id) => {
          return (
            <div
              className="search-result"
              key={id}
              onClick={(e) => {
                setProductAtom({ productSelected: result.Production.name });
                setInput("");
                setResult([]);
              }}
            >
              {result.Production.name}
            </div>
          );
        })}
      </div>
      <ModalAddNewTestProduct open={open} handleClose={handleClose} />
    </div>
  );
};

export default SearchBarTestProduct;
