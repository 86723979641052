import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../../../../firebaseConfig";

import { getDataPlanDeTravail } from "../../../../../../../Data/firebaseInteractionOwnerV2";
import DisplayPlanDeTravailMachine from "./DisplayPlanDeTravailMachine/DisplayPlanDeTravailMachine";
import DisplayPlanDetravailPrep from "./DisplayPlanDetravailPrep/DisplayPlanDetravailPrep";
import DisplayDishPossibleToDoInPlanDeTravail from "./DisplayDishPossibleToDoInPlanDeTravail/DisplayDishPossibleToDoInPlanDeTravail";

const ModifyPlanDeTravailParametre = ({
  SelectedPlanDeTravailID,
  RestaurantName,
}) => {
  const [PlanDeTravailData, setPlanDeTravailData] = useState(null);

  const [PlanDeTravail] = useCollection(
    collection(getFirestore(app), `${RestaurantName}PlanDeTravail`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      const Data = await getDataPlanDeTravail(
        SelectedPlanDeTravailID,
        RestaurantName
      );
      setPlanDeTravailData(Data);
    };
    fetchData();
  }, [PlanDeTravail, SelectedPlanDeTravailID]);

  useEffect(() => {
    setPlanDeTravailData(null);
  }, [SelectedPlanDeTravailID]);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        height: "100%",
      }}
    >
      {PlanDeTravailData && (
        <DisplayPlanDeTravailMachine
          DataMachine={PlanDeTravailData.ListeMachineData}
          PlanDeTravailID={SelectedPlanDeTravailID}
          RestaurantName={RestaurantName}
        />
      )}
      {PlanDeTravailData && (
        <DisplayPlanDetravailPrep
          DataPrep={PlanDeTravailData.ListePreparationData}
          RestaurantName={RestaurantName}
          PlanDeTravailID={SelectedPlanDeTravailID}
        />
      )}
      {PlanDeTravailData && (
        <DisplayDishPossibleToDoInPlanDeTravail
          RestaurantName={RestaurantName}
          PlanDeTravailID={SelectedPlanDeTravailID}
        />
      )}
    </div>
  );
};

export default ModifyPlanDeTravailParametre;
