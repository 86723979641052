import React, {useState, useEffect} from "react";
import './UploadImage.css'

const UploadImage = ({setImag}) => {

    const onSelectImage = (event)=>{
        const reader = new FileReader();

        if(event.target.files[0]){
            reader.readAsDataURL(event.target.files[0])
        }

        reader.onload = (readerEvent) =>{
            if(readerEvent.target.result){
                setImag(readerEvent.target.result.toString())
                
            }

        }
      }

    return(<>
        <input type="file" onChange={onSelectImage} />
    </>)
}
export default UploadImage