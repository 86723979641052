import React from "react";
import "./CardOrder.css";

const CardOrder = ({
  OrderDate,
  PreparationOrder,
  setPrepSelected,
  setOrderSelected,
}) => {
  return (
    <div className="CardOrder" onClick={() => setOrderSelected(OrderDate)}>
      <div>{OrderDate}</div>

      {PreparationOrder.map((prep) => {
        return (
          <div className="DisplayPrep" onClick={() => setPrepSelected(prep)}>
            <div>{prep.Nom}</div>
            <div>QTE : {prep.Qte}</div>
            <div>{prep.Unite}</div>
            <div>
              <img className="img-Prep" src={prep.PhotoFirebase} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardOrder;
