import React, { useState } from "react";
import "./ModifictaionDishOrPrep.css";
import ModifictaionDish from "../../OwnerV2Components/ModifictaionDishOrPrep/ModifictaionDish/ModifictaionDish";

const ModifictaionDishOrPrep = ({ SelectedDish, SelectedPrep }) => {
  return (
    <div className="ContenerModifictaionDishOrPrep">
      {SelectedDish && <ModifictaionDish SelectedDish={SelectedDish} />}
      {/*SelectedPrep && <ModifictaionPreparation />*/}
    </div>
  );
};

export default ModifictaionDishOrPrep;
