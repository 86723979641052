import React, { useState, useEffect } from "react";
import "./DisplayIngredientDetail.css";
import UpdateIngredientData from "../../Button/UpdateIngredientData/UpdateIngredientData";

const DisplayIngredientDetail = ({ Selected }) => {
  return (
    <div className="">
      {" "}
      <div>
        <h1>DATA INGREDIENT </h1>
        {Selected && (
          <>
            <h2>{Selected.name}</h2>
            <img style={{ maxWidth: "250px" }} src={Selected.Img} alt="" />
            <div>Unite De Recette: {Selected.UniteDeRecette}</div>
          </>
        )}
      </div>
      {Selected && (
        <UpdateIngredientData
          IDIngredient={Selected.IDPepers}
          DefaultData={{
            name: Selected.name,
            UniteDeRecette: Selected.UniteDeRecette,
          }}
        />
      )}
    </div>
  );
};

export default DisplayIngredientDetail;
