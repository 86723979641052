import React, { useEffect, useState } from "react";
import "./RelierDishToZeltyID.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import {
  getAllZeltyDish,
  HandleAssociePeperDishtoZelty,
} from "../../../../Data/firebaseInteractionOwnerV2";
import Avatar from "@mui/material/Avatar";
import { TextField, Button } from "@material-ui/core";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";

const RelierDishToZeltyID = ({ Dish }) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({
    IDDish: Dish.Production.IDPepers,
    ZeltyID: Dish.ZeltyID,
  });
  const [AllZeltyDish, setAllZeltyDish] = useState(null);

  const handleOpenModal = () => {
    setDataForm((prevData) => ({
      ...prevData,
      IDDish: Dish.Production.IDPepers,
      ZeltyID: Dish.ZeltyID,
    }));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [dishes] = useCollection(collection(getFirestore(app), "dishes"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    async function fetchOrders() {
      const ZeltyDish = await getAllZeltyDish();
      setAllZeltyDish(ZeltyDish);
    }
    fetchOrders();
  }, [dishes]);

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        Relier a ZeltyID
      </div>
      <ModalAvecFunction
        open={OpenModal}
        handleClose={handleCloseModal}
        Form={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            {AllZeltyDish &&
              AllZeltyDish.map((ZeltyDish) => (
                <div
                  style={
                    DataForm.hasOwnProperty("ZeltyID") &&
                    DataForm.ZeltyID === ZeltyDish.id
                      ? {
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          backgroundColor: "lightblue",
                        }
                      : {
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }
                  }
                  onClick={() => {
                    setDataForm((prev) => ({ ...prev, ZeltyID: ZeltyDish.id }));
                  }}
                >
                  {ZeltyDish.name}
                  <Avatar
                    style={{ marginLeft: "15px", marginRight: "15px" }}
                    alt={ZeltyDish.name}
                    src={ZeltyDish.image}
                  />
                </div>
              ))}
          </div>
        }
        TextActionButton={"Valider"}
        SousParagraphe={`Choisissez un plat ZeltyID pour le plat ${Dish.Production.IDPepers}`}
        TitleModal={`Association du plat ${Dish.Production.IDPepers} a un plat ZeltyID`}
        DataForm={{ ...DataForm }}
        Function={HandleAssociePeperDishtoZelty}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default RelierDishToZeltyID;
