import React, { useState, useEffect } from "react";
import "./SearchBarPrep.css";

const SearchBarPrep = ({ UseEffectFunction, setResult }) => {
  const [input, setInput] = useState("");

  const fetchData = (value) => {
    UseEffectFunction().then((json) => {
      const results = json.filter((prep) => {
        return value && prep.name && prep.name.toLowerCase().includes(value);
      });
      setResult(results);
    });
  };
  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="search-bar-container-Prep">
      <div className="input-wrapper-Prep">
        <input
          placeholder="Type to search preparation"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchBarPrep;
