import { UilEstate, UilPackage } from "@iconscout/react-unicons";

export const SidebarDataOwner = [
  {
    icon: UilEstate,
    heading: "Creation Produit",
  },
  {
    icon: UilPackage,
    heading: "Fiches Technique",
  },
  {
    icon: UilPackage,
    heading: "All Data FireBase",
  },
  {
    icon: UilPackage,
    heading: "user Data",
  },
  {
    icon: UilPackage,
    heading: "controle manager",
  },
  {
    icon: UilPackage,
    heading: "Logistique",
  },
  {
    icon: UilPackage,
    heading: "Data Kitchen",
  },
  {
    icon: UilPackage,
    heading: "Matiére",
  },
];
