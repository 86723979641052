import React, { useState } from "react";

const AdresseInfo = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontSize: "26px", fontWeight: "bold" }}>
        Nom du restaurant
      </div>
      <div>Adresse</div>
      <div>Heure de fermeture</div>
    </div>
  );
};

export default AdresseInfo;
