import React, { useState, useEffect } from "react";
import ModalAvecFunction from "../../../../components/VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField } from "@material-ui/core";

import {
  getAllDish,
  HandleModifierCategoriesDish,
  getAllDishCategories,
} from "../../../../Data/firebaseInteractionOwnerV2";

const ModifierCategorieAllDish = ({ IDDish }) => {
  const [OpenModal, setOpenModal] = useState(false);
  const [DataForm, setDataForm] = useState({});

  const [AllDish, setAllDish] = useState(null);
  const [AllCategories, setAllCategories] = useState(null);

  useEffect(() => {
    async function fetchOrders() {
      const Dish = await getAllDish(IDDish);
      setAllDish(Dish);
      const Categories = await getAllDishCategories();
      setAllCategories(Categories);
    }
    fetchOrders();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="ButtonAddMatiere" onClick={() => handleOpenModal()}>
        Change categorie tout les produits
      </div>
      <ModalAvecFunction
        open={OpenModal}
        handleClose={handleCloseModal}
        Form={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            {AllCategories &&
              AllCategories.map((categorie) => (
                <div
                  style={
                    DataForm.hasOwnProperty("categorie") &&
                    DataForm.categorie === categorie.IDPepers
                      ? {
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid red",
                          marginBottom: "5px",
                        }
                      : {
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid black",
                          marginBottom: "5px",
                        }
                  }
                  onClick={() =>
                    setDataForm((prev) => ({
                      ...prev,
                      categorie: categorie.IDPepers,
                    }))
                  }
                >
                  {categorie.name}
                </div>
              ))}
            {AllDish &&
              AllDish.map((dish) => (
                <div
                  style={
                    DataForm.hasOwnProperty("ListeDish") &&
                    DataForm.ListeDish.includes(dish.Production.IDPepers)
                      ? {
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid red",
                          marginBottom: "5px",
                        }
                      : {
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid black",
                          marginBottom: "5px",
                        }
                  }
                  onClick={() =>
                    setDataForm((prev) => {
                      if (prev.hasOwnProperty("ListeDish")) {
                        if (prev.ListeDish.includes(dish.Production.IDPepers)) {
                          return {
                            ...prev,
                            ListeDish: prev.ListeDish.filter(
                              (id) => id !== dish.Production.IDPepers
                            ),
                          };
                        } else {
                          return {
                            ...prev,
                            ListeDish: [
                              ...prev.ListeDish,
                              dish.Production.IDPepers,
                            ],
                          };
                        }
                      } else {
                        return {
                          ...prev,
                          ListeDish: [dish.Production.IDPepers],
                        };
                      }
                    })
                  }
                >
                  {dish.Production.name}
                </div>
              ))}
          </div>
        }
        TextActionButton={"Ajouter categories"}
        SousParagraphe={``}
        TitleModal={`Modification categories `}
        DataForm={{ ...DataForm }}
        Function={HandleModifierCategoriesDish}
        setDataForm={setDataForm}
      />
    </div>
  );
};

export default ModifierCategorieAllDish;
