import React, { useState, useEffect } from "react";
import "./StockDisplay.css";
import {
  getStockData,
  putStockData,
} from "../../../../Data/firebaseInteractionOwnerV2";
import SearchbarWithGivenData from "../../../../components/OwnerV2Components/SearchbarWithGivenData/SearchbarWithGivenData";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";
import { Button, CircularProgress } from "@mui/material";

const StockDisplay = ({ RestaurantName }) => {
  const [StockData, setStockData] = useState(null);
  const [searchbarResultStockData, setSearchbarResultStockData] =
    useState(null);

  const [Restaurant] = useCollection(
    collection(getFirestore(app), `${RestaurantName}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  async function fetchOrders() {
    const Stock = await getStockData();
    setStockData(Stock);
  }

  useEffect(() => {
    fetchOrders();
  }, [Restaurant]);

  if (searchbarResultStockData && searchbarResultStockData.length === 0) {
    setSearchbarResultStockData(StockData);
  }

  return (
    <div className="ContenerDisplayStock">
      <div className="rechercheDansStock">
        <SearchbarWithGivenData
          data={StockData}
          setResult={setSearchbarResultStockData}
          Propriete={"name"}
          TextToSearch={"Search in the stock"}
        />
      </div>
      <div className="DisplayStock">
        <div>
          {searchbarResultStockData &&
            searchbarResultStockData
              .filter((stock) => stock.TypeDisplay === "Ingredient")
              .map((ingredient) => (
                <div>
                  {ingredient.name} Qte:{ingredient.Qte}
                </div>
              ))}
        </div>
        <div>
          {searchbarResultStockData ? (
            searchbarResultStockData
              .filter((stock) => stock.TypeDisplay === "Preparation")
              .map((Prep) => (
                <div>
                  {Prep.name} Qte:{Prep.Qte}
                </div>
              ))
          ) : (
            <CircularProgress size={24} color="secondary" />
          )}
        </div>
      </div>
    </div>
  );
};

export default StockDisplay;
