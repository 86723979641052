import React, { useState, useEffect } from "react";
import "./DisplayFicheTechinique.css";
import FichesTechnique from "../FichesTechnique/FichesTechnique";
import { getProductInPosteDeTravailForFicheTechnique } from "../../Data/firebaseService";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../firebaseConfig";

const DisplayFicheTechinique = ({ Workplace }) => {
  const [ProductToDo, setProductToDo] = useState([]);

  const [PosteDeTravail] = useCollection(
    collection(getFirestore(app), "PosteDeTravail"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    async function fetchProductToDo() {
      const AllProductToDo = await getProductInPosteDeTravailForFicheTechnique(
        Workplace
      );
      setProductToDo(AllProductToDo);
    }
    fetchProductToDo();
  }, [PosteDeTravail, Workplace]);

  return (
    <div className="FicheTechniqueContener">
      {Workplace}
      {ProductToDo.map((product) => {
        return (
          <>
            <FichesTechnique ProductName={product} />
          </>
        );
      })}
    </div>
  );
};

export default DisplayFicheTechinique;
