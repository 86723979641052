import React, { useState } from "react";
import "./MainDashCuisinier.css";
import PosteConfection from "../../PosteConfection/PosteConfection";
import SelectProduct from "../../SelectProduct/SelectProduct";
import SelectProductAssemblage from "../../SelectProduct/SelectProductAssemblage/SelectProductAssemblage";
import PosteConfectionAssemblage from "../../../components/PosteConfection/PosteConfectionAssemblage/PosteConfectionAssemblage";

const MainDashCuisinier = ({ Workplace }) => {
  return (
    <div className="MainDash">
      {Workplace === "Chaud" && (
        <>
          <PosteConfection WorkPlace="Chaud" />
          <SelectProduct WorkPlace="Chaud" />
        </>
      )}
      {Workplace === "Froid" && (
        <>
          <PosteConfection WorkPlace="Froid" />
          <SelectProduct WorkPlace="Froid" />
        </>
      )}
      {Workplace === "Assemblage" && (
        <>
          <PosteConfectionAssemblage />
          <SelectProductAssemblage />
        </>
      )}
    </div>
  );
};

export default MainDashCuisinier;
