import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import "./ModalAddNewMatierePremiere.css";
import { AddNewMatierePremiere } from "../../../Functions/HandleClickFunctions";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { getFournisseurSearchBarData } from "../../../Data/firebaseService";
import ModalAddNewFournisseurs from "../../Modal/ModalAddNewFournisseurs/ModalAddNewFournisseurs";
import { AiOutlinePlusCircle } from "react-icons/ai";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore } from "firebase/firestore";
import { app } from "../../../firebaseConfig";

export default function ModalAddNewMatierePremiere({ open, handleClose }) {
  const [NomMatiere, setMatiere] = useState("");
  const [NomRefFournisseur, setNomRefFournisseur] = useState("");
  const [Fournisseur, setFournisseur] = useState("");
  const [AllFournisseur, setAllFournisseur] = useState(null);
  const [openModalAddFournisseur, setOpenModalAddFournisseur] = useState(false);
  const [PrixHT, setPrixHT] = useState("");
  const [SousCatégorie, setSousCatégorie] = useState("");
  const [Statut, setStatut] = useState("");
  const [UniteMesure, setUniteMesure] = useState("");
  const [SKU, setSKU] = useState("");
  const [Conditionnement, setConditionnement] = useState(null);

  // Qte conditionnement represente la qte presente dans le conditionnement en fonction de l'unitee de recette
  // Par exemple dans un pack de 24 canette, il y a 24 canette, donc la qte conditionnement est de 24 car l'unite de recette est unit
  // PrixURecetteHT = PrixHT / QteConditionnement
  const [QteConditionnement, setQteConditionnement] = useState("");

  const [Production] = useCollection(
    collection(getFirestore(app), "Production"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      let AllFournisseur = await getFournisseurSearchBarData();
      setAllFournisseur(AllFournisseur);
    };
    fetchData();
  }, [Production]);

  const handleCloseModalAddFournisseur = () => {
    setOpenModalAddFournisseur(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ fontSize: "35px" }}>
          Ajouter une nouvelle Matiere premiere
        </div>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom de la matiere premiere"
            variant="outlined"
            value={NomMatiere}
            onChange={(e) => setMatiere(e.target.value)}
          />
        </Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Nom Chez le fournisseur"
            variant="outlined"
            value={NomRefFournisseur}
            onChange={(e) => setNomRefFournisseur(e.target.value)}
          />
        </Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="SKU (Ref fournisseur)"
            variant="outlined"
            value={SKU}
            onChange={(e) => setSKU(e.target.value)}
          />
        </Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Conditionnement"
            variant="outlined"
            value={Conditionnement}
            onChange={(e) => setConditionnement(e.target.value)}
          />
        </Typography>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Prix HT"
            variant="outlined"
            value={PrixHT}
            onChange={(e) => setPrixHT(e.target.value)}
          />
        </Typography>

        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Qte Conditionnement"
            variant="outlined"
            value={QteConditionnement}
            onChange={(e) => setQteConditionnement(e.target.value)}
          />
        </Typography>

        <Typography id="modal-Select-sousCategorie">
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">Unite Mesure</InputLabel>
            <Select
              labelId="select-Fournisseeur"
              id="select-Fournisseeur"
              value={UniteMesure}
              onChange={(e) => setUniteMesure(e.target.value)}
            >
              <MenuItem key="Kg" value="Kg">
                Kg
              </MenuItem>
              <MenuItem key="unit" value="unit">
                unit
              </MenuItem>
            </Select>
          </FormControl>
        </Typography>
        <Typography id="modal-Select-sousCategorie"></Typography>
        <Typography id="modal-Select-Fournisseeur">
          <FormControl sx={{ width: "200px", mt: "10px", mb: "10px" }}>
            <InputLabel id="select-unite-Preparation">
              Selection fournisseur
            </InputLabel>
            <Select
              labelId="select-Fournisseeur"
              id="select-Fournisseeur"
              value={Fournisseur}
              onChange={(e) => setFournisseur(e.target.value)}
            >
              {AllFournisseur != null &&
                AllFournisseur.map((fournisseur) => (
                  <MenuItem key={fournisseur.Nom} value={fournisseur.Nom}>
                    {fournisseur.Nom}
                  </MenuItem>
                ))}
            </Select>
            <AiOutlinePlusCircle
              onClick={() => setOpenModalAddFournisseur(true)}
              className="Plus-Add-Product"
            />
          </FormControl>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              AddNewMatierePremiere(
                NomMatiere,
                Fournisseur,
                NomRefFournisseur,
                PrixHT,
                UniteMesure,
                SKU,
                Conditionnement,
                QteConditionnement
              );
              handleClose();
            }}
          >
            Ajouter Matiere Premiere
          </Button>
        </Typography>
        <ModalAddNewFournisseurs
          open={openModalAddFournisseur}
          handleClose={handleCloseModalAddFournisseur}
        />
      </Box>
    </Modal>
  );
}
