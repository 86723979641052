import React, { useState } from "react";
import "./AddNewUserPrep.css";
import ModalAvecFunction from "../../../VisiteurComponents/Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField, Button } from "@material-ui/core";
import { AddNewUserPreparation } from "../../../../Data/firebaseInteractionVisitorsV2";
import UploadImage from "../../../UploadImage/UploadImage";

const AddNewProduct = ({ userID }) => {
  const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
  const [DataForm, setDataForm] = useState({ UserIdOwner: userID });
  const [img, setImag] = useState(null);

  const handleOpenCreateProductModal = () => {
    setOpenCreateProductModal(true);
  };

  const handleCloseCreateProductModal = () => {
    setOpenCreateProductModal(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Ajoutez ici la logique pour traiter les données du formulaire
  };

  return (
    <div>
      <div
        className="ButtonAddProduct"
        onClick={() => handleOpenCreateProductModal()}
      >
        ADD PREPARATION
      </div>
      <ModalAvecFunction
        open={openCreateProductModal}
        handleClose={handleCloseCreateProductModal}
        Form={
          <form onSubmit={handleFormSubmit}>
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }))
              }
              label="Nom de la preparation"
            />
            <div className="SelectUniteDeMesureContener">
              Type de preparation
              <div
                className={
                  DataForm.Type === "Mise a disposition"
                    ? "SelectedUnite"
                    : "SelectUnite"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    Type: "Mise a disposition",
                  }))
                }
              >
                Mise a disposition
              </div>
              <div
                className={
                  DataForm.Type === "Decoupe formatage"
                    ? "SelectedUnite"
                    : "SelectUnite"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    Type: "Decoupe formatage",
                  }))
                }
              >
                Decoupe formatage
              </div>
              <div
                className={
                  DataForm.Type === "Preparation"
                    ? "SelectedUnite"
                    : "SelectUnite"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    Type: "Preparation",
                  }))
                }
              >
                Preparation
              </div>
            </div>
            <div className="SelectUniteDeMesureContener">
              Unite de mesure
              <div
                className={
                  DataForm.UniteDeMesure === "Unite"
                    ? "SelectedUnite"
                    : "SelectUnite"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    UniteDeMesure: "Unite",
                  }))
                }
              >
                Unite
              </div>
              <div
                className={
                  DataForm.UniteDeMesure === "G"
                    ? "SelectedUnite"
                    : "SelectUnite"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    UniteDeMesure: "G",
                  }))
                }
              >
                G
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              <UploadImage setImag={setImag} />
            </div>
          </form>
        }
        TextActionButton={"Ajouter la preparation"}
        TitleModal={"Nouvelle preparation"}
        DataForm={{ ...DataForm, img }}
        setDataForm={setDataForm}
        Function={AddNewUserPreparation}
      />
    </div>
  );
};

export default AddNewProduct;
