import React, { useState } from "react";
import "./AddIngredient.css";
import ModalAvecFunction from "../../Modal/ModalAvecFunction/ModalAvecFunction";
import { TextField, Button } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { addNewIngredientVisitorV2 } from "../../../../Data/firebaseInteractionVisitorsV2";
import UploadImage from "../../../UploadImage/UploadImage";

const AddIngredient = ({ userID }) => {
  const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
  const [img, setImag] = useState(null);
  const [DataForm, setDataForm] = useState({});

  const handleOpenCreateProductModal = () => {
    setOpenCreateProductModal(true);
    setImag(null);
  };

  const handleCloseCreateProductModal = () => {
    setOpenCreateProductModal(false);
  };

  return (
    <div>
      <div
        className="ButtonAddMatiere"
        onClick={() => handleOpenCreateProductModal()}
      >
        ADD Ingredient
      </div>
      <ModalAvecFunction
        open={openCreateProductModal}
        handleClose={handleCloseCreateProductModal}
        Form={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }))
              }
              label="Ingredient"
              value={DataForm.name}
            />
            {/*<TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  NomFournisseur: event.target.value,
                }))
              }
              label="Nom chez le fournisseur"
              value={DataForm.Ingredient}
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  PrixHT: event.target.value,
                }))
              }
              label="PrixHT"
              value={DataForm.PrixHT}
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  Conditionnement: event.target.value,
                }))
              }
              label="Conditionnement"
              value={DataForm.Conditionnement}
            />
            <TextField
              onChange={(event) =>
                setDataForm((prevData) => ({
                  ...prevData,
                  QteConditionnement: event.target.value,
                }))
              }
              label="Qte Du Conditionnement Par unite de recette"
            />*/}
            <div className="SelectFournisseurContener">
              UNITE DE RECETTE :
              <div
                className={
                  DataForm.UniteDeRecette === "Unit"
                    ? "SelectedIngredient"
                    : "SelectIngredient"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    UniteDeRecette: "Unit",
                  }))
                }
              >
                Unit
              </div>
              <div
                className={
                  DataForm.UniteDeRecette === "KG"
                    ? "SelectedIngredient"
                    : "SelectIngredient"
                }
                onClick={() =>
                  setDataForm((prevData) => ({
                    ...prevData,
                    UniteDeRecette: "KG",
                  }))
                }
              >
                KG
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              <UploadImage setImag={setImag} />
            </div>
          </div>
        }
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter un Ingredient"}
        DataForm={{ ...DataForm, img }}
        setDataForm={setDataForm}
        Function={addNewIngredientVisitorV2}
      />
    </div>
  );
};

export default AddIngredient;
