import React, { useState, useEffect } from "react";
import "./DetailSelectedRefairePrep.css";
import {
  getPrepObjectWithIDPrep,
  getIngredientObjectUsedInPrepRefaitWithIDPrepRefait,
} from "../../../../../../../../Data/firebaseInteractionOwnerV2";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

const DetailSelectedRefairePrep = ({ SelectedRefaitPrep, RestaurantName }) => {
  const [PrepData, setPrepData] = useState(null);
  const [IngredientData, setIngredientData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const Preparation = await getPrepObjectWithIDPrep(
        SelectedRefaitPrep.IDPrepRefait
      );
      setPrepData(Preparation);
      const IngredientData =
        await getIngredientObjectUsedInPrepRefaitWithIDPrepRefait(
          SelectedRefaitPrep.IDPepers,
          RestaurantName
        );
      setIngredientData(IngredientData);
    }
    fetchData();
  }, [SelectedRefaitPrep]);

  console.log(IngredientData);
  return (
    <div className="ContenerDetailSelectedRefairePrep">
      <div className="QteRefaitDisplay">
        Qte Refait prep : {SelectedRefaitPrep.QteRefait}{" "}
        {PrepData && PrepData.UniteDeMesure}
      </div>
      <div>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom Ingredient</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="left">Qte Ingredient used</TableCell>
                <TableCell align="left">Qte Ingredient theorique</TableCell>
                <TableCell align="left">
                  Ecart de conssomation recette
                </TableCell>
                <TableCell align="left">Unite De Recette</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {IngredientData &&
                IngredientData.map((ingredient) => (
                  <TableRow
                    key={ingredient.IDPepers}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {ingredient.name}
                    </TableCell>
                    <TableCell align="left">
                      <Avatar alt={ingredient.name} src={ingredient.Img} />
                    </TableCell>
                    <TableCell align="left">
                      {ingredient.QteUsedInPrepRefait}
                    </TableCell>
                    <TableCell align="left">
                      {ingredient.QteRecetteInitiale}
                    </TableCell>
                    <TableCell align="left">{ingredient.EcartConsso}</TableCell>
                    <TableCell align="left">
                      {ingredient.UniteDeRecette}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DetailSelectedRefairePrep;
