import React, { useState } from "react";
import "./ChoixRangeDate.css";
import TextField from "@mui/material/TextField";

const ChoixRangeDate = ({ setDateStart, setDateEnd }) => {
  return (
    <div>
      <TextField
        id="DateStart"
        label="DateStart"
        type="date"
        defaultValue="2023-05-24"
        onChange={(e) =>
          setDateStart(() => {
            return `${e.target.value}T00:00:00`;
          })
        }
      />
      <TextField
        id="DateEnd"
        label="DateEnd"
        type="date"
        defaultValue="2023-05-24"
        onChange={(e) =>
          setDateEnd(() => {
            return `${e.target.value}T00:00:00`;
          })
        }
      />
    </div>
  );
};

export default ChoixRangeDate;
