import React from "react";
import { SidebarDataManager } from "../../Data/DataManagerMode";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useRecoilState } from "recoil";
import { sideBar } from "../../atoms/SideBarAtome";
import CommandePreparation from "../../../src/View/Manager/CommandePreparation";
import StockRestauarant from "../../View/Manager/StockRestauarant";
import ActualiteRestuarant from "../../View/Manager/ActualiteRestuarant";
import "./Manager.css";
import PageRefairePreparation from "../../View/Manager/PageRefairePreparation";
import IndicationQuandRefairePrep from "../../components/ManagerComponents/IndicationQuandRefairePrep/IndicationQuandRefairePrep";

const Manager = () => {
  const [SideBarAtom, setSideBarAtom] = useRecoilState(sideBar);
  return (
    <>
      <Sidebar data={SidebarDataManager} />
      {/*SideBarAtom.view === 0 && <CommandePreparation />*/}
      {/*SideBarAtom.view === 1 && <StockRestauarant />*/}
      {/*SideBarAtom.view === 2 && <ActualiteRestuarant />*/}
      {SideBarAtom.view === 0 && (
        <PageRefairePreparation RestaurantName={"RestaurantSainteFoy"} />
      )}
      {SideBarAtom.view === 1 && (
        <IndicationQuandRefairePrep RestaurantName={"RestaurantSainteFoy"} />
      )}
    </>
  );
};

export default Manager;
