import {
  getFirestore,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { app, firestore, storage } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { m } from "framer-motion";
import { ca, el } from "date-fns/locale";

// Prend un objet date et retourne une string au format AAAA-MM-JJ
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
// la date de debut est inclu et la date de fin exclu par exemple si on veut la conssomation d'ingredient entre du 01/03/2024 au 03/03/2024 il faut mettre la date de debut a 01/03/2024 et la date de fin a 04/03/2024 les journee 01 - 02- 03 seront inclu dans le resultat
// Le format de la date doit etre AAAA-MM-JJTHH:MM:SS
export async function getDailyConssomationPrep(
  RestaurantName,
  dateStart,
  dateEnd
) {
  try {
    let TableauIDDailyActivity = [];
    let DateDebut = new Date(dateStart);

    while (DateDebut.getTime() < new Date(dateEnd).getTime()) {
      const year = DateDebut.getFullYear();
      const month = String(DateDebut.getMonth() + 1).padStart(2, "0");
      const day = String(DateDebut.getDate()).padStart(2, "0");
      const DateFormated = `${day}${month}${year}`;
      TableauIDDailyActivity.push(DateFormated);
      DateDebut = new Date(DateDebut.getTime() + 24 * 60 * 60 * 1000);
    }

    let ConssomationTotalePrepEntreDeuxDate = {};
    for (const DateDocument of TableauIDDailyActivity) {
      const DailyDocRef = doc(
        firestore,
        `${RestaurantName}DailyActivities`,
        DateDocument
      );
      const DailySnapshot = await getDoc(DailyDocRef);
      if (DailySnapshot.exists()) {
        const DailyData = DailySnapshot.data();
        let ConssomationTheoriquePrep = [];
        if (DailyData.hasOwnProperty("ConssomationTheoriquePrep")) {
          ConssomationTheoriquePrep = Object.keys(
            DailyData.ConssomationTheoriquePrep
          );
        } else {
          alert(
            `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`
          );
          console.log(
            `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`
          );

          throw `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`;
        }
        for (const IDPrep of ConssomationTheoriquePrep) {
          if (ConssomationTotalePrepEntreDeuxDate.hasOwnProperty(IDPrep)) {
            ConssomationTotalePrepEntreDeuxDate[IDPrep] +=
              DailyData.ConssomationTheoriquePrep[IDPrep];
          } else {
            ConssomationTotalePrepEntreDeuxDate[IDPrep] =
              DailyData.ConssomationTheoriquePrep[IDPrep];
          }
        }
      } else {
        console.log("le daily document n'existe pas");
      }
    }

    let ListeAllPrepVenduData = [];
    const PrepConsso = Object.keys(ConssomationTotalePrepEntreDeuxDate);
    for (const IDPrep of PrepConsso) {
      const PrepDocRef = doc(firestore, `preparations`, IDPrep);
      const PrepSnapshot = await getDoc(PrepDocRef);
      if (PrepSnapshot.exists()) {
        const PrepData = PrepSnapshot.data();
        ListeAllPrepVenduData.push({
          ...PrepData,
          QteVendu: ConssomationTotalePrepEntreDeuxDate[IDPrep],
        });
      } else {
        throw "l'ingredient pepers n'existe pas dans la base de donnée";
      }
    }

    return ListeAllPrepVenduData.sort((a, b) => b.QteVendu - a.QteVendu);
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
// la date de debut est inclu et la date de fin exclu par exemple si on veut la conssomation d'ingredient entre du 01/03/2024 au 03/03/2024 il faut mettre la date de debut a 01/03/2024 et la date de fin a 04/03/2024 les journee 01 - 02- 03 seront inclu dans le resultat
// Le format de la date doit etre AAAA-MM-JJTHH:MM:SS
export async function getDailyConssomationIngredient(
  RestaurantName,
  dateStart,
  dateEnd
) {
  try {
    let TableauIDDailyActivity = [];
    let DateDebut = new Date(dateStart);

    while (DateDebut.getTime() < new Date(dateEnd).getTime()) {
      const year = DateDebut.getFullYear();
      const month = String(DateDebut.getMonth() + 1).padStart(2, "0");
      const day = String(DateDebut.getDate()).padStart(2, "0");
      const DateFormated = `${day}${month}${year}`;
      TableauIDDailyActivity.push(DateFormated);
      DateDebut = new Date(DateDebut.getTime() + 24 * 60 * 60 * 1000);
    }

    let ConssomationTotaleMatiereEntreDeuxDate = {};
    for (const DateDocument of TableauIDDailyActivity) {
      const DailyDocRef = doc(
        firestore,
        `${RestaurantName}DailyActivities`,
        DateDocument
      );
      const DailySnapshot = await getDoc(DailyDocRef);
      if (DailySnapshot.exists()) {
        const DailyData = DailySnapshot.data();
        let IngredientConssome = [];
        if (DailyData.hasOwnProperty("IngredientConssome")) {
          IngredientConssome = Object.keys(DailyData.IngredientConssome);
        } else {
          console.log(
            `Il n'y a pas de conssomation d'ingredient pour le dailyActivitie : ${Date}`
          );
        }
        for (const IDIngredient of IngredientConssome) {
          if (
            ConssomationTotaleMatiereEntreDeuxDate.hasOwnProperty(IDIngredient)
          ) {
            ConssomationTotaleMatiereEntreDeuxDate[IDIngredient] +=
              DailyData.IngredientConssome[IDIngredient];
          } else {
            ConssomationTotaleMatiereEntreDeuxDate[IDIngredient] =
              DailyData.IngredientConssome[IDIngredient];
          }
        }
      } else {
        alert(
          `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`
        );
        console.log(
          `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`
        );

        throw `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`;
      }
    }

    let ListeAllIngredientConssoData = [];
    const IngredientConsso = Object.keys(
      ConssomationTotaleMatiereEntreDeuxDate
    );
    for (const IDIngredient of IngredientConsso) {
      const IngredientDocRef = doc(firestore, `Ingredients`, IDIngredient);
      const IngredientSnapshot = await getDoc(IngredientDocRef);
      if (IngredientSnapshot.exists()) {
        const IngredientData = IngredientSnapshot.data();
        ListeAllIngredientConssoData.push({
          ...IngredientData,
          QteConssome: ConssomationTotaleMatiereEntreDeuxDate[IDIngredient],
        });
      } else {
        throw "l'ingredient pepers n'existe pas dans la base de donnée";
      }
    }
    return ListeAllIngredientConssoData;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function GetIngredientMisEnStockEntreDeuxDate(
  RestaurantName,
  dateStart,
  dateEnd
) {
  // On creer un tableau de date qui represente les ID des documents pour le DailyActivity pour recuperer la liste des commandes matiere entree en stock au restuarant ce jours la
  let TableauIDDailyActivity = [];
  let DateDebut = new Date(dateStart);
  while (DateDebut.getTime() < new Date(dateEnd).getTime()) {
    const year = DateDebut.getFullYear();
    const month = String(DateDebut.getMonth() + 1).padStart(2, "0");
    const day = String(DateDebut.getDate()).padStart(2, "0");
    const DateFormated = `${day}${month}${year}`;
    TableauIDDailyActivity.push(DateFormated);
    DateDebut = new Date(DateDebut.getTime() + 24 * 60 * 60 * 1000);
  }

  try {
    let ComptageIngredientRecu = {};
    for (const IDDailyActivity of TableauIDDailyActivity) {
      const RefDailyActivity = doc(
        firestore,
        `${RestaurantName}DailyActivities`,
        IDDailyActivity
      );
      const DailyActivitySnapshoot = await getDoc(RefDailyActivity);
      if (DailyActivitySnapshoot.exists()) {
        if (
          DailyActivitySnapshoot.data().hasOwnProperty(
            "CommandeMatiereMiseEnStock"
          )
        ) {
          const IDCommandeMisEnStock =
            DailyActivitySnapshoot.data().CommandeMatiereMiseEnStock;
          for (const IDCommande of IDCommandeMisEnStock) {
            const CommandeDocRef = doc(
              firestore,
              `${RestaurantName}CommandeMatiere`,
              IDCommande
            );
            const CommandeSnapshot = await getDoc(CommandeDocRef);
            if (CommandeSnapshot.exists()) {
              const CommandeData = CommandeSnapshot.data();
              for (const MatiereRecu of CommandeData.MatiereOrder) {
                const MatiereDocRef = doc(
                  firestore,
                  "ReferenceMatierePremiere",
                  MatiereRecu.IDMatiere
                );
                const MatiereSnapshot = await getDoc(MatiereDocRef);
                const MatiereData = MatiereSnapshot.data();
                ComptageIngredientRecu[MatiereData.IngredientID] =
                  (ComptageIngredientRecu[MatiereData.IngredientID] || 0) +
                  MatiereRecu.QteRecu * MatiereData.QteConditionnement;
              }
            } else {
              alert(`La commande matiere ${IDCommande} n'existe pas`);
              throw `La commande matiere ${IDCommande} n'existe pas`;
            }
          }
        } else {
          console.log(
            `Il n'y a pas de commande matiere mise en stock pour le DailyActivity ${IDDailyActivity}`
          );
        }
      } else {
        alert(`Le DailyActivity ${IDDailyActivity} n'existe pas`);
        throw `Le DailyActivity ${IDDailyActivity} n'existe pas`;
      }
    }
    return ComptageIngredientRecu;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function getAllMatiereOfAnIngredientWithFormatedData(
  IngredientObject
) {
  const FournisseurDocRef = doc(
    firestore,
    "ProductionVisitorV2",
    "FournisseursVisitor"
  );
  const FournisseurSnapshot = await getDoc(FournisseurDocRef);
  const AllFournisseur = FournisseurSnapshot.data().Fournisseurs;

  const querySnapshot = await getDocs(
    collection(firestore, "ReferenceMatierePremiere")
  );
  const AllRefMatiere = [];
  querySnapshot.forEach((doc) => {
    AllRefMatiere.push({ ...doc.data() });
  });

  const AllReferenceMatiereForAnIngredient = AllRefMatiere.filter(
    (RefMatiere) => RefMatiere.IngredientID === IngredientObject.IDPepers
  );

  const AllReferenceMatiereForAnIngredientWithFournisseur =
    AllReferenceMatiereForAnIngredient.map((RefMatiere) => {
      const FournisseurData = AllFournisseur.filter(
        (Fournisseur) => Fournisseur.IDPepers === RefMatiere.FournisseurID
      )[0];

      return {
        ...RefMatiere,
        FournisseurData,
      };
    });

  return AllReferenceMatiereForAnIngredientWithFournisseur;
}

export async function ChangeDefaultRefMatiere(
  IngredientObject,
  RefMatiereObject
) {
  try {
    if (IngredientObject !== null && RefMatiereObject !== null) {
    } else {
      alert("Il manque une informations ");
      throw new Error("Il manque une informations");
    }
    const IngredientDocRef = doc(
      firestore,
      "Ingredients",
      IngredientObject.IDPepers
    );
    await updateDoc(IngredientDocRef, {
      IdREFfournisseurParDefault: RefMatiereObject.IDPepers,
    });
    console.log("Document successfully updated!");
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}

export async function getAllDish() {
  const querySnapshot = await getDocs(collection(firestore, "dishes"));
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data() });
  });

  return documents;
}

export async function getAllPrep() {
  const querySnapshot = await getDocs(collection(firestore, "preparations"));
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data() });
  });

  return documents;
}
export async function getPrepObjectForPrepUsedInDish(SelectedDish) {
  const documents = [];
  for (const PrepUsed of SelectedDish.Production.PreparationIntermediaire) {
    const docRef = doc(firestore, "preparations", PrepUsed.IDPrep);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      documents.push({
        ...docSnapshot.data(),
        QteUsedInDish: PrepUsed.Qte,
      });
    } else {
      console.warn(`Document with ID ${PrepUsed.IDPrep} does not exist.`);
    }
  }

  return documents;
}

export async function HandleSwitchProductToOnline(Data) {
  try {
    const docRefActifProduct = doc(
      firestore,
      Data.RestaurantName,
      "ActifProductInTheRestaurant"
    );
    const docSnapshot = await getDoc(docRefActifProduct);

    let OldListeOfActifIngredient = docSnapshot.data().ActiveIngredient;
    let OldListeOfActifPrep = docSnapshot.data().ActivePrep;
    let OldListeOfActifProduct = docSnapshot.data().ActiveProduct;

    let newListeActiveProduct = [];
    Data.SelectedDish.forEach((dishID) => {
      if (!docSnapshot.data().ActiveProduct.includes(dishID)) {
        newListeActiveProduct = [...newListeActiveProduct, dishID];
      }
    });

    if (docSnapshot.exists()) {
      newListeActiveProduct = [
        ...docSnapshot.data().ActiveProduct,
        ...newListeActiveProduct,
      ];
    } else {
      throw "le document n'existe pas";
    }
    let NouveauDishID = newListeActiveProduct.filter(
      (DishID) => !OldListeOfActifProduct.includes(DishID)
    );
    if (NouveauDishID.length === 0) {
      alert("Les produits sont déjà actif");
      throw new Error("Les produits sont déjà actif");
    }

    let newListeActivePrep = [];
    for (const DishID of newListeActiveProduct) {
      const docRefDish = doc(firestore, "dishes", DishID);
      const docSnapshotActivDish = await getDoc(docRefDish);
      const DishPrep =
        docSnapshotActivDish.data().Production.PreparationIntermediaire;

      DishPrep.forEach((prepUsed) => {
        if (
          !docSnapshot.data().ActivePrep.includes(prepUsed.IDPrep) &&
          !newListeActivePrep.includes(prepUsed.IDPrep)
        ) {
          newListeActivePrep.push(prepUsed.IDPrep);
        }
      });
    }

    if (docSnapshot.exists()) {
      newListeActivePrep = [
        ...docSnapshot.data().ActivePrep,
        ...newListeActivePrep,
      ];
    } else {
      throw "le document n'existe pas";
    }

    let newListeActiveIngredient = [];
    for (const PrepID of newListeActivePrep) {
      const docRefPrep = doc(firestore, "preparations", PrepID);
      const docSnapshotPrep = await getDoc(docRefPrep);
      const PrepMatiere = docSnapshotPrep.data().MatierePremiereUtilisees;

      PrepMatiere.forEach((IngredientUsed) => {
        if (
          !docSnapshot
            .data()
            .ActiveIngredient.includes(IngredientUsed.IDIngredient) &&
          !newListeActiveIngredient.includes(IngredientUsed.IDIngredient)
        ) {
          newListeActiveIngredient.push(IngredientUsed.IDIngredient);
        }
      });
    }

    if (docSnapshot.exists()) {
      newListeActiveIngredient = [
        ...docSnapshot.data().ActiveIngredient,
        ...newListeActiveIngredient,
      ];
    } else {
      throw "le document n'existe pas";
    }

    await updateDoc(docRefActifProduct, {
      ActiveProduct: newListeActiveProduct,
      ActivePrep: newListeActivePrep,
      ActiveIngredient: newListeActiveIngredient,
    });
    // On update que les nouveaux ingredients dans le stock
    let NouveauIngrediantID = newListeActiveIngredient.filter(
      (IngredientID) => !OldListeOfActifIngredient.includes(IngredientID)
    );

    const RefStockResto = doc(firestore, Data.RestaurantName, "Stock");
    for (const IngredientID of NouveauIngrediantID) {
      const StockSnapshoot = await getDoc(RefStockResto);
      if (StockSnapshoot.exists()) {
        updateDoc(RefStockResto, {
          [IngredientID]: { Qte: 0, Type: "Ingredient" },
        });
      } else {
        throw "Le document stock du restaurant n'existe pas";
      }
    }
    // On update que les nouveaux Prep dans le stock
    let NouveauPrepID = newListeActivePrep.filter(
      (PrepID) => !OldListeOfActifPrep.includes(PrepID)
    );

    console.log(NouveauPrepID);

    for (const PrepID of NouveauPrepID) {
      const StockSnapshoot = await getDoc(RefStockResto);
      if (StockSnapshoot.exists()) {
        updateDoc(RefStockResto, {
          [PrepID]: { Qte: 0, Type: "Preparation" },
        });
      } else {
        throw "Le document stock du restaurant n'existe pas";
      }
    }
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false;
  }
}
export async function PassProductToOfline(Listedish, RestaurantName) {
  try {
    const docRefActifProduct = doc(
      firestore,
      RestaurantName,
      "ActifProductInTheRestaurant"
    );
    const docSnapshot = await getDoc(docRefActifProduct);

    let OldListeOfActifProduct = docSnapshot.data().ActiveProduct;
    let OldListeOfActifIngredient = docSnapshot.data().ActiveIngredient;
    let OldListeOfActifPrep = docSnapshot.data().ActivePrep;

    // On verifie si le produit est bien dans la liste des produits actif pour les desactiver
    Listedish.forEach((dish) => {
      if (!OldListeOfActifProduct.includes(dish.IDPepers)) {
        alert("Le produit n'est pas dans la liste des produits actif");
        throw new Error(
          "Le produit n'est pas dans la liste des produits actif"
        );
      }
    });

    // On genere la nouvelle liste de produit actif
    let newListeActiveProduct = [];
    OldListeOfActifProduct.forEach((ActiveProductID) => {
      let isProductInList = true;
      Listedish.forEach((dish) => {
        if (dish.IDPepers === ActiveProductID) {
          isProductInList = false;
        }
      });
      if (isProductInList) {
        newListeActiveProduct.push(ActiveProductID);
      }
    });

    let newListeActivePrep = [];
    for (const DishID of newListeActiveProduct) {
      const docRefDish = doc(firestore, "dishes", DishID);
      const docSnapshotActivDish = await getDoc(docRefDish);
      if (docSnapshotActivDish.exists()) {
        const DishPrep =
          docSnapshotActivDish.data().Production.PreparationIntermediaire;

        DishPrep.forEach((prepUsed) => {
          newListeActivePrep.push(prepUsed.IDPrep);
        });
      } else {
        throw "le document n'existe pas";
      }
    }

    newListeActivePrep = [...new Set(newListeActivePrep)];

    let newListeActiveIngredient = [];
    for (const PrepID of newListeActivePrep) {
      const docRefPrep = doc(firestore, "preparations", PrepID);
      const docSnapshotPrep = await getDoc(docRefPrep);
      if (docSnapshotPrep.exists()) {
        const PrepMatiere = docSnapshotPrep.data().MatierePremiereUtilisees;

        PrepMatiere.forEach((IngredientUsed) => {
          newListeActiveIngredient.push(IngredientUsed.IDIngredient);
        });
      } else {
        throw "le document de preparation n'existe pas";
      }
    }

    newListeActiveIngredient = [...new Set(newListeActiveIngredient)];

    // on identifie les ingredients qui ne sont plus utiliser
    let IngredientEnleve = OldListeOfActifIngredient.filter(
      (OldIngredientID) => !newListeActiveIngredient.includes(OldIngredientID)
    );
    if (IngredientEnleve.length > 0) {
      console.log("Il y a des ingredients qui ne sont plus utilisés");
      const RefStockResto = doc(firestore, RestaurantName, "Stock");
      const StockSnapshoot = await getDoc(RefStockResto);
      if (StockSnapshoot.exists()) {
        const StockData = StockSnapshoot.data();
        const newStockData = { ...StockData };
        for (const IDIngredientEnleve of IngredientEnleve) {
          console.log(
            `l'ingredient :${IDIngredientEnleve} est enleve, La qte en stock etait de ${StockData[IDIngredientEnleve].Qte}`
          );
          delete newStockData[IDIngredientEnleve];
          await setDoc(RefStockResto, { ...newStockData });
        }
      } else {
        throw "Le document stock du restaurant n'existe pas";
      }
    } else {
      console.log("Il n'y a pas d'ingredients qui ne sont plus utilisés");
    }
    // on On fait la meme chose pour les prep
    // on identifie les ingredients qui ne sont plus utiliser
    let PrepEnleve = OldListeOfActifPrep.filter(
      (OldPrepID) => !newListeActivePrep.includes(OldPrepID)
    );
    if (PrepEnleve.length > 0) {
      console.log("Il y a des Preparations qui ne sont plus utilisées");
      const RefStockResto = doc(firestore, RestaurantName, "Stock");
      const StockSnapshoot = await getDoc(RefStockResto);
      if (StockSnapshoot.exists()) {
        const StockData = StockSnapshoot.data();
        const newStockData = { ...StockData };
        for (const IDPrepEnleve of PrepEnleve) {
          console.log(
            `la preparation :${IDPrepEnleve} est enlevée, La qte en stock etait de ${StockData[IDPrepEnleve].Qte}`
          );
          delete newStockData[IDPrepEnleve];
          await setDoc(RefStockResto, { ...newStockData });
        }
      } else {
        throw "Le document stock du restaurant n'existe pas";
      }
    } else {
      console.log("Il n'y a pas de Preparations qui ne sont plus utilisées");
    }

    await updateDoc(docRefActifProduct, {
      ActiveProduct: newListeActiveProduct,
      ActivePrep: newListeActivePrep,
      ActiveIngredient: newListeActiveIngredient,
    });

    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false;
  }
}
export async function HandleCreateRestaurant(Data) {
  try {
    if (!Data.hasOwnProperty("name")) {
      throw "Il manque le nom du restuarant";
    }
    const docRefActifProduct = doc(firestore, "AllRestaurant", Data.name);
    const docSnapshot = await getDoc(docRefActifProduct);
    if (docSnapshot.exists()) {
      alert(
        `Le document avec l'ID ${Data.name} existe dans la collection AllRestaurant.`
      );
      throw `Le document avec l'ID ${Data.name} existe dans la collection AllRestaurant.`;
    }

    await setDoc(docRefActifProduct, { IDPepers: uuidv4(), name: Data.name });

    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false;
  }
}

export async function getIngredientsObjectInSelectedPrep(SlectedPrep) {
  const documents = [];
  for (const IngredientUsed of SlectedPrep.MatierePremiereUtilisees) {
    const docRef = doc(firestore, "Ingredients", IngredientUsed.IDIngredient);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      documents.push({
        ...docSnapshot.data(),
        QteUsedInPrep: IngredientUsed.Qte,
      });
    } else {
      console.warn(
        `Document with ID ${IngredientUsed.IDIngredient} does not exist.`
      );
    }
  }

  return documents;
}

export async function getIngredientObjectUsedInPrep(PrepId) {
  try {
    const docRefPrep = doc(firestore, "preparations", PrepId);
    const PrepSnapshoot = await getDoc(docRefPrep);

    if (PrepSnapshoot.exists()) {
      let PrepData = PrepSnapshoot.data();
      const documents = [];
      for (const IngredientUsed of PrepData.MatierePremiereUtilisees) {
        const docRef = doc(
          firestore,
          "Ingredients",
          IngredientUsed.IDIngredient
        );
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          documents.push({
            ...docSnapshot.data(),
            QteUsedInPrep: IngredientUsed.Qte,
          });
        } else {
          console.warn(
            `Document with ID ${IngredientUsed.IDIngredient} does not exist.`
          );
          throw `Document with ID ${IngredientUsed.IDIngredient} does not exist.`;
        }
      }
      return documents;
    } else {
      console.warn(`Document does not exist.`);
      throw `Document does not exist.`;
    }
  } catch (error) {
    console.log(`une error ${error}`);
  }
}
export async function getPrepObjectWithIDPrep(PrepId) {
  try {
    const docRefPrep = doc(firestore, "preparations", PrepId);
    const PrepSnapshoot = await getDoc(docRefPrep);

    if (PrepSnapshoot.exists()) {
      let PrepData = PrepSnapshoot.data();
      return PrepData;
    } else {
      console.warn(`Document does not exist.`);
      throw `Document does not exist.`;
    }
  } catch (error) {
    console.log(`une error ${error}`);
  }
}
export async function getStockData() {
  const documents = [];
  const docRef = doc(firestore, "RestaurantSainteFoy", "Stock");
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    const ListIDIngredientOrPrep = Object.keys(docSnapshot.data());
    for (const id of ListIDIngredientOrPrep) {
      const docRefIngredient = doc(firestore, "Ingredients", id);
      const docSnapshotIngredient = await getDoc(docRefIngredient);
      if (docSnapshotIngredient.exists()) {
        documents.push({
          ...docSnapshot.data()[id],
          TypeDisplay: docSnapshot.data()[id].Type,
          ...docSnapshotIngredient.data(),
        });
      } else {
        const docRefPrep = doc(firestore, "preparations", id);
        const docSnapshotPrep = await getDoc(docRefPrep);
        if (docSnapshotPrep.exists()) {
          documents.push({
            ...docSnapshot.data()[id],
            TypeDisplay: docSnapshot.data()[id].Type,
            ...docSnapshotPrep.data(),
          });
        }
      }
    }
  } else {
    console.warn(`Document "Stock" does not exist.`);
  }

  return documents;
}

export async function putStockData() {
  const documents = [];
  const docRef = doc(firestore, "RestaurantSainteFoy", "Stock");

  updateDoc(docRef, {
    "7182d974-d036-46a7-ba60-b21ba8ed05b7": { Qte: 140, Type: "Preparation" },
  });

  return documents;
}
export async function getActiveProductIntheRestaurant(RestaurantName) {
  const documents = [];
  const docRef = doc(firestore, RestaurantName, "ActifProductInTheRestaurant");
  const docSnapShot = await getDoc(docRef);
  const AllActiveProduct = docSnapShot.data().ActiveProduct;

  const AllActiveProductObject = [];
  for (const prod of AllActiveProduct) {
    const docRefProd = doc(firestore, "dishes", prod);
    const docSnapshotProd = await getDoc(docRefProd);

    if (docSnapshotProd.exists()) {
      AllActiveProductObject.push({ ...docSnapshotProd.data().Production });
    } else {
      console.warn("La ref n'existe pas");
    }
  }

  return AllActiveProductObject;
}

export async function getActivePrep(RestaurantName) {
  try {
    const docRef = doc(
      firestore,
      RestaurantName,
      "ActifProductInTheRestaurant"
    );
    const docSnapShot = await getDoc(docRef);
    let ListeIDPrepUsed;
    if (docSnapShot.exists()) {
      ListeIDPrepUsed = docSnapShot.data().ActivePrep;
    } else {
      throw "le document n'existe pas";
    }

    const AllActivePrepObject = [];
    for (const IDPrep of ListeIDPrepUsed) {
      const docRefProd = doc(firestore, "preparations", IDPrep);
      const docSnapshotProd = await getDoc(docRefProd);

      if (docSnapshotProd.exists()) {
        AllActivePrepObject.push({ ...docSnapshotProd.data() });
      } else {
        console.warn("La ref n'existe pas");
      }
    }

    return AllActivePrepObject;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
// On urtilise la liste des produits actif
export async function getActiveIngredient(RestaurantName) {
  try {
    const docRef = doc(
      firestore,
      RestaurantName,
      "ActifProductInTheRestaurant"
    );
    const docSnapShot = await getDoc(docRef);
    let ListeIDIngredientUsed;
    if (docSnapShot.exists()) {
      ListeIDIngredientUsed = docSnapShot.data().ActiveIngredient;
    } else {
      throw "le document n'existe pas";
    }

    const AllActiveIngredientObject = [];
    for (const IDIngredient of ListeIDIngredientUsed) {
      const docRefIngredients = doc(firestore, "Ingredients", IDIngredient);
      const docSnapshotIngredients = await getDoc(docRefIngredients);

      if (docSnapshotIngredients.exists()) {
        AllActiveIngredientObject.push({ ...docSnapshotIngredients.data() });
      } else {
        throw "La ref n'existe pas";
      }
    }

    return AllActiveIngredientObject;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function HandleRefairePrep(Data) {
  try {
    console.log(Data);
    // on verifie si la qte entree est bien un nombre
    let newQteIngredientUsedForRefaire = [];
    Object.keys(Data.QteIngredientUsedForRefaire).forEach((ingredientID) => {
      if (typeof Data.QteIngredientUsedForRefaire[ingredientID] === "string") {
        let Qte = Data.QteIngredientUsedForRefaire[ingredientID].replace(
          ",",
          "."
        );
        let FormatedQte = parseFloat(
          Data.QteIngredientUsedForRefaire[ingredientID].replace(",", ".")
        );
        if (isNaN(FormatedQte) || !/^\d*\.?\d*$/.test(Qte)) {
          alert("La qte n'est pas un nombre valide");
          throw new Error(
            "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
          );
        }
        newQteIngredientUsedForRefaire = [
          ...newQteIngredientUsedForRefaire,
          { [ingredientID]: FormatedQte },
        ];
      } else {
        newQteIngredientUsedForRefaire = [
          ...newQteIngredientUsedForRefaire,
          { [ingredientID]: Data.QteIngredientUsedForRefaire[ingredientID] },
        ];
      }
    });

    let newQteRefait = 0;
    if (typeof Data.QteRefait === "string") {
      let Qte = Data.QteRefait.replace(",", ".");
      let FormatedQte = parseFloat(Data.QteRefait.replace(",", "."));
      if (isNaN(FormatedQte) || !/^\d*\.?\d*$/.test(Qte)) {
        alert("La qte n'est pas un nombre valide");
        throw new Error(
          "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
        );
      }
      newQteRefait = FormatedQte;
    } else {
      newQteRefait = Data.QteRefait;
    }

    if (
      Data.hasOwnProperty("RestaurantName") &&
      Data.hasOwnProperty("IDPrep") &&
      Data.hasOwnProperty("QteIngredientUsedForRefaire") &&
      Data.hasOwnProperty("QteRefait")
    ) {
    } else {
      alert("Il manque une informations dans le formulaire");
      throw new Error("Il manque une informations dans le formulaire");
    }

    const IDPepers = uuidv4();
    const collectionRef = doc(
      firestore,
      "RestaurantSainteFoyRefairePrep",
      IDPepers
    );

    const PrepRef = doc(firestore, "preparations", Data.IDPrep);
    const PrepSnapshoot = await getDoc(PrepRef);

    // On inscrit dans le document de la preparation l'id de cet evenement refait mais avant on verifie que les qte entree ne sont pas incoherente
    const CoefParRaportALaRecetteDeBase =
      PrepSnapshoot.data().QteFinalePrep / newQteRefait;

    newQteIngredientUsedForRefaire.forEach((IngredientUsed) => {
      const IDIngredient = Object.keys(IngredientUsed)[0];
      const QteIngredientUsed = IngredientUsed[IDIngredient];
      const QteIngredientUsedPourRefait =
        QteIngredientUsed * CoefParRaportALaRecetteDeBase;
      if (
        QteIngredientUsedPourRefait >
        PrepSnapshoot.data().MatierePremiereUtilisees.filter(
          (Ingredient) => Ingredient.IDIngredient === IDIngredient
        )[0].Qte *
          1.2
      ) {
        alert(
          `Incoherence ${QteIngredientUsedPourRefait} > ${
            PrepSnapshoot.data().MatierePremiereUtilisees.filter(
              (Ingredient) => Ingredient.IDIngredient === IDIngredient
            )[0].Qte * 1.2
          }`
        );
        throw new Error(
          `Incoherence ${QteIngredientUsedPourRefait} > ${
            PrepSnapshoot.data().MatierePremiereUtilisees.filter(
              (Ingredient) => Ingredient.IDIngredient === IDIngredient
            )[0].Qte * 1.2
          }`
        );
      }
      if (
        QteIngredientUsedPourRefait <
        PrepSnapshoot.data().MatierePremiereUtilisees.filter(
          (Ingredient) => Ingredient.IDIngredient === IDIngredient
        )[0].Qte /
          1.2
      ) {
        alert(
          `Incoherence ${QteIngredientUsedPourRefait} < ${
            PrepSnapshoot.data().MatierePremiereUtilisees.filter(
              (Ingredient) => Ingredient.IDIngredient === IDIngredient
            )[0].Qte * 1.2
          }`
        );
        throw new Error(
          `Incoherence ${QteIngredientUsedPourRefait} < ${
            PrepSnapshoot.data().MatierePremiereUtilisees.filter(
              (Ingredient) => Ingredient.IDIngredient === IDIngredient
            )[0].Qte * 1.2
          }`
        );
      }
    });

    let newListHistoryPrepRefait = [];
    if (
      PrepSnapshoot.exists() &&
      PrepSnapshoot.data().hasOwnProperty("HistoryPrepRefait")
    ) {
      newListHistoryPrepRefait = [
        ...PrepSnapshoot.data().HistoryPrepRefait,
        IDPepers,
      ];
    } else {
      newListHistoryPrepRefait = [IDPepers];
    }
    await updateDoc(PrepRef, { HistoryPrepRefait: newListHistoryPrepRefait });

    await setDoc(collectionRef, {
      RestaurantName: Data.RestaurantName,
      IDPrepRefait: Data.IDPrep,
      IDPepers,
      date: new Date(),
      IngredientUtilise: newQteIngredientUsedForRefaire,
      QteRefait: newQteRefait,
    });

    // On indique dans le daily activity l'ID du document de cet evenement refait prep
    const Today = new Date();
    const year = Today.getFullYear();
    const month = String(Today.getMonth() + 1).padStart(2, "0");
    const day = String(Today.getDate()).padStart(2, "0");
    const REFDocDailyActivity = `${day}${month}${year}`;

    const RefDailyActivity = doc(
      firestore,
      `${Data.RestaurantName}DailyActivities`,
      REFDocDailyActivity
    );
    const DailyActivitySnapshoot = await getDoc(RefDailyActivity);
    if (DailyActivitySnapshoot.exists()) {
      if (DailyActivitySnapshoot.data().hasOwnProperty("PreparationRefaite")) {
        await updateDoc(RefDailyActivity, {
          PreparationRefaite: [
            ...DailyActivitySnapshoot.data().PreparationRefaite,
            IDPepers,
          ],
        });
      } else {
        await updateDoc(RefDailyActivity, {
          PreparationRefaite: [IDPepers],
        });
      }
    } else {
      console.log(
        "Le DailyActivity pour aujourd'hui n'est pas encore creer, on va le creer"
      );
      await setDoc(RefDailyActivity, {
        PreparationRefaite: [IDPepers],
      });
    }

    // On met a jour le stock pour enlever les matieres premieres utilisee et pour ajouter la qte de preparation creer
    for (const IngredientUsed of newQteIngredientUsedForRefaire) {
      const IDIngredient = Object.keys(IngredientUsed)[0];
      const QteIngredientUsed = IngredientUsed[IDIngredient];
      const docRefStock = doc(firestore, `${Data.RestaurantName}`, "Stock");
      const StockSnapshoot = await getDoc(docRefStock);
      if (StockSnapshoot.exists()) {
        await updateDoc(docRefStock, {
          [IDIngredient]: {
            ...StockSnapshoot.data()[IDIngredient],
            Qte: StockSnapshoot.data()[IDIngredient].Qte - QteIngredientUsed,
          },
        });
      } else {
        throw "Le document stock n'existe pas";
      }
    }
    // On met a jour le stock pour ajouter la qte de prep refait
    const docRefStock = doc(firestore, `${Data.RestaurantName}`, "Stock");
    const StockSnapshoot = await getDoc(docRefStock);
    if (StockSnapshoot.exists()) {
      await updateDoc(docRefStock, {
        [Data.IDPrep]: {
          ...StockSnapshoot.data()[Data.IDPrep],
          Qte: StockSnapshoot.data()[Data.IDPrep].Qte + newQteRefait,
        },
      });
    } else {
      throw "Le document stock n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function HandleSavePrep(Data) {
  try {
    // on verifie si la qte entree est bien un nombre
    console.log(Data);
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function HandleOrderIngredient(Data) {
  try {
    const ListeIngredients = Object.keys(Data.QteIngredientOrder);
    let newQteIngredientOrder = [];
    for (const IngredientID of ListeIngredients) {
      if (typeof Data.QteIngredientOrder[IngredientID] === "string") {
        let Qte = Data.QteIngredientOrder[IngredientID].replace(",", ".");
        let FormatedQte = parseFloat(
          Data.QteIngredientOrder[IngredientID].replace(",", ".")
        );
        if (isNaN(FormatedQte) || !/^\d*\.?\d*$/.test(Qte)) {
          alert("La qte n'est pas un nombre valide");
          throw new Error(
            "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
          );
        }
        newQteIngredientOrder = [
          ...newQteIngredientOrder,
          { IDIngredient: IngredientID, QteCommande: FormatedQte },
        ];
      } else {
        newQteIngredientOrder = [
          ...newQteIngredientOrder,
          {
            IDIngredient: IngredientID,
            QteCommande: Data.QteIngredientOrder[IngredientID],
          },
        ];
      }
    }
    //On recupere la matiere par default pour chcun des ingredients
    let ListMatireACommanderWithQtePerFournisseur = {};
    for (const IngredientOrder of newQteIngredientOrder) {
      const IngredientRef = doc(
        firestore,
        "Ingredients",
        IngredientOrder.IDIngredient
      );
      const IngredientSnapshoot = await getDoc(IngredientRef);
      let MatiereData;
      if (IngredientSnapshoot.exists()) {
        let IngredientData = IngredientSnapshoot.data();
        if (IngredientData.IdREFfournisseurParDefault !== "") {
          const MatiereRef = doc(
            firestore,
            "ReferenceMatierePremiere",
            IngredientData.IdREFfournisseurParDefault
          );
          const MatiereSnapshoot = await getDoc(MatiereRef);
          if (MatiereSnapshoot.exists()) {
            MatiereData = MatiereSnapshoot.data();
            if (
              ListMatireACommanderWithQtePerFournisseur.hasOwnProperty(
                MatiereData.FournisseurID
              )
            ) {
              ListMatireACommanderWithQtePerFournisseur[
                MatiereData.FournisseurID
              ] = [
                ...ListMatireACommanderWithQtePerFournisseur[
                  MatiereData.FournisseurID
                ],
                { ...MatiereData, QteCommande: IngredientOrder.QteCommande },
              ];
            } else {
              ListMatireACommanderWithQtePerFournisseur[
                MatiereData.FournisseurID
              ] = [
                { ...MatiereData, QteCommande: IngredientOrder.QteCommande },
              ];
            }
          } else {
            throw "Le document de la matiere par default n'existe pas";
          }
        } else {
          throw "L'ingredient n'a pas de reference fournisseur par default";
        }
      } else {
        throw "Le document de l'ingredient n'existe pas";
      }
    }
    const ListFournisseurToOrder = Object.keys(
      ListMatireACommanderWithQtePerFournisseur
    );
    for (const FournisseurID of ListFournisseurToOrder) {
      const FormatedOrderOfTheFournisseur = [];
      let PrixDeLaCommande = 0;
      ListMatireACommanderWithQtePerFournisseur[FournisseurID].forEach(
        (RefOrder) => {
          if (RefOrder.QteCommande > 0) {
            PrixDeLaCommande =
              PrixDeLaCommande + RefOrder.PrixHT * RefOrder.QteCommande;
            FormatedOrderOfTheFournisseur.push({
              IDMatiere: RefOrder.IDPepers,
              QteOrder: RefOrder.QteCommande,
            });
          }
        }
      );
      const IDPepers = uuidv4();
      const collectionRef = doc(
        firestore,
        `${Data.RestaurantName}CommandeMatiere`,
        IDPepers
      );
      if (FormatedOrderOfTheFournisseur.length > 0) {
        await setDoc(collectionRef, {
          RestaurantName: Data.RestaurantName,
          PrixDeLaCommande,
          FournisseurID: FournisseurID,
          IDPepers,
          MatiereOrder: FormatedOrderOfTheFournisseur,
          DeliveryState: "EnCoursDeLivraison",
          date: new Date(),
        });
        const RefListeCommandeEnCoursForTheRestaurant = doc(
          firestore,
          Data.RestaurantName,
          "CommandeMatiereEnCoursDeLivraison"
        );
        const CommandeEnCoursSnapshoot = await getDoc(
          RefListeCommandeEnCoursForTheRestaurant
        );
        const PrevCommandeEnCours =
          CommandeEnCoursSnapshoot.data().EnCoursDeLivraison;
        await updateDoc(RefListeCommandeEnCoursForTheRestaurant, {
          EnCoursDeLivraison: [...PrevCommandeEnCours, IDPepers],
        });
        // on ajoute indique d'une nouvelle commande a ete passe dans le dailyActivity
        const Today = new Date();
        const year = Today.getFullYear();
        const month = String(Today.getMonth() + 1).padStart(2, "0");
        const day = String(Today.getDate()).padStart(2, "0");
        const REFDocDailyActivity = `${day}${month}${year}`;

        const RefDailyActivity = doc(
          firestore,
          `${Data.RestaurantName}DailyActivities`,
          REFDocDailyActivity
        );
        const DailyActivitySnapshoot = await getDoc(RefDailyActivity);
        if (DailyActivitySnapshoot.exists()) {
          if (
            DailyActivitySnapshoot.data().hasOwnProperty(
              "CommandeMatierePassee"
            )
          ) {
            await updateDoc(RefDailyActivity, {
              CommandeMatierePassee: [
                ...DailyActivitySnapshoot.data().CommandeMatierePassee,
                IDPepers,
              ],
            });
          } else {
            await updateDoc(RefDailyActivity, {
              CommandeMatierePassee: [IDPepers],
            });
          }
        } else {
          console.log(
            "Le DailyActivity pour aujourd'hui n'est pas encore creer, on va le creer"
          );
          await setDoc(RefDailyActivity, { CommandeMatierePassee: [IDPepers] });
        }
      } else {
        console.log("La commande est vide");
      }
    }
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function getActiveIngredientWithMatiereRef(RestaurantName) {
  try {
    const docRefIngredients = doc(
      firestore,
      RestaurantName,
      "ActifProductInTheRestaurant"
    );
    const docSnapshotIngredientsActif = await getDoc(docRefIngredients);
    if (docSnapshotIngredientsActif.exists()) {
      const IngredientActif =
        docSnapshotIngredientsActif.data().ActiveIngredient;

      let ActifIngredientObject = [];
      for (const IDIngredient of IngredientActif) {
        const docRefIngredients = doc(firestore, "Ingredients", IDIngredient);
        const docSnapshotIngredients = await getDoc(docRefIngredients);
        if (docSnapshotIngredients.exists()) {
          const docRefMatiereref = doc(
            firestore,
            "ReferenceMatierePremiere",
            docSnapshotIngredients.data().IdREFfournisseurParDefault
          );
          const MatiererefSnapshoot = await getDoc(docRefMatiereref);
          const MatiereRefDefault = MatiererefSnapshoot.data();

          ActifIngredientObject.push({
            ...docSnapshotIngredients.data(),
            MatiereRefDefault,
          });
        } else {
          throw "Document n'existe pas";
        }
      }
      return ActifIngredientObject;
    } else {
      throw "Document n'existe pas";
    }
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}
export async function getActivePreparationInRestaurant(RestaurantName) {
  try {
    const docRefPrep = doc(
      firestore,
      RestaurantName,
      "ActifProductInTheRestaurant"
    );
    const docSnapshotPrep = await getDoc(docRefPrep);
    if (docSnapshotPrep.exists()) {
      const PrepActif = docSnapshotPrep.data().ActivePrep;

      let ActifPrepObject = [];
      for (const IDPrep of PrepActif) {
        const docRefPrep = doc(firestore, "preparations", IDPrep);
        const docSnapshotPrep = await getDoc(docRefPrep);
        if (docSnapshotPrep.exists()) {
          ActifPrepObject.push({
            ...docSnapshotPrep.data(),
          });
        } else {
          throw "Document n'existe pas";
        }
      }
      return ActifPrepObject;
    } else {
      throw "Document n'existe pas";
    }
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}
export async function HandleFaireInventaire(Data) {
  try {
    const ListeIngredients = Object.keys(Data.QteIngredientInventaire);
    let newQteIngredientInventaire = [];
    for (const IngredientID of ListeIngredients) {
      if (typeof Data.QteIngredientInventaire[IngredientID] === "string") {
        let Qte = Data.QteIngredientInventaire[IngredientID].replace(",", ".");
        let FormatedQte = parseFloat(
          Data.QteIngredientInventaire[IngredientID].replace(",", ".")
        );
        if (isNaN(FormatedQte) || !/^\d*\.?\d*$/.test(Qte)) {
          alert("La qte n'est pas un nombre valide");
          throw new Error(
            "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
          );
        }
        newQteIngredientInventaire = [
          ...newQteIngredientInventaire,
          { IDIngredient: IngredientID, QteInventaire: FormatedQte },
        ];
      } else {
        newQteIngredientInventaire = [
          ...newQteIngredientInventaire,
          {
            IDIngredient: IngredientID,
            QteInventaire: Data.QteIngredientInventaire[IngredientID],
          },
        ];
      }
    }

    //On fait la meme chose pour les preparation
    const ListePrep = Object.keys(Data.QtePrepInventaire);
    let newQtePrepInventaire = [];
    for (const PrepID of ListePrep) {
      if (typeof Data.QtePrepInventaire[PrepID] === "string") {
        let Qte = Data.QtePrepInventaire[PrepID].replace(",", ".");
        let FormatedQte = parseFloat(
          Data.QtePrepInventaire[PrepID].replace(",", ".")
        );
        if (isNaN(FormatedQte) || !/^\d*\.?\d*$/.test(Qte)) {
          alert("La qte n'est pas un nombre valide");
          throw new Error(
            "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
          );
        }
        newQtePrepInventaire = [
          ...newQtePrepInventaire,
          { IDPrep: PrepID, QteInventaire: FormatedQte },
        ];
      } else {
        newQtePrepInventaire = [
          ...newQtePrepInventaire,
          {
            IDPrep: PrepID,
            QteInventaire: Data.QtePrepInventaire[PrepID],
          },
        ];
      }
    }

    //On met a jour le stock Ingredient
    for (const InventaireIngredient of newQteIngredientInventaire) {
      const docRefStock = doc(firestore, `${Data.RestaurantName}`, "Stock");
      const StockSnapshoot = await getDoc(docRefStock);
      if (StockSnapshoot.exists()) {
        await updateDoc(docRefStock, {
          [InventaireIngredient.IDIngredient]: {
            ...StockSnapshoot.data()[InventaireIngredient.IDIngredient],
            Qte: InventaireIngredient.QteInventaire,
          },
        });
      } else {
        throw "Le document stock n'existe pas";
      }
    }

    //On met a jour le stock Prep
    for (const InventairePrep of newQtePrepInventaire) {
      const docRefStock = doc(firestore, `${Data.RestaurantName}`, "Stock");
      const StockSnapshoot = await getDoc(docRefStock);
      if (StockSnapshoot.exists()) {
        await updateDoc(docRefStock, {
          [InventairePrep.IDPrep]: {
            ...StockSnapshoot.data()[InventairePrep.IDPrep],
            Qte: InventairePrep.QteInventaire,
          },
        });
      } else {
        throw "Le document stock n'existe pas";
      }
    }

    const IDPepers = uuidv4();
    const docRefInventaire = doc(
      firestore,
      `${Data.RestaurantName}Inventaire`,
      IDPepers
    );

    await setDoc(docRefInventaire, {
      RestaurantName: Data.RestaurantName,
      IDPepers,
      InventaireIngredient: newQteIngredientInventaire,
      InventairePreparation: newQtePrepInventaire,
      UniteDeMesureQteIngredient: "UniteDeRecette",
      UniteDeMesureQtePrep: "UniteDeMesure",
      date: new Date(),
    });
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function getQteIngredientUsedInPrep(
  IDPrep,
  RestaurantName,
  QtePrep
) {
  try {
    const docRefPrep = doc(firestore, "preparations", IDPrep);
    const PrepSnapshoot = await getDoc(docRefPrep);
    const IngredientConssome = [];
    if (PrepSnapshoot.exists()) {
      let PrepData = PrepSnapshoot.data();
      if (PrepData.hasOwnProperty("HistoryPrepRefait")) {
        console.log(
          "La preparation a ete refait il faut prendre les ingredient reel utilisé dans la preparation"
        );
        let DataPrepRefait = {};
        let prepRefaiteIsFromTheRestaurantName = false;
        let i = 1;
        while (prepRefaiteIsFromTheRestaurantName === false) {
          let IDPreprefaitToCheck =
            PrepData.HistoryPrepRefait[PrepData.HistoryPrepRefait.length - i];
          const docRefPrepRefait = doc(
            firestore,
            `${RestaurantName}RefairePrep`,
            IDPreprefaitToCheck
          );
          const PrepRefaitSnapshoot = await getDoc(docRefPrepRefait);
          if (PrepRefaitSnapshoot.exists()) {
            if (PrepRefaitSnapshoot.data().RestaurantName === RestaurantName) {
              prepRefaiteIsFromTheRestaurantName = true;
              DataPrepRefait = PrepRefaitSnapshoot.data();
              prepRefaiteIsFromTheRestaurantName = true;
            } else {
              i++;
            }
          } else {
            console.warn(`Document PREP refait does not exist.`);
            throw `Document PREP refait does not exist.`;
          }
        }
        for (const IngredientUsed of DataPrepRefait.IngredientUtilise) {
          let IDIngredient = Object.keys(IngredientUsed)[0];
          const docRef = doc(firestore, "Ingredients", IDIngredient);
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            IngredientConssome.push({
              ...docSnapshot.data(),
              QteUsedInPrep:
                IngredientUsed[IDIngredient] *
                (QtePrep / DataPrepRefait.QteRefait),
            });
          } else {
            console.warn(
              `Document with ID ${IngredientUsed.IDIngredient} does not exist.`
            );
          }
        }
      } else {
        for (const IngredientUsed of PrepData.MatierePremiereUtilisees) {
          const docRef = doc(
            firestore,
            "Ingredients",
            IngredientUsed.IDIngredient
          );
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            IngredientConssome.push({
              ...docSnapshot.data(),
              QteUsedInPrep:
                IngredientUsed.Qte * (QtePrep / PrepData.QteFinalePrep),
            });
          } else {
            console.warn(
              `Document with ID ${IngredientUsed.IDIngredient} does not exist.`
            );
          }
        }
      }
      return IngredientConssome;
    } else {
      console.warn(`Document PREP does not exist.`);
      throw `Document PREP does not exist.`;
    }
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}

export async function GetConssomationMatiereEntreDeuxInventaires(
  IDInventaireStart,
  IDInventaireEnd,
  RestaurantName
) {
  try {
    const InventaireStartDocRef = doc(
      firestore,
      `${RestaurantName}Inventaire`,
      IDInventaireStart
    );
    const InventaireStartSnapshot = await getDoc(InventaireStartDocRef);
    const InventaireStart = InventaireStartSnapshot.data();

    const InventaireEndDocRef = doc(
      firestore,
      `${RestaurantName}Inventaire`,
      IDInventaireEnd
    );
    const InventaireEndSnapshot = await getDoc(InventaireEndDocRef);
    const InventaireEnd = InventaireEndSnapshot.data();

    if (InventaireStart.date.seconds >= InventaireEnd.date.seconds) {
      alert(
        "L'enventaire du debut est a une date superieur ou egale a celui de fin"
      );
      throw "L'enventaire du debut est a une date superieur ou egale a celui de fin";
    }
    const dateStart = formatDate(new Date(InventaireStart.date.seconds * 1000));
    const dateEnd = formatDate(new Date(InventaireEnd.date.seconds * 1000));
    const ComptageIngredientRecu = await GetIngredientMisEnStockEntreDeuxDate(
      RestaurantName,
      dateStart,
      dateEnd
    );
    if (ComptageIngredientRecu === false) {
      throw "Erreur dans la recuperation des ingredients mis en stock";
    }

    // On recupere l'ensemble des ingredients present dans l'inventaire de debut et de fin
    let IngredientIDInventaire = [];
    InventaireStart.InventaireIngredient.forEach((ingredient) => {
      IngredientIDInventaire.push(ingredient.IDIngredient);
    });
    InventaireEnd.InventaireIngredient.forEach((ingredient) => {
      IngredientIDInventaire.push(ingredient.IDIngredient);
    });
    IngredientIDInventaire = [...new Set(IngredientIDInventaire)];
    // On recupere l'ensemble des preparations present dans l'inventaire de debut et de fin

    const QteConssomeIngredient = {};
    for (const ID of IngredientIDInventaire) {
      let QteInventaireStartIngredient =
        InventaireStart.InventaireIngredient.filter(
          (ingredient) => ingredient.IDIngredient === ID
        );
      if (QteInventaireStartIngredient.length === 0) {
        console.log("L'ingredient n'existe pas dans l'inventaire de debut");
        QteInventaireStartIngredient = 0;
      } else {
        QteInventaireStartIngredient =
          QteInventaireStartIngredient[0].QteInventaire;
      }

      let QteInventaireEndIngredient =
        InventaireEnd.InventaireIngredient.filter(
          (ingredient) => ingredient.IDIngredient === ID
        );
      if (QteInventaireEndIngredient.length === 0) {
        console.log("L'ingredient n'existe pas dans l'inventaire de fin");
        QteInventaireEndIngredient = 0;
      } else {
        QteInventaireEndIngredient =
          QteInventaireEndIngredient[0].QteInventaire;
      }
      let QteIngredientRecu = 0;
      if (ComptageIngredientRecu.hasOwnProperty(ID)) {
        QteIngredientRecu = ComptageIngredientRecu[ID];
      }
      let QteConssome =
        QteInventaireStartIngredient +
        QteIngredientRecu -
        QteInventaireEndIngredient;
      QteConssomeIngredient[ID] = parseFloat(QteConssome.toFixed(2));
    }
    // On recupere l'ensemble des preparations present dans l'inventaire de debut et de fin
    let PreparationIDInventaire = [];
    InventaireStart.InventairePreparation.forEach((Preparation) => {
      PreparationIDInventaire.push(Preparation.IDPrep);
    });
    InventaireEnd.InventairePreparation.forEach((Preparation) => {
      PreparationIDInventaire.push(Preparation.IDPrep);
    });
    PreparationIDInventaire = [...new Set(PreparationIDInventaire)];

    // Pour chacune des preparations on met a jours la conssomation des matieres premieres on doit ajouter les matiere premiere des preparation de l'inventaire du debut et soustraire celle de l'inventaire de fin
    for (const IDPrep of PreparationIDInventaire) {
      let QteInventaireStartPrep = InventaireStart.InventairePreparation.filter(
        (Preparation) => Preparation.IDPrep === IDPrep
      );
      if (QteInventaireStartPrep.length === 0) {
        console.log("La preparation n'existe pas dans l'inventaire de debut");
        QteInventaireStartPrep = 0;
      } else {
        QteInventaireStartPrep = QteInventaireStartPrep[0].QteInventaire;

        let QteInventaireEndPrep = InventaireEnd.InventairePreparation.filter(
          (Preparation) => Preparation.IDPrep === IDPrep
        );
        if (QteInventaireEndPrep.length === 0) {
          console.log("La preparation n'existe pas dans l'inventaire de fin");
          QteInventaireEndPrep = 0;
        } else {
          QteInventaireEndPrep = QteInventaireEndPrep[0].QteInventaire;
        }
        //Qte Prep a aditionner dans le comptage des ingredients
        let DifferenceQtePrep = QteInventaireStartPrep - QteInventaireEndPrep;

        // On regarde les matieres premieres utilisees dans la preparation pour cette qte de preparation en prenant en compte la valeur des ingredients mis dans la preparation refaite
        const QteIngredientInPrep = await getQteIngredientUsedInPrep(
          IDPrep,
          RestaurantName,
          DifferenceQtePrep
        );
        // Pour chacune des matieres premieres utilisees dans la preparation on aditionne la qte utilisee dans le comptage des ingredients
        for (const IngredientUsed of QteIngredientInPrep) {
          let IDIngredient = IngredientUsed.IDPepers;
          let QteUsedInPrep = IngredientUsed.QteUsedInPrep;
          if (QteConssomeIngredient.hasOwnProperty(IDIngredient)) {
            QteConssomeIngredient[IDIngredient] += QteUsedInPrep;
          } else {
            throw "L'ingredient n'existe pas dans le comptage des ingredients fait a partir des deux inventaires";
          }
        }
      }
    }
    // On regarde la conssomation theorique des ingredients
    const conssoTheoriqueIngredient = await getDailyConssomationIngredient(
      RestaurantName,
      dateStart,
      dateEnd
    );
    let conssoTheoriqueIngredientObject = {};
    conssoTheoriqueIngredient.forEach((ingredient) => {
      let IDIngredient = ingredient.IDPepers;
      let QteConssomeTheorique = ingredient.QteConssome;
      if (conssoTheoriqueIngredientObject.hasOwnProperty(IDIngredient)) {
        conssoTheoriqueIngredientObject[IDIngredient] += QteConssomeTheorique;
      } else {
        conssoTheoriqueIngredientObject[IDIngredient] = QteConssomeTheorique;
      }
    });

    let IngredientObjectConssomation = {};
    for (const IDIngredient of Object.keys(QteConssomeIngredient)) {
      let ConssomationTheorique = 0;
      if (conssoTheoriqueIngredientObject.hasOwnProperty(IDIngredient)) {
        ConssomationTheorique = conssoTheoriqueIngredientObject[IDIngredient];
      }
      let EcartConssomation =
        QteConssomeIngredient[IDIngredient] - ConssomationTheorique;
      const refIngredient = doc(firestore, "Ingredients", IDIngredient);
      const IngredientSnapshoot = await getDoc(refIngredient);
      if (IngredientSnapshoot.exists()) {
        IngredientObjectConssomation[IDIngredient] = {
          ...IngredientSnapshoot.data(),
          ConssomationMatiere: QteConssomeIngredient[IDIngredient],
          ConssomationTheorique,
          EcartConssomation,
        };
      } else {
        alert("Attention l'ingredient n'existe pas");
        throw "Attention l'ingredient n'existe pas";
      }
    }

    return IngredientObjectConssomation;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function getAllInventaire(RestaurantName) {
  const querySnapshot = await getDocs(
    collection(firestore, `${RestaurantName}Inventaire`)
  );
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data() });
  });

  return documents;
}
export async function getMatiereOrderDataWithMatiereData(
  RestaurantName,
  CommandeMatiereID
) {
  try {
    const docRefCommandeMatiere = doc(
      firestore,
      `${RestaurantName}CommandeMatiere`,
      CommandeMatiereID
    );
    const docSnapshotCommandeMatiere = await getDoc(docRefCommandeMatiere);
    if (docSnapshotCommandeMatiere.exists()) {
      const MatiereCommandee = docSnapshotCommandeMatiere.data().MatiereOrder;

      let MatiereObject = [];
      for (const Matiere of MatiereCommandee) {
        const docRefMatiere = doc(
          firestore,
          "ReferenceMatierePremiere",
          Matiere.IDMatiere
        );
        const docSnapshotMatiere = await getDoc(docRefMatiere);
        if (docSnapshotMatiere.exists()) {
          MatiereObject.push({
            ...docSnapshotMatiere.data(),
            QteOrder: Matiere.QteOrder,
          });
        } else {
          throw "Document n'existe pas";
        }
      }
      return {
        ...docSnapshotCommandeMatiere.data(),
        MatiereOrder: MatiereObject,
      };
    } else {
      throw "Document n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function HandleValidateMatiereOrder(Data) {
  console.log(Data);
  try {
    const ListeMatiereOrder = Object.keys(Data.QteMatiereOrder);
    let FormatedQteMatiereOrder = [];
    for (const MatiereID of ListeMatiereOrder) {
      if (typeof Data.QteMatiereOrder[MatiereID] === "string") {
        let Qte = Data.QteMatiereOrder[MatiereID].replace(",", ".");
        let FormatedQte = parseFloat(
          Data.QteMatiereOrder[MatiereID].replace(",", ".")
        );
        if (isNaN(FormatedQte) || !/^\d*\.?\d*$/.test(Qte)) {
          alert("La qte n'est pas un nombre valide");
          throw new Error(
            "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
          );
        }
        FormatedQteMatiereOrder = [
          ...FormatedQteMatiereOrder,
          { IDMatiere: MatiereID, QteRecu: FormatedQte },
        ];
      } else {
        FormatedQteMatiereOrder = [
          ...FormatedQteMatiereOrder,
          {
            IDMatiere: MatiereID,
            QteRecu: Data.QteMatiereOrder[MatiereID],
          },
        ];
      }
    }
    console.log(FormatedQteMatiereOrder);
    //On met a jour le stock Ingredient
    for (const MatiereRecu of FormatedQteMatiereOrder) {
      const docRefStock = doc(firestore, `${Data.RestaurantName}`, "Stock");
      const StockSnapshoot = await getDoc(docRefStock);
      if (StockSnapshoot.exists()) {
        const docRefMatiere = doc(
          firestore,
          "ReferenceMatierePremiere",
          MatiereRecu.IDMatiere
        );
        const MatiereSnapshoot = await getDoc(docRefMatiere);
        if (MatiereSnapshoot.exists()) {
          const QteEnUniteDeRecette =
            MatiereSnapshoot.data().QteConditionnement * MatiereRecu.QteRecu;
          if (
            StockSnapshoot.data().hasOwnProperty(
              MatiereSnapshoot.data().IngredientID
            )
          ) {
            await updateDoc(docRefStock, {
              [MatiereSnapshoot.data().IngredientID]: {
                ...StockSnapshoot.data()[MatiereSnapshoot.data().IngredientID],
                Qte:
                  StockSnapshoot.data()[MatiereSnapshoot.data().IngredientID]
                    .Qte + QteEnUniteDeRecette,
              },
            });
          } else {
            alert(
              `L'ingredient ${
                MatiereSnapshoot.data().IngredientID
              } n'existe pas dans le stock. Il a peut etre ete enlevé entre temps`
            );
            throw `L'ingredient ${
              MatiereSnapshoot.data().IngredientID
            } n'existe pas dans le stock. Il a peut etre ete enlevé entre temps`;
          }
        } else {
          alert("La matiere n'existe pas");
          throw "La matiere n'existe pas";
        }
      } else {
        throw "Le document stock n'existe pas";
      }
    }

    // On met a jours la commande matiere pour dire qu'elle a bien ete mis en stock et On regenere la propriete MatiereOrder avec la qte de matiere recu
    const docRefCommandeMatiere = doc(
      firestore,
      `${Data.RestaurantName}CommandeMatiere`,
      Data.CommandeMatiereID
    );
    const CommandeMatiereSnapshoot = await getDoc(docRefCommandeMatiere);
    if (CommandeMatiereSnapshoot.exists()) {
      let NewMatiereOrder = CommandeMatiereSnapshoot.data().MatiereOrder.map(
        (MatiereOrdered) => {
          let QteRecu = FormatedQteMatiereOrder.filter(
            (matiereRecu) => matiereRecu.IDMatiere === MatiereOrdered.IDMatiere
          );
          return { ...MatiereOrdered, QteRecu: QteRecu[0].QteRecu };
        }
      );
      await updateDoc(docRefCommandeMatiere, {
        MisEnStockLe: new Date(),
        MatiereOrder: NewMatiereOrder,
        DeliveryState: "Livree",
      });
    } else {
      throw "La commande matiere n'exsite pas";
    }
    // On enleve la commande matiere de la liste des commandes en atente de livraion
    const docRefCommandeEnLivraison = doc(
      firestore,
      `${Data.RestaurantName}`,
      "CommandeMatiereEnCoursDeLivraison"
    );
    const SnapshootCommandeEnLivraison = await getDoc(
      docRefCommandeEnLivraison
    );
    const DataEnLivraison =
      SnapshootCommandeEnLivraison.data().EnCoursDeLivraison;

    const newListeCommandeID = DataEnLivraison.filter(
      (commande) => commande !== Data.CommandeMatiereID
    );
    await updateDoc(docRefCommandeEnLivraison, {
      EnCoursDeLivraison: newListeCommandeID,
    });

    // On indique dans le daily activity que cette commande a ete recu et mise en stock
    const Today = new Date();
    const year = Today.getFullYear();
    const month = String(Today.getMonth() + 1).padStart(2, "0");
    const day = String(Today.getDate()).padStart(2, "0");
    const REFDocDailyActivity = `${day}${month}${year}`;

    const RefDailyActivity = doc(
      firestore,
      `${Data.RestaurantName}DailyActivities`,
      REFDocDailyActivity
    );
    const DailyActivitySnapshoot = await getDoc(RefDailyActivity);
    if (DailyActivitySnapshoot.exists()) {
      if (
        DailyActivitySnapshoot.data().hasOwnProperty(
          "CommandeMatiereMiseEnStock"
        )
      ) {
        await updateDoc(RefDailyActivity, {
          CommandeMatiereMiseEnStock: [
            ...DailyActivitySnapshoot.data().CommandeMatiereMiseEnStock,
            Data.CommandeMatiereID,
          ],
        });
      } else {
        await updateDoc(RefDailyActivity, {
          CommandeMatiereMiseEnStock: [Data.CommandeMatiereID],
        });
      }
    } else {
      console.log(
        "Le DailyActivity pour aujourd'hui n'est pas encore creer, on va le creer"
      );
      await setDoc(RefDailyActivity, {
        CommandeMatiereMiseEnStock: [Data.CommandeMatiereID],
      });
    }
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function GetGestionPreparationData(RestaurantName) {
  try {
    // On recupere toute les preparations qui doivent etre activent au restaurant
    const ActifProductDocRef = doc(
      firestore,
      `${RestaurantName}`,
      "ActifProductInTheRestaurant"
    );
    const ActifProductSnapshot = await getDoc(ActifProductDocRef);
    const ActifPrepData = ActifProductSnapshot.data().ActivePrep;

    let PrepDataForGestion = [];
    for (const IDPrepActive of ActifPrepData) {
      const PrepDocRef = doc(firestore, "preparations", IDPrepActive);
      const PrepSnapshot = await getDoc(PrepDocRef);
      if (PrepSnapshot.exists()) {
        let lasteRefaitDate;
        let lasteRefaitQte;
        let UniteDeMesure = PrepSnapshot.data().UniteDeMesure;
        if (PrepSnapshot.data().hasOwnProperty("HistoryPrepRefait")) {
          let lastPrepRefaitEventID =
            PrepSnapshot.data().HistoryPrepRefait[
              PrepSnapshot.data().HistoryPrepRefait.length - 1
            ];
          const PrepDocRef = doc(
            firestore,
            `${RestaurantName}RefairePrep`,
            lastPrepRefaitEventID
          );
          const PreprefaitSnapshoot = await getDoc(PrepDocRef);
          if (PreprefaitSnapshoot.exists()) {
            lasteRefaitDate = new Date(
              PreprefaitSnapshoot.data().date.seconds * 1000
            );
            lasteRefaitQte = PreprefaitSnapshoot.data().QteRefait;
          }
        } else {
          lasteRefaitDate = new Date();
          lasteRefaitQte = 0;
        }
        // On regarde dans le stock la qte theorique depuis le dernier inventaire
        const StockDocRef = doc(firestore, RestaurantName, "Stock");
        const StockSnapshoot = await getDoc(StockDocRef);
        let QteTheoriqueStock;
        if (StockSnapshoot.exists()) {
          QteTheoriqueStock = StockSnapshoot.data()[IDPrepActive].Qte;
        } else {
          QteTheoriqueStock = 0;
        }
        const data = {
          IDPrep: IDPrepActive,
          name: PrepSnapshot.data().name,
          img: PrepSnapshot.data().img,
          lasteRefaitDate,
          lasteRefaitQte,
          UniteDeMesure,
          QteTheoriqueStock,
          QteJete: 200,
        };
        PrepDataForGestion.push(data);
      }
    }
    return PrepDataForGestion;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function GetGestionMatiereOrderData(RestaurantName) {
  try {
    // On recupere toute les preparations qui doivent etre activent au restaurant

    const querySnapshot = await getDocs(
      collection(firestore, `${RestaurantName}CommandeMatiere`)
    );
    const CommandeMatiereData = [];
    querySnapshot.forEach((doc) => {
      CommandeMatiereData.push({ ...doc.data() });
    });
    // Trier les commandes par date (la plus ancienne d'abord)
    CommandeMatiereData.sort((a, b) => {
      const dateA = new Date(a.date.seconds * 1000);
      const dateB = new Date(b.date.seconds * 1000);
      return dateA - dateB;
    });

    return CommandeMatiereData;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function GetPrepRefaitForPrepID(PrepID, RestaurantName) {
  try {
    let PrepRefaitData = [];
    const PrepDocRef = doc(firestore, "preparations", PrepID);
    const PrepSnapshot = await getDoc(PrepDocRef);
    if (PrepSnapshot.exists()) {
      if (PrepSnapshot.data().hasOwnProperty("HistoryPrepRefait")) {
        for (const IDEventRefairePrep of PrepSnapshot.data()
          .HistoryPrepRefait) {
          const PrepDocRef = doc(
            firestore,
            `${RestaurantName}RefairePrep`,
            IDEventRefairePrep
          );
          const PreprefaitSnapshoot = await getDoc(PrepDocRef);
          if (PreprefaitSnapshoot.exists()) {
            PrepRefaitData.push({ ...PreprefaitSnapshoot.data() });
          }
        }
      } else {
        console.log("La preparation n'a pas encore ete refait");
      }
    }

    return PrepRefaitData;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function getIngredientObjectUsedInPrepRefaitWithIDPrepRefait(
  PrepRefaitID,
  RestaurantName
) {
  try {
    let IngredientUsedInRefaitWithQteUsed = [];
    const PrepRefaitDocRef = doc(
      firestore,
      `${RestaurantName}RefairePrep`,
      PrepRefaitID
    );
    const PrepRefaitSnapshot = await getDoc(PrepRefaitDocRef);
    if (PrepRefaitSnapshot.exists()) {
      const PrepDocRef = doc(
        firestore,
        "preparations",
        PrepRefaitSnapshot.data().IDPrepRefait
      );
      const PrepSnapshot = await getDoc(PrepDocRef);
      if (PrepSnapshot.exists()) {
        for (const IngredientUsed of PrepRefaitSnapshot.data()
          .IngredientUtilise) {
          const IngredientRef = doc(
            firestore,
            "Ingredients",
            Object.keys(IngredientUsed)[0]
          );
          const IngredientSnapshoot = await getDoc(IngredientRef);
          if (IngredientSnapshoot.exists()) {
            let ratioQteRefaite =
              PrepRefaitSnapshot.data().QteRefait /
              PrepSnapshot.data().QteFinalePrep;

            let QteRecetteInitiale =
              PrepSnapshot.data().MatierePremiereUtilisees.filter(
                (Ingredient) =>
                  Ingredient.IDIngredient === Object.keys(IngredientUsed)[0]
              )[0].Qte * ratioQteRefaite;

            IngredientUsedInRefaitWithQteUsed.push({
              ...IngredientSnapshoot.data(),
              QteUsedInPrepRefait:
                IngredientUsed[Object.keys(IngredientUsed)[0]],
              QteRecetteInitiale,
              EcartConsso:
                IngredientUsed[Object.keys(IngredientUsed)[0]] -
                QteRecetteInitiale,
            });
          }
        }
      } else {
        throw "La preparation n'existe pas";
      }
    } else {
      throw "Le document de la preparation refait n'existe pas";
    }

    return IngredientUsedInRefaitWithQteUsed;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function UpdateIngredientInfo(Data) {
  try {
    const IngredientDocRef = doc(firestore, "Ingredients", Data.IDPepers);
    const IngredientSnapshot = await getDoc(IngredientDocRef);
    if (IngredientSnapshot.exists()) {
      await updateDoc(IngredientDocRef, {
        ...Data,
      });
    } else {
      throw "L'ingredient n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function getAllZeltyDish() {
  const querySnapshot = await getDocs(collection(firestore, `zeltyDishes`));
  const documents = [];
  querySnapshot.forEach((doc) => {
    documents.push({ ...doc.data() });
  });
  return documents;
}
export async function getAllActiveDishInrestaurant(RestaurantName) {
  const docRef = doc(
    firestore,
    `${RestaurantName}`,
    "ActifProductInTheRestaurant"
  );
  const docSnapshoot = await getDoc(docRef);
  if (docSnapshoot.exists()) {
    const ActifProductData = docSnapshoot.data().ActiveProduct;
    let AllActiveDish = [];
    for (const IDDish of ActifProductData) {
      const DishDocRef = doc(firestore, `dishes`, IDDish);
      const DishSnapshot = await getDoc(DishDocRef);
      if (DishSnapshot.exists()) {
        AllActiveDish.push({ ...DishSnapshot.data() });
      }
    }
    return AllActiveDish;
  }
}
export async function HandleAssociePeperDishtoZelty(Data) {
  try {
    const DishDocRef = doc(firestore, `dishes`, Data.IDDish);
    const DishSnapshot = await getDoc(DishDocRef);
    if (DishSnapshot.exists()) {
      const ZeltyDishDocRef = doc(
        firestore,
        "zeltyDishes",
        Data.ZeltyID.toString()
      );
      const ZeltyDishSnapshot = await getDoc(ZeltyDishDocRef);
      if (ZeltyDishSnapshot.exists()) {
        await updateDoc(DishDocRef, {
          ZeltyID: Data.ZeltyID.toString(),
        });

        await updateDoc(ZeltyDishDocRef, {
          IDPepers: Data.IDDish,
        });
      } else {
        throw "le produit zelty n'existe pas";
      }
    } else {
      throw "le produit pepers n'existe pas";
    }

    return true;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function getZeltyDishWithIDZelty(IDZelty) {
  try {
    const DishZeltyDocRef = doc(firestore, `zeltyDishes`, IDZelty);
    const DishZeltySnapshot = await getDoc(DishZeltyDocRef);
    let DishZeltyData;
    if (DishZeltySnapshot.exists()) {
      DishZeltyData = DishZeltySnapshot.data();
    } else {
      throw "le produit pepers n'existe pas";
    }
    return DishZeltyData;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function getDailyAnalyuseProduitVendu(
  RestaurantName,
  dateStart,
  dateEnd
) {
  try {
    let TableauIDDailyActivity = [];
    let DateDebut = new Date(dateStart);

    while (DateDebut.getTime() < new Date(dateEnd).getTime()) {
      const year = DateDebut.getFullYear();
      const month = String(DateDebut.getMonth() + 1).padStart(2, "0");
      const day = String(DateDebut.getDate()).padStart(2, "0");
      const DateFormated = `${day}${month}${year}`;
      TableauIDDailyActivity.push(DateFormated);
      DateDebut = new Date(DateDebut.getTime() + 24 * 60 * 60 * 1000);
    }

    let SumAllProdZeltySold = {};
    for (const DateDocument of TableauIDDailyActivity) {
      const DailyDocRef = doc(
        firestore,
        `${RestaurantName}DailyActivities`,
        DateDocument
      );
      const DailySnapshot = await getDoc(DailyDocRef);
      if (DailySnapshot.exists()) {
        const DailyData = DailySnapshot.data();
        let ListeAllProdVendu = [];
        if (DailyData.hasOwnProperty("ListeAllProdVendu")) {
          ListeAllProdVendu = Object.keys(DailyData.ListeAllProdVendu);
        } else {
          console.log(
            `Il n'y a pas de produit vendu pour le dailyActivitie : ${Date}`
          );
          alert(
            `Il n'y a pas de produit vendu pour le dailyActivitie : ${Date}`
          );
          throw `Il n'y a pas de produit vendu pour le dailyActivitie : ${Date}`;
        }
        for (const IDZelty of ListeAllProdVendu) {
          if (SumAllProdZeltySold.hasOwnProperty(IDZelty)) {
            SumAllProdZeltySold[IDZelty] +=
              DailyData.ListeAllProdVendu[IDZelty];
          } else {
            SumAllProdZeltySold[IDZelty] = DailyData.ListeAllProdVendu[IDZelty];
          }
        }
      } else {
        alert(
          `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`
        );
        console.log(
          `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`
        );

        throw `Il n'y a pas de conssomation theorique d'ingredient pour le dailyActivitie : ${DateDocument}`;
      }
    }

    let ListeAllProdVenduData = [];
    for (const IDProd of Object.keys(SumAllProdZeltySold)) {
      const ProdZeltyDocRef = doc(firestore, `zeltyDishes`, IDProd);
      const ProdZeltySnapshot = await getDoc(ProdZeltyDocRef);
      if (ProdZeltySnapshot.exists()) {
        const ProdZeltyData = ProdZeltySnapshot.data();
        if (ProdZeltyData.hasOwnProperty("IDPepers")) {
          const ProdPepersDocRef = doc(
            firestore,
            `dishes`,
            ProdZeltyData.IDPepers
          );
          const ProdPepersSnapshot = await getDoc(ProdPepersDocRef);
          if (ProdPepersSnapshot.exists()) {
            const ProdPepersData = ProdPepersSnapshot.data();
            ListeAllProdVenduData.push({
              ...ProdPepersData,
              QteVendu: SumAllProdZeltySold[IDProd],
            });
          } else {
            throw "le produit Zelty n'existe pas dans la base de donnée";
          }
        } else {
          console.log(
            `le produit Zelty : ${IDProd} n'est pas relié a un produit pepers dans la base de donnée`
          );
        }
      } else {
        throw "le produit Zelty n'existe pas dans la base de donnée";
      }
    }

    return ListeAllProdVenduData.sort((a, b) => b.QteVendu - a.QteVendu);
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}

export async function getIngredientAndPrepObjectUsedInInventaire(
  RestaurantName,
  InventaireSelectedID
) {
  try {
    const InventaireRef = doc(
      firestore,
      `${RestaurantName}Inventaire`,
      InventaireSelectedID
    );
    const InventaireSnapshot = await getDoc(InventaireRef);
    let ListeIngredientObject = [];
    let ListePrepObject = [];
    if (InventaireSnapshot.exists()) {
      const InventaireData = InventaireSnapshot.data();
      for (const IngredientInventaire of InventaireData.InventaireIngredient) {
        const IngredientDocRef = doc(
          firestore,
          `Ingredients`,
          IngredientInventaire.IDIngredient
        );
        const IngredientSnapshot = await getDoc(IngredientDocRef);
        if (IngredientSnapshot.exists()) {
          const IngredientData = IngredientSnapshot.data();
          ListeIngredientObject.push({
            ...IngredientData,
            QteInventaire: IngredientInventaire.QteInventaire,
          });
        } else {
          alert("l'ingredient n'existe pas dans la base de donnée");
          throw "l'ingredient n'existe pas dans la base de donnée";
        }
      }
      for (const PrepInventaire of InventaireData.InventairePreparation) {
        const PrepDocRef = doc(
          firestore,
          `preparations`,
          PrepInventaire.IDPrep
        );
        const PrepSnapshot = await getDoc(PrepDocRef);
        if (PrepSnapshot.exists()) {
          const PrepData = PrepSnapshot.data();
          ListePrepObject.push({
            ...PrepData,
            QteInventaire: PrepInventaire.QteInventaire,
          });
        } else {
          alert("l'ingredient n'existe pas dans la base de donnée");
          throw "l'ingredient n'existe pas dans la base de donnée";
        }
      }
    } else {
      alert("Le document de l'inventaire n'existe pas");
      throw "Le document de l'inventaire n'existe pas";
    }
    return { ListeIngredientObject, ListePrepObject };
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function getPrepWhereIngredientIsUsed(IngredientID) {
  try {
    const PrepRef = collection(firestore, "preparations");
    const PrepSnapshoot = await getDocs(PrepRef);
    let ListePrep = [];
    PrepSnapshoot.forEach((doc) => {
      const PrepData = doc.data();
      for (const IngredientUsed of PrepData.MatierePremiereUtilisees) {
        if (IngredientUsed.IDIngredient === IngredientID) {
          ListePrep.push({ ...PrepData });
        }
      }
    });
    return ListePrep;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function getProductWherePrepIsUsed(PrepID) {
  try {
    const PrepRef = doc(firestore, `preparations`, PrepID);
    const PrepSnapshot = await getDoc(PrepRef);
    let ListeDish = [];
    if (PrepSnapshot.exists()) {
      let ListeDishWherePrepIsUSed = [];
      if (PrepSnapshot.data().hasOwnProperty("ListeDishWherePrepIsUsed")) {
        ListeDishWherePrepIsUSed = PrepSnapshot.data().ListeDishWherePrepIsUsed;
      } else {
        alert("La preparation n'est pas utilisé dans un produit");
        throw "La preparation n'est pas utilisé dans un produit";
      }

      for (const IDDish of ListeDishWherePrepIsUSed) {
        const dishesDocRef = doc(firestore, "dishes", IDDish);
        const dishesSnapshot = await getDoc(dishesDocRef);
        if (dishesSnapshot.exists()) {
          ListeDish.push({ ...dishesSnapshot.data() });
        } else {
          alert("Le produit n'existe pas");
          throw "Le produit n'existe pas";
        }
      }
    } else {
      alert("La preparation n'existe pas");
      throw "La preparation n'existe pas";
    }
    return ListeDish;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function GetAllFournisseur() {
  try {
    const FournisseursRef = doc(
      firestore,
      `ProductionVisitorV2`,
      "FournisseursVisitor"
    );
    const FournisseursSnapshot = await getDoc(FournisseursRef);
    let ListeFournisseur = [];
    if (FournisseursSnapshot.exists()) {
      ListeFournisseur = FournisseursSnapshot.data().Fournisseurs;
    } else {
      throw "Le document des fournisseurs n'existe pas";
    }
    return ListeFournisseur;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
export async function GetMatiereOrderDetail(RestaurantName, IDMatiereOrder) {
  try {
    const MatiereOrderDocRef = doc(
      firestore,
      `${RestaurantName}CommandeMatiere`,
      IDMatiereOrder
    );
    const MatiereOrderSnapshot = await getDoc(MatiereOrderDocRef);

    let ListeMatiereObject = [];
    if (MatiereOrderSnapshot.exists()) {
      let ListeMatiereOrderData = MatiereOrderSnapshot.data().MatiereOrder;
      for (const MatiereOrder of ListeMatiereOrderData) {
        const MatiereRef = doc(
          firestore,
          "ReferenceMatierePremiere",
          MatiereOrder.IDMatiere
        );
        const MatiereSnapshot = await getDoc(MatiereRef);
        if (MatiereSnapshot.exists()) {
          if (MatiereOrder.hasOwnProperty("QteRecu")) {
            ListeMatiereObject.push({
              ...MatiereSnapshot.data(),
              QteOrder: MatiereOrder.QteOrder,
              QteRecu: MatiereOrder.QteRecu,
            });
          } else {
            ListeMatiereObject.push({
              ...MatiereSnapshot.data(),
              QteOrder: MatiereOrder.QteOrder,
            });
          }
        } else {
          throw "La matiere n'existe pas";
        }
      }
    } else {
      throw "Le document n'existe pas";
    }
    return ListeMatiereObject;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
export async function UpdateOrCreateNewOption(OptionChoice, InfoOptionData) {
  try {
    if (OptionChoice.length === 0) {
      alert("Vous devez choisir des choix options");
      throw new Error("Vous devez choisir des choix options");
    }
    // On effectue les verifications pour les données de l'option
    let FormatedInfoOptionData = { name: InfoOptionData.name };
    if (
      !InfoOptionData.hasOwnProperty("max_choices") ||
      !InfoOptionData.hasOwnProperty("min_choices") ||
      InfoOptionData.min_choices === undefined
    ) {
      alert("Vous devez choisir min_choices et max_choices");
      throw new Error("Vous devez choisir min_choices et max_choices");
    }

    if (typeof InfoOptionData.min_choices === "string") {
      let min_choices = InfoOptionData.min_choices.replace(",", ".");
      let Formated_min_choices = parseFloat(
        InfoOptionData.min_choices.replace(",", ".")
      );

      if (isNaN(Formated_min_choices) || !/^\d*\.?\d*$/.test(min_choices)) {
        alert("La conversion n'a pas réussi. Ce n'est pas un nombre valide.");
        throw new Error(
          "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
        );
      }
      FormatedInfoOptionData.min_choices = Formated_min_choices;
    } else if (typeof InfoOptionData.min_choices === "number") {
      FormatedInfoOptionData.min_choices = InfoOptionData.min_choices;
    }

    if (typeof InfoOptionData.max_choices === "string") {
      let max_choices = InfoOptionData.max_choices.replace(",", ".");
      let Formated_max_choices = parseFloat(
        InfoOptionData.max_choices.replace(",", ".")
      );

      if (isNaN(Formated_max_choices) || !/^\d*\.?\d*$/.test(max_choices)) {
        alert("La conversion n'a pas réussi. Ce n'est pas un nombre valide.");
        throw new Error(
          "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
        );
      }
      FormatedInfoOptionData.max_choices = Formated_max_choices;
    } else if (typeof InfoOptionData.max_choices === "number") {
      FormatedInfoOptionData.max_choices = InfoOptionData.max_choices;
    }

    let FormatetdOptionChoice = [];
    OptionChoice.forEach(async (OptionChoix) => {
      let FormatedPriceOptionChoice;
      if (typeof OptionChoix.price === "string") {
        let PriceOptionChoice = OptionChoix.price.replace(",", ".");
        let FormatedPriceOptionChoiceNumber = parseFloat(
          OptionChoix.price.replace(",", ".")
        );

        if (
          isNaN(FormatedPriceOptionChoiceNumber) ||
          !/^\d*\.?\d*$/.test(PriceOptionChoice)
        ) {
          alert("La conversion n'a pas réussi. Ce n'est pas un nombre valide.");
          throw new Error(
            "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
          );
        }
        FormatedPriceOptionChoice = FormatedPriceOptionChoiceNumber;
      } else if (typeof OptionChoix.price === "number") {
        FormatedPriceOptionChoice = OptionChoix.price;
      }

      if (!OptionChoix.hasOwnProperty("preparationIntermediaire")) {
        alert("Vous devez choisir des Preparations dans les choix");
        throw new Error("Vous devez choisir des Preparations dans les choix");
      }

      let FormatedPreparationIntermediaire = [];
      OptionChoix.preparationIntermediaire.forEach((PrepUsed) => {
        if (typeof PrepUsed.Qte === "string") {
          let QtePrepInOption = PrepUsed.Qte.replace(",", ".");
          let FormatedQtePrepInOption = parseFloat(
            PrepUsed.Qte.replace(",", ".")
          );

          if (
            isNaN(FormatedQtePrepInOption) ||
            !/^\d*\.?\d*$/.test(QtePrepInOption)
          ) {
            alert(
              "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
            );
            throw new Error(
              "La conversion n'a pas réussi. Ce n'est pas un nombre valide."
            );
          }
          FormatedPreparationIntermediaire.push({
            IDPrep: PrepUsed.IDPrep,
            Qte: FormatedQtePrepInOption,
          });
        } else if (typeof PrepUsed.Qte === "number") {
          FormatedPreparationIntermediaire.push({
            IDPrep: PrepUsed.IDPrep,
            Qte: PrepUsed.Qte,
          });
        }
      });

      FormatetdOptionChoice.push({
        IDPepers: OptionChoix.id,
        preparationIntermediaire: FormatedPreparationIntermediaire,
        price: FormatedPriceOptionChoice,
        name: OptionChoix.name,
      });
    });

    const IDOption = uuidv4();
    const OptionDocRef = doc(firestore, "Options", IDOption);
    await setDoc(OptionDocRef, {
      ...FormatedInfoOptionData,
      IDPepers: IDOption,
      OptionValueID: FormatetdOptionChoice.map(
        (OptionChoix) => OptionChoix.IDPepers
      ),
    });

    FormatetdOptionChoice.forEach(async (FormatedOptionChoix) => {
      const OptionDocRef = doc(
        firestore,
        "OptionsValue",
        FormatedOptionChoix.IDPepers
      );
      await setDoc(OptionDocRef, {
        ...FormatedOptionChoix,
      });
    });
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
export async function getAllPepersOption() {
  try {
    const OptionsRef = collection(firestore, "Options");
    const OptionsSnapshoot = await getDocs(OptionsRef);
    let ListeOption = [];
    OptionsSnapshoot.forEach((doc) => {
      const OptionsData = doc.data();
      ListeOption.push({ ...OptionsData });
    });
    return ListeOption;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function HandleAssociePeperOptiontoPepersDish(Data) {
  console.log(Data);
}
export async function getAllPepersOptionsValue() {
  try {
    const OptionsValueRef = collection(firestore, "OptionsValue");
    const OptionsValueSnapshoot = await getDocs(OptionsValueRef);
    let ListeOptionValue = [];
    OptionsValueSnapshoot.forEach((doc) => {
      const OptionsValueData = doc.data();
      ListeOptionValue.push({ ...OptionsValueData });
    });
    return ListeOptionValue;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
export async function HandleAssocietePepersOptionValueToZeltyOptionValue(Data) {
  console.log(Data);
  try {
    const ZeltyOptionValueRef = doc(
      firestore,
      "zeltyOptionsValue",
      Data.OptionValueZelty.toString()
    );
    const ZeltyOptionValueSnapshot = await getDoc(ZeltyOptionValueRef);
    if (ZeltyOptionValueSnapshot.exists()) {
      const OptionValuePepersRef = doc(
        firestore,
        "OptionsValue",
        Data.IDPeperOptionValue
      );
      const OptionValuePepersSnapshot = await getDoc(OptionValuePepersRef);
      if (OptionValuePepersSnapshot.exists()) {
        await updateDoc(OptionValuePepersRef, {
          IDZeltyOptionValue: Data.OptionValueZelty.toString(),
        });
      }
      await updateDoc(ZeltyOptionValueRef, {
        IDPepersOptionValue: Data.IDPeperOptionValue.toString(),
      });
    } else {
      throw "L'Option Value zelty n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function getAllZeltyOptionValue() {
  try {
    const OptionsValueRef = collection(firestore, "zeltyOptionsValue");
    const OptionsValueSnapshoot = await getDocs(OptionsValueRef);
    let ListeOptionValue = [];
    OptionsValueSnapshoot.forEach((doc) => {
      const OptionsValueData = doc.data();
      ListeOptionValue.push({ ...OptionsValueData });
    });
    return ListeOptionValue;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function getAllPlanDeTravail(RestaurantName) {
  try {
    const docRef = collection(firestore, `${RestaurantName}PlanDeTravail`);
    const docSnapShot = await getDocs(docRef);
    let ListePlanDeTravail = [];
    docSnapShot.forEach((doc) => {
      ListePlanDeTravail.push({ ...doc.data().info });
    });
    return ListePlanDeTravail;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
export async function getAllMachine() {
  try {
    const docRef = collection(firestore, `Machine`);
    const docSnapShot = await getDocs(docRef);
    let AllMAchineData = [];
    docSnapShot.forEach((doc) => {
      AllMAchineData.push({ ...doc.data() });
    });
    return AllMAchineData;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function HandleCreatePlanDeTravail(Data) {
  console.log(Data);
  try {
    let planDeTravailID = uuidv4();
    const docRef = doc(
      firestore,
      `${Data.RestaurantName}PlanDeTravail`,
      planDeTravailID
    );
    await setDoc(docRef, {
      info: {
        DateCreation: new Date(),
        name: Data.name,
        IDPepers: planDeTravailID,
      },
      ListeMachine: [],
      ListePreparation: [],
      ProductInProduction: [],
    });
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function HandleAddMachine(Data) {
  console.log(Data);
  try {
    let MachineID = uuidv4();
    const docRef = doc(firestore, "Machine", MachineID);
    await setDoc(docRef, {
      ...Data,
      IDPepers: MachineID,
    });

    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function getDataPlanDeTravail(PlanDeTravailID, RestaurantName) {
  try {
    const docRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      PlanDeTravailID
    );
    const docSnapShot = await getDoc(docRef);
    let ListeMachineData = [];
    let ListePreparationData = [];
    if (docSnapShot.exists()) {
      let PlanDeTravailData = docSnapShot.data();
      for (const Machine of PlanDeTravailData.ListeMachine) {
        const MachineRef = doc(firestore, "Machine", Machine);
        const MachineSnapShot = await getDoc(MachineRef);
        if (MachineSnapShot.exists()) {
          ListeMachineData.push(MachineSnapShot.data());
        }
      }
      for (const Preparation of PlanDeTravailData.ListePreparation) {
        const PreparationRef = doc(firestore, "preparations", Preparation);
        const PreparationSnapShot = await getDoc(PreparationRef);
        if (PreparationSnapShot.exists()) {
          ListePreparationData.push(PreparationSnapShot.data());
        } else {
          throw "La preparation n'existe pas";
        }
      }
    } else {
      throw "Le plan de travail n'existe pas";
    }
    return {
      ListeMachineData,
      ListePreparationData,
    };
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function GetListProductCanBeMadeInThePosteDeTravail(
  PlanDeTravailID,
  RestaurantName
) {
  try {
    const docRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      PlanDeTravailID
    );
    const docSnapShot = await getDoc(docRef);
    let ListProductCanBeMadeInThePosteDeTravail = [];
    if (docSnapShot.exists()) {
      let IDListPrepInPlanDeTravail = docSnapShot.data().ListePreparation;

      let ListProductAvailableInTheResto = [];
      const dishesRef = doc(
        firestore,
        RestaurantName,
        "ActifProductInTheRestaurant"
      );

      const dishesSnapShot = await getDoc(dishesRef);
      if (dishesSnapShot.exists()) {
        ListProductAvailableInTheResto = dishesSnapShot.data().ActiveProduct;
        for (const IDProduct of ListProductAvailableInTheResto) {
          const ProductRef = doc(firestore, "dishes", IDProduct);
          const ProductSnapShot = await getDoc(ProductRef);
          let CheckIfOnePrepIsNotInPlanDeTravail = true;
          if (ProductSnapShot.exists()) {
            if (
              ProductSnapShot.data().Production.hasOwnProperty(
                "PreparationIntermediaire"
              )
            ) {
              if (
                ProductSnapShot.data().Production.PreparationIntermediaire
                  .length === 0
              ) {
                CheckIfOnePrepIsNotInPlanDeTravail = false;
              }
              for (const PrepUsed of ProductSnapShot.data().Production
                .PreparationIntermediaire) {
                if (!IDListPrepInPlanDeTravail.includes(PrepUsed.IDPrep)) {
                  CheckIfOnePrepIsNotInPlanDeTravail = false;
                }
              }
            }
            if (CheckIfOnePrepIsNotInPlanDeTravail) {
              ListProductCanBeMadeInThePosteDeTravail.push(
                ProductSnapShot.data()
              );
            }
          } else {
            throw "Le produit n'existe pas";
          }
        }
      } else {
        throw "Liste Actif product in the restaurant n'existe pas";
      }
    } else {
      throw "Le plan de travail n'existe pas";
    }
    return ListProductCanBeMadeInThePosteDeTravail;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function HandleAddMachineToPlanDeTravail(Data) {
  console.log(Data);
  try {
    for (const MachineID of Data.ListeMachine) {
      const docRefMachine = doc(firestore, "Machine", MachineID);
      const docSnapShotMachine = await getDoc(docRefMachine);
      if (docSnapShotMachine.exists()) {
        let MachineData = docSnapShotMachine.data();
        if (MachineData.hasOwnProperty("AssociateToPlanDeTravailID")) {
          if (MachineData.AssociateToPlanDeTravailID === "") {
          } else {
            alert("La machine est deja associé a un plan de travail");
            throw "La machine est deja associé a un plan de travail";
          }
        }
      } else {
        alert("La machine n'existe pas");
        throw "La machine n'existe pas";
      }
    }

    for (const MachineID of Data.ListeMachine) {
      const docRefMachine = doc(firestore, "Machine", MachineID);
      const docSnapShotMachine = await getDoc(docRefMachine);
      if (docSnapShotMachine.exists()) {
        await updateDoc(docRefMachine, {
          AssociateToPlanDeTravailID: Data.PlanDeTravailID,
        });
      } else {
        throw "Le plan de travail n'existe pas";
      }
    }

    const docRef = doc(
      firestore,
      `${Data.RestaurantName}PlanDeTravail`,
      Data.PlanDeTravailID
    );
    const docSnapShot = await getDoc(docRef);
    if (docSnapShot.exists()) {
      await updateDoc(docRef, {
        ListeMachine: arrayUnion(...Data.ListeMachine),
      });
    } else {
      throw "Le plan de travail n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
export async function HandleAddPrepToPlanDeTravail(Data) {
  console.log(Data);
  try {
    const docRef = doc(
      firestore,
      `${Data.RestaurantName}PlanDeTravail`,
      Data.PlanDeTravailID
    );
    const docSnapShot = await getDoc(docRef);
    if (docSnapShot.exists()) {
      await updateDoc(docRef, {
        ListePreparation: arrayUnion(...Data.ListePrep),
      });
    } else {
      throw "Le plan de travail n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function SupprimerMachineInPlanDeTravail(
  machine,
  PlanDeTravailID,
  RestaurantName
) {
  try {
    const docRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      PlanDeTravailID
    );
    const docSnapShot = await getDoc(docRef);
    if (docSnapShot.exists()) {
      const docRefMachine = doc(firestore, `Machine`, machine.IDPepers);
      const docSnapShotMachine = await getDoc(docRef);
      if (docSnapShotMachine.exists()) {
        await updateDoc(docRefMachine, {
          AssociateToPlanDeTravailID: "",
        });
        await updateDoc(docRef, {
          ListeMachine: arrayRemove(machine.IDPepers),
        });
      } else {
        throw "La machine n'existe pas";
      }
    } else {
      throw "Le plan de travail n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function SupprimerPrepInPlanDeTravail(
  Prep,
  PlanDeTravailID,
  RestaurantName
) {
  try {
    const docRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      PlanDeTravailID
    );
    const docSnapShot = await getDoc(docRef);
    if (docSnapShot.exists()) {
      await updateDoc(docRef, {
        ListePreparation: arrayRemove(Prep.IDPepers),
      });
    } else {
      throw "Le plan de travail n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function SupprimerPlanDeTravail(PlanDeTravailID, RestaurantName) {
  try {
    const docRef = doc(
      firestore,
      `${RestaurantName}PlanDeTravail`,
      PlanDeTravailID
    );
    const docSnapShot = await getDoc(docRef);
    if (docSnapShot.exists()) {
      let listeMachine = docSnapShot.data().ListeMachine;
      for (const MachineID of listeMachine) {
        const docRefMachine = doc(firestore, `Machine`, MachineID);
        const docSnapShotMachine = await getDoc(docRefMachine);
        if (docSnapShotMachine.exists()) {
          await updateDoc(docRefMachine, {
            AssociateToPlanDeTravailID: "",
          });
        } else {
          throw "La machine n'existe pas";
        }
      }
      await deleteDoc(docRef);
    } else {
      throw "Le plan de travail n'existe pas";
    }
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function getZeltyDishRupture(RestaurantName) {
  try {
    const response = await fetch(
      `https://us-central1-reddd-6b0fd.cloudfunctions.net/api/getZeltyRuptureProduct`
    );
    if (!response.ok) {
      throw new Error("Erreur HTTP " + response.status);
    }
    const data = await response.json();
    const docRefProductInRestaurant = doc(
      firestore,
      `${RestaurantName}`,
      "ActifProductInTheRestaurant"
    );
    const docSnapShotProductInRestaurant = await getDoc(
      docRefProductInRestaurant
    );
    if (docSnapShotProductInRestaurant.exists()) {
      const ListProductInRestaurant =
        docSnapShotProductInRestaurant.data().ActiveProduct;
      let ListeProductwithRuptureData = [];
      for (const Product of ListProductInRestaurant) {
        const ProductPeperDocRef = doc(firestore, "dishes", Product);
        const ProductPeperSnapshot = await getDoc(ProductPeperDocRef);
        if (ProductPeperSnapshot.exists()) {
          const ProductPeperData = ProductPeperSnapshot.data();
          if (ProductPeperData.hasOwnProperty("ZeltyID")) {
            const InfoRuptureZelty = data.dishes.filter(
              (dish) => dish.id_dish.toString() === ProductPeperData.ZeltyID
            )[0];
            ListeProductwithRuptureData.push({
              InfoRuptureZelty,
              ...ProductPeperData,
            });
          } else {
            console.log(
              "le produit pepers n'est pas associé a un produit zelty"
            );
          }
        } else {
          throw "Le produit n'existe pas";
        }
      }
      return ListeProductwithRuptureData;
    } else {
      throw "Le document des produits actifs n'existe pas";
    }
  } catch (error) {
    console.error(error);
    // Gérer l'erreur ici
  }
}
export async function getZeltyDishRuptureProduitActifQuiNeDevraitPasEtreDansLeRestaurant(
  RestaurantName
) {
  try {
    {
      /*const responseAllZeltyDish = await fetch(
      `https://us-central1-reddd-6b0fd.cloudfunctions.net/api/getZeltyRuptureProduct`
    );
    if (!responseAllZeltyDish.ok) {
      throw new Error("Erreur HTTP " + response.status);
    }
  const dataAllZeltyDish = await responseAllZeltyDish.json();*/
    }

    const response = await fetch(
      `https://us-central1-reddd-6b0fd.cloudfunctions.net/api/getZeltyRuptureProduct`
    );
    if (!response.ok) {
      throw new Error("Erreur HTTP " + response.status);
    }
    const data = await response.json();
    const AllActiveProduct = data.dishes.filter(
      (dish) => dish.outofstock === false
    );
    const docRefProductInRestaurant = doc(
      firestore,
      `${RestaurantName}`,
      "ActifProductInTheRestaurant"
    );
    const docSnapShotProductInRestaurant = await getDoc(
      docRefProductInRestaurant
    );
    if (docSnapShotProductInRestaurant.exists()) {
      const ListProductInRestaurant =
        docSnapShotProductInRestaurant.data().ActiveProduct;
      let ListeProductActiveButNotInTheRestauarant = [];
      for (const Product of AllActiveProduct) {
        const ProductZeltyDocRef = doc(
          firestore,
          "zeltyDishes",
          Product.id_dish.toString()
        );
        const ProductZeltySnapshot = await getDoc(ProductZeltyDocRef);
        if (ProductZeltySnapshot.exists()) {
          const ProductZeltyData = ProductZeltySnapshot.data();
          if (ProductZeltyData.hasOwnProperty("IDPepers")) {
            const ProductPepersDocRef = doc(
              firestore,
              "dishes",
              ProductZeltyData.IDPepers
            );
            const ProductPepersSnapshot = await getDoc(ProductPepersDocRef);
            if (ProductPepersSnapshot.exists()) {
              const ProductPepersData = ProductPepersSnapshot.data();
              if (
                !ListProductInRestaurant.includes(
                  ProductPepersData.Production.IDPepers
                )
              ) {
                ListeProductActiveButNotInTheRestauarant.push({
                  InfoRuptureZelty: Product,
                  ...ProductPepersData,
                });
              }
            } else {
              throw "Le produit pepers n'existe pas";
            }
          } else {
            console.log(
              "le produit Zelty n'est pas associé a un produit Pepers"
            );
          }
        } else {
          console.log(
            `Le produit zelty ${Product.id_dish.toString()} n'existe pas dans la base de donnee pepers`
          );
          // on cherche le produit dans zelty
        }
      }
      return ListeProductActiveButNotInTheRestauarant;
    } else {
      throw "Le document des produits actifs n'existe pas";
    }
  } catch (error) {
    console.error(error);
    // Gérer l'erreur ici
  }
}

export async function getDishDataWithId(IDDish) {
  try {
    const docRef = doc(firestore, "dishes", IDDish);
    const docSnapShot = await getDoc(docRef);
    if (docSnapShot.exists()) {
      return docSnapShot.data();
    } else {
      throw "Le produit n'existe pas";
    }
  } catch (error) {
    console.error(error);
  }
}

export async function HandleModifierDish(Data) {
  try {
    if (Data.Production.name === "") {
      alert("Il faus mettre un nom au produit");
      throw new Error("Il faus mettre un nom au produit");
    }
    if (Data.img === null) {
      alert("Il manque une image");
      throw new Error("Il manque une image");
    }

    const imageRef = ref(storage, `Visitor/Product/${Data.Production.name}`);
    await uploadString(imageRef, Data.img, "data_url");
    const downloadURL = await getDownloadURL(imageRef);

    const docRef = doc(firestore, "dishes", Data.IDDish);
    await updateDoc(docRef, {
      Production: { ...Data.Production, img: downloadURL },
    });

    return true;
  } catch (error) {
    console.error(error);
  }
}

export async function SupprimerPrepRefait(
  IDPrep,
  IDPrepRefait,
  RestaurantName
) {
  try {
    console.log(IDPrep);
    const docRef = doc(firestore, "preparations", IDPrep);
    const docSnapShot = await getDoc(docRef);
    if (docSnapShot.exists()) {
      const data = docSnapShot.data();
      if (data.hasOwnProperty("HistoryPrepRefait")) {
        const docRefPrepRefait = doc(
          firestore,
          `${RestaurantName}RefairePrep`,
          IDPrepRefait
        );
        const docSnapShotPrepRefait = await getDoc(docRefPrepRefait);
        if (docSnapShotPrepRefait.exists()) {
          const dataPrepRefait = docSnapShotPrepRefait.data();
          const Today = new Date(dataPrepRefait.date.seconds * 1000);
          const year = Today.getFullYear();
          const month = String(Today.getMonth() + 1).padStart(2, "0");
          const day = String(Today.getDate()).padStart(2, "0");
          const REFDocDailyActivity = `${day}${month}${year}`;

          const RefDailyActivity = doc(
            firestore,
            `${RestaurantName}DailyActivities`,
            REFDocDailyActivity
          );
          const DailyActivitySnapshoot = await getDoc(RefDailyActivity);
          if (DailyActivitySnapshoot.exists()) {
            const DailyActivityData = DailyActivitySnapshoot.data();
            if (DailyActivityData.hasOwnProperty("PreparationRefaite")) {
              await updateDoc(RefDailyActivity, {
                PreparationRefaite: arrayRemove(IDPrepRefait),
              });
              await updateDoc(docRef, {
                HistoryPrepRefait: arrayRemove(IDPrepRefait),
              });
              await deleteDoc(docRefPrepRefait);
            }
          }
        } else {
          throw "La preparation refait n'existe pas";
        }
      } else {
        throw "La preparation n'a pas de preparation refait";
      }
    } else {
      throw "La preparation n'existe pas";
    }
  } catch (error) {
    console.error(error);
  }
}
export async function AllPrepIsRefait() {
  try {
    const docRef = collection(firestore, "preparations");
    const docSnapShot = await getDocs(docRef);
    let ListePrep = [];
    docSnapShot.forEach((doc) => {
      const data = doc.data();
      if (data.hasOwnProperty("HistoryPrepRefait")) {
        ListePrep.push({ ...data });
      }
    });
    console.log(ListePrep);
    return ListePrep;
  } catch (error) {
    console.error(error);
  }
}

export async function HandleCreatePlanDeTravailWithSelectedProduct(Data) {
  try {
    console.log(Data);
    let planDeTravailID = uuidv4();
    const docRef = doc(
      firestore,
      `${Data.RestaurantName}PlanDeTravail`,
      planDeTravailID
    );
    let listAllPrepUsed = [];
    for (const IDProduct of Data.ListeDish) {
      const dishesDocRef = doc(firestore, "dishes", IDProduct);
      const dishesSnapshot = await getDoc(dishesDocRef);
      if (dishesSnapshot.exists()) {
        const productData = dishesSnapshot.data();
        if (
          productData.Production.hasOwnProperty("PreparationIntermediaire") &&
          productData.Production.PreparationIntermediaire.length > 0
        ) {
          productData.Production.PreparationIntermediaire.forEach(
            (PrepUsed) => {
              if (!listAllPrepUsed.includes(PrepUsed.IDPrep)) {
                listAllPrepUsed.push(PrepUsed.IDPrep);
              }
            }
          );
        }
      } else {
        alert("Le produit n'existe pas");
        throw "Le produit n'existe pas";
      }
    }

    await setDoc(docRef, {
      info: {
        DateCreation: new Date(),
        name: Data.name,
        IDPepers: planDeTravailID,
      },
      ListeMachine: [],
      ListePreparation: [...listAllPrepUsed],
      ProductInProduction: [],
    });
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function getDailyAnalysePrepRefait(
  RestaurantName,
  dateStart,
  dateEnd
) {
  try {
    let TableauIDDailyActivity = [];
    let DateDebut = new Date(dateStart);

    while (DateDebut.getTime() < new Date(dateEnd).getTime()) {
      const year = DateDebut.getFullYear();
      const month = String(DateDebut.getMonth() + 1).padStart(2, "0");
      const day = String(DateDebut.getDate()).padStart(2, "0");
      const DateFormated = `${day}${month}${year}`;
      TableauIDDailyActivity.push(DateFormated);
      DateDebut = new Date(DateDebut.getTime() + 24 * 60 * 60 * 1000);
    }

    let ListePrepRefait = [];
    for (const DateDocument of TableauIDDailyActivity) {
      const DailyDocRef = doc(
        firestore,
        `${RestaurantName}DailyActivities`,
        DateDocument
      );
      const DailySnapshot = await getDoc(DailyDocRef);
      if (DailySnapshot.exists()) {
        const DailyData = DailySnapshot.data();
        if (DailyData.hasOwnProperty("PreparationRefaite")) {
          ListePrepRefait = [
            ...ListePrepRefait,
            ...DailyData.PreparationRefaite,
          ];
        } else {
          console.log(
            `Il n'y a pas de prep refait pour le dailyActivitie ${DateDocument} `
          );
          alert(
            `Il n'y a pas de prep refait pour le dailyActivitie  ${DateDocument}`
          );
        }
        let listePrepRefaitData = [];
        for (const IDPrepRefait of ListePrepRefait) {
          const PrepRefaitDocRef = doc(
            firestore,
            `${RestaurantName}RefairePrep`,
            IDPrepRefait
          );
          const PrepRefaitSnapshot = await getDoc(PrepRefaitDocRef);
          if (PrepRefaitSnapshot.exists()) {
            const PrepRefaitData = PrepRefaitSnapshot.data();
            const PrepDocRef = doc(
              firestore,
              "preparations",
              PrepRefaitData.IDPrepRefait
            );
            const PrepSnapshot = await getDoc(PrepDocRef);
            if (PrepSnapshot.exists()) {
              const PrepData = PrepSnapshot.data();
              if (PrepRefaitData.hasOwnProperty("IngredientUtilise")) {
                let newListeIngredientUsed = [];
                for (const IngredientUsedID of PrepRefaitData.IngredientUtilise) {
                  const IngredientDocRef = doc(
                    firestore,
                    "Ingredients",
                    Object.keys(IngredientUsedID)[0]
                  );
                  const IngredientSnapshot = await getDoc(IngredientDocRef);
                  if (IngredientSnapshot.exists()) {
                    const IngredientData = IngredientSnapshot.data();
                    newListeIngredientUsed.push({
                      IngredientData,
                      QteUsed:
                        IngredientUsedID[Object.keys(IngredientUsedID)[0]],
                    });
                  } else {
                    alert("L'ingredient n'existe pas");
                    throw "L'ingredient n'existe pas";
                  }
                }
                listePrepRefaitData.push({
                  PrepData,
                  IngredientUtilise: newListeIngredientUsed,
                  qteRefait: PrepRefaitData.QteRefait,
                  RestaurantName: PrepRefaitData.RestaurantName,
                  dateRefait: PrepRefaitData.date,
                });
              }
            } else {
              throw "La preparation qui a ete refaite n'existe n'existe pas";
            }
          } else {
            throw "La preparation refait n'existe pas";
          }
        }
        console.log(listePrepRefaitData);
        return listePrepRefaitData;
      } else {
        alert(`Il n'y a pas de dailyActivitie : ${DateDocument}`);
        console.log(`Il n'y a pas de dailyActivitie : ${DateDocument}`);

        throw `Il n'y a pas de dailyActivitie : ${DateDocument}`;
      }
    }
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return false; // Sortir de la fonction si une erreur se produit
  }
}
export async function getAllDishCategories() {
  try {
    const docRef = doc(firestore, "Production", "CategorieDish");
    const docSnapShot = await getDoc(docRef);
    const ListeCategories = docSnapShot.data().ListeCategorie;

    return ListeCategories;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}

export async function HandleModifierCategoriesDish(Data) {
  try {
    if (!Data.hasOwnProperty("ListeDish")) {
      throw new Error("Il faut selectionner un plat");
    }
    if (Data.ListeDish.length === 0) {
      throw new Error("Il manques des infos");
    }

    if (!Data.hasOwnProperty("categorie")) {
      throw new Error("Il faut selectionner une categorie");
    }

    if (Data.categorie === "") {
      throw new Error("Il manques des infos");
    }

    for (const DishID of Data.ListeDish) {
      const docRef = doc(firestore, "dishes", DishID);
      await updateDoc(docRef, {
        CategorieDish: Data.categorie,
      });
    }
    return true;
  } catch (error) {
    console.error(error.message);
    return false;
  }
}
